import _ from 'lodash';
import { connect } from 'react-redux';

import { FormsActions } from '../../../../redux/forms';
import { PatientActions } from '../../../../redux/patient';
import CreatePatient from './CreatePatient';

const mapStateToProps = ({ medic }) => ({
  currentMedicId: _.get(medic, 'currentMedic.id'),
  selectedMedicTeam: _.chain(medic).get(['medicalTeamList', medic.selectedTeam]).value(),
});

const mapDispatchToProps = (dispatch) => ({
  patientsCreateRequest: (patients) => dispatch(PatientActions.patientsCreateRequest(patients)),
  clearData: () => dispatch(FormsActions.clearData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreatePatient);
