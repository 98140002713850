
import _ from 'lodash';
import { connect } from 'react-redux';

import { AuthActions } from '../../../redux/auth';
import { DisplayActions } from '../../../redux/display';
import { FormsActions } from '../../../redux/forms';
import { QuestionIntroActions } from '../../../redux/questionIntro';
import { SurveyActions } from '../../../redux/survey';
import { testJSON } from '../../../utils/utils';
import Consent from './Consent';

const mapStateToProps = ({ survey, auth }) => {
  let { currentSurvey, questionTypeIdsCheckboxes } = survey;
  const questionTypes = survey.questionTypes || {};
  if (currentSurvey) {
    if (currentSurvey.side) {
      currentSurvey.side = testJSON(currentSurvey.side) ? JSON.parse(currentSurvey.side) : currentSurvey.side;
    }
    if (currentSurvey.radius) {
      currentSurvey.radius = testJSON(currentSurvey.radius) ? JSON.parse(currentSurvey.radius) : currentSurvey.radius;
    }
    if ( currentSurvey.description) {
      currentSurvey.description = testJSON(currentSurvey.description) ? JSON.parse(currentSurvey.description) : currentSurvey.description;
    }

    const allSteps = [...(currentSurvey.survey_pdfs||[]), ...(currentSurvey.steps||[])];
    const max = Array.isArray(allSteps) && allSteps.length ? Math.max(...allSteps.map(el => parseInt(el.number))) : 0;
    const arrayNumbers = [...Array.from(Array(max + 1).keys()), -1];
    let array = [];
    arrayNumbers.forEach(i => {
      const pdfs = (currentSurvey.survey_pdfs||[]).filter(s => s.number === i);
      if (pdfs){
        array = [...array, ...pdfs];
      }
      const steps = (currentSurvey.steps||[]).filter(s => s.number === i);
      if (steps){
        array = [...array, ...steps];
      }
    });
    array = array.map((el, index) => ({ ...el, number: index + 1 }));
    currentSurvey.steps = (currentSurvey.steps || []).map(s => {
      const st = array.find(el => el.id === s.id);
      return { ...s, number: st.number };
    });
    currentSurvey.survey_pdfs = (currentSurvey.survey_pdfs || []).map(s => {
      const st = array.find(el => el.id === s.id);
      return { ...s, number: st.number };
    });

    let stepList = currentSurvey.steps ?? [];
    if (!currentSurvey.is_ref_signature ) {
      stepList = stepList.map((step, i) => ({
        ...step,
        questions: step.questions.map((q, t) => ({
          ...q,
          answer: currentSurvey.mainSurvey?.steps[i]?.questions[t]?.answer || '',
        })),
      }));
    }

    currentSurvey.steps = stepList.map(s => ({
      ...s,
      questions: s.questions.map(q => {
        if (questionTypeIdsCheckboxes.includes(q.type_id)) {
          const questionTypeName = _.get(questionTypes, [q.type_id, 'value']);

          return {
            ...q,
            type_name: questionTypeName,
          };
        } else {
          return q;
        }
      }),
    }));
  }

  return {
    currentSurvey,
    user: auth.user || {},
    questionTypes,
  };
};

const mapDispatchToProps = (dispatch) => ({
  introQuestionRequest: (surveyTypeId, lngId) => dispatch(QuestionIntroActions.introQuestionRequest(surveyTypeId, lngId)),
  surveyCurrentRequest: (id) => dispatch(SurveyActions.surveyCurrentRequest(id)),
  surveyCurrentDelete: () => dispatch(SurveyActions.surveyCurrentDelete()),
  questionTypesRequest: () => dispatch(SurveyActions.questionTypesRequest()),
  resetStatus: () => dispatch(AuthActions.resetStatus()),
  resetIsSigned: () => dispatch(SurveyActions.resetSurveySigned()),
  ipRequest: () => dispatch(AuthActions.ipRequest()),
  ipClear: () => dispatch(AuthActions.ipSuccess(null)),
  updateData: (key, data) => dispatch(FormsActions.updateData(key, data)),
  resetStep: () => dispatch(QuestionIntroActions.resetStep()),
  onRejectSurvey: (id, payload) => dispatch(SurveyActions.rejectRequest(id, payload)),
  setIsClinicalStudy: (isClinicalStudy) => dispatch(DisplayActions.setIsClinicalStudy(isClinicalStudy || false)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Consent);
