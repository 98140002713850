import _ from 'lodash';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { TemplateService } from '../../services';
import { defaultLanguageId } from '../../utils/const';
import { LoaderActions } from '../loader';
import { default as TemplateActions, types } from './actions';

function* templatesRequest() {
  yield put(LoaderActions.loading());
  const { language_id } = yield select((state) => state?.auth?.user);
  const [error, data] = yield call(TemplateService.getAll, [defaultLanguageId, language_id].filter(el => !!el));

  if (!error && data) {
    const { templates } = data;
    yield put(TemplateActions.templatesGetSuccess(templates.map( t => ({
      ...t,
      translation: _.keyBy(t.translation, 'language_id'),
    }))));
  } else {
    yield put(TemplateActions.templatesGetFailure());
  }
  yield put(LoaderActions.loaded());
}

// eslint-disable-next-line import/no-anonymous-default-export
export default [takeLatest(types.TEMPLATES_GET_REQUEST, templatesRequest)];
