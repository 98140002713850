// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.paint-button {
  background-color: #1d2a61;
  outline: none;
  border-color: #1d2a61;
  border-radius: 15px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  font-size: 0.8rem;
  border: none;
  margin-left: 5px;
}

.paint-button-content {
  display: -webkit-inline-box;
  height: 90px;
}

.paint-button-content .ec-modal-button-second {
  width: 160px;
}

.paint-button-content .ec-modal-button-second p {
  margin: 0;
}

/* Style the side navigation */
.paint-sidenav {
  height: 100%;
  width: 200px;
  background-color: rgb(233, 233, 233);
}

.circle-picker {
  margin: 0px;
  padding: 0px;
  margin-top: 5px;
  justify-content: center;
}

.paint-button-content-lines {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25px;
  overflow-y: auto;
}

.paint-button-content-lines button {
  height: 70px;
}

.paint-button-content-lines button p {
  margin: 0;
}
/* Style the content */
.paint-content {
  margin: 0 20px;
  padding: 0 20px;
}
`, "",{"version":3,"sources":["webpack://./src/ui/components/PaintForm/Paint.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,aAAa;EACb,qBAAqB;EACrB,mBAAmB;EACnB,YAAY;EACZ,iBAAiB;EACjB,eAAe;EACf,iBAAiB;EACjB,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,2BAA2B;EAC3B,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,SAAS;AACX;;AAEA,8BAA8B;AAC9B;EACE,YAAY;EACZ,YAAY;EACZ,oCAAoC;AACtC;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,eAAe;EACf,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,SAAS;AACX;AACA,sBAAsB;AACtB;EACE,cAAc;EACd,eAAe;AACjB","sourcesContent":[".paint-button {\n  background-color: #1d2a61;\n  outline: none;\n  border-color: #1d2a61;\n  border-radius: 15px;\n  color: white;\n  font-weight: bold;\n  cursor: pointer;\n  font-size: 0.8rem;\n  border: none;\n  margin-left: 5px;\n}\n\n.paint-button-content {\n  display: -webkit-inline-box;\n  height: 90px;\n}\n\n.paint-button-content .ec-modal-button-second {\n  width: 160px;\n}\n\n.paint-button-content .ec-modal-button-second p {\n  margin: 0;\n}\n\n/* Style the side navigation */\n.paint-sidenav {\n  height: 100%;\n  width: 200px;\n  background-color: rgb(233, 233, 233);\n}\n\n.circle-picker {\n  margin: 0px;\n  padding: 0px;\n  margin-top: 5px;\n  justify-content: center;\n}\n\n.paint-button-content-lines {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin-top: 25px;\n  overflow-y: auto;\n}\n\n.paint-button-content-lines button {\n  height: 70px;\n}\n\n.paint-button-content-lines button p {\n  margin: 0;\n}\n/* Style the content */\n.paint-content {\n  margin: 0 20px;\n  padding: 0 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
