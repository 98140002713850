

import { connect } from 'react-redux';

import { SurveyActions } from '../../../../redux/survey';
import Step from './Step';

const mapStateToProps = ({ survey }) => {
  return { questionTypes: Object.values((survey && survey.questionTypes) || {}) };
};

const mapDispatchToProps = (dispatch) => ({ questionTypesRequest: () => dispatch(SurveyActions.questionTypesRequest()) });

export default connect(mapStateToProps, mapDispatchToProps)(Step);
