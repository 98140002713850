/* Core exports */
import { connect } from 'react-redux';

import ProtectedRoutes from './ProtectedRoutes';

const mapStateToProps = ({ auth }) => {
  return { userRole: auth.user?.role };
};


export default connect(mapStateToProps, null)(ProtectedRoutes);
