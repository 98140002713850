
import { methods } from '../utils';
import { surveyMutations, surveyQueries } from './GraphQLRequest';
import { authenticatedMutation, authenticatedQuery, authenticatedService } from './middleware';

const { POST, PUT, GET } = methods;

const PROXY_API_URL = process.env.REACT_APP_PROXY_API || process.env.REACT_APP_BASE_API_URL || '/api';
const PROXY_SIGN_URL = process.env.REACT_APP_PROXY_SIGN || process.env.REACT_APP_SIGN_API_URL || '/sign';

/**
 * REST ROUTES
 */
const routes = {
  uploadSign: `${PROXY_SIGN_URL}`,
  surveys: `${PROXY_SIGN_URL}/surveys`,
  uploadDraw: `${PROXY_SIGN_URL}/file`,
  generatePDF: (surveyId) => `${PROXY_SIGN_URL}/surveys/${surveyId}/downloadAllPdfImagesFromDbToFolder`,
  generateDraw: (loid, ext) => `${PROXY_SIGN_URL}/file/${loid}?ext=${ext}`,
  surveywithPDF: (loid) => `${PROXY_SIGN_URL}/surveys/create?loid=${loid}`,
  surveysV2: () => `${PROXY_API_URL}/V2/surveys/`,
  surveyById: (surveyId) => `${PROXY_API_URL}/V2/surveys/${surveyId}`,
  archivedSurvey: (surveyId) => `${PROXY_API_URL}/V2/surveys/${surveyId}/archive`,
  archivedSomeSurvey: `${PROXY_API_URL}/V2/surveys/archive`,
  consentReject: (surveyId) => `${PROXY_API_URL}/V2/surveys/${surveyId}/reject`,
  genPatientSignUrl: (surveyId) => `${PROXY_API_URL}/V2/surveys/${surveyId}/createSignUrl`,
  withdrawSurvey: (surveyId) => `${PROXY_API_URL}/V2/surveys/${surveyId}/withdrawal`,

  genCounterSignUrl: (surveyId) => `${PROXY_SIGN_URL}/surveys/${surveyId}/contralia/countersign/genUrl`,
  contraliaSignInit: (surveyId) => `${PROXY_API_URL}/V2/surveys/${surveyId}/contraliaSignature`,
  contraliaSignNewCodeRequest: (surveyId) => `${PROXY_SIGN_URL}/surveys/${surveyId}/contralia/newCode`,
  contraliaSignCodeCheck: (surveyId) => `${PROXY_SIGN_URL}/surveys/${surveyId}/contralia/checkOtp`,
  contraliaCountersignInit: (surveyId) => `${PROXY_SIGN_URL}/surveys/${surveyId}/contralia/countersign/initiate`,
  contraliaCountersignNewCode: (surveyId) => `${PROXY_SIGN_URL}/surveys/${surveyId}/contralia/countersign/newCode`,
  contraliaCountersignCodeCheck: (surveyId) => `${PROXY_SIGN_URL}/surveys/${surveyId}/contralia/countersign/checkOtp`,
};

/*
 * SERVICES
 */
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  fetchPatientSurveyList: (patientId) => authenticatedQuery(surveyQueries.fetchPatientSurveyList(), {}, { patientId }),
  getSurveys: (languageId) => authenticatedQuery(surveyQueries.getSurveys(), {}, { languageId }),
  medicSurveys: () => authenticatedQuery(surveyQueries.surveysMedic()),
  types: (languageIds) => authenticatedQuery(surveyQueries.types(), {}, { languageIds }),
  specialities: (languageIds) => authenticatedQuery(surveyQueries.specialities(), {}, { languageIds }),
  questionTypes: () => authenticatedQuery(surveyQueries.questionTypes()),
  getSurvey: (id) => authenticatedService(GET, routes.surveyById(id), {}),
  saveSurveyToPatient: (formData) => authenticatedService(POST, routes.surveysV2(), formData, {}, {}),
  upsertWithPDF: (surveys, loid) => authenticatedService(PUT, routes.surveywithPDF(loid), { payload: surveys }),
  currentUpdateSurvey: (id, survey) =>
    authenticatedMutation(surveyMutations.currentUpdate(id, survey)),
  consentUpdate: (id, consent) => authenticatedMutation(surveyMutations.consentUpdate(), {}, { id, consent }),
  consentReject: (id, payload) => authenticatedService(POST, routes.consentReject(id), payload),
  genPatientSignUrl: (surveyId, questions, ip, startDate, endDate) => authenticatedService(POST, routes.genPatientSignUrl(surveyId), { questions, ip, startDate, endDate }),

  contraliaCountersignUrl: (surveyId) => authenticatedService(POST, routes.genCounterSignUrl(surveyId)),
  contraliaCountersignInit: (surveyId) => authenticatedService(POST, routes.contraliaCountersignInit(surveyId)),
  contraliaCountersignNewCode: (surveyId) => authenticatedService(POST, routes.contraliaCountersignNewCode(surveyId)),
  contraliaCountersignCodeCheck: (surveyId, code) => authenticatedService(POST, routes.contraliaCountersignCodeCheck(surveyId), { code }),
  contraliaSignInit: (id, questions, ip, startDate, endDate) => authenticatedService(POST, routes.contraliaSignInit(id), { id, questions, ip, startDate, endDate }),
  contraliaSignNewCodeRequest: (surveyId) => authenticatedService(POST, routes.contraliaSignNewCodeRequest(surveyId)),
  contraliaSignCodeCheck: (surveyId, code) => authenticatedService(POST, routes.contraliaSignCodeCheck(surveyId), { code }),
  uploadSign: (formData) => authenticatedService(POST, routes.uploadSign, formData, {}, {}),
  generatePDF: (surveyId) => authenticatedService(GET, routes.generatePDF(surveyId)),
  generateDraw: (loid, ext) => authenticatedService(GET, routes.generateDraw(loid, ext), {}),
  signPaperSurvey: (formData) => authenticatedService(POST, routes.surveys, formData, {}),
  uploadDraw: (formData) => authenticatedService(POST, routes.uploadDraw, formData, {}),
  createSurveyDrawings: (drawings) => authenticatedMutation(surveyMutations.createSurveyDrawings(), {}, { data: drawings }),
  createSurveyAttachment: (attachments) => authenticatedMutation(surveyMutations.createSurveyAttachments(), {}, { data: attachments }),
  currentSurveyIntervention: (interventionID) =>
    authenticatedQuery(surveyQueries.currentSurveyIntervention(), {}, { id: interventionID }),
  archivedSome: (ids) => authenticatedService(POST, routes.archivedSomeSurvey, { ids }),
  archivedSurvey: (id) => authenticatedService(POST, routes.archivedSurvey(id)),
  updateUpdateDate: id => authenticatedMutation(surveyMutations.updateUpdateDate(), {}, { id }),
  withdrawSurvey: surveyId => authenticatedService(POST, routes.withdrawSurvey(surveyId)),

  patientSignSurvey: (id, questions, ip, startDate, endDate, codeData) => authenticatedService(PUT, routes.surveyById(id), { questions, ip, startDate, endDate, ...codeData }),
};
