// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ec-pdf-viewer-container {
  width: 100%;
  max-width: 600px;
  margin: auto;
}

.ec-pdf-viewer-container-hidden {
  display: none;
}

.ec-pdf-viewer-container canvas,
.ec-pdf-viewer-container svg,
.ec-pdf-viewer-container .react-pdf__Page__svg {
  width: 100% !important;
  max-width: 100% !important;
  height: unset !important;
}

.ec-pdf-viewer-custom-navigation {
  background-color: #74798c;
  height: 35px;
  margin-bottom: 15px;
  padding: 0 15px;
}

.ec-pdf-viewer-custom-navigation > button {
  cursor: pointer;
  margin: 0;
  text-decoration: none;
}


.ec-pdf-viewer-custom-navigation > button:hover {
  color: white;
  text-transform: none;
}

.ec-pdf-viewer-navigation-text {
  color: white;
  white-space: nowrap;
  font-size: 13px;
}
`, "",{"version":3,"sources":["webpack://./src/ui/components/PDFViewer/style.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,aAAa;AACf;;AAEA;;;EAGE,sBAAsB;EACtB,0BAA0B;EAC1B,wBAAwB;AAC1B;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,SAAS;EACT,qBAAqB;AACvB;;;AAGA;EACE,YAAY;EACZ,oBAAoB;AACtB;;AAEA;EACE,YAAY;EACZ,mBAAmB;EACnB,eAAe;AACjB","sourcesContent":[".ec-pdf-viewer-container {\r\n  width: 100%;\r\n  max-width: 600px;\r\n  margin: auto;\r\n}\r\n\r\n.ec-pdf-viewer-container-hidden {\r\n  display: none;\r\n}\r\n\r\n.ec-pdf-viewer-container canvas,\r\n.ec-pdf-viewer-container svg,\r\n.ec-pdf-viewer-container .react-pdf__Page__svg {\r\n  width: 100% !important;\r\n  max-width: 100% !important;\r\n  height: unset !important;\r\n}\r\n\r\n.ec-pdf-viewer-custom-navigation {\r\n  background-color: #74798c;\r\n  height: 35px;\r\n  margin-bottom: 15px;\r\n  padding: 0 15px;\r\n}\r\n\r\n.ec-pdf-viewer-custom-navigation > button {\r\n  cursor: pointer;\r\n  margin: 0;\r\n  text-decoration: none;\r\n}\r\n\r\n\r\n.ec-pdf-viewer-custom-navigation > button:hover {\r\n  color: white;\r\n  text-transform: none;\r\n}\r\n\r\n.ec-pdf-viewer-navigation-text {\r\n  color: white;\r\n  white-space: nowrap;\r\n  font-size: 13px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
