import './style.css';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import Card from '../card';
import ConfirmPopup from '../confirmPopup';
import CountryFlag from '../formV3/controllers/CountrySelect/CountryFlag';
import Button from '../styledComponent/Button';

const URL_PARAMS_KEY = 'editLocale';

function FormLanguageSelector({ languageList, LanguageIds, fetchAllLanguages, shouldConfirm, selectedLngId }) {
  const { t } = useTranslation();
  const [selectedLng, setSelectedLng] = useState();
  const [isConfirmModalOpen, setIsConfirmModaleOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    fetchAllLanguages();
  }, []);

  useEffect(() => {
    const lng = searchParams.get(URL_PARAMS_KEY) || (Array.isArray(languageList) && languageList.length && languageList[0].language_code);
    if (lng !== selectedLng) {
      setSelectedLng(lng);
    }
  }, [searchParams, languageList]);

  useEffect(() => {
    if (selectedLngId){
      const lng = Array.isArray(languageList) && languageList.find(l => l.id === selectedLngId);
      if (lng) {
        handleChangeUrlLng(lng);
      }
    }
  }, [selectedLngId]);

  const list = React.useMemo(() => {
    if (LanguageIds) {
      return languageList.filter((lng) => LanguageIds.includes(lng.id));
    } else {
      return languageList || [];
    }
  }, [languageList, LanguageIds]);

  const handleChangeUrlLng = (lng) => {
    setSearchParams({ [URL_PARAMS_KEY] : lng?.language_code }, { replace: true });
  };

  const handleClickChangeLng = (e, lng) => {
    e.preventDefault();
    if (lng !== selectedLng) {
      setSelectedLng(lng);
    }
    if (!shouldConfirm()) {
      handleChangeUrlLng(lng);
    } else {
      handleOpenModal();
    }
  };

  const onModalConfirm = () => {
    handleCloseModal();
    handleChangeUrlLng(selectedLng);
  };

  const handleCloseModal = () => {
    setIsConfirmModaleOpen(false);
  };

  const handleOpenModal = () => {
    setIsConfirmModaleOpen(true);
  };

  if ((Array.isArray(list) && list.length > 1)) {
    return (
      <Card >
        <div className="FormLanguageSelector d-flex flex-column flex-sm-row flex-wrap">
          {list.map((lng) => (
            <Button
              key={lng.id}
              onClick={(e) => handleClickChangeLng(e, lng)}
              type="button"
              variant="link"
              className={selectedLng === lng.language_code ? 'active' : ''}
              disabled={selectedLng === lng.language_code}
            >
              <CountryFlag countryCode={lng?.country_code_flag} hideText />
            </Button>
          ))}
        </div>

        {isConfirmModalOpen && (
          <ConfirmPopup
            title={t('confirm_change_lng')}
            onConfirm={onModalConfirm}
            action="delete"
            isOpen={isConfirmModalOpen}
            onClose={handleCloseModal}
          />
        )}
      </Card>
    );
  } else {
    return null;
  }
}

FormLanguageSelector.propTypes = {
  languageList: PropTypes.array,
  LanguageIds: PropTypes.array,
};

export default FormLanguageSelector;
