import '../style.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CONTENT_TYPE_MAPPER } from '../../../../utils/enum';
import SmallLoader from '../../loaders/SmallLoader';
import { Button } from '../../styledComponent';
import PDFObject from './PDFObject';

const PDFViewerOnly = ({ token, documentData, hidden = false, showBtn, onDocumentLoadSuccess }) => {
  const { t } = useTranslation();
  const [pdfUrl, setPdfUrl] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!!documentData?.file) {
      const objectUrl = window.URL.createObjectURL(documentData.file);
      setPdfUrl(objectUrl);
      setIsLoading(false);
    }
  }, [documentData && documentData.file]);

  useEffect(() => {
    const fetchData = async (url) => {
      const responseType = 'blob'; // 'arraybuffer' || 'blob';
      const res = await axios.get(url, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/pdf',
          Authorization: `Bearer ${token}`,
        },
        responseType,
      });

      const newFileType = res.headers['content-type'];
      const objectUrl = window.URL.createObjectURL(res.data);
      setPdfUrl(newFileType === CONTENT_TYPE_MAPPER.PDF ? objectUrl : null);
      setIsLoading(false);
    };
    if (token && documentData && documentData.url) {
      fetchData(documentData.url);
    }
  }, [documentData && documentData.url, token]);

  const handleDownload = () => {
    var link = document.createElement('a'); //creating an invisible element
    link.setAttribute('href', pdfUrl || window.URL.createObjectURL(
      documentData.file,
    ));
    link.setAttribute('download', documentData.filename || 'test.pdf');
    document.body.appendChild(link);
    link.click();
    setTimeout(function () {
      window.URL.revokeObjectURL(link);
    }, 200);
  };

  return (
    <>
      { !documentData
        ? null
        : (
          <>
            <div className={`d-flex ${isLoading ? '' : 'd-none'}`}><SmallLoader /></div>
            <div className={`ec-pdf-viewer-container d-flex flex-column mb-1 ${hidden || isLoading ? 'd-none' : ''}`}>
              { pdfUrl && (
                <PDFObject url={pdfUrl} />
              ) }
              { showBtn && (
                <div className="d-flex justify-content-end">
                  <Button
                    onClick={handleDownload}
                    type="button"
                  >
                    {t('download')}
                  </Button>
                </div>
              )}
            </div>
          </>
        )
      }
    </>
  );
};

export default PDFViewerOnly;
