import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Modal from '../../modal';
import DocumentChoiceRender from './DocumentChoiceRender';

export const ACTIONS = {
  BUILD_PDF: 'build_pdf',
  BUILD_DRAW: 'build_draw',
  BUILD_ATTACHMENT: 'build_attachment',
  QUESTION_PDF: 'question_pdf',
  EDIT_DATE: 'edit_date',
  GENERATE_QRCODE: 'qr_code',
  BUILD_INFO: 'build_info',
  UPLOAD_SIGN: 'upload_sign',
  RESUME: 'resume',
  DELETE: 'delete',
  DELETE_TOKEN: 'delete_token',
  COUNTERSIGNED_INFO_MESSAGE: 'countersigned_info_message',
  RESET_PDF_URL: 'reset_pdf_url',
};

function DocumentChoice({ onModalClose, survey, patient, action, onClearAction, children, bearerToken }) {
  const { t } = useTranslation();
  const title = React.useMemo(() => {
    if (Object.values(ACTIONS).includes(action)){
      return t(`DocumentChoice.title.${action}`);
    } else {
      return t('DocumentChoice.title.default');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action]);

  const handleOnModalClose = () => {
    if (onModalClose) {
      onClearAction();
      onModalClose();
    }
  };
  return (
    <Modal onClose={handleOnModalClose} title={title} isOpen>
      {!action
        ? (
          <div className="ec-document-choice-line">
            {children}
          </div>
        ):(
          <DocumentChoiceRender
            action={action}
            survey={survey}
            patient={patient}
            onModalClose={handleOnModalClose}
            bearerToken={bearerToken}
            onClearAction={onClearAction}
          />
        )}
    </Modal>
  );
}


DocumentChoice.propTypes = {
  onModalClose: PropTypes.func,
  survey: PropTypes.object,
  patient: PropTypes.object,
  action: PropTypes.oneOf([
    'build_pdf', 'build_draw', 'build_attachment', 'question_pdf', 'edit_date', 'qr_code', 'build_info',
  ]),
  onClearAction: PropTypes.func,
  children: PropTypes.any,
  bearerToken: PropTypes.string,
};

export default DocumentChoice;
