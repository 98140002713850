import parse from 'html-react-parser';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../styledComponent';

const QuestionBasicsTextError = ({ onValidate, onCancel, text }) => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);

  const handleOnValidate = () => {
    if (onValidate) {
      onValidate();
    }
  };

  const handleOnError = () => {
    if (!activeStep) {
      setActiveStep(activeStep + 1);
    } else if (onCancel) {
      onCancel();
    }
  };

  return activeStep === 0 ? (
    <>
      {parse("<p class='ec-question-basics-common-text'>" + text + '</p>')}
      <p className={'ec-question-basics-common-text'}>
        {t('verify_identity_error_first_choice')}
      </p>
      <Button
        variant="primary"
        onClick={onValidate}
        type="button"
      >
        {t('verify_identity_error_first_button')}
      </Button>
      <p className={'ec-question-basics-common-text'}>
        {t('verify_identity_error_second_choice')}
      </p>
      <Button
        variant="danger"
        onClick={handleOnError}
        type="button"
      >
        {t('verify_identity_error_second_button')}
      </Button>
    </>
  ) : (
    <>
      <p className={'ec-question-basics-common-text'}>
        {t('verify_identity_error_stop_consent')}
      </p>
      <div className={'ec-question-basics-modal-separator'} />
      <div className="d-flex flex-row justify-content-between mx-4">
        <Button
          variant="primary"
          onClick={handleOnError}
          type="button"
        >
          {t('verify_identity_error_second_button')}
        </Button>
        <Button
          variant="danger"
          onClick={handleOnValidate}
          type="button"
        >
          {t('verify_identity_error_first_button')}
        </Button>
      </div>
    </>
  );
};

export default QuestionBasicsTextError;
