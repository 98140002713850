import './style.css';

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import IconPaper from '../../../../assets/img/icon-acte-papier@3x.png';
import IconNum from '../../../../assets/img/icon-signer-numeriquement@3x.png';

const CardButton = ({ onClick, onClose }) => {
  const { t } = useTranslation();
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  const handleClickOutside = (e) => {
    const { className } = e.target;
    if (!className) {
      return;
    }
    if (
      className !== 'infopatient-details-card-container'
      && className !== 'infopatient-details-card-button-active'
      && className !== 'infopatient-detail-card-button-image'
      && className !== 'infopatient-details-card-button-text'
      && onClose
    ) {
      onClose();
    }
  };

  return (
    <div className={'infopatient-details-card-container'}>
      <button
        className={'infopatient-details-card-button-active'}
        onClick={(e) => onClick(e, 'numeric')}
      >
        <img
          className={'infopatient-detail-card-button-image'}
          src={IconNum}
          alt="infopatient-detail-card-button"
        />
        <p className={'infopatient-details-card-button-text'}>
          {t ? t('card_button_numerique') : ''}
        </p>
      </button>
      <button
        className={'infopatient-details-card-button-active'}
        onClick={(e) => onClick(e, 'paper')}
      >
        <img
          className={'infopatient-detail-card-button-image'}
          src={IconPaper}
          alt="infopatient-detail-card-button"
        />
        <p className={'infopatient-details-card-button-text'}>
          {t ? t('card_button_papers') : ''}
        </p>
      </button>
    </div>
  );
};

export default CardButton;