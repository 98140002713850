import './style.css';

import React from 'react';

import { IconBack } from '../../../assets/img/icons';

const NavigateBack = ({ text, onClick }) => {
  const handleOnClick = () => {
    if (onClick) onClick();
  };
  return (
    <div className={'ec-navigate-back-button-container'} onClick={handleOnClick}>
      <div className={'ec-navigate-back-button'}>
        <img src={IconBack} alt="previous" />
      </div>
      <p className={'ec-navigate-back-button-text'}>{text}</p>
    </div>
  );
};

export default NavigateBack;
