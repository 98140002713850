import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import IconDomicile from '../../../assets/img/cicon-domicile.png';
import IconLieu from '../../../assets/img/cicon-lieu.png';
import { CONSENT_TYPES } from './ConsentType';

const Button = ({ type, onClick }) => {
  const { t } = useTranslation();
  const [active, setActive] = useState(false);
  const handleOnClick = () => {
    if (type === 'add') setActive(!active);
    else if (onClick) onClick();
  };

  const iconSrc = () => {
    switch (type) {
      case CONSENT_TYPES.LOCALLY:
        return IconLieu;
      case CONSENT_TYPES.HOME_BASED:
        return IconDomicile;
      default:
        return IconLieu;
    }
  };

  const textSrc = () => {
    switch (type) {
      case CONSENT_TYPES.LOCALLY:
        return t('consent_preview_locally');
      case CONSENT_TYPES.HOME_BASED:
        return t('consent_preview_home_based');
      default:
        return t('consent_preview_locally');
    }
  };

  return (
    <button
      className={active ? 'sidemenu-button-active' : 'sidemenu-button'}
      onClick={handleOnClick}
      type="button"
    >
      <div className={'consent-preview-button-text-container'}>
        <p className={'consent-preview-button-text-min'}>
          {t('consent_preview_consentement')}
        </p>
        <p className={'consent-preview-button-text-max'}> {textSrc()} </p>
      </div>
      <div className={'sidemenu-button-image-card-container'}>
        <div className={'sidemenu-button-image-background'}>
          <img src={iconSrc()} alt="icon source" />
        </div>
      </div>
    </button>
  );
};

export default Button;