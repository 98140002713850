import _ from 'lodash';
import { connect } from 'react-redux';

import { FormsActions } from '../../../redux/forms';
import { MedicActions } from '../../../redux/medic';
import { PatientActions } from '../../../redux/patient';
import { SurveyActions } from '../../../redux/survey';
import { editLetterCaseUsers, getUserProperty } from '../../../utils';
import ConsentPreview from './ConsentPreview';
import { INITIAL_SURVEY } from './FormModel/formInitialValues';

const mapStateToProps = ({ auth, patient, survey, medic, forms }, props) => {
  const { initialValues } = props;
  const questionTypeIdsCheckboxes = survey.questionTypeIdsCheckboxes;
  const replaceVariables = (comment, pFullContactName, mLastname) => {
    let ret = comment;
    if (!ret || !ret.length) {
      return ret;
    }
    if (pFullContactName) {
      ret = ret.replace(/%nom_patient%/g, pFullContactName);
    }
    if (mLastname) {
      ret = ret.replace(/%nom_docteur%/g, mLastname);
    }
    return ret;
  };


  /**
   * @description Get the selected representatives
   * @param {Array<any>}data
   * @param {undefined | 'signatory'}type
   * @return {any[]|{}[]|*[]}
   */
  const getSelectionRepresentatives = (data, type= undefined) => {
    if (!data|| !data.length) return [];

    if (data.length === 1) return data;

    const selected = data.filter(s => s.is_selected)[0];
    // if type is specified, return the first selected representative with the specified type
    if(type) {
      return [{ [type]: selected }];
    }
    // if no type is specified, return the first selected representative
    return [selected];
  };


  const currentMedic= (medic && medic.currentMedic) || null;
  const selectedPatient= patient && {
    ...patient.selectedPatient,
    tutorsByPatientId: getSelectionRepresentatives(patient.currentPatient.signatories, 'signatory'),
    signatories: getSelectionRepresentatives(patient.currentPatient.signatories),
  };

  let newInitialValues = {
    ...INITIAL_SURVEY,
    // ...INITIAL_SURVEY_TEST,
    ...survey.temporarySurvey || initialValues,
  };
  // get referent medic
  const medicTeam = _.get(medic, ['medicalTeamList', newInitialValues.medical_teams_id, 'medics']);
  const referentMedic = Array.isArray(medicTeam) && medicTeam.find(m => `${m.id}` === `${newInitialValues.referent_medic_id}`);
  const patientFullContactName = getUserProperty(selectedPatient, 'fullContactName');
  const medicLastname = editLetterCaseUsers(referentMedic).lastname;
  const questionTypeList = survey?.questionTypes || {};

  newInitialValues = {
    ...newInitialValues,
    steps: (newInitialValues.steps || []).map(s => ({
      ...s,
      questions: s.questions.map(q => {
        q = {
          ...q,
          type_name: _.get(questionTypeList, [q.type_id, 'value']),
          translation: {
            ...q.translation,
            desc: replaceVariables(q.translation.desc, patientFullContactName, medicLastname),
            rewording: replaceVariables(q.translation.rewording, patientFullContactName, medicLastname),
          },
        };
        if (q.translation.texte_pdf && q.translation.texte_pdf !== ''){
          q = {
            ...q,
            translation: {
              ...q.translation,
              texte_pdf: replaceVariables(q.translation.texte_pdf, patientFullContactName, medicLastname),
            },
          };
        }
        if (Array.isArray(q.translation?.data?.list)) {
          q = {
            ...q,
            translation: {
              ...q.translation,
              data: {
                ...q.translation.data,
                list: (q.translation.data.list || []).map(el => {
                  if (!!el?.label) {
                    el.label = replaceVariables(el.label, patientFullContactName, medicLastname);
                  }
                  return el;
                }),
              },
            },
          };
        }
        if (questionTypeIdsCheckboxes.includes(q.type_id)) {
          return {
            ...q,
            translation: {
              ...q.translation,
              data: {
                ...q.translation.data,
                list: q.translation.data.list.map(el => (_.isObject(el) ? el : ({ value : el }))),
              },
            },
          };
        } else {
          return q;
        }
      }),
    })),
  };

  return {
    token: auth.token,
    questionTypes: Object.values(questionTypeList),
    initialValues: newInitialValues,
    patient: (patient && patient.list) || {},
    link: survey && survey.link,
    currentMedic: currentMedic,
    selectedTeam: (medic && medic.selectedTeam),
    selectedPatient: selectedPatient,
    reduxForms: _.get(forms, 'data'),
    surveyTypes: survey.types,
    questionTypeIdsCheckboxes,
    medicalTeamList: medic.medicalTeamList,
  };
};

const mapDispatchToProps = (dispatch) => ({
  patientDetailRequest: (id, selectedTeam = null) => dispatch(PatientActions.patientDetailRequest(id, selectedTeam)),
  surveyTypesRequest: () => dispatch(SurveyActions.surveyTypesRequest()),
  specialitiesRequest: () => dispatch(SurveyActions.specialitiesRequest()),
  questionTypesRequest: () => dispatch(SurveyActions.questionTypesRequest()),
  linkSurveyPatientRequest: (survey, attachments) => dispatch(SurveyActions.linkSurveyPatientRequest(survey, attachments)),
  deleteTemporarySurvey: () => dispatch(SurveyActions.temporarySurveyDelete()),
  deleteLocalLink: () => dispatch(SurveyActions.deleteLocalLink()),
  resetGeneratedURL: () => dispatch(SurveyActions.resetGenerateSuccess()),
  checkIfEmailExistsInDb: ( email, userId ) => dispatch(PatientActions.checkIfEmailExistsInDb(email, userId)),
  patientUpdateRequest: (id, patient, role) => dispatch(PatientActions.patientUpdateRequest(id, patient, role)),
  clearFormData: () => dispatch(FormsActions.clearData()),
  onTeamChange: (teamId) => dispatch(MedicActions.changeSelectedMedicTeamRequest(teamId)),
  resumeSurveyRequest: (surveyId) => dispatch(PatientActions.resumeSurveyRequest( surveyId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConsentPreview);
