
import { addYears, endOfYear, subYears } from 'date-fns';

import { regexSecu } from '../../../../utils/regex';
import { isValidSecuKey, isValidSecuNumFromRegex } from './rule';

const PRESET = {
  birthdate : {
    preset: {
      maxDate: new Date(),
      minDate: subYears(endOfYear(new Date()), 100),
    },
  },
  security_number : {
    preset: {
      autoCorrect:'off',
      autoCapitalize: 'characters',
    },
    mask:{
      formatChars: {
        '9': '[0-9]',
        'a': '[A-Za-z]',
        '*': '[A-Za-z0-9]',
        's': '[1-478]',
        'c': '[0-9]|[ABab]',
      },
      mask:'s 99 99 9c 999 999 99',
    },
    rules: {
      required: true,
      maxLength: 22,
      minLength: 22,
      pattern: regexSecu,
      validate: {
        isValidSecuNum: v => isValidSecuNumFromRegex(v),
        isValidSecuKey: v => isValidSecuKey(v),
      },
    },
  },
  rpps_number : {
    preset: {
      maxLength:'11', // Le numéro RPPS renseigné est composé de 11 caractères numériques
      autoCorrect:'off',
      autoCapitalize: 'characters',
    },
  },
  postcode : {
    preset: {
      // maxLength:'7'
      autoCorrect:'off',
      autoCapitalize: 'characters',
    },
    mask:{
      formatChars: { '9': '[0-9]' },
      mask:'99 999',
    },
    rules: {
      required: true,
      maxLength: 7,
      minLength: 7,
      validate: {},
    },
  },
  intervention_date: {
    preset: {
      maxDate: addYears(endOfYear(new Date()), 1),
      minDate: subYears(endOfYear(new Date()), 1),
    },
  },
  password: {
    preset: {
      autoCorrect:'off',
      autoComplete: 'password',
      autoCapitalize:'off',
      spellCheck:'false',
      placeholder: 'Azerty123',
    },
    rules: { required: true },
  },
  email:{
    preset: {
      autoCorrect:'off',
      autoComplete: 'email',
      autoCapitalize:'off',
      spellCheck:'false',
      keyboard: 'email-address',
      placeholder: 'email@service.com',
    },
    rules: { required: true },
  },
};

export { PRESET };