import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { AbilityContext } from '../../../../services/casl/Can';
import { MODEL_NAMES, PERMISSIONS } from '../../../../services/casl/roles';
import { defaultLanguageId } from '../../../../utils/const';
import { extractParams } from '../../../../utils/router-helper';
import AuthLayout from '../../../layouts/authLayout';
import CreateForm from '../CreateForm';

const SurveysCreate = ({ medicTemplateList, languageList, questionTypesRequest, questionTypeList, surveyTypesRequest, specialitiesRequest, currentFormRequest, questionTypeIdsCheckboxes }) => {
  const { templateId } = useParams();
  const [searchParams] = useSearchParams();
  const [initialValues, setInitialValues] = useState();
  const ability = useContext(AbilityContext);
  const [readOnlyForm, setReadOnlyForm] = useState(false);
  const [selectedLngId, setSelectedLngId] = useState();
  const urlParams = useMemo(() => extractParams(searchParams), [searchParams]);
  const editLocaleParams = useMemo(() => urlParams?.editLocale, [urlParams?.editLocale]);
  const currentForm = useMemo(() => _.get(medicTemplateList, [templateId]), [templateId, medicTemplateList]);
  const canUpdateForm = useMemo(() => ability.can(PERMISSIONS.UPDATE, { ...(currentForm || {}), medic_id: `${currentForm?.medic_id}` }), [ability, currentForm]);
  const canCreateForm = useMemo(() => ability.can(PERMISSIONS.CREATE, MODEL_NAMES.MEDIC_TEMPLATES), [ability]);

  useEffect(() => {
    surveyTypesRequest();
    questionTypesRequest();
    specialitiesRequest();
  }, []);

  useEffect(() => {
    if (templateId) {
      currentFormRequest(templateId);
      // currentFormRequest(templateId, selectedLngId || 1);
    }
  }, [templateId, selectedLngId]);


  useEffect(() => {
    if (Array.isArray(languageList)) {
      let newSelectedLngId = languageList[0]?.id;
      if (editLocaleParams) {
        newSelectedLngId = languageList.find((lng) => lng.language_code === editLocaleParams)?.id;
      } else if (currentForm) {
        const translationArray = Object.values(currentForm.translation || {});
        if (translationArray.length) {
          newSelectedLngId = translationArray[0].language_id;
        } else if (Array.isArray(currentForm.availableLng)) {
          newSelectedLngId = languageList.find((lng) => lng.language_code === currentForm.availableLng[0])?.id;
        }
      }
      if (newSelectedLngId !== selectedLngId) {
        setSelectedLngId(newSelectedLngId);
      }
    }
  }, [languageList, currentForm, editLocaleParams]);

  useEffect(() => {
    if (currentForm && currentForm.id && currentForm.id !== '') {
      // can edit
      const isReadOnly = !canUpdateForm;
      setReadOnlyForm(isReadOnly);
    } else {
      // can create
      const isReadOnly = !canCreateForm;
      setReadOnlyForm(isReadOnly);
    }
  }, [currentForm, ability]);

  useEffect(() => {
    if (_.isObject(currentForm) && questionTypeList && selectedLngId) {
      const selectedLng = languageList.find((lng) => lng.id === selectedLngId);
      const isTemplateAlreadyTranslated = (currentForm.availableLng || []).includes(selectedLng?.language_code);
      const defaultLngId = (currentForm.availableLng && currentForm.availableLng.length)
        ? languageList.find((lng) => lng.language_code === currentForm.availableLng[0])?.id
        : languageList.find((lng) => lng.id === defaultLanguageId)?.id;
      const template = _.get(currentForm, ['translation', selectedLngId], {});
      let description = template && template.description && JSON.parse(template.description);
      const newInitialValues = {
        id: currentForm.id,
        language_id: selectedLngId,
        medical_teams_id: currentForm.medical_teams_id,
        translation: {
          id: template.id || null,
          language_id: template.language_id || selectedLngId,
          title: template.title || '',
          description: Array.isArray(description) ? description.map((tag) => ({ value: tag })) : [],
        },
        isRadius: currentForm.radius === 'on',
        isSide: currentForm.side === 'on',
        is_published: currentForm.is_published || false,
        medic_id: currentForm.medic_id,
        speciality_id: currentForm.speciality_id,
        type_id: currentForm.type_id,
        steps: (currentForm.steps || []).map((step) => {
          const stepTranslation = _.get(step, ['translation', selectedLngId], {});
          return {
            id: step.id,
            number: step.number,
            translation: {
              id: stepTranslation.id || '',
              language_id: stepTranslation.language_id || selectedLngId || '',
              title: stepTranslation.title || '',
            },
            questions: (step.questions || []).map((q) => {
              const questionTranslation = _.get(q, ['translation', selectedLngId], {});
              const dataTranslation = isTemplateAlreadyTranslated
                ? _.get(q, ['translation', selectedLngId, 'data'], {})
                : _.chain(q).get(['translation', defaultLngId, 'data'], {}).mapValues((value, key) => {
                  if (['first', 'second'].includes(key)) {
                    return '';
                  } else if (key === 'list') {
                    return value.map(el => ({ ...el, label: '' }));
                  }
                  return value;
                }).value();
              const listData = questionTypeIdsCheckboxes.includes(q.type_id)
                ? dataTranslation?.list?.map((el) => {
                  return _.isObject(el) ? el : { value: el || '' };
                })
                : dataTranslation?.list;
              const data = dataTranslation
                ? listData
                  ? {
                    ...(dataTranslation || {}),
                    list: listData,
                  }
                  : { ...(dataTranslation || {}) }
                : null;
              return {
                id: q.id,
                number: q.number,
                type_id: q.type_id,
                type_name: _.get(questionTypeList, [q.type_id, 'value']),
                translation: {
                  id: questionTranslation.id || '',
                  language_id: questionTranslation.language_id || selectedLngId || '',
                  desc: questionTranslation.desc || '',
                  incompris: questionTranslation.incompris || '',
                  question: questionTranslation.question || '',
                  rewording: questionTranslation.rewording || '',
                  texte_pdf: questionTranslation.texte_pdf || '',
                  data: data,
                },
              };
            }),
          };
        }),
        survey_pdfs: (Array.isArray(currentForm.survey_pdfs) && currentForm.survey_pdfs.length) ? currentForm.survey_pdfs : (currentForm.survey_pdfs ? [currentForm.survey_pdfs] : [{ file: {} }]),
      };
      setInitialValues(newInitialValues);
    } else {
      setInitialValues();
    }

    return function cleanup() {
      setInitialValues();
    };
  }, [currentForm, selectedLngId, questionTypeList]);

  return (
    <AuthLayout title={'Gestion formulaire'} current={'7'} bread={'Formulaires:/surveys;Gestion formulaire:/surveys/add'}>
      <CreateForm initialValues={initialValues} selectedLngId={selectedLngId} readOnlyForm={readOnlyForm} canSave={canUpdateForm || canCreateForm}/>
    </AuthLayout>
  );
};

SurveysCreate.propTypes = { initialValues: PropTypes.object };


export default SurveysCreate;
