import { createReducer } from 'reduxsauce';

import { types } from './actions';
export const STEP_BEFORE = 'stepBefore';
export const STEP_AFTER = 'stepAfter';

const initialState = {
  list: [],
  loading: false,
  hasLoad: false,
  hasMore: true,
  [STEP_BEFORE]: 0,
  [STEP_AFTER]: 0,
};

const startLoader = (state = { ...initialState }) => ({
  ...state,
  loading: true,
});

const stopLoader = (state = { ...initialState }) => ({
  ...state,
  loading: false,
});

const introQuestionSuccess = (state = { ...initialState }, action = {} ) => {
  const { introductionQuestions: list } = action;
  return {
    ...state,
    list,
    loading: false,
    hasLoad: true,
  };
};

const resetReducer = () => {
  return { ...initialState };
};

const incrementStep = (state = { ...initialState }, action = {} ) => {
  const { stepBefore, stepAfter } = state;
  const { isAfter } = action;
  return {
    ...state,
    [isAfter ? STEP_AFTER : STEP_BEFORE]: (isAfter ? stepAfter : stepBefore) + 1,
    loading: false,
  };
};

const resetStep = (state = { ...initialState }) => {
  return {
    ...state,
    [STEP_BEFORE]: 0,
    [STEP_AFTER]: 0,
    loading: false,
  };
};

export default createReducer(initialState, {
  [types.RESET_REDUCER]: resetReducer,

  [types.INTRO_START_LOADER]: startLoader,
  [types.INTRO_STOP_LOADER]: stopLoader,

  [types.INTRO_FETCH_SUCCES]: introQuestionSuccess,

  [types.INTRO_FETCH_SUCCES]: introQuestionSuccess,

  [types.INCREMENT_STEP]: incrementStep,
  [types.RESET_STEP]: resetStep,


});
