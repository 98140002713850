import { createAction } from '../../utils';

export const types = {
  RESET_REDUCER: 'forms_RESET_REDUCER',

  UPDATE_DATA: 'forms_UPDATE_DATA',
  OMIT_DATA: 'forms_OMIT_DATA',
  CLEAR_DATA: 'forms_CLEAR_DATA',
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  resetReducer: () => createAction(types.RESET_REDUCER),
  updateData: (key, data) => createAction(types.UPDATE_DATA, { key, data }),
  omitData: (key) => createAction(types.OMIT_DATA, { key }),
  clearData: () => createAction(types.CLEAR_DATA),
};