import '../../form/style.css';

import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';


const ButtonChoice = ({ onPatientClick, onTutorClick }) => {
  const { t } = useTranslation();
  const handleTutorClick = () => {
    onTutorClick();
  };

  const handlePatientClick = () => {
    onPatientClick();
  };

  return (
    <div>
      <div className={'button-choice-content-body'}>
        <button className={'button-choice-cardbg-button'} onClick={handlePatientClick}>
          <div className={'button-choice-min-text'}>
            {t('patientForms.button_choice.independant.title')}
          </div>
          <div className={'button-choice-max-text'}>
            {t('patientForms.button_choice.independant.subTitle')}
          </div>
        </button>
        <button className={'button-choice-cardbg-button'} onClick={handleTutorClick}>
          <div className={'button-choice-min-text'}>
            {t('patientForms.button_choice.tutor.title')}
          </div>
          <div className={'button-choice-max-text'}>
            {t('patientForms.button_choice.tutor.subTitle')}
          </div>
        </button>
      </div>
    </div>
  );
};

ButtonChoice.propTypes = {
  onPatientClick: PropTypes.func.isRequired,
  onTutorClick: PropTypes.func.isRequired,
};

export default ButtonChoice;
