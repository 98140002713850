import { connect } from 'react-redux';

import { LanguageActions } from '../../../../redux/languages';
import MedicForm from './MedicForm';

const mapStateToProps = ({ language }) => {
  return {
    languageOptions: Object.values(language?.list || {}).map(el => ({
      value:el.id,
      label: el.name,
    })),
  };
};

const mapDispatchToProps = (dispatch) => ({ languagesRequest: () => dispatch(LanguageActions.fetchAll()) });

export default connect(mapStateToProps, mapDispatchToProps)(MedicForm);