import _ from 'lodash';
import { connect } from 'react-redux';

import { FormsActions } from '../../../../redux/forms';
import { PatientActions } from '../../../../redux/patient';
import { TYPE_TUTOR } from './definitions';
import TutorForm from './TutorForm';

const mapStateToProps = ({ patient, forms }, props) => {
  const { id, defaultValues } = props;
  const reduxValues = _.get(forms, `data.${id}`);
  const defValues = { tutorsRetained: reduxValues || defaultValues };

  const getTutorsAccordingToTheirStatus = (type, selectedPatient) => {
    if (selectedPatient.tutorsByPatientId && !selectedPatient.tutorsByPatientId.length) return [];

    return ([...selectedPatient.tutorsByPatientId].filter((filterTutor) => filterTutor.signatory.tutor_status === type,
    ).map(tutor => ({
      value: tutor.signatory.id,
      label: tutor.signatory.fullname,
      isSelected: tutor.signatory.is_selected,
    })));
  };

  const optionalTutorsOfPatient = getTutorsAccordingToTheirStatus(TYPE_TUTOR.OPTIONAL, patient.selectedPatient);
  optionalTutorsOfPatient.push({
    value: patient.selectedPatient.id,
    label: `${patient.selectedPatient.firstname} ${patient.selectedPatient.lastname}`,
    isSelected: true,
  });

  const obligatoryTutorsOfPatient = getTutorsAccordingToTheirStatus(TYPE_TUTOR.OBLIGATORY, patient.selectedPatient);

  return {
    defaultValues: defValues,
    obligatoryTutorsOfPatient,
    optionalTutorsOfPatient,
    selectedPatientId: patient.selectedPatient.id,
    medic_id: patient.selectedPatient.medic_id,
    medical_teams_id: patient.selectedPatient.medical_teams_id,
    patientId: patient.selectedPatient.id,
    patient: patient.selectedPatient,
  };
};

const mapDispatchToProps = (dispatch) => ({
  tutorCreateForPatient: (tutor, patientId) => dispatch(PatientActions.tutorCreateForPatient(
    tutor,
    patientId,
  )),
  selectedAutonomousPatient: (patientId) => dispatch(PatientActions.selectedPatientAddFormulaire(patientId)),
  updateData: (key, data) => dispatch(FormsActions.updateData(key, data)),
  omitData: (key) => dispatch(FormsActions.omitData(key)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TutorForm);