import './style.css';

import React, {
  forwardRef, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState,
} from 'react';

import { AbilityContext } from '../../../../services/casl/Can';
import { MODEL_NAMES, PERMISSIONS } from '../../../../services/casl/roles';
import i18n from '../../../../services/i18n/i18n';
import CreatePatient from '../../../components/patientFormV2/CreatePatient';
import SearchBar from '../../../components/searchBar';
import CreateSurvey from '../../../components/surveyFormV2';
import Button, { SIDEMENU_BUTTONS } from './Button';
import FilterCard from './FilterCard';
import StatCard from './StatCard';

export const searchBarTags = [
  {
    title:'medic.sidemenu.filters.local_phone_number.title',
    key: 'local_phone_number',
    type: 'phone_number',
    enumerations: null,
  },
  {
    title:'medic.sidemenu.filters.security_number.title',
    key: 'security_number',
    type: 'security_number',
    enumerations: null,
  },
  {
    title:'medic.sidemenu.filters.lastname.title',
    key: 'lastname',
    type: 'string',
    enumerations: null,
  },
  {
    title:'medic.sidemenu.filters.use_name.title',
    key: 'use_name',
    type: 'string',
    enumerations: null,
    hide: true,
  },
  {
    title:'medic.sidemenu.filters.firstname.title',
    key: 'firstname',
    type: 'string',
    enumerations: null,
  },
  {
    title:i18n.t('medic.sidemenu.filters.birthdate.title'),
    key: 'birthdate',
    type: 'date',
    enumerations: null,
  },
  {
    title:i18n.t('medic.sidemenu.filters.surveys.title'),
    key: 'surveys',
    type: 'intervention',
    enumerations: null,
  },
  {
    title:'medic.sidemenu.filters.fullName.title',
    key: 'fullName',
    type: 'string',
    enumerations: null,
    hide: true,
  },
  {
    title:'medic.sidemenu.filters.fullNameReversed.title',
    key: 'fullNameReversed',
    type: 'string',
    enumerations: null,
    hide: true,
  },
];

const numToWords = {
  1: 'one',
  2: 'two',
  3: 'three',
  4: 'four',
  5: 'five',
  6: 'six',
  7: 'seven',
};

const SideMenu = (
  {
    onFilter,
    deleteTemporarySurvey,
    onForm,
    onSearch,
    patientsList,
    temporarySurvey,
    selectedFilter,
    medicMetrics,
    statValues,
    isSelectedTeam,
    isClinicalMode,
    clearData,
  },
  ref,
) => {
  const ability = useContext(AbilityContext);
  const searchBarRef = useRef({});
  const disabledAddPatientBtn = useMemo(() => !ability.can(PERMISSIONS.READ, { __typename: MODEL_NAMES.SIDEMENU_BUTTONS, value: SIDEMENU_BUTTONS.ADD_PATIENT }), [ability]);
  const disabledAddFormToPatientBtn = useMemo(() => !ability.can(PERMISSIONS.READ, { __typename: MODEL_NAMES.SIDEMENU_BUTTONS, value: SIDEMENU_BUTTONS.ADD_FORM_TO_PATIENT }), [ability]);

  const [modalToggle, setmodalToggle] = useState({
    formType: '',
    show: false,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formated, setFormated] = useState([]);

  useImperativeHandle(ref, () => ({
    resetSearchBar() {
      onResetSearchBar();
    },
    isOpen() {
      return isModalOpen;
    },
    openModalForm() {
      handleOnOpenModalForm(SIDEMENU_BUTTONS.ADD_FORM_TO_PATIENT);
      setIsModalOpen(true);
    },
  }));

  useEffect(() => {
    // prepare patient for search bar
    if (patientsList.map(el => el.id).sort().join('') !== formated.map(el => el.id).sort().join('')) {
      const newFormated = patientsList.map((p) => {
        const ret = {
          ...p,
          fullName: p.firstname + ' ' + p.lastname,
          fullNameReversed: p.lastname + ' ' + p.firstname,
        };
        const firstnames = p.firstname.split(' ');
        const lastnames = p.lastname.split(' ');
        if (firstnames.length > 1) {
          for (let i = 0, size = firstnames.length; i < size; i++) {
            const numWord = numToWords[i + 1];
            ret[`firstnameSpecial${numWord}`] = firstnames[i] + ' ' + p.lastname;
            if (!searchBarTags.map((d) => d.key).includes(`firstnameSpecial${numWord}`)) {
              searchBarTags.push({
                title: `FirstnameSpecial${numWord}`,
                key: `firstnameSpecial${numWord}`,
                type: 'string',
                enumerations: null,
                hide: true,
              });
            }
          }
        }
        if (lastnames.length > 1) {
          for (let i = 0, size = lastnames.length; i < size; i++) {
            const numWord = numToWords[i + 1];
            ret[`lastnameSpecial${numWord}`] = p.firstname + ' ' + lastnames[i];
            if (!searchBarTags.map((d) => d.key).includes(`lastnameSpecial${numWord}`)) {
              searchBarTags.push({
                title: `LastnameSpecial${numWord}`,
                key: `lastnameSpecial${numWord}`,
                type: 'string',
                enumerations: null,
                hide: true,
              });
            }
          }
        }
        return ret;
      });
      setFormated(newFormated);
    }
  }, [patientsList && patientsList.length]);


  const handleOnOpenModalForm = (type) => {
    if (
      (type === SIDEMENU_BUTTONS.ADD_PATIENT && disabledAddPatientBtn)
      || (type === SIDEMENU_BUTTONS.ADD_FORM_TO_PATIENT && disabledAddFormToPatientBtn)
    ) return;
    setmodalToggle({ formType: type, show: true });
  };

  const handleCloseModal = () => {
    clearData && clearData();
    if (temporarySurvey && deleteTemporarySurvey) deleteTemporarySurvey();
    setmodalToggle({ ...modalToggle, show: false });
  };

  const onResetSearchBar = () => {
    if (searchBarRef && searchBarRef.current) {
      searchBarRef.current.reset();
    }
  };

  if (temporarySurvey && !isModalOpen) {
    handleOnOpenModalForm(SIDEMENU_BUTTONS.ADD_FORM_TO_PATIENT);
    setIsModalOpen(true);
  }


  return (
    <div className={'sidemenu mx-2'}>
      <SearchBar
        ref={searchBarRef}
        list={patientsList || formated}
        onSearch={onSearch}
        tags={
          Array.isArray(searchBarTags) && searchBarTags
            .filter(el => ability.can(PERMISSIONS.READ, {
              __typename: MODEL_NAMES.PATIENT_FIELDS,
              value: el.key,
            }) )
        }
        fullKeyList={['firstname', 'lastname', 'use_name', 'security_number', 'birthdate', 'phone_number', 'email', 'anonymization_number']}
      />
      <Button type={SIDEMENU_BUTTONS.ADD_PATIENT} onClick={handleOnOpenModalForm} disabled={!isSelectedTeam || disabledAddPatientBtn} />
      <Button type={SIDEMENU_BUTTONS.ADD_FORM_TO_PATIENT} onClick={handleOnOpenModalForm} disabled={!isSelectedTeam || disabledAddFormToPatientBtn} />
      <Button type={SIDEMENU_BUTTONS.FORM} onClick={onForm} />
      <FilterCard
        onFilter={onFilter}
        selectedFilter={selectedFilter}
        medicMetrics={medicMetrics}
      />
      <StatCard
        statValues={statValues}
        isClpatientsinicalMode={isClinicalMode}
      />
      { modalToggle.show && (
        <>
          { modalToggle.formType === SIDEMENU_BUTTONS.ADD_PATIENT && (
            <CreatePatient
              onCloseModal={handleCloseModal}
              isModalOpen={modalToggle.show && modalToggle.formType === SIDEMENU_BUTTONS.ADD_PATIENT}
            />
          ) }
          { modalToggle.formType === SIDEMENU_BUTTONS.ADD_FORM_TO_PATIENT && (
            <CreateSurvey
              onCloseModal={handleCloseModal}
              temporarySurvey={temporarySurvey}
              isModalOpen={modalToggle.show && modalToggle.formType === SIDEMENU_BUTTONS.ADD_FORM_TO_PATIENT}
            />
          )}
        </>)}
    </div>
  );
};

export default forwardRef(SideMenu);
