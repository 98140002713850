import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { defineAbilitiesFor, PERMISSIONS } from '../../../services/casl/roles';
import { MEDIC_ROLES, prepareContactForUpdate, prepareInfoForUpdate, TYPE_CONST, USER_ROLE } from '../../../utils';
import { patientMapper } from '../../../utils/dataMappers';
import ResetPwd from '../../screens/authentication/option/ModalRenew/ResetPwd';
import GlobalLoader from '../loaders/GlobalLoader';
import Modal from '../modal/Modal';
import ContactFormContainer from '../patientFormV2/Steps/ContactFormContainer';
import InfosFormContainer from '../patientFormV2/Steps/InfosFormContainer';

const STEP = {
  RENEW_PWD: 1,
  EDIT_INFO: 2,
  EDIT_CONTACT: 3,
};

const getReadOnlyFields = (newData) => {
  let readonlyFields = _.chain(newData).omitBy(_.isUndefined).omitBy(_.isNull).omitBy(el => el === '' || el === undefined).keys().value();
  if (readonlyFields.includes('city') && !readonlyFields.includes('city_id')) {
    readonlyFields = readonlyFields.filter(el => !['city', 'city_postcode'].includes(el));
  }
  if (readonlyFields.includes('birthplace') && !readonlyFields.includes('birthplace_id')) {
    readonlyFields = readonlyFields.filter(el => !['birthplace', 'birthplace_postcode'].includes(el));
  }
  return readonlyFields;
};

const ConnectionModals = ({
  user,
  current,
  role,
  medicUpdateEditInfo,
  createPassword,
  patientUpdateRequest,
  isLoginFromQrCode,
  abilitiesRules,
  isClinicalMode,
  setCaslRules,
  selectedTeam,
}) => {
  const { t } = useTranslation();
  const [prevRules, setPrevRules] = useState([]);
  const [stepsList, setStepsList] = useState([]);
  const [stepsListIndex, setStepsListIndex] = useState(0);
  const [data, setData] = useState({});
  const [firstStepData, setFirstStepData] = useState();

  useEffect(() => {
    const rules = defineAbilitiesFor({
      user,
      patient: !MEDIC_ROLES.includes(role) && current,
      medic: MEDIC_ROLES.includes(role) && current,

      isClinicalMode,
      medicalTeamSettings : selectedTeam?.medicalTeamSettings,
      userRules: abilitiesRules,
    },
    );
    if (!_.isEqual(prevRules, rules )){
      setCaslRules([...(rules || [])]);
      setPrevRules([...(rules || [])]);
    }
  }, [abilitiesRules, isClinicalMode, current]);

  useEffect(() => {
    const newStepsList = [];
    if ( _.isObject(user) ){
      // edit password if renew_pwd === true
      if (user.renew_pwd === true && isLoginFromQrCode === false){
        newStepsList.push(STEP.RENEW_PWD);
      }
      if (role === USER_ROLE.PATIENT && user.edit_info === false){
        // for patient, review info if edit_info === false
        newStepsList.push(STEP.EDIT_INFO);
        newStepsList.push(STEP.EDIT_CONTACT);
      }
    }
    setStepsList(newStepsList);
    setStepsListIndex(0);
  }, [user]);

  useEffect(() => {
    if (current && role === USER_ROLE.PATIENT) {
      const newData = patientMapper(current);
      const initFormValues = {
        info: {
          defaultValues: _.pick(newData, ['gender', 'security_number', 'lastname', 'use_name', 'firstname', 'birthdate', 'birthplace_id', 'birthplace', 'birthplace_postcode']),
          readonlyFields: getReadOnlyFields(newData),
        },
        contact: {
          defaultValues: _.pick(newData, ['phone_number', 'email', 'email_confirm', 'city', 'city_id', 'city_postcode']),
          readonlyFields: getReadOnlyFields(newData),
        },
      };
      setData(initFormValues);
    }
  }, [current, role]);

  const handleCreatePassword = (data) => {
    if (createPassword) {
      createPassword(data.password);
    }
  };

  const handleSubmit = (data, types) => {
    switch (types) {
      case TYPE_CONST.INFO:
        setFirstStepData(data);
        // go to next step
        setStepsListIndex(stepsListIndex + 1);
        break;
      case TYPE_CONST.CONTACT:
        switch (role) {
          case USER_ROLE.MEDIC:
            medicUpdateEditInfo(user.id);
            break;
          case USER_ROLE.PATIENT:
            const patientData = {
              ...prepareInfoForUpdate(firstStepData),
              ...prepareContactForUpdate(data),
            };
            if (patientUpdateRequest) patientUpdateRequest(current.id, patientData);
            break;
          default:
        }
        setStepsListIndex(- 1);
        break;
      default:
    }
  };

  const currentStep = _.get(stepsList, stepsListIndex);

  const getTitle = (currentStep) => {
    switch (currentStep) {
      case STEP.RENEW_PWD:
        return t('change_pwd');
      case STEP.EDIT_INFO:
        return t('verif_first_connexion');
      case STEP.EDIT_CONTACT:
        return t('verif_first_connexion');
      default:
        return '';
    }
  };

  return (
    ( !user || !stepsList.length)
      ? null
      : (
        <Modal title={getTitle(currentStep)} hideCloseBtn isOpen={stepsList.includes(currentStep) } >
          { currentStep === STEP.RENEW_PWD && (
            <div className={`${currentStep === STEP.RENEW_PWD ? '' : 'd-none'}`}>
              <ResetPwd
                id={'ConnectionModals.ResetPwd'}
                onSubmit={handleCreatePassword}
              />
            </div>
          ) }
          { currentStep === STEP.EDIT_INFO && (
            _.isEmpty(current)
              ? <GlobalLoader displayed />
              : (
                <div className={`${currentStep === STEP.EDIT_INFO ? '' : 'd-none'}`}>
                  <InfosFormContainer
                    defaultValues={_.get(data, 'info.defaultValues')}
                    readonlyFields={isClinicalMode ? [] : _.get(data, 'info.readonlyFields', [])}
                    isPatientReviewing
                    id={'connectionModal-InfosFormContainer'}
                    onSubmit={(data) => handleSubmit(data, TYPE_CONST.INFO)}
                    canPermission={PERMISSIONS.UPDATE}
                  />
                </div>
              )) }
          { currentStep === STEP.EDIT_CONTACT && (
            <div className={`${currentStep === STEP.EDIT_CONTACT ? '' : 'd-none'}`}>
              <ContactFormContainer
                defaultValues={_.get(data, 'contact.defaultValues')}
                disabledFields={isClinicalMode ? [] : _.get(data, 'contact.readonlyFields', [])}
                isPatientReviewing
                hideFields={['language_id']}
                id={'connectionModal-ContactFormContainer'}
                onSubmit={(formData) => handleSubmit(formData, TYPE_CONST.CONTACT)}
                onCancel={() => setStepsListIndex(Array.isArray(stepsList) ? stepsList.findIndex(STEP.EDIT_CONTACT) : -1)}
              />
            </div>
          ) }
        </Modal>
      ));
};

export default ConnectionModals;
