import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { IconSeeForm } from '../../../../assets/img/icons';
import { SURVEY_STATUS } from '../../../../utils';

function IconSeeRender({ element, status, index, onClick }) {
  const { t } = useTranslation();
  const isActive
    = [SURVEY_STATUS.ACCEPTED, SURVEY_STATUS.COUNTERSIGNED, SURVEY_STATUS.WITHDRAWAL].includes(status)
    || element.survey_drawing_id > 0
    || element.survey_attachment_id > 0;
  return (
    <button className="action-wrapper p-0 link" onClick={() => isActive && onClick(element, index)}>
      <img
        src={IconSeeForm}
        data-tip={isActive ? t('medicPatient.history.list.actionBtn.attachments') : ''}
        className={isActive ? '' : 'image-disabled'}
        alt={isActive ? 'image' : 'image-disabled'}
      />
    </button>
  );
}

IconSeeRender.propTypes = { element: PropTypes.object, status: PropTypes.string, onClick: PropTypes.func, index: PropTypes.number };

export default IconSeeRender;
