import { memo } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { SnackActions } from '../../../../../redux/snackBar';
import InputSelectV2 from '../../../formV3/controllers/InputSelectV2';
import { CustomFormController } from '../../../formV3/innerWrapper';
import ModalButton from '../../../formV3/submit/ModalButton';
import ModalButtonsGroup from '../../../formV3/submit/ModalButtonsGroup';
import { TYPE_TUTOR } from '../definitions';

const FIELD_NAME = {
  [TYPE_TUTOR.OBLIGATORY]: 'obligatory_tutors_of_patient',
  [TYPE_TUTOR.OPTIONAL]: 'optional_tutors_of_patient',
};

const LIMIT_ITEMS = {
  [TYPE_TUTOR.OBLIGATORY]: 3,
  [TYPE_TUTOR.OPTIONAL]: 1,
};

const EXPLAIN_BOUNDARY_ITEMS = {
  [TYPE_TUTOR.OBLIGATORY]: 'tutorForms.warning.add.obligatory',
  [TYPE_TUTOR.OPTIONAL]: 'tutorForms.warning.add.facultative',
};


function AddingTutorToNonAutonomousPatients({
  formMethods,
  obligatoryTutorsOfPatient,
  optionalTutorsOfPatient,
  handleAddTutor,
  handleCancel,
  onSubmits,
  onErrors,
  handleSubmit,
  control,
}) {
  const { t } = useTranslation();

  /**
   * @description Limits the number of items to LIMIT_ITEMS(3, 1) for obligatory and optional tutors
   * @param {*[]} tutors
   * @param {string} field
   * @return {*[]}
   */
  const limitTheNumberOfItems = (tutors, field) => {
    if(tutors.length > LIMIT_ITEMS[field]) {
      const { message, ...options } = SnackActions.displayWarning(EXPLAIN_BOUNDARY_ITEMS[field]);
      options.type = toast.TYPE.WARNING;
      toast(t(message, { limit: LIMIT_ITEMS[field] }), options);
      return tutors.slice(0, LIMIT_ITEMS[field]);
    }
    return tutors;
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmits, onErrors)} className={'custom-formV2 d-flex flex-column'}>
        <CustomFormController
          name={FIELD_NAME.OBLIGATORY}
          label={t('surveyPatientForm.guardian.obligatory.label')}
          isRequiredField={true}
        >
          <InputSelectV2 options={obligatoryTutorsOfPatient} control={control} isMulti limitTheNumberOfItems={(data) => limitTheNumberOfItems(data, TYPE_TUTOR.OBLIGATORY)} />
          <ModalButton
            label={t('patientForms.button.add_tutor.label')}
            onClick={(e) => handleAddTutor(e, TYPE_TUTOR.OBLIGATORY)}
            variant="ghost"
          />
        </CustomFormController>

        <CustomFormController
          name={FIELD_NAME.OPTIONAL}
          label={t('surveyPatientForm.guardian.facultative.label')}
        >
          <InputSelectV2 options={optionalTutorsOfPatient} control={control} isMulti limitTheNumberOfItems={(data) => limitTheNumberOfItems(data, TYPE_TUTOR.OBLIGATORY)} />
          <ModalButton
            label={t('patientForms.button.add_tutor.label')}
            onClick={(e) => handleAddTutor(e, TYPE_TUTOR.OPTIONAL)}
            variant="ghost"
          />
        </CustomFormController>
        <ModalButtonsGroup onCancel={handleCancel} submitLabel={t('form.button.next.label')}/>
      </form>
    </FormProvider>
  );
}

export default memo(AddingTutorToNonAutonomousPatients);