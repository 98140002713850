/**
 * GRAPHQL QUERIES
 */
import { MEDICTEMPLATES_CORE_FIELDS } from './medicTemplatsFragments';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getAll: () => `
    ${MEDICTEMPLATES_CORE_FIELDS}
    query getAllMedicTemplates($languageCode: String = "fr-FR") {
      medic_templates {
        ...MedicTemplatesCoreFields
      }
    }
  `,
  getFromMedicTeamId: () => `
    ${MEDICTEMPLATES_CORE_FIELDS}
    query GetFromMedicTeamId($medicTeamId: Int!, $languageCode: String = "fr-FR") {
      medic_templates(where: {medical_teams_id: {_eq: $medicTeamId}}) {
        ...MedicTemplatesCoreFields
      }
    }
  `,
  getSome: () => `
    ${MEDICTEMPLATES_CORE_FIELDS}
    query GetSome($idList: [Int!]!) {
      medic_templates(where: {id: {_in: $idList}}) {
        ...MedicTemplatesCoreFields
      }
    }
  `,
};
