import './style.css';

import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import AvatarMedicGroup from './AvatarMedicGroup';

const TeamsList = ({ data }) => {
  const { t } = useTranslation();

  return (
    data?.length
      ? data.map((d) => (
        <div key={`ec-teams-list-${d.title}`}>
          <p className="ec-teams-list-title"> {d.title} </p>
          <AvatarMedicGroup data={d.medics} limit={4} />
        </div>
      ))
      : <p className={'specialities-black-text'}>{t('no_medic_team_disclaimer')}</p>
  );
};

export default TeamsList;

TeamsList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      medics: PropTypes.arrayOf(
        PropTypes.shape({
          firstname: PropTypes.string,
          lastname: PropTypes.string,
        }),
      ),
    }),
  ),
};
