/**
 * GRAPHQL QUERIES
 */

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  fetchAllCities: () => `
    query {
      cities {
        id
        name
        postcode
      }
    }`,
  fetchCityList: () => `
    query FetchCityList(
      $postcode: String
      # , $name: String
    ) {
      cities(
        where: {
          # _or: {
          postcode: {_like: $postcode}, 
            # slug: {_ilike: $name}
          # }
        }, 
        limit: 50, 
        order_by: {
          name: asc, 
          postcode: asc
        }
      ) {
        id
        name
        postcode
      }
    }
  `,
};
