import React, { useEffect, useState } from 'react';

export default ({ labelClassName, className, value, options, name, onChange }) => {
  const [checked, setChecked] = useState(value);

  useEffect(() => {
    if (value !== checked) setChecked(value);
  }, [value]);

  const handleOnChange = (option) => {
    const newValue = !checked;
    setChecked(newValue);

    const target = { name: name, value: newValue };
    if (onChange) onChange({ target });
  };

  return (
    <>
      <label className="input-checkbox-switch">
        <input type="checkbox" checked={checked} onChange={handleOnChange} />
        <span className="input-checkbox-slider round"></span>
      </label>
    </>
  );
};
