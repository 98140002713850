
import { addMonths, addYears, endOfYear, subMonths } from 'date-fns';

import yup from '../../../../../utils/yup';

const yupSchema = yup.object().shape({
  patient_id: yup.string().required('surveyPatientForm.patient_id.rules.required'),
  clinic_id: yup.string().required('surveyPatientForm.clinic_id.rules.required'),
  medical_teams_id: yup.string().required('surveyPatientForm.medical_teams_id.rules.required'),
  medic_surveys: yup
    .array().of(
      yup.string().required('surveyPatientForm.medic_surveys.rules.required'),
    )
    .min(1, 'surveyPatientForm.medic_surveys.rules.required')
    .required('surveyPatientForm.medic_surveys.rules.required'),
  referent_medic_id: yup.string().required('surveyPatientForm.referent_medic_id.rules.required'),
  intervention: yup
    .object()
    .shape({
      date_consult: yup
        .date()
        .label('surveyPatientForm.intervention.date_consult.rules.date')
        .format('dd/MM/yyyy', 'surveyPatientForm.intervention.date_consult.rules.format')
        .min(subMonths(new Date(), 3), 'surveyPatientForm.intervention.date.rules.min')
        .max(addMonths(new Date(), 3), 'surveyPatientForm.intervention.date.rules.max')
        .required('surveyPatientForm.intervention.date_consult.rules.required'),
      date: yup
        .date()
        .label('surveyPatientForm.intervention.date.rules.date')
        .format('dd/MM/yyyy', 'surveyPatientForm.intervention.date.rules.format')
        .min(subMonths(new Date(), 1), 'surveyPatientForm.intervention.date.rules.min')
        .max(addYears(endOfYear(new Date()), 1), 'surveyPatientForm.intervention.date.rules.max')
        .required('surveyPatientForm.intervention.date.rules.required')
        .when('date_consult', (date_consult, schema) => {
          return date_consult && schema.min(date_consult, 'surveyPatientForm.intervention.date.rules.manual');
        }),
    }),
  // }, [['date', 'date_consult']] ),
});

export default yupSchema;
