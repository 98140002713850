import _ from 'lodash';
import { connect } from 'react-redux';

import { FormsActions } from '../../../../redux/forms';
import UploadForm from './UploadForm';

const mapStateToProps = ({ forms, survey, medicTemplate }, props) => {
  const { id, defaultValues } = props;
  const reduxValues = _.get(forms, `data.${id}`);
  return ({
    defaultValues:  { ...(reduxValues || defaultValues) },
    surveyTypes: _.chain(survey).get('types').values().value() || [],
    isSurveyLoading: survey.loading || survey.loadingType,
    medicTemplateList: _.chain(medicTemplate).get('list').values().value(),
  });
};

const mapDispatchToProps = (dispatch) => ({
  //
  updateData: (key, data) => dispatch(FormsActions.updateData(key, data)),
  omitData: (key) => dispatch(FormsActions.omitData(key)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadForm);