
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { InputText } from '../../../formV3/controllers';
import InputSelectV2 from '../../../formV3/controllers/InputSelectV2';
import PhoneInput from '../../../formV3/controllers/PhoneInput';
import { CustomFormController } from '../../../formV3/innerWrapper';
import CityPicker from '../../../formV3/subForm/CityPicker';
import ModalButton from '../../../formV3/submit/ModalButton';
import ModalButtonsGroup from '../../../formV3/submit/ModalButtonsGroup';
import yupSchema from './FormModel/validationSchema';

const ContactFormContainer = ({
  disabledFields = [],
  onCancel,
  defaultValues,
  onSubmit,
  isNotFinalStep,
  onAddTutor,
  hideFields = [],
  languageOptions = [],
  languagesRequest,
  isClinicalMode,
}) => {
  const { t } = useTranslation();
  // const schemaValidation = yupSchema(hideFields);
  const [shouldAddTutor, setShouldAddTutor] = useState(false);
  const [prevDefaultValues, setPrevDefaultValues] = useState();

  const isVisible = useMemo(() => ({
    language_id: !hideFields.includes('language_id'),
    phone_number: !hideFields.includes('phone_number'),
    email: !hideFields.includes('email'),
    email_confirm: !hideFields.includes('email'),
    city: !isClinicalMode && !hideFields.includes('city'),
    city_id: !isClinicalMode&& !hideFields.includes('city_id'),
    city_postcode: !isClinicalMode&& !hideFields.includes('city_postcode'),
  }), [isClinicalMode, hideFields]);

  const schemaValidation = yupSchema(isVisible);

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: useMemo(() => {
      return defaultValues;
    }, [defaultValues]),
    resolver: yupResolver(schemaValidation),
    shouldFocusError: true,
  });
  const { handleSubmit, watch, reset } = formMethods;

  useEffect(() => {
    if(!languageOptions.length) {
      languagesRequest();
    }
  }, []);

  useEffect(() => {
    if (_.isObject(defaultValues) && !_.isEqual(defaultValues, prevDefaultValues)){
      reset(defaultValues);
      setPrevDefaultValues(defaultValues);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  const onSubmits = (data) => {
    if (_.isEmpty(data.city_id)) {
      data.city_id = null;
    }
    shouldAddTutor ? onAddTutor(data) : onSubmit(data);
  };

  const handleCancel = (e) => {
    onCancel && onCancel(e);
  };

  // eslint-disable-next-line no-console
  const onErrors = (errors) => console.error(errors);

  const handleAddTutor = () => {
    setShouldAddTutor(true);
  };

  return (
    <>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmits, onErrors)} className={'custom-formV2'} >
          {isVisible.language_id && (
            <div className="row">
              <CustomFormController
                name={'language_id'}
                label={t('patientForms.language_id.label')}
                isRequiredField={true}
                isVisible={isVisible['language_id']}
              >
                <InputSelectV2 options={languageOptions} />
              </CustomFormController>
            </div>
          )}
          <CityPicker
            city={'city'}
            yupSchema={schemaValidation}
            defaultValues={defaultValues}
            readonlyFields={disabledFields}
            isVisible={isVisible}
          />
          <div className="row">
            <CustomFormController
              name={'phone_number'}
              label={t('patientForms.phone_number.label')}
              isRequiredField={true}
              isVisible={isVisible['phone_number']}
            >
              <PhoneInput disabled={disabledFields.includes('phone_number')} />
            </CustomFormController>
          </div>
          <div className="row">
            <CustomFormController
              name={'email'}
              label={t('patientForms.email.label')}
              isRequiredField={true}
              isVisible={isVisible['email']}
            >
              <InputText
                inputPreset={'email'}
                readOnly={disabledFields.includes('email')}
              />
            </CustomFormController>
          </div>

          <div className="row">
            <CustomFormController
              name={'email_confirm'}
              label={t('patientForms.email_confirm.label')}
              isRequiredField={true}
              isVisible={isVisible['email_confirm']}
            >
              <InputText
                inputPreset={'email'}
                readOnly={disabledFields.includes('email_confirm')}
              />
            </CustomFormController>
          </div>
          { onAddTutor && isNotFinalStep && (
            <ModalButton
              label={t('patientForms.button.add_tutor.label')}
              type="submit"
              onClick={handleAddTutor}
              variant="ghost"
            />
          )}
          <ModalButtonsGroup onCancel={handleCancel} submitLabel={t('form.button.send.label')} />
        </form>
      </FormProvider>

      {!!process.env.REACT_APP_DEBUG_FORM && (
        <pre>
          { JSON.stringify(watch(), null, 2) }
        </pre>
      )}
    </>
  );
};

ContactFormContainer.propTypes = { title: PropTypes.string };

export default ContactFormContainer;
