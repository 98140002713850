import { addDays, format } from 'date-fns';


export const INITIAL_SURVEY_FORM = {
  patient_id: {},
  clinic_id: {},
  medical_teams_id: {},
  medic_surveys: [],
  referent_medic_id: {},
  intervention: {
    date_consult: format(new Date(), 'yyyy-MM-dd'),
    date:  format(addDays(new Date(), 16), 'yyyy-MM-dd'),
  },
};