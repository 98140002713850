import _ from 'lodash';
import { useEffect, useState } from 'react';

const useScript = (params) => {
  const [script, setScript] = useState(null);

  useEffect(() => {
    if (process.env.REACT_APP_SHOW_DESK) {
      var newScript = document.createElement('script');
      _.keys(params).forEach((key) => {
        newScript[key] = params[key];
      });
      if (!script && newScript !== script) {
        setScript(newScript);
        document.getElementsByTagName('head')[0].appendChild(newScript);
      }
    }
    return function clean() {
      if (script && document.body && document.body.contains(script)) {
        document.body.removeChild(script);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);
};

export default useScript;
