/**
 * GRAPHQL QUERIES
 */

import { PATIENT_CORE_FIELDS, PATIENT_MINIMAL_FIELDS } from './patientFragments';
import { SURVEYS_CORE_FIELDS, SURVEYS_CORE_FIELDS_FOR_PATIENT } from './surveyFragments';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  patientsByIds: `
    ${PATIENT_MINIMAL_FIELDS}
    query MedicPatientsByIds(
      $ids: [Int!]!,
      $_gte: timestamptz!
    ) {
      patients(where: {
        id: {_in: $ids},
        is_tutor: {_eq: false},
        is_archived: {_eq: false},
      }) {
        ...patientsMinimalFields
        surveys(
          order_by: {intervention: {date: desc}}, 
          where: {
            intervention: {date: {_gte: $_gte}},
            is_archived: {_eq: false},
        },
          # limit: 1
        ) {
          id
          title
          is_signed
          status
          intervention {
            date
            number
          }
        }
        tutorsByPatientId {
          signatory {
            ...patientsMinimalFields
          }
        }
      }
    }
  `,
  patients: `
    ${PATIENT_MINIMAL_FIELDS}
    ${PATIENT_CORE_FIELDS}
    query MedicPatients($_gte: timestamptz!) {
      patients(where: {
        is_tutor: {_eq: false},
        is_archived: {_eq: false},
      }) {
        ...patientsMinimalFields
        surveys(
          order_by: {intervention: {date: asc}}, 
          where: {
            intervention: {date: {_gte: $_gte}},
            is_archived: {_eq: false}
          },
          # limit: 1
        ) {
          id
          title
          is_signed
          status
          medical_teams_id 
          patient_id
          referent_medic_id
          
          intervention {
            date
            number
          }
          survey_type {
            should_countersign
            can_withdraw
          }
        }
        tutorsByPatientId {
          signatory {
            ...patientsCoreFields
          }
        }
      }
    }
  `,
  patient: `
    ${SURVEYS_CORE_FIELDS}
    ${PATIENT_CORE_FIELDS}
    query GetPatientFromId($id: Int!) {
      patients(where: {
        id: {_eq: $id},
        is_archived: {_eq: false},
      }) {
        ...patientsCoreFields
        tutorsByPatientId {
          signatory {
            ...patientsCoreFields
          }
        }
        surveys(order_by: {intervention: {number: desc}}) {
          ...surveysCoreFields
          clinic {
            name
          }
          updated_at
        }
      }
    }
  `,
  // fetch patient and tutors from user_id. the 1st query before to fetch details
  current: `
    ${SURVEYS_CORE_FIELDS_FOR_PATIENT}
    ${PATIENT_CORE_FIELDS}
    query CurrentPatient {
      patients {
        ...patientsCoreFields
        tutors {
          patientByPatientId {
            gender
            firstname
            lastname
          }
        }
        surveys(order_by: {intervention: {number: desc}}, where: {is_archived: {_eq: false}}) {
          ...surveysCoreFieldsForPatient
        }
        user {
          ...usersCoreFields
        }
      }
    }
  `,
  tutored: `
    query {
      tutors {
        patientByPatientId {
          firstname
          lastname
          use_name
          birthdate
          security_number
          gender
          surveys {
            id
            title
            is_signed
            status
            tutored_id
            survey_drawing_id
            survey_type {
              id
              value
            }
            survey_drawing {
              loid
              ext
            }
            survey_attachment_id
            survey_attachment {
              loid
              ext
            }
            intervention {
              id
              date
              number
              type
            }
          }
        }
      }
    }
  `,
  patientFromUserId: `
    ${PATIENT_CORE_FIELDS}
    query PatientFromUserId($userId: Int!) {
      patients(order_by: {created_at: desc}, limit: 1, where: {user_id: {_eq: $userId}, is_archived: {_eq: false}}) {
        ...patientsCoreFields
      }
    }
  `,
};
