
import { Buffer } from 'buffer';
import _ from 'lodash';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { AuthenticationService, ConnecteurService, UserService } from '../../services';
import i18n, { changeLanguage } from '../../services/i18n/i18n';
import { isProduction, PATIENT_LOGIN_PATH, PRO_LOGIN_PATH } from '../../utils/const';
import { validEmailRegex } from '../../utils/regex';
import { insertParam } from '../../utils/router-helper';
import { isNullOrWhitespace } from '../../utils/utils';
import { CommonActions } from '../common';
import { DisplayActions } from '../display';
import { FormsActions } from '../forms';
import { LoaderActions } from '../loader';
import { MedicActions } from '../medic';
import { PatientActions } from '../patient';
import { SnackActions } from '../snackBar';
import { SplashActions } from '../splash';
import { default as AuthActions, types } from './actions';

function* getLoginInfo() {
  const { token } = yield select((state) => state.auth);
  if (!token) return;
  // using token from auto login link to verify if token is viable
  // const { token, refresh } = tokens;
  // const isUsernameTheUserId = token && token.length > 0;
  // when coming from Splash / case medicConnect, username is userId
  const [error, response] = yield call(AuthenticationService.getLoginInfo);
  if (error) {
    yield put(AuthActions.getLoginInfoError());
    yield put(SnackActions.displayError('link_is_expired'));
    return [error, response];
  }
  const { user, medic, patients } = response.data;
  if (!user) {
    yield put(AuthActions.logout());
    yield put(SnackActions.displayError('authentication_failed'));
    yield put(LoaderActions.loaded());
  } else {
    // set user
    yield put(AuthActions.userSuccess(user));

    if (medic) {
      yield put(MedicActions.currentMedicSuccess(medic));
      yield put(MedicActions.fetchMedicTeamData());
      const { selectedTeam } = yield select((state) => state.medic);
      if (selectedTeam || medic.connector_medical_teams_id) {
        yield put(MedicActions.changeSelectedMedicTeamRequest(selectedTeam || medic.connector_medical_teams_id));
      }
      changeLanguage(user?.language?.language_code);
      yield put(AuthActions.axigateActionOnLogin());
    }

    if (Array.isArray(patients)) {
      // get patient with most pending signature for selecting it
      const patientIndex = patients.reduce((iMax, x, i, arr) => (x.unSignedSurveysCoun > arr[iMax].unSignedSurveysCount ? i : iMax), 0);
      const defaultPatient = patients[patientIndex];
      changeLanguage(defaultPatient?.language?.language_code);
      yield put(PatientActions.patientCurrentDetailSuccess(patients, patientIndex));
      const medicalTeam = defaultPatient?.medicalTeam;
      yield put(AuthActions.saveAbilitiesRules(medicalTeam?.role?.permissions || [], 'medicalTeam'));
      yield put(DisplayActions.setAppMode(medicalTeam?.is_clinical_study || false));
    }
    const userPermissions = (user?.users_roles || []).reduce((acc, val) => [...acc, ...(val?.role?.permissions || [])], []);
    yield put(AuthActions.saveAbilitiesRules(userPermissions, 'user'));
    // is user role is the requested one
    yield put(AuthActions.getLoginInfoSuccess());
  }
  return [error, response];
}

function* getToken({ username, password, code, otp, uuid }) {
  const [error, response] = yield call(AuthenticationService.getToken, { username, password, code, otp, uuid });

  if (response && response.data) {
    if (response?.data?.error === 'invalid_grant'){
      yield put(AuthActions.logout());
      return;
    }
    const { access_token, token_type, refresh_token, username: us } = response.data;
    const authorization = Buffer.from(`${username}:${password}`).toString('base64');
    yield put(AuthActions.loginSuccess(token_type, access_token, refresh_token, authorization, us) );
    yield put(AuthActions.clearLastSmsSent());
  }
  return [error, response];
}

function* axigateActionOnLogin() {
  // #######################################
  // #       CONNECTEUR axigate
  // #######################################
  // Redirect to Connect if there is a redirectPayload in Redux auth
  const { redirectPayload } = yield select((state) => state.splash);
  const { token } = yield select((state) => state.auth);
  if (token && redirectPayload) {
    const params = { redirectPayload: redirectPayload.payload };
    const { destinationPath } = redirectPayload;
    const [error, response] = yield call(
      ConnecteurService.axigateFromFront,
      {
        destinationPath,
        params,
      },
    );
    if (response && response?.data?.success) {
      const { data: { url } } = response;
      yield put(SplashActions.clearRedirectPayload());
      yield put(AuthActions.setRedirectUrl(url));
    } else {
      yield put(LoaderActions.loaded());
      yield put(SnackActions.displaySnackBar());
      yield put(AuthActions.loginFailure());
      if (error) {
        const errorData = error?.response?.data?.message || 'error';
        yield put(SnackActions.displayError(errorData, 100000));
      }
      yield put(SplashActions.clearRedirectPayload());
    }
  }
}

function* loginFromToken({ token_type, access_token, refresh_token, username }) {
  yield put(LoaderActions.loading());
  yield put(SplashActions.clearTokens());
  yield put(AuthActions.userLoading());
  yield put(AuthActions.loginSuccess(token_type, access_token, refresh_token, null, username));
  const { isLoginFromQrCode } = yield select((state) => state.splash);
  yield put(AuthActions.setIsLoginFromQrCode(isLoginFromQrCode || false));
  yield put(AuthActions.getLoginInfo());
  yield put(LoaderActions.loaded());
}


function* authOtpRequest({ username, password, code }) {
  // get OTP code
  const [error, response] = yield call(AuthenticationService.getOtpCode, { username, password, code });
  if (error) {
    yield put(SnackActions.displayError('authentication_failed'));
    yield put(AuthActions.logout());
  } else {
    // yield put(AuthActions.getLoginInfo());
    const { uuid, code: otp } = response.data;
    if (uuid) {
      yield put(AuthActions.authorizationSuccess(username, password, code, uuid, otp));
      if (!isProduction && otp) {
        yield put(SnackActions.displayInfo(otp));
      }
    } else {
      const {
        username,
        password,
        code,
        uuid,
      } = yield select((state) => state.auth.oauth);
      const [error] = yield call(getToken, { username, password, code, uuid, otp });
      if (error) {
        yield put(SnackActions.displayError('authentication_failed'));
        yield put(AuthActions.logout());
      } else {
        yield put(AuthActions.getLoginInfo());
      }
    }
  }
}

function* authorizationRequest({ username, password }) {
  yield put(LoaderActions.loading());
  yield put(AuthActions.userLoading());
  // email toLowerCase if it is an email
  const trimedEmail = username.trim();
  if (trimedEmail.match(validEmailRegex)) {
    username = trimedEmail.toLowerCase();
  }
  const [error, response] = yield call(AuthenticationService.authorizationRequest, { username, password });
  if (response?.request?.responseURL) {
    const url = new URL(response.request.responseURL);
    const urlParams = new URLSearchParams(url.search);
    const code = urlParams.get('code');
    const errorParams = urlParams.get('error');
    if (errorParams || !code) {
      yield put(AuthActions.loginFailure());
      yield put(SnackActions.displayError('authentication_failed'));
    } else {
      // get OTP code
      yield put(AuthActions.authOtpRequest(username, password, code));
    }
  } else {
    yield put(AuthActions.loginFailure());
    let message = _.get(error, 'response.data.message');
    if (!message || message === 'not allowed') {
      message = 'invalid_credentials';
    } else {
      message = `errors.${message}`;
    }
    yield put(SnackActions.displayError(message));
  }

  yield put(LoaderActions.loaded());
}


function* loginFromOtpRequest({ otp }) {
  const {
    username,
    password,
    code,
    uuid,
  } = yield select((state) => state.auth.oauth);
  const [error] = yield call(getToken, { username, password, code, uuid, otp });
  if (error) {
    yield put(SnackActions.displayError('authentication_failed'));
  } else {
    yield put(AuthActions.getLoginInfo());
  }
}


function* loginRequest({ username, password }) {
  yield put(LoaderActions.loading());
  yield put(AuthActions.userLoading());
  // email toLowerCase if it is an email
  const trimedEmail = username.trim();
  if (trimedEmail.match(validEmailRegex)) {
    username = trimedEmail.toLowerCase();
  }
  const [error, response] = yield call(AuthenticationService.authorize, { username, password });
  if (!error && response && response.request.responseURL) {
    const url = new URL(response.request.responseURL);
    const urlParams = new URLSearchParams(url.search);
    const code = urlParams.get('code');
    const errorParams = urlParams.get('error');
    if (errorParams || !code){
      yield put(AuthActions.loginFailure());
      // yield put(SnackActions.displayError(error));
      yield put(SnackActions.displayError('authentication_failed'));
    } else {
      const [tokenErr] = yield call(getToken, { username, password, code });
      if (tokenErr) {
        yield put(AuthActions.logout());
        return;
      }
      yield put(AuthActions.getLoginInfo());
    }
  } else if (error) {
    yield put(AuthActions.loginFailure());
    let message = _.get(error, 'response.data.message');
    if (!message || message === 'not allowed') {
      message = 'invalid_credentials';
    } else {
      message = `errors.${message}`;
    }
    yield put(SnackActions.displayError(message));
  }
  yield put(LoaderActions.loaded());
}

/**
 * Warning: This code is not used. The current refresh action is in middleware
 * @returns {Generator<*, string[]|*[], ?>}
 */
function* refresh() {
  const { refresh_token, authorization } = yield select((state) => state.auth);
  const [error, response] = yield call(AuthenticationService.refreshToken, {
    authorization,
    refresh_token,
  });
  if (response && response.data.access_token) {
    const { access_token: token, token_type, refresh_token: refreshToken } = response.data;
    yield put(AuthActions.loginSuccess(token_type, token, refreshToken, authorization));
    return [null, `${token_type} ${token}`];
  } else return [error, null];
}

function* forgotPwd({ username, isPro, email, phoneNumber, birthdate }) {
  const [error, response] = yield call(AuthenticationService.getCode, { username, isPro, email, phoneNumber, birthdate });
  if (!error && response) {
    const { uuid, code } = response.data;
    yield put(AuthActions.setLastSmsSent(uuid));
    if (!isNullOrWhitespace(code)) {
      yield put(SnackActions.displayInfo(code));
    }
  } else {
    if (error?.response?.data?.message && ['too_many_attempt_please_try_again_later', 'too_many_user_matching_query', 'error_with_code'].includes(error.response.data.message)) {
      yield put(SnackActions.displayError(error.response.data.message));
    } else {
      yield put(SnackActions.displayError('an_error_occurs_getting_the_code'));
    }
  }
}

function* checkCode({ code, action }) {
  if (isNullOrWhitespace(code)){
    return;
  }
  const { uuid } = yield select((state) => state.auth.sms);
  const [error, response] = yield call(AuthenticationService.checkCode, { code, uuid, action });
  if (!error && response?.data?.success === true) {
    yield put(AuthActions.checkCodeSuccess());
    yield put(AuthActions.clearLastSmsSent());
  } else {
    yield put(AuthActions.checkCodeFailure());
    yield put(SnackActions.displayError('authentication_wrong_code'));
  }
}

function* changePwd({ newPwd, code }) {
  const { uuid } = yield select((state) => state.auth.sms);
  const [error, response] = yield call(AuthenticationService.changePwd, { password: newPwd, code, uuid });
  if (!error && response?.data?.success) {
    const {
      username,
      isPro,
    } = response.data;
    yield put(SnackActions.displayInfo('reset_pwd_success'));
    const newUrlParams = insertParam(new URLSearchParams(), [
      { key: 'username', value: username },
    ].filter(el => !!el.value));
    yield put(AuthActions.setRedirectUrl(`${isPro ? PRO_LOGIN_PATH : PATIENT_LOGIN_PATH}?${newUrlParams.toString()}`));
  } else {
    yield put(SnackActions.displayError('an_error_occurs_updating_password'));
  }
}

function* createPwd({ newPwd }) {
  const [error, response] = yield call(AuthenticationService.createPwd, { newPwd });
  if (!error && response) {
    yield put(AuthActions.passwordSuccess());
    yield put(AuthActions.renewInfos(true));
    yield put(SnackActions.displayInfo('authentication_pwd_changed'));
  } else {
    yield put(SnackActions.displayError('an_error_occurs_updating_password'));
  }
}


function* disabledToken() {
  const [error, response] = yield call(AuthenticationService.logout);
  if (response && !error){
    yield put(AuthActions.disabledTokenSuccess());
  }
}


function* logout({ displayError, newState, toUrl, isManualLogout }) {
  yield put(AuthActions.disabledToken());
  if (!displayError) {
    // eslint-disable-next-line no-console
    console.log( i18n.t('logout_success'));
    // yield put(SnackActions.displayInfo('logout_success'))
  };
  if (isManualLogout) {
    yield put(CommonActions.resetAllReducers());
  } else {
    yield put(CommonActions.resetReducers());
    yield put(AuthActions.logoutSuccess({ ...(newState ||{}), redirectUrl: toUrl }));
  }
}

function* ipRequest() {
  const [error, response] = yield call(AuthenticationService.ip);
  if (!error && response && response.status === 200) {
    yield put(AuthActions.ipSuccess(response.data));
  }
}

function* updateUserEditInfoRequest({ userId }) {
  const [error, response] = yield call(UserService.patchUser, userId, { edit_info: true });
  if (error) {
    yield put(SnackActions.displayError('medic_fetch_metrics_error'));
  } else if (response) {
    yield put(AuthActions.editInfoSuccess());
  }
}

function* cguApprove({ userId }) {
  const [error, response] = yield call(UserService.patchUser, userId, { accepted_cgu: true });
  if (error) {
    yield put(SnackActions.displayError('medic_fetch_metrics_error'));
  } else if (response) {
    yield put(AuthActions.cguSuccess());
  }
}

function* rgpdApprove({ userId }) {
  const [error, response] = yield call(UserService.patchUser, userId, { accepted_rgpd: true });
  if (error) {
    yield put(SnackActions.displayError('medic_fetch_metrics_error'));
  } else if (response) {
    yield put(AuthActions.rgpdSuccess());
  }
}

function* deleteCurrentToken() {
  const [error, response] = yield call(AuthenticationService.deleteToken);
  if (!error && response && response.status === 200 && response.data) {
    const { value, refresh_token } = response.data;
    yield put(AuthActions.setTokens(value, refresh_token));
  }
}

function* attachUserCodeRequest({ formData }) {
  const { username, password, code, uuid } = formData;
  if (!username || !password) {
    return;
  }
  if (code) {
    const [error, response] = yield call(AuthenticationService.attachUserCodeVerify, username, password, code, uuid);
    yield put(FormsActions.clearData('attachUserCode'));
    if (!error && response && response.status === 200 && response.data) {
      yield put(FormsActions.updateData('attachUserCode', { success: true }));
      yield put(SnackActions.displayInfo('success'));
    }
  } else {
    // it is code request
    const [error, response] = yield call(AuthenticationService.attachUserCodeRequest, username, password);
    if (!error && response && response.status === 200 && response.data) {
      const { uuid, code } = response.data;
      yield put(FormsActions.updateData('attachUserCode', { username, password, uuid }));
      if (!isNullOrWhitespace(code)) {
        yield put(SnackActions.displayInfo(code));
      }
    }
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  takeLatest(types.AUTHORIZATION_REQUEST, authorizationRequest),
  takeLatest(types.AUTH_OTP_REQUEST, authOtpRequest),
  takeLatest(types.LOGIN_FROM_OTP_REQUEST, loginFromOtpRequest),

  takeLatest(types.LOGIN_REQUEST, loginRequest),
  takeLatest(types.LOGIN_REFRESH, refresh),
  takeLatest(types.LOGOUT, logout),
  takeLatest(types.CHANGE_PASSWORD, changePwd),
  takeLatest(types.CREATE_PASSWORD, createPwd),
  takeLatest(types.CHECK_CODE, checkCode),
  takeLatest(types.FORGOT_PASSWORD, forgotPwd),
  takeLatest(types.IP_REQUEST, ipRequest),

  takeLatest(types.EDIT_INFO_REQUEST, updateUserEditInfoRequest),

  takeLatest(types.GET_LOGIN_INFO, getLoginInfo),

  takeLatest(types.APPROVED_CGU, cguApprove),
  takeLatest(types.APPROVED_RGPD, rgpdApprove),

  takeLatest(types.DELETE_CURRENT_TOKEN_REQUEST, deleteCurrentToken),
  takeLatest(types.DISABLED_TOKEN, disabledToken),

  takeLatest(types.AXIGATE_ACTION_ON_LOGIN, axigateActionOnLogin),
  takeLatest(types.LOGIN_FROM_TOKEN, loginFromToken),

  takeLatest(types.ATTACH_USER_CODE_REQUEST, attachUserCodeRequest),
];
