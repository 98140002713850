// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Dropzone.jsx */
.consent-step-dropzone-container {
  height: 75px;
  border-radius: 14px;
  border: dashed 3px rgba(0, 0, 0, 0.07);
  background-color: rgba(222, 222, 222, 0.07);
  margin-left: 28px;
  margin-bottom: 28px;
  margin-right: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
}

.consent-step-dropzone-container-text {
  font-size: 16px;
  font-weight: normal;
  line-height: 2.13;
  color: #79828b;
  margin-bottom: 0px;
  z-index: 1;
}

.consent-step-mobile-items {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}

.consent-step-mobile-items > div {
  margin: 0 !important;
}

.consent-step-mobile-items > div > p {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/ui/screens/createFormV2/Step/Dropzone/style.css"],"names":[],"mappings":"AAAA,iBAAiB;AACjB;EACE,YAAY;EACZ,mBAAmB;EACnB,sCAAsC;EACtC,2CAA2C;EAC3C,iBAAiB;EACjB,mBAAmB;EACnB,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,UAAU;AACZ;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,iBAAiB;EACjB,cAAc;EACd,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,6BAA6B;EAC7B,WAAW;AACb;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,aAAa;AACf","sourcesContent":["/* Dropzone.jsx */\n.consent-step-dropzone-container {\n  height: 75px;\n  border-radius: 14px;\n  border: dashed 3px rgba(0, 0, 0, 0.07);\n  background-color: rgba(222, 222, 222, 0.07);\n  margin-left: 28px;\n  margin-bottom: 28px;\n  margin-right: 30px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  z-index: 0;\n}\n\n.consent-step-dropzone-container-text {\n  font-size: 16px;\n  font-weight: normal;\n  line-height: 2.13;\n  color: #79828b;\n  margin-bottom: 0px;\n  z-index: 1;\n}\n\n.consent-step-mobile-items {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-around;\n  width: 100%;\n}\n\n.consent-step-mobile-items > div {\n  margin: 0 !important;\n}\n\n.consent-step-mobile-items > div > p {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
