import './radioMultiQuestion.css';

import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { generateUniqKey } from '../utils/generateUniqKey';
import { FormattedTableData } from '../utils/questionGroupByType';

export function PreviewRadioMultiQuestionsTable({ questions }) {

  const matrixTable = useMemo(() => {
    const formattedQuestions = new FormattedTableData(questions);
    return formattedQuestions.getTableData();
  }, [questions]);

  return (
    <div className="container__table">
      {
        matrixTable.map((data) =>
          <table>
            {
              data.map((values, index) => {
                if (index === 0) {
                  return <thead key={generateUniqKey(7)}>
                    <tr>
                      <td>&nbsp;</td>
                      {
                        values.map((header) => <td key={generateUniqKey(7)}>{header}</td>)
                      }
                    </tr>
                  </thead>;
                } else {
                  return <tbody key={generateUniqKey(7)}>
                    <tr>
                      {
                        values.map((value) => <td
                          key={generateUniqKey(7)}>{value === true ? String.fromCharCode(8226) : value}</td>)
                      }
                    </tr>
                  </tbody>;
                }

              })
            }
          </table>,
        )
      }
    </div>

  );
}

PreviewRadioMultiQuestionsTable.propTypes = { questions: PropTypes.array.isRequired };