import { connect } from 'react-redux';

import { AuthActions } from '../../redux/auth';
import LoginFromToken from './LoginFromToken';


const mapStateToProps = ({ auth, splash }) => {
  return {
    isLogoutSuccess: auth.isLogoutSuccess,
    splash: splash,
  };
};

const mapDispatchToProps = (dispatch) => ({ loginFromToken: (accessToken, refreshToken, username) => dispatch(AuthActions.loginFromToken(accessToken, refreshToken, username)) });


export default connect(mapStateToProps, mapDispatchToProps)(LoginFromToken);
