/**
 * @important: The order of the steps is important, it must be respected and must be modified with care
 * @type {{PATIENT_REPRESENTATIVE: string, DATE_WARNING: string, UPLOAD: string, PATIENT: string, TEMPLATE: string, SUP_INFO: string}}
 */
export const STEPS = {
  PATIENT: 'PATIENT',
  PATIENT_REPRESENTATIVE: 'PATIENT_REPRESENTATIVE',
  DATE_WARNING: 'DATE_WARNING',
  TEMPLATE: 'TEMPLATE',
  SUP_INFO:'SUP_INFO',
  UPLOAD: 'UPLOAD',
};

export const STEP_PATIENT_REPRESENTATIVE_INDEX = 2;
export const DELETING_PATIENT_REPRESENTATIVE_INDEX = STEP_PATIENT_REPRESENTATIVE_INDEX - 1;
export const ADDING_A_PATIENT_REPRESENTATIVE_INDEX = STEP_PATIENT_REPRESENTATIVE_INDEX + 1;
