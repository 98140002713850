import './loader.scss';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ClearCache from '../../ClearCache';

const GlobalLoader = ({ displayed, infinitLoading }) => {
  const { t } = useTranslation();
  const [count, setCount] = useState(0);
  const [showBtn, setShowBtn] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (displayed) {
        setCount((count) => count + 1);
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (displayed) {
      setCount(0);
    }
  }, [displayed]);

  useEffect(() => {
    setShowBtn(!infinitLoading && count > 60);
  }, [count]);

  return (
    <div className="d-flex justify-content-center w-100 h-100" >
      <div className={`loader-wrapper ${displayed ? 'loading' : 'loaded'}`}>
        {showBtn
          ? (
            <div className={'alert-box'}>
              <div>{t('error_detected_please_empty_cache')}</div>
              <ClearCache />
            </div>
          ) : (
            <div className={'folding-cube'}>
              <div className={'cube1 cube'} />
              <div className={'cube2 cube'} />
              <div className={'cube4 cube'} />
              <div className={'cube3 cube'} />
            </div>
          )}
      </div>
    </div>
  );
};

export default GlobalLoader;