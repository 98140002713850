// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ec-consentheader-description-tag-remove {
  border: none;
  background: none;
  color: #1890ff;
}

.ec-consentheader-description-tag-remove:after {
  content: 'x';
}

.ec-consentheader-description-tag > input {
  border: none;
  background: none;
  width: 0px;
}

.ReactTags__selected {
  display: flex;
}
`, "",{"version":3,"sources":["webpack://./src/ui/components/formV3/controllers/InputTagsV3/style.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,UAAU;AACZ;;AAEA;EACE,aAAa;AACf","sourcesContent":[".ec-consentheader-description-tag-remove {\n  border: none;\n  background: none;\n  color: #1890ff;\n}\n\n.ec-consentheader-description-tag-remove:after {\n  content: 'x';\n}\n\n.ec-consentheader-description-tag > input {\n  border: none;\n  background: none;\n  width: 0px;\n}\n\n.ReactTags__selected {\n  display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
