import { methods } from '../utils';
import { templateQueries } from './GraphQLRequest';
import { authenticatedQuery, authenticatedService } from './middleware';

const { POST } = methods;

const PROXY_API_URL = process.env.REACT_APP_PROXY_API || process.env.REACT_APP_BASE_API_URL || '/api';

const routes = { createMedicTemplatesFromStoreId: `${PROXY_API_URL}/v2/medicTemplates/fromStoreId` };

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getAll: (languageIds) => authenticatedQuery(templateQueries.getAll(), {}, { languageIds }),
  getDetails: (templateId = null) => authenticatedQuery(templateQueries.getDetails(), {}, { templateId }),

  createMedicTemplatesFromStoreId: (storeId, medicalTeamId) => authenticatedService(POST, routes.createMedicTemplatesFromStoreId, { storeId, medicalTeamId }, {}, {}),
};
