/**
 * GRAPHQL MUTATIONS
 */

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  linkTutors: (links) => `
    mutation {
      insert_tutors(objects: [${links
    .map((l) => `{guardian_id: ${l.guardian_id}, patient_id: ${l.patient_id} }`)
    .join(',')}]) {
        returning {
          patient {
            id
          }
          patientByPatientId {
            id
          }
        }
      }
    }`,
  patchPatient: () => `
    mutation PatchPatient($patientId: Int!, $patientData: patients_set_input!) {
      update_patients(
        where: { id: {_eq: $patientId} },
        _set: $patientData
      ) {
        returning {
          id
        }
      }
    }
  `,
  updateCGU: (id) => `
    mutation {
      update_users(where: {id: {_eq: ${id}}}, _set: {accepted_cgu: true}) {
        returning {
          id
          accepted_cgu
        }
      }
    }
  `,
  updateRGPD: (id) => `
    mutation {
      update_users(where: {id: {_eq: ${id}}}, _set: {accepted_rgpd: true}) {
        returning {
          id
          accepted_rgpd
        }
      }
    }
  `,
  updateEditInfo: () => `
    mutation UpdateEditInfo($id: Int!) {
      update_users(where: {id: {_eq: $id}}, _set: {edit_info: true}) {
        returning {
          id
          edit_info
        }
      }
    }
  `,
};
