
// eslint-disable-next-line import/no-anonymous-default-export
export const MEDICTEMPLATES_CORE_FIELDS = `
 fragment MedicTemplatesCoreFields on medic_templates {
    id
    created_at
    type_id
    speciality_id
    store_id
    is_published
    medic_id
    medical_teams_id
    translation: medic_templates_translations {
      id
      medic_template_id
      language_id
      title
      description
    }
    medic {
      lastname
    }
    side
    radius
    survey_pdfs {
      loid
      id
      name
    }
  }
`;
