import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { IconDelete } from '../../../../assets/img/icons';
import { AbilityContext } from '../../../../services/casl/Can';
import { CONSENT_COMPONENT, MODEL_NAMES, PERMISSIONS } from '../../../../services/casl/roles';
import { isNullOrWhitespace } from '../../../../utils/utils';
import Card from '../../../components/card';
import { InputText } from '../../../components/formV3/controllers';
import ErrorMessage from '../../../components/formV3/errors/ErrorMessage';
import { SurveyFormController } from '../../../components/formV3/innerWrapper';
import { Button } from '../../../components/styledComponent';
import { INITIAL_QUESTION } from '../FormModel/formInitialValues';
import QuestionComponent from '../QuestionTypes/QuestionComponent';
import Dropzone from './Dropzone';
import styles from './style.module.css';
/*
  disabled is tricky because medics and patients can update respectiv part of the form
  so disabled = false means medic can edit it, = true means patient can edit it
*/
const Step = ({
  control, onDeleteStep, disabled, onNext, onCancel, onPrevious,
  prefixName, index, step, createMode, questionTypes, currentSurvey,
  isConsent, readOnly,
  consentPreview,
}) => {
  const prefix = `${prefixName ? `${prefixName}.` : ''}`;
  const translationPrefix = `${prefix}translation.`;
  const TITLE_NAME = `${translationPrefix}title`;
  const methods = useFormContext();
  const { t } = useTranslation();
  const ability = useContext(AbilityContext);
  const { fields, remove, append } = useFieldArray({
    // const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control: control || methods.control, // control props comes from useForm (optional: if you are using FormContext)
    name: `${prefix}questions`, // unique name for your Field Array
    keyName: 'key', // When your array field contains objects with the key name id, useFieldArray will overwrite and remove it. If you want to keep the id field in your array of objects, you must use keyName prop to change to other name.
  });

  const [editOnClick, setEditOnClick] = useState(false);
  const { setFocus } = methods;


  const titleWatch = useWatch({
    control: control || methods.control,
    name: TITLE_NAME, // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
    //defaultValue: 'defaut' // default value before the render
  });

  useEffect(() => {
    if (editOnClick) setFocus(TITLE_NAME);
  }, [editOnClick]);

  const onAddQuestion = (type) => {
    type = JSON.parse(type);
    const newQ = INITIAL_QUESTION(type, fields.length + 1, step.id);
    append(newQ);
  };

  const handleDeleteQuestion = (index) => {
    remove(index);
  };

  const handleOnCancel = () => {
    if (onCancel) onCancel();
  };

  const handleOnNext = () => {
    if (onNext) onNext();
  };

  const handlePrevious = () => {
    if (onPrevious) onPrevious();
  };

  const handleDisplayInput = (event) => {
    handleEditOnClick(event, true);
  };

  const handleHideInput = (event) => {
    handleEditOnClick(event, false);
  };

  const handleEditOnClick = (event, value = !editOnClick) => {
    if (disabled) return;
    event && event.preventDefault();
    setEditOnClick(value);
  };

  const handleTitleKeyPress = (event) => {
    event && event.preventDefault();
    if (event && event.key === 'Enter') {
      handleDisplayInput(event);
    }
  };

  return (
    <div className="consent-step-card-container mx-2" >
      <Card>
        <div className=' mx-5'>
          <div className="d-flex justify-content-between align-items-center">
            { isConsent
              ? <div className={styles.title}>{step.translation.title}</div>
              : <>
                <SurveyFormController name={TITLE_NAME} isRequiredField={true}>
                  <InputText
                    id={`consent-step-card-title-${index || -1}`}
                    className={`${editOnClick ? styles.title : 'd-none'} ${isNullOrWhitespace(step?.translation?.title || '') ? 'border' : ''}`}
                    readOnly={disabled || !ability.can(PERMISSIONS.UPDATE, { __typename: MODEL_NAMES.CONSENT_COMPONENT, value: CONSENT_COMPONENT.STEP })}
                    onBlur={handleHideInput}
                    placeholder={t('default_form_data.step.title')}
                    disabled={disabled}

                  />
                  <div
                    id={`consent-step-card-title-div-${index || -1}`}
                    className={`${!editOnClick ? styles.title : 'd-none'} ${isNullOrWhitespace(step?.translation?.title || '') ? 'border' : ''}`}
                    onClick={handleDisplayInput}
                    onKeyPress={handleTitleKeyPress}
                    role="link"
                    tabIndex="0"
                  >
                    {isNullOrWhitespace(titleWatch) ? t('default_form_data.step.title') : titleWatch}
                  </div>
                </SurveyFormController>
                {!disabled && (
                  ability.can(PERMISSIONS.DELETE, { __typename: MODEL_NAMES.CONSENT_COMPONENT, value: CONSENT_COMPONENT.STEP }) && (
                    <Button
                      type="button"
                      className={`${styles['step-card-image']}`}
                      onClick={onDeleteStep}
                      variant="ghost"
                    >
                      <img src={IconDelete} alt={'delete step'} />
                    </Button>
                  )
                )}
              </>
            }
          </div>
          {fields
            .sort((a, b) => a.number - b.number)
            .map((question, k) => {
              return (
                <div key={question.key}>
                  <QuestionComponent
                    questionTypes={questionTypes}
                    question={question}
                    onDelete={() => handleDeleteQuestion(k)}
                    prefixName={`${prefix}questions.${k}`}
                    index={k}
                    nestIndex={index}
                    createMode={createMode}
                    disabled={disabled}
                    isConsent={isConsent}
                    readOnly={readOnly}
                    currentSurvey={currentSurvey}
                    consentPreview={consentPreview}
                    hideError
                  />
                  {!isConsent && <ErrorMessage name={`${prefix}questions.${k}`} />}
                </div>
              );
            })}
          {!isConsent && !consentPreview && !disabled
          && ability.can(PERMISSIONS.CREATE, { __typename: MODEL_NAMES.CONSENT_COMPONENT, value: CONSENT_COMPONENT.QUESTION }) && (
            <Dropzone onChange={onAddQuestion} />
          )}
          {isConsent && ability.can(PERMISSIONS.READ, { __typename: MODEL_NAMES.CONSENT_COMPONENT, value: CONSENT_COMPONENT.QUESTION }) && (
            <div className="d-flex my-2 align-self-end flex-sm-column">
              <button className="consent-step-button-cancel" onClick={handleOnCancel}>
                <p className="consent-step-button-cancel-text"> {t('return')} </p>
              </button>
              {index > 0 && (
                <button className="consent-step-button-cancel" onClick={handlePrevious}>
                  <p className="consent-step-button-cancel-text"> {t('previous')} </p>
                </button>
              )}
              <button className="consent-step-button-next" onClick={handleOnNext}>
                <p className="consent-step-button-next-text"> {t('continue')} </p>
              </button>
            </div>
          )}
        </div>
      </Card>
      {!isConsent && <ErrorMessage name={prefixName} />}
    </div>
  );
};

Step.propTypes = { can: PropTypes.object };

export default Step;
