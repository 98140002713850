import _ from 'lodash';
import { connect } from 'react-redux';

import { FormsActions } from '../../../../redux/forms';
import Form from './Form';

const mapStateToProps = ({ forms }, props) => {
  const { id, defaultValues } = props;
  const reduxValues = _.get(forms, `data.${id}`);
  return { defaultValues:  { ...(reduxValues || defaultValues) } };
};

const mapDispatchToProps = (dispatch) => ({
  updateData: (key, data) => dispatch(FormsActions.updateData(key, data)),
  omitData: (key) => dispatch(FormsActions.omitData(key)),
  //
});

export default connect(mapStateToProps, mapDispatchToProps)(Form);
