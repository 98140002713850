/**
 * GRAPHQL QUERIES
 */

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  doesEmailExist: `
    query DoesEmailExist($wherePatient: patients_bool_exp!, $whereMedic: medics_bool_exp!) {
      patients_aggregate(where: $wherePatient) {
        aggregate {
          count
        }
      }
      medics_aggregate(where: $whereMedic) {
        aggregate {
          count
        }
      }
    }
  `,
};
