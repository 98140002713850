import './style.css';

import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { shortidGenerate } from '../../../../../utils/utils';
import InputSelectV2 from '../../../../components/formV3/controllers/InputSelectV2';
import { SurveyFormController } from '../../../../components/formV3/innerWrapper';
import { arrayToFormName } from '..';
import ArrayList from '../ArrayList';
import QuestionHeader from '../QuestionHeader';

const DropdownList = ({ question, onDelete, prefixName, index, nestIndex, disabled, isConsent, readOnly }) => {
  const { t } = useTranslation();
  return (
    <QuestionHeader
      question={question}
      onDelete={onDelete}
      prefixName={prefixName}
      index={index}
      nestIndex={nestIndex}
      disabled={disabled}
      isConsent={isConsent}
    >
      { isConsent ? (
        <SurveyFormController
          name={arrayToFormName([prefixName, 'answer'])}
          isRequiredField={true}
          classNameError="form-item-error-text-dropdown"
        >
          <InputSelectV2
            options={_.get(question, 'translation.data.list', [])}
            placeholder={t('DropdownList.placeholder')}
            className={'ec-dropdown-list-question-choice'}
            readOnly={readOnly}
            disabled={disabled}
          />
        </SurveyFormController>
      ) : (
        <ArrayList
          prefixName={arrayToFormName([prefixName, 'translation', 'data'])}
          name={'list'}
          sufixNameInput={'label'}
          defaultNewItem={{ value: shortidGenerate(), label: '' }}
          deletableItems
          disabled={disabled}
        />
      )}
    </QuestionHeader>
  );
};

export default DropdownList;

DropdownList.propTypes = {
  question: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  prefixName: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  nestIndex: PropTypes.number,
};
