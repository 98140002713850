import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { IconEditForm } from '../../../../assets/img/icons';

function IconEditRender({ element, onClick, index }) {
  const { t } = useTranslation();
  return (
    <button className="action-wrapper p-0 link" onClick={() => onClick(element, index)}>
      <img
        src={IconEditForm}
        data-tip={t('medicPatient.history.list.actionBtn.parameters')}
        alt='parameters'
        style={{ width: '36px', height: '36px' }}
      />
    </button>
  );
}

IconEditRender.propTypes = { element: PropTypes.object, onClick: PropTypes.func, index: PropTypes.number };

export default IconEditRender;
