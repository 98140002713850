// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ec-card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 6px 10px 0 rgba(115, 115, 139, 0.09);
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding-bottom: 15px;
}

.ec-card-test {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 6px 10px 0 rgba(115, 115, 139, 0.09);
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding-bottom: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/ui/components/card/style.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,kDAAkD;EAClD,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,oBAAoB;AACtB;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,kDAAkD;EAClD,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,oBAAoB;AACtB","sourcesContent":[".ec-card {\n  background-color: #ffffff;\n  border-radius: 8px;\n  box-shadow: 0 6px 10px 0 rgba(115, 115, 139, 0.09);\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 20px;\n  padding-bottom: 15px;\n}\n\n.ec-card-test {\n  background-color: #ffffff;\n  border-radius: 8px;\n  box-shadow: 0 6px 10px 0 rgba(115, 115, 139, 0.09);\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 20px;\n  padding-bottom: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
