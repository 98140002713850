import '../../style.scss';

import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ToolTip from '../../../tooltip';

function ListSelectArrayField({ name, rules = { required: false }, options, className, containerClassName, maxItemQty = 1, keysToReturn = ['value'], defaultValue, Card, onChange, ...rest }) {

  const { t } = useTranslation();

  const { control, register } = useFormContext(); // retrieve all hook methods
  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: name, // unique name for your Field Array
    // keyName: "id", default to "id", you can change the key name
  });

  const handleSelect = (value) => {
    let formatedValue = _.pick(value, keysToReturn);
    if (onChange) formatedValue = onChange(formatedValue) || formatedValue;
    (fields.length < maxItemQty) && append(formatedValue);
  };

  const onKeyPressHandler = (event, value) => {
    if(event.key === 'Enter'){
      handleSelect(value);
    }
  };

  return (
    <>
      <ToolTip />
      <div className="d-flex justify-content-end justify-content-sm-start">
        { fields.map((field, index) => (
          <div key={field.value} className={'position-relative visible '} data-tip={field.label} {...register(`${name}.${index}`)} >
            <div className="btn-delete" onClick={() => remove(index)} aria-hidden="true" />
            <div
              className={'form-screen-switch-template-label-selector-validate'}
              key={`selector-validate_${index}`}
            />
            <input
              className="invisible"
              key={field.value} // important to include key with field's id
              {...register(`${name}.${index}.value`)}
              defaultValue={field.value} // make sure to include defaultValue
            />
          </div>
        )) }
        { _.times(maxItemQty - fields.length, (i) => (
          <div
            className={'form-screen-switch-template-label-selector visible '}
            key={`selector_${i}`}
          />
        )) }
      </div>
      <div className={`${containerClassName || 'container'}`}>
        {
          options && Array.isArray(options) && options.length
            ? options.filter((o) => !(fields.map(el => el.value)).includes(o.value)).map((t, i) => (
              <div
                key={`ListSelect_card${i}`}
                className="tile"
                onClick={() => handleSelect(t)}
                role="button"
                tabIndex={i}
                onKeyPress={(e) => onKeyPressHandler(e, t)}
                {...rest}
              >
                { Card
                  ? (
                    <div className={'medic-form-list-item-container'}>
                      <Card {...t} />
                    </div>
                  )
                  : (
                    <div className={'medic-form-list-item-container'}>
                      <p className={'medic-form-list-item-title'}>{t.label || t.value || t.title || 'Pas de titre'}</p>
                    </div>
                  )
                }
              </div>
            ))
            : <div className="tile" >
              <div className={'medic-form-list-item-container'}>
                <p className={'medic-form-list-item-title'}>{t('empty_list')}</p>
              </div>
            </div>
        }
      </div>
    </>
  );
}

ListSelectArrayField.propTypes = {
  register: PropTypes.func,
  name: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
  ),
};

export default ListSelectArrayField;
