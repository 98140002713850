// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.question-type-text-answer-input-area {
  outline: none;
  border-radius: 8px;
  margin-left: 10px;
  width: 96%;
  margin-bottom: 15px;
  resize: none;
}
`, "",{"version":3,"sources":["webpack://./src/ui/screens/createFormV2/QuestionTypes/TextAnswer/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,iBAAiB;EACjB,UAAU;EACV,mBAAmB;EACnB,YAAY;AACd","sourcesContent":[".question-type-text-answer-input-area {\n  outline: none;\n  border-radius: 8px;\n  margin-left: 10px;\n  width: 96%;\n  margin-bottom: 15px;\n  resize: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
