import './style.css';

import React, { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';

const PrimaryButton = ({ active, image, text, onClick, center, buttonStyle, textStyle, tooltip, tooltipId, type='button', disabled, hidden = false }) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  const handleOnClick = (event) => {
    event.stopPropagation();
    if (onClick && !disabled) onClick(event);
  };

  const centerStyle = () => {
    return center === 'left' ? 'ec-primary-button-left' : 'ec-primary-button-center';
  };

  const styleButtonClassName = buttonStyle
    ? buttonStyle
    : `${active ? 'ec-primary-button-active' : 'ec-primary-button'} ${centerStyle()} ${hidden ? 'ec-primary-button-hidden' : ''}`;
  const styleButtonTextClassName = textStyle ? textStyle : 'ec-primary-button-text';

  return (
    <button data-tip={tooltip} data-for={tooltipId} className={styleButtonClassName} onClick={handleOnClick} type={type} disabled={disabled}>
      <div className='d-flex justify-content-center ec-primary-button-img-container'>
        <img src={image} alt="button" />
      </div>
      <p className={styleButtonTextClassName}>{text}</p>
    </button>
  );
};

export default PrimaryButton;
