import './error.css';

import React from 'react';

import history from '../../../core/history';
import i18n from '../../../services/i18n/i18n';
import { TYPE_CONST } from '../../../utils';

class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
    error: null,
    info: null,
  };

  constructor(props) {
    super(props);
    history.listen(() => {
      if (this.state.error) {
        this.setState({
          error: null,
          info: null,
        });
      }
    });
  }

  static getDerivedStateFromError(error) { // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) { // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    this.setState({ ...this.state, hasError: true, error, info: errorInfo });
  }

  handleSendError = () => {
    const { error, info } = this.state;
    if (error && info && info.componentStack) {
      const contact = i18n.t('generic_error_contact');
      const subject = i18n.t('generic_error_subject');
      const body = {
        message: `${i18n.t('generic_error_message')}%0A%0A`,
        error: `${error}%0A`,
        stack: `Stack:${info.componentStack}`,
      };
      window.location.href = `mailto:${contact}?subject=${subject}&body=${body.message}${body.error}${body.stack}}`;
    }
  };

  render() {
    if (this.state.hasError) { // You can render any custom fallback UI
      return (
        <div id={'error'}>
          <p className={TYPE_CONST.CONTACT}>
            {i18n.t('generic_error_contact_message')}
            <span className={'email'} onClick={this.handleSendError}>
              {i18n.t('generic_error_contact')}
            </span>
          </p>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;

