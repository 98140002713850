export const toPng = (elementId) => {
  function dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    const byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    let ab = new ArrayBuffer(byteString.length);
    let ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }

  return new Promise((resolve, reject) => {
    const html2canvas = require('html2canvas');

    html2canvas(document.getElementById(elementId)).then(function (canvas) {
      const dataUrl = canvas.toDataURL('image/png');
      const blob = dataURItoBlob(dataUrl);
      const resultFile = new File([blob], 'file_name');
      resolve({
        resultFile,
        dataUrl,
      });
    });
  });
};
