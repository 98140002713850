import { format, intlFormat, isValid, parse, parseISO } from 'date-fns';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import _ from 'lodash';

import { parseDate } from '../services/datefns';
import i18n from '../services/i18n/i18n';
import { getUserProperty, SURVEY_STATUS } from './';
import { validEmailRegex } from './regex';


export const phoneNumberMapper = phoneNumber => {
  try {
    const phoneUtil = PhoneNumberUtil.getInstance();
    phoneNumber = phoneNumber.startsWith('+') ? phoneNumber : `+${phoneNumber}`;
    const parsePhoneNumber = phoneNumber && phoneUtil.parse(phoneNumber);
    const isValid = phoneNumber && phoneUtil.isValidNumber(parsePhoneNumber);
    return {
      phone_number: isValid ? phoneUtil.format(parsePhoneNumber, PhoneNumberFormat.E164) : null,
      local_phone_number: isValid ? phoneUtil.format(parsePhoneNumber, PhoneNumberFormat.NATIONAL) : null,
    };
  } catch (e) {
    return {
      phone_number: null,
      local_phone_number: null,
    };
  }
};

export const patientMapper = (patient) => {
  if (!patient || _.isEmpty(patient)) return patient;
  const { phone_number: phoneNumber } = phoneNumberMapper(patient.phone_number);
  const email = patient.email && validEmailRegex.test(patient.email) ? patient.email : '';
  const city_postcode = _.get(patient, 'cityByCityId.postcode') || _.get(patient, 'postcode') || '';
  const city = _.get(patient, 'cityByCityId.name') || _.get(patient, 'city') || '';
  const birthplace_postcode = _.get(patient, 'cityByBirthplaceId.postcode') || _.get(patient, 'birthplace_postcode') || '';
  const birthplace = _.get(patient, 'cityByBirthplaceId.name') || _.get(patient, 'birthplace') || '';
  return ({
    language_id: patient.language_id,
    independent: patient.tutorsByPatientId && !patient.tutorsByPatientId.length,
    firstname: patient.firstname,
    lastname: patient.lastname,
    security_number: patient.security_number,
    gender: patient.gender,
    use_name: patient.use_name,
    birthdate: patient.birthdate,
    birthplace_postcode,
    birthplace,
    birthplace_id: patient.birthplace_id || null,
    email: email,
    email_confirm: email,
    phone_number: phoneNumber || '',
    postcode: city_postcode,
    city_postcode,
    city,
    city_id: patient.city_id || null,
  });
};

export const patientInfoMapper = (patient) => {
  if (!patient) return patient;
  return _.pick(patientMapper(patient), ['independent', 'firstname', 'lastname', 'security_number', 'gender', 'use_name', 'birthdate', 'birthplace_postcode', 'birthplace', 'birthplace_id']);
};

export const patientContactMapper = (patient) => {
  if (!patient) return patient;
  return _.pick(patientMapper(patient), ['email', 'email_confirm', 'phone_number', 'city_postcode', 'city', 'city_id', 'use_name', 'birthdate', 'birthplace_postcode', 'birthplace', 'birthplace_id', 'language_id']);
};

export const tutorMapper = (tutor) => {
  const email = tutor.email && validEmailRegex.test(tutor.email) ? tutor.email : '';
  const birthdate = parseDate(tutor.birthdate);
  return {
    email: email,
    email_confirm: email,
    phone_number: tutor.phone_number,
    firstname: tutor.firstname,
    lastname: tutor.lastname,
    security_number: tutor.security_number,
    gender: tutor.gender,
    city: tutor.city,
    city_postcode: _.get(tutor, 'cityByCityId.postcode') || tutor.postcode || '',
    city_id: !_.isEmpty(tutor.cityByCityId) ? {
      value: `${_.get(tutor, 'cityByCityId.id', '')}`,
      label: `${_.get(tutor, 'cityByCityId.name', '')}`,
    } : tutor.city,
    birthplace_postcode: _.get(tutor, 'cityByBirthplaceId.postcode') || tutor.birthplace_postcode || '',
    birthplace: tutor.birthplace,
    birthplace_id: !_.isEmpty(tutor.cityByBirthplaceId)
      ? {
        value: `${_.get(tutor, 'cityByBirthplaceId.id', '')}`,
        label: `${_.get(tutor, 'cityByBirthplaceId.name', '')}`,
      }
      : tutor.birthplace_id
        ? { value: tutor.birthplace_id }
        : null,
    birthdate,
    language_id: tutor.language_id,
  };
};

export const surveyStatusMapper = (status, isClinicalMode = false) => {
  const orderedStatusList = isClinicalMode ? [
    SURVEY_STATUS.DRAFT,
    SURVEY_STATUS.COUNTERSIGNED,
    SURVEY_STATUS.NOT_SIGNED,
    SURVEY_STATUS.WITHDRAWAL,
    SURVEY_STATUS.REFUSED,
    SURVEY_STATUS.SENT,
    SURVEY_STATUS.FURTHER_INFO,
    'needsInfo',
    SURVEY_STATUS.ACCEPTED,
  ] : [
    SURVEY_STATUS.DRAFT,
    SURVEY_STATUS.COUNTERSIGNED,
    SURVEY_STATUS.ACCEPTED,
    SURVEY_STATUS.NOT_SIGNED,
    SURVEY_STATUS.WITHDRAWAL,
    SURVEY_STATUS.SENT,
    SURVEY_STATUS.REFUSED,
    SURVEY_STATUS.FURTHER_INFO,
    'needsInfo',
  ];
  const index = orderedStatusList.findIndex(el => el === status);
  return index >= 0 ? index : 0;
};

export const surveyToSortable = (surveySteps) => {
  const columnOrder = surveySteps.map((s, sIndex) => ({
    id: `step.${sIndex}`,
    title: s?.translation?.title || `step ${sIndex + 1}`,
  }));
  const itemsOrder = surveySteps.map((s, sIndex) => {
    return {
      [columnOrder[sIndex].id]: s.questions.map((q, qIndex) => ({
        id: `step.${sIndex}.questions.${qIndex}`,
        title: q?.translation?.question || `questions ${qIndex + 1} from step ${sIndex + 1}`,

      })),
    };
  }).reduce((acc, el) => ({ ...acc, ...el }), {});
  return {
    columnOrder,
    itemsOrder,
  };
};

export const listToSortable = (list) => {
  const lists = {};
  const items = {};
  //const listsIds = []
  const itemsIds = [];
  list.forEach((l, index) => {
    const id = `0-${index}`;
    itemsIds.push(id);
    items[id] = {
      id: id,
      content: l,
    };
  });
  lists['0'] = {
    id: '0',
    title: '',
    itemsIds: itemsIds,
  };
  return {
    items: items,
    lists: lists,
    listOrder: ['0'],
  };
};

export const sortableToList = (data) => {
  const newList = [];
  const { lists, items } = data;
  lists[0].itemsIds.forEach((itemId) => {
    newList.push(items[itemId].content);
  });
  return newList;
};

export const sortableToSurvey = (data, prevSteps) => {
  const { itemsOrder, columnOrder } = data;
  const newStepss = columnOrder.map((c, sIndex) => {
    return ({
      ..._.get(prevSteps, [c.id.replace('step.', '')]),
      number: sIndex + 1,
      questions: itemsOrder[c.id].map((item, qIndex) => ({
        ..._.get(prevSteps, item.id.replace('step.', '')),
        number: qIndex + 1,
      })),
    });
  });
  return newStepss;
};

export const questionTypeValueToIdMapper = (types, type) => {
  if (!types || types === undefined || !types.length || !type || type === undefined) {
    return undefined;
  }
  return (types.filter((t) => t.value === type) || [{ id: undefined }])[0].id;
};

export const questionTypeIdToValueMapper = (types, type) => {
  if (!types || types === undefined || !types.length || !type || type === undefined) {
    return undefined;
  }
  return (types.filter((t) => t.id === type) || [{ value: undefined }])[0].value;
};

export const surveyTypeIdToValueMapper = (types, type) => {
  if (!types || types === undefined || !types.length || !type || type === undefined) {
    return undefined;
  }
  return (types.filter((t) => t.id === type) || [{ value: undefined }])[0].value;
};


const HAND = ['Auriculaire', 'Annulaire', 'Majeur', 'Index', 'Pouce'];

const countQuestion = (steps, survey_pdfs) => {
  var value = 0;
  steps && steps.forEach((v) => {
    value += v.questions.length;
  });
  if (survey_pdfs) value += survey_pdfs.length;
  return value;
};

export const buildText = (text, survey) => {
  const { patient, tutored } = survey;
  const signataire = _.cloneDeep(tutored || patient);
  const editedPatient = _.cloneDeep(patient);
  const parsedPatientBirthdate = editedPatient.birthdate ? parse(editedPatient.birthdate, 'dd/MM/yyyy', new Date()) : null;
  const isValidPatientBirthDate = parsedPatientBirthdate ? isValid(parsedPatientBirthdate) : false;
  const medecin = survey.referent ? _.cloneDeep(survey.referent) : null;
  const keyword = [
    { key: '%nom_signataire%', value: getUserProperty(signataire, 'contactNameWithTitle') },
    { key: '%prenom_signataire%', value: signataire?.firstname },
    { key: '%sexe_signataire%', value: getUserProperty(signataire, 'title') },
    // {key: "%is_woman_patient%", value: editedPatient.title !== "male"},
    { key: '%date_naissance_signataire%', value: signataire?.birthdate || i18n.t('no_information') },
    { key: '%ville_signataire%', value: signataire?.city ? signataire.city : i18n.t('no_information') },
    { key: '%nom_patient%', value: getUserProperty(editedPatient, 'contactNameWithTitle') || '' },
    { key: '%prenom_patient%', value: editedPatient?.firstname },
    { key: '%sexe_patient%', value: getUserProperty(editedPatient, 'title') },
    // {key: "%is_woman_patient%", value: editedPatient?.title !== "male"},
    { key: '%date_naissance_patient%', value: isValidPatientBirthDate ? editedPatient.birthdate : i18n.t('no_information') },
    { key: '%date_naissance_patient_MM_AAAA%', value: isValidPatientBirthDate ? format(parsedPatientBirthdate, 'MM/yyyy') : i18n.t('no_information') },

    // ville_patient is not possible as if patient got a tutor then he got no city
    // {key: "%ville_patient%", value: editedPatient?.city ? editedPatient.city : t('no_information')},
    { key: '%nom_docteur%', value: getUserProperty(medecin, 'fullContactName') || '' },
    { key: '%titre_nom_docteur%', value: medecin ? i18n.t(`medic_title.${medecin.title}`) + ` ${medecin.lastname}` : '' },
    { key: '%prenom_docteur%', value: medecin ? medecin.firstname : '' },
    {
      key: '%date_operation%', value: _.get(survey, 'intervention.date')
        ? intlFormat(parseISO(_.get(survey, 'intervention.date')), {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        })
        : '',
    },
    { key: '%nb_questions%', value: countQuestion(survey.steps, survey.survey_pdfs) },
    { key: '%gender_pathos%', value: _.get(survey, 'survey_type.value', '').toLowerCase() },
    {
      key: '%cote%', value: Array.isArray(survey?.side)
        ? `côté ${survey.side.join(' et ').toLowerCase()}`
        : survey?.side || '',
    },
    {
      key: '%rayon%',
      value: survey?.radius ? (HAND.includes(_.get(survey, 'radius.0.label', '').trim()) ? 'de la main' : 'du pied') : '',
    },
    {
      key: '%rayon_details%',
      value: Array.isArray(survey?.radius)
        ? survey.radius
          .map((v, i) => {
            return v.label.trim();
          })
          .join(', ')
          .toLowerCase()
        : '',
    },
    { key: 'bold%', value: "<span class='ec-question-basics-common-bold-text'>" },
    { key: '%bold', value: '</span>' },
    { key: 'red%', value: "<span class='ec-question-basics-common-red-text'>" },
    { key: '%red', value: '</span>' },
    { key: 'tutor%', value: !signataire?.is_tutor ? "<span style='display: none'>" : '' },
    { key: '%tutor', value: !signataire?.is_tutor ? '</span>' : '' },
    { key: '%raison_delai_praticien%', value: survey?.date_warning || i18n.t('no_information') },
    { key: '\n', value: '</br>' },
  ];

  keyword.forEach((v) => {
    text = text && text.split(v.key).join(v.value);
  });
  return text;
};