import { createAction } from '../../utils';

// Types
export const types = {
  RESET_REDUCER: 'medic_RESET_REDUCER',

  MEDIC_SURVEYS_SUCCESS: 'MEDIC_SURVEYS_SUCCESS',

  SURVEY_TYPES_REQUEST: 'SURVEY_TYPES_REQUEST',
  SURVEY_TYPES_SUCCESS: 'SURVEY_TYPES_SUCCESS',
  SURVEY_TYPES_FAILURE: 'SURVEY_TYPES_FAILURE',

  SPECIALITIES_REQUEST: 'SPECIALITIES_REQUEST',
  SPECIALITIES_SUCCESS: 'SPECIALITIES_SUCCESS',
  SPECIALITIES_FAILURE: 'SPECIALITIES_FAILURE',

  LINK_SURVEY_PATIENT_REQUEST: 'LINK_SURVEY_PATIENT_REQUEST',
  LINK_SURVEY_PATIENT_SUCCESS: 'LINK_SURVEY_PATIENT_SUCCESS',
  LINK_SURVEY_PATIENT_FAILURE: 'LINK_SURVEY_PATIENT_FAILURE',

  DELETE_LOCAL_LINK: 'DELETE_LOCAL_LINK',

  QUESTION_TYPES_REQUEST: 'QUESTION_TYPES_REQUEST',
  QUESTION_TYPES_SUCCESS: 'QUESTION_TYPES_SUCCESS',
  QUESTION_TYPES_FAILURE: 'QUESTION_TYPES_FAILURE',

  SURVEY_CURRENT_REQUEST: 'SURVEY_CURRENT_REQUEST',
  SURVEY_CURRENT_SUCCESS: 'SURVEY_CURRENT_SUCCESS',
  SURVEY_CURRENT_FAILURE: 'SURVEY_CURRENT_FAILURE',
  SURVEY_LIST_SUCCESS: 'SURVEY_LIST_SUCCESS',

  SURVEY_CURRENT_UPDATE_REQUEST: 'SURVEY_CURRENT_UPDATE_REQUEST',
  SURVEY_CURRENT_UPDATE_SUCCESS: 'SURVEY_CURRENT_UPDATE_SUCCESS',
  SURVEY_CURRENT_UPDATE_FAILURE: 'SURVEY_CURRENT_UPDATE_FAILURE',

  SURVEY_CURRENT_DELETE: 'SURVEY_CURRENT_DELETE',

  SURVEY_CONSENT_UPDATE_REQUEST: 'SURVEY_CONSENT_UPDATE_REQUEST',
  SURVEY_CONSENT_UPDATE_SUCCESS: 'SURVEY_CONSENT_UPDATE_SUCCESS',
  SURVEY_CONSENT_UPDATE_FAILURE: 'SURVEY_CONSENT_UPDATE_FAILURE',

  TEMPORARY_SURVEY_CREATE: 'TEMPORARY_SURVEY_CREATE',
  TEMPORARY_SURVEY_SUCCESS: 'TEMPORARY_SURVEY_SUCCESS',
  TEMPORARY_SURVEY_DELETE: 'TEMPORARY_SURVEY_DELETE',

  SURVEY_UPLOAD_SIGN: 'SURVEY_UPLOAD_SIGN',
  SURVEY_GENERATE_PDF: 'SURVEY_GENERATE_PDF',
  SURVEY_UPLOAD_SIGN_SUCCESS: 'SURVEY_UPLOAD_SIGN_SUCCESS',
  SURVEY_UPLOAD_SIGN_FAILURE: 'SURVEY_UPLOAD_SIGN_FAILURE',

  SURVEY_GENERATE_DRAW: 'SURVEY_GENERATE_DRAW',
  SURVEY_GENERATE_SUCCESS: 'SURVEY_GENERATE_SUCCESS',
  SURVEY_GENERATE_RESET: 'SURVEY_GENERATE_RESET',

  SURVEY_SIGN_REQUEST: 'SURVEY_SIGN_REQUEST',
  SURVEY_SIGN_SUCCESS: 'SURVEY_SIGN_SUCCESS',
  SURVEY_SIGN_FAILURE: 'SURVEY_SIGN_FAILURE',

  SURVEY_COUNTERSIGN_SUCCESS: 'SURVEY_COUNTERSIGN_SUCCESS',
  SURVEY_COUNTERSIGN_FAILURE: 'SURVEY_COUNTERSIGN_FAILURE',

  SURVEY_WITHDRAWAL_REQUEST: 'SURVEY_WITHDRAWAL_REQUEST',
  SURVEY_WITHDRAWAL_SUCCESS: 'SURVEY_WITHDRAWAL_SUCCESS',
  SURVEY_WITHDRAWAL_FAILURE: 'SURVEY_WITHDRAWAL_FAILURE',

  RESET_SURVEY_SIGNED: 'RESET_SURVEy_SIGNED',
  RESET_SURVEY_COUNTER_SIGNED: 'RESET_SURVEY_COUNTER_SIGNED',

  CREATE_SURVEY_PAPER: 'CREATE_SURVEY_PAPER',
  CREATE_SURVEY_PAPER_SUCCESS: 'CREATE_SURVEY_PAPER_SUCCESS',
  CREATE_SURVEY_PAPER_FAILURE: 'CREATE_SURVEY_PAPER_FAILURE',

  SET_IS_FROM_QR_CONNECTION: 'SET_IS_FROM_QR_CONNECTION',

  SURVEY_DELETE_REQUEST: 'SURVEY_DELETE_REQUEST',
  SURVEY_DELETE_SUCCESS: 'SURVEY_DELETE_SUCCESS',
  SURVEY_DELETE_FAILURE: 'SURVEY_DELETE_FAILURE',

  REJECT_REQUEST: 'survey_REJECT_REQUEST',
  REJECT_SUCCESS: 'survey_REJECT_SUCCESS',
  REJECT_FAILURE: 'survey_REJECT_FAILURE',

  GEN_SURVEY_SIGN_URL: 'survey_GEN_SURVEY_SIGN_URL',
  GEN_SURVEY_SIGN_URL_SUCCESS: 'survey_GEN_SURVEY_SIGN_URL_SUCCESS',
  GEN_SURVEY_SIGN_URL_ERROR: 'survey_GEN_SURVEY_SIGN_URL_ERROR',
  GEN_SURVEY_SIGN_URL_RESET: 'survey_GEN_SURVEY_SIGN_URL_RESET',

  CONTRALIA_COUNTERSIGN_URL: 'survey_CONTRALIA_COUNTERSIGN_URL',
  INITIATE_CONTRALIA_SIGNATURE: 'survey_INITIATE_CONTRALIA_SIGNATURE',
  CONTRALIA_NEW_CODE_REQUEST: 'survey_CONTRALIA_NEW_CODE_REQUEST',
  CONTRALIA_CHECK_OTP: 'survey_CONTRALIA_CHECK_OTP',
  CONTRALIA_COUNTERSIGN_INIT: 'survey_CONTRALIA_COUNTERSIGN_INIT',
  CONTRALIA_COUNTERSIGN_NEW_CODE_REQUEST: 'survey_CONTRALIA_COUNTERSIGN_NEW_CODE_REQUEST',

  FETCH_PATIENT_SURVEY_LIST: 'survey_FETCH_PATIENT_SURVEY_LIST',
};

// Actions
// eslint-disable-next-line import/no-anonymous-default-export
export default {

  /** REQUEST */

  medicSurveysSuccess: (medic_surveys) => createAction(types.MEDIC_SURVEYS_SUCCESS, { medic_surveys }),

  surveyTypesRequest: () => createAction(types.SURVEY_TYPES_REQUEST),
  surveyTypesSuccess: (survey_types) => createAction(types.SURVEY_TYPES_SUCCESS, { survey_types }),
  surveyTypesFailure: () => createAction(types.SURVEY_TYPES_FAILURE),

  specialitiesRequest: () => createAction(types.SPECIALITIES_REQUEST),
  specialitiesSuccess: (survey_specialities) => createAction(types.SPECIALITIES_SUCCESS, { survey_specialities }),
  specialitiesFailure: () => createAction(types.SPECIALITIES_FAILURE),

  questionTypesRequest: () => createAction(types.QUESTION_TYPES_REQUEST),
  questionTypesSuccess: (question_types) => createAction(types.QUESTION_TYPES_SUCCESS, { question_types }),
  questionTypesFailure: () => createAction(types.QUESTION_TYPES_FAILURE),

  linkSurveyPatientRequest: (survey, attachments) => createAction(types.LINK_SURVEY_PATIENT_REQUEST, { survey, attachments }),
  linkSurveyPatientSuccess: (ids) => createAction(types.LINK_SURVEY_PATIENT_SUCCESS, { ids }),
  linkSurveyPatientFailure: () => createAction(types.LINK_SURVEY_PATIENT_FAILURE),

  deleteLocalLink: () => createAction(types.DELETE_LOCAL_LINK),

  surveyCurrentRequest: (id) => createAction(types.SURVEY_CURRENT_REQUEST, { id }),
  surveyCurrentSuccess: (surveys) => createAction(types.SURVEY_CURRENT_SUCCESS, { surveys }),
  surveyCurrentFailure: () => createAction(types.SURVEY_CURRENT_FAILURE),

  surveyListSuccess: (surveys) => createAction(types.SURVEY_LIST_SUCCESS, { surveys }),

  surveyCurrentUpdateRequest: (id, survey) => createAction(types.SURVEY_CURRENT_UPDATE_REQUEST, { id, survey }),
  surveyCurrentUpdateSuccess: (questions) => createAction(types.SURVEY_CURRENT_UPDATE_SUCCESS, { questions }),
  surveyCurrentUpdateFailure: () => createAction(types.SURVEY_CURRENT_UPDATE_FAILURE),

  surveyCurrentDelete: () => createAction(types.SURVEY_CURRENT_DELETE),

  surveyConsentUpdateFailure: () => createAction(types.SURVEY_CONSENT_UPDATE_FAILURE),

  temporarySurveyCreate: (survey, navigate) => createAction(types.TEMPORARY_SURVEY_CREATE, { survey, navigate }),
  temporarySurveySuccess: (temporarySurvey) => createAction(types.TEMPORARY_SURVEY_SUCCESS, { temporarySurvey }),
  temporarySurveyDelete: () => createAction(types.TEMPORARY_SURVEY_DELETE),

  surveyUploadSign: (survey) => createAction(types.SURVEY_UPLOAD_SIGN, { survey }),
  generateSurveyPDF: (surveyId, type = 'surveys') => createAction(types.SURVEY_GENERATE_PDF, { surveyId, type }),
  surveyUploadSignSuccess: (survey) => createAction(types.SURVEY_UPLOAD_SIGN_SUCCESS, { survey }),
  surveyUploadSignFailure: () => createAction(types.SURVEY_UPLOAD_SIGN_FAILURE),

  generateSurveyDraw: (survey, toDisplay) => createAction(types.SURVEY_GENERATE_DRAW, { survey, toDisplay }),
  generateSuccess: (url) => createAction(types.SURVEY_GENERATE_SUCCESS, { url }),
  resetGenerateSuccess: () => createAction(types.SURVEY_GENERATE_RESET),

  surveySignSuccess: () => createAction(types.SURVEY_SIGN_SUCCESS),
  surveySignFailure: () => createAction(types.SURVEY_SIGN_FAILURE),

  surveyCounterSignSuccess: () => createAction(types.SURVEY_COUNTERSIGN_SUCCESS),
  surveyCounterSignFailure: () => createAction(types.SURVEY_COUNTERSIGN_FAILURE),

  surveyWithdrawalRequest: (surveyId) => createAction(types.SURVEY_WITHDRAWAL_REQUEST, { surveyId }),
  surveyWithdrawalSuccess: (survey) => createAction(types.SURVEY_WITHDRAWAL_SUCCESS, { survey }),
  surveyWithdrawalFailure: () => createAction(types.SURVEY_WITHDRAWAL_FAILURE),

  resetSurveySigned: () => createAction(types.RESET_SURVEY_SIGNED),
  resetSurveyCounterSigned: () => createAction(types.RESET_SURVEY_COUNTER_SIGNED),

  createSurveyPaper: (survey) => createAction(types.CREATE_SURVEY_PAPER, { survey }),
  createSurveyPaperSuccess: (survey) => createAction(types.CREATE_SURVEY_PAPER_SUCCESS, { survey }),
  createSurveyPaperFailure: () => createAction(types.CREATE_SURVEY_PAPER_FAILURE),

  surveyDeleteRequest: (patientId, IDs) => createAction(types.SURVEY_DELETE_REQUEST, { patientId, IDs }),
  surveyDeleteSuccess: (IDs) => createAction(types.SURVEY_DELETE_SUCCESS, { IDs }),
  surveyDeleteFailure: () => createAction(types.SURVEY_DELETE_FAILURE),

  rejectRequest: (id, payload) => createAction(types.REJECT_REQUEST, { id, payload }),


  genSurveySignUrl: (surveyId, questions, ip, startDate, endDate) => createAction(types.GEN_SURVEY_SIGN_URL, { surveyId, questions, ip, startDate, endDate }),
  genSurveySignUrlSuccess: (url) => createAction(types.GEN_SURVEY_SIGN_URL_SUCCESS, { url }),
  genSurveySignUrlError: () => createAction(types.GEN_SURVEY_SIGN_URL_ERROR),
  genSurveySignUrlReset: () => createAction(types.GEN_SURVEY_SIGN_URL_RESET),

  contraliaSignInit: (id, questions, ip, startDate, endDate) => createAction(types.INITIATE_CONTRALIA_SIGNATURE, { id, questions, ip, startDate, endDate }),
  contraliaSignNewCodeRequest: (surveyId, isPro = false) => createAction(types.CONTRALIA_NEW_CODE_REQUEST, { surveyId, isPro }),
  contraliaCodeCheck: (surveyId, code, isPro = false) => createAction(types.CONTRALIA_CHECK_OTP, { surveyId, code, isPro }),

  contraliaCountersignUrl: (surveyId) => createAction(types.CONTRALIA_COUNTERSIGN_URL, { surveyId }),
  contraliaCountersignInit: (surveyId) => createAction(types.CONTRALIA_COUNTERSIGN_INIT, { surveyId }),
  contraliaCountersignWithOtp: (surveyId, signatoryId) => createAction(types.CONTRALIA_CHECK_OTP, { surveyId, signatoryId }),
  contraliaCountersignNewCode: (surveyId) => createAction(types.CONTRALIA_COUNTERSIGN_NEW_CODE_REQUEST, { surveyId }),

  fetchPatientSurveyList: (patientIndex = null) => createAction(types.FETCH_PATIENT_SURVEY_LIST, { patientIndex }),


  resetReducer: () => createAction(types.RESET_REDUCER),
};
