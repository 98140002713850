
import { connect } from 'react-redux';

import SurveyDocumentViewer from './SurveyDocumentViewer';

const mapStateToProps = ({ auth, survey }, props) => {
  const { survey: surveyProps } = props;
  return {
    token: auth.token || null,
    survey: surveyProps || survey.currentSurvey,
  };
};

const ConnectedSurveyDocumentViewer = connect(mapStateToProps, null)(SurveyDocumentViewer);
export default ConnectedSurveyDocumentViewer;