import './style.scss';

import React from 'react';

const SmallLoader = () => (
  <div className="small-loader mx-auto">
    <div />
    <div />
    <div />
  </div>
);

export default SmallLoader;