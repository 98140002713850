import _ from 'lodash';
import { call, put, takeLatest } from 'redux-saga/effects';

import { CityService } from '../../services';
import { regexPostcode } from '../../utils/regex';
import { default as CityActions, types } from './actions';


function* fetchCityList({ postcode }) {
  // remove spaces from string
  postcode = (postcode || '').replace(/\s/g, '');
  if (postcode === '_____' || postcode === null) postcode = '';
  if (regexPostcode.test(postcode)){
    yield put(CityActions.fetchBegin());
    const [error, response] = yield call(CityService.fetchCityList, { postcode });
    if (error || !response) {
      yield put(CityActions.fetchCityListFailure());
      return;
    }
    const cities = _.keyBy(response && response.cities, 'id');
    yield put(CityActions.fetchCityListSuccess(cities));
  } else {
    yield put(CityActions.fetchCityListSuccess({}));
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default [takeLatest(types.CITIES_FETCH_QUERY, fetchCityList)];
