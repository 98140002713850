import { rppsRegex } from '../../../../utils/regex';
import yup from '../../../../utils/yup';

const yupMedicSchema = yup.object().shape({
  rpps_number: yup
    .string()
    .length(11, 'medicForm.rpps_number.rules.format')
    .matches(rppsRegex, { message: 'medicForm.rpps_number.rules.format', excludeEmptyString: true })
    .nullable(),
  phone_number: yup
    .string()
    .validMobile({ message: 'medicForm.phone_number.rules.format', excludeEmptyString: true }),
});

export default yupMedicSchema;