import './style.css';

import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { SURVEY_STATUS } from '../../../../utils';
import NavigateBack from '../../../components/navigateBack';
import AuthLayout from '../../../layouts/authLayout';
import CommentaryCard from './CommentaryCard';
import ContraliaIFrameSignatureModal from './ContraliaIFrameSignatureModal';
import SummaryModal from './SummaryModal';
import SurveyCard from './SurveyCard';

const ConsentRecap = ({
  survey,
  questionTypes,
  patient,
  consentAnswers,
  omitData,
  resetSurveySigned,
  genSurveySignUrlReset,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [hideSummaryModal, setHideSummaryModal] = useState(false);
  const readOnly = useMemo(() =>
    survey && [SURVEY_STATUS.ACCEPTED, SURVEY_STATUS.COUNTERSIGNED].includes(survey.status)
  , [survey && survey.status]);

  useEffect(() => {
    genSurveySignUrlReset();
  }, []);

  useEffect(() => {
    if (!survey) {
      handleOnCancel();
    }
  }, [survey]);

  const redirectAfterSign = () => {
    resetSurveySigned();
    omitData('consent');
    handleOnCancel();
  };

  const handleOnCancel = () => {
    navigate('/');
  };

  const handlePrevious = () => {
    navigate(-1);
  };

  const handleIFrameLoaded = () => {
    setHideSummaryModal(true);
  };

  const displaySummaryModal = () => {
    setHideSummaryModal(false);
  };

  return (
    <AuthLayout>
      <NavigateBack text={t('dashboard')} onClick={handleOnCancel} />
      <div className="d-flex flex-column px-2 justify-content-between flex-md-row">
        <SurveyCard survey={survey} className="w-100 mx-2 px-2 ec-card" />
        <CommentaryCard survey={survey} className="w-100 mx-2 px-2 ec-card" />
      </div>
      {hideSummaryModal
        ? null
        : <SummaryModal
          survey={survey}
          consent={consentAnswers}
          questionTypes={questionTypes}
          onPrevious={handlePrevious}
          patient={patient}
          readOnly={readOnly}
        />
      }
      <ContraliaIFrameSignatureModal onSignSuccess={redirectAfterSign} onIframeLoaded={handleIFrameLoaded} onError={displaySummaryModal} />
    </AuthLayout>
  );
};

export default ConsentRecap;
