import { connect } from 'react-redux';

import Menu from './Menu';

const mapStateToProps = ({ survey }) => {
  return { questionTypes: Object.values((survey && survey.questionTypes) || {}) };
};


export default connect(mapStateToProps, null)(Menu);
