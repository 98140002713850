import { createAction } from '../../utils';

// Types
export const types = {
  RESET_REDUCER: 'display_RESET_REDUCER',

  DISPLAY_START_LOADER: 'display_START_LOADER',
  DISPLAY_STOP_LOADER: 'display_STOP_LOADER',

  SET_APP_MODE: 'display_SET_APP_MODE',
  SET_APP_MODE_SUCCESS: 'display_SET_APP_MODE_SUCCESS',

  SET_PATIENT_APP_MODE: 'display_SET_PATIENT_APP_MODE',

  SET_IS_CLINICAL_STUDY : 'display_SET_IS_CLINICAL_STUDY',
};

// Actions
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  /** REQUEST */
  startLoader: () => createAction(types.DISPLAY_START_LOADER),
  stopLoader: () => createAction(types.DISPLAY_STOP_LOADER),

  resetReducer: () => createAction(types.RESET_REDUCER),


  setPatientAppMode: (surveys) => createAction(types.SET_PATIENT_APP_MODE, { surveys }),
  setAppMode: (isClinicalStudy) => createAction(types.SET_APP_MODE, { isClinicalStudy }),
  setAppModeSuccess: (appMode) => createAction(types.SET_APP_MODE_SUCCESS, { appMode }),

  setIsClinicalStudy: (isClinicalStudy)=> createAction(types.SET_IS_CLINICAL_STUDY, { isClinicalStudy }),

};
