
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import iconEmpty from '../../../../assets/img/illu-pas-dinterventions.png';
import { scrollToTop } from '../../../../utils/utils';
import Item from './Item/';

const hashCode = function(str) {
  let hash = 0, i, chr;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

const InfiniteLoadList = ({
  chunk, onClick, logginUserRole, bearerToken, smallScreen, medic, patient, tutors, isPatient,
  isSigned, onResetIsSigned, preselectedID, hasCounterSign, resetAutoLoginSurveyID, isClinicalMode,
}) => {
  const loader = useRef(null);
  const { t } = useTranslation();
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [list, setList] = useState([]);

  useEffect(() => {
    if (Array.isArray(chunk)) {
      scrollToTop();
      loadFunc(1);
      setHasMore(true);
    }
  }, [hashCode(JSON.stringify(chunk))]);

  const handleObserver = useCallback((entries) => {
    if (hasMore){
      const target = entries[0];
      if (target.isIntersecting) {
        setPage((prev) => prev + 1);
      }
    }
  }, []);

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: '20px',
      threshold: 0,
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (loader.current) observer.observe(loader.current);
  }, [handleObserver]);


  useEffect(() => {
    return function cleanUp() {
      setList([]);
    };
  }, []);

  useEffect(() => {
    if (page) {
      loadFunc(page);
    }
  }, [page]);

  const loadFunc = (newPage) => {
    if (newPage !== page){
      setPage(newPage);
    }
    setList(chunk.slice(0, newPage).reduce((acc, val) => [...acc, ...val], []) );
    const index = newPage - 1 ;
    const hasMore = index < chunk.length;
    setHasMore(hasMore);
  };

  return (
    <>
      { Array.isArray(list) && list.length
        ? (
          list.map((surveyList, i) => (
            <div
              key={`info-patient-survey-number-${i}`}
              className={`item-card-patient${(i) % 2 === 0 ? '-grey' : ''}`}
            >
              <Item
                logginUserRole={logginUserRole}
                bearerToken={bearerToken}
                smallScreen={smallScreen}
                surveyList={surveyList}
                patient={patient}
                intervention={surveyList[0].intervention || {}}
                onClick={onClick}
                tutors={tutors}
                isPatient={isPatient}
                isSigned={isSigned}
                onResetIsSigned={onResetIsSigned}
                preselectedID={preselectedID}
                hasCounterSign={hasCounterSign}
                resetAutoLoginSurveyID={resetAutoLoginSurveyID}
                isClinicalMode={isClinicalMode}
              />
            </div>
          ))
        ) : (
          <div className={'d-flex flex-column align-content-center align-items-center patient-contact-historique-empty '}>
            <img src={iconEmpty} alt="empty list" />
            <div className={'patient-contact-historique-empty-text'}>
              {t('empty_tab_patient')}
            </div>
          </div>
        )}
      <div ref={loader} />
    </>
  );
};

InfiniteLoadList.propTypes = {
  chunk: PropTypes.array,
  onClick: PropTypes.func,
  selectedTeam: PropTypes.number,
  isClinicalMode: PropTypes.bool,
  medicId: PropTypes.number,
};

export default InfiniteLoadList;
