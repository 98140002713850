import './style.css';

import React from 'react';
import { useTranslation } from 'react-i18next';

import imageError from '../../../assets/img/icon-error@3x.png';

const BlockedModal = () => {
  const { t } = useTranslation();
  return (
    <div id={'blockedModal'}>
      <img src={imageError} alt="error"/>
      <p>{t('blockedModal.disclamer_portrait_orientation')}</p>
    </div>
  );};

export default BlockedModal;