import '../style.css';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { InputGroupedRadio, InputText } from '../../../../components/formV3/controllers';
import ErrorMessage from '../../../../components/formV3/errors/ErrorMessage';
import { SurveyFormController } from '../../../../components/formV3/innerWrapper';
import { arrayToFormName } from '..';
import QuestionHeader from '../QuestionHeader';

export const VALIDATION_KEYS = {
  FIRST: 'first',
  SECOND: 'second',
};

const Validation = ({ question, onDelete, prefixName, index, nestIndex, createMode, disabled, isConsent, readOnly }) => {
  const { t } = useTranslation();
  const ANSWER_DATA_FORM_NAME = arrayToFormName([prefixName, 'translation', 'data']);
  const ANSWER_FORM_NAME = arrayToFormName([prefixName, 'answer']);
  const answer = useWatch({ name: ANSWER_FORM_NAME });
  const { setValue } = useFormContext(); // retrieve all hook methods
  const [isRewordModalOpen, setIsRewordModalOpen] = useState(false);

  useEffect(() => {
    if (readOnly !== true && answer === VALIDATION_KEYS.SECOND) {
      setIsRewordModalOpen(answer === VALIDATION_KEYS.SECOND);
      if (question?.translation?.rewording) {
        setValue(arrayToFormName([prefixName, 'asked_more_info']), true);
      }
    }
  }, [answer]);

  const handleCloseRewording = () => {
    setIsRewordModalOpen(false);
    if (isConsent && readOnly !== true) {
      setValue(ANSWER_FORM_NAME, null);
    }
  };

  return (
    <QuestionHeader
      question={question}
      onDelete={onDelete}
      prefixName={prefixName}
      index={index}
      nestIndex={nestIndex}
      disabled={disabled}
      isConsent={isConsent}
      isRewordModalOpen={isRewordModalOpen}
      onCloseRewording={handleCloseRewording}
      hasRewording
      readOnly={readOnly}
    >
      { isConsent
        ? (
          <SurveyFormController name={ANSWER_FORM_NAME} >
            <InputGroupedRadio
              options={Object.values(VALIDATION_KEYS).map(key => ({ key, value: question?.translation?.data[key] }))}
              containerClassName="input-radio-group-vertical"
              className="input-radio-input-container-vertical"
              readOnly={readOnly}
              disabled={disabled}
            />
          </SurveyFormController>
        ) : (
          <>
            {Object.values(VALIDATION_KEYS).map(key => (
              <div key={key} className="row input-radio-input-container-vertical d-flex align-items-center">
                <div className="col-auto">
                  <span className="input-radio-input-vertical" />
                </div>
                <div className="col" >
                  <SurveyFormController name={arrayToFormName([ANSWER_DATA_FORM_NAME, key])} hideError>
                    <InputText
                      readOnly={disabled || !createMode}
                      className="input-radio-value-vertical"
                      placeholder={t(`default_form_data.questions.validation.data.${key}`)}
                    />
                  </SurveyFormController>
                </div>
              </div>
            ))}
            <ErrorMessage name={ANSWER_DATA_FORM_NAME} />
          </>
        )}
    </QuestionHeader>
  );
};

export default Validation;

Validation.propTypes = {

  question: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  prefixName: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  nestIndex: PropTypes.number,
};
