// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ec-filter-button-activated .ec-filter-image {
  background: none;
}

.ec-filter-button-image-activated {
  filter: brightness(0) invert(1);
}

.ec-filter-image {
  width: 60px;
  height: 60px;
  border-radius: 14px;
  background-color: #FEF3F2;
  display: flex;
  justify-content: center;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/ui/components/filter/filterIcon/style.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,yBAAyB;EACzB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB","sourcesContent":[".ec-filter-button-activated .ec-filter-image {\n  background: none;\n}\n\n.ec-filter-button-image-activated {\n  filter: brightness(0) invert(1);\n}\n\n.ec-filter-image {\n  width: 60px;\n  height: 60px;\n  border-radius: 14px;\n  background-color: #FEF3F2;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
