import _ from 'lodash';
import { connect } from 'react-redux';

import { FormsActions } from '../../../../redux/forms';
import { MedicTemplateActions } from '../../../../redux/medicTemplates';
import { getUserLngTranslation } from '../../../../utils/translation';
import SurveyTemplateForm from './SurveyTemplateForm';

const mapStateToProps = ({ auth, forms, survey, medic, patient, medicTemplate }, props) => {
  const { id, defaultValues } = props;
  const { user: { language_id } } = auth;
  const reduxValues = _.get(forms, `data.${id}`);
  const surveyTypes = Object.values(survey.types || {});
  const medicTemplateList = getUserLngTranslation(Object.values(medicTemplate.list || {}), language_id, 'displayTranslation');
  const defValue = { template:[], ...(reduxValues || defaultValues) };
  return ({
    defaultValues: defValue,
    surveyTypes,
    isSurveyLoading: survey.loading || survey.loadingType,
    currentMedic: medic.currentMedic,
    patientDefaultLanguageId: patient.selectedPatient?.language?.id,
    medicTemplateList: (medicTemplateList || []).map(mt => {
      const translation = Object.values(mt?.translation);
      return ({
        ...mt,
        language_id: mt.displayTranslation.language_id,
        availableLngs: Array.isArray(translation) ? translation.map(el => el.language_id) : [],
      });}),
    isMedicTemplateLoading: medicTemplate.loading,
  });
};

const mapDispatchToProps = (dispatch) => ({
  //
  updateData: (key, data) => dispatch(FormsActions.updateData(key, data)),
  omitData: (key) => dispatch(FormsActions.omitData(key)),
  medicTemplatesGetRequest: (selectedTeam) => dispatch(MedicTemplateActions.medicTemplatesGetRequest(selectedTeam)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SurveyTemplateForm);