/**
 * GRAPHQL QUERIES
 */

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  fetchFromSurveyTypeId: () => `
    query IntroQuestionFromSurveyTypeId($surveyTypeId: Int!, $languageId: Int!) {
      introduction_questions(order_by: {displayed_number: asc}, where: {survey_type_id: {_eq: $surveyTypeId}}) {
        id
        is_after
        displayed_number
        survey_type_id
        displayed_if
        introduction_questions_translations(where: {language_id: {_eq: $languageId}}) {
          id
          introduction_questions_id
          value
          title
          text_success
          text_error
          pdf_url
          language_id
          pdf_checkbox_label
          first_button_label
          second_button_label
          loid
        }
      }
    }
  `,
};
