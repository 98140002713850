import {
  differenceInYears, format, isDate, parse, parseISO, setDefaultOptions, subMinutes,
} from 'date-fns';
import {
  af,
  ar,
  arDZ,
  arEG,
  arMA,
  arSA,
  arTN,
  az,
  be,
  beTarask,
  bg,
  bn,
  bs,
  ca,
  cs,
  cy,
  da,
  de,
  deAT,
  el,
  enAU,
  enCA,
  enGB,
  enIE,
  enIN,
  enNZ,
  enUS,
  enZA,
  eo,
  es,
  et,
  eu,
  faIR,
  fi,
  fr,
  frCA,
  frCH,
  fy,
  gd,
  gl,
  gu,
  he,
  hi,
  hr,
  ht,
  hu,
  hy,
  id,
  is,
  it,
  itCH,
  ja,
  jaHira,
  ka,
  kk,
  km,
  kn,
  ko,
  lb,
  lt,
  lv,
  mk,
  mn,
  ms,
  mt,
  nb,
  nl,
  nlBE,
  nn,
  oc,
  pl,
  pt,
  ptBR,
  ro,
  ru,
  sk,
  sl,
  sq,
  sr,
  srLatn,
  sv,
  ta,
  te,
  th,
  tr,
  ug,
  uk,
  uz,
  uzCyrl,
  vi,
  zhCN,
  zhHK,
  zhTW,
} from 'date-fns/locale';
import _ from 'lodash';


const getLng = (lng) => {
  switch (lng) {
    case 'af':
      return af;
    case 'ar':
      return ar;
    case 'arDZ':
      return arDZ;
    case 'arEG':
      return arEG;
    case 'arMA':
      return arMA;
    case 'arSA':
      return arSA;
    case 'arTN':
      return arTN;
    case 'az':
      return az;
    case 'be':
      return be;
    case 'beTarask':
      return beTarask;
    case 'bg':
      return bg;
    case 'bn':
      return bn;
    case 'bs':
      return bs;
    case 'ca':
      return ca;
    case 'cs':
      return cs;
    case 'cy':
      return cy;
    case 'da':
      return da;
    case 'de':
      return de;
    case 'deAT':
      return deAT;
    case 'el':
      return el;
    case 'enAU':
      return enAU;
    case 'enCA':
      return enCA;
    case 'enGB':
      return enGB;
    case 'enIE':
      return enIE;
    case 'enIN':
      return enIN;
    case 'enNZ':
      return enNZ;
    case 'enUS':
      return enUS;
    case 'enZA':
      return enZA;
    case 'eo':
      return eo;
    case 'es':
      return es;
    case 'et':
      return et;
    case 'eu':
      return eu;
    case 'faIR':
      return faIR;
    case 'fi':
      return fi;
    case 'frCA':
      return frCA;
    case 'frCH':
      return frCH;
    case 'fy':
      return fy;
    case 'gd':
      return gd;
    case 'gl':
      return gl;
    case 'gu':
      return gu;
    case 'he':
      return he;
    case 'hi':
      return hi;
    case 'hr':
      return hr;
    case 'ht':
      return ht;
    case 'hu':
      return hu;
    case 'hy':
      return hy;
    case 'id':
      return id;
    case 'is':
      return is;
    case 'it':
      return it;
    case 'itCH':
      return itCH;
    case 'ja':
      return ja;
    case 'jaHira':
      return jaHira;
    case 'ka':
      return ka;
    case 'kk':
      return kk;
    case 'km':
      return km;
    case 'kn':
      return kn;
    case 'ko':
      return ko;
    case 'lb':
      return lb;
    case 'lt':
      return lt;
    case 'lv':
      return lv;
    case 'mk':
      return mk;
    case 'mn':
      return mn;
    case 'ms':
      return ms;
    case 'mt':
      return mt;
    case 'nb':
      return nb;
    case 'nl':
      return nl;
    case 'nlBE':
      return nlBE;
    case 'nn':
      return nn;
    case 'oc':
      return oc;
    case 'pl':
      return pl;
    case 'pt':
      return pt;
    case 'ptBR':
      return ptBR;
    case 'ro':
      return ro;
    case 'ru':
      return ru;
    case 'sk':
      return sk;
    case 'sl':
      return sl;
    case 'sq':
      return sq;
    case 'sr':
      return sr;
    case 'srLatn':
      return srLatn;
    case 'sv':
      return sv;
    case 'ta':
      return ta;
    case 'te':
      return te;
    case 'th':
      return th;
    case 'tr':
      return tr;
    case 'ug':
      return ug;
    case 'uk':
      return uk;
    case 'uz':
      return uz;
    case 'uzCyrl':
      return uzCyrl;
    case 'vi':
      return vi;
    case 'zhCN':
      return zhCN;
    case 'zhHK':
      return zhHK;
    case 'zhTW':
      return zhTW;
    case 'fr':
    default:
      return fr;
  }
};


const setDefaultLng = (lng) => {
  setDefaultOptions({ locale: getLng(lng) });
};

function isSameOrBefore(date1, date2) {
  return differenceInYears(date1, date2) <= 0;
}

function toUTC(date) {
  return subMinutes(date, date.getTimezoneOffset());
}

const formatBirthdate = (date, isClinicalMode) => {
  return date ? format(parseDate(date), isClinicalMode ? 'MM/yyyy' : 'dd/MM/yyyy') : '';
};

const parseDate = (date) => {
  if (!date || isDate(date)) {
    return date;
  }
  if (_.isString(date)) {
    if (date.length === 10) {
      const getFormat = (date) => {
        if (!date) {
          return null;
        }
        if (date.includes('/')) {
          return 'dd/MM/yyyy';
        }
        if (date.includes('-')) {
          return 'yyyy-MM-dd';
        }
        return null;
      };
      const FORMAT = getFormat(date);
      return FORMAT ? parse(date, FORMAT, new Date()) : new Date(date);
    } else if (
      // "2022-12-27T15:29:18+01:00" or "2013-02-04T22:44:30.652Z"
      ((date.length === 24 && date.slice(-1) === 'Z') || (date.length === 25 && ['+', '-'].includes(date.slice(-6, -5))))
      && date.slice(10, 11) === 'T'
    ) {
      return parseISO(date);
    }
  }
  return;
};


export {
  setDefaultLng, getLng, isSameOrBefore, toUTC, parseDate, formatBirthdate,
};