import { createReducer } from 'reduxsauce';

import { types } from './actions';

const initialState = {
  token: null,
  refresh_token: null,
  token_type: 'Bearer',
  redirectPayload: null,
  redirectOnLogin: null,
};

const clearTokens = (state = { ...initialState } ) => {
  return {
    ...state,
    token: null,
    refresh_token: null,
    token_type: 'Bearer',
  };
};

// region Reducers
const setSuccess = (state = { ...initialState }, action = {} ) => {
  const { payload } = action;
  return {
    ...state,
    ...payload,
  };
};

const clear = (state = { ...initialState }) => {
  const { redirectPayload } = state;
  return { ...initialState, redirectPayload };
};

const setRedirectPayload = (state = { ...initialState }, action = {} ) => {
  const { url } = action;
  return {
    ...state,
    redirectPayload: url,
  };
};

const clearRedirectPayload = (state = { ...initialState }) => {
  return {
    ...state,
    redirectPayload: null,
  };
};

const setIsLoginFromQrCode = (state = { ...initialState }) => {
  return {
    ...state,
    isLoginFromQrCode: true,
  };
};

// endregion

export default createReducer(initialState, {
  [types.SET_SUCCESS]: setSuccess,
  [types.CLEAR]: clear,
  [types.CLEAR_TOKENS]: clearTokens,
  [types.SET_REDIRECT_PAYLOAD]: setRedirectPayload,
  [types.CLEAR_REDIRECT_PAYLOAD]: clearRedirectPayload,
  [types.SET_LOGIN_FROM_QR_CODE]: setIsLoginFromQrCode,

});
