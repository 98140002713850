import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { IconDeleteForm } from '../../../assets/img/icons';
import { AbilityContext } from '../../../services/casl/Can';
import { MODEL_NAMES, PATIENT_FIELDS, PERMISSIONS } from '../../../services/casl/roles';
import { secuNumbFormater } from '../../../utils';
import { useWindowDimensions } from '../../../utils/hooks';
import { capitalizeFirstLetter } from '../../../utils/utils';
import ConfirmBtn from '../ConfirmBtn';
import SkeletonItem from '../skeletonItem/SkeletonItem';
import ToolTip from '../tooltip';

const EditPatientInfoCard = ({
  title = '',
  birthname = '',
  gender = '',
  birthdate = '',
  birthplace = '',
  security_number,
  isloading,
  patientArchiveRequest,
  patientId,
  selectedTeam,
}) => {
  const { t } = useTranslation();
  const ability = useContext(AbilityContext);

  const isVisible = useMemo(() => ({
    security_number: ability.can(PERMISSIONS.READ, { __typename: MODEL_NAMES.PATIENT_FIELDS, value: PATIENT_FIELDS.SECURITY_NUMBER }),
    birthplace: ability.can(PERMISSIONS.READ, { __typename: MODEL_NAMES.PATIENT_FIELDS, value: PATIENT_FIELDS.BIRTHPLACE }),
  }), [ability]);

  const canDeletePatient = useMemo(() => ability.can(PERMISSIONS.DELETE, MODEL_NAMES.PATIENTS), [ability]);

  const { width: windowsWidth } = useWindowDimensions();
  const maxLength = (windowsWidth) => {
    switch (true) {
      case windowsWidth > 1015:
        return 25;
      case windowsWidth <= 900:
        return 28;
      case windowsWidth <= 1015:
        return 32;
      default:
        return 22;
    }
  };

  const handleConfirmClick = () => {
    if (canDeletePatient) {
      patientArchiveRequest(patientId, selectedTeam);
    }
  };

  return (
    <>
      <div className="infopatient-text-container">
        <div className='infopatient-title-container'>
          <div className='text-name-container'>
            {title && <ToolTip />}
            <p className={'infopatient-text-name'} data-tip={title.length > maxLength(windowsWidth) ? title : null}>
              {isloading ? t('patient_card_title') : title}
            </p>
          </div>
          <div className='archive-patient-container'>
            <ConfirmBtn
              title={t('confirm_archive_patient')}
              onConfirm={handleConfirmClick}
              action={'delete'}
              isOpen={false}
              disabled={!canDeletePatient}
            >
              <img
                className={'archive-patient'}
                src={IconDeleteForm}
                data-tip={t('medicPatient.history.list.actionBtn.archive')}
                alt="patient_button_delete"
              />
            </ConfirmBtn>
          </div>
        </div>
      </div>

      <div className={'infopatient-row-container'}>
        <p className={'infopatient-text-min'}>{t('patient_card_intervention_gender')}</p>
        <p className={'infopatient-text-max'}>
          {isloading ? <SkeletonItem width={65} height={20} animation /> : capitalizeFirstLetter(gender)}
        </p>
      </div>
      <div className={'infopatient-row-container'}>
        <p className={'infopatient-text-min'}>{t('patient_card_birthdate')}</p>
        <p className={'infopatient-text-max'}>
          {isloading ? <SkeletonItem width={100} height={20} animation /> : birthdate || ''}
        </p>
      </div>
      {((birthplace && birthplace !== '') || isloading) && (
        <div className={'infopatient-row-container'}>
          <p className={'infopatient-text-min'}>{t('patient_card_birthplace')}</p>
          <p className={'infopatient-text-max'}>
            {isloading ? <SkeletonItem width={100} height={20} animation /> : birthplace}
          </p>
        </div>
      )}
      {isVisible['security_number'] && (
        <div className={'infopatient-row-container'}>
          <p className={'infopatient-text-min'}>{t('patient_card_intervention_securite')}</p>
          <p className={'infopatient-text-max'}>
            {isloading ? (
              <SkeletonItem width={150} height={20} animation />
            ) : (
              secuNumbFormater(security_number)
            )}
          </p>
        </div>
      )}
      {birthname && (
        <div className={'infopatient-row-container'}>
          <p className={'infopatient-text-min'}>{t('patient_card_birthname')}</p>
          <p className={'infopatient-text-max'}>
            {isloading ? <SkeletonItem width={150} height={20} animation /> : birthname}
          </p>
        </div>
      )}
    </>
  );
};

export default EditPatientInfoCard;
