import './style.css';

import PropTypes from 'prop-types';
import React from 'react';

import ToolTip from '../../../components/tooltip';
import AvatarMedic from './AvatarMedic';

const AvatarMedicGroup = ({ data, limit }) => {
  const getFormatedData = () => {
    // const ret = [];
    if (typeof data === 'object' && data !== null) {
      data = Object.values(data);
    }
    const overLimit = data.length - limit > 0 && { id: '+', label: `+ ${data.length - limit}` };
    const ret = data.slice(0, overLimit ? limit - 1 : limit);
    return overLimit ? [...ret, overLimit] : ret;
  };
  return (
    <div className="ec-avatar-medic-group">
      <ToolTip />
      {getFormatedData().map((d, index) => (
        <AvatarMedic
          key={`${d.id}_${index}`}
          data={d}
        />
      ))}
    </div>
  );
};

export default AvatarMedicGroup;
AvatarMedicGroup.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      firstname: PropTypes.string,
      lastname: PropTypes.string,
    }),
  ),
};