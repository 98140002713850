import { createReducer } from 'reduxsauce';

import { types } from './actions';

const initialState = { medicFilter: 'patient' };

const setMedicFilter = (state = { ...initialState }, action = {} ) => {
  const { filter } = action;
  return {
    ...state,
    medicFilter: filter,
  };
};

const resetMedicFilter = (state = { ...initialState }) => ({
  ...state,
  medicFilter: 'patient',
});

const resetReducer = () => {
  return { ...initialState };
};

export default createReducer(initialState, {
  [types.RESET_REDUCER]: resetReducer,

  [types.SET_MEDIC_FILTER]: setMedicFilter,
  [types.RESET_MEDIC_FILTER]: resetMedicFilter,
});
