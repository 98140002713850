
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { forceEmptyCache } from '../../../utils/cache';
import { PUBLIC_HOME_PATH } from '../../../utils/const';
import { Button } from '../styledComponent';

function ClearCache({ resetAllReducers }) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleClick = async (event) => {
    event.preventDefault();
    await forceEmptyCache({ hardReload: false });
    resetAllReducers();
    navigate(PUBLIC_HOME_PATH);
  };

  return (
    <Button onClick={handleClick} type="button" variant="danger">
      {t('empty_cache')}
    </Button>
  );
}

export default ClearCache;

