

import { connect } from 'react-redux';

import { QuestionIntroActions } from '../../../../redux/questionIntro';
import { STEP_AFTER, STEP_BEFORE } from '../../../../redux/questionIntro/reducers';
import IntroQuestionsModal from './IntroQuestionsModal';

const mapStateToProps = ({ auth, questionIntro }, props) => {
  const { isAfter } = props;
  return {
    isLoading: questionIntro.loading,
    hasLoad: questionIntro.hasLoad,
    questionIntroList: questionIntro.list,
    currentIndex: questionIntro[isAfter ? STEP_AFTER : STEP_BEFORE],
    token: auth.token,
  };
};

const mapDispatchToProps = (dispatch) => ({
  resetStep: () => dispatch(QuestionIntroActions.resetStep()),
  incrementStep: (isAfter) => dispatch(QuestionIntroActions.incrementStep(isAfter)),
});

export default connect(mapStateToProps, mapDispatchToProps)(IntroQuestionsModal);
