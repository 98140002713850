
import _ from 'lodash';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { MedicService } from '../../services';
import { generateRulesFromData } from '../../services/casl/roles';
import { changeLanguage } from '../../services/i18n/i18n';
import { phoneNumberMapper } from '../../utils/dataMappers';
import { AuthActions } from '../auth';
import { DisplayActions } from '../display';
import { LoaderActions } from '../loader';
import { PatientActions } from '../patient';
import { SnackActions } from '../snackBar';
import { default as MedicActions, types } from './actions';

function* currentMedicRequest() {
  const [error, response] = yield call(MedicService.currentMedic);
  const medic = response?.data?.medic;
  if (error || !medic) {
    yield put(SnackActions.displayError('current_medic_error'));
    yield put(MedicActions.currentMedicFailure());
  } else {
    yield put(MedicActions.currentMedicSuccess(medic));
  }
}

function* fetchMedicTeamPatients({ teamId }) {
  if (!teamId) return;
  const { medicalTeamList, selectedTeam } = yield select((state) => state.medic);
  const teamPatients = _.get(medicalTeamList, [selectedTeam, 'patients'], []);
  if (!teamPatients.length) {
    yield put(LoaderActions.loading());
  }
  const [error, response] = yield call(MedicService.fetchMedicTeamPatients, teamId);
  if (error || (error && !response?.data)) {
    yield put(MedicActions.medicTeamFailure());
  } else {
    const patients = _.get(response, 'data.result', []);
    yield put(PatientActions.patientsSuccess(patients));
    yield put(MedicActions.medicTeamAddPatientList(teamId, patients));
  }
  yield put(LoaderActions.loaded());
}

function* fetchMedicTeamData() {
  const [error, response] = yield call(MedicService.fetchMedicTeamData);
  if (error || (error && !response.data)) {
    yield put(MedicActions.medicTeamFailure());
  } else {
    const teams = _.get(response, 'data.result', []);
    yield put(MedicActions.medicTeamSuccessV2(teams));
    const { selectedTeam } = yield select((state) => state.medic);
    const teamIds = Array.isArray(teams) && teams.map(el => el.id);
    if (Array.isArray(teamIds) && teamIds.length && (!selectedTeam || !teamIds.includes(selectedTeam))) {
      let defaultTeam = teams.find(el => el.isDefault);
      if (!defaultTeam) {
        defaultTeam = teams[0];
      }
      if (defaultTeam?.id) yield put(MedicActions.changeSelectedMedicTeamSuccess(defaultTeam?.id));
    }
  }
  yield put(LoaderActions.loaded());
}

function* updateMedicRequest({ medic }) {
  if (medic.phone_number) {
    const { local_phone_number, phone_number } = phoneNumberMapper(medic.phone_number);
    if (phone_number) {
      medic.phone_number = phone_number;
    }
    if (local_phone_number) {
      medic.local_phone_number = local_phone_number;
    }
  }
  const [error, response] = yield call(MedicService.updateMedic, medic);
  if (error) {
    yield put(SnackActions.displayError('update_medic_error'));
    yield put(MedicActions.updateMedicFailure());
  } else {
    const newUserLanguage = response && response.update_users && response.update_users.returning[0].language;
    changeLanguage(newUserLanguage.language_code);
    medic.user.language = newUserLanguage;
    yield put(SnackActions.displayInfo('update_medic_success'));
    yield put(MedicActions.updateMedicSuccess(medic));
  }
}

function* medicUpdateRGPDRequest({ id }) {
  const [error, response] = yield call(MedicService.updateRGPD, id);
  if (error) {
    yield put(SnackActions.displayError('patient_update_rgpd_error'));
  } else if (response && response.update_users.returning[0].accepted_rgpd) {
    yield put(SnackActions.displayInfo('patient_update_rgpd_success'));
    yield put(AuthActions.rgpdSuccess());
  }
}

function* medicUpdateCguRequest({ id }) {
  const [error, response] = yield call(MedicService.updateCGU, id);
  if (error) {
    yield put(SnackActions.displayError('patient_update_cgu_error'));
  } else if (response && response.update_users.returning[0].accepted_cgu) {
    yield put(SnackActions.displayInfo('patient_update_cgu_success'));
    yield put(AuthActions.cguSuccess());
  }
}

function* medicUpdateEditInfoRequest({ id }) {
  const [error, response] = yield call(MedicService.updateEditInfo, id);
  if (error) {
    yield put(SnackActions.displayError('medic_fetch_metrics_error'));
  } else if (response && response.update_users.returning[0].edit_info) {
    yield put(AuthActions.editInfoSuccess());
  }
}

function* changeSelectedMedicTeamRequest({ teamId }) {
  const { selectedTeam } = yield select((state) => state.medic);
  if (teamId !== selectedTeam) {
    yield put(PatientActions.startLoader());
  }
  yield put(MedicActions.changeSelectedMedicTeamSuccess(teamId));
}

function* setMedicalTeamParams() {
  const { medicalTeamList, selectedTeam, currentMedic } = yield select((state) => state.medic);
  if (selectedTeam && !_.isEmpty(medicalTeamList)) {
    if (Object.values(medicalTeamList || {}).some(el => `${el.id}` === `${selectedTeam}`)) {
      const medicalTeam = medicalTeamList[selectedTeam];
      const medicalTeamRole = _.isObject(medicalTeam.role) ? medicalTeam.role : Array.isArray(medicalTeam.role) ? medicalTeam.role.slice(0, 1) : {};
      yield put(DisplayActions.setAppMode(medicalTeam.is_clinical_study));
      yield put(AuthActions.saveAbilitiesRules(medicalTeamRole.permissions, 'medicalTeam'));
      yield put(AuthActions.addAbilitiesRules(generateRulesFromData(medicalTeamRole?.permissions || [], { medic: currentMedic, medicalTeam }), 'medicalTeam'));
    }
  }
}

function* medicUpdateNotif({ medic }) {
  const [error, response] = yield call(MedicService.updateNotif, medic);
  if (error) {
    yield put(SnackActions.displayError('Mise à jour erreur '));
  } else if (response && response.update_medics.returning[0]) {
    yield put(SnackActions.displayInfo('Mise à jour succès'));
    yield put(MedicActions.updateMedicSuccess(medic));
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  takeLatest(types.CURRENT_MEDIC_REQUEST, currentMedicRequest),
  takeLatest(types.MEDIC_UPDATE_CGU_REQUEST, medicUpdateCguRequest),
  takeLatest(types.MEDIC_UPDATE_RGPD_REQUEST, medicUpdateRGPDRequest),
  takeLatest(types.UPDATE_MEDIC_REQUEST, updateMedicRequest),
  takeLatest(types.MEDIC_UPDATE_NOTIF_REQUEST, medicUpdateNotif),
  takeLatest(types.MEDIC_UPDATE_EDIT_INFO_REQUEST, medicUpdateEditInfoRequest),
  takeLatest(types.MEDIC_FETCH_MEDIC_TEAM_DATA, fetchMedicTeamData),
  takeLatest(types.MEDIC_CHANGE_SELECTED_MEDIC_TEAM_REQUEST, changeSelectedMedicTeamRequest),
  takeLatest(types.FETCH_MEDIC_TEAM_PATIENTS, fetchMedicTeamPatients),
  takeLatest(types.SET_MEDICAL_TEAM_PARAMS, setMedicalTeamParams),

];
