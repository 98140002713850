export const resolveId = (router) => {
  return router.location.pathname.split('/').pop();
};

export const resolveObjectFromStore = (router, storage, key) => {
  const id = router.location.pathname.split('/').pop();
  const list = Array.isArray(storage) ? storage : Object.values(storage);
  const search = (p) => p[key] === id;
  const found = list.find(search);
  return found;
};

export const resolveQueryParam = (router, keys) => {
  return resolveQueryParamFromSearch(router.location.search, keys);
};

export const queryParamRemoveKeys = (searchParams, keys) => {
  keys.forEach(k => {
    if (searchParams.has(k)) {
      searchParams.delete(k);
    }
  });
  return searchParams;
};

export const resolveQueryParamFromSearch = (locationSearch, keys) => {
  const query = new URLSearchParams(locationSearch);
  let search = {};

  for (let i = 0, len = keys.length; i < len; ++i) {
    const value = query.get(keys[i]);
    if (value) {
      search[keys[i]] = decodeURIComponent(value);
    }
  }

  return search;
};


export function extractParams(searchParams) {
  const params = Object.fromEntries([...searchParams]);
  Object.keys(params || {}).forEach(function(key) {
    params[key] = decodeURIComponent(params[key]);
  });
  return params;
}

export function getParameterByName(name, url = window.location.href) {
  name = name.replace(/[[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function insertParam(urlParams, keys) {
  keys.forEach(({ key, value }) => {
    // key = encodeURIComponent(key);
    value = encodeURIComponent(value);
    urlParams.set(key, value);
  });

  return urlParams;
}

export function pathAndParamsToUrl(path, params) {

  Object.entries(params).forEach(([paramName, paramValue]) => {
    if (paramValue) {
      path = path.replace(new RegExp(`/:${paramName}(/|$)`), `/${paramValue}$1`);
    }
  });
  return path;
}