import { createReducer } from 'reduxsauce';

import { types } from './actions';

const initialState = { displayed: false };

// Reducers
const displayLoader = (state = { ...initialState }) => {
  return {
    ...state,
    displayed: true,
  };
};

const hideLoader = (state = { ...initialState }) => {
  return {
    ...state,
    displayed: false,
  };
};

const resetReducer = () => {
  return { ...initialState };
};

export default createReducer(initialState, {
  [types.RESET_REDUCER]: resetReducer,

  [types.LOADING]: displayLoader,
  [types.LOADED]: hideLoader,
});
