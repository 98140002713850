import './style.css';

import { PhoneNumberFormat, PhoneNumberType, PhoneNumberUtil } from 'google-libphonenumber';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { COUNTERSIGN_INVALID_NUMBER } from '../../../utils/const';
import { extractParams, insertParam } from '../../../utils/router-helper';
import Card from '../../components/card';
import NavigateBack from '../../components/navigateBack';
import { Button } from '../../components/styledComponent';
import AuthLayout from '../../layouts/authLayout';
import ProfilIDConnexion from './Idconnexion';
import ProfilInfo from './Info';
import MedicForm from './MedicForm';
import ProfilNotification from './Notification';
import ProfilSpecialites from './ProfilSpecialites';
import TeamsList from './teamsList';

const Profil = ({ currentMedic, medicTeam, updateMedicRequest, medicUpdateNotifRequest, specialitiesRequest, medicSpecialities, selectedTeam }) => {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const [edit, setEdit] = useState(false);
  const [messageEditPopup, setMessageEditPopup] = useState('');
  const [urlParams, setUrlParams] = useState();

  useEffect(() => {
    setUrlParams(extractParams(searchParams));
  }, [searchParams]);

  useEffect(() => {
    if (urlParams && urlParams.query === COUNTERSIGN_INVALID_NUMBER) {
      setEdit(true);
      setMessageEditPopup(t('countersign_invalid_number_redirect'));
    }
  }, [urlParams && urlParams.query]);

  useEffect(() => {
    if (specialitiesRequest) specialitiesRequest();
  }, [currentMedic]);

  const onBackClick = () => {
    navigate(selectedTeam ? `/teams/${selectedTeam}` : '/');
  };

  const onCloseEditModal = () => {
    setMessageEditPopup('');
    setEdit(false);
  };

  const handleSubmit = (newData) => {
    const phoneUtil = PhoneNumberUtil.getInstance();
    let phoneNumber = newData.phone_number && newData.phone_number.startsWith('+') ? newData.phone_number : `+${newData.phone_number}`;
    let localPhoneNumber = null;
    const parsedPhoneNumber = phoneUtil.parse(phoneNumber);
    if (phoneUtil.isValidNumber(parsedPhoneNumber) && phoneUtil.getNumberType(parsedPhoneNumber) === PhoneNumberType.MOBILE) {
      localPhoneNumber = phoneUtil.format(parsedPhoneNumber, PhoneNumberFormat.NATIONAL);
    }
    const newMedic = {
      id: currentMedic.id,
      rpps_number: newData.rpps_number,
      phone_number: phoneNumber,
      local_phone_number: localPhoneNumber,
      user: { uid: currentMedic.user.id, language_id: newData.user.language_id },
    };

    if (updateMedicRequest) updateMedicRequest(newMedic);
    const urlParamsCountersign = new URLSearchParams();
    const paramsCountersign = insertParam(urlParamsCountersign, [
      { key: 'csids', value: urlParams.csids },
      { key: 'index', value: urlParams.index },
    ]);
    const redirectCountersignInvalidNumber = `/patients/${urlParams.csidp}?${paramsCountersign.toString()}`;
    if (urlParams.csidp && urlParams.csids) navigate(redirectCountersignInvalidNumber);
    setEdit(!edit);
  };

  const handleNotificationChange = (e) => {
    const newMedic = {
      id: currentMedic.id,
      notif_mail: e.name === 'mail' ? e.value : currentMedic.notif_mail,
      notif_sms: e.name === 'sms' ? e.value : currentMedic.notif_sms,
    };
    if (medicUpdateNotifRequest) medicUpdateNotifRequest(newMedic);
  };

  return (
    <AuthLayout>
      <div className={'profil-navigate-back'}>
        <NavigateBack text={t('dashboard')} onClick={onBackClick} />
      </div>
      <Card>
        <div className={'profil-main-container'}>
          <Button className="profil-validate-button" onClick={() => setEdit(!edit)}>
            {t('edit')}
          </Button>
          <p className={'profil-title-text'}>{t('profil_coordonnees')}</p>
          <ProfilInfo info={currentMedic} />
          <div className={'profil-div-separte'} />
          <div className={'profil-block-container'}>
            <div className={'profil_row_container'}>
              <p className={'profil-title-text'}>{t('profil_id_connexion')}</p>
              <ProfilIDConnexion data={currentMedic} />
            </div>
            <div className={'profil_row_container'}>
              <p className={'profil-title-text'}>{t('profil_notifications')}</p>
              <ProfilNotification

                data={currentMedic}
                onChange={handleNotificationChange}
              />
            </div>
          </div>
          <div className={'profil-div-separte'} />
          <ProfilSpecialites

            specialities={medicSpecialities || []}
          />
          <div className={'profil-div-separte'} />
          <p className={'profil-title-text'}>{t('medicals_teams_title')}</p>
          <TeamsList data={medicTeam} />
        </div>
      </Card>
      <MedicForm
        medic={_.pick(currentMedic, ['firstname', 'lastname', 'rpps_number', 'phone_number', 'local_phone_number', 'country', 'user.language_id'])}
        onClose={onCloseEditModal}
        onSubmit={handleSubmit}
        message={messageEditPopup}
        isOpen={edit}
      />
    </AuthLayout>
  );
};

export default Profil;

Profil.propTypes = {
  currentMedic: PropTypes.object,
  medicTeam: PropTypes.array,
  updateMedicRequest: PropTypes.func,
  specialitiesRequest: PropTypes.func,
  medicSpecialities: PropTypes.array,
};