
import { SIDE_CONST } from '../../../../utils/enum';
import yup from '../../../../utils/yup';
import { yupStep } from '../../createFormV2/FormModel/validationSchema';

const yupPreviewSchema = yup.object().shape({
  old_id: yup.string(),
  title: yup.string('surveyCreateForm.title.format.required').required('surveyCreateForm.title.rules.required').trim(),
  template_id: yup
    .array()
    .of(
      yup.string(),
    )
    .nullable(),
  clinic_id: yup.string().required('error'),
  patient_id: yup.string().required('error'),
  type_id: yup.string().required('error'),
  speciality_id: yup.string().required('error'),
  referent_medic_id: yup.string().required('error'),
  medical_teams_id: yup.string().required('error'),
  medic_surveys: yup
    .array()
    .of(
      yup.string(),
    )
    .nullable()
    .min(1, 'error'),
  radius: yup
    .array()
    .of(
      yup.object().shape({
        isDisabled: yup.boolean(),
        value: yup.number(),
        label: yup.string(),
      }))
    .nullable(),
  side: yup
    .array()
    .of(
      yup.mixed().oneOf(Object.values(SIDE_CONST)),
    )
    .nullable(),
  intervention: yup
    .object()
    .shape({
      date_consult: yup.string().required('error'),
      date: yup.string().required('error'),
    })
    .nullable()
    .required(),
  order: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.number(),
        order: yup.number(),
      })),
  steps: yup.array().of(yupStep)
    .when('survey_pdfs', {
      is: pdf => {
        return !pdf || !pdf.length;
      },
      then: (schema) => schema.min(1),
      otherwise: (schema) => schema.min(0),
    }),
  survey_pdfs: yup
    .array()
    .of(yup.object().shape({
      id: yup.number(),
      loid: yup.number(),
      name: yup.string(),
    }),
    ),
});

export default yupPreviewSchema;
export { yupPreviewSchema };