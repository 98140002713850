import { connect } from 'react-redux';

import { AuthActions } from '../../redux/auth';
import { SplashActions } from '../../redux/splash';
import RedirectOnceLogin from './RedirectOnceLogin';


const mapStateToProps = ({ auth, splash }) => {
  return {
    token: auth.token,
    userRole: auth.user?.role,
    isUserLoggedIn: auth.isUserLoggedIn,
    isUserLoading: auth.isUserLoading,
    redirectOnLogin: splash.redirectOnLogin,
    user:auth.user,
    loginInfoSuccess: auth.loginInfoSuccess,
    splash: splash,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getLoginInfo: () => dispatch(AuthActions.getLoginInfo()),
  setRedirectUrl: (url) => dispatch(AuthActions.setRedirectUrl(url)),
  clearSplash: (url) => dispatch(SplashActions.clear(url)),

});


export default connect(mapStateToProps, mapDispatchToProps)(RedirectOnceLogin);
