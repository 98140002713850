// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.small-loader {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.small-loader div {
  display: inline-block;
  position: absolute;
  left: 6px;
  width: 13px;
  background: rgba(0, 0, 0, 0.5);
  animation: smallLoaderAnimation 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.small-loader div:nth-child(1) {
  left: 6px;
  animation-delay: -0.24s;
}
.small-loader div:nth-child(2) {
  left: 26px;
  animation-delay: -0.12s;
}
.small-loader div:nth-child(3) {
  left: 45px;
  animation-delay: 0s;
}
@keyframes smallLoaderAnimation {
  0% {
    top: 6px;
    height: 51px;
  }
  50%, 100% {
    top: 19px;
    height: 26px;
  }
}`, "",{"version":3,"sources":["webpack://./src/ui/components/loaders/SmallLoader/style.scss"],"names":[],"mappings":"AAEA;EACE,qBAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;AADF;AAGE;EACE,qBAAA;EACA,kBAAA;EACA,SAAA;EACA,WAAA;EACA,8BAbM;EAeN,0EAAA;AADJ;AAGI;EACE,SAAA;EAEA,uBAAA;AADN;AAII;EACE,UAAA;EAEA,uBAAA;AAFN;AAKI;EACE,UAAA;EAEA,mBAAA;AAHN;AAmBA;EACE;IAAK,QAAA;IAAU,YAAA;EAJf;EAKA;IAAY,SAAA;IAAW,YAAA;EADvB;AACF","sourcesContent":["$black50: rgba(0, 0, 0, 0.5);\r\n\r\n.small-loader {\r\n  display: inline-block;\r\n  position: relative;\r\n  width: 64px;\r\n  height: 64px;\r\n\r\n  div {\r\n    display: inline-block;\r\n    position: absolute;\r\n    left: 6px;\r\n    width: 13px;\r\n    background: $black50;\r\n    -webkit-animation: smallLoaderAnimation 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;\r\n    animation: smallLoaderAnimation 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;\r\n\r\n    &:nth-child(1) {\r\n      left: 6px;\r\n      -webkit-animation-delay: -0.24s;\r\n      animation-delay: -0.24s;\r\n    }\r\n\r\n    &:nth-child(2) {\r\n      left: 26px;\r\n      -webkit-animation-delay: -0.12s;\r\n      animation-delay: -0.12s;\r\n    }\r\n\r\n    &:nth-child(3) {\r\n      left: 45px;\r\n      -webkit-animation-delay: 0s;\r\n      animation-delay: 0s;\r\n    }\r\n  }\r\n}\r\n\r\n@-webkit-keyframes smallLoaderAnimation {\r\n  0% {\r\n    top: 6px;\r\n    height: 51px;\r\n  }\r\n  50%, 100% {\r\n    top: 19px;\r\n    height: 26px;\r\n  }\r\n}\r\n\r\n@keyframes smallLoaderAnimation {\r\n  0% { top: 6px; height: 51px;  }\r\n  50%, 100% { top: 19px; height: 26px; }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
