// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.listSelectField-container {
  overflow: visible;
  overscroll-behavior-y: contain;
  border-radius: 8px;
  transition: height 1.5s;
  width: 100%;
}
.listSelectField-tile {
  background-color: #f8f8f8;
  padding: 10px 0px 10px 15px;
  margin: 5px 0;
  border-radius: 8px;
  object-fit: cover;
  cursor: pointer;
  width: 100%;
  height: 100%;
}
.listSelectField-tile:hover {
  background-color: #06c3d4;
  color: #ffffff;
}`, "",{"version":3,"sources":["webpack://./src/ui/components/formV3/controllers/ListSelectField/style.scss"],"names":[],"mappings":"AACE;EACE,iBAAA;EACA,8BAAA;EACA,kBAAA;EAKA,uBAAA;EACA,WAAA;AAAJ;AAGE;EACE,yBAAA;EACA,2BAAA;EACA,aAAA;EACA,kBAAA;EACA,iBAAA;EACA,eAAA;EACA,WAAA;EACA,YAAA;AADJ;AAGI;EACE,yBAAA;EACA,cAAA;AADN","sourcesContent":[".listSelectField {\n  &-container {\n    overflow: visible;\n    overscroll-behavior-y: contain;\n    border-radius: 8px;\n    -moz-transition: height 1.5s;\n    -ms-transition: height 1.5s;\n    -o-transition: height 1.5s;\n    -webkit-transition: height 1.5s;\n    transition: height 1.5s;\n    width: 100%;\n  }\n\n  &-tile {\n    background-color: #f8f8f8;\n    padding: 10px 0px 10px 15px;\n    margin: 5px 0;\n    border-radius: 8px;\n    object-fit: cover;\n    cursor: pointer;\n    width: 100%;\n    height: 100%;\n\n    &:hover {\n      background-color: #06c3d4;\n      color: #ffffff;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
