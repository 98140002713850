import '../style.scss';

import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import ErrorMessage from '../errors/ErrorMessage';

function SurveyFormController({ name, label, rules, controlClassName, labelClassName, isRequiredField, children, classNameError, hideError, ...rest }) {
  return (
    <div className={`${controlClassName || 'd-flex flex-column w-100'}`}>
      {label && (
        <label
          className={`${labelClassName || 'form-item-label '} ${
            isRequiredField || (_.get(rules, 'required.value') || _.get(rules, 'required', false)) === true ? 'required' : ''
          }`}
          htmlFor={name}
        >
          {label || ''}
        </label>
      )}
      <div >
        { children
          && React.Children.map(children, (child) => {
            return child
              ? React.createElement(child.type, {
                ...{
                  key: `custom-formV2-control_${name}`,
                  name,
                  rules,
                  ...rest,
                  ...child.props,
                },
              })
              : child;
          })}
      </div>
      { !hideError && <ErrorMessage name={name} hideError={hideError} classNameError={classNameError} />}
    </div>
  );
}

SurveyFormController.propTypes = {
  register: PropTypes.func,
  name: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.shape({ message: PropTypes.string }),
  controlClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  classNameError: PropTypes.string,
  isRequiredField: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default SurveyFormController;
