import './style.css';

import React, { useEffect, useRef, useState } from 'react';
import { IconContext } from 'react-icons';
import { AiOutlineCloseCircle } from 'react-icons/ai';

import { isNullOrWhitespace } from '../../../utils/utils';

const Modal = ({
  isOpen,
  onClose,
  title,
  hideCloseBtn,
  fullWidth,
  fullHeight,
  scrollable,
  children,
  titleClassName,
  style,
  alwaysDisplayHtml,
}) => {
  const refTitle = useRef();
  const [modalClasses, setModalClasses] = useState();

  const modalRef = useRef(null);

  useEffect(() => {
    if (refTitle.current) refTitle.current.innerHTML = title || '';
  }, [title, refTitle && refTitle.current]);

  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'auto';
    setModalClasses(
      `ec-modal ${modalRef.current && modalRef.current.clientHeight > 650 ? 'large-modal' : ''} ${fullWidth ? 'full-width' : ''} ${fullHeight ? 'full-height' : ''}`,
    );
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen, modalRef, fullWidth, fullHeight]);

  return (
    !isOpen && !alwaysDisplayHtml
      ? null
      : <div className={`ec-modal-wrapper ${isOpen ? '' : 'd-none'}`} style={{ overflowY: scrollable ? 'initial !important' : 'auto' }}>
        <div className={`ec-modal-dialog ${modalClasses}`} >
          <div className={`${scrollable ? 'ec-modal-scrollable' : ''} ec-modal-content `} style={style}>
            {(!isNullOrWhitespace(title) || !hideCloseBtn) && (
              <div className={titleClassName || 'mx-4 my-2 ec-modal-header d-flex align-items-start justify-content-between'}>
                {!isNullOrWhitespace(title) && refTitle && <h3 ref={refTitle}>{title}</h3>}
                { !hideCloseBtn && (
                  <button style={{ cursor: 'pointer' }} onClick={onClose} className={'ec-modal-close-btn'} type="button">
                    <IconContext.Provider value={{ color: '#00aecb', size: '30px' }}>
                      <div>
                        <AiOutlineCloseCircle />
                      </div>
                    </IconContext.Provider>
                  </button>
                )}
              </div>
            )}
            <div
            // className={`${scrollable ? 'ec-modal-scrollable' : ''}`}
              ref={modalRef}>
              <div className={'ec-modal-contain'}>
                {React.Children.map(children, (child, index) => {
                  return child && child.type.name !== 'ModalButtons'
                    ? child
                    : null;
                })}
                {/* {children} */}
              </div>
            </div>
            {React.Children.map(children, (child, index) => {
              return child && child.type.name === 'ModalButtons'
                ? child
                : null;
            })}
          </div>
        </div>
        <div className={'ec-backdrop'}></div>
      </div>
  );
};

export default Modal;
