import _ from 'lodash';
import { memo } from 'react';

import ContactFormContainer from '../../../patientFormV2/Steps/ContactFormContainer';
import InfosFormContainer from '../../../patientFormV2/Steps/InfosFormContainer';
import { IS_DEBUG, MAX_TUTORS } from '../definitions';

const tutorFormAddingStep = {
  INFO_TUTOR: 1,
  CONTACT_TUTOR: 2,
};

function DisplayingStepAddingConsent ({
  previousFormStep,
  completeFormStep,
  formStep,
  formValues,
  displayedAdditionOfATutor,
}) {
  return formStep !== tutorFormAddingStep.INFO_TUTOR ? (
    <ContactFormContainer
      defaultValues={_.get(formValues, `tutor.${formStep}`)}
      onCancel={previousFormStep}
      id={`tutors.${formStep}.contact`}
      debug={IS_DEBUG}
      isNotFinalStep={displayedAdditionOfATutor < MAX_TUTORS - 1}
      onSubmit={(val) => completeFormStep(val, `tutor.${formStep}`)}
      submitLabel={'ajouter'}
      typeTutor={displayedAdditionOfATutor}
    />) : (
    <InfosFormContainer
      defaultValues={_.get(formValues, `tutor.${formStep}`)}
      onSubmit={(val) => completeFormStep(val, `tutor.${formStep}`)}
      onCancel={previousFormStep}
      id={`tutors.${formStep}.info`}
      isTutor={true}
      debug={IS_DEBUG}
    />);
}

export default memo(DisplayingStepAddingConsent);