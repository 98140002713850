import './style.css';

import React from 'react';
import ReactTooltip from 'react-tooltip';

const ToolTip = ({ props }) => (
  <ReactTooltip
    backgroundColor={'white'}
    textColor={'#00aecb'}
    className={'ec-tooltip'}
    place={'top'}
    multiline={true}
    {...props}
  />
);

export default ToolTip;
