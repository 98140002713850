
import React, { forwardRef, useImperativeHandle } from 'react';
import { useFormContext } from 'react-hook-form';

import { CONTENT_TYPE_MAPPER } from '../../../../../utils/enum';

const FileUploader = ({
  name,
  buttonClassName,
  buttonTitle,
  buttonTitleClassName,
  accept,
  uploadIcon,
  iconInside,
  iconClassname,
  disabled,
  tooltip,
  onConfirm,
}, ref2,
) => {
  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef(null);
  useImperativeHandle(ref2, () => ({ toggleInput: () => hiddenFileInput.current.click() }));

  const methods = useFormContext();
  const { register } = methods;
  const { ref, onChange, ...field } = register(name);

  const handleClick = (event) => {
    event.preventDefault();
    if (onConfirm) {
      onConfirm(event);
    } else if (!disabled) hiddenFileInput.current.click();
  };

  const onCustomChange = (event) => {
    const selectedFiles = Array.from(event?.target?.files || []);
    const hasError = selectedFiles.some(file => !(file.type && accept && accept.includes(file.type)));
    if (!hasError) {
      onChange(event);
      // methods.setValue(name, event.target.files[0]);
    }
  };

  return (
    <>
      { uploadIcon && !iconInside
        ? (
          <button className='btn-link d-flex justify-content-center ec-primary-button-img-container' onClick={handleClick} >
            <img src={uploadIcon} className={iconClassname || ''} data-tip={tooltip} alt="upload" />
          </button>
        ) : (
          <button className={buttonClassName} onClick={handleClick} data-tip={tooltip}>
            {iconInside && uploadIcon && <img src={uploadIcon} alt="upload" />}
            <p className={buttonTitleClassName}>{buttonTitle}</p>
          </button>
        )}
      <input
        type="file"
        {...field}
        onChange={onCustomChange}
        ref={(e) => {
          ref(e);
          hiddenFileInput.current = e; // you can still assign to ref
        }}
        accept={accept != null ? accept : CONTENT_TYPE_MAPPER.PDF}
        style={{ display: 'none' }}
        disabled={disabled}
      />
    </>
  );
};

export default forwardRef(FileUploader);
