// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ec-delete-popup {
  position: absolute;
  z-index: 1;
  width: 250px;
  display: flex;
  right: 0;
  top: 0;
}

.ec-delete-popup .ec-card{
  text-align: center;
  margin: 10px;
  padding: 10px;
}

.ec-delete-popup-title {
  margin: 0 10px;
}

.ec-delete-popup-answers-containers {
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
}

.ec-delete-popup-answer {
  padding: 5px 15px;
  margin: 5px 0px 5px 0px;
}

.ec-delete-popup-first-answer {
  color: #00d7e7;
}

.ec-delete-popup-first-answer:hover {
  color: #00aecb;
  cursor: pointer;
}

.ec-delete-popup-second-answer {
  margin-right: 5px;
  color: #ed695a;
}

.ec-delete-popup-second-answer:hover {
  color: #9e1000;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/ui/components/confirmPopup/style.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,UAAU;EACV,YAAY;EACZ,aAAa;EACb,QAAQ;EACR,MAAM;AACR;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,6BAA6B;AAC/B;;AAEA;EACE,iBAAiB;EACjB,uBAAuB;AACzB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,eAAe;AACjB","sourcesContent":[".ec-delete-popup {\n  position: absolute;\n  z-index: 1;\n  width: 250px;\n  display: flex;\n  right: 0;\n  top: 0;\n}\n\n.ec-delete-popup .ec-card{\n  text-align: center;\n  margin: 10px;\n  padding: 10px;\n}\n\n.ec-delete-popup-title {\n  margin: 0 10px;\n}\n\n.ec-delete-popup-answers-containers {\n  margin-top: 10px;\n  display: flex;\n  justify-content: space-around;\n}\n\n.ec-delete-popup-answer {\n  padding: 5px 15px;\n  margin: 5px 0px 5px 0px;\n}\n\n.ec-delete-popup-first-answer {\n  color: #00d7e7;\n}\n\n.ec-delete-popup-first-answer:hover {\n  color: #00aecb;\n  cursor: pointer;\n}\n\n.ec-delete-popup-second-answer {\n  margin-right: 5px;\n  color: #ed695a;\n}\n\n.ec-delete-popup-second-answer:hover {\n  color: #9e1000;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
