import './Paint.css';

import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CONTENT_TYPE_MAPPER } from '../../../utils/enum';
import FileUploader from '../fileUploadButton';
import Paint, { ACTIONS } from './Paint';

const PaintForm = ( { imgSrc, onImageChange, onPaintChange, savedData, noBackground, onReset }, ref ) => {
  const { t } = useTranslation();
  const [size, setSize] = useState([0, 0]);
  const [oldImg, setOldImg] = useState(null);
  const paintRef = useRef({});

  useImperativeHandle(ref, () => ({
    getSaveData() {
      if (paintRef && paintRef.current) {
        return paintRef.current.getSaveData();
      }
    },
    setOldImg: () => setOldImg(imgSrc),
  }));

  const handlerFileUpload = async (file) => {
    if (onImageChange) {
      onImageChange({ imgSrc: file, noBackground: false });
    }
  };

  const handleNoBackgroundClick = () => {
    if (onImageChange) {
      onImageChange({ imgSrc: null, noBackground: true });
    }
  };

  const onReturn = () => {
    if (onImageChange) {
      onImageChange(null);
    }
  };

  const handleOnPaintChange = (data) => {
    if (onPaintChange) {
      onPaintChange(data);
    }
  };

  const onDelete = () => {
    if (onImageChange) {
      onImageChange({ imgSrc: null, noBackground: false });
    }
  };

  const onClick = (action) => {
    switch (action) {
      case ACTIONS.DELETE:
        if (onImageChange) {
          onImageChange({ imgSrc: null, noBackground: false });
        }
        break;
      case ACTIONS.RETURN:
        setOldImg(imgSrc);
        if (onImageChange) {
          onImageChange(null);
        }
        break;
      case ACTIONS.CLEAR:
        if (onReset) {
          onReset();
        }
        break;
      case ACTIONS.IMAGE:
        if (onImageChange) {
          onImageChange({ imgSrc: oldImg, noBackground: false });
        }
        setOldImg(null);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const data = imgSrc != null ? URL.createObjectURL(imgSrc) : null;
  return (
    <>
      {imgSrc == null && noBackground === false && (
        <div className={'paint-button-content'}>
          <FileUploader
            buttonClassName={'ec-modal-button-second ec-modal-button-text'}
            buttonTitle={t('add_an_image')}
            accept={`${CONTENT_TYPE_MAPPER.IMAGES}`}
            handleFile={(file) => handlerFileUpload(file)}
          />
          { oldImg && (
            <button
              className={'ec-modal-button-second ec-modal-button-text'}
              onClick={() => onClick(ACTIONS.IMAGE)}
              type="button"
            >
              <p> {t('return_to_image')} </p>
            </button>
          )}
          <button
            className={'ec-modal-button-second ec-modal-button-text'}
            onClick={handleNoBackgroundClick}
            type="button"
          >
            <p> {t('no_background')} </p>
          </button>
        </div>
      )}
      {(data || noBackground === true) && (
        <Paint
          ref={paintRef}
          size={size}
          imgSrc={data}
          onImageChange={handleOnPaintChange}
          onClick={onClick}
          onReturn={onReturn}
          savedData={savedData}
          onDelete={onDelete}
        />
      )}
    </>
  );
};

export default forwardRef(PaintForm);
