/**
 * GRAPHQL QUERIES
 */

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getAll: () => `
    query getTemplates($languageIds: [Int!] = [1]) {
      templates {
        id
        speciality_id
        type_id
        translation: templates_translations(where: {language_id: {_in: $languageIds}}) {
          language_id
          title
          language_id
        }
      }
    }
  `,
  getDetails: () => `
    query GetDetails($templateId: Int! = 941, $languageCode: String = "fr-FR") {
      templates(where: {id: {_eq: $templateId}}) {
        id
        radius
        side
        speciality_id
        type_id
        templates_translations(where: {language: {language_code: {_eq: $languageCode}}}) {
          title
          description
        }
        step_templates(order_by: {step: {number: asc}}) {
          step {
            id
            number
            steps_translations(where: {language: {language_code: {_eq: $languageCode}}}) {
              title
            }
            questions(order_by: {number: asc}) {
              id
              asked_more_info
              number
              type_id
              questions_translations(where: {language: {language_code: {_eq: $languageCode}}}) {
                data
                desc
                incompris
                question
                rewording
                texte_pdf
              }
            }
          }
        }
      }
    }
  }
  
  `,
};
