
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { CONTENT_TYPE_MAPPER } from '../../../../../utils/enum';
import PDFViewer from '../../../PDFViewer';

function InputFileUpload({
  name,
  label,
  rules = { required: false },
  disabled,
  defaultValue,
  className,
  accept=CONTENT_TYPE_MAPPER.PDF,
  onConfirm,
  uploadIcon,
  iconClassname,
  tooltip,
  iconInside,
  buttonTitle,
  buttonClassName,
  buttonTitleClassName,
  ...rest
}) {
  const hiddenFileInput = useRef(null);
  const [state, setState] = useState({
    file: undefined,
    imagePreviewUrl: undefined,
  });
  const { control } = useFormContext(); // retrieve all hook methods
  const {
    field: { onChange, value, ref, ...field }, // field : { onChange, onBlur, value, name: fieldName, ref, ...field },
    fieldState: { error }, // fieldState: { invalid, isTouched, isDirty, error },
    // formState: { errors, isDirty, isSubmitting, touched, submitCount },
  } = useController({
    name,
    control,
    rules: { ...rules },
    defaultValue: defaultValue || _.get(control, ['defaultValuesRef', 'current', name]) || '',
  });

  const handleChange = (event) => {
    event.preventDefault();
    let reader = new FileReader();
    const file = event.target.files[0];
    if (file) {
      reader.onloadend = () => {
        setState({
          file: file,
          imagePreviewUrl: reader.result,
        });
      };
      reader.readAsDataURL(file);
      onChange(file);
    }
  };

  const handleClick = (event) => {
    if (onConfirm) {
      onConfirm();
    } else if (!disabled) {
      hiddenFileInput.current.click();
    }
  };

  const showPreloadImage = () => {
    const { file, imagePreviewUrl } = state;
    if (error) {
      return <div>erreur</div>;
    } else if (file) {
      if (file?.type !== CONTENT_TYPE_MAPPER.PDF) {
        return <img src={imagePreviewUrl} alt="preview" />;
      } else {
        return <></>;
      }
    } else {
      return <div>folder</div>;
    }
  };

  return (
    <>
      {uploadIcon && !iconInside ? (
        <img src={uploadIcon} onClick={handleClick} className={iconClassname || ''} data-tip={tooltip} alt="upload" />
      ) : (
        <button className={buttonClassName} onClick={handleClick} data-tip={tooltip} type="button">
          {iconInside && uploadIcon && <img src={uploadIcon} alt="upload" />}
          <p className={buttonTitleClassName}>{buttonTitle}</p>
        </button>
      )}
      <input
        type="file"
        accept={accept}
        style={{ display: 'none' }}
        ref={(e) => {
          ref(e);
          hiddenFileInput.current = e; // you can still assign to ref
        }}
        onChange={handleChange}
        {...rest}
        {...field}
      />
      {showPreloadImage()}

      {value && (
        <>
          <h4>File</h4> {value.name} - {value.size} bytes
          <PDFViewer documentData={state && state.file} />
          <br />
        </>
      )}
    </>
  );
}

InputFileUpload.propTypes = {
  register: PropTypes.func,
  name: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  accept: PropTypes.oneOf([
    'image/jpg',
    'image/jpeg',
    'image/gif',
    'image/png',
    'application/pdf',
  ]),
};

export default InputFileUpload;
