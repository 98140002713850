
import PropTypes from 'prop-types';
import React from 'react';

import useDeviceDetect from '../../../../utils/hooks/useDeviceDetect';

const ModalBtnContainer = ({ children }) => {
  const { isFirefox } = useDeviceDetect();

  return (
    <div className={`ec-modal-footer ${isFirefox ? 'ec-modal-buttons-firefox-container' : 'ec-modal-buttons-container'}`}>
      { children }
    </div>
  );
};

export default ModalBtnContainer;

ModalBtnContainer.propTypes = {
  defaultValues: PropTypes.object,
  children: PropTypes.node.isRequired,
  onSubmit: PropTypes.func,
  yupSchema: PropTypes.object,
  formClassName: PropTypes.string,
};

