
import './style.css';

import PropTypes from 'prop-types';
import React from 'react';

import useDeviceDetect from '../../../../utils/hooks/useDeviceDetect';
import { Button } from '../../styledComponent';

const ModalButton = ({ label, buttonStyle, disabled, onClick, hidden, type, variant = 'primary' }) => {
  const { isSafari, isFirefox } = useDeviceDetect();

  return (
    <div className={`${isFirefox ? 'ec-modal-buttons-firefox-container': 'ec-modal-buttons-container'} ${hidden ? 'invisible' : ''}`}>
      <Button
        variant={variant}
        className={buttonStyle || `${isSafari ? 'ec-modal-button-safari-text' : 'ec-modal-button-text'}`}
        onClick={onClick}
        disabled={disabled}
        type={type}
      >
        {label}
      </Button>
    </div>
  );
};

ModalButton.propTypes = {
  label: PropTypes.string.isRequired,
  buttonStyle: PropTypes.string,
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  variant: PropTypes.string,
};

export default ModalButton;
