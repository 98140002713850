import _ from 'lodash';
import { createReducer } from 'reduxsauce';

import { editLetterCaseUsers, SURVEY_STATUS } from '../../utils';
import { types } from './actions';

export const initialState = {
  list: {},
  patientPerTeam: {},
  loading: false,
  loadingPatient: false,
  loadingTutor: false,
  available: false,
  isEmailAvailable: null,
  emailLoading: false,
  indexCurrentPatient: 0,
  currentPatientList: [],
  currentPatient: {},
  currentTutored: {},
  legalSuccess: false,
  uploadSuccess: false,
  selectedPatient: {},
  selectedPatientIsAutonomous: true,
};

const PATIENT_AUTONOMOUS = 0;

/**
 * @param state
 * @returns {{list: {}, loading: boolean}}
 */
const startLoader = (state = { ...initialState }) => ({
  ...state,
  loading: true,
});

/**
 * @param state
 * @returns {{list: {}, loading: boolean}}
 */
const stopLoader = (state = { ...initialState }) => ({
  ...state,
  loading: false,
});

/**
 * @param state
 * @returns {{list: {}, loading: boolean}}
 */
const stopPatientLoader = (state = { ...initialState }) => ({
  ...state,
  loadingPatient: false,
});

/**
 * @param state
 * @returns {{list: {}, loading: boolean}}
 */
const startTutorLoader = (state = { ...initialState }) => ({
  ...state,
  loadingTutor: true,
});

/**
 * @param state
 * @returns {{list: {}, loading: boolean}}
 */
const stopTutorLoader = (state = { ...initialState }) => ({
  ...state,
  loadingTutor: false,
});

/**
 * Save many patients. It will erase old value
 * @param state
 * @param action
 * @returns {{list: {}, loading: boolean}}
 */
const patientsSuccess = (state = { ...initialState }, action = {}) => {
  const { patients, shouldMerge } = action;
  const { list } = state;
  const newList = _.keyBy(patients.map(p => editLetterCaseUsers(p)), 'id');
  return {
    ...state,
    list: shouldMerge ? _.merge(list, newList) : newList,
    loading: false, /*, hasMore: patients.length > 0 */
  };
};

/**
 * Save a single patient
 * @param state
 * @param action
 * @returns {{list: {}, loading: boolean}}
 */

const patientDetailSuccess = (state = { ...initialState }, action = {}) => {
  const { list, selectedPatient } = state;
  const { patient } = action;
  const newSelectedPatient = editLetterCaseUsers(patient);

  return {
    ...state,
    list: { ...list, [newSelectedPatient.id]: _.merge(list[newSelectedPatient.id], newSelectedPatient) },
    selectedPatient: selectedPatient && selectedPatient.id === newSelectedPatient.id ? _.merge(selectedPatient, newSelectedPatient) : newSelectedPatient,
    loading: false,
  };
};

const patientDetailSurveySuccess = (state = { ...initialState }, action = {}) => {
  const { survey } = action;
  const { list } = state;
  let data = list;
  const patient = data[survey.patient_id] || {};
  const { surveys } = patient;
  if (!surveys || !surveys.length) {
    return {
      ...state,
      list: data,
    };
  }
  patient.surveys = surveys.map((s) => {
    if (s.id === survey.id) {
      return { ...s, ...survey };
    }
    return s;
  });
  data[survey.patient_id] = editLetterCaseUsers(patient);
  return {
    ...state,
    list: data,
    uploadSuccess: true,
  };
};

const patientDetailSurveyRefresh = (state = initialState, action = {}) => {
  const { survey } = action;
  const updatedSurvey = Object.values(survey);
  const { selectedPatient, list } = state;
  let newSelectedPatient = { ...selectedPatient };
  let newPatientsList = { ...list };

  updatedSurvey.forEach((s, index) => {
    if (`${s.patient_id}` === `${selectedPatient.id}`) {
      if (s.id) {
        const i = _.get(selectedPatient, 'surveys', []).findIndex(item => item.id === updatedSurvey.id);
        const selectedSurvey = _.get(selectedPatient, ['surveys', i]);
        newSelectedPatient = _.update(selectedPatient, ['surveys', i], () => _.merge(selectedSurvey, updatedSurvey));
      } else {
        newSelectedPatient.surveys = Array.isArray(newSelectedPatient.surveys) ? [...newSelectedPatient.surveys, s] : [s];
      }
    }
    newPatientsList = {
      ...newPatientsList,
      [s.id || index]: s,
    };
  });

  return {
    ...state,
    list: newPatientsList,
    selectedPatient: newSelectedPatient,
  };
};

const patientDetailSurveyStatusRefresh = (state = { ...initialState }, action = {}) => {
  const { id, status } = action;
  const { selectedPatient, list } = state;

  const index = _.get(selectedPatient, 'surveys', []).findIndex(item => item.id === id);
  const newSurvey = {
    ...selectedPatient.surveys[index],
    status: status,
  };
  const newSelectedPatient = _.update(selectedPatient, ['surveys', index], () => newSurvey);

  const index2 = _.get(list, [selectedPatient.id, 'surveys'], []).findIndex(item => item.id === id);
  const newList = _.update(list, [selectedPatient.id, 'surveys', index2], () => newSurvey);

  return {
    ...state,
    selectedPatient: newSelectedPatient,
    list: newList,
  };
};

const patientResumeSurveySuccess = (state = { ...initialState }, action = {}) => {
  const { surveyId } = action;
  const { selectedPatient } = state;
  const { surveys } = selectedPatient;

  const index = surveys.findIndex(el => el.id === surveyId);
  const survey = surveys[index];
  let newSurveys;
  if (index === -1) {
    newSurveys = surveys.slice();
  } else {
    newSurveys = [...surveys.slice(0, index), { ...survey, status: SURVEY_STATUS.SENT, is_signed: false }, ...surveys.slice(index + 1)];
  }

  return {
    ...state,
    currentPatient: {
      ...selectedPatient,
      surveys: newSurveys,
    },
    selectedPatient: {
      ...selectedPatient,
      surveys: newSurveys,
    },
  };
};

const patientDetailSurveyDelete = (state = { ...initialState }, action = {}) => {
  const { patientId, IDs } = action;
  const { list, selectedPatient } = state;
  return {
    ...state,
    list: {
      ...list,
      [patientId]: {
        ...list[patientId],
        surveys: list?.[patientId]?.surveys?.filter(s => !IDs.includes(s.id)),
      },
    },
    selectedPatient: {
      ...selectedPatient,
      surveys: selectedPatient?.surveys?.filter(s => !IDs.includes(s.id)),
    },
  };
};

const patientInterventionUpdateSuccess = (state = { ...initialState }, action = {}) => {
  const { newPatient } = action;

  return {
    ...state,
    selectedPatient: { ...newPatient },
  };
};

const patientCurrentDetailSuccess = (state = initialState, action = {}) => {
  const { patientList, patientIndex } = action;
  const newCurrentPatientList = patientList.map(patient => editLetterCaseUsers(patient));
  return {
    ...state,
    currentPatientList: newCurrentPatientList,
    currentPatient: _.get(newCurrentPatientList, patientIndex || '0'),
    indexCurrentPatient: patientIndex || 0,
    loadingPatient: false,
  };
};

const tutoredCurrentDetailSuccess = (state = { ...initialState }, action = {}) => {
  const { tutored } = action;
  return {
    ...state,
    currentTutored: editLetterCaseUsers(tutored),
    loadingTutor: false,
  };
};

/**
 * Save all created patients
 * @param state
 * @param action
 * @returns {{list: {}, loading: boolean}}
 */
const patientsCreateSuccess = (state = { ...initialState }, action = {}) => {
  const { newPatientList } = action;
  const { list } = state;

  return {
    ...state,
    // added surveys: [] for the medic list Item.jsx in order to get status
    list: { ...list, ..._.keyBy(newPatientList.map(p => ({ surveys: [], ...editLetterCaseUsers(p) })), 'id') },
    loading: false,
  };
};

const patientUpdatePropertySuccess = (state = { ...initialState }, action = {}) => {
  const { patientId, update } = action;
  const { list, currentPatientList } = state;
  let patient = list[patientId] || currentPatientList.find(el => el.id === patientId);
  patient = _.merge(patient, update);
  patient = editLetterCaseUsers(patient);
  const formatedPatient = editLetterCaseUsers({ ...patient });

  const newList = { ...list };
  newList[patient.id] = formatedPatient;

  let newCurrentPatientList = [...currentPatientList];
  if (Array.isArray(newCurrentPatientList)) {
    const index = newCurrentPatientList.findIndex(el => el.id === formatedPatient.id);
    if (index === -1) {
      newCurrentPatientList = [...newCurrentPatientList, formatedPatient];
    } else {
      newCurrentPatientList = [...newCurrentPatientList.slice(0, index), formatedPatient, ...newCurrentPatientList.slice(index + 1)];
    }
  } else {
    newCurrentPatientList = [formatedPatient];
  }
  return {
    ...state,
    list: newList,
    currentPatient: formatedPatient,
    currentPatientList: newCurrentPatientList,
    loading: false,
  };
};

const patientUpdateSuccess = (state = { ...initialState }, action = {}) => {
  const { patient } = action;
  const statePatient = !_.isEmpty(state.currentPatient) ? state.currentPatient : state.selectedPatient;
  let nPatien;
  if (parseInt(statePatient.id) === parseInt(patient.id)) {
    // autonomous patient
    nPatien = _.merge(statePatient, patient);
  } else {
    // is patient turors ?
    const index = (statePatient.tutorsByPatientId || []).findIndex(el => parseInt(el?.signatory?.id) === parseInt(patient?.id));
    if (index >= 0) {
      nPatien = {
        ...statePatient,
        tutorsByPatientId: (statePatient.tutorsByPatientId)
          ? [...statePatient.tutorsByPatientId.slice(0, index), { signatory: _.merge(statePatient.tutorsByPatientId[index].signatory, patient) }, ...statePatient.tutorsByPatientId.slice(index + 1)]
          : [],
      };
    }
  }
  const newList = { ...state.list };
  let formatedPatient = editLetterCaseUsers({ ...nPatien });
  if (formatedPatient.is_tutor) {
    let p = (Object.values(newList)).find(el => (el.tutorsByPatientId || []).map(l => _.get(l, 'signatory.id')).includes(formatedPatient.id));
    const index = p.tutorsByPatientId.findIndex(el => _.get(el, 'signatory.id', '') === formatedPatient.id);
    formatedPatient = {
      ...p,
      tutorsByPatientId: [...p.tutorsByPatientId.slice(0, index), { signatory: formatedPatient }, ...p.tutorsByPatientId.slice(index + 1)],
    };
  }
  newList[formatedPatient.id] = _.merge(newList[patient.id] || {}, formatedPatient);

  let newCurrentPatientList = [...state.currentPatientList];
  if (Array.isArray(newCurrentPatientList)) {
    const index = newCurrentPatientList.findIndex(el => el.id === formatedPatient.id);
    if (index === -1) {
      newCurrentPatientList = [...newCurrentPatientList, formatedPatient];
    } else {
      newCurrentPatientList = [...newCurrentPatientList.slice(0, index), formatedPatient, ...newCurrentPatientList.slice(index + 1)];
    }
  } else {
    newCurrentPatientList = [formatedPatient];
  }

  return {
    ...state,
    list: newList,
    selectedPatient: formatedPatient,
    currentPatient: formatedPatient,
    currentPatientList: newCurrentPatientList,
    loading: false,
  };
};

/**
 *
 * @param state
 * @returns {{list: {}, loading: boolean}}
 */
const startEmailLoader = (state = { ...initialState }) => ({
  ...state,
  available: false,
  isEmailAvailable: null,
  emailLoading: true,
});

const patientsEmailFailure = (state = { ...initialState }) => {
  return {
    ...state,
    available: false,
    isEmailAvailable: false,
    emailLoading: false,
  };
};

const patientsEmailSuccess = (state = { ...initialState }, action = {}) => {
  const { message } = action;
  return {
    ...state,
    available: { isAvailable: message },
    isEmailAvailable: message,
    emailLoading: false,
  };
};

const deleteCurrentPatient = (state = { ...initialState }) => {
  return {
    ...state,
    currentPatient: {},
    currentPatientList: [],
  };
};

const resetUploadSuccess = (state = { ...initialState }) => ({
  ...state,
  uploadSuccess: false,
});

const incrementIndexCurrentPatient = (state = { ...initialState }) => {
  const { currentPatientList, indexCurrentPatient } = state;
  let newIndex = indexCurrentPatient + 1;

  const arrayLastIndex = Array.isArray(currentPatientList) ? currentPatientList.length - 1 : 0;
  if (newIndex > arrayLastIndex) {
    newIndex = 0;
  }
  const currentPatient = _.get(currentPatientList, [newIndex || 0]);
  return { ...state, indexCurrentPatient: newIndex, currentPatient };
};

const decrementIndexCurrentPatient = (state = { ...initialState }) => {
  const { currentPatientList, indexCurrentPatient } = state;
  let newIndex = indexCurrentPatient - 1;
  if (newIndex < 0) {
    newIndex = Array.isArray(currentPatientList) ? currentPatientList.length - 1 : 0;
  }
  const currentPatient = _.get(currentPatientList, [newIndex || 0]);
  return { ...state, indexCurrentPatient: newIndex, currentPatient };
};

const clearSelectedPatient = (state = { ...initialState }) => {
  return {
    ...state,
    selectedPatient: {},
  };
};

const selectedPatientAddSurvey = (state = { ...initialState }, action = {}) => {
  const { survey } = action;
  const { selectedPatient, list } = state;
  let newSelectedPatient = { ...selectedPatient };
  if (selectedPatient && selectedPatient.surveys && selectedPatient.id === survey.patient_id) {
    newSelectedPatient.surveys = Array.isArray(selectedPatient.surveys) ? [...selectedPatient.surveys, survey] : [survey];
  }
  return {
    ...state,
    list: {
      ...list,
      [survey.patient_id]: {
        ...list[survey.patient_id],
        surveys: Array.isArray(list[survey.patient_id].surveys) ? [...list[survey.patient_id].surveys, survey] : [survey],
      },
    },
    selectedPatient: newSelectedPatient,
  };
};

const selectedPatientAddFormulaire = (
  state = { ...initialState },
  action = {},
) => {
  const { patientId } = action;
  const selectedPatient = state.list[patientId];

  return {
    ...state,
    selectedPatient,
    selectedPatientIsAutonomous: selectedPatient.tutorsByPatientId && selectedPatient.tutorsByPatientId.length === PATIENT_AUTONOMOUS,
  };
};

const selectedPatientUpdate = (state = { ...initialState }, action = {}) => {
  const { patient } = action;

  const patientsList = {
    ...state.list,
    [patient.id]: {
      ...state.list[patient.id],
      ...patient,
      tutorsByPatientId: [...patient.signatories].map((tutor) => ({
        signatory: {
          id: tutor.id,
          fullname: `${tutor.firstname} ${tutor.lastname}`,
          tutor_status: tutor.type_tutor,
          is_selected: tutor.is_selected,
        },
      })),
    },
  };

  return {
    ...state,
    list: patientsList,
    selectedPatient: patientsList[patient.id],
  };
};

const updatingPatientSignatureAfterCreatingConsent = (state = { ...initialState }, action = {}) => {
  const { surveys, patientId } = action;
  const { list } = state;

  const patientList = {
    ...list,
    [patientId]: {
      ...list[patientId],
      signatories: list[patientId].signatories.map((signatory) => {
        signatory.is_selected = surveys.map((surv) => +surv.tutored_id).includes(+signatory.id);
        return signatory;
      }),
      tutorsByPatientId: list[patientId].tutorsByPatientId.map((tutor) => {
        tutor.signatory.is_selected = surveys.map((surv) => +surv.tutored_id).includes(+tutor.signatory.id);
        return tutor;
      }),
    },
  };

  return {
    ...state,
    list: patientList,
    /* selectedPatient: patientList[patientId],*/
    currentPatient: patientList[patientId],
  };
};

const resetReducer = () => {
  return { ...initialState };
};


/**
 * MAPPING
 */
export default createReducer(initialState, {
  [types.RESET_REDUCER]: resetReducer,

  [types.START_LOADER]: startLoader,
  [types.STOP_LOADER]: stopLoader,

  [types.PATIENTS_SUCCESS]: patientsSuccess,

  [types.PATIENTS_DETAIL_REQUEST]: startLoader,
  [types.PATIENTS_DETAIL_SUCCESS]: patientDetailSuccess,
  [types.PATIENTS_DETAIL_FAILURE]: stopLoader,
  [types.PATIENTS_DETAIL_SURVEY_SUCCESS]: patientDetailSurveySuccess,

  [types.PATIENTS_CURRENT_DETAIL_SUCCESS]: patientCurrentDetailSuccess,
  [types.PATIENTS_CURRENT_DETAIL_FAILURE]: stopPatientLoader,

  [types.TUTORED_CURRENT_DETAIL_REQUEST]: startTutorLoader,
  [types.TUTORED_CURRENT_DETAIL_SUCCESS]: tutoredCurrentDetailSuccess,
  [types.TUTORED_CURRENT_DETAIL_FAILURE]: stopTutorLoader,

  [types.PATIENTS_CREATE_REQUEST]: startLoader,
  [types.PATIENTS_CREATE_SUCCESS]: patientsCreateSuccess,
  [types.PATIENTS_CREATE_FAILURE]: stopLoader,

  [types.PATIENTS_UPDATE_REQUEST]: startLoader,
  [types.PATIENTS_UPDATE_SUCCESS]: patientUpdateSuccess,
  [types.PATIENTS_UPDATE_FAILURE]: stopLoader,

  [types.DELETE_CURRENT_PATIENT]: deleteCurrentPatient,

  [types.PATIENTS_MY_UPDATE_REQUEST]: startLoader,
  [types.PATIENTS_MY_UPDATE_FAILURE]: stopLoader,

  [types.PATIENTS_UPDATE_CGU_REQUEST]: startLoader,
  [types.PATIENTS_UPDATE_CGU_FAILURE]: stopLoader,

  [types.PATIENTS_UPDATE_RGPD_REQUEST]: startLoader,
  [types.PATIENTS_UPDATE_RGPD_FAILURE]: stopLoader,

  [types.PATIENTS_UPDATE_EDIT_INFO_REQUEST]: startLoader,
  [types.PATIENTS_UPDATE_EDIT_INFO_FAILURE]: stopLoader,

  [types.PATIENTS_SURVEY_RESUME_REQUEST_SUCCESS]: patientResumeSurveySuccess,

  [types.PATIENTS_EMAIL_REQUEST]: startEmailLoader,
  [types.PATIENTS_EMAIL_SUCCESS]: patientsEmailSuccess,
  [types.PATIENTS_EMAIL_FAILURE]: patientsEmailFailure,

  [types.PATIENTS_DETAIL_SURVEY_REFRESH]: patientDetailSurveyRefresh,
  [types.PATIENTS_DETAIL_SURVEY_STATUS_REFRESH]: patientDetailSurveyStatusRefresh,
  [types.PATIENTS_DETAIL_SURVEY_DELETE]: patientDetailSurveyDelete,

  [types.RESET_UPLOAD_SUCCESS]: resetUploadSuccess,

  [types.PATIENTS_INTERVENTION_UPDATE_SUCCESS]: patientInterventionUpdateSuccess,

  [types.INCREMENT_INDEX_CURRENT_PATIENT]: incrementIndexCurrentPatient,
  [types.DECREMENT_INDEX_CURRENT_PATIENT]: decrementIndexCurrentPatient,

  [types.PATIENT_UPDATE_PROPERTY_SUCCESS]: patientUpdatePropertySuccess,
  [types.PATIENT_CLEAR_SELECTED_PATIENT]: clearSelectedPatient,

  [types.SELECTED_PATIENT_ADD_SURVEY]: selectedPatientAddSurvey,

  [types.SELECTED_PATIENT_ADD_FORMULAIRE]: selectedPatientAddFormulaire,
  [types.SELECTED_PATIENTS_UPDATE_SUCCESS]: selectedPatientUpdate,
  [types.UPDATING_PATIENT_SIGNATURE_AFTER_CREATING_CONSENT]: updatingPatientSignatureAfterCreatingConsent,
});
