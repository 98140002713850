import _ from 'lodash';
import React, { useEffect } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

function TeamLoader({ onTeamChange, medicalTeamList, selectedTeam, fetchMedicTeamPatients, setMedicalTeamParams }) {
  const { teamId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (teamId && !_.isEmpty(medicalTeamList)) {
      if (!(teamId in medicalTeamList)) {
        navigate('/');
      }
      if (teamId in medicalTeamList && (`${selectedTeam}` !== `${teamId}`)) {
        onTeamChange(teamId);
      }
    }
  }, [teamId, selectedTeam, medicalTeamList]);

  useEffect(() => {
    fetchMedicTeamPatients(selectedTeam);
    setMedicalTeamParams();
  }, [selectedTeam]);

  return (
    <Outlet />
  );
}

export default TeamLoader;

