import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import PDFViewer from '../../../components/PDFViewer';
import { Button } from '../../../components/styledComponent';

function PdfRender({ pdf, onValidatePDF, isLast, hideBtn }) {
  const { t } = useTranslation();
  const [isLastPdfPage, setIsLastPdfPage] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const pdfUrl = useMemo(() => {
    if (pdf && pdf.medic_template_id) {
      return `${process.env.REACT_APP_SIGN_API_URL}/medic_templates/${pdf.medic_template_id}/surveyPdf/${pdf.loid}`;
    } else if (pdf && pdf.survey_id) {
      return `${process.env.REACT_APP_SIGN_API_URL}/surveys/${pdf.survey_id}/surveyPdf/${pdf.loid}`;
    } else {
      return;
    }
  }, [pdf]);

  const handleLastClick = () => {
    if (isLastPdfPage !== true) {
      setIsLastPdfPage(true);
    }
  };

  const handleDocumentLoadSuccess = () => {
    setDisabled(false);
  };

  const handleValidatePDF = (e) => {
    !disabled && onValidatePDF && onValidatePDF(e);
  };

  return (
    <div className="consent-screen-pdf-container" >
      <PDFViewer
        documentData={{ url: pdfUrl } }
        customNavigation
        onLastClick={handleLastClick}
        showBtn
        onDocumentLoadSuccess={handleDocumentLoadSuccess}
      />
      { ( !hideBtn) && (
        <div className="d-flex justify-content-end">
          <Button
            variant="primary"
            id="validate-pdf-button-id"
            onClick={handleValidatePDF}
            disabled={disabled || !isLastPdfPage}
            type={isLast ? 'submit' : 'button'}
            className="ml-auto "
          >
            {t('Continue')}
          </Button>
        </div>
      )}
    </div>
  );
}

PdfRender.propTypes = {
  pdf: PropTypes.object,
  onValidatePDF: PropTypes.func,
  survey: PropTypes.object,
};

export default PdfRender;
