import _ from 'lodash';
import PropTypes from 'prop-types';
import { useFormContext, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const Message = ({ message, classNameError, name, hideError, style }) => {
  const { t, i18n } = useTranslation();
  return (
    <div
      className={`custom-formV2-control-error-text ${!message && (hideError ? 'd-none' : 'invisible')} ${classNameError || ''}`}
      key={`custom-formV2-control-error_${name}`}
      style={style}
    >
      <span role="alert">{i18n.exists(message) ? t(message) : (message || 'message erreur')}</span>
    </div>
  ) ;
};

const ErrorMessage = ({ name, control, ...props }) => {
  const formContext = useFormContext(); // retrieve all hook methods
  const { errors } = useFormState({ control: control || formContext.control });

  let error = name ? _.get(errors, `${name}`) : errors;
  if (!error || _.isEmpty(error)) return null;
  if (Array.isArray(error) || (_.isObject(error) && !('message' in error))) {
    error = Array.isArray(error) ? error : Object.values(error);
    return (
      error.map((value, n, arr) => {
        const { message } = value;
        return (
          <div key={`ErrorMessage${n}`}>
            <Message message={message} {...props} />
            {n < Object.values(arr).length - 1 ? <br /> : null}
          </div>
        );
      })
    );
  } else {
    return <Message message={_.get(error, 'message')} name={name} {...props} />;
  }
};

ErrorMessage.propTypes = {
  name: PropTypes.string,
  classNameError: PropTypes.string,
};

export default ErrorMessage;
