import '../style.css';

import { differenceInHours, parseISO } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from '../../../../components/modal/Modal';
import { Button } from '../../../../components/styledComponent';
import { LOST_PSWD_STEP } from '../..';
import CodeStep from './CodeStep';
import EmailStep from './EmailStep';
import ResetPwd from './ResetPwd';
import UsernameStep from './UsernameStep';

const ModalRenew = (
  {
    step,
    onModalClose,
    isModalOpen,
    isPro,
    onStepCompletion,
    onPreviousStep,
    lastSmsSent,
    clearLastSmsSent,
  },
) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({});

  // clear smsCount if more than 1 hour since lastSmsSent
  useEffect(() => {
    if (differenceInHours(new Date(), parseISO(lastSmsSent)) < 2 && clearLastSmsSent) {
      clearLastSmsSent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastSmsSent]);

  const handleSubmit = (data, formId) => {
    setFormData({
      ...formData,
      [formId] : data,
    });
  };

  return (
    <Modal
      title={t('auth.forgotten_pswd.Modal.titleStep', { context: step }) }
      onClose={() => onModalClose('mdp')}
      isOpen={isModalOpen}
      hideCloseBtn={[LOST_PSWD_STEP.B_FILL_CODE, LOST_PSWD_STEP.C_NEW_PSWD].includes(step)}
    >
      {step === LOST_PSWD_STEP.A_FILL_USERNAME && (
        <div className={`${step === LOST_PSWD_STEP.A_FILL_USERNAME ? 'visible' : 'd-none'}` }>
          <UsernameStep
            id={'ModalRenew.UsernameStep'}
            onCancel={onPreviousStep}
            onStepCompletion={onStepCompletion}
            onSubmit={handleSubmit}
          />
          { !isPro && (
            <Button type='button' variant='link' className='btn btn-link' onClick={() => onStepCompletion(LOST_PSWD_STEP.A_FILL_EMAIL)} >
              {t('modalRenewForm.username.go_to_email')}
            </Button>
          )}
        </div>
      )}
      {step === LOST_PSWD_STEP.A_FILL_EMAIL && (
        <div className={`${step === LOST_PSWD_STEP.A_FILL_EMAIL ? 'visible' : 'd-none'}` }>
          <EmailStep
            id={'ModalRenew.EmailStep'}
            onCancel={onPreviousStep}
            onStepCompletion={onStepCompletion}
            onSubmit={handleSubmit}
            isPro={isPro}
          />
        </div>
      )}
      {step === LOST_PSWD_STEP.B_FILL_CODE && (
        <div className={`${step === LOST_PSWD_STEP.B_FILL_CODE ? 'visible' : 'd-none'}` }>
          <CodeStep

            id={'ModalRenew.CodeStep'}
            title={t('forgotten_password_code')}
            onCancel={onPreviousStep}
            onStepCompletion={onStepCompletion}
            isPro={isPro}
            onSubmit={handleSubmit}
          />
        </div>
      )}
      {step === LOST_PSWD_STEP.C_NEW_PSWD && (
        <div className={`${step === LOST_PSWD_STEP.C_NEW_PSWD ? 'visible' : 'd-none'}` }>
          <ResetPwd
            id={'ModalRenew.ResetPwd'}
            onCancel={onPreviousStep}
            onStepCompletion={onStepCompletion}
            isPro={isPro}
            onSubmit={handleSubmit}
          />
        </div>
      )}
    </Modal>
  );
};

export default ModalRenew;
