import _ from 'lodash';
import { connect } from 'react-redux';

import { AuthActions } from '../../../redux/auth';
import { PatientActions } from '../../../redux/patient';
import { generateRulesFromData } from '../../../services/casl/roles';
import { MEDIC_ROLES, USER_ROLE } from '../../../utils';
import ConnectionModals from './ConnectionModals';

const mapStateToProps = ({ auth, patient, medic, display }) => {
  const role = _.get(auth, 'user.role');
  const current = MEDIC_ROLES.includes(role) ? medic.currentMedic : (patient.currentPatient || _.get(patient.currentPatientList, [patient.indexCurrentPatient]));

  const rawAbilitiesRules = Object.values(auth?.rawAbilitiesRules || {}).reduce((acc, permissions) => ([...acc, ...permissions]), []);

  return {
    role: role,
    user: auth.user,
    current: current,
    legalSuccess: _.get(auth, 'legalSuccess'),
    renewInfos: auth.renew_infos,
    isLoginFromQrCode: auth.isLoginFromQrCode,
    isClinicalMode: display.isClinicalStudy,
    abilitiesRules: generateRulesFromData(rawAbilitiesRules, { medic: medic.currentMedic || {}, medicalTeam: { id: medic.selectedTeam || patient?.currentPatient?.medical_teams_id } }),
    selectedTeam: role === USER_ROLE.PATIENT ? _.get(patient, ['currentPatient', 'medicalTeam'], {}) : _.get(medic, ['medicalTeamList', medic.selectedTeam], {}),
  };
};

const mapDispatchToProps = (dispatch) => ({
  createPassword: (newPwd) => dispatch(AuthActions.createPassword(newPwd)),
  patientsUpdateEditInfo: (id) => dispatch(PatientActions.patientsUpdateEditInfoRequest(id)),
  patientUpdateRequest: (id, patient) => dispatch(PatientActions.patientUpdateRequest(id, patient)),
  checkIfEmailExistsInDb: ( email, userId ) => dispatch(PatientActions.checkIfEmailExistsInDb(email, userId)),
  resetLegalSuccess: () => AuthActions.resetLegalSuccess(),
  editInfoSuccess: () => AuthActions.editInfoSuccess(),
  setCaslRules: (rules) => dispatch(AuthActions.setCaslRules(rules)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConnectionModals);
