import './style.css';

import React from 'react';
import { useTranslation } from 'react-i18next';

const ProfilSpecialites = ({ specialities }) => {
  const { t } = useTranslation();
  return (
    <div className={'specialites-main-container'}>
      <div className={'specialites-items'}>
        <p className={'profil-title-text'}>{t('profil_specialites')}</p>
        {Array.isArray(specialities) && specialities.length ? (
          specialities
            .filter((s) => s.translation)
            .map((s, index) => (
              <p className={'specialites-black-text'} key={index}>
                {s.translation.value}
              </p>
            ))
        ) : (
          <p className={'specialites-black-text'}>{t('no_specialites_disclaimer')}</p>
        )}
      </div>
    </div>
  );
};

export default ProfilSpecialites;
