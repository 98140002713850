import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import iconError from '../../../../assets/img/icon-error@3x.png';
import iconValide from '../../../../assets/img/icon-signe@3x.png';
import { SURVEY_STATUS, USER_ROLE } from '../../../../utils';
import { SURVEY_STATUS_COLOUR } from '../../../../utils/enum';
import ToolTip from '../../tooltip';

const getIcon = (sta) => {
  switch (sta) {
    case SURVEY_STATUS.COUNTERSIGNED:
    case SURVEY_STATUS.ACCEPTED:
      return {
        icon: iconValide,
        alt: 'signed',
        style:{
          height: '10px',
          width: '10px',
        },
      };
    case SURVEY_STATUS.REFUSED:
    case SURVEY_STATUS.FURTHER_INFO:
    case SURVEY_STATUS.NOT_SIGNED:
    case SURVEY_STATUS.WITHDRAWAL:
      return {
        icon: iconError,
        alt: 'error',
      };
    default:
      return { icon: false };
  }
};

function Status({ survey, userRole, onClickSign, disabled, isClinicalMode, languageCode }) {
  const { t } = useTranslation();
  const [title, setTitle] = useState('');
  const [icon, setIcon] = useState({});
  const [render, setRender] = useState();
  const [status, setStatus] = useState();

  useEffect(() => {
    setStatus(survey.status);
    setIcon(getIcon(survey.status));
  }, [survey && survey.status]);

  useEffect(() => {
    setTitle(t(`medicPatient.history.list.status.${userRole === USER_ROLE.PATIENT ? 'patient' : 'pro'}.${isClinicalMode && isClinicalMode === true ? 'clinicalStudy' : 'core'}.${status}`));
  }, [status, userRole, isClinicalMode]);

  useEffect(() => {
    setRender(getComponent(status, isClinicalMode, userRole, icon, title, disabled));
  }, [status, userRole, isClinicalMode, icon, title, disabled]);

  const getComponent = (status, isClinicalMode, userRole, icon, title, disabled) => {
    let statusElm = null;
    switch (status) {
      case SURVEY_STATUS.COUNTERSIGNED:
      case SURVEY_STATUS.ACCEPTED:
      case SURVEY_STATUS.FURTHER_INFO:
      case SURVEY_STATUS.REFUSED:
      case SURVEY_STATUS.NOT_SIGNED:
      case SURVEY_STATUS.WITHDRAWAL:
        let dataTip;
        if (status === SURVEY_STATUS.ACCEPTED ) {
          const signatureDate = survey.ip_data?.signatureDate && new Date(survey.ip_data?.signatureDate*1000).toLocaleDateString(languageCode);
          dataTip = t('InfoPatient.List.Status.signatureDate', { date: signatureDate });
        }
        statusElm = (
          <>
            <ToolTip />
            <div
              data-tip={dataTip}
              className={`
              status
              d-flex 
              infopatient-historique-title-form 
              ${SURVEY_STATUS_COLOUR(status, isClinicalMode)}
              ${ (userRole === USER_ROLE.MEDIC)
            ? 'infopatient-historique-title-form-signed-medic-only'
            : ''
          } `}
            >
              <p>{title}</p>
              { icon
                ? <img
                  src={icon.icon}
                  style={icon.style}
                  alt={icon.alt}
                />
                : null
              }
            </div>
          </>
        );
        break;
      case SURVEY_STATUS.SENT:
        if ( userRole === USER_ROLE.PATIENT ){
          statusElm = (
            <button
              className={'btn btn-blue infopatient-button-historique-title-form-sent'}
              onClick={() => onClickSign(survey)}
              disabled={disabled}
            >
              <p>{t('medicPatient.history.list.actionBtn.toSign')}</p>
            </button>
          );
        } else {
          statusElm = (
            <div
              className={
                'd-flex infopatient-historique-title-form infopatient-historique-title-form-signed-medic-only'
              }
            >
              <p>{title}</p>
            </div>
          );
        }
        break;
      case SURVEY_STATUS.DRAFT:
        statusElm = (
          <div
            className={
              'd-flex infopatient-historique-title-form infopatient-historique-title-form-signed-medic-only'
            }
          >
            <p>{t('medicPatient.history.list.status.patient.core.pending')}</p>
          </div>
        );
        break;
      default:
        break;
    }
    return (statusElm);
  };

  return ( <Fragment>{render}</Fragment> );
}

Status.propTypes = {
  survey:PropTypes.object.isRequired,
  onClickSign:PropTypes.func.isRequired,
  userRole:PropTypes.string.isRequired,
};

export default Status;

