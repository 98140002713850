/* Core exports */
import { connect } from 'react-redux';

import RequireAuth from './RequireAuth';

const mapStateToProps = ({ auth }) => {
  return {
    token: auth.token,
    isUserLoading: auth.isUserLoading,
  };
};


export default connect(mapStateToProps, null)(RequireAuth);
