import _ from 'lodash';
import { connect } from 'react-redux';

import PDF from './PDF';

const mapStateToProps = ({ survey }, props) => {
  const { currentSurvey } = props;
  const currentSurveyRedux = _.get(survey, ['currentSurvey']);
  return { currentSurvey: currentSurvey || currentSurveyRedux };
};

export default connect(mapStateToProps, null)(PDF);
