import { defaultLanguageId } from './const';

export const getUserLngTranslation = (list, userLng, destinationKey = 'translation') => {
  return (Array.isArray(list) ? list : []).map(el => {
    const translation = Object.values(el?.translation || {});
    if (Array.isArray(translation)) {
      let userLanguage = translation.find(st => `${st.language_id}` === `${userLng}` );
      if (!userLanguage) {
        userLanguage = translation.find(st => `${st.language_id}` === `${defaultLanguageId}`);
      }
      el[destinationKey] = userLanguage || translation[0] || {};
    }
    return el;
  });
};