// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.authentication-forgotten-password-input {
  max-width: 513px;
  height: 27px;
  border-radius: 8px;
  border: solid 1px #cfcfcf;
  background-color: #ffffff;
  outline: none;
  padding: 16px;
  font-weight: 200;
  font-size: 15px;
  color: #79828b;
}

.authentication-forgotten-password-separator {
  height: 25px;
}

.authentication-forgotten-password-text-desc {
  font-size: 18px;
  font-weight: normal;
  line-height: 1.33;
  letter-spacing: 0.45px;
  color: #393d52;
}

.authentication-forgotten-password-logo-error-content {
  display: flex;
  position: absolute;
  right: 40px;
  top: 80px;
}

.authentication-forgotten-password-logo-error-content-step2 {
  display: flex;
  position: absolute;
  right: 0px;
  top: 27px;
}

.authentication-forgotten-password-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.authentication-forgotten-password-logo-error {
  width: 24px;
  height: 21px;
}

.authentication-forgotten-password-text {
  font-size: 30px;
  font-weight: bold;
  line-height: 0.49;
  letter-spacing: -0.14px;
  color: #393d52;
}
`, "",{"version":3,"sources":["webpack://./src/ui/screens/authentication/option/style.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;EACzB,yBAAyB;EACzB,aAAa;EACb,aAAa;EACb,gBAAgB;EAChB,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,iBAAiB;EACjB,sBAAsB;EACtB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,WAAW;EACX,SAAS;AACX;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,UAAU;EACV,SAAS;AACX;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,iBAAiB;EACjB,uBAAuB;EACvB,cAAc;AAChB","sourcesContent":[".authentication-forgotten-password-input {\n  max-width: 513px;\n  height: 27px;\n  border-radius: 8px;\n  border: solid 1px #cfcfcf;\n  background-color: #ffffff;\n  outline: none;\n  padding: 16px;\n  font-weight: 200;\n  font-size: 15px;\n  color: #79828b;\n}\n\n.authentication-forgotten-password-separator {\n  height: 25px;\n}\n\n.authentication-forgotten-password-text-desc {\n  font-size: 18px;\n  font-weight: normal;\n  line-height: 1.33;\n  letter-spacing: 0.45px;\n  color: #393d52;\n}\n\n.authentication-forgotten-password-logo-error-content {\n  display: flex;\n  position: absolute;\n  right: 40px;\n  top: 80px;\n}\n\n.authentication-forgotten-password-logo-error-content-step2 {\n  display: flex;\n  position: absolute;\n  right: 0px;\n  top: 27px;\n}\n\n.authentication-forgotten-password-content {\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n}\n\n.authentication-forgotten-password-logo-error {\n  width: 24px;\n  height: 21px;\n}\n\n.authentication-forgotten-password-text {\n  font-size: 30px;\n  font-weight: bold;\n  line-height: 0.49;\n  letter-spacing: -0.14px;\n  color: #393d52;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
