
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { shortidGenerate } from '../../../../../utils/utils';
import { InputGroupedCheckboxV2 } from '../../../../components/formV3/controllers';
import { SurveyFormController } from '../../../../components/formV3/innerWrapper';
import { arrayToFormName } from '..';
import ArrayList from '../ArrayList';
import QuestionHeader from '../QuestionHeader';

const CheckboxFull = ({ question, onDelete, prefixName, index, nestIndex, readOnly, disabled, initialValues, isConsent }) => {
  return (
    <QuestionHeader
      question={question}
      onDelete={onDelete}
      prefixName={prefixName}
      index={index}
      nestIndex={nestIndex}
      disabled={disabled}
      initialValues={initialValues}
      isConsent={isConsent}
    >
      { isConsent
        ? (
          <SurveyFormController
            name={arrayToFormName([prefixName, 'answer'])}
            isRequiredField={true}
            classNameError="form-item-error-text-dropdown"
          >
            <InputGroupedCheckboxV2
              options={_.get(question, 'translation.data.list', [])}
              className={'ec-dropdown-list-question-choice'}
              readOnly={readOnly}
              disabled={disabled}
            />
          </SurveyFormController>
        ) : (
          <ArrayList
            prefixName={arrayToFormName([prefixName, 'translation', 'data'])}
            name={'list'}
            defaultNewItem={[{ value: shortidGenerate(), label: '' }]}
            deletableItems
            disabled={disabled}
            isTextArea
          />
        )}
    </QuestionHeader>
  );
};

export default CheckboxFull;

CheckboxFull.propTypes = {
  question: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  prefixName: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  nestIndex: PropTypes.number,
};
