import _ from 'lodash';
import { createReducer } from 'reduxsauce';

import { types } from './actions';

const initialState = {
  list: {},
  loading: false,
  newMedicTemplateId: null,
};

const startLoader = (state = { ...initialState }) => ({
  ...state,
  loading: true,
});

const stopLoader = (state = { ...initialState }) => ({
  ...state,
  loading: false,
});

const medicTemplatesGetSuccess = (state = { ...initialState }, action = {} ) => {
  const { medic_templates } = action;
  return {
    ...state,
    list: _.keyBy(medic_templates, 'id'),
    loading: false,
  };
};

const medicTemplateGetDetailSuccess = (state = { ...initialState }, action = {} ) => {
  const { medic_templates } = action;
  const { list } = state;
  return {
    ...state,
    list: { ...list, ..._.keyBy(medic_templates, 'id') },
    loading: false,
  };
};

const medicTemplateAssignSuccess = (state = { ...initialState }, action = {} ) => {
  const { templates } = action;
  const { list } = state;
  return {
    ...state,
    list: { ...list, ..._.keyBy(templates, 'id') },
    loading: false,
  };
};

const medicTemplateUnassignSuccess = (state = { ...initialState }, action = {} ) => {
  const { templateIdsList } = action;
  const { list } = state;
  return {
    ...state,
    list: _.omit(list, templateIdsList),
    loading: false,
  };
};

const medicTemplateActivateSuccess = (state = { ...initialState }, action = {} ) => {
  const { template, isPublished } = action;
  let data = state.list;

  if (isPublished) {
    data[template.id] = template;
  } else {
    delete data[template.id];
  }

  return {
    ...state,
    list: data,
    loading: false,
  };
};

const medicTemplateDeleteSuccess = (state = { ...initialState }, action = {} ) => {
  const { templateID } = action;
  const { list } = state;
  return {
    ...state,
    list: _.omit(list, [templateID]),
    loading: false,
  };
};


const setNewMedicTemplateId = (state = { ...initialState }, action = {} ) => {
  const { newMedicTemplateId } = action;
  return {
    ...state,
    loading: false,
    newMedicTemplateId: newMedicTemplateId,
  };
};

const clearNewMedicTemplateId = (state = { ...initialState } ) => {
  return {
    ...state,
    newMedicTemplateId: null,
  };
};



const resetReducer = () => {
  return { ...initialState };
};



export default createReducer(initialState, {
  [types.RESET_REDUCER]: resetReducer,

  [types.MEDIC_TEMPLATE_GET_REQUEST]: startLoader,
  [types.MEDIC_TEMPLATE_GET_SUCCESS]: medicTemplatesGetSuccess,
  [types.MEDIC_TEMPLATE_GET_FAILURE]: stopLoader,

  [types.MEDIC_TEMPLATE_GET_DETAILS_REQUEST]: startLoader,
  [types.MEDIC_TEMPLATE_GET_DETAILS_SUCCESS]: medicTemplateGetDetailSuccess,
  [types.MEDIC_TEMPLATE_GET_DETAILS_FAILURE]: stopLoader,

  [types.MEDIC_TEMPLATE_ASSIGN_SUCCESS]: medicTemplateAssignSuccess,
  [types.MEDIC_TEMPLATE_ASSIGN_FAILURE]: stopLoader,

  [types.MEDIC_TEMPLATE_UNASSIGN_REQUEST]: startLoader,
  [types.MEDIC_TEMPLATE_UNASSIGN_SUCCESS]: medicTemplateUnassignSuccess,
  [types.MEDIC_TEMPLATE_UNASSIGN_FAILURE]: stopLoader,

  [types.MEDIC_TEMPLATE_ACTIVATE_REQUEST]: startLoader,
  [types.MEDIC_TEMPLATE_ACTIVATE_SUCCESS]: medicTemplateActivateSuccess,
  [types.MEDIC_TEMPLATE_ACTIVATE_FAILURE]: stopLoader,

  [types.MEDIC_TEMPLATE_DELETE_REQUEST]: startLoader,
  [types.MEDIC_TEMPLATE_DELETE_SUCCESS]: medicTemplateDeleteSuccess,
  [types.MEDIC_TEMPLATE_DELETE_FAILURE]: stopLoader,

  [types.SET_NEW_MEDICTEMPLATE_ID]: setNewMedicTemplateId,
  [types.CLEAR_NEW_MEDICTEMPLATE_ID]: clearNewMedicTemplateId,


});
