
import { connect } from 'react-redux';

import { AuthActions } from '../../../redux/auth';
import { LoaderActions } from '../../../redux/loader';
import { MedicActions } from '../../../redux/medic';
import { SplashActions } from '../../../redux/splash';
import Splash from './Splash';

const mapStateToProps = ({ auth, splash }) => {
  return {
    authToken: auth.token,
    authRefresh: auth.refresh_token,
    redirectOnLogin: splash.redirectOnLogin,
    splash: splash,
    isUserLoggedIn: auth.isUserLoggedIn,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loaded: () => dispatch(LoaderActions.loaded()),
  loginSuccess: (token, refresh, username) => dispatch(AuthActions.loginSuccess('Bearer', token, refresh, '', username)),
  getLoginInfo: () => dispatch(AuthActions.getLoginInfo()),

  roleSuccess: (role) => dispatch(AuthActions.roleSuccess(role)),
  setIsLoginFromQrCode: () => dispatch(SplashActions.setIsLoginFromQrCode()),
  medicSetAutoLoginData: (data) => dispatch(MedicActions.medicSetAutoLoginData(data)),

  setRedirectUrl: (url) => dispatch(AuthActions.setRedirectUrl(url)),
  setRedirectPayload: (payload) => dispatch(SplashActions.setRedirectPayload(payload)),

  setSplashAndLogout: (payload) => dispatch(SplashActions.set(payload)),
  loginFromToken: (accessToken, refreshToken, username) => dispatch(AuthActions.loginFromToken(accessToken, refreshToken, username)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Splash);
