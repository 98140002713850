import './style.css';

import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { USER_ROLE } from '../../../utils';
import { PRO_LOGIN_PATH } from '../../../utils/const';
import { extractParams, insertParam } from '../../../utils/router-helper';

const Splash = ({
  roleSuccess,
  setIsLoginFromQrCode,
  getLoginInfo,
  medicSetAutoLoginData,
  authToken,
  authRefresh,
  setRedirectUrl,
  setRedirectPayload,
  setSplashAndLogout,
  logout,
}) => {
  const [searchParams] = useSearchParams();
  const [urlParams, setUrlParams] = useState();

  useEffect(() => {
    setUrlParams(extractParams(searchParams));
  }, [searchParams]);

  useEffect(() => {
    if (!urlParams ) return;
    const { patient, action, surveyID, teamID, token, refresh, keepLogin } = urlParams;
    let redirectUrl;
    let forceLogout;
    let redirectUrlAfterLogout;

    switch (action) {
      case 'consent':
        const { date_operation, date_consultation, _interface_patient_id, clinic } = urlParams;
        const newUrlParams = insertParam(new URLSearchParams(), [
          { key: 'action', value: action },
          { key: 'patient', value: patient },
          { key: 'date_operation', value: date_operation },
          { key: 'date_consultation', value: date_consultation },
          { key: '_interface_patient_id', value: _interface_patient_id },
          { key: 'clinic', value: clinic },
        ].filter(el => !!el.value));
        redirectUrl = `/teams/${teamID}?${newUrlParams.toString()}`;
        break;

      case 'QRConnect':
        setIsLoginFromQrCode();
        redirectUrl = `/consent/${surveyID}`;
        break;

      case 'connect':
        redirectUrl = '/';
        break;

      case 'medicConnect':
        const newSurveyID = typeof surveyID === 'string' ? parseInt(surveyID, 10) : surveyID;
        if (medicSetAutoLoginData && surveyID && surveyID > -1) {
          medicSetAutoLoginData({ surveyID: newSurveyID, patientID: patient, teamID: parseInt(teamID, 10) });
        }
        redirectUrl = `/teams/${teamID}/patients/${patient}`;
        break;

      case 'patient_pdf':
        roleSuccess(USER_ROLE.MEDIC);
        redirectUrl = `/teams/${teamID}/patients/${patient}`;
        break;

      case 'medicLogin':
        const { username, redirectPayload, redirectPath } = urlParams;
        forceLogout = true;
        // go to login page
        const urlParamsMedicLogin = new URLSearchParams();
        const paramsMedicLogin = insertParam(urlParamsMedicLogin, [
          { key: 'username', value: username },
          // { key: 'redirectPath', value: redirectPath },
          // { key: 'redirectPayload', value: redirectPayload },
        ].filter(el => !!el.value));
        setRedirectPayload({
          destinationPath: redirectPath,
          payload: redirectPayload,
        });
        redirectUrlAfterLogout = `${PRO_LOGIN_PATH}?${paramsMedicLogin.toString()}`;
        break;
      default:
        redirectUrl = '/';
        break;
    }

    // keepLogin is on action "consent"
    const shouldLogout = forceLogout || (!keepLogin && (!authToken || token !== authToken) && (!authRefresh || refresh !== authRefresh));
    if (shouldLogout) {
      setSplashAndLogout({ token, refresh_token: refresh, token_type: token ? 'Bearer' : null, redirectOnLogin: redirectUrl, redirectUrlAfterLogout });
    } else {
      redirectUrl && setRedirectUrl(redirectUrl);
    }

  }, [urlParams]);

  return <div></div>;
};

export default Splash;
