import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CONTENT_TYPE_MAPPER, REVERS_CONTENT_TYPE_MAPPER } from '../../../../../utils/enum';
import { formatFilename, isNullOrWhitespace } from '../../../../../utils/utils';
import { ModalButtons } from '../../../buttons';
import PDFViewer from '../../../PDFViewer';
import PDFViewerOnly from '../../../PDFViewer/PDFViewerOnly';
import { ACTIONS } from '../DocumentChoice';

const SurveyDocumentViewer = ({
  survey, action, token, onClearAction, canCancel, showBtn = true, hideSecond,
  hidePreview, firstText, onLastClick, firstButtonStyle, secondText, ioid, scale = 1,
  onFileLoaded, hidden, forceDocument, isConsent,
}) => {
  const { t } = useTranslation();
  const [filename, setFilename] = useState();
  const [url, setUrl] = useState();
  const [pdfUrl, setPdfUrl] = useState();
  const [image, setImage] = useState();
  const [fileType, setFileType] = useState();

  useEffect(() => {
    if (forceDocument && forceDocument.file)
      setFileType(forceDocument?.file?.type || null);
  }, [forceDocument]);

  useEffect(() => {
    if (!!forceDocument) return;
    const fetchData = async (url, token, responseType = 'blob') => {
      //  responseType = 'arraybuffer' || 'blob';
      const res = await axios.get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        responseType,
      });

      const newFileType = res.headers['content-type'];

      setFileType(newFileType);
      setFilename(`${formatFilename(`${survey.id}_${filename}`)}.${REVERS_CONTENT_TYPE_MAPPER[newFileType]}`);

      const objectUrl = window.URL.createObjectURL(res.data);
      setPdfUrl(newFileType === CONTENT_TYPE_MAPPER.PDF ? objectUrl : null);
      setImage(newFileType !== CONTENT_TYPE_MAPPER.PDF ? objectUrl : null);
      onFileLoaded && onFileLoaded();
    };
    if (url && !isNullOrWhitespace(token)) {
      fetchData(url, token);
    }
  }, [url, token]);

  useEffect(() => {
    if (!!forceDocument) return;
    if (survey) {
      const prefixUrl = `${process.env.REACT_APP_SIGN_API_URL}/${survey.__typename === 'surveys' ? 'surveys' : 'medic_templates'}/${survey.id}`;
      const formatedFilename = formatFilename(survey.title);
      switch (action) {
        case ACTIONS.BUILD_PDF:
          setFilename(`${formatedFilename}_consent`);
          setUrl(`${prefixUrl}/consentPdf`);
          break;
        case ACTIONS.BUILD_DRAW:
          setFilename(`${formatedFilename}_draw`);
          setUrl(`${prefixUrl}/drawing/${survey.survey_drawing_id}`);
          break;
        case ACTIONS.BUILD_ATTACHMENT:
          setFilename(`${formatedFilename}_attachment`);
          setUrl(`${prefixUrl}/attachment/${survey.survey_attachment_id}`);
          break;
        case ACTIONS.QUESTION_PDF:
          if (ioid && ioid > 0) {
            setFilename(`${formatedFilename}_ioid`);
            setUrl(`${prefixUrl}/ioid/${ioid}`);
          }
          break;
        default:
          setUrl();
      }
    }
  }, [action, survey]);

  const handleDownload = () => {
    if (!showBtn) return;
    var link = document.createElement('a');
    link.href = pdfUrl;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    setTimeout(function () {
      window.URL.revokeObjectURL(link);
    }, 200);
  };

  const handleClear = () => {
    setUrl();
    setPdfUrl();
    setImage();
    if (onClearAction) onClearAction();
  };

  return (
    <div className="w-100" >
      { hidePreview
        ? null
        : (
          fileType === CONTENT_TYPE_MAPPER.PDF && (forceDocument || url)
            ? (
              isConsent
                ? (<PDFViewer
                  scale={scale}
                  documentData={forceDocument || { url }}
                  onLastClick={onLastClick}
                  hidden={hidden}
                /> )
                : (<PDFViewerOnly
                  scale={scale}
                  documentData={forceDocument || { url }}
                  onLastClick={onLastClick}
                  hidden={hidden}
                />))
            : image ? <img src={image} alt="draw" style={{ width: '100%' }} /> : null
        )}
      { showBtn && (
        <div>
          <ModalButtons
            firstText={firstText || t('download')}
            onFirstClick={handleDownload}
            firstButtonStyle={firstButtonStyle}
            hideSecond={!canCancel || hideSecond}
            secondText={canCancel && (secondText || t('return'))}
            onSecondClick={canCancel && handleClear}
          />
        </div>
      )}
    </div>
  );
};

SurveyDocumentViewer.propTypes = {
  survey: PropTypes.object,
  action: PropTypes.string,
  onClearAction: PropTypes.func,
};

export default SurveyDocumentViewer;