import './style.css';

import React from 'react';

import FilterIcon from './filterIcon';
import { FilterTextCompact, FilterTextLines } from './filterText';

const Filter = ({
  primaryText,
  secondaryText,
  value,
  type,
  activated,
  status,
  onClick,
  isStat,
}) => {

  const handleOnClick = (e) => {
    e.preventDefault();
    if (onClick) {
      onClick(value);
    }
  };

  return (
    <button
      className={`${
        activated
          ? `ec-filter-button-activated ec-filter-button-activated-${type}`
          : `ec-filter-button ec-filter-button-${type}`
      }${isStat ? ' is-stats-button' : ''}`}
      onClick={handleOnClick}
      type="button"
    >
      <FilterIcon image={value} active={activated} />
      {type === 'compact'
        ? <FilterTextCompact
          primaryText={primaryText}
          secondaryText={secondaryText}
          active={activated}
        />
        : <FilterTextLines primaryText={primaryText} secondaryText={secondaryText} active={activated} />
      }
      {!onClick
        ? <div className={`ec-filter-button-colored-dot ${status}`} />
        : null
      }
    </button>
  );
};

export default Filter;
