// SkeletonItem.jsx
import './styles.css';

import PropTypes from 'prop-types';
import React from 'react';
import styled, { keyframes } from 'styled-components';
const e = React.createElement;
const wave = keyframes`
  from {
    opacity: 0.5;
    left: -100%;
  }
  to {
    opacity: 1;
    left: 200%;
  }
`;
const pulse = keyframes`
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 0.3;
  }
`;
const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
};

const StyledSkeleton = styled(({ component, children, ...props }) => e(component, props, children))`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  border-radius: 0.6em;
  opacity: 0.3;
  background-color: #79828b;

  position: relative;
  overflow: hidden;

  @media screen and (max-width: ${size.tablet}) { 
    height: ${({ height }) => {
    if (height){
      const [full, value, sign] = `${height}`.match(/^(\d+(?:\.\d+)?)\s*(rem|px|em|%)?$/);
      return `${parseInt(value, 10) / 2}${sign || 'px'}`;
    }
  }};
  }

  ::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(to right, #FFFFFF4C 0%, #000 100%);
    animation: ${({ animation }) => ((animation === 'wave') ? wave : animation === 'pulse' ? pulse : null)};
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-delay: 0.5s;
    animation-timing-function: linear;
    transform: rotate(45deg);
  }
`;

StyledSkeleton.defaultProps = { component: 'div', width: '100%', height: '1.2em' };

const SkeletonItem = ({
  animation = 'pulse',
  component = 'div',
  height = '1.2em',
  width = '100%',
}) => {
  const getAnimation = (animation) => {
    if (!animation) return;
    if (animation === true || animation === 'wave') {
      return 'wave';
    }
    if (animation === 'pulse') {
      return 'pulse';
    }
  };
  return (
    <StyledSkeleton width={width} height={height} animation={getAnimation(animation)} component={component}/>
  );
};

export default SkeletonItem;
SkeletonItem.propTypes = {
  /**
   * The animation.
   * If `false` the animation effect is disabled.
   */
  animation: PropTypes.oneOf(['pulse', 'wave', false, true]),
  /**
   * Override or extend the styles applied to the component.
   * See [CSS API](#css) below for more details.
   */
  classes: PropTypes.object,
  /**
   * @ignore
   */
  className: PropTypes.string,
  /**
   * The component used for the root node.
   * Either a string to use a HTML element or a component.
   */
  component: PropTypes.oneOf(['div', 'span', 'p']),
  /**
   * Height of the skeleton.
   * Useful when you don't want to adapt the skeleton to a text element but for instance a card.
   */
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * The type of content that will be rendered.
   */
  variant: PropTypes.oneOf(['text', 'rect', 'circle']),
  /**
   * Width of the skeleton.
   * Useful when the skeleton is inside an inline element with no width of its own.
   */
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
