import './style.css';

import React from 'react';

import SkeletonItem from '../../skeletonItem/SkeletonItem';

const ItemSkeleton = ({ isAutonome }) => {
  return (
    <div className={'d-flex flex-row justify-content-between flex-row align-items-center px-md-2 infopatient-historique-item '}>
      <div className={'px-2 flex-grow-4 col-title'}>
        <SkeletonItem animation />
      </div>
      <div className="px-2 d-none d-md-block col-number">
        <SkeletonItem animation />
      </div>
      <div className="px-2 d-none d-md-block col-date">
        <SkeletonItem animation />
      </div>
      { isAutonome
        ? (
          <div className={'px-2'}>
            <SkeletonItem animation />
          </div>
        )
        : null
      }
      <div className={'px-2 col-status'}>
        <SkeletonItem animation />
      </div>
      <div className={'px-2 col-action'}>
        <SkeletonItem animation />
      </div>
    </div>
  );
};

export default ItemSkeleton;