export { default as medicQueries } from './medicQueries';
export { default as medicMutations } from './medicMutations';
export { default as patientQueries } from './patientQueries';
export { default as patientMutations } from './patientMutations';
export { default as templateQueries } from './templateQueries';
export { default as medicTemplateQueries } from './medicTemplateQueries';
export { default as medicTemplateMutations } from './medicTemplateMutations';
export { default as surveyMutations } from './surveyMutations';
export { default as surveyQueries } from './surveyQueries';
export { default as clinicQueries } from './clinicQueries';
export { default as authQueries } from './authQueries';
export { default as questionIntroQueries } from './questionIntroQueries';
export { default as cityQueries } from './cityQueries';
export { default as userQueries } from './userQueries';
export { default as userMutations } from './userMutations';
export { default as languageQueries } from './languageQueries';
