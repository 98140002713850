import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ALL } from '../../../../utils/const';
import { capitalizeFirstLetter } from '../../../../utils/utils';
import Card from '../../../components/card';
import Filter from '../../../components/filter';

function FilterSelect({ title, onSelect, list, type, selected, qty }) {
  const { t } = useTranslation();
  return (
    <Card>
      <div className={'form-screen-filters'}>
        <p className={'form-screen-filters-title'}>
          {title}
        </p>
        {
          [{ id: ALL, value: ALL, label: t(ALL) }, ...(list || [])]
            .map((s) => (
              <Filter
                key={`${type}_${s.value}`}
                primaryText={capitalizeFirstLetter(s.label)}
                activated={selected.includes(s.value)}
                secondaryText={
                  qty?.[s.value] || 0
                }
                value={s.label}
                type={'compact'}
                onClick={(filter) => onSelect({ type, value: filter, id: s.value })}
              />
            ))
        }
      </div>
    </Card>
  );
}

FilterSelect.propTypes = {
  title: PropTypes.string,
  onSelect: PropTypes.func,
  list: PropTypes.array,
  type: PropTypes.string,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  qty: PropTypes.object,
};

export default FilterSelect;
