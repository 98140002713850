
export function generateUniqKey(length = 10) {
  const content = 'abcdefghijklmnopqrstuvwxyz0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ-$!_&%+;:[{]}§ç?*ùµ@#';
  let key = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * content.length);
    key += content[randomIndex];
  }

  return key;
}
