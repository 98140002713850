import _ from 'lodash';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AbilityContext } from '../../../services/casl/Can';
import { MODEL_NAMES, PATIENT_FIELDS, PERMISSIONS } from '../../../services/casl/roles';
import { getUserProperty, secuNumbFormater } from '../../../utils';
import Card from '../../components/card';
import SkeletonItem from '../../components/skeletonItem/SkeletonItem';

const PatientContactInfo = ({ patient, isLoading }) => {
  const { t } = useTranslation();
  const ability = useContext(AbilityContext);
  const isVisible = useMemo(() => ({ security_number: ability.can(PERMISSIONS.READ, { __typename: MODEL_NAMES.PATIENT_FIELDS, value: PATIENT_FIELDS.SECURITY_NUMBER }) }), [ability]);

  const fullDisplayName = patient && getUserProperty(patient, 'fullDisplayName');
  const postcode = _.get(patient, 'postcode');
  const city = _.get(patient, 'city');
  const address = `${postcode ? `${postcode} ` : ''}${city || ''}`;
  const isTutor = _.get(patient, 'is_tutor', false);

  return (
    <Card>
      <div className={isTutor ? 'patient-row-tutored-container' : 'patient-row-text-container'}>
        <p className={'patient-text'}>{t('patient_informations')}</p>
        <div className={'patient-column-container'}>
          <div className={'infopatient-row-container'}>
            <p className={'infopatient-text-min'}>{t('tutors_text_name')}</p>
            <div className={'infopatient-text-max'}>
              { isLoading
                ? <SkeletonItem width={160} animation />
                : fullDisplayName
              }
            </div>
          </div>
          <div className={'infopatient-row-container'}>
            <p className={'infopatient-text-min'}>{t('patient_text_phone')}</p>
            <div className={'infopatient-text-max'}>
              { isLoading
                ? <SkeletonItem width={160} animation />
                : _.get(patient, 'local_phone_number')
              }
            </div>
          </div>
          { isTutor && (
            <>
              <div className={'infopatient-row-container'}>
                <p className={'infopatient-text-min'}>{t('patient_text_email')}</p>
                <div className={'infopatient-text-max'}>
                  { isLoading
                    ? <SkeletonItem width={160} animation />
                    : (_.get(patient, 'email')) || ''
                  }
                </div>
              </div>
              <div className={'infopatient-row-container'}>
                <p className={'infopatient-text-min'}>{t('patient_text_address')}</p>
                <div className={'infopatient-text-max'}>
                  { isLoading
                    ? <SkeletonItem width={160} animation />
                    : address
                  }
                </div>
              </div>
            </>
          )}
          {isVisible['security_number'] && (
            <div className={'infopatient-row-container'}>
              <p className={'infopatient-text-min'}>{t('patient_card_intervention_securite')}</p>
              <p className={'infopatient-text-max'}>
                { isLoading
                  ? <SkeletonItem width={160} animation />
                  : secuNumbFormater(_.get(patient, 'security_number', ''))
                }
              </p>
            </div>
          )}
        </div>
      </div>
    </Card>
  );
};

export default PatientContactInfo;
