import './Paint.css';
import 'rc-slider/assets/index.css';

import Slider from 'rc-slider';
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { CirclePicker } from 'react-color';
import { useTranslation } from 'react-i18next';

import CanvasDraw from './react-canvas-draw/lib/index';

export const ACTIONS = {
  UNDO: 'UNDO',
  CLEAR: 'CLEAR',
  RETURN: 'RETURN',
  DELETE: 'DELETE',
  IMAGE: 'IMAGE',
};

const Paint = (
  { size, imgSrc, onClick, savedData },
  ref,
) => {
  const { t } = useTranslation();
  const [color, setColor] = useState('#444');
  const [brushRadius, setBrushRadius] = useState(12);
  const canvasDrawRef = useRef({});

  useImperativeHandle(ref, () => ({
    getSaveData() {
      if (canvasDrawRef && canvasDrawRef.current) {
        return canvasDrawRef.current.getSaveData();
      }
    },
  }));

  const handleColorChange = (color) => {
    setColor(color.hex);
  };

  const handleActions = (action) => {
    switch (action) {
      case ACTIONS.CLEAR:
        canvasDrawRef.current.clear();
        if (onClick) {
          onClick(action);
        }
        break;
      case ACTIONS.UNDO:
        canvasDrawRef.current.undo();
        break;
      case ACTIONS.RETURN:
      case ACTIONS.DELETE:
        if (onClick) {
          onClick(action);
        }
        break;
      default:
        break;
    }
  };

  return (
    <div className={'d-flex flex-row'}>
      <div className={'paint-sidenav'}>
        <CirclePicker
          circleSize={25}
          circleSpacing={14}
          width={'100%'}
          colors={[
            '#f44336',
            '#e91e63',
            '#9c27b0',
            '#673ab7',
            '#3f51b5',
            '#2196f3',
            '#03a9f4',
            '#00bcd4',
            '#009688',
            '#4caf50',
            '#8bc34a',
            '#cddc39',
            '#ffeb3b',
            '#ffc107',
            '#ff9800',
            '#ff5722',
            '#795548',
            '#607d8b',
            '#000000',
            '#ffffff',
          ]}
          onChange={handleColorChange}
        />
        <br /> <br />
        <label>{t('paint.brushRadius.label', { radius: brushRadius })}</label>
        <Slider
          step={1}
          min={1}
          max={20}
          value={brushRadius}
          onChange={(value) => setBrushRadius(value)}
        />
        <div className="paint-button-content-lines">
          <button
            className={'ec-modal-button-second ec-modal-button-text'}
            onClick={() => handleActions(ACTIONS.CLEAR)}
            type="button"
          >
            <p> {t('paint.btn.reset')} </p>
          </button>
          <button
            className={'ec-modal-button-second ec-modal-button-text'}
            onClick={() => handleActions(ACTIONS.UNDO)}
            type="button"
          >
            <p> {t('paint.btn.undo')} </p>
          </button>
          {/*<button className={"ec-modal-button-second ec-modal-button-text"} onClick={() => handleActions(ACTIONS.RETURN)} ><p> {t('return')} </p></button>*/}
          <button
            className={'ec-modal-button-second ec-modal-button-text'}
            onClick={() => handleActions(ACTIONS.DELETE)}
            type="button"
          >
            <p> {t('paint.btn.delete_draw')} </p>
          </button>
        </div>
      </div>
      <div className={'paint-content'}>
        <br />
        <br />
        <div id={'my_canvas'} style={{ width: '100%', marginTop: '-100px' }}>
          <CanvasDraw
            key={0}
            ref={canvasDrawRef}
            loadTimeOffset={10}
            canvasWidth={size[0] - 300}
            canvasHeight={size[1] - 250 > 0 ? size[1] - 250 : 400}
            imgSrc={imgSrc}
            brushRadius={brushRadius}
            brushColor={color}
            saveData={savedData}
            immediateLoading
          />
        </div>
      </div>
    </div>
  );
};

export default forwardRef(Paint);
