import { createAction } from '../../utils';

// Types
export const types = {
  RESET_REDUCER: 'intro_RESET_REDUCER',

  INTRO_START_LOADER: 'intro_START_LOADER',
  INTRO_FETCH: 'intro_FETCH',
  INTRO_FETCH_SUCCES: 'intro_FETCH_SUCCESS',
  INTRO_STOP_LOADER: 'intro_STOP_LOADER',

  INCREMENT_STEP: 'intro_INCREMENT_STEP',
  RESET_STEP: 'intro_RESET_STEP',
};

// Actions
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  /** REQUEST */
  startLoader: () => createAction(types.INTRO_START_LOADER),
  introQuestionRequest: (surveyTypeId, lngId) => createAction(types.INTRO_FETCH, { surveyTypeId, lngId }),
  introQuestionSuccess: (introductionQuestions) => createAction(types.INTRO_FETCH_SUCCES, { introductionQuestions }),
  stopLoader: () => createAction(types.INTRO_STOP_LOADER),

  incrementStep: (isAfter = false) => createAction(types.INCREMENT_STEP, { isAfter }),
  resetStep: () => createAction(types.RESET_STEP),

  resetReducer: () => createAction(types.RESET_REDUCER),

};
