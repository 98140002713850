import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { SURVEY_STATUS } from '../../../../../utils';
import Iframe from '../IFrame/IFrame';

function ContraliaIFrameCounterSignModal({ signUrl, surveyId, contraliaCountersignUrl, onIframeLoaded, genSurveySignUrlReset, onSignSuccess }) {
  const [listening, setListening] = useState(false);
  const [surveyStatus, setSurveyStatus] = useState();
  const [isLoading, setIsLoading] = useState();

  useEffect(() => {
    handleContraliaInitiate();
    return () => genSurveySignUrlReset();
  }, []);

  useEffect(() => {
    if ([SURVEY_STATUS.COUNTERSIGNED].includes(surveyStatus)) {
      handleSuccess();
    }
  }
  , [surveyStatus]);

  const handleSuccess = () => {
    genSurveySignUrlReset();
    onSignSuccess();
  };

  const handleContraliaInitiate = () => {
    setIsLoading(true);
    contraliaCountersignUrl(surveyId);
  };

  useEffect(() => {
    if (signUrl) {
      if (signUrl === 'already_signed') {
        handleSuccess();
      } else {
        setIsLoading(false);
      }
    }
  }, [signUrl]);

  useEffect(() => {
    if (listening) {
      const PROXY_API_URL = process.env.REACT_APP_PROXY_API || process.env.REACT_APP_BASE_API_URL || '/api';
      const url = `${PROXY_API_URL}/V2/surveys/${surveyId}/realtime-status`;
      const source = new EventSource(url, { withCredentials: false });
      source.onopen = () => {
        // eslint-disable-next-line no-console
        console.log('SSE opened!');
      };
      source.onmessage = (e) => {
        const data = JSON.parse(e.data);
        setSurveyStatus(data.status);
      };
      source.onerror = (e) => {
        // eslint-disable-next-line no-console
        console.error('SSE Error: ', e);
        source.close();
      };
      source.addEventListener('close', () => {
        source.close();
      });
      return () => {
        source.close();
      };
    }
  }, [listening]);

  const handleIframeMessage = ({ name, params }) => {
    // edoc.ready  edoc.done   edoc.pageLoaded
    if (name === 'edoc.done') {
      setListening(true);
    } else if (name === 'edoc.ready') {
      onIframeLoaded && onIframeLoaded();
    }
  };

  return (
    <Iframe
      className="w-100 mx-2 px-2 ec-card"
      src={signUrl}
      width="100%"
      height="750px"
      onMessage={handleIframeMessage}
      isLoading={isLoading === true || !signUrl}
    />
  );
}

ContraliaIFrameCounterSignModal.propTypes = {
  signUrl: PropTypes.string,
  onIframeLoaded: PropTypes.func,
  genSurveySignUrlReset: PropTypes.func.isRequired,
  onSignSuccess: PropTypes.func.isRequired,
  contraliaCountersignUrl: PropTypes.func.isRequired,
};

export default ContraliaIFrameCounterSignModal;
