// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

/* React Select */
.ec-select-group_style {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 600;
  color: #000000;
  cursor: pointer;
}

.ec-select-group_style:hover {
  color: #00aecb;
}

.ec-select-group_style_disabled {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 600;
  color: grey;
  cursor: pointer;
}

.ec-group-badge-styles {
  background-color: #ebecf0;
  border-radius: 2em;
  color: #172b4d;
  display: inline-block;
  font-size: 12;
  font-weight: normal;
  line-height: 1;
  min-width: 1;
  padding: 0.16666666666667em 0.5em;
}

.ec-group-badge-styles_disabled {
  background-color: #ebecf0;
  border-radius: 2em;
  color: grey;
  display: inline-block;
  font-size: 12;
  font-weight: normal;
  line-height: 1;
  min-width: 1;
  padding: 0.16666666666667em 0.5em;
}

.reactSelect input:focus,
.reactSelect > input:focus {
  border: none;
  box-shadow: unset;
}
`, "",{"version":3,"sources":["webpack://./src/ui/components/formV3/controllers/InputSelectV2/style.css"],"names":[],"mappings":";;AAEA,iBAAiB;AACjB;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,eAAe;EACf,gBAAgB;EAChB,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,cAAc;EACd,qBAAqB;EACrB,aAAa;EACb,mBAAmB;EACnB,cAAc;EACd,YAAY;EACZ,iCAAiC;AACnC;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,WAAW;EACX,qBAAqB;EACrB,aAAa;EACb,mBAAmB;EACnB,cAAc;EACd,YAAY;EACZ,iCAAiC;AACnC;;AAEA;;EAEE,YAAY;EACZ,iBAAiB;AACnB","sourcesContent":["\n\n/* React Select */\n.ec-select-group_style {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  font-size: 18px;\n  font-weight: 600;\n  color: #000000;\n  cursor: pointer;\n}\n\n.ec-select-group_style:hover {\n  color: #00aecb;\n}\n\n.ec-select-group_style_disabled {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  font-size: 18px;\n  font-weight: 600;\n  color: grey;\n  cursor: pointer;\n}\n\n.ec-group-badge-styles {\n  background-color: #ebecf0;\n  border-radius: 2em;\n  color: #172b4d;\n  display: inline-block;\n  font-size: 12;\n  font-weight: normal;\n  line-height: 1;\n  min-width: 1;\n  padding: 0.16666666666667em 0.5em;\n}\n\n.ec-group-badge-styles_disabled {\n  background-color: #ebecf0;\n  border-radius: 2em;\n  color: grey;\n  display: inline-block;\n  font-size: 12;\n  font-weight: normal;\n  line-height: 1;\n  min-width: 1;\n  padding: 0.16666666666667em 0.5em;\n}\n\n.reactSelect input:focus,\n.reactSelect > input:focus {\n  border: none;\n  box-shadow: unset;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
