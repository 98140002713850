
import * as mask from '../../../../formV3/utils/masks';

export const INITIAL_INFO = {
  gender: '',
  firstname: '',
  lastname: '',
  use_name: '',
  security_number: '',
  birthdate: '',
  birthplace_id: undefined,
  birthplace_postcode: mask.postcode,
  birthplace: '',
};