// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_tag__qZtOE {
  background-color: #00aecb;
  background-image: linear-gradient(#00d7e7, #00aecb);
  border-radius: 20px;
  color: #ffffff;
}

.style_input_tag__ld-3i {
  height: 30px;
  outline: none;
  border: none;
  font-size: 18px;
}

.style_input_tag__ld-3i:hover, .style_input_tag__ld-3i:focus {
  border-radius: 8px;
  border: solid 1px #cfcfcf;
}`, "",{"version":3,"sources":["webpack://./src/ui/components/formV3/controllers/InputTagsV3/style.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,mDAAmD;EACnD,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,yBAAyB;AAC3B","sourcesContent":[".tag {\n  background-color: #00aecb;\n  background-image: linear-gradient(#00d7e7, #00aecb);\n  border-radius: 20px;\n  color: #ffffff;\n}\n\n.input_tag {\n  height: 30px;\n  outline: none;\n  border: none;\n  font-size: 18px;\n}\n\n.input_tag:hover, .input_tag:focus {\n  border-radius: 8px;\n  border: solid 1px #cfcfcf;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tag": `style_tag__qZtOE`,
	"input_tag": `style_input_tag__ld-3i`
};
export default ___CSS_LOADER_EXPORT___;
