export const questionTypeEnum = {
  validation: 'validation',
  text_answer: 'text_answer',
  dropdown_list: 'dropdown_list',
  multiple_choice: 'multiple_choice',
  pdf: 'pdf',
  checkbox_full: 'checkbox_full',
  radio_multi_questions_to_table: 'radio_multi_questions_to_table',
};

/**
 * @description: This class is responsible for formatting the data to be displayed in a table.
 * It groups the questions by type and then generates the headers and values for the table.
 */
export class FormattedTableData {
  #questions;
  #headers;

  constructor(questions) {
    this.#questions = this.#groupTheQuestionsWhoseAnswersAreIdentical(questions);
    this.#headers = new Map();
  }

  static questionGroupByType(questions) {
    return Array.from(questions.reduce((grouped, question) => {
      if (!grouped.has(question.type_id)) {
        grouped.set(question.type_id, { type: question.type_id, questions: [] });
      }
      grouped.get(question.type_id).questions.push(question);
      return grouped;
    }, new Map()).values());
  }

  getTableData() {
    const headers = this.#questions.map(this.#generateHeaders.bind(this));
    return headers.map((header, index) => {
      const matrix = [header.map(h => h.label)];
      this.#questions[index].forEach(question => {
        const row = header.map(h => h.values.includes(question.answer));
        row.unshift(question.translation.question);
        matrix.push(row);
      });
      return matrix;
    });
  }

  #generateHeaders(questions) {
    questions.forEach(q => {
      q.translation.data.list.forEach(({ key, value }) => {
        const lowerValue = value.toLowerCase();
        if (!this.#headers.has(lowerValue)) {
          this.#headers.set(lowerValue, new Set());
        }
        this.#headers.get(lowerValue).add(key);
      });
    });
    const headers = Array.from(this.#headers.entries()).map(([key, valuesSet]) => ({
      label: key,
      values: Array.from(valuesSet),
    }));
    this.#headers.clear();
    return headers;
  }

  #groupTheQuestionsWhoseAnswersAreIdentical(questions) {
    const hasIntersectionArrays = (array1, array2, key) => {
      const set = new Set(array2.map(obj => obj[key].toLowerCase()));
      return array1.every(obj => set.has(obj[key].toLowerCase()));
    };

    return questions.reduce((acc, item) => {
      const list = item.translation.data.list;
      const group = acc.find(group =>
        group.some(gItem => hasIntersectionArrays(gItem.translation.data.list, list, 'value')),
      );
      group ? group.push(item) : acc.push([item]);
      return acc;
    }, []);
  }
}