import { createAction } from '../../utils';
import { PUBLIC_HOME_PATH } from '../../utils/const';

// Types
export const types = {
  LOGIN_FROM_TOKEN: 'auth_LOGIN_FROM_TOKEN',
  LOGIN_REFRESH: 'auth_LOGIN_REFRESH',
  LOGIN_REQUEST: 'auth_LOGIN_REQUEST',
  LOGIN_FAILURE: 'auth_LOGIN_FAILURE',
  LOGIN_SUCCESS: 'auth_LOGIN_SUCCESS',
  ROLE_SUCCESS: 'auth_ROLE_SUCCESS',
  FORGOT_PASSWORD: 'auth_FORGOT_PASSWORD',
  CHANGE_PASSWORD: 'auth_CHANGE_PASSWORD',
  CHECK_CODE: 'auth_CHECK_CODE',
  CHECK_CODE_SUCCESS: 'auth_CHECK_CODE_SUCCESS',
  CHECK_CODE_FAILURE: 'auth_CHECK_CODE_FAILURE',
  CREATE_PASSWORD: 'auth_CREATE_PASSWORD',
  LOGOUT: 'auth_LOGOUT',
  LOGOUT_SUCCESS: 'auth_LOGOUT_SUCCESS',
  RESET_STATUS: 'auth_RESET_STATUS',
  IP_REQUEST: 'auth_IP_REQUEST',
  IP_SUCCESS: 'auth_IP_SUCCESS',
  IP_FAILURE: 'auth_IP_FAILURE',
  RENEW_INFOS: 'auth_RENEW_INFOS',
  SET_LAST_SMS_SENT: 'auth_SET_LAST_SMS_SENT',
  CLEAR_LAST_SMS_SENT: 'auth_CLEAR_LAST_SMS_SENT',
  SMS_SET_ERROR: 'SMS_SET_ERROR',
  SMS_CLEAR_ERROR: 'SMS_CLEAR_ERROR',

  SET_LOGIN_FROM_QR_CODE: 'auth_SET_LOGIN_FROM_QR_CODE',
  SET_TOKENS: 'auth_SET_TOKENS',


  PASSWORD_SUCCESS: 'auth_PASSWORD_SUCCESS',
  APPROVED_CGU: 'auth_APPROVED_CGU',
  CGU_SUCCESS: 'auth_CGU_SUCCESS',
  APPROVED_RGPD: 'auth_APPROVED_RGPD',

  RGPD_SUCCESS: 'auth_RGPD_SUCCESS',
  EDIT_INFO_SUCCESS: 'auth_EDIT_INFO_SUCCESS',
  EDIT_INFO_REQUEST: 'auth_EDIT_INFO_REQUEST',
  LEGAL_RESET_SUCCESS: 'auth_LEGAL_RESET_SUCCESS',

  GET_LOGIN_INFO: 'auth_GET_LOGIN_INFO',
  GET_LOGIN_INFO_ERROR: 'auth_GET_LOGIN_INFO_ERROR',
  GET_LOGIN_INFO_SUCCESS : 'auth_GET_LOGIN_INFO_SUCCESS ',

  // GET_USER_LANGUAGE: 'auth_GET_USER_LANGUAGE',

  ADD_ABILITES_RULES: 'auth_ADD_ABILITES_RULES',
  SAVE_ABILITES_RULES: 'auth_SAVE_ABILITES_RULES',

  DISABLED_TOKEN: 'auth_DISABLED_TOKEN',
  DISABLED_TOKEN_SUCCESS: 'auth_DISABLED_TOKEN_SUCCESS',

  DELETE_CURRENT_TOKEN_REQUEST: 'auth_DELETE_CURRENT_TOKEN_REQUEST',

  USER_LOADING: 'auth_USER_LOADING',
  USER_LOADED: 'auth_USER_LOADED',

  AXIGATE_ACTION_ON_LOGIN: 'auth_AXIGATE_ACTION_ON_LOGIN',
  SET_REDIRECT_URL: 'auth_SET_REDIRECT_URL',
  CLEAR_REDIRECT_URL: 'auth_CLEAR_REDIRECT_URL',

  SET_CASL_RULES: 'SET_CASL_RULES',

  ATTACH_USER_CODE_REQUEST: 'auth_ATTACH_USER_CODE_REQUEST',
  ATTACH_USER_CODE_REQUEST_SUCCESS : 'auth_ATTACH_USER_CODE_REQUEST_SUCCESS ',
  ATTACH_USER_CODE_REQUEST_FAILURE: 'auth_ATTACH_USER_CODE_REQUEST_FAILURE',

  AUTHORIZATION_REQUEST: 'AUTHORIZATION_REQUEST',
  AUTHORIZATION_SUCCESS: 'AUTHORIZATION_SUCCESS',
  AUTH_OTP_REQUEST: 'AUTH_OTP_REQUEST',
  LOGIN_FROM_OTP_REQUEST: 'LOGIN_FROM_OTP_REQUEST',

  USER_SUCCESS: 'auth_USER_SUCCESS',
};

// Actions
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  authorizationRequest: (username, password) => createAction(types.AUTHORIZATION_REQUEST, { username, password }),
  authorizationSuccess: (username, password, code, uuid, otp) => createAction(types.AUTHORIZATION_SUCCESS, { username, password, code, uuid, otp }),
  authOtpRequest: (username, password, code) => createAction(types.AUTH_OTP_REQUEST, { username, password, code }),
  loginFromOtpRequest: (otp)=> createAction(types.LOGIN_FROM_OTP_REQUEST, { otp }),

  loginRefresh: () => createAction(types.LOGIN_REFRESH),
  loginRequest: (username, password) => createAction(types.LOGIN_REQUEST, { username, password }),
  loginSuccess: (token_type, access_token, refresh_token, authorization = null, username = null) => createAction(types.LOGIN_SUCCESS, { token_type, access_token, refresh_token, authorization, username }),
  loginFailure: () => createAction(types.LOGIN_FAILURE),
  userSuccess: (user) => createAction(types.USER_SUCCESS, { user }),
  roleSuccess: (role) => createAction(types.ROLE_SUCCESS, { role }),
  forgotPassword: (username= null, isPro = false, email = null, phoneNumber = null, birthdate = null) => createAction(types.FORGOT_PASSWORD, { username, isPro, email, phoneNumber, birthdate }),
  changePassword: (newPwd, code) => createAction(types.CHANGE_PASSWORD, { newPwd, code }),
  createPassword: (newPwd) => createAction(types.CREATE_PASSWORD, { newPwd }),
  checkCode: (code, action) => createAction(types.CHECK_CODE, { code, action }),
  checkCodeSuccess: () => createAction(types.CHECK_CODE_SUCCESS),
  checkCodeFailure: (status = 'KO') => createAction(types.CHECK_CODE_FAILURE, { status }),
  logout: (displayError = true, newState = null, toUrl = PUBLIC_HOME_PATH, isManualLogout = false ) => createAction(types.LOGOUT, { displayError, newState, toUrl, isManualLogout }),
  logoutSuccess: (newInitialState = null) => createAction(types.LOGOUT_SUCCESS, { newInitialState }),
  disabledToken: () => createAction(types.DISABLED_TOKEN),
  disabledTokenSuccess: () => createAction(types.DISABLED_TOKEN_SUCCESS),
  resetStatus: () => createAction(types.RESET_STATUS),
  ipRequest: () => createAction(types.IP_REQUEST),
  ipSuccess: (ip) => createAction(types.IP_SUCCESS, { ip }),

  renewInfos: (renew) => createAction(types.RENEW_INFOS, { renew }),

  setRedirectUrl: (url) => createAction(types.SET_REDIRECT_URL, { url }),
  clearRedirectUrl: () => createAction(types.CLEAR_REDIRECT_URL),

  setLastSmsSent: (uuid) => createAction(types.SET_LAST_SMS_SENT, { uuid }),
  clearLastSmsSent: () => createAction(types.CLEAR_LAST_SMS_SENT),
  smsSetError: () => createAction(types.SMS_SET_ERROR),
  smsClearError: () => createAction(types.SMS_CLEAR_ERROR),

  setIsLoginFromQrCode: () => createAction(types.SET_LOGIN_FROM_QR_CODE),
  setTokens: (token, refresh_token) => createAction(types.SET_TOKENS, { token, refresh_token }),

  passwordSuccess: () => createAction(types.PASSWORD_SUCCESS),
  cguApprove: (userId) => createAction(types.APPROVED_CGU, { userId }),
  cguSuccess: () => createAction(types.CGU_SUCCESS),
  rgpdApprove: (userId) => createAction(types.APPROVED_RGPD, { userId }),
  rgpdSuccess: () => createAction(types.RGPD_SUCCESS),
  updateUserEditInfoRequest: (userId) => createAction(types.EDIT_INFO_REQUEST, { userId }),
  editInfoSuccess: () => createAction(types.EDIT_INFO_SUCCESS),
  resetLegalSuccess: () => createAction(types.LEGAL_RESET_SUCCESS),

  getLoginInfo: () => createAction(types.GET_LOGIN_INFO),
  getLoginInfoSuccess: () => createAction(types.GET_LOGIN_INFO_SUCCESS ),
  getLoginInfoError: () => createAction(types.GET_LOGIN_INFO_ERROR),
  // getUserLanguage: () => createAction(types.GET_USER_LANGUAGE),
  addAbilitiesRules: (rules, key) => createAction(types.ADD_ABILITES_RULES, { rules, key }),
  saveAbilitiesRules: (rules, key) => createAction(types.SAVE_ABILITES_RULES, { rules, key }),

  deleteCurrentToken: () => createAction(types.DELETE_CURRENT_TOKEN_REQUEST),

  userLoading: () => createAction(types.USER_LOADING),
  setCaslRules: (rules) => createAction(types.SET_CASL_RULES, { rules }),

  axigateActionOnLogin: () => createAction(types.AXIGATE_ACTION_ON_LOGIN),
  loginFromToken: (access_token, refresh_token, username = null, token_type = 'Bearer' ) => createAction(types.LOGIN_FROM_TOKEN, { token_type, access_token, refresh_token, username }),

  attachUserCodeRequest: (formData) => createAction(types.ATTACH_USER_CODE_REQUEST, { formData }),
  attachUserCodeRequestSuccess: (username, password, uuid) => createAction(types.ATTACH_USER_CODE_REQUEST_SUCCESS, { username, password, uuid }),
  attachUserCodeRequestFailure: () => createAction(types.ATTACH_USER_CODE_REQUEST_FAILURE),

};
