import './style.css';

import React, { useEffect, useRef, useState } from 'react';

import { IconRafter } from '../../../assets/img/icons';
import { deepChildrenMap } from '../../../utils';
import DropdownItem from './DropdownItem';

const Dropdown = ({ text, smallText, title, children, className }) => {
  const node = useRef();

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const handleClickOutside = (e) => {
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  const handleItemClick = (e, callback) => {
    setIsOpen(false);
    if (callback) callback(e);
  };

  const preparedChildren = deepChildrenMap(children, (child) => {
    if (child.type === DropdownItem) {
      var extraChildProps = { onClick: (e) => handleItemClick(e, child?.props?.onClick) };
      return React.cloneElement(child, extraChildProps);
    } else return child;
  });

  return (
    <div ref={node} className="ec-dropdown">
      {title && (
        <span className="ec-dropdown-title authlayout-second-dropdown-button-name-text-name">
          {title}
        </span>
      )}
      <button
        className={
          /*showDetails ? "authlayout-button-name-info-into-details-activated" : */ 'authlayout-button-name-info-into-details'
        }
        onClick={toggle}
      >
        <p
          className={`${
            className ? className : 'authlayout-button-name-info-into-details-text-name'
          }${isOpen ? ' activated' : ''}`}
        >
          {text}
        </p>
        <p
          className={`authlayout-button-name-info-into-details-text-name-small${
            isOpen ? ' activated' : ''
          }`}
        >
          {smallText}
        </p>
        <div
          className={`authlayout-button-name-info-into-details-image${isOpen ? ' activated' : ''}`}
        >
          <img src={IconRafter} alt="IconRafter" />
        </div>
      </button>
      {isOpen ? <ul>{preparedChildren}</ul> : null}
    </div>
  );
};

export default Dropdown;
