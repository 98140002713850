
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { PATIENT_HOME_PATH, PRO_HOME_PATH, PUBLIC_HOME_PATH } from '../../../utils/const';


const NoMatch = ({ isPro, isUserLoggedIn }) => {
  const { t } = useTranslation();
  let location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // displayWarning(t('noMatch.disclamer', { pathname: location.pathname }));
    // eslint-disable-next-line no-console
    console.log(t('noMatch.disclamer', { pathname: location.pathname }));
    const fromUrl = `${location.pathname}${location.search || ''}`;
    if (isUserLoggedIn) {
      navigate(isPro ? PRO_HOME_PATH : PATIENT_HOME_PATH, { state: { fromUrl } });
    } else {
      navigate(PUBLIC_HOME_PATH, { state: { fromUrl } });
    }
  }, [location?.pathname]);

  return (
    <div>
      <h3>
        {t('noMatch.disclamer', { pathname: location.pathname }) }
      </h3>
    </div>
  );
};

export default NoMatch;

