/**
 * GRAPHQL MUTATIONS
 */

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  update: () => `
    mutation UpdateMedic($_medic: medics_set_input!, $_id: Int!, $_user: users_set_input!, $_uid: Int!) {
      update_medics(
        where: { id: { _eq: $_id} }, 
        _set: $_medic
      ) {
        returning {
          id
        }
      }
      update_users(
        where: { id: { _eq: $_uid} }, 
        _set: $_user
      ) {
        returning {
          id
          username
          language_id
          language {
            id
            language_code
            name
            name_in_native_language
            date_format
            currency
            country_code_flag
          }
        }
      }
    }

  `,
  updateCGU: () => `
    mutation updateCGU($_id: Int!) {
      update_users(where: {id: {_eq: $id}}, _set: {accepted_cgu: true}) {
        returning {
          id
          accepted_cgu
        }
      }
    }
  `,
  updateRGPD: () => `
    mutation updateRGPD($_id: Int!) {
      update_users(where: {id: {_eq: $id}}, _set: {accepted_rgpd: true}) {
        returning {
          id
          accepted_rgpd
        }
      }
    }
  `,
  updateEditInfo: () => `
    mutation UpdateEditInfo($id: Int!) {
      update_users(where: {id: {_eq: $id}}, _set: {edit_info: true}) {
        returning {
          id
          edit_info
        }
      }
    }
  `,
  updateNotif: () => `
  mutation UpdateNotif($medic: medics_set_input!, $id: Int!) {
    update_medics(where: {id: {_eq: $id}}, _set: $medic) {
      returning {
        id
        notif_mail
        notif_sms
      }
    }
  }
`,
};
