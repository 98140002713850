import { createAction } from '../../utils/index';

// Types
export const types = {
  RESET_REDUCER: 'medic_RESET_REDUCER',

  DISPLAY_ERROR: 'DISPLAY_ERROR',
  DISPLAY_WARNING: 'DISPLAY_WARNING',
  DISPLAY_INFO: 'DISPLAY_INFO',
  HIDE_SNACK_BAR: 'HIDE_SNACK_BAR',
};

export const levels = {
  INFO: 'info',
  SUCCESS:  'success',
  WARNING: 'warning',
  ERROR: 'error',
  DEFAULT: 'default',
};

// Actions
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  displayError: (message, timeout = 5000) =>
    createAction(types.DISPLAY_ERROR, {
      message,
      level: levels.ERROR,
      timeout,
    }),

  displayInfo: (message, timeout = 5000) =>
    createAction(types.DISPLAY_INFO, {
      message,
      level: levels.INFO,
      timeout,
    }),

  displayWarning: (message, timeout = 5000) =>
    createAction(types.DISPLAY_WARNING, {
      message,
      level: levels.WARNING,
      timeout,
    }),

  hideSnackBar: () => createAction(types.HIDE_SNACK_BAR),
};
