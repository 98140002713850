import yup from '../../../../utils/yup';


export const TYPE_TUTOR = {
  OBLIGATORY: 'OBLIGATORY',
  OPTIONAL: 'OPTIONAL',
};
export const SPACE_BETWEEN_POSTAL_CODE = ' ';
export const MAX_TUTORS = 2;
export const MIN_TUTORS = 1;
export const IS_DEBUG = process.env.NODE_ENV === 'development' && !!process.env.REACT_APP_DEBUG;

export const defaultTutorsSurvey = {
  obligatory_tutors_of_patient: [],
  optional_tutors_of_patient: [],
};

export const yupSchema = yup.object({
  obligatory_tutors_of_patient: yup.array().of(
    yup.string()
      .required('surveyPatientForm.guardian.obligatory.rules.required'),
  ),
});
