import _ from 'lodash';
import { createReducer } from 'reduxsauce';

import { APP_MODES } from '../../utils/enum';
import { types } from './actions';

const initialState = {
  loading: false,
  appMode: APP_MODES.COMMON,
  isClinicalStudy: false,
};

const startLoader = (state = { ...initialState }) => ({
  ...state,
  loading: true,
});

const stopLoader = (state = { ...initialState }) => ({
  ...state,
  loading: false,
});


const setPatientAppMode = (state = { ...initialState }, action = {} ) => {
  const { surveys } = action;
  const appMode = surveys.some(survey => _.get(survey, 'survey_type.should_countersign')) ? APP_MODES.CLINICAL_STUDY : APP_MODES.COMMON;

  return {
    ...state,
    appMode,
  };
};

const setAppModeSuccess = (state = { ...initialState }, action = {} ) => {
  const { appMode } = action;
  return {
    ...state,
    appMode,
  };
};

const setIsClinicalStudy= (state = { ...initialState }, action = {} ) => {
  const { isClinicalStudy } = action;
  return {
    ...state,
    isClinicalStudy: isClinicalStudy || false,
  };
};


const resetReducer = () => {
  return { ...initialState };
};


export default createReducer(initialState, {
  [types.RESET_REDUCER]: resetReducer,

  [types.DISPLAY_START_LOADER]: startLoader,
  [types.DISPLAY_STOP_LOADER]: stopLoader,

  [types.SET_PATIENT_APP_MODE]: setPatientAppMode,

  [types.SET_APP_MODE_SUCCESS]: setAppModeSuccess,

  [types.SET_IS_CLINICAL_STUDY]: setIsClinicalStudy,
});
