import '../../style.css';

import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import yup from '../../../../../../utils/yup';
import { ModalButtonsGroup } from '../../../../../components/formV3';
import { InputText } from '../../../../../components/formV3/controllers';
import { CustomFormController } from '../../../../../components/formV3/innerWrapper';

const ResetPwd = (
  {
    id,
    defaultValues,
    updateData,
    onStepCompletion,
    onCancel,
    onSubmit,
    omitData,
    changePassword,
    createPwd,
    code,
    onSubmit:onSubmitProps,
  },
) => {
  const { t } = useTranslation();
  const yupSchema = yup.object().shape({
    password: yup.string()
      .min(12, 'modalRenewForm.password.rules.min')
      .matches(/[A-Z]/, 'modalRenewForm.password.rules.minOneMaj')
      .matches(/[a-z]/, 'modalRenewForm.password.rules.minOneMinuscule')
      .matches(/\d/, 'modalRenewForm.password.rules.minOneNumber')
      .matches(/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~§]/, 'modalRenewForm.password.rules.minOneSpecialChar')
      .required('modalRenewForm.password.rules.required'),
    passwordConfirmation: yup.string()
      .oneOf([yup.ref('password'), null], 'modalRenewForm.passwordConfirmation.rules.same'),
  });

  const formMethods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onSubmit',
    defaultValues: useMemo(() => {
      return defaultValues;
    }, [defaultValues]),
    resolver: yupResolver(yupSchema),
    shouldFocusError: true,
  });
  const { handleSubmit, watch, formState: { isSubmitSuccessful, isValid } } = formMethods;

  useEffect(() => {
    // on form submit complete, got to next step
    if (isSubmitSuccessful === true){
      const step = async () => {
        if (onStepCompletion) await onStepCompletion();
      };
      step();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful]);

  const onSubmits = async (data) => {
    updateData(id, data);
    if (onSubmit) onSubmit(data);
    if (changePassword) code ? changePassword(data.password, code) : createPwd(data.password);
    onSubmitProps && onSubmitProps(data, id);
  };

  const handleCancel = () => {
    omitData(id);
    if (onCancel) onCancel(1);
  };

  return (
    <div >
      {/* STEP 3 */}
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmits)} className={'authentication-forgotten-password-content'}>
          <CustomFormController
            name={'password'}
            rules={{ required: true }}
          >
            <InputText
              inputPreset={'password'}
              placeholder={t('modalRenewForm.password.placeholder')}
              autoComplete="one-time-code"
              type="password"
              hidden
              showPswdHelper
            />
          </CustomFormController>
          <CustomFormController
            name={'passwordConfirmation'}
            rules={{ required: true }}
          >
            <InputText
              inputPreset={'password'}
              placeholder={t('modalRenewForm.passwordConfirmation.placeholder')}
              autoComplete="one-time-code"
              type="password"
              hidden
            />
          </CustomFormController>

          <ModalButtonsGroup
            cancelLabel={t('cancel')}
            submitLabel={t('next')}
            onCancel={handleCancel}
            hideFirst={!(!!onCancel)}
          />
        </form>
        {!!process.env.REACT_APP_DEBUG_FORM && (
          <pre>
            { JSON.stringify(watch(), null, 2) }
            'isValid': { isValid }
          </pre>
        )}
      </FormProvider>
    </div>
  );
};

export default ResetPwd;
