import { put, takeLatest } from 'redux-saga/effects';

import { AuthActions } from '../auth';
import { SplashActions } from '.';
import { types } from './actions';

/**
 * Start Fetching all patient for the current medic
 * @returns {Generator<*, void, ?>}
 */
function* set({ payload }) {
  yield put(SplashActions.setSuccess(payload));
  yield put(AuthActions.logout(false, null, payload.redirectUrlAfterLogout ));
}

// eslint-disable-next-line import/no-anonymous-default-export
export default [takeLatest(types.SET, set)];
