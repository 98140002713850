
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AbilityContext } from '../../../../../services/casl/Can';
import { MODEL_NAMES, PATIENT_FIELDS, PERMISSIONS } from '../../../../../services/casl/roles';
import { capitalizeFirstLetter } from '../../../../../utils/utils';
import { isRequiredField } from '../../../formV3';
import { DatePicker, InputText } from '../../../formV3/controllers';
import InputGroupedRadio from '../../../formV3/controllers/InputGroupedRadio';
import { CustomFormController } from '../../../formV3/innerWrapper';
import CityPicker from '../../../formV3/subForm/CityPicker';
import ModalButtonsGroup from '../../../formV3/submit/ModalButtonsGroup';
import { yupSchema, yupSchemaPatient } from './FormModel/validationSchema';


function InfosFormContainer({ onCancel, defaultValues, onSubmit, isTutor, readonlyFields = [], isPatientReviewing, canPermission = PERMISSIONS.CREATE, caslUpdateAt, isClinicalMode, submitLabel }) {
  const { t } = useTranslation();
  const [prevDefaultValues, setPrevDefaultValues] = useState();
  const ability = useContext(AbilityContext);
  const isVisible = useMemo(() => ({
    gender: true,
    security_number: ability.can(canPermission, { __typename: MODEL_NAMES.PATIENT_FIELDS, value: PATIENT_FIELDS.SECURITY_NUMBER }),
    lastname: true,
    use_name: true,
    firstname: true,
    birthdate: true,
    birthplace: !isClinicalMode,
    birthplace_id: !isClinicalMode,
    birthplace_postcode: !isClinicalMode,
  }), [ability, caslUpdateAt, isClinicalMode]);

  const selectedYupSchema = isPatientReviewing ? yupSchemaPatient(isVisible, isClinicalMode) : yupSchema(isVisible);

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: useMemo(() => defaultValues, [defaultValues, isVisible]),
    resolver: yupResolver(selectedYupSchema),
    shouldFocusError: true,
  });

  const { handleSubmit, watch, setValue, reset } = formMethods;

  useEffect(() => {
    if (_.isObject(defaultValues) && !_.isEqual(defaultValues, prevDefaultValues)){
      reset({ ...defaultValues });
      setPrevDefaultValues(defaultValues);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  useEffect(() => {
    if (isTutor === true){
      setValue('is_tutor', isTutor);
    }
  }, [isTutor]);

  const onSubmits = (data) => {
    onSubmit(data);
  };

  const handleCancel = () => {
    onCancel && onCancel();
  };

  // eslint-disable-next-line no-console
  const onErrors = (errors) => console.error(errors);

  return (
    <>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmits, onErrors)} className={'custom-formV2 d-flex flex-column'} >
          <div className="px-2">
            <CustomFormController
              name={'gender'}
              label={t('patientForms.gender.label')}
              isRequiredField={isRequiredField(selectedYupSchema, 'gender')}
            >
              <InputGroupedRadio
                options={[
                  { key: 'male', value: capitalizeFirstLetter(t('male')) },
                  { key: 'female', value: capitalizeFirstLetter(t('female')) },
                ]}
                containerClassName="input-radio-group"
                className="input-radio-value"
                readOnly={readonlyFields.includes('gender')}
              />
            </CustomFormController>
          </div>
          <div className={'px-2'}>
            <CustomFormController
              name={'security_number'}
              label={t('patientForms.security_number.label')}
              isRequiredField={isRequiredField(selectedYupSchema, 'security_number')}
              isVisible={isVisible['security_number']}
            >
              <InputText
                inputPreset="security_number"
                readOnly={readonlyFields.includes('security_number')}
              />
            </CustomFormController>
          </div>
          <div className="d-flex flex-sm-row flex-column">
            <div className={'px-2 '}>
              <CustomFormController
                name={'lastname'}
                label={t('patientForms.lastname.label')}
                isRequiredField={isRequiredField(selectedYupSchema, 'lastname')}
                isVisible={isVisible['lastname']}
              >
                <InputText
                  className="createPatient-Input"
                  readOnly={readonlyFields.includes('lastname')}
                />
              </CustomFormController>
            </div>
            <div className={'px-2 '}>
              <CustomFormController
                name={'use_name'}
                label={t('patientForms.use_name.label')}
                isRequiredField={isRequiredField(selectedYupSchema, 'use_name')}
                isVisible={isVisible['use_name']}
              >
                <InputText
                  className="createPatient-Input"
                  readOnly={readonlyFields.includes('use_name')}
                />
              </CustomFormController>
            </div>
          </div>
          <div className="d-flex flex-sm-row flex-column">
            <div className="px-2">
              <CustomFormController
                name={'firstname'}
                label={t('patientForms.firstname.label')}
                isRequiredField={isRequiredField(selectedYupSchema, 'firstname')}
                isVisible={isVisible['firstname']}
              >
                <InputText
                  className="createPatient-Input"
                  readOnly={readonlyFields.includes('firstname')}
                />
              </CustomFormController>
            </div>
            <div className="px-2">
              <CustomFormController
                name={'birthdate'}
                label={t('patientForms.birthdate.label')}
                isRequiredField={isRequiredField(selectedYupSchema, 'birthdate')}
                isVisible={isVisible['birthdate']}
              >
                <DatePicker
                  inputPreset={'birthdate'}
                  disabled={readonlyFields.includes('birthdate')}
                  dateFormat={isClinicalMode ? 'MM/yyyy' : 'dd/MM/yyyy'}
                />
              </CustomFormController>
            </div>
          </div>
          <CityPicker
            city={'birthplace'}
            yupSchema={selectedYupSchema}
            defaultValues={defaultValues}
            readonlyFields={readonlyFields}
            isVisible={isVisible}
          />
          <ModalButtonsGroup
            onCancel={handleCancel}
            submitLabel={t('form.button.next.label')}
            hideFirst={!(!!onCancel)}
          />

        </form>
      </FormProvider>

      {!!process.env.REACT_APP_DEBUG_FORM && (
        <pre>
          { JSON.stringify(watch(), null, 2) }
        </pre>
      )}
    </>
  );
}

InfosFormContainer.propTypes = { title: PropTypes.string };

export default InfosFormContainer;
