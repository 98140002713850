// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ec-primary-button {
  background: none;
  border: none;
  outline: none;
  display: flex;
  margin-top: 5px;
  margin-left: 10px;
  margin-right: 10px;
  width: 221px;
  height: 45px;
  cursor: pointer;
  border-radius: 8px;
}

.ec-primary-button-hidden {
  opacity: .3;
}

.ec-primary-button > .ec-primary-button-img-container {
  width: 20px;
  height: 20px;
}

.ec-primary-button-center {
  align-items: center;
  justify-content: flex-start;
}

.ec-primary-button-left {
  align-items: center;
  justify-content: flex-start;
}

.ec-primary-button:hover {
  background-color: #f8f8f8;
}

.ec-primary-button:disabled,
.ec-primary-button[disabled] {
  background-color: #f8f8f8;
}


.ec-primary-button-active {
  background-color: #f8f8f8;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  margin-top: 5px;
  margin-left: 10px;
  margin-right: 10px;
  width: 221px;
  height: 45px;
}

.ec-primary-button:last-child,
.ec-primary-button-active:last-child {
  margin-bottom: 5px;
}

.ec-primary-button-text {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.33;
  color: #393d52;
  margin-left: 30px;
}
`, "",{"version":3,"sources":["webpack://./src/ui/components/buttons/primaryButton/style.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,YAAY;EACZ,aAAa;EACb,aAAa;EACb,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,YAAY;EACZ,YAAY;EACZ,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,mBAAmB;EACnB,2BAA2B;AAC7B;;AAEA;EACE,mBAAmB;EACnB,2BAA2B;AAC7B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;;EAEE,yBAAyB;AAC3B;;;AAGA;EACE,yBAAyB;EACzB,YAAY;EACZ,aAAa;EACb,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,YAAY;EACZ,YAAY;AACd;;AAEA;;EAEE,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,iBAAiB;EACjB,cAAc;EACd,iBAAiB;AACnB","sourcesContent":[".ec-primary-button {\n  background: none;\n  border: none;\n  outline: none;\n  display: flex;\n  margin-top: 5px;\n  margin-left: 10px;\n  margin-right: 10px;\n  width: 221px;\n  height: 45px;\n  cursor: pointer;\n  border-radius: 8px;\n}\n\n.ec-primary-button-hidden {\n  opacity: .3;\n}\n\n.ec-primary-button > .ec-primary-button-img-container {\n  width: 20px;\n  height: 20px;\n}\n\n.ec-primary-button-center {\n  align-items: center;\n  justify-content: flex-start;\n}\n\n.ec-primary-button-left {\n  align-items: center;\n  justify-content: flex-start;\n}\n\n.ec-primary-button:hover {\n  background-color: #f8f8f8;\n}\n\n.ec-primary-button:disabled,\n.ec-primary-button[disabled] {\n  background-color: #f8f8f8;\n}\n\n\n.ec-primary-button-active {\n  background-color: #f8f8f8;\n  border: none;\n  outline: none;\n  display: flex;\n  align-items: center;\n  margin-top: 5px;\n  margin-left: 10px;\n  margin-right: 10px;\n  width: 221px;\n  height: 45px;\n}\n\n.ec-primary-button:last-child,\n.ec-primary-button-active:last-child {\n  margin-bottom: 5px;\n}\n\n.ec-primary-button-text {\n  font-size: 18px;\n  font-weight: bold;\n  line-height: 1.33;\n  color: #393d52;\n  margin-left: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
