import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  defaultTutorsSurvey,
  MAX_TUTORS, MIN_TUTORS, SPACE_BETWEEN_POSTAL_CODE, TYPE_TUTOR, yupSchema,
} from './definitions';
import AddingTutorToNonAutonomousPatients from './steps/AddingTutorToNonAutonomousPatients';
import DisplayingStepAddingConsent from './steps/DisplayingStepAddingConsent';


const TutorForm = ({
  onCancel,
  onSubmit,
  updateData,
  formField,
  defaultValues,
  medic_id,
  medical_teams_id,
  patientId,
  patient,
  tutorCreateForPatient,
  optionalTutorsOfPatient,
  obligatoryTutorsOfPatient,
}) => {
  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: useMemo(() => defaultValues, [defaultValues]),
    resolver: yupResolver(yupSchema),
    shouldFocusError: true,
  });
  const [formStep, setFormStep] = useState(MIN_TUTORS);
  const [formValues, setFormValues] = useState({});
  const [displayedAdditionOfATutor, setDisplayedAdditionOfATutor] = useState(false);
  const [tutorType, setTutorType] = useState(TYPE_TUTOR.OBLIGATORY);

  const { control, handleSubmit, reset, setValue } = formMethods;

  const processSelectedTutors = useCallback((tutors, filterCallback) =>
    tutors.map(filterCallback).filter(Boolean),
  []);

  useEffect(() => {
    const filterSelectedTutors = (tutor) => (tutor.isSelected ? tutor.value : null);

    setValue('obligatory_tutors_of_patient', processSelectedTutors(obligatoryTutorsOfPatient, filterSelectedTutors));
    setValue('optional_tutors_of_patient', processSelectedTutors(optionalTutorsOfPatient, filterSelectedTutors));
  }, [patient, obligatoryTutorsOfPatient, optionalTutorsOfPatient, processSelectedTutors, setValue]);

  const handleCancel = useCallback((id) => {
    if (onCancel) onCancel(id);
  }, [onCancel]);

  const onSubmits = useCallback((data) => {
    const tutorIds = _.concat(
      _.get(data, 'obligatory_tutors_of_patient', []),
      _.get(data, 'optional_tutors_of_patient', []),
    ).filter(Boolean);

    if (updateData) updateData(formField, tutorIds);
    if (onSubmit) onSubmit(null, tutorIds);
  }, [updateData, formField, onSubmit]);

  const handleAddTutor = useCallback((event, typeTutor) => {
    event.preventDefault();
    setDisplayedAdditionOfATutor(true);
    setTutorType(typeTutor);
  }, []);

  const completeFormStep = useCallback(async (newVal, key) => {
    const newFormValues = _.set(formValues, key, { ...newVal, tutor_status: tutorType });
    setFormValues(newFormValues);

    if (formStep === MAX_TUTORS) {
      setDisplayedAdditionOfATutor(false);
      const tutor = Object.values(newFormValues.tutor).reduce((acc, value) => {
        if (value) {
          return {
            ...acc,
            ...value,
            ...(value.birthplace_postcode && { birthplace_postcode: value.birthplace_postcode.replace(SPACE_BETWEEN_POSTAL_CODE, '') }),
            ...(value.city_postcode && { postcode: value.city_postcode.replace(SPACE_BETWEEN_POSTAL_CODE, '') }),
          };
        }
        return acc;
      }, {});

      await tutorCreateForPatient(patientId, {
        ...tutor,
        is_tutor: true,
        medic_id,
        medical_teams_id,
        patientId,
      });
      reset(defaultTutorsSurvey);
    }

    setFormStep(s => (s < MAX_TUTORS ? s + 1 : MIN_TUTORS));
  }, [formStep, formValues, tutorType, tutorCreateForPatient, patientId, medic_id, medical_teams_id, reset]);

  const previousFormStep = useCallback(() => {
    setFormStep(s => (s > MIN_TUTORS ? s - 1 : MIN_TUTORS));
    setDisplayedAdditionOfATutor(formStep > MIN_TUTORS);
  }, [formStep]);

  // eslint-disable-next-line no-console
  const onErrors = (errors) => console.error('errors :>> ', errors);


  return (
    <div className="p-2">
      {displayedAdditionOfATutor
        ? <DisplayingStepAddingConsent
          previousFormStep={previousFormStep}
          completeFormStep={completeFormStep}
          formStep={formStep}
          formValues={formValues}
          displayedAdditionOfATutor={displayedAdditionOfATutor}
        />
        : <AddingTutorToNonAutonomousPatients
          formMethods={formMethods}
          obligatoryTutorsOfPatient={obligatoryTutorsOfPatient}
          optionalTutorsOfPatient={optionalTutorsOfPatient}
          handleAddTutor={handleAddTutor}
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
          control={control}
          onSubmits={onSubmits}
          onErrors={onErrors}
        />
      }
    </div>
  );
};

TutorForm.propTypes = {
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  updateData: PropTypes.func,
  formField: PropTypes.string,
  defaultValues: PropTypes.object,
  medic_id: PropTypes.string,
  medical_teams_id: PropTypes.string,
  patientId: PropTypes.string,
  patient: PropTypes.object,
  tutorCreateForPatient: PropTypes.func,
};

export default TutorForm;