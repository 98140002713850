import './style.css';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { IconLogout, IconProfil } from '../../../../assets/img/icons';
import { getUserProperty, MEDIC_ROLES } from '../../../../utils';
import useCurrentPath from '../../../../utils/hooks/useCurrentPath';
import { pathAndParamsToUrl } from '../../../../utils/router-helper';
import Dropdown from '../../../components/dropdown';
import DropdownItem from '../../../components/dropdown/DropdownItem';

const AuthLayoutButton = ({
  onLogout,
  user,
  selectedTeam,
  teams,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { path: currentPath, params: currentParams } = useCurrentPath(); // `/members/5566` -> `/members/:id`
  const [username, setUsername] = useState('');
  const [usernameSmall, setUsernameSmall] = useState('');
  const [currentTeamTitle, setCurrentTeamTitle] = useState('');

  useEffect(() => {
    const newSelectedTeam = Array.isArray(teams) && teams.length && teams.find(team => parseInt(team.id, 10) === parseInt(selectedTeam, 10));
    const newTitle = newSelectedTeam?.title || '';
    if (newTitle !== currentTeamTitle) {
      setCurrentTeamTitle(newTitle);
    }
  }, [selectedTeam, teams]);

  useEffect(() => {
    if (user && user.firstname) {
      setUsername(getUserProperty(user, 'fullContactName'));
      setUsernameSmall(`${user.firstname[0]} ${user.lastname[0]}`);
    }
  }, [user]);

  const handleTeamChange = (teamId) => {
    if (currentPath.includes('/:teamId')){
      navigate(`${pathAndParamsToUrl(currentPath, {
        ...currentParams,
        teamId,
      })}${location.search}`);
    } else {
      navigate(`/teams/${teamId}${location.search}`);
    }
  };

  const handleOnLogout = () => {
    onLogout && onLogout();
  };

  const handleClickProfl = () => {
    if (user.id) {
      navigate('/profil');
    }
  };

  return (
    <div className={'authlayout-button-name-info-details-container'}>
      <Dropdown text={username} smallText={usernameSmall}>
        { MEDIC_ROLES.includes(user?.user?.role)
          ? (
            <DropdownItem
              icon={IconProfil}
              text={t('topMenu.dropdown.profil')}
              onClick={handleClickProfl}
            />
          )
          : <></>
        }
        <DropdownItem
          icon={IconLogout}
          text={t('topMenu.dropdown.logout')}
          onClick={handleOnLogout}
        />
      </Dropdown>
      { MEDIC_ROLES.includes(user?.user?.role) && teams && teams.length && (
        <Dropdown
          text={currentTeamTitle}
          smallText={acronym(currentTeamTitle)}
          className="authlayout-second-dropdown-button-name-text-name"
          title={t('topMenu.team.label')}
        >
          { teams.map((team, index) => {
            return (
              <DropdownItem
                key={`dropdown_item_medical_viewer_${index}`}
                icon={IconProfil}
                text={team.title}
                textStyle={'dropdown-item-owner-button-text'}
                smallText={acronym(team.title)}
                onClick={(e) => handleTeamChange(team.id)}
              />
            );
          })}
        </Dropdown>
      )}
    </div>
  );
};

export default AuthLayoutButton;

const acronym = string => ((typeof string === 'string' || string instanceof String) ? string.match(/\b(\w)/g)?.join('') : '');
