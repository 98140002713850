/**
 * GRAPHQL QUERIES
 */

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  clinics: () => `
    query Clinics {
      clinics {
        id
        created_at
        name
        clinic_medics {
          medic {
            firstname
            lastname
            id
          }
        }
      }
    }
  `,
};
