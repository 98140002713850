import PropTypes from 'prop-types';
import React from 'react';

import SearchTag from './SearchTag';

function TagWrapper({ currentTags, deleteTag, saveTag, selectTags }) {
  return (
    <div className="SearchBar__TagWrapper">{
      currentTags.map((tag, i) => (
        <SearchTag
          key={i}
          tagText={tag}
          deleteTag={deleteTag}
          saveTag={saveTag}
          index={i}
          selectTags={selectTags}
        />
      ))}
    </div>
  );

}

TagWrapper.propTypes = {
  currentTags:PropTypes.array,
  deleteTag: PropTypes.func,
  saveTag: PropTypes.func,
  selectTags: PropTypes.object,
};

export default TagWrapper;
