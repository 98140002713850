
import _ from 'lodash';

import { alphaRegex, regexPostcode, regexSecu } from '../../../../../../utils/regex';
import { isNullOrWhitespace } from '../../../../../../utils/utils';
import yup from '../../../../../../utils/yup';

const today = new Date();

export const yupSchema = isVisible => {
  const omitList = Object.keys(_.omitBy(isVisible, el => el === true));
  return yup.object().shape(_.omit({
    gender: yup
      .mixed('patientForms.gender.rules.required')
      .oneOf(['male', 'female'], 'patientForms.gender.rules.required')
      .required('patientForms.gender.rules.required'),
    security_number: yup
      .string()
      // .matches(regexSecu, { message: 'patientForms.security_number.rules.not_valid', excludeEmptyString: true })
      .isValidSecuKey('form.fields.security_number.rules.key_not_valid'),
    // .required('patientForms.security_number.rules.required'),
    lastname: yup
      .string('patientForms.lastname.rules.format')
      .matches(alphaRegex, { message:'patientForms.lastname.rules.format', excludeEmptyString: true })
      .required('patientForms.lastname.rules.required')
      .trim(),
    use_name: yup
      .string()
      .matches(alphaRegex, { message:'patientForms.use_name.rules.format', excludeEmptyString: true })
      .trim(),
    firstname: yup
      .string()
      .matches(alphaRegex, { message:'patientForms.lastname.rules.format', excludeEmptyString: true })
      .required('patientForms.firstname.rules.required')
      .trim(),
    birthdate: yup
      .date()
      .format('dd/MM/yyyy', 'patientForms.birthdate.rules.format')
      .max(today, 'patientForms.birthdate.rules.max')
      .required('patientForms.birthdate.rules.date'),
    birthplace_postcode: yup
      .string()
      .matches(regexPostcode, { message: 'patientForms.birthplace_postcode.rules.format', excludeEmptyString: true })
      .nullable()
      .trim(),
    birthplace_id: yup
      .string()
      .when(['birthplace_postcode', 'birthplace'], (postcode, city, schema) => {
        const formatedPostcode = postcode && postcode.replace(/[_ ]/g, '');
        if (isNullOrWhitespace(city) && formatedPostcode.length) {
          return schema.required('patientForms.birthplace_id.rules.required');
        }else {
          return schema.nullable();
        }
      }),
    birthplace: yup
      .string()
      .nullable()
      .trim(),
  }, omitList))
  ;
};

export const yupSchemaPatient = (isVisible, isClinicalStudy) => {
  const omitList = Object.keys(_.omitBy(isVisible, el => el === true));
  return yup.object().shape(_.omit({
    gender: yup
      .mixed('patientForms.gender.rules.required')
      .oneOf(['male', 'female'], 'patientForms.gender.rules.required')
      .required('patientForms.gender.rules.required'),
    security_number: yup
      .string()
      .matches(regexSecu, { message: 'patientForms.security_number.rules.not_valid', excludeEmptyString: true })
      .isValidSecuKey('form.fields.security_number.rules.key_not_valid')
      .required('patientForms.security_number.rules.required'),
    lastname: yup
      .string('patientForms.lastname.rules.format')
      .matches(alphaRegex, { message:'patientForms.lastname.rules.format', excludeEmptyString: true })
      .required('patientForms.lastname.rules.required'),
    use_name: yup
      .string()
      .matches(alphaRegex, { message:'patientForms.use_name.rules.format', excludeEmptyString: true }),
    firstname: yup
      .string()
      .matches(alphaRegex, { message:'patientForms.lastname.rules.format', excludeEmptyString: true })
      .required('patientForms.firstname.rules.required'),
    birthdate: yup
      .date()
      .format('dd/MM/yyyy', 'patientForms.birthdate.rules.format')
      .max(today, 'patientForms.birthdate.rules.max')
      .required('patientForms.birthdate.rules.date'),
    birthplace_postcode: yup
      .string()
      .matches(regexPostcode, { message: 'patientForms.birthplace_postcode.rules.format', excludeEmptyString: true })
      .nullable(),
    birthplace_id: yup
      .string()
      .when(['birthplace_postcode', 'birthplace'], (postcode, city, schema) => {
        const formatedPostcode = postcode && postcode.replace(/[_ ]/g, '');
        if (isNullOrWhitespace(city) && formatedPostcode.length) {
          return isClinicalStudy ? schema.nullable() : schema.required('patientForms.birthplace_id.rules.required');
        } else {
          return schema.nullable();
        }
      }),
    birthplace: isClinicalStudy
      ? yup
        .string()
        .nullable()
      : yup
        .string()
        .nullable().required('patientForms.birthplace.rules.required'),

  }, omitList)) ;
};
