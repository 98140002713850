import PropTypes from 'prop-types';
import React from 'react';

import { getUserProperty } from '../../../../../../utils';

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};
const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
};

const MedicTeamsOptions = ({ data, innerRef, isDisabled, innerProps }) => {
  const { medics, value, label } = data;
  return !isDisabled
    ? (
      <div {...innerProps} id={value} ref={innerRef} className="mt-2 mx-2" >
        <div className="link bold " style={groupStyles}>
          <span className="">{label}</span>
          <span className="" style={groupBadgeStyles}>{medics && medics.length}</span>
        </div>
        {medics && medics.map(m => <div key={m.id} className="col ml-4" style={{ opacity: '60%' }} >{getUserProperty(m, 'titledName')}</div>)}
      </div>
    )
    : null;
};

MedicTeamsOptions.propTypes = {
  data: PropTypes.shape({
    medics: PropTypes.arrayOf(PropTypes.object),
    value: PropTypes.number,
    label: PropTypes.string,
  }),
  innerRef: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  isDisabled: PropTypes.bool,
  innerProps: PropTypes.object,
};

export default MedicTeamsOptions;
