import { countries } from 'countries-list';
import PropTypes from 'prop-types';
import React from 'react';

import InputSelect from '../InputSelect';
import CountryFlag from './CountryFlag';

const CountrySelect = ({ name, defaultValue }) => {

  const getSelectOptions = Object.keys(countries).map((countryCode) => ({
    value: countryCode,
    label: <CountryFlag key={countryCode} />,
  }));

  const changeTheme = (theme) => ({
    ...theme,
    spacing: {
      baseUnit: 4,
      controlHeight: 60,
      menuGutter: 8,
    },
  });

  return (
    <InputSelect
      options={getSelectOptions}
      name={name}
      changeTheme={changeTheme}
      isClearable={true}
      defaultValue={defaultValue}
    />
  );
};

CountrySelect.propTypes = {
  name: PropTypes.string,
  defaultValue: PropTypes.string,
};

export default CountrySelect;
