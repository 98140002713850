import _ from 'lodash';
import { connect } from 'react-redux';

import { FormsActions } from '../../../../../../redux/forms';
import UsernameStep from './UsernameStep';

const mapStateToProps = ({ forms }, props) => {
  const { id } = props;
  return { defaultValues: _.get(forms.data, id) };
};

const mapDispatchToProps = (dispatch) => ({
  updateData: (key, data) => dispatch(FormsActions.updateData(key, data)),
  omitData: (key) => dispatch(FormsActions.omitData(key)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UsernameStep);
