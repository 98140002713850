import './style.css';

import React from 'react';

const FilterTextCompact = ({ primaryText, secondaryText, active }) => {
  return (
    <>
      <p
        className={
          active ? 'ec-filter-primarytext-compact-activated' : 'ec-filter-primarytext-compact'
        }
      >
        {primaryText}
      </p>
      <p
        className={
          active ? 'ec-filter-secondarytext-compact-activated' : 'ec-filter-secondarytext-compact'
        }
      >
        {secondaryText}
      </p>
    </>
  );
};

export default FilterTextCompact;