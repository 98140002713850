
import _ from 'lodash';
import { connect } from 'react-redux';

import { AuthActions } from '../../../../redux/auth';
import { MedicActions } from '../../../../redux/medic';
import { MEDIC_ROLES } from '../../../../utils';
import AuthLayoutData from './AuthLayoutData';

const mapStateToProps = ({ medic, patient, auth }) => {
  const userRole = auth?.user?.role;
  return {
    user: MEDIC_ROLES.includes(userRole) ? medic.currentMedic : _.get(patient, 'currentPatientList.0'),
    role: userRole,
    selectedTeam: medic.selectedTeam,
    teams: Object.values(medic?.medicalTeamList || {}).map(el => _.pick(el, ['id', 'title', 'isDefault'])),
    isReduxMedicalTeamList: !!medic.medicalTeamList,
  };
};

const mapDispatchToProps = (dispatch) => ({
  logout: (isManualLogout = true) => dispatch(AuthActions.logout( true, null, null, isManualLogout )),
  currentMedicRequest: () => dispatch(MedicActions.currentMedicRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthLayoutData);
