import _ from 'lodash';
import { createReducer } from 'reduxsauce';

import { types } from './actions';

const initialState = {
  list: {},
  loading: false,
};

/**
 *
 * @param state
 * @returns {{list: {}, loading: boolean}}
 */
const startLoader = (state = { ...initialState }) => ({
  ...state,
  loading: true,
});

/**
 *
 * @param state
 * @returns {{list: {}, loading: boolean}}
 */
const stopLoader = (state = { ...initialState }) => ({
  ...state,
  loading: false,
});

/**
 * Save many patients. It will erase old value
 *
 * @param state
 * @param action
 * @returns {{list: {}, loading: boolean}}
 */
const clinicSuccess = (state = { ...initialState }, action = {} ) => {
  const { clinics } = action;
  return {
    ...state,
    list: _.keyBy(clinics, 'id'),
    loading: false, /*, hasMore: patients.length > 0 */
  };
};

const clinicDelete = (state = { ...initialState }) => {
  return {
    ...state,
    list: {},
  };
};

const resetReducer = () => {
  return { ...initialState };
};

/**
 * MAPPING
 */
export default createReducer(initialState, {
  [types.RESET_REDUCER]: resetReducer,

  [types.CLINIC_REQUEST]: startLoader,
  [types.CLINIC_SUCCESS]: clinicSuccess,
  [types.CLINIC_FAILURE]: stopLoader,
  [types.CLINIC_DELETE]: clinicDelete,
});
