const colors = {
  white: '#fff',
  black: '#000',
  blue: '#00d7e7',
  darkBlue: '#00aecb',
  orange: '#e76800',
  darkOrange: '#a14b05',
  red: '#db4040',
  darkRed: '#db2222',
  green: 'green',
  transparent: 'transparent',
};

const backgrounds = {
  primary: `linear-gradient(252deg, ${colors.blue}, ${colors.darkBlue})`,
  warning: `linear-gradient(252deg, ${colors.orange}, ${colors.darkOrange})`,
  danger: `linear-gradient(252deg, ${colors.red}, ${colors.darkRed})`,
};

const backgroundOvers = {
  primary: `linear-gradient(252deg, ${colors.darkBlue}, ${colors.blue})`,
  warning: `linear-gradient(252deg, ${colors.darkOrange}, ${colors.orange})`,
  danger: `linear-gradient(252deg, ${colors.darkRed}, ${colors.red})`,
};

export const theme = {
  buttons: {
    outlinePrimary: {
      color: colors.blue,
      background: colors.transparent,
      borderColor: colors.blue,
      hover: {
        color: colors.white,
        background: backgroundOvers.primary,
      },
    },
    primary: {
      color: colors.white,
      background: backgrounds.primary,
      hover: {
        color: colors.white,
        background: backgroundOvers.primary,
      },
    },
    secondary: {
      color: colors.white,
      background: colors.red,
      hover: {
        color: colors.white,
        background: colors.green,
      },
    },
    ghost: {
      color: '#212529',
      background: colors.transparent,
      hover: {
        color: '#212529',
        background: '#eeeef1',
      },
    },
    outlineDanger: {
      color: colors.red,
      background: colors.transparent,
      borderColor: colors.red,
      hover: {
        color: colors.white,
        background: backgroundOvers.danger,
      },
    },
    danger: {
      color: colors.white,
      background: backgrounds.danger,
      hover: {
        color: colors.black,
        background: backgroundOvers.danger,
      },
    },
    warning: {
      color: colors.white,
      background: backgrounds.warning,
      hover: {
        color: colors.white,
        background: backgroundOvers.warning,
      },
    },
    icon: {
      color: colors.white,
      background: '#1d2a61',
      hover: {
        color: '#00aecb',
        background: '#1d2a61',
      },
    },
  },
  qrCode: {
    primary: { color: '#ed695a' },
    secondary: { color: '#00aecb' },
  },
};