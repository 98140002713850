import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';

import withRouter from '../../../../utils/hooks/withRouter';
import ConsentHeader from './ConsentHeader';

const mapStateToProps = ({ medicTemplate, auth, medic, survey, radius }, props) => {
  const { initialValues, id } = props;
  const currentMedic = medic.currentMedic;
  const visibleMedicSpecialities = currentMedic?.medicSpecialityIds || [];
  const currentForm = isNaN(id) ? null : _.get(medicTemplate, ['list', id]);

  if (currentForm && currentForm.speciality_id && !visibleMedicSpecialities.includes(currentForm.speciality_id)) {
    visibleMedicSpecialities.push(currentForm.speciality_id);
  }
  if (initialValues && initialValues.speciality_id && !visibleMedicSpecialities.includes(initialValues.speciality_id)) {
    visibleMedicSpecialities.push(initialValues.speciality_id);
  }

  const surveyTypesOptions = Object.values(survey.types || {}).map((el) => {
    const translation = _.get(el, ['survey_types_translations', auth.languageId || 1]);
    return {
      value: el.id,
      label: translation?.title || '',
    };
  });
  const specialitiesOptions = Object.values(_.pick(survey.specialities, visibleMedicSpecialities)).map((el) => {
    const translation = _.get(el, ['specialities_translations', auth.languageId || 1]);
    return {
      value: el.id,
      label: translation?.value || '',
    };
  });

  return {
    surveyTypesOptions,
    specialitiesOptions,
    radiusOptions: radius.options,
  };
};

const ConsentHeaderConnect = compose(withRouter, connect(mapStateToProps, null))(ConsentHeader);
export default ConsentHeaderConnect;
