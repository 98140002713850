import '../style.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';

const InputGroupedCheckboxV3 = ({
  name, control,
  options, className, readOnly = false, ...props
}) => {
  const methods = useFormContext();
  const [checkList, setCheckList] = useState();
  const {
    field: { onChange, value, ref, ...field },
    fieldState: { error },
  } = useController({
    name,
    control: control || methods.control,
  });

  useEffect(() => {
    if (!checkList){
      const newCheckList = (options || []).map(label => (value || []).includes(label));
      setCheckList(newCheckList);
      onChange(value);
    }
  }, [value]);

  const handleChange = (event) => {
    if (readOnly) return;
    const { target: { checked, value: v } } = event;
    const index = options.findIndex(el => el === v);
    const newCheckList = [...(checkList|| []).slice(0, index), checked ? v : false, ...(checkList|| []).slice(index +1)];
    setCheckList(newCheckList);
    onChange(newCheckList);
  };

  return (
    <div className='d-flex flex-column'>
      {options.map(({ label, value: optionVal }, index) => {
        return (
          <label
            key={`${name}_${value}_${index}`}
            htmlFor={`${name}_${value}_${index}`}
            className={'checkboxContainer checkbox mb-2'}
          >
            <input
              id={`${name}_${value}_${index}`}
              type="checkbox"
              value={`${optionVal}`}
              checked={(checkList && Array.isArray(checkList) && index < checkList.length && checkList[index]) || false}
              aria-invalid={error ? 'true' : 'false'}
              onChange={handleChange}
              name={field.name}
              disabled={readOnly}
              className={`${className ||''} ${readOnly ? 'noHover' : ''}`}
              {...props}
              ref={ref}
            />
            <span className="label">{label}</span>
          </label>
        );
      })}
    </div>
  );
};

InputGroupedCheckboxV3.propTypes = {
  name: PropTypes.string,
  control: PropTypes.func,
  rules: PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  }),
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.string,
      }),
    ]),
  ),
  containerClassName: PropTypes.string,
  className: PropTypes.string,
};

export default InputGroupedCheckboxV3;
