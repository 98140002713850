import '../../style.css';

import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import yup from '../../../../../../utils/yup';
import { ModalButtonsGroup } from '../../../../../components/formV3';
import { InputText } from '../../../../../components/formV3/controllers';
import { CustomFormController } from '../../../../../components/formV3/innerWrapper';

const UsernameStep = (
  {
    id,
    smsSentQty,
    defaultValues,
    updateData,
    onStepCompletion,
    onCancel,
    omitData,
    onSubmit:onSubmitProps,
  },
) => {
  const { t } = useTranslation();

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onSubmit',
    defaultValues: useMemo(() => {
      return defaultValues;
    }, [defaultValues]),
    resolver: yupResolver(yup.object().shape({ username: yup.string('modalRenewForm.username.rules.username').required('modalRenewForm.username.rules.required') })),
    shouldFocusError: true,
  });
  const { handleSubmit, watch, formState: { isSubmitSuccessful, isValid, isDirty } } = formMethods;

  useEffect(() => {
    // on form submit complete, got to next step
    if (isSubmitSuccessful === true){
      const nextStep = async () => {
        await onStepCompletion();
      };
      nextStep();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful]);

  const onSubmit = async (data) => {
    updateData(id, data);
    onSubmitProps && onSubmitProps(data, id);
  };

  const handleCancel = () => {
    omitData(id);
    if (onCancel) onCancel();
  };

  return (
    <div className={'authentication-forgotten-password-content'}>
      {/* STEP 1 */}
      <p className={`authentication-forgotten-password-text-desc ${smsSentQty > 2 ? 'visible' : 'invisible'}`}>
        {t('forgotten_password_too_many_tries')}
      </p>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div >
            <p className={'authentication-forgotten-password-text-desc'}>
              {t('modalRenewForm.username.description_text')}
            </p>
            <CustomFormController
              name={'username'}
              rules={{ required: true }}
            >
              <InputText placeholder={t('username_placeholder')} />
            </CustomFormController>

            <ModalButtonsGroup
              cancelLabel={t('cancel')}
              submitLabel={t('next')}
              onCancel={handleCancel}
              disabledSubmit={!isDirty || !isValid}
            />
          </div>
        </form>
        {!!process.env.REACT_APP_DEBUG_FORM && (
          <>
            <pre>
              { JSON.stringify(watch(), null, 2) }
            </pre>
            isValid: { isValid }
            isDirty: { isDirty }
          </>
        )}
      </FormProvider>
    </div>
  );
};

export default UsernameStep;
