// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ec-legal-modal-button-text {
  align-self: 'center';
  font-size: 20px;
  letter-spacing: -0.14px;
}

.ec-legal-modal-button {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  min-height: 30px;
}
`, "",{"version":3,"sources":["webpack://./src/ui/components/legalModal/style.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,eAAe;EACf,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;EAChB,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,gBAAgB;AAClB","sourcesContent":[".ec-legal-modal-button-text {\n  align-self: 'center';\n  font-size: 20px;\n  letter-spacing: -0.14px;\n}\n\n.ec-legal-modal-button {\n  margin-top: 20px;\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  min-height: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
