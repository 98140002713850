import './style.css';

import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { IconContext } from 'react-icons';
import { AiOutlineDelete, AiOutlineMenu } from 'react-icons/ai';
import { BsPlus } from 'react-icons/bs';
import { MdUnfoldMore } from 'react-icons/md';

import DraggableItem from './DraggableItem';

const List = ({ list, items, index, onAddItem, onDeleteItem, deletableItems, onItemChange }, ref) => {
  const [unpacked, setUnpacked] = useState(true);
  const [newItem, setNewItem] = useState('');
  const itemsLength = items.length;
  const myRef = useRef(null);

  const onKeyDown = (event) => {
    // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      handleOnAddItem();
    }
  };
  useImperativeHandle(ref, () => ({
    getItem() {
      return newItem;
    },
  }));

  useEffect(() => {
    if (deletableItems) {
      if (itemsLength > 1) {
        myRef.current.focus();
      }
    }
  }, []);

  const handleOnDelete = (id) => {
    if (onDeleteItem) {
      onDeleteItem(id);
    }
  };

  const handleOnAddItem = () => {
    if (onAddItem && newItem !== '') {
      onAddItem(newItem);
      setNewItem('');
      myRef.current.focus();
    } else myRef.current.focus();
  };

  const handleOnItemChange = (e, itemId) => {
    if (onItemChange) {
      onItemChange(e, itemId);
    }
  };

  return (
    <>
      {deletableItems ? (
        <>
          <Droppable droppableId={list.id}>
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef} className="ec-sortable-list-items-container">
                {items.map((item, index) => {
                  return (
                    <div key={`sortable-item-${item.id || index}`}>
                      <DraggableItem
                        item={item}
                        index={index}
                        itemsLength={itemsLength}
                        onDelete={handleOnDelete}
                        deletableItems={deletableItems}
                        onKeyDown={onKeyDown}
                        onChange={handleOnItemChange}
                      />
                    </div>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          <div className="ec-sortable-item-separator" />
          <div className="ec-sortable-item ec-sortable-item-bordered">
            <IconContext.Provider value={{ color: '#00aecb', size: '2em' }}>
              <div className="ec-sortable-list-icons" onClick={handleOnAddItem}>
                <BsPlus />
              </div>
            </IconContext.Provider>
            <input ref={myRef} value={newItem} onChange={(e) => setNewItem(e.target.value)} onKeyDown={onKeyDown} />
            <div className="ec-sortable-item-delete-icon">
              <IconContext.Provider value={{ color: 'red', size: '1.5em' }}>
                <button className="ec-sortable-list-button-icons" onClick={() => setNewItem('')}>
                  <AiOutlineDelete />
                </button>
              </IconContext.Provider>
            </div>
          </div>
        </>
      ) : (
        <Draggable draggableId={list.id} index={index}>
          {(provided, snapshot) => (
            <div ref={provided.innerRef} {...provided.draggableProps} >
              <div className="ec-sortable-list-title-container">
                <div {...provided.dragHandleProps} className="ec-sortable-list-icons">
                  <IconContext.Provider value={{ color: '#00aecb', size: '20px' }}>
                    <div>
                      <AiOutlineMenu />
                    </div>
                  </IconContext.Provider>
                </div>
                <p isDragging={snapshot.isDragging}> {list.title} </p>
                <button className="ec-sortable-list-button-icons" onClick={() => setUnpacked(!unpacked)}>
                  <IconContext.Provider value={{ color: '#00aecb', size: '20px' }}>
                    <div>
                      <MdUnfoldMore />
                    </div>
                  </IconContext.Provider>
                </button>
              </div>
              {unpacked && (
                <div>
                  <Droppable droppableId={list.id}>
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef} className="ec-sortable-list-items-container">
                        {items.map((item, index) => {
                          return (
                            <div key={`sortable-unpacked-item-${item.id}`}>
                              <DraggableItem itemsLength={itemsLength} item={item} index={index} />
                            </div>
                          );
                        })}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>
              )}
            </div>
          )}
        </Draggable>
      )}
    </>
  );
};

export default forwardRef(List);
