import './style.css';

import React from 'react';
import { useTranslation } from 'react-i18next';

const ListHeader= ({ isAutonome, isPatient }) => {
  const { t } = useTranslation();
  return (
    <div className={`py-2 row justify-content-between align-items-center  mt-1 px-md-2 infopatient-historique-header ${isPatient ? ' is-patient' : ''}`}>
      <p className={'col '}>{t('medicPatient.history.list.headers.name')}</p>
      <p className="d-none d-md-block col ">{t('medicPatient.history.list.headers.refNumber')}</p>
      <p className="d-none d-md-block col  justify-content-center d-md-block">{t('medicPatient.history.list.headers.date')}</p>
      {isAutonome === false ? <p className={'col '}>{t('medicPatient.history.list.headers.tutorName')}</p> : null}
      <p className={'col '}>{t('medicPatient.history.list.headers.status')}</p>
      <p className={'col '}>{t('medicPatient.history.list.headers.action')}</p>
    </div>
  );};

export default ListHeader;