import './style.css';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CONTENT_TYPE_MAPPER } from '../../../../utils/enum';
import { ModalButtons } from '../../../components/buttons';
import { FileUploader } from '../../../components/formV3/controllers';
import Modal from '../../../components/modal';
import Attachment from './Attachment';

const AttachmentForm = ({ attachment, onClose, onClearFile }) => {
  const { t } = useTranslation();
  const [file, setFile] = useState();

  useEffect(() => {
    setFile(attachment ? attachment[0] : null);
  }, [attachment]);


  const handleOnClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleOnReset = () => {
    if (onClearFile) {
      onClearFile('attachment');
    }
    setFile(null);
  };

  return (
    <Modal title={t('add_attachment')} onClose={handleOnClose} isOpen scrollable>
      <div className={'paint-button-content'}>
        <FileUploader
          name="attachment"
          buttonClassName={'ec-modal-button-second ec-modal-button-text'}
          buttonTitle={t('choose_file')}
          accept={`${CONTENT_TYPE_MAPPER.IMAGES}, ${CONTENT_TYPE_MAPPER.PDF}`}
        />
        { file && (
          <button className="ec-modal-button-second ec-modal-button-text" onClick={handleOnReset}>
            <p> {t('delete_attachment')} </p>
          </button>
        )}
      </div>
      <div style={{ minHeight: '50vh' }}>
        <Attachment file={file} />
      </div>
      { file && (
        <ModalButtons hideFirst secondText={t('validate')} onSecondClick={handleOnClose} />
      )}
    </Modal>
  );
};

export default AttachmentForm;
