import '../../form/style.css';
import './../style.css';

import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Switch from 'react-switch';
import * as yup from 'yup';

import { filterAndOrderList } from '../../../../utils/format';
import { normalizeString } from '../../../../utils/utils';
import SearchBarForm from '../../../screens/forms/SearchBarForm';
import { ListSelectField } from '../../formV3/controllers';
import InputSelectV2 from '../../formV3/controllers/InputSelectV2';
import { isRequiredField } from '../../formV3/Form/Form';
import { CustomFormController } from '../../formV3/innerWrapper';
import ModalButtonsGroup from '../../formV3/submit/ModalButtonsGroup';
import SmallLoader from '../../loaders/SmallLoader';

const sortTemplate = (templates, key = 'label') =>
  templates.sort((a, b) => {
    const nameA = normalizeString(a[key] || '');
    const nameB = normalizeString(b[key] || '');
    return nameA - nameB;
  });

const MAX_LENGTH = 4;

const SurveyTemplateForm = ({
  id = 'surveyTemplateForm',
  medicTemplateList,
  surveyTypes,
  patientDefaultLanguageId,
  defaultValues,
  updateData,
  onSubmit,
  onCancel,
  isMedicTemplateLoading,
  medicTemplatesGetRequest,
  selectedMedicalTeamId,
}) => {
  const { t } = useTranslation();
  const searchBarRef = useRef(null);

  const [isAutoSubmitOn, setIsAutoSubmitOn] = useState(false);
  const [formTypeOption, setFormTypeOption] = useState([]);
  const [initialTemplateList, setInitialTemplateList] = useState([]);
  const [displayTemplatesList, setDisplayTemplatesList] = useState([]);

  const [checked, setChecked] = useState((defaultValues.template || []).length > 1 ||false);
  const [maxTemplateQty, setMaxTemplateQty] = useState((defaultValues.template || []).length || 1);
  const [searchedTemplatesList, setSearchedTemplatesList] = useState();
  const yupSchema = useMemo(() =>
    yup.object().shape({
      template: yup.array()
        .of(yup.object().shape({
          value: yup.string(),
          label: yup.string(),
          side: yup.mixed().oneOf(['on', 'off', null]),
          radius: yup.mixed().oneOf(['on', 'off', null]),
        }))
        .max(maxTemplateQty || 1, 'surveyTemplateForm.template.rules.required'),
      type_id: yup.string().required('surveyTemplateForm.type_id.rules.required'),
    }), [maxTemplateQty]);

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: useMemo(() => {
      return defaultValues;
    }, [defaultValues]),
    resolver: yupResolver(yupSchema),
    shouldFocusError: true,
  });

  const { control, watch, trigger, handleSubmit, setValue, formState: { isValid, isDirty } } = formMethods;
  const typeIdWatch = useWatch({ control, name: 'type_id' });
  const templateWatch = useWatch({ control, name: 'template' });

  useEffect(() => {
    if (selectedMedicalTeamId) {
      medicTemplatesGetRequest && medicTemplatesGetRequest(selectedMedicalTeamId);
    }
  }, [selectedMedicalTeamId]);

  useEffect(() => {
    if (Array.isArray(formTypeOption) && formTypeOption.length) {
      let newTypeId = formTypeOption[0].value;
      if (!typeIdWatch || typeIdWatch !== newTypeId) {
        setValue('type_id', newTypeId, { shouldValidate: true });
      }
    }
  }, [formTypeOption]);

  useEffect(() => {
    if (searchBarRef && searchBarRef.current) {
      searchBarRef.current.focus();
    }
  }, [searchBarRef]);

  useEffect(() => {
    if (templateWatch && Array.isArray(templateWatch) && templateWatch.length >= maxTemplateQty ) {
      (isAutoSubmitOn || (isValid && isDirty)) && handleSubmit(data => onSubmits(data))();
    }
  }, [templateWatch, handleSubmit]);

  // set select on types from types available for this medic
  useEffect(() => {
    // set type option list
    let newFilteredTypes;
    if (medicTemplateList && Array.isArray(medicTemplateList) && medicTemplateList.length){
      // get types from medicTemplateList
      const medicTypesIdList = _.uniq(medicTemplateList.map(el => el.type_id));
      newFilteredTypes = surveyTypes.filter(s => medicTypesIdList.includes(s.id));
    } else {
      newFilteredTypes = [...surveyTypes];
    }
    newFilteredTypes = newFilteredTypes.filter(el => !!el?.translation?.label).map(el => ({
      value: el.id,
      label: el.translation.label,
    }));
    setFormTypeOption(newFilteredTypes);
    setInitialTemplateList((medicTemplateList || []).map(el => ({
      ...el, // used to get returned value and availableLngs and type_id
      value : el?.id,
      label : el?.displayTranslation?.title,
    }),
    ));
  }, [medicTemplateList, surveyTypes]);

  useEffect(() => {
    if (typeIdWatch && Array.isArray(templateWatch) && Array.isArray(initialTemplateList)){
      const newDisplayTemplatesList = sortTemplate(
        // filter tempalte already selected and filter templates from type
        [...initialTemplateList].filter(t => !templateWatch.includes(t.value) && `${t.type_id}` === `${typeIdWatch}`),
      );
      if (newDisplayTemplatesList.map(el => el.value).join('.') !== displayTemplatesList.map(el => el.value).join('.')){
        setDisplayTemplatesList([...newDisplayTemplatesList]);
      }
    }
  }, [initialTemplateList, templateWatch, typeIdWatch]);

  const handleSearch = (result) => {

    if (result) {
      setSearchedTemplatesList(result.map(el => el.item.value));
    } else {
      setSearchedTemplatesList(null);
    }
  };

  const handleOnSwitchChange = () => {
    const newChecked = !checked;
    if (newChecked === false) {
      setValue('template', templateWatch && templateWatch.slice(0, 1));
      setMaxTemplateQty(1);
    } else {
      setMaxTemplateQty(MAX_LENGTH);
    }
    setChecked(newChecked);
  };

  const onSubmits = (data) => {
    if (updateData) updateData(id, data);
    if (onSubmit) onSubmit(null, data);
  };

  // eslint-disable-next-line no-console
  const onErrors = (errors) => console.error('errors :>> ', errors);

  useEffect(() => {
    formMethods.reset({ ...defaultValues });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  const handleCancel = () => {
    if (onCancel) onCancel(id);
  };

  return (
    <>
      <SearchBarForm
        onSearch={handleSearch}
        placeholder={t('form_card_search_placeholder')}
        list={displayTemplatesList}
        keys={['label']}
        ref={searchBarRef}
      />
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmits, onErrors)} className={'custom-formV2'} >
          <div className={'createPatient-Container-part2'}>
            <CustomFormController
              name={'type_id'}
              label={t('surveyTemplateForm.type_id.label')}
              isRequiredField={isRequiredField(yupSchema, 'type_id')}
            >
              <InputSelectV2 options={formTypeOption} />
            </CustomFormController>
          </div>
          <div className="form-screen-switch-template-container SurveyTemplateForm-switch">
            <span className="form-screen-switch-template-label">
              {t('surveyTemplateForm.switch.multiple')}
            </span>
            <Switch
              className="form-screen-switch-template-switch"
              checked={checked}
              onChange={handleOnSwitchChange}
              offColor="#00AECB"
              offHandleColor="#00D7E7"
              onColor="#00D7E7"
              onHandleColor="#00AECB"
            />
          </div>
          <div className={`${isMedicTemplateLoading ? '' : 'd-none'}`}>
            <SmallLoader />
          </div>
          <div className={`createPatient-Container-part2 ${isMedicTemplateLoading ? 'd-none' : ''}`}>
            <CustomFormController
              name={'template'}
              isRequiredField={isRequiredField(yupSchema, 'template')}
            >
              <ListSelectField
                options={filterAndOrderList(displayTemplatesList, searchedTemplatesList, 'value')}
                keysToReturn={['language_id', 'side', 'radius', 'store_id', 'type_id', 'speciality_id', 'label', 'value']}
                onChange={async () => {
                  await trigger('template');
                  setIsAutoSubmitOn(true);
                }}
                selectDefaultLanguageId={ patientDefaultLanguageId }
                maxItemQty={maxTemplateQty}
              />
            </CustomFormController>
          </div>
          <ModalButtonsGroup onCancel={handleCancel} submitLabel={t('form.button.next.label')} disabledSubmit={!Array.isArray(templateWatch) || !templateWatch.length}/>
        </form>
        {!!process.env.REACT_APP_DEBUG_FORM && (
          <>
            <pre>
              { JSON.stringify(watch(), null, 2) }
            </pre>
          </>
        )}
      </FormProvider>
    </>
  );
};

export default SurveyTemplateForm;
