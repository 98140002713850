import './style.css';

import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Card from '../../components/card';
import List from '../../components/patientDetails/list';
import AuthLayout from '../../layouts/authLayout';
import PatientContactInfo from './PatientContactInfo';
import Tutoredinfo from './Tutoredinfo';

const Patient = ({
  currentPatientList,
  indexCurrentPatient,
  currentPatient,
  generateSurveyPDF,
  generateSurveyDraw,
  isLoadingPatient,
  isLoadingTutor,
  surveyCurrentDelete,
  surveyTypesRequest,
  incrementIndexCurrentPatient,
  decrementIndexCurrentPatient,
  types,
  auth,
  fetchPatientSurveyList,
  surveyList,
  isSurveyListLoading,
  getLoginInfo,
}) => {
  const { t } = useTranslation();
  const [searched, setSearched] = useState(null);

  useEffect(() => {
    if (currentPatientList[indexCurrentPatient]) {
      // fetch patient data (Surveys)
      fetchPatientSurveyList(indexCurrentPatient);
    }
  }, [indexCurrentPatient, currentPatientList]);

  useEffect(() => {
    surveyCurrentDelete();
    surveyTypesRequest();
  }, []);

  const filteredSurveyList = useMemo(() => {
    if (searched) return surveyList.filter((s) => searched.includes(s.intervention.id));
    return surveyList;
  }, [surveyList, searched]);

  const onSearch = (filters) => {
    if (!filters) setSearched(null);
    else setSearched(filters.map((f) => f.id));
  };

  const handleOnSurveyClick = ({ action, survey }) => {
    switch (action) {
      case 'build_draw':
        generateSurveyDraw(survey);
        break;
      case 'build_attachment':
        generateSurveyDraw({ ...survey, survey_drawing: survey.survey_attachment });
        break;
      case 'build_pdf':
        generateSurveyPDF(survey);
        break;
      default:
        break;
    }
  };

  return (
    <AuthLayout>
      <div className={'infopatient-main-container'}>
        <div className={'patient-info'}>
          <div className={'patient-contact'}>
            <PatientContactInfo
              patient={currentPatient}
              isLoading={isLoadingPatient}
            />
            <Tutoredinfo
              currentPatientList={currentPatientList}
              indexCurrentPatient={indexCurrentPatient}
              incrementIndexCurrentPatient={incrementIndexCurrentPatient}
              decrementIndexCurrentPatient={decrementIndexCurrentPatient}
              isLoading={isLoadingTutor}
            />
          </div>
        </div>
        <Card>
          <div className={'patient-contact-historique'}>
            <div className={'infopatient-segmented-control'}>
              <div className={'infopatient-interventions-header'}>
                <p className={'infopatient-text-interventions-name '}>
                  {t('patient_screen.interventionsList.title')}
                </p>
              </div>
            </div>
            <List
              surveys={filteredSurveyList}
              isPatient
              onClick={handleOnSurveyClick}
              isLoading={isSurveyListLoading}
              onSearch={onSearch}
              types={types}
              auth={auth}
            />
          </div>
        </Card>
      </div>
    </AuthLayout>
  );
};

export default Patient;
