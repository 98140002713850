import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { getUserProperty } from '../../../../utils';
import DOMPurify from '../../../../utils/dompurify';
import { InputGroupedRadio } from '../../formV3/controllers';
import { isRequiredField } from '../../formV3/Form/Form';
import { CustomFormController } from '../../formV3/innerWrapper';
import ModalButtonsGroup from '../../formV3/submit/ModalButtonsGroup';


const DateWarning = ({ id = 'dateWarning', defaultValues, onSubmit, updateData, omitData, patient, date_consult, theorical_date_acte, daysToAct, onCancel, isLast = false }) => {
  const { t } = useTranslation();
  const answers = ['first', 'second', 'third'].map(key => ({ key: t(`surveyPatientForm.date_warning.options.${key}`), value: t(`surveyPatientForm.date_warning.options.${key}`) }));

  const yupSchema = yup.object().shape({ answer: yup.mixed().oneOf( answers.map( el => el.key)) });

  const formMethods = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: useMemo(() => {
      return defaultValues;
    }, [defaultValues]),
    resolver: yupResolver(yupSchema),
    shouldFocusError: true,
  });
  const { handleSubmit, watch, trigger } = formMethods;

  const onSubmits = (data) => {
    trigger();

    if (updateData) updateData(id, data);
    if (onSubmit) onSubmit(null, data);
  };

  // eslint-disable-next-line no-console
  const onErrors = (errors) => console.error('errors :>> ', errors);

  useEffect(() => {
    formMethods.reset({ ...defaultValues });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  const handleCancel = (id) => {
    if (onCancel) onCancel(id);
  };

  return (
    <>
      <div
        className='mb-4'
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(
            t('surveyPatientForm.date_warning.desc', {
              daysToAct: daysToAct,
              date_consult: date_consult,
              theorical_date_acte: theorical_date_acte,
              contactNameWithTitle: getUserProperty(patient, 'contactNameWithTitle'),
            }),
          ),
        }}
      />

      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmits, onErrors)} className={'custom-formV2'} >
          <CustomFormController
            name={'answer'}
            isRequiredField={isRequiredField(yupSchema, 'answer')}
          >
            <InputGroupedRadio
              options={answers}
              containerClassName="input-radio-group"
              className="input-radio-value"
              isVertical
            />
          </CustomFormController>
          <ModalButtonsGroup onCancel={handleCancel} submitLabel={isLast ? t('form.button.send.label') : t('form.button.next.label')} />
        </form>
        {!!process.env.REACT_APP_DEBUG_FORM && (
          <pre>
            { JSON.stringify(watch(), null, 2) }
          </pre>
        )}
      </FormProvider>
    </>
  );
};

export default DateWarning;
