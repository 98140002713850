import _ from 'lodash';

import i18n from '../../../../services/i18n/i18n';
import { QUESTION_TYPES } from '../../../../utils/enum';
import { shortidGenerate } from '../../../../utils/utils';

export const INITIAL_STEP = (number = 1) => ({
  isNew: true,
  number,
  translation: {
    id: '',
    language_id: '',
    title: i18n.t('default_form_data.step.title'),
  },
  questions: [],
});

export const INITIAL_QUESTION = (type, number = 1, stepId = 0) => {
  let question;
  let newData = {};
  switch (type.value) {
    case QUESTION_TYPES.multiple_choice:
      question = i18n.t('default_form_data.questions.multiple_choice.question');
      newData = {
        list: [{
          value: shortidGenerate(),
          label: i18n.t('default_form_data.questions.multiple_choice.list.firstChoice'),
        }],
      };
      break;
    case QUESTION_TYPES.text_answer:
      question = i18n.t('default_form_data.questions.text_answer.question');
      newData = { answer: '' };
      break;
    case QUESTION_TYPES.dropdown_list:
      question = i18n.t('default_form_data.questions.dropdown_list.question');
      newData = {
        list: [{
          value: shortidGenerate(),
          label: i18n.t('default_form_data.questions.dropdown_list.list.firstChoice'),
        }],
      };
      break;
    case QUESTION_TYPES.pdf:
      question = i18n.t('default_form_data.questions.pdf.question');
      newData = { pdf: null };
      break;
    case QUESTION_TYPES.checkbox_full:
      question = i18n.t('default_form_data.questions.checkbox_full.question');
      newData = {
        list: [
          { value: shortidGenerate(), label: i18n.t('default_form_data.questions.checkbox_full.list.firstChoice') }],
      };
      break;
    case QUESTION_TYPES.radio_multi_questions_to_table:
      question = i18n.t('default_form_data.questions.radio_multi_questions_to_table.question');
      newData = {
        list: [
          { key: shortidGenerate(), value: i18n.t('default_form_data.questions.radio_multi_questions_to_table.list.yes') },
          { key: shortidGenerate(), value: i18n.t('default_form_data.questions.radio_multi_questions_to_table.list.no') },
          { key: shortidGenerate(), value: i18n.t('default_form_data.questions.radio_multi_questions_to_table.list.notApplicable') },
          { key: shortidGenerate(), value: i18n.t('default_form_data.questions.radio_multi_questions_to_table.list.notConcerned') }],
      };
      break;
    case QUESTION_TYPES.validation:
    default:
      question = i18n.t('default_form_data.questions.validation.question');
      newData = {
        first: i18n.t('default_form_data.questions.validation.data.first'),
        second: i18n.t('default_form_data.questions.validation.data.second'),
      };
      break;
  }
  const returnedQuestion = {
    isNew: true,
    translation: {
      question,
      comment: '',
      data: newData,
      desc: '',
      rewording: '',
      texte_pdf: '',
      incompris: '',
    },
    asked_more_info: null,
    step_id: stepId,
    type: type.value || '',
    type_id: type.id || '',
    number: number,
  };
  _.set(returnedQuestion, 'translation.data', newData);
  return returnedQuestion;
};

export const INITIAL_SURVEY = {
  is_published: false,
  translation: {
    title: i18n.t('default_form_data.title'),
    description: [],
    consent_pdf_template: 'default',
  },
  type_id: '',
  speciality_id: '',
  isRadius: false,
  isSide: false,
  steps: [INITIAL_STEP(1)],
};

export const INITIAL_SURVEY_TEST = {
  is_published: false,
  title: 'titre',
  type: null,
  speciality: null,
  radius: '',
  side: '',
  isRadius: false,
  isSide: false,
  description: [],
  steps: [
    {
      isNew: true,
      title: "Titre de l'étape 1",
      comment: '',
      number: 1,
      survey_id: 0,
      questions: [
        {
          isNew: true,
          question: 'Votre Question 1',
          data: { first: "Oui, j'ai compris", second: "Non, je désire de l'information supplémentaire" },
          asked_more_info: null,
          step_id: 0,
          type: 'validation',
          type_id: 0,
          number: 1,
          desc: 'desc',
          rewording: 'rewording',
          texte_pdf: '',
          incompris: '',
        },
        {
          isNew: true,
          question: 'Votre Question 2',
          data: { first: "Oui, j'ai compris", second: "Non, je désire de l'information supplémentaire" },
          asked_more_info: null,
          step_id: 0,
          type: 'validation',
          type_id: 0,
          number: 2,
          desc: 'desc',
          rewording: 'rewording',
          texte_pdf: '',
          incompris: '',
        },
      ],
    },
    {
      isNew: true,
      title: "Titre de l'étape 2",
      number: 2,
      survey_id: 0,
      questions: [
        {
          isNew: true,
          question: 'Votre Question 3',
          data: { first: "Oui, j'ai compris", second: "Non, je désire de l'information supplémentaire" },
          asked_more_info: null,
          step_id: 0,
          type: 'validation',
          type_id: 0,
          number: 3,
          desc: 'desc',
          rewording: 'rewording',
          texte_pdf: '',
          incompris: '',
        },
      ],
    },
  ],
};
