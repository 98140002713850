// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
#hideHeightOver800.ec-tooltip {
  visibility: visible !important;
}

/* when the browser window is 800px height or more:  */
@media (min-height: 801px) {
  #hideHeightOver800.ec-tooltip {
    visibility: hidden !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/ui/components/tooltip/style.css"],"names":[],"mappings":";AACA;EACE,8BAA8B;AAChC;;AAEA,sDAAsD;AACtD;EACE;IACE,6BAA6B;EAC/B;AACF","sourcesContent":["\n#hideHeightOver800.ec-tooltip {\n  visibility: visible !important;\n}\n\n/* when the browser window is 800px height or more:  */\n@media (min-height: 801px) {\n  #hideHeightOver800.ec-tooltip {\n    visibility: hidden !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
