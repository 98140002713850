import React from 'react';

import { CONTENT_TYPE_MAPPER } from '../../../../utils/enum';
import PDFViewer from '../../../components/PDFViewer';

const Attachment = ({ ext, file }) => {
  const returnElement = () => {
    if (!file) return null;
    if (ext) {
      switch(ext) {
        case 'png':
        case 'jpeg':
        case 'jpg':
          return <img className="ec-attachment-image" src={URL.createObjectURL(file)} alt="attachment" />;
        case 'pdf':
          return <PDFViewer documentData={{ file: file }} customNavigation />;
        default:
          return null;
      }
    } else if (file.type){
      switch(file.type) {
        case CONTENT_TYPE_MAPPER.PNG:
        case CONTENT_TYPE_MAPPER.JPEG:
        case CONTENT_TYPE_MAPPER.JPG:
          return <img className="ec-attachment-image" src={URL.createObjectURL(file)} alt="attachment" />;
        case CONTENT_TYPE_MAPPER.PDF:
          return <PDFViewer documentData={{ file: file }} customNavigation />;
        default:
          return null;
      }
    } else {
      return null;
    }
  };

  return returnElement();
};

export default Attachment;
