import '../../style.css';

import { yupResolver } from '@hookform/resolvers/yup';
import { toDate } from 'date-fns';
import React, { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { parseDate } from '../../../../../../services/datefns';
import yup from '../../../../../../utils/yup';
import { ModalButtonsGroup } from '../../../../../components/formV3';
import { DatePicker, InputText, PhoneInput } from '../../../../../components/formV3/controllers';
import { CustomFormController } from '../../../../../components/formV3/innerWrapper';

const EmailStep = ({
  id,
  smsSentQty,
  defaultValues,
  updateData,
  onStepCompletion,
  onCancel,
  omitData,
  onSubmit:onSubmitProps,
  isPro,
}) => {
  const { t } = useTranslation();
  const yupSchema = yup.object().shape({
    email: yup.string().email('modalRenewForm.email.rules.email').required('modalRenewForm.email.rules.required'),
    birthdate: yup
      .date()
      .format('dd/MM/yyyy', 'patientForms.birthdate.rules.format')
      .max(new Date(), 'patientForms.birthdate.rules.max')
      .required('patientForms.birthdate.rules.date'),
    phone_number: yup
      .string()
      .required('patientForms.phone_number.rules.required')
      .validMobile({ message: 'medicForm.phone_number.rules.format', excludeEmptyString: true }),
  });

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: useMemo(() => {
      return defaultValues;
    }, [defaultValues]),
    resolver: yupResolver(yupSchema),
    shouldFocusError: true,
  });
  const { handleSubmit, watch, formState: { isSubmitSuccessful, isDirty, isValid } } = formMethods;

  useEffect(() => {
    // on form submit complete, got to next step
    if (isSubmitSuccessful === true){
      const nextStep = async () => {
        await onStepCompletion();
      };
      nextStep();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful]);

  const onSubmit = async (data) => {
    const formatedData = {
      ...data,
      birthdate: data.birthdate && data.birthdate !== '' ? toDate(parseDate(data.birthdate)).toISOString() : null,
    };
    updateData(id, formatedData );
    onSubmitProps && onSubmitProps(formatedData, id);
  };

  const handleCancel = () => {
    omitData(id);
    if (onCancel) onCancel();
  };
  // eslint-disable-next-line no-console
  const onError = (error) => console.log('error', error);

  return (
    <div className={'authentication-forgotten-password-content'}>
      {/* STEP 1 */}
      <p className={`m-0 authentication-forgotten-password-text-desc ${smsSentQty > 2 ? 'visible' : 'invisible'}`}>
        {t('forgotten_password_too_many_tries')}
      </p>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <div>
            <p className={'authentication-forgotten-password-text-desc'}>
              {isPro
                ? t('modalRenewForm.email.description_text_pro')
                : t('modalRenewForm.email.description_text')
              }
            </p>
            <CustomFormController
              name={'email'}
              rules={{ required: true }}
            >
              <InputText
                inputPreset={'email'}
                placeholder={t('email_placeholder')}
              />
            </CustomFormController>
            <CustomFormController
              name={'phone_number'}
              label={t('patientForms.phone_number.label')}
              isRequiredField={true}
            >
              <PhoneInput />
            </CustomFormController>
            <CustomFormController
              name={'birthdate'}
              label={t('patientForms.birthdate.label')}
              isRequiredField={true}
            >
              <DatePicker inputPreset={'birthdate'} />
            </CustomFormController>

            <ModalButtonsGroup
              cancelLabel={t('cancel')}
              submitLabel={t('next')}
              onCancel={handleCancel}
            />
          </div>
        </form>
        {!!process.env.REACT_APP_DEBUG_FORM && (
          <>
            <pre>
              { JSON.stringify(watch(), null, 2) }
            </pre>
            <p>
              isValid: { isValid }
            </p>
            <p>
              isDirty: { isDirty }
            </p>
          </>
        )}
      </FormProvider>
    </div>
  );
};

export default EmailStep;
