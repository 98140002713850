import './style.css';

import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router';

import { MEDIC_ROLES } from '../../../../utils';
import ToolTip from '../../../components/tooltip';
import AuthLayoutButton from './AuthLayoutButton';

const AuthLayoutData = ({
  currentMedicRequest,
  logout,
  user,
  role,
  selectedTeam,
  teams,
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (MEDIC_ROLES.includes(role)) {
      currentMedicRequest();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role]);
  const teamIds = useMemo(() => Array.isArray(teams) && teams.map(el => el.id), [teams]);

  useEffect(() => {
    if (Array.isArray(teamIds) && teamIds.length && selectedTeam && !teamIds.includes(selectedTeam)) {
      // if the selected team is not in the scope => redirect to scope team
      let team = teams.find(el => el.isDefault);
      if (!team) {
        team = teams[0];
      }
      if (team?.id) navigate(`/teams/${team.id}`);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamIds, selectedTeam]);

  useEffect(() => {
    // On load, hide zoho floating icon
    const interval = setInterval(() => {
      var elementsArray = document.getElementsByClassName('zsiq_floatmain');
      if (elementsArray && elementsArray.length) {
        elementsArray[0].classList.add('display-none');
        clearInterval(interval);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
      var elementsArray = document.getElementsByClassName('zsiq_floatmain');
      elementsArray && elementsArray.length && elementsArray[0].classList.remove('display-none');
    };
  }, []);

  const handleLogout = () => {
    logout();
  };

  return (
    <div className="authlayout-data-big-container">
      <ToolTip />
      <div className={'authlayout-data-container'}>
        <AuthLayoutButton
          user={user}
          onLogout={handleLogout}
          selectedTeam={selectedTeam}
          teams={teams}
        />
      </div>
    </div>
  );
};

export default AuthLayoutData;
