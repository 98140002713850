export { default as ErrorBoundary } from './ErrorBoundary';
export { default as Auth } from './authentication/Auth';
export { default as Medic } from './medic';
export { default as Accueil } from './Accueil';
export { default as Forms } from './forms';
export { default as InfoPatient } from './infoPatient';
export { default as Consent } from './ConsentV2';
export { default as Profil } from './profil';
export { default as ConsentPreview } from './ConsentPreviewV2';
export { default as Patient } from './patient';
export { default as Splash } from './splash';
export { default as SurveysCreate } from './createFormV2/SurveysCreate';
export { default as NoMatch } from './NoMatch';
export { default as ConsentRecap } from './ConsentV2/ConsentRecap';
export { default as AttachUser } from './AttachUser';
