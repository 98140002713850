import { format } from 'date-fns';
import _ from 'lodash';

import { methods } from '../utils';
import { patientMutations, patientQueries } from './GraphQLRequest';
import { authenticatedMutation, authenticatedQuery, authenticatedService } from './middleware';

const { GET, POST, PUT, PATCH } = methods;

const PROXY_API_URL = process.env.REACT_APP_PROXY_API || process.env.REACT_APP_BASE_API_URL || '/api';

/**
 * REST ROUTES
 */
const routes = {
  createPatientsUsers: `${PROXY_API_URL}/V2/patients`,
  generateConsentCode: (id) => `${PROXY_API_URL}/V2/surveys/${id}/code`,
  resumeSurvey: (id) => `${PROXY_API_URL}/V2/surveys/${id}/resume`,
  patientDetails: (medicalTeamId, patientId) => `${PROXY_API_URL}/V2/medicalTeams/${medicalTeamId}/patients/${patientId}`,
  getPatientDetail: (patientId) => `${PROXY_API_URL}/V2/patients/${patientId}`,
  updateInterventionInfo: (interventionNumber) => `${PROXY_API_URL}/V2/interventions/${interventionNumber}/date`,
  patientArchiveRequest: (patientId) => `${PROXY_API_URL}/V2/patients/${patientId}/archive`,
  tutorCreateForPatient: (patientId) => `${PROXY_API_URL}/V2/patients/${patientId}/tutor`,
};

/*
 * SERVICES
 */
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  linkTutors: (links) => authenticatedMutation(patientMutations.linkTutors(links)),
  patient: (id) => authenticatedQuery(patientQueries.patient, {}, { id }),
  patientsByIds: (ids) => authenticatedQuery(patientQueries.patientsByIds, {}, {
    ids: Array.isArray(ids) ? ids : [ids],
    _gte: format(new Date(), 'yyyy-MM-dd'),
  }),
  patients: () => authenticatedQuery(patientQueries.patients, {}, { _gte: format(new Date(), 'yyyy-MM-dd') }),
  create: (payload) => authenticatedService(POST, routes.createPatientsUsers, { payload }, {}, {}),
  patchPatient: (patientId, patient) => authenticatedMutation(patientMutations.patchPatient(), {}, { patientId, patientData: _.omit(patient, ['id']) }),
  updateCGU: (id) => authenticatedMutation(patientMutations.updateCGU(id)),
  updateRGPD: (id) => authenticatedMutation(patientMutations.updateRGPD(id)),
  updateEditInfo: (id) => authenticatedMutation(patientMutations.updateEditInfo(), {}, { id }),
  delete: (id) => authenticatedMutation(patientMutations.delete(id)),
  resumeSurvey: (surveyID) => authenticatedService(POST, routes.resumeSurvey(surveyID)),
  patientFromUserId: (userId) => authenticatedQuery(patientQueries.patientFromUserId, {}, { userId }),
  current: () => authenticatedQuery(patientQueries.current),
  tutored: () => authenticatedQuery(patientQueries.tutored),
  generateSignatureCode: (surveyId) => authenticatedService(POST, routes.generateConsentCode(surveyId), {}, { }, {}),
  updateInterventionInfo: (interventionNumber, date, dateWarning = null) => authenticatedService(PUT, routes.updateInterventionInfo(interventionNumber), { date, dateWarning }),
  patientDetails: (medicalTeamId, patientId) => authenticatedService(GET, routes.patientDetails(medicalTeamId, patientId)),
  getPatientDetail: (patientId) => authenticatedService(GET, routes.getPatientDetail(patientId)),
  patientArchiveRequest: (patientId, selectedTeam) => authenticatedService(PATCH, routes.patientArchiveRequest(patientId), { selectedTeam }),
  tutorCreateForPatient: (patientId, payload) => authenticatedService(POST, routes.tutorCreateForPatient(patientId), { payload }),
};
