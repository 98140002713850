// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_title__NwACq {
  height: 60px;
  font-weight: bold;
  font-size: 30px;
  border: none;
  margin-bottom: 16px;
}
.style_title__NwACq:focus {
  box-shadow: 0 0 1px #00aecb;
}

.style_side__xp4Y0, .style_radius__pnacY {
  color: #79828b;
  font-size: 18px;
}
`, "",{"version":3,"sources":["webpack://./src/ui/screens/createFormV2/ConsentHeader/style.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,iBAAiB;EACjB,eAAe;EACf,YAAY;EACZ,mBAAmB;AACrB;AACA;EACE,2BAA2B;AAC7B;;AAEA;EACE,cAAc;EACd,eAAe;AACjB","sourcesContent":[".title {\n  height: 60px;\n  font-weight: bold;\n  font-size: 30px;\n  border: none;\n  margin-bottom: 16px;\n}\n.title:focus {\n  box-shadow: 0 0 1px #00aecb;\n}\n\n.side, .radius {\n  color: #79828b;\n  font-size: 18px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `style_title__NwACq`,
	"side": `style_side__xp4Y0`,
	"radius": `style_radius__pnacY`
};
export default ___CSS_LOADER_EXPORT___;
