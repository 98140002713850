/**
 * GRAPHQL MUTATIONS
 */

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  createTemplate: (template) => `
    mutation {
      insert_surveys(
        objects: ${JSON.stringify(template).replace(/"(\w+)"\s*:/g, '$1:')}) {
        returning {
          id
        }
      }
    }`,
  createLinkSurveyPatient: (links) => `
    mutation {
      insert_surveys(objects: ${JSON.stringify(links).replace(/"(\w+)"\s*:/g, '$1:')}) {
        returning {
          id
          patient_id
          template_id
          tutored_id
        }
      }
    }
  `,
  createSurveyAttachments: () => `
    mutation insertSurveyAttachment($data: [survey_attachments_insert_input!]!) {
      insert_survey_attachments(objects: $data){
        returning {
            id
          }
      }
    }
  `,
  createSurveyDrawings: () => `
    mutation insertSurveyDrawings($data: [survey_drawings_insert_input!]!) {
      insert_survey_drawings(objects: $data) {
        returning {
          id
        }
      }
    }
  `,
  currentUpdate: (id, survey) => `
    mutation {
      insert_questions(
        objects: ${JSON.stringify(survey).replace(/"(\w+)"\s*:/g, '$1:')},
        on_conflict: {
          constraint: questions_pkey,
          update_columns: [data],
          where: {
            step_id: {_eq: ${id}}
          }
        }
      ) {
        returning {
          id
          data
          question
          type_id
          step_id
        }
      }
    }
  `,
  consentUpdate: () => `
    mutation ConsentUpdate($id: Int!, $consent: surveys_set_input!) {
      update_surveys(
        where: { id: { _eq :$id } }, 
        _set: $consent
      ) {
        returning {
          id
        }
      }
    }
  `,
  archivedSome: () => `
    mutation DeleteSomeSurveys($_ids: [Int!]!, $_archived_date: timestamptz!) {
      update_surveys(
        where: {id: {_in: $_ids}}, 
        _set: {
          is_archived: true, 
          archived_date: $_archived_date
        }
      ) {
        returning {
          id
          is_archived
          archived_date
        }
      }
    }
  `,
  updateUpdateDate: () => `
    mutation UpdateUpdateDate($id: Int!) {
      update_surveys(where: {id: {_eq: $id}}, _set: {updated_at: "now()"}) {
        returning {
          id
        }
      }
    }
  `,
};
