import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { SURVEY_STATUS } from '../../../../../utils';
import { ModalButtons } from '../../../../components/buttons';
import RichTextWithDraft from '../../../../components/formV3/controllers/RichTextWithDraft';
import { CustomFormController } from '../../../../components/formV3/innerWrapper';
import Modal from '../../../../components/modal';
import { reformatString } from '../../../ConsentV2/ConsentRecap/SummaryModal';
import { arrayToFormName } from '..';
import ConsentRewording from './ConsentRewording';

const Rewording = ({ question, prefixName, disabled, onExit, isOpen, isConsent, readOnly, onRejectSurvey }) => {
  const { t } = useTranslation();
  const { consentId } = useParams();
  const [isConfirmCancelModalOpen, setIsConfirmCancelModalOpen] = useState(false);
  const [skipStepOne, setSkipStepOne] = useState(false);

  useEffect(() => {
    if (isOpen && isConsent && (!question || !question?.translation?.rewording || question?.translation?.rewording === '')){
      handleMisunderstood();
      setSkipStepOne(true);
    }
  }, [question, isOpen]);

  const handleOnExit = (event) => {
    if (onExit) {
      onExit(event);
    }
  };

  const handleCloseModal = () => {
    setIsConfirmCancelModalOpen(false);
    onExit && onExit();
  };

  const handlePrevious = () => {
    if (skipStepOne) {
      handleCloseModal();
    } else {
      setIsConfirmCancelModalOpen(false);
    }
  };

  const handleMisunderstood = () => {
    setIsConfirmCancelModalOpen(true);
  };

  const handleRejectSurvey = () => {
    const message = (question?.translation?.incompris || '').length
      ? reformatString(question?.translation?.incompris)
      : t('ConsentRender.rejection_message.question', { question : question?.translation?.question });
    onRejectSurvey(consentId, {
      is_signed: true,
      status: SURVEY_STATUS.REFUSED,
      error_message: message,
    });
    handleCloseModal();
  };

  return (
    <Modal
      title={isConfirmCancelModalOpen ? null : t('rewording_card_title')}
      hideCloseBtn={!!isConfirmCancelModalOpen}
      onClose={handleCloseModal}
      scrollable
      isOpen={isOpen}
      alwaysDisplayHtml
    >
      {
        isConsent
          ? (
            <ConsentRewording
              isConfirmMessage={isConfirmCancelModalOpen}
              question={question}
              onMisunderstood={handleMisunderstood}
              onCloseModal={handleCloseModal}
              onRejectSurvey={handleRejectSurvey}
              onPrevious={handlePrevious}
              readOnly={readOnly}
            />
          ) : (
            <>
              <CustomFormController name={arrayToFormName([prefixName, 'translation', 'rewording'])}>
                <RichTextWithDraft
                  editableOnClick={false}
                  readOnly={false}
                  disabled={disabled}
                />
              </CustomFormController>
              <ModalButtons
                htmlType="button"
                firstText={t('cancel')}
                secondText={t('validate')}
                onFirstClick={handleOnExit}
                onSecondClick={handleOnExit}
              />
            </>
          )
      }
    </Modal>
  );
};

export default Rewording;
