import '../style.scss';

import PropTypes from 'prop-types';
import React from 'react';
import { useFormContext } from 'react-hook-form';

function InputRadio({ control, name, defaultValue, value, label, readOnly = false, ...rest }) {
  const methods = useFormContext();
  const { register } = methods;
  const params = readOnly ? { readOnly: true } : {};
  return (
    <label
      htmlFor={`${name}_${value}`}
      className={`radioBtnContainer my-1 d-flex align-items-center ${readOnly ? 'noHover' : ''}`}
    >
      <input
        type="radio"
        id={`${name}_${value}`}
        value={value}
        {...params}
        {...register(name)}
        {...rest}
      />
      <span className="checkmark" />
      <span className="label">{label}</span>
    </label>
  );
}

InputRadio.propTypes = {
  register: PropTypes.func,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
};

export default InputRadio;
