import 'rc-checkbox/assets/index.css';
import './style.css';

import RcCheckbox from 'rc-checkbox';
import React, { useEffect, useState } from 'react';

const Checkbox = ({ className, value, name, onChange, label, disabled, multiple = false, index = -1 }) => {
  const [isSelected, setIsSelected] = useState();

  useEffect(() => {
    if (typeof value === 'boolean'){
      setIsSelected(value);
    } else {
      if (value === 'on') {
        setIsSelected(true);
      } else if (value === 'off') {
        setIsSelected(false);
      }
    }
  }, [value]);

  const handleOnChange = (e) => {
    const { target: { checked, name } } = e;
    setIsSelected(checked);
    if (onChange) {
      onChange({ target: { name: name, value: checked, index: index } });
    }
  };

  return (multiple
    ? <button
      type='button'
      onClick={() => handleOnChange({ target: { name, checked: !isSelected } } )}
      className="ec-checkbox-multi-container"
    >
      <RcCheckbox
        /*onChange={handleOnChange}*/
        checked={isSelected || value === 'on'}
        name={name}
        disabled={disabled}
        className="rc-checkbox-inner-container"
      />
      <textarea
        className="ec-checkbox-multiple-text-area"
        defaultValue={label}
        readOnly
        rows={4}
      >
      </textarea>
    </button>
    : (
      <label className={`${className} ${disabled && isSelected === true ? 'bold' : ''} ${disabled ? 'noHover' : ''}`}>
        <RcCheckbox
          onChange={handleOnChange}
          checked={isSelected || value === 'on'}
          name={name}
          disabled={disabled}
          className="rc-checkbox-inner-container"
        />
        <span className="mx-2">{label}</span>
      </label>
    ));
};

export default Checkbox;
