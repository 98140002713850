import React, { useEffect, useState } from 'react';

import CustomSelect from '../customSelect/customSelect';

const InputSelect = ({
  labelClassName,
  showListOnMount,
  placeholder,
  validate,
  value,
  edit,
  name,
  className,
  onChange,
  disabled,
  style,
  onReorder,
  noContainer,
}) => {
  const [values, setValues] = useState(placeholder);
  const data = (value || []).map((v) => {
    return { id: v.type.id, name: v.type.value, isSelected: v.isSelected };
  });

  useEffect(() => {
    if (value && value[0]) {
      const filtered = value.filter((v) => v.isSelected);
      if (filtered && filtered[0]) setValues(filtered[0].type.value);
    }
  }, [value && value[0]]);

  const handleOnChange = (e) => {
    if (disabled && onReorder) onReorder();
    const newValues = e.name;
    let tmp = value;
    const index = tmp.findIndex((item) => item.type.value === newValues);
    for (let i = 0, size = tmp.length; i < size; i += 1)
      i !== index ? (tmp[i].isSelected = false) : (tmp[i].isSelected = true);
    const target = { name: name, value: tmp };

    if (onChange) onChange({ target });
    if (validate) validate(tmp);
    setValues(newValues);
  };
  return (
    <div className={'datapicker-input'}>
      <CustomSelect
        noContainer={noContainer}
        disabled={disabled}
        defaultText={values}
        optionsList={data}
        style={style}
        onChange={(o) => handleOnChange(o)}
        showListOnMount={showListOnMount}
      />
    </div>
  );
};

export default InputSelect;
