import _ from 'lodash';
import { connect } from 'react-redux';

import { FormsActions } from '../../../../redux/forms';
import SupInfoForm from './SupInfoForm';

const mapStateToProps = ({ forms, radius }, props) => {
  const { id, defaultValues } = props;
  const reduxValues = _.get(forms, `data.${id}`);
  return ({
    defaultValues:  { side_answer: [], radius_answer:[], ...(reduxValues || defaultValues) },
    radiusOptions: _.map(radius.list, t => ({
      label: t.title,
      isDisabled:false,
      options: t.medics.map((el, index) => ({
        isDisabled: false,
        value: el.id,
        label: el.firstname,
      })),
    })),
  });
};

const mapDispatchToProps = (dispatch) => ({
  //
  updateData: (key, data) => dispatch(FormsActions.updateData(key, data)),
  omitData: (key) => dispatch(FormsActions.omitData(key)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SupInfoForm);