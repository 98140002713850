import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import i18n from '../../../../services/i18n/i18n';
import { QUESTION_TYPES } from '../../../../utils/enum';
import { capitalizeFirstLetter } from '../../../../utils/utils';
import { VALIDATION_KEYS } from '../../createFormV2/QuestionTypes/Validation/Validation';
import { reformatString } from './SummaryModal';


function makeString(arr) {
  if (!Array.isArray(arr)) return arr;
  if (arr.length === 1) return arr[0];
  const firsts = arr.slice(0, arr.length - 1);
  const last = arr[arr.length - 1];
  return firsts.join(', ') + ` ${i18n.t('and')} ` + last;
}

function SummaryModalQuestions({ questionTypes, question }) {
  const { translation, answer } = question;
  const { t } = useTranslation();

  const questionType = questionTypes[question.type_id];
  let start = ([QUESTION_TYPES.validation, QUESTION_TYPES.checkbox_full].includes(questionType.value) && question.asked_more_info)
    ? t('SummaryModal.answerList.asked_more_info')
    : t('SummaryModal.answerList.start');

  const { allAnswer, newStart, desc } = formatAnswers(questionType, translation, answer, start);
  if (translation.texte_pdf && translation.texte_pdf.length) {
    return (
      <div>
        {start} {reformatString(translation.texte_pdf)} {t('SummaryModal.answerList.at_the_question', { question: desc || '' })}
      </div>
    );
  } else {
    return (
      <div >
        {newStart} {Array.isArray(allAnswer)
          ? allAnswer.map((a, index) => <p className="mt-0" key={`${question.id}_${index}`}> {a} </p>)
          : allAnswer
        } {t('SummaryModal.answerList.at_the_question', { question: desc || '' })}
      </div>
    );
  }

}

SummaryModalQuestions.propTypes = {
  questionTypes: PropTypes.object,
  question: PropTypes.object,
};

export default SummaryModalQuestions;

const formatAnswers = (questionType, translation, answer, start) => {
  let allAnswer;
  let newStart = start;
  let desc = [QUESTION_TYPES.validation].includes(questionType.value) && translation.desc && translation.desc.length
    ? reformatString(translation.desc)
    : translation.question;
  desc = capitalizeFirstLetter(desc.replace(/,/g, ', ').trim());

  const dataList = translation.data.list;

  switch (questionType.value) {
    case QUESTION_TYPES.checkbox_full:
      const length = Array.isArray(answer) ? answer.length : 0;
      allAnswer = answer
        .slice()
        .sort((a, b) => dataList.indexOf(a) - dataList.indexOf(b))
        .map((answerValue, index) => {
          const isLastQuestion = index === length - 1;
          const answerLabel = dataList.find(el => `${el.value}` === `${answerValue}`)['label'];
          if (answerLabel.includes('\n')) {
            const splitAnswer = answerLabel.split('\n');
            const splitLength = splitAnswer.length;
            splitAnswer[0] = `"${splitAnswer[0]}`;
            splitAnswer[splitLength - 1] = `${splitAnswer[splitLength - 1]}"${isLastQuestion ? '' : ','}`;
            return splitAnswer;
          } else {
            return isLastQuestion ? `et "${answerLabel}"` : `"${answerLabel}",`;
          }
        });
      newStart += ':';
      break;
    case QUESTION_TYPES.validation:
      allAnswer = `"${Object.values(VALIDATION_KEYS).includes(answer) ? translation.data[answer] : answer}"`;
      break;
    case QUESTION_TYPES.multiple_choice:
      const a = dataList.filter(el => answer.includes(`${el.value}`)).map(el => el.label);
      allAnswer = `"${makeString(a)}"`;
      break;
    case QUESTION_TYPES.dropdown_list: {
      if (answer && answer.label){
        allAnswer = `"${answer.label}"`;
      } else {
        const value = dataList.find(el => `${el.value}` === `${answer}`);
        allAnswer = `"${value?.label || ''}"`;
      }
      break;
    }
    case QUESTION_TYPES.text_answer:
      allAnswer = `"${answer || ''}"`;
      break;
    case QUESTION_TYPES.pdf:
      newStart = i18n.t('SummaryModal.answerList.pdf');
      break;
    default:
      allAnswer = '';
      break;
  }
  return { allAnswer, newStart, desc };
};