
export const validAlphaRegex = RegExp(/^[a-z '-]+$/i);

export const validEmailRegex = /^(\w{1,50}(?:[.+_-]\w{1,50}){0,5})@\w{1,50}(?:[.-]\w{1,50}){0,5}\.(\w{2,3})$/i;
export const validAlphaDigitRegex = RegExp(/^[a-z0-9 '-]+$/i);
export const validDigitRegex = RegExp(/^\d+$/);
export const digitRegex = /\d/;

export const regexSecu = /^([123478])(?:[\s.-]{0,5})(\d{2})(?:[\s.-]{0,5})(0[1-9]|1[0-2]|[2-3]\d|4[0-2]|[5-9]\d)(?:[\s.-]{0,5})(\d{2}|2[AB])(?:[\s.-]{0,5})(\d\d[1-9]|\d[1-9]0|[1-9]\d0)(?:[\s.-]{0,5})(\d\d[1-9]|\d[1-9]0|[1-9]\d0)(?:[\s.-]{0,5})(0[1-9]|[1-8]\d|9[0-7])$/;

export const regexDoubleQuotes = /"/g;

export const firstLetterRegex = /(^|\s|[^a-zA-Z\u00C0-\u017F])([a-zA-Z\u00C0-\u017F])/g;
export const firstLetterEachFirstnameRegex = /(?:[^A-Za-zÀ-ÖØ-öø-ÿ\n]|^|-)([A-Za-zÀ-ÖØ-öø-ÿ])/g;
export const regexPhoneNumber = /^(0[6-7])(?:[\s.-]{0,5})(\d{2})(?:[\s.-]{0,5})(\d{2})(?:[\s.-]{0,5})(\d{2})(?:[\s.-]{0,5})(\d{2})$/;
export const womanRegex = /^(women|woman|female|femme)$/i;
export const manRegex = /^(men|man|male|homme)$/i;
export const unkownRegex = /^(unkown|other|autre|inconnu)$/i;

export const regexPostcode = /^[0-9_]{2,5}/;

export const alphaRegex = /[a-zA-Z\u00C0-\u017F]/;

export const rppsRegex = /^10\d{9}$/;

export const checkMobilePhone = (areaCode, phoneNumberWithArea) => {
  const countries = [
    // https://howtophoneto.com/belgium/
    // https://en.wikipedia.org/wiki/List_of_mobile_telephone_prefixes_by_country
    { name: 'france', areaCode: '33', regex: /^(33)([6-7])(\d{8})$/, regexFormater: /^(33)([6-7])(\d{2})(\d{2})(\d{2})(\d{2})$/, replaceWith: '0$2 $3 $4 $5 $6' },
    { name: 'albania', areaCode: '355', regex: /^(355)(6[6-9])(\d{7})$/, regexFormater: /^(355)(6[6-9])(\d{7})$/, replaceWith: '0$2 $3' },
    { name: 'algeria', areaCode: '213', regex: /^(213)(55|66|77|9\d)(\d{7})$/, regexFormater: /^(213)([5679])(\d{2})(\d{2})(\d{2})(\d{2})$/, replaceWith: '0$2 $3 $4 $5 $6' },
    { name: 'andorra', areaCode: '376', regex: /^(376)([346])(\d{5})$/, regexFormater: /^(376)([346])(\d{2})(\d{2})(\d{2})(\d{2})$/, replaceWith: '0$2 $3 $4 $5 $6' },
    { name: 'australia', areaCode: '61', regex: /^(61)([45])(\d{8})$/, regexFormater: /^(61)([45])(\d{4})(\d{4})$/, replaceWith: '0$2 $3 $4' },
    { name: 'austria', areaCode: '43', regex: /^(43)\s?(6(?:50|64|7[67]|8[018]|99))\s?(\d{7,8})$/, regexFormater: /^(43)\s?(6(?:50|64|7[67]|8[018]|99))\s?(\d{7,8})$/, replaceWith: '0$2 $3 $4' },
    { name: 'belarus', areaCode: '375', regex: /^(375)(2[59]|33|44)(\d{7})$/ },
    { name: 'belgium', areaCode: '32', regex: /^(32)(468\d{5}|4[789]\d{6})$/ },
    { name: 'bosniaHerzegovina', areaCode: '387', regex: /^(387)(6\d{7})$/ },
    { name: 'bulgaria', areaCode: '359', regex: /^(359)((?:430|799)\d{5}|(?:48|8[7-9]|98)\d{6})$/ },
    { name: 'canada', areaCode: '1', regex: /^(1)\s{0,2}\(?(\d{3})\)?\s{0,2}(\d{3})[-\s]{0,2}(\d{4})/ },
    { name: 'china', areaCode: '86', regex: /^(86)(1\d{9})$/ },
    { name: 'croatia', areaCode: '385', regex: /^(385)(9\d{8})$/ },
    { name: 'cyprus', areaCode: '257', regex: /^(257)(9\d{7})$/ },
    { name: 'czechRepublic', areaCode: '420', regex: /^(420)(91|[67]\d)(\d{7})$/ },
    { name: 'denmark', areaCode: '45', regex: /^(45)(\d{8})$/ },
    { name: 'estonia', areaCode: '372', regex: /^(372)(5\d|8[1-3])(\d{5,6})$/ },
    { name: 'finland', areaCode: '358', regex: /^(358)(4\d|50)(\d{7,9})$/ },
    { name: 'germany', areaCode: '49', regex: /^(49)(01[5-7])(\d{3,8})$/ },
    { name: 'greece', areaCode: '30', regex: /^(30)(6\d{9})$/ },
    { name: 'guadeloupe', areaCode: '590', regex: /^(590)(690)(\d{6})$/ },
    { name: 'ireland', areaCode: '353', regex: /^(353)(08)(\d{4,7})$/ },
    { name: 'italy', areaCode: '39', regex: /^(39)(3)(\d{9})$/ },
    { name: 'japan', areaCode: '81', regex: /^(81)(0([7-9]0\d|701))(\d{5,6})$/ },
    { name: 'kosovo', areaCode: '383', regex: /^(383)(\d{8})$/ },
    { name: 'latvia', areaCode: '371', regex: /^(371)(2)(\d{7})$/ },
    { name: 'liechtenstein', areaCode: '423', regex: /^(383)(\d{7})$/ },
    { name: 'luxembourg', areaCode: '352', regex: /^(352)(6\d1)(\d{2,5})$/ },
    { name: 'macedonia', areaCode: '389', regex: /^(389)(7)(\d{7})$/ },
    { name: 'malta', areaCode: '356', regex: /^(356)(99)(\d{6})$/ },
    { name: 'moldova', areaCode: '373', regex: /^(373)(6)(\d{7})$/ },
    { name: 'monaco', areaCode: '377', regex: /^(377)(4)(\d{9})$/ },
    { name: 'montenegro', areaCode: '382', regex: /^(382)(6)(\d{7})$/ },
    { name: 'morocco', areaCode: '212', regex: /^(212)([67])(\d{8})$/ },
    { name: 'netherlands', areaCode: '31', regex: /^(31)(6)(\d{8})$/ },
    { name: 'norway', areaCode: '47', regex: /^(47)([94]\d|5[89])(\d{6})$/ },
    { name: 'poland', areaCode: '48', regex: /^(48)([4-7]\d|88)(\d{7})$/ },
    { name: 'portugal', areaCode: '351', regex: /^(351)(9)(\d{8})$/ },
    { name: 'romania', areaCode: '40', regex: /^(40)(7)(\d{8})$/ },
    { name: 'slovakia', areaCode: '421', regex: /^(421)(9(0[1-9]|[1-5]\d))(\d{6})$/ },
    { name: 'slovenia', areaCode: '386', regex: /^(386)(\d{8})$/ },
    { name: 'spain', areaCode: '34', regex: /^(34)(6[1-9]|7\d)(\d{7})$/ },
    { name: 'sweden', areaCode: '46', regex: /^(46)(07[02369])(\d{7})$/ },
    { name: 'switzerland', areaCode: '41', regex: /^(41)(7[4-9])(\d{7})$/ },
    { name: 'turkey', areaCode: '90', regex: /^(90)(5)(\d{9})$/ },
    { name: 'ukrain', areaCode: '380', regex: /^(380)(\d{9})$/ },
    { name: 'unitedKingdom', areaCode: '44', regex: /^(44)(07[1-9])(\d{6,7})$/ },
    { name: 'unitedStates', areaCode: '1', regex: /^(1)\s{0,2}\(?(\d{3})\)?\s{0,2}(\d{3})[-\s]{0,2}(\d{4})/ },
    { name: 'vaticanCity', areaCode: '39', regex: /^(39)(06698)(\d{5})$/ },
  ];
  const country = countries.find((el) => el.areaCode === areaCode);
  return country && country.regex.test(phoneNumberWithArea);
};
