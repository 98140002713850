import { connect } from 'react-redux';

import { parseDate } from '../../../../../services/datefns';
import { INITIAL_INFO } from './FormModel/formInitialValues';
import InfosFormContainer from './InfosFormContainer';

const mapStateToProps = ({ auth, display }, props) => {
  const { defaultValues, isTutor } = props;
  const initialValues = { ...INITIAL_INFO, is_tutor: isTutor, ...defaultValues };

  return {
    defaultValues: {
      ...initialValues,
      birthdate: defaultValues?.birthdate ? parseDate(defaultValues.birthdate) : null,
    },
    isClinicalMode: display.isClinicalStudy,
    caslUpdateAt: auth.casl.date,
  };
};

export default connect(mapStateToProps, null)(InfosFormContainer);
