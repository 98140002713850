// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ec-navigate-back-button-container {
  display: flex;
  align-items: center;
  width: 255px;
  position: absolute;
  top: 130px;
}

.infopatient-info-contact .ec-navigate-back-button-container {
  position: absolute;
}

.ec-navigate-back-button {
  width: 58px;
  height: 58px;
  border-radius: 20px;
  background-color: #0d204b;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.ec-navigate-back-button:hover img {
  filter: invert(54%) sepia(38%) saturate(5038%) hue-rotate(154deg) brightness(96%) contrast(101%);
}

.ec-navigate-back-button-text {
  font-size: 20px;
  font-weight: bold;
  line-height: 0.73;
  letter-spacing: -0.09px;
  color: #ffffff;
  margin-left: 21px;
}

@media screen and (max-width: 600px) {
  .ec-navigate-back-button-container {
    top: 90px;
  }
}

@media screen and (max-width: 500px) {
  .ec-navigate-back-button-container {
    width: 100%;
    width: 186px;
    background-color: #0d204b;
    top: 11px;
  }

  .ec-navigate-back-button-container .ec-navigate-back-button-text {
    margin: 0;
    font-size: 17px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/ui/components/navigateBack/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,YAAY;EACZ,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,yBAAyB;EACzB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;AACjB;;AAEA;EACE,gGAAgG;AAClG;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,iBAAiB;EACjB,uBAAuB;EACvB,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE;IACE,SAAS;EACX;AACF;;AAEA;EACE;IACE,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,SAAS;EACX;;EAEA;IACE,SAAS;IACT,eAAe;EACjB;AACF","sourcesContent":[".ec-navigate-back-button-container {\n  display: flex;\n  align-items: center;\n  width: 255px;\n  position: absolute;\n  top: 130px;\n}\n\n.infopatient-info-contact .ec-navigate-back-button-container {\n  position: absolute;\n}\n\n.ec-navigate-back-button {\n  width: 58px;\n  height: 58px;\n  border-radius: 20px;\n  background-color: #0d204b;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n}\n\n.ec-navigate-back-button:hover img {\n  filter: invert(54%) sepia(38%) saturate(5038%) hue-rotate(154deg) brightness(96%) contrast(101%);\n}\n\n.ec-navigate-back-button-text {\n  font-size: 20px;\n  font-weight: bold;\n  line-height: 0.73;\n  letter-spacing: -0.09px;\n  color: #ffffff;\n  margin-left: 21px;\n}\n\n@media screen and (max-width: 600px) {\n  .ec-navigate-back-button-container {\n    top: 90px;\n  }\n}\n\n@media screen and (max-width: 500px) {\n  .ec-navigate-back-button-container {\n    width: 100%;\n    width: 186px;\n    background-color: #0d204b;\n    top: 11px;\n  }\n\n  .ec-navigate-back-button-container .ec-navigate-back-button-text {\n    margin: 0;\n    font-size: 17px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
