import './style.css';

import React from 'react';

import logo from '../../../../assets/img/logo/logo-easy-consent-dark-copie@3x.png';

const AuthLayoutLogo = ({ onClick }) => (
  <div className={'authlayout-logo-container'} >
    <img src={logo} alt="authlayout-logo" onClick={onClick}/>
  </div>
);

export default AuthLayoutLogo;
