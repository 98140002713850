import _ from 'lodash';
import { connect } from 'react-redux';

import { FormsActions } from '../../../../redux/forms';
import { PatientActions } from '../../../../redux/patient';
import { parseDate } from '../../../../services/datefns';
import { getUserProperty } from '../../../../utils';
import { INITIAL_SURVEY_FORM } from './FormModel/formInitialValues';
import SurveyPatientForm from './SurveyPatientForm';

const mapStateToProps = ({ forms, clinic, display, medic }, props) => {
  const { id, urlParams, patient: propsPatient } = props;
  const medicalTeamList = Object.values(medic.medicalTeamList || {});
  const patientsListOptions = medicalTeamList.reduce((acc, t) => [...acc, ...(t.patients || [])], []);
  const reduxValues = _.get(forms, `data.${id}`);
  const patientId = propsPatient?.id && parseInt(propsPatient.id);
  const selectedTeam = medic.selectedTeam;
  const medicTeamOptions = medicalTeamList.map((t) => ({
    value: t.id,
    label: t.title,
    medics: (t.medics || []).map(m => ({
      ...m,
      isOwner: m.id === t.owner_id,
    })),
  }));

  const clinicsListOptions = Object.values(clinic.list || {}).map((c) => ({
    value: c.id,
    label: c.name,
  }));
  const medicClinicId = clinicsListOptions.length === 1 ? clinicsListOptions[0].value : null;
  const defaultValues = !!reduxValues
    ? { ...reduxValues }
    : {
      ...INITIAL_SURVEY_FORM,
      patient_id: urlParams?.patient_id ? parseInt(urlParams.patient_id) : patientId || INITIAL_SURVEY_FORM.patient_id,
      medical_teams_id: selectedTeam ? parseInt(selectedTeam) : INITIAL_SURVEY_FORM.medical_teams_id,
      intervention: {
        date: urlParams?.intervention?.date ? parseDate(urlParams.intervention.date) : INITIAL_SURVEY_FORM.intervention.date,
        date_consult: urlParams?.intervention?.date_consultation ? parseDate(urlParams.intervention.date_consultation) : INITIAL_SURVEY_FORM.intervention.date_consult,
      },
      clinic_id: urlParams?.clinic_id ? parseInt(urlParams.clinic_id) : medicClinicId || INITIAL_SURVEY_FORM.clinic_id,
    };
  if (urlParams?._interface_patient_id){
    defaultValues.id_event_number = urlParams._interface_patient_id;
  }

  return ({
    medicTeamOptions,
    patientsListOptions: (patientsListOptions || []).map((p) => ({
      value: p.id,
      label: getUserProperty(p, 'contactNameWithTitle'),
    })),
    clinicsListOptions: clinicsListOptions,
    defaultValues: defaultValues,
    appMode: display.appMode,
  });
};

const mapDispatchToProps = (dispatch) => ({
  patientDetailRequest: (patientId, selectedTeam = null, params = { loading: false }) => dispatch(PatientActions.patientDetailRequest(patientId, selectedTeam, params)),
  updateData: (key, data) => dispatch(FormsActions.updateData(key, data)),
  omitData: (key) => dispatch(FormsActions.omitData(key)),
  selectedAutonomousPatient: (patientId) => dispatch(PatientActions.selectedPatientAddFormulaire(patientId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SurveyPatientForm);