import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { InputText } from '../../controllers';
import InputSelectV2 from '../../controllers/InputSelectV2';
import { isRequiredField } from '../../Form/Form';
import { CustomFormController } from '../../innerWrapper';

const isPostcodeValid = postcode => postcode && postcode !== '' && postcode !== '__ ___';

const CityPicker = ({ city, citiesOptions, fetchCityListWithPostcode, clearCityList, isCityLoading, yupSchema, defaultValues, readOnly, readonlyFields = [], isVisible }) => {
  const { t } = useTranslation();
  const initialPostCode = useMemo(() => defaultValues[`${city}_postcode`], [city, defaultValues && defaultValues[`${city}_postcode`]]);
  const [label, setLabel] = useState();
  const [selectedCity, setSelectedCity] = useState();
  const [postcode, setPostcode] = useState();
  const [prevCities, setPrevCities] = useState();
  const [canFetch, setCanFetch] = useState(false);
  const { control, setValue } = useFormContext(); // retrieve all hook methods
  const postcodeWatch = useWatch({
    control,
    name: `${city}_postcode`, // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
    // defaultValue: _.get(defaultValues, `${city}_postcode`),
  });
  const cityIdWatch = useWatch({
    control,
    name: `${city}_id`, // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
    defaultValue: _.get(defaultValues, `${city}_id`, ''),
  });
  const cityWatch = useWatch({
    control,
    name: `${city}`, // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
    defaultValue: _.get(defaultValues, `${city}`, ''),
  });

  useEffect(() => {
    if (isPostcodeValid(initialPostCode)) {
      fetchCityListWithPostcode(initialPostCode);
      setCanFetch(true);
    }
    return function cleanup(){
      clearCityList && clearCityList();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialPostCode]);

  useEffect(() => {
    // update state postcode and fetch city list
    const newLabel = city.split('.').pop();
    if (newLabel !== label){
      setLabel(newLabel);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [city]);

  useEffect(() => {
    // update state postcode and fetch city list
    const formatedPostcodeWatch = postcodeWatch && `${postcodeWatch}`.replace(/\s/g, '');
    const newPostcode = formatedPostcodeWatch;
    if (isPostcodeValid(newPostcode)){
      if (newPostcode !== postcode){
        setPostcode(newPostcode);
        canFetch && fetchCityListWithPostcode(newPostcode);
        setCanFetch(true);
      }
    } else if (newPostcode === '' || formatedPostcodeWatch === ''){
      setCityNames();
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postcodeWatch]);

  useEffect(() => {
    // on cityId selected, update the postcode and the city
    setCanFetch(false);
    if (cityIdWatch !== selectedCity) {
      setSelectedCity(cityIdWatch);
      const newCity = citiesOptions && citiesOptions.find( c => c.value === cityIdWatch);
      setValue(city, newCity?.label || '');
      const newPostcode = newCity?.postcode || '';
      if (newPostcode && (newPostcode !== postcode)) {
        setPostcode(newPostcode);
        setValue(`${city}_postcode`, newPostcode | '');
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cityIdWatch]);

  useEffect(() => {
    // on redux city updated
    const newPrevCities = citiesOptions.map(c => c.value).sort().join();
    if (newPrevCities !== prevCities) {
      setPrevCities(newPrevCities);
      if (citiesOptions.length === 1) {
        setCanFetch(false);
        // if city list return only 1 result then this is the selected result
        const newCity = citiesOptions[0];
        setCityNames(newCity.label, newCity.value);
      } else {
        // if city list return more than 1 result then clear _id if it is not on the list
        if (citiesOptions.length) {
          const newCity = cityWatch && citiesOptions.find(c => c.label.normalize() === `${cityWatch}`.normalize());
          if (!newCity) {
            setCityNames();
          }
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [citiesOptions]);

  const setCityNames = (cityValue = defaultValues[city] || '', cityIdValue = defaultValues[`${city}_id`] || '') => {
    setValue(city, cityValue || '');
    setValue(`${city}_id`, cityIdValue || '');
  };

  return (
    <div className="d-flex flex-sm-row flex-column">
      <div className={'pr-2 w-100'}>
        <CustomFormController
          name={`${city}_postcode`}
          label={t(`patientForms.${label}_postcode.label`)}
          isRequiredField={isRequiredField(yupSchema, `${city}_postcode`)}
          isVisible={_.get(isVisible, `${city}_postcode`)}
        >
          <InputText
            inputPreset="postcode"
            readOnly={readOnly || readonlyFields.includes(`${city}_postcode`)}
          />
        </CustomFormController>
      </div>
      <div className={'pl-2 w-100 w-sm-75'}>
        <CustomFormController
          name={`${city}_id`}
          label={t(`patientForms.${label}_id.label`)}
          controlClassName={`${(cityIdWatch || (citiesOptions.length && (!cityWatch || cityWatch === ''))) ? '' : 'd-none'}`}
          isRequiredField={isRequiredField(yupSchema, `${city}_id`)}
          isVisible={_.get(isVisible, `${city}_id`)}
        >
          <InputSelectV2
            options={citiesOptions}
            isLoading={isCityLoading}
            disabled={readOnly || readonlyFields.includes(`${city}_id`)}
          />
        </CustomFormController>
        <CustomFormController
          name={`${city}`}
          label={t(`patientForms.${label}.label`)}
          controlClassName={`${(cityIdWatch || (citiesOptions.length && (!cityWatch || cityWatch === ''))) ? 'd-none' : ''}`}
          isRequiredField={isRequiredField(yupSchema, `${city}`)}
          isVisible={_.get(isVisible, `${city}`)}
        >
          <InputText
            isLoading={isCityLoading}
            readOnly={readOnly || readonlyFields.includes(`${city}`)}
          />
        </CustomFormController>
      </div>
    </div>
  );
};

const areEqual = (prevProps, nextProps) => {
  return prevProps.name !== nextProps.name;
};

CityPicker.propTypes = {
  fetchCityListWithPostcode: PropTypes.func.isRequired,
  city: PropTypes.string.isRequired,
  citiesOptions: PropTypes.array,
  isCityLoading: PropTypes.bool,
};

export default React.memo(CityPicker, areEqual);
