import { createReducer } from 'reduxsauce';

const initialState = {
  loading: false,
  options: [{
    label : 'Main',
    options: [
      {
        value:1,
        label : 'Auriculaire',
      },
      {
        value:2,
        label : 'Annulaire',
      },
      {
        value:3,
        label : 'Majeur',
      },
      {
        value:4,
        label : 'Index',
      },
      {
        value:5,
        label : 'Pouce',
      },
    ],
  }, {
    label : 'Pied',
    options: [
      {
        value:6,
        label : 'Hallux',
      },
      {
        value:7,
        label : 'Secundus',
      },
      {
        value:8,
        label : 'Tertius',
      },
      {
        value:9,
        label : 'Quartus',
      },
      {
        value:10,
        label : 'Quintus',
      },
    ],
  }],
  list: {
    0: {
      id: 1,
      title: 'Main',
      medics: [
        {
          id: 1,
          firstname: 'Auriculaire',
        },
        {
          id: 2,
          firstname: 'Annulaire',
        },
        {
          id: 3,
          firstname: 'Majeur',
        },
        {
          id: 4,
          firstname: 'Index',
        },
        {
          id: 5,
          firstname: 'Pouce',
        },
      ],
    },
    1: {
      id: 2,
      title: 'Pied',
      medics: [
        {
          id: 6,
          firstname: 'Hallux',
        },
        {
          id: 7,
          firstname: 'Secundus',
        },
        {
          id: 8,
          firstname: 'Tertius',
        },
        {
          id: 9,
          firstname: 'Quartus',
        },
        {
          id: 10,
          firstname: 'Quintus',
        },
      ],
    },
  },
};

export default createReducer(initialState, {});
