import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import ModalBtnContainer from '../../../modal/ModalBtnContainer/ModalBtnContainer';
import ModalButton from '../ModalButton';

const ModalButtonsGroup = ({ submitLabel, cancelLabel, onCancel, disabledSubmit, hideFirst, hideSecond }) => {
  const { t } = useTranslation();
  return (
    <ModalBtnContainer>
      { !hideFirst && (
        <ModalButton
          label={cancelLabel || t('form.button.cancel.label')}
          type="button"
          onClick={onCancel}
          variant="ghost"
        />
      )}
      { !hideSecond && (
        <ModalButton
          label={submitLabel || t('form.button.send.label')}
          type="submit"
          disabled={disabledSubmit}
        />
      )}
    </ModalBtnContainer>
  );
};

ModalButtonsGroup.propTypes = {
  onCancel: PropTypes.func.isRequired,
  submitLabel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  cancelLabel: PropTypes.string,
};

export default ModalButtonsGroup;
