/**
 * GRAPHQL QUERIES
 */

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  currentMedic: () => `
    query CurrentMedic($email: String!) {
      medics(where: {user: {username: {_ilike: $email}}}, limit: 1) {
        id
        gender
        firstname
        lastname
        title
        country
        rpps_number
        phone_number
        local_phone_number
        is_premium
        is_secretary
        notif_mail
        notif_sms
        email
        user {
          id
          username
          role
          renew_pwd
          accepted_cgu
          accepted_rgpd
          edit_info
        }
        medic_specialities {
          speciality_id
        }
      }
    }
  `,
  medicTeam: () => `
    query MedicTeam($medicId: Int!) {
      medical_teams(where: {medical_teams_medics: {medic_id: {_eq: $medicId}}}) {
        id
        title
        owner {
          id
        }
        medical_teams_medics {
          medic {
            id
            title
            firstname
            lastname
            is_secretary
          }
        }
        patients(where: { is_tutor: {_eq: false}}) {
          id
          is_tutor
          tutors {
            patient_id
          }
          tutorsByPatientId {
            guardian_id
          }
        }
      }
    }
  `,
  medicsMetrics: () => `
    query MedicsMetrics($medicId: Int!, $_gte: timestamptz!) {
      sent:interventions_aggregate(
        distinct_on: number, 
        where: {
          date: {_gte: $_gte}, 
          surveys: {status: {_eq: "sent"}, 
            # is_archived: {_eq: false},  # is_archived: {_eq: false}, 
            medic_surveys: {medic_id: {_eq: $medicId}}}}
      ) {
        aggregate {
          count
        }
      }

      refused:interventions_aggregate(
        distinct_on: number, 
        where: {
          date: {_gte: $_gte}, 
          surveys: {status: {_eq: "refused"}, 
            # is_archived: {_eq: false}, 
            medic_surveys: {medic_id: {_eq: $medicId}}}}
      ) {
        aggregate {
          count
        }
      }

      accepted:interventions_aggregate(
        distinct_on: number, 
        where: {
          date: {_gte: $_gte}, 
          surveys: {status: {_eq: "accepted"}, 
            # is_archived: {_eq: false}, 
            medic_surveys: {medic_id: {_eq: $medicId}}}}
      ) {
        aggregate {
          count
        }
      }

      total:interventions_aggregate(
        distinct_on: number, 
        where: {
          date: {_gte: $_gte}, 
          surveys: {status: {_neq: "draft"}, 
            # is_archived: {_eq: false}, 
            medic_surveys: {medic_id: {_eq: $medicId}}}}
      ) {
        aggregate {
          count
        }
      }
    }
  `,
  medicsTeamsMetricsDetails: () => `
    query MedicsMetricsDetails($teamId: Int!, $_gte: timestamptz!) {
      sent:interventions(
        distinct_on: number, 
        where: {
          date: {_gte: $_gte}, 
          surveys: {status: {_eq: "sent"},
            medical_teams_id: {_eq: $teamId}
          }
        }
      ) {
        id
        surveys {
          patient_id
        }
      }

      refused:interventions(
        distinct_on: number, 
        where: {
          date: {_gte: $_gte}, 
          surveys: {status: {_eq: "refused"},
            medical_teams_id: {_eq: $teamId}
          }
        }
      ) {
        id
        surveys {
          patient_id
        }
      }

      consent:interventions(
        # distinct_on: number, 
        where: {
          date: {_gte: $_gte}, 
          surveys: {
            status: {_neq: "draft"},
            medical_teams_id: {_eq: $teamId}
          }
        }
      ) {
        id
        surveys {
          patient_id
        }
      }

      surgery:interventions(
        distinct_on: number, 
        where: {
          date: {_gte: $_gte}, 
          surveys: {
            status: {_neq: "draft"},
            medical_teams_id: {_eq: $teamId}
          }
        }
      ) {
        id
        surveys {
          patient_id
        }
      }

      patient:patients(
        where: {
          medical_teams_id: {_eq: $teamId}, 
          is_tutor: {_eq: false}
        }
      ) {
        id
      }
    }
  `,
  medicsTeamsMetrics: () => `
    query MedicsMetrics($teamId: Int!, $_gte: timestamptz!) {
      sent:interventions_aggregate(
        distinct_on: number, 
        where: {
          date: {_gte: $_gte}, 
          surveys: {status: {_eq: "sent"}, 
            # is_archived: {_eq: false}, 
            medical_teams_id: {_eq: $teamId}}}
      ) {
        aggregate {
          count
        }
      }

      refused:interventions_aggregate(
        distinct_on: number, 
        where: {
          date: {_gte: $_gte}, 
          surveys: {status: {_eq: "refused"}, 
            # is_archived: {_eq: false}, 
            medical_teams_id: {_eq: $teamId}}}
      ) {
        aggregate {
          count
        }
      }

      consent:interventions_aggregate(
        distinct_on: number, 
        where: {
          date: {_gte: $_gte}, 
          surveys: {status: {_eq: "accepted"}, 
            # is_archived: {_eq: false}, 
            medical_teams_id: {_eq: $teamId}}}
      ) {
        aggregate {
          count
        }
      }

      surgery:interventions_aggregate(
        distinct_on: number, 
        where: {
          date: {_gte: $_gte}, 
          surveys: {status: {_neq: "draft"}, 
            # is_archived: {_eq: false}, 
            medical_teams_id: {_eq: $teamId}}}
      ) {
        aggregate {
          count
        }
      }

      patient:patients_aggregate(where: {medical_teams_id: {_eq: $teamId}, is_tutor: {_eq: false}}) {
        aggregate {
          count
        }
      }
    }
  `,
  medicTeamOwner: () => `
    query MedicTeamOwner($id: Int!) {
      medical_teams(where: {medical_teams_medics: {medic_id: {_eq: $id}}}) {
        id
        owner {
          id
          firstname
          lastname
        }
      }
    }
  `,
};
