import { createAction } from '../../utils';

// Types
export const types = {
  RESET_REDUCER: 'medic_RESET_REDUCER',

  CURRENT_MEDIC_REQUEST: 'medic_CURRENT_MEDIC_REQUEST',
  CURRENT_MEDIC_SUCCESS: 'medic_CURRENT_MEDIC_SUCCESS',
  CURRENT_MEDIC_FAILURE: 'medic_CURRENT_MEDIC_FAILURE',

  MEDIC_STATS_SUCCESS: 'medic_MEDIC_STATS_SUCCESS',

  UPDATE_MEDIC_REQUEST: 'medic_UPDATE_MEDIC_REQUEST',
  UPDATE_MEDIC_SUCCESS: 'medic_UPDATE_MEDIC_SUCCESS',
  UPDATE_MEDIC_FAILURE: 'medic_UPDATE_MEDIC_FAILURE',

  MEDIC_UPDATE_PASSWORD_SUCCESS: 'medic_MEDIC_UPDATE_PASSWORD_SUCCESS',

  MEDIC_UPDATE_CGU_REQUEST: 'medic_MEDIC_UPDATE_CGU_REQUEST',
  MEDIC_UPDATE_CGU_SUCCESS: 'medic_MEDIC_UPDATE_CGU_SUCCESS',
  MEDIC_UPDATE_CGU_FAILURE: 'medic_MEDIC_UPDATE_CGU_FAILURE',

  MEDIC_UPDATE_NOTIF_REQUEST: 'medic_MEDIC_UPDATE_NOTIF_REQUEST',
  MEDIC_UPDATE_NOTIF_SUCCESS: 'medic_MEDIC_UPDATE_NOTIF_SUCCESS',
  MEDIC_UPDATE_NOTIF_FAILURE: 'medic_MEDIC_UPDATE_NOTIF_FAILURE',

  MEDIC_UPDATE_RGPD_REQUEST: 'medic_MEDIC_UPDATE_RGPD_REQUEST',
  MEDIC_UPDATE_RGPD_SUCCESS: 'medic_MEDIC_UPDATE_RGPD_SUCCESS',
  MEDIC_UPDATE_RGPD_FAILURE: 'medic_MEDIC_UPDATE_RGPD_FAILURE',

  MEDIC_UPDATE_EDIT_INFO_REQUEST: 'medic_MEDIC_UPDATE_EDIT_INFO_REQUEST',
  MEDIC_UPDATE_EDIT_INFO_SUCCESS: 'medic_MEDIC_UPDATE_EDIT_INFO_SUCCESS',
  MEDIC_UPDATE_EDIT_INFO_FAILURE: 'medic_MEDIC_UPDATE_EDIT_INFO_FAILURE',

  MEDIC_LEGAL_SUCCESS_RESET: 'medic_MEDIC_LEGAL_SUCCESS_RESET',

  MEDIC_TEAM_REQUEST: 'medic_MEDIC_TEAM_REQUEST',
  MEDIC_TEAM_SUCCESS: 'medic_MEDIC_TEAM_SUCCESS',
  MEDIC_TEAM_SUCCESS_V2: 'medic_MEDIC_TEAM_SUCCESS_V2',
  MEDIC_TEAM_OWNER_SUCCESS: 'medic_MEDIC_TEAM_OWNER_SUCCESS',
  MEDIC_TEAM_FAILURE: 'medic_MEDIC_TEAM_FAILURE',

  /* QR */

  MEDIC_GENRATE_QR_URL_REQUEST: 'medic_MEDIC_GENRATE_QR_URL_REQUEST',
  MEDIC_GENRATE_QR_URL_SUCCESS: 'medic_MEDIC_GENRATE_QR_URL_SUCCESS',
  MEDIC_GENRATE_QR_URL_FAILURE: 'medic_MEDIC_GENRATE_QR_URL_FAILURE',
  MEDIC_RESET_QR_URL: 'medic_MEDIC_RESET_QR_URL',

  MEDIC_CHANGE_SELECTED_MEDIC_TEAM_REQUEST: 'medic_MEDIC_CHANGE_SELECTED_MEDIC_TEAM_REQUEST',
  MEDIC_CHANGE_SELECTED_MEDIC_TEAM_SUCCESS: 'medic_MEDIC_CHANGE_SELECTED_MEDIC_TEAM_SUCCESS',
  MEDIC_CHANGE_SELECTED_MEDIC_TEAM_FAILURE: 'medic_MEDIC_CHANGE_SELECTED_MEDIC_TEAM_FAILURE',

  MEDIC_SET_AUTO_LOGIN_DATA: 'medic_MEDIC_SET_AUTO_LOGIN_DATA',
  MEDIC_RESET_AUTO_LOGIN_DATA: 'medic_MEDIC_RESET_AUTO_LOGIN_DATA',

  MEDIC_GET_PATIENTS: 'medic_GET_PATIENTS',
  MEDIC_TEAM_REQUEST_FROM_MEDIC_ID: 'medic_TEAM_REQUEST_FROM_MEDIC_ID',

  FETCH_MEDIC_TEAM_PATIENTS: 'medic_FETCH_MEDIC_TEAM_PATIENTS',

  MEDIC_FETCH_MEDIC_TEAM_DATA: 'medic_FETCH_MEDIC_TEAM_DATA',
  MEDIC_MEDICAL_TEAM_ADD_PATIENT: 'medic_MEDICAL_TEAM_ADD_PATIENT',

  MEDIC_TEAM_ADD_PATIENT_LIST: 'medic_MEDIC_TEAM_ADD_PATIENT_LIST',

  MEDICAL_TEAM_PATIENT_SURVEY_DELETE: 'medic_MEDICAL_TEAM_PATIENT_SURVEY_DELETE',

  UPDATE_MEDICAL_TEAM_PATIENT: 'medcis_UPDATE_MEDICAL_TEAM_PATIENT',
  SET_MEDICAL_TEAM_PARAMS: 'SET_MEDICAL_TEAM_PARAMS',
};

// Actions
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  /** REQUEST */
  currentMedicRequest: () => createAction(types.CURRENT_MEDIC_REQUEST),
  currentMedicSuccess: (medic) => createAction(types.CURRENT_MEDIC_SUCCESS, { medic }),
  currentMedicFailure: () => createAction(types.CURRENT_MEDIC_FAILURE),

  medicStatsSuccess: (stats) => createAction(types.MEDIC_STATS_SUCCESS, { stats }),

  updateMedicRequest: (medic) => createAction(types.UPDATE_MEDIC_REQUEST, { medic }),
  updateMedicSuccess: (medic) => createAction(types.UPDATE_MEDIC_SUCCESS, { medic }),
  updateMedicFailure: () => createAction(types.UPDATE_MEDIC_FAILURE),

  medicUpdatePasswordSuccess: () => createAction(types.MEDIC_UPDATE_PASSWORD_SUCCESS),

  /* CGU */

  medicUpdateCguRequest: (id) => createAction(types.MEDIC_UPDATE_CGU_REQUEST, { id }),
  medicUpdateCguSuccess: (accepted_cgu) => createAction(types.MEDIC_UPDATE_CGU_SUCCESS, { accepted_cgu }),
  medicUpdateCguFailure: () => createAction(types.MEDIC_UPDATE_CGU_FAILURE),

  /* RGPD */

  medicUpdateRGPDRequest: (id) => createAction(types.MEDIC_UPDATE_RGPD_REQUEST, { id }),
  medicUpdateRGPDSuccess: (accepted_rgpd) => createAction(types.MEDIC_UPDATE_RGPD_SUCCESS, { accepted_rgpd }),
  medicUpdateRGPDFailure: () => createAction(types.MEDIC_UPDATE_RGPD_FAILURE),

  /* EDIT INFO */

  medicUpdateEditInfoRequest: (id) => createAction(types.MEDIC_UPDATE_EDIT_INFO_REQUEST, { id }),
  medicUpdateEditInfoSuccess: (edit_info) => createAction(types.MEDIC_UPDATE_EDIT_INFO_SUCCESS, { edit_info }),
  medicUpdateEditInfoFailure: () => createAction(types.MEDIC_UPDATE_EDIT_INFO_FAILURE),

  /* Notifications */
  medicUpdateNotifRequest: (medic) => createAction(types.MEDIC_UPDATE_NOTIF_REQUEST, { medic }),
  medicUpdateNotifSuccess: (medic) => createAction(types.MEDIC_UPDATE_NOTIF_SUCCESS, { medic }),
  medicUpdateNotifFailure: () => createAction(types.MEDIC_UPDATE_NOTIF_FAILURE),

  medicResetLegalSuccess: () => createAction(types.MEDIC_LEGAL_SUCCESS_RESET),

  medicTeamSuccessV2: (teams) => createAction(types.MEDIC_TEAM_SUCCESS_V2, { teams }),
  medicTeamOwnerSuccess: (teams) => createAction(types.MEDIC_TEAM_OWNER_SUCCESS, { teams }),
  medicTeamFailure: () => createAction(types.MEDIC_TEAM_FAILURE),

  changeSelectedMedicTeamRequest: (teamId = null) => createAction(types.MEDIC_CHANGE_SELECTED_MEDIC_TEAM_REQUEST, { teamId }),
  changeSelectedMedicTeamSuccess: (teamId) => createAction(types.MEDIC_CHANGE_SELECTED_MEDIC_TEAM_SUCCESS, { teamId }),
  changeSelectedMedicTeamFailure: () => createAction(types.MEDIC_CHANGE_SELECTED_MEDIC_TEAM_FAILURE),

  medicSetAutoLoginData: (data) => createAction(types.MEDIC_SET_AUTO_LOGIN_DATA, { data }),
  medicResetAutoLoginData: () => createAction(types.MEDIC_RESET_AUTO_LOGIN_DATA),

  fetchMedicTeamData: () => createAction(types.MEDIC_FETCH_MEDIC_TEAM_DATA),
  fetchMedicTeamPatients: (teamId) => createAction(types.FETCH_MEDIC_TEAM_PATIENTS, { teamId }),
  medicTeamAddPatient: (patients) => createAction(types.MEDIC_MEDICAL_TEAM_ADD_PATIENT, { patients }),
  medicTeamAddPatientList: (teamId, patients) => createAction(types.MEDIC_TEAM_ADD_PATIENT_LIST, { teamId, patients }),

  medicTeamPatientSurveyDelete: (patientId, IDs) => createAction(types.MEDICAL_TEAM_PATIENT_SURVEY_DELETE, { patientId, IDs }),
  updateMedicalTeamPatient: (newPatient) => createAction(types.UPDATE_MEDICAL_TEAM_PATIENT, { newPatient }),

  setMedicalTeamParams: () => createAction(types.SET_MEDICAL_TEAM_PARAMS),

  resetReducer: () => createAction(types.RESET_REDUCER),
};
