
import _ from 'lodash';
import { connect } from 'react-redux';

import { MedicTemplateActions } from '../../../redux/medicTemplates';
import { SnackActions } from '../../../redux/snackBar';
import { SurveyActions } from '../../../redux/survey';
import { TemplateActions } from '../../../redux/template';
import { getUserLngTranslation } from '../../../utils/translation';
import { normalizeString } from '../../../utils/utils';
import Forms from './Forms';

const sortList = (list, key = 'title') => list.sort((a, b) => {
  const nameA = normalizeString(_.get(a, key, ''));
  const nameB = normalizeString(_.get(b, key, ''));
  return nameA > nameB;
});

const mapStateToProps = ({ auth, medic, medicTemplate, template, survey }) => {
  const { user: { language_id } } = auth;

  // medicTemplate
  const medicTemplateList = Object.values(medicTemplate.list || {});
  const formatedMedicTemplateList = getUserLngTranslation([...medicTemplateList], language_id, 'displayTranslation');
  const uploadedTemplateIds = _.uniq([...medicTemplateList].map(t => t.store_id).filter(el => !!el));

  // template
  const storeTemplateList = Object.values(template.list || {});
  const formatedStoreTemplateList = getUserLngTranslation([...storeTemplateList], language_id, 'displayTranslation');

  const templateList = [...storeTemplateList, ...medicTemplateList];

  const specialitiesIdsList = _.uniq(templateList.map(el => el.speciality_id));
  const typesIdsList = _.uniq(templateList.map(el => el.type_id));

  return {
    types: sortList(Object.values(_.pick(survey.types || {}, typesIdsList)), ['translation', 'title']),
    specialities: sortList(Object.values(_.pick(survey.specialities || {}, specialitiesIdsList)), ['translation', 'value']),
    selectedTeam: medic.selectedTeam,
    medicTemplateList:  sortList(formatedMedicTemplateList, ['displayTranslation', 'title']),
    storeTemplateList: sortList(formatedStoreTemplateList, ['displayTranslation', 'title']),
    uploadedTemplateIds,
    currentMedic: medic.currentMedic,
  };
};

const mapDispatchToProps = (dispatch) => ({

  surveyTypesRequest: () => dispatch(SurveyActions.surveyTypesRequest()),
  specialitiesRequest: () => dispatch(SurveyActions.specialitiesRequest()),
  medicTemplatesGetRequest: (selectedTeam) => dispatch(MedicTemplateActions.medicTemplatesGetRequest(selectedTeam)),
  storeTemplatesGetRequest: () => dispatch(TemplateActions.templatesGetRequest()),

  createMedicTemplatesFromStoreId:(templateID)=> dispatch( MedicTemplateActions.createMedicTemplatesFromStoreId(templateID) ),

  medicUnactivateTemplateRequest: (id) => dispatch(MedicTemplateActions.medicTemplateUnassignRequest(id)),
  medicTemplateDeleteRequest: (templateID) =>
    dispatch(MedicTemplateActions.medicTemplateDeleteRequest(templateID)),
  displayInfo: (message) => dispatch(SnackActions.displayInfo(message)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Forms);
