
import { connect } from 'react-redux';

import { AuthActions } from '../../../redux/auth';
import { PatientActions } from '../../../redux/patient';
import LegalModal from './LegalModal';

const mapStateToProps = ({ auth }) => {

  return {
    user: auth.user,
    token: auth.token,
    isLoginFromQrCode: auth.isLoginFromQrCode,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateCGU: (userId) => dispatch(AuthActions.cguApprove(userId)),
  updateRGPD: (userId) => dispatch(AuthActions.rgpdApprove(userId)),
  resetLegalSuccess: () => dispatch(AuthActions.resetLegalSuccess()),
  deleteCurrentPatient: () => dispatch(PatientActions.deleteCurrentPatient()),
  logout: () => dispatch(AuthActions.logout(false)),

});

export default connect(mapStateToProps, mapDispatchToProps)(LegalModal);
