import { formatISO } from 'date-fns';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SURVEY_STATUS } from '../../../../../utils';
import { Button } from '../../../../components/styledComponent';
import Iframe from '../IFrame/IFrame';

function ContraliaIFrameSignatureModal({ signUrl, signUrlError, onError, consentAnswers, survey, ip, genSurveySignUrl, onIframeLoaded, genSurveySignUrlReset, onSignSuccess }) {
  const { t } = useTranslation();
  const endDate = useMemo(() => new Date(), []);
  const [listening, setListening] = useState(false);
  const [surveyStatus, setSurveyStatus] = useState();
  const [isLoading, setIsLoading] = useState();
  const isBtnDisabled = !signUrlError && (isLoading || signUrl);

  useEffect(() => {
    return () => genSurveySignUrlReset();
  }, []);

  useEffect(() => {
    if (signUrlError) {
      onIframeLoaded();
      onError();
    }
  }, [signUrlError]);

  useEffect(() => {
    setSurveyStatus(survey?.status);
  }, [survey && survey.status]);

  useEffect(() => {
    if ([SURVEY_STATUS.ACCEPTED, SURVEY_STATUS.COUNTERSIGNED].includes(surveyStatus)) {
      onSignSuccess();
    }
  }
  , [surveyStatus]);

  const handleContraliaInitiate = (e) => {
    if (isBtnDisabled) return;
    setIsLoading(true);
    const { startDate } = consentAnswers;
    const questions = (consentAnswers.steps || [])
      .reduce((questions, step) => [...questions, ...step.questions], [])
      .map(q => _.pick(q, ['id', 'answer', 'asked_more_info']));

    genSurveySignUrl(survey.id, _.keyBy(questions, 'id'), ip, formatISO(startDate), formatISO(endDate || new Date()));
  };

  useEffect(() => {
    if (signUrl) {
      if (signUrl === 'already_signed') {
        onSignSuccess();
      } else {
        onIframeLoaded();
        setIsLoading(false);
      }
    }
  }, [signUrl]);

  useEffect(() => {
    if (listening) {
      const PROXY_API_URL = process.env.REACT_APP_PROXY_API || process.env.REACT_APP_BASE_API_URL || '/api';
      const url = `${PROXY_API_URL}/V2/surveys/${survey.id}/realtime-status`;
      const source = new EventSource(url, { withCredentials: false });
      source.onopen = () => {
        // eslint-disable-next-line no-console
        console.log('SSE opened!');
      };
      source.onmessage = (e) => {
        const data = JSON.parse(e.data);
        setSurveyStatus(data.status);
      };
      source.onerror = (e) => {
        // eslint-disable-next-line no-console
        console.error('SSE Error: ', e);
        source.close();
      };
      source.addEventListener('close', () => {
        source.close();
      });
      return () => {
        source.close();
      };
    }
  }, [listening]);

  const handleIframeMessage = ({ name, params }) => {
    // edoc.ready  edoc.done   edoc.pageLoaded
    if (name === 'edoc.done') {
      setListening(true);
    } else if (name === 'edoc.ready') {
      onIframeLoaded();
    }
  };

  return (
    <>
      {signUrlError && ( <div>{t('survey_sign_error_0')}</div> )}
      <div className="d-flex flex-column flex-md-row align-self-stretch justify-content-end">
        <Button onClick={handleContraliaInitiate} disabled={isBtnDisabled}>
          {t('consentFormStep.action.Continue.btn')}
        </Button>
      </div>
      <Iframe
        className="w-100 mx-2 px-2 ec-card"
        src={signUrl}
        width="100%"
        height="750px"
        onMessage={handleIframeMessage}
        isLoading={isLoading === true || !signUrl}
      />
    </>
  );
}

ContraliaIFrameSignatureModal.propTypes = {
  signUrl: PropTypes.string,
  consentAnswers: PropTypes.object,
  survey: PropTypes.object,
  ip: PropTypes.string.isRequired,
  genSurveySignUrl : PropTypes.func.isRequired,
  onIframeLoaded: PropTypes.func.isRequired,
  genSurveySignUrlReset: PropTypes.func.isRequired,
  onSignSuccess: PropTypes.func.isRequired,
};

export default ContraliaIFrameSignatureModal;
