import DOMPurify from 'dompurify';

DOMPurify.setConfig({ ADD_TAGS: ['iframe'], ADD_ATTR: ['allowfullscreen', 'style', 'frameborder', 'width', 'heigth', 'scrolling'] });

// Add a hook to make all links open a new window
DOMPurify.addHook('afterSanitizeAttributes', function(node) {
  // set all elements owning target to target=_blank
  if ('target' in node) {
    node.setAttribute('target', '_blank');
    // prevent https://www.owasp.org/index.php/Reverse_Tabnabbing
    node.setAttribute('rel', 'noopener noreferrer');
  }
  // set non-HTML/MathML links to xlink:show=new
  if (!node.hasAttribute('target')
    && (node.hasAttribute('xlink:href')
      || node.hasAttribute('href'))) {
    node.setAttribute('xlink:show', 'new');
  }
});


DOMPurify.addHook('uponSanitizeElement', (node, data) => {
  // remove autoplay
  if (node && node.hasAttribute && node.hasAttribute('autoplay')) {
    node.removeAttribute('autoplay');
  }
  if (data.tagName === 'iframe') {
    const src = node.getAttribute('src') || '';
    const regex = /^https:\/\/(www\.)?(((youtube|dailymotion)\.com\/embed)|(player\.vimeo\.com\/video))\//;
    if (!regex.test(src)) {
      return node.parentNode?.removeChild(node);
    }

  }
});

export default DOMPurify;