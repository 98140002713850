import './style.css';

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import iconMedecin from '../../../assets/img/illu-medecin@3x.png';
import iconPatient from '../../../assets/img/illu-patient@3x.png';
import { PATIENT_LOGIN_PATH, PRO_LOGIN_PATH } from '../../../utils/const';
import LoginLayout from '../../layouts';

const Accueil = ({
  tokenExpired,
  loaded,
  logout,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();


  const handleSubmitMedcin = () => {
    navigate(PRO_LOGIN_PATH);
  };

  const handleSubmitPatient = () => {
    navigate(PATIENT_LOGIN_PATH);
  };

  useEffect(() => {
    if (tokenExpired) {
      loaded();
      logout();
    }
  }, [tokenExpired]);

  return (
    <LoginLayout>
      <div className={'Acceuil-Card-Container'}>
        <div className={'Acceuil-Header-Content'}>
          <p>{t('accueil_connect')}</p>
        </div>
        <div className={'Acceuil-content-body'}>
          <button className={'Acceuil-cardBg-button'} onClick={handleSubmitPatient} type="button">
            <div className={'Acceuil-Rectangle'}>
              <img src={iconPatient} alt="acceuil patient" />
            </div>
            <div className={'Acceuil-minText'}>
              <p>{t('accueil_min_text')}</p>
            </div>
            <div className={'accueil-max-text'}>
              <p>{t('accueil_patient_text')}</p>
            </div>
          </button>
          <button className={'Acceuil-cardBg-button'} onClick={handleSubmitMedcin} type="button">
            <div className={'Acceuil-Rectangle'}>
              <img src={iconMedecin} alt="acceuil pro" />
            </div>
            <div className={'Acceuil-minText'}>
              <p>{t('accueil_medecin_min_text')}</p>
            </div>
            <div className={'accueil-max-text'}>
              <p>{t('accueil_medecin_text')}</p>
            </div>
          </button>
        </div>
      </div>
    </LoginLayout>
  );
};

export default Accueil;