import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';

import appReducers, { rootSaga } from '../redux';
import { loadState, saveState } from '../utils/storage';

const sagaMiddleware = createSagaMiddleware();

// Retrieves state from local storage
const persistedState = loadState();

const otherMiddlewares = [];

// Configure store middleware
const middleware = [
  // Info: Apply sagaMiddleware in first !
  applyMiddleware(
    sagaMiddleware,
    ...otherMiddlewares,
  ),
].concat(
  process.env.NODE_ENV === 'development'
    ? [
      window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : compose,
    ]
    : [],
);

// Create store
const store = createStore(
  combineReducers({ ...appReducers }),
  persistedState,
  compose(...middleware),
);

// Each sagas are isolated
// This ensure that the sagas will not be canceled if one failed
// (Be aware that should be a source of performance leak)
rootSaga.forEach((saga) =>
  sagaMiddleware.run(function* () {
    yield saga;
  }),
);

// When redux change occures
store.subscribe(() => {
  // Save state when changes occurred
  const { auth, user, medic, display, patient } = store.getState();
  saveState({
    auth,
    user,
    patient,
    medic,
    display,
  });
});

export default store;
