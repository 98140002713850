import { methods } from '../utils';
import { TOKEN_ACTIONS } from '../utils/enum';
import { insertParam } from '../utils/router-helper';
import { parseDate } from './datefns';
import { authQueries } from './GraphQLRequest';
import { authenticatedQuery, authenticatedService, service } from './middleware';

const PROXY_API_URL = process.env.REACT_APP_PROXY_API || process.env.REACT_APP_BASE_API_URL || '/api';
const { POST, GET, PUT } = methods;

const routes = {
  QRConnect: (id) => `${PROXY_API_URL}/V2/surveys/${id}/qr-connect`,
  authorize: `${PROXY_API_URL}/auth/authorize`,
  logout: `${PROXY_API_URL}/auth/logout`,
  otpCode: `${PROXY_API_URL}/auth/otp-code`,
  getToken: `${PROXY_API_URL}/auth/token`,
  getCode: `${PROXY_API_URL}/V2/users/pwd/forgot`,
  changePwd: `${PROXY_API_URL}/V2/users/pwd/renew`,
  checkCode: `${PROXY_API_URL}/V2/users/code/verify`,
  createPwd: `${PROXY_API_URL}/V2/users/pwd`,
  getIP: `${PROXY_API_URL}/V2/users/current_ip`,
  getNewToken: `${PROXY_API_URL}/auth/get_new_token`,
  loginInfo: `${PROXY_API_URL}/V2/users/current`,
  attachUserCodeRequest: `${PROXY_API_URL}/V2/users/attach/code`,
  attachUserCodeVerify: `${PROXY_API_URL}/V2/users/attach/verify`,
};

const generateState = () => {
  const validChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let array = new Uint8Array(40);
  window.crypto.getRandomValues(array);
  array = array.map((x) => validChars.codePointAt(x % validChars.length));
  const randomState = String.fromCharCode.apply(null, array);
  return randomState;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  logout: () => authenticatedService(POST, routes.logout),
  authorizationRequest: ({ username, password }) =>
    service(
      GET,
      routes.authorize,
      {},
      {
        prompt: 'none',
        response_type: 'code',
        redirect_uri: process.env.REACT_APP_BASE_API_URL,
        client_id: process.env.REACT_APP_CLIENT_ID,
        state: generateState(),
      },
      { Authorization: `Basic ${btoa(`${username}:${password}`)}` },
    ),
  getToken: ({ username, password, code, otp, uuid }) => {
    const newUrlParams = insertParam(new URLSearchParams(), [
      { key: 'grant_type', value: 'authorization_code' },
      { key: 'client_id', value: process.env.REACT_APP_CLIENT_ID },
      { key: 'code', value: code },
      { key: 'redirect_uri', value: process.env.REACT_APP_BASE_API_URL },
    ]);
    return service(
      POST,
      routes.getToken,
      newUrlParams.toString(),
      { otp, uuid },
      { Authorization: `Basic ${btoa(`${username}:${password}`)}` },
    );},
  refreshToken: ({ authorization, refresh_token }) => {
    const newUrlParams = insertParam(new URLSearchParams(), [
      { key: 'grant_type', value: 'refresh_token' },
      { key: 'refresh_token', value: refresh_token },
      { key: 'client_id', value: process.env.REACT_APP_CLIENT_ID },
      { key: 'client_secret', value: process.env.REACT_APP_CLIENT_SECRET },
    ]);
    return service(
      POST,
      routes.getToken,
      newUrlParams.toString(),
      {},
      // { Authorization: 'Basic ' + authorization },
    );
  },
  getOtpCode: ({ username, password }) =>{
    return service(
      POST,
      routes.otpCode,
      {},
      {},
      { Authorization: `Basic ${btoa(`${username}:${password}`)}` },
    );
  },
  getCode: ({ username, isPro, email, phoneNumber, birthdate }) =>
    service(
      GET,
      routes.getCode,
      {},
      { username, isPro, email, phoneNumber, birthdate: birthdate ? parseDate(birthdate).toISOString() : null },
      {},
    ),
  changePwd: ({ password, code, uuid }) =>
    service(
      PUT,
      routes.changePwd,
      { password, code, uuid, action: TOKEN_ACTIONS.forgotPassword },
      {},
      {},
    ),
  checkCode: ({ code, uuid, action }) => service(POST, routes.checkCode, { code, uuid, action }, {}, {}),
  createPwd: ({ newPwd }) =>
    authenticatedService(
      POST,
      routes.createPwd,
      { password: newPwd },
    ),
  QRConnect: (id) =>
    authenticatedService(
      POST,
      routes.QRConnect(id),
    ),
  getLoginInfo: () => authenticatedService(GET, routes.loginInfo, {}, {}),
  getLoginInfoByUserId: (userId, token) => authenticatedQuery(authQueries.loginInfoByUserId(), {}, { userId }, { token }),
  getLoginInfoFromToken: (value) => authenticatedQuery(authQueries.getLoginInfoFromToken(), {}, { value }),
  cgu: () => authenticatedQuery(authQueries.cgu()),
  ip: () => authenticatedService(GET, routes.getIP),
  deleteToken: () => authenticatedService(POST, routes.getNewToken, {}),
  attachUserCodeRequest: (username, password) => authenticatedService(POST, routes.attachUserCodeRequest, { username, password }),
  attachUserCodeVerify: (username, password, code, uuid, action = TOKEN_ACTIONS.attachUserAccount) => authenticatedService(POST, routes.attachUserCodeVerify, { username, password, code, uuid, action }),
};
