import _ from 'lodash';
import { connect } from 'react-redux';

import { AuthActions } from '../../../../redux/auth';
import { FormsActions } from '../../../../redux/forms';
import { SnackActions } from '../../../../redux/snackBar';
import { SplashActions } from '../../../../redux/splash';
import Auth from './Auth';

const mapStateToProps = ({ auth, forms }) => {
  return {
    oauthOtpUuid: auth?.oauth?.uuid,
    isCodeValid: auth.isCodeValid && auth.isCodeValid === true,
    lastSmsSent: auth?.sms?.lastSent,
    smsSentQty: auth?.sms?.sentQty,
    formsData: _.get(forms.data, 'ModalRenew'),
  };
};

const mapDispatchToProps = (dispatch) => ({
  authorizationRequest: (username, password) => dispatch(AuthActions.authorizationRequest(username, password)),
  loginFromOtpRequest: (otp) => dispatch(AuthActions.loginFromOtpRequest(otp)),
  loginRequest: (username, password) => dispatch(AuthActions.loginRequest(username, password)),
  logout: () => dispatch(AuthActions.logout()),
  notImplemented: () => dispatch(SnackActions.displayWarning('[not yet implemented]')),
  omitData: (key) => dispatch(FormsActions.omitData(key)),
  setRedirectPayload: (payload) => dispatch(SplashActions.setRedirectPayload(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
