import { createAction } from '../../utils';

// Types
export const types = {
  RESET_REDUCER: 'medic_RESET_REDUCER',

  LOADING: 'LOADING',
  LOADED: 'LOADED',
};

// Actions
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  loaded: () => createAction(types.LOADED),

  loading: () => createAction(types.LOADING),
};
