import { connect } from 'react-redux';

import { SnackActions } from '../../../redux/snackBar';
import { MEDIC_ROLES } from '../../../utils';
import NoMatch from './NoMatch';


const mapStateToProps = ({ auth }) => {
  return {
    isUserLoggedIn: auth.isUserLoggedIn,
    isPro: MEDIC_ROLES.includes(auth.user?.role),
  };
};

const mapDispatchToProps = (dispatch) => ({ displayWarning: (message) => dispatch(SnackActions.displayWarning(message)) });

export default connect(mapStateToProps, mapDispatchToProps)(NoMatch);
