import './style.css';

import parse from 'html-react-parser';
import React from 'react';

import { ModalButtons } from '../buttons';

const QuestionBasicsTextSuccess = ({ onValidate, text }) => {
  const handleOnValidate = () => {
    if (onValidate) {
      onValidate();
    }
  };

  return (
    <>
      {parse("<p class='ec-question-basics-common-text'>" + text + '</p>')}
      <ModalButtons firstText="Continuer" onFirstClick={handleOnValidate} hideSecond />
    </>
  );
};

export default QuestionBasicsTextSuccess;