
import React, { forwardRef, useImperativeHandle } from 'react';

import { CONTENT_TYPE_MAPPER } from '../../../utils/enum';

const FileUploader = (
  {
    buttonClassName,
    buttonTitle,
    buttonTitleClassName,
    handleFile,
    accept,
    uploadIcon,
    iconInside,
    iconClassname,
    disabled,
    tooltip,
    onConfirm,
    label,
  },
  ref,
) => {
  useImperativeHandle(ref, () => ({
    toggleInput() {
      hiddenFileInput.current.click();
    },
  }));

  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef(null);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = (event) => {
    if (onConfirm) {
      onConfirm();
    } else if (!disabled) hiddenFileInput.current.click();
  };

  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = (event) => {
    const selectedFiles = Array.from(event?.target?.files || []);
    const hasError = selectedFiles.some(file => !(file.type && accept && accept.includes(file.type)));
    const fileUploaded = event.target.files[0];
    !hasError && handleFile(fileUploaded);
  };

  return (
    <div className={`action-wrapper p-0 ${!disabled && 'is-active'}`}>
      { uploadIcon && !iconInside ? (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
        <img src={uploadIcon} onClick={handleClick} className={iconClassname || ''} data-tip={!disabled ? tooltip : ''} alt="upload" />
      ) : (
        <button className={buttonClassName} onClick={handleClick} data-tip={tooltip} type="button">
          {iconInside && uploadIcon && <img src={uploadIcon} alt="upload" />}
          <p className={buttonTitleClassName}>{buttonTitle}</p>
        </button>
      )}
      <input
        type="file"
        accept={accept != null ? accept : CONTENT_TYPE_MAPPER.PDF}
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: 'none' }}
        disabled={disabled}
      />
      {label && <p>{label}</p>}
    </div>
  );
};

export default forwardRef(FileUploader);
