/* eslint-disable jsx-a11y/no-onchange */
import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.css';

import {
  eachDayOfInterval,
  eachMonthOfInterval,
  endOfWeek,
  endOfYear,
  format,
  getYear,
  startOfWeek,
  startOfYear,
} from 'date-fns';
import { fr } from 'date-fns/locale';
import PropTypes from 'prop-types';
import React, { useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useController, useFormContext, useWatch } from 'react-hook-form';
import ReactInputMask from 'react-input-mask';

import { parseDate } from '../../../../../services/datefns';
import { PRESET } from '../../preset/preset';

const now = new Date();

const months = eachMonthOfInterval({
  start: startOfYear(now),
  end: endOfYear(now),
}).map((d) => format(d, 'LLLL', { locale: fr }));

const days = eachDayOfInterval({
  start: startOfWeek(now),
  end: endOfWeek(now),
}).map((d) => format(d, 'EEEEEE', { locale: fr }));

const DatePicker = ({
  control,
  name,
  rules,
  inputPreset,
  labelClassName,
  className,
  disabled,
  minDate : minDateProps,
  maxDate : maxDateProps,
  containerClassName,
  dateFormat = 'dd/MM/yyyy',
}) => {
  const [selectedDate, setSelectedDate] = useState();
  const [minDate, setMinDate] = useState(minDateProps);
  const [maxDate, setMaxDate] = useState(maxDateProps);
  const [yearsRange, setYearsRange] = useState([]);
  const datePickerRef = useRef(null);
  const methods = useFormContext(); // retrieve all hook methods
  const {
    field: { ref, value, onChange, ...field }, // field : { onChange, onBlur, value, name: fieldName, ref, ...field },
    fieldState: { error }, // fieldState: { invalid, isTouched, isDirty, error },
    // formState: { errors, isDirty, isSubmitting, touched, submitCount },
  } = useController({
    name,
    control: control || methods.control,
    rules: { ...rules },
  });
  const fieldValue = useWatch({
    control,
    name,
  });

  useImperativeHandle(ref, () => ({
    focus: () => {
      datePickerRef.current.setOpen(true);
      datePickerRef.current.input.focus();
    },
  }));
  const preset = useMemo(() => PRESET[inputPreset]?.preset || {});

  useEffect(() => {
    setMinDate(minDateProps || preset?.minDate);
    setMaxDate(maxDateProps || preset?.maxDate);
  }, [preset, minDateProps, maxDateProps]);

  useEffect(() => {
    let newDateValue = fieldValue;
    if (typeof newDateValue === 'string' || newDateValue instanceof String) {
      newDateValue = (newDateValue.length ? parseDate(fieldValue) : undefined);
      onChange(newDateValue);
    }
    setSelectedDate(newDateValue);
  }, [fieldValue]);

  useEffect(() => {
    const newYearsRange = range(getYear(minDate), getYear(maxDate), 1);
    if (yearsRange.join() !== newYearsRange.join()) {
      setYearsRange(newYearsRange);
    }
  }, [minDate, maxDate]);

  const locale = {
    ...fr,
    localize: {
      day: (n) => days[n],
      month: (n) => months[n],
    },
  };

  return (
    <div className={`${containerClassName || 'customDatePicker'}`}>
      <ReactDatePicker
        minDate={minDate}
        maxDate={maxDate}
        showDisabledMonthNavigation
        adjustDateOnChange
        dateFormat={dateFormat}
        selected={selectedDate}
        locale={locale}
        disabled={disabled}
        isClearable={!disabled}
        onChange={onChange}
        showMonthYearPicker={dateFormat === 'MM/yyyy'}
        {...field}
        renderCustomHeader={customHeader(yearsRange, months)}
        ref={(e) => {
          ref(e);
          datePickerRef.current = e; // you can still assign to ref
        }}
        customInput={<CustomInput name={name} dateFormat={dateFormat} error={error} className={disabled ? 'inputDisabled' : ''} />}
      />
    </div>
  );
};


const range = (start, stop, step) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

const CustomInput = React.forwardRef(({ name, dateFormat, error, className, ...props }, ref) => {
  const customInputRef = useRef(null);
  return (
    <ReactInputMask
      mask={dateFormat === 'dd/MM/yyyy' ? 'd9/m9/ab99' : 'm9/ab99'}
      formatChars={{
        'a': '[1-2]',
        'b': '[90]',
        'm': '[0-1]',
        'd': '[0-3]',
        '9': '[0-9]',
      }}
      {...props}
    >
      {(inputMaskProps) => (
        <input
          type="text"
          className={`${className} react-datepicker-ignore-onclickoutside`}
          ref={(e) => {
            ref(e);
            customInputRef.current = e; // you can still assign to ref
          }}
          aria-invalid={error ? 'true' : 'false'}
          key={`inputDatePicker_${name}`}
          {...inputMaskProps}
        />
      )}
    </ReactInputMask>
  );
});

const customHeader = (yearsRange, months) => ({
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}) => (
  <div
    style={{
      margin: 10,
      display: 'flex',
      justifyContent: 'center',
    }}
  >
    <button type="button" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
      {'<'}
    </button>
    <select
      value={date.getFullYear()}
      onChange={({ target: { value } }) => changeYear(value)}
    >
      { yearsRange.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      )) }
    </select>
    <select
      value={months[date.getMonth()]}
      onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
    >
      { months.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      )) }
    </select>
    <button type="button" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
      {'>'}
    </button>
  </div>
);

DatePicker.propTypes = {
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  rules: PropTypes.object,
  inputPreset: PropTypes.string,
  labelClassName: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
};

export default DatePicker;