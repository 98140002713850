import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import GlobalLoader from '../../ui/components/loaders/GlobalLoader';

function Redirect({ redirectUrl, clearRedirectUrl }) {
  const navigate = useNavigate();

  useEffect(() => {
    if (redirectUrl) {
      const url = redirectUrl;
      clearRedirectUrl();
      navigate(url, { replace:true });
    }
  }, [redirectUrl]);

  return (
    <>
      <GlobalLoader />
      <Outlet/>
    </>
  );
}

Redirect.propTypes = { redirectUrl:PropTypes.string, clearRedirectUrl: PropTypes.func.isRequired };

export default Redirect;

