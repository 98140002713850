import './style.css';

import _ from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AbilityContext } from '../../../services/casl/Can';
import { MODEL_NAMES, PERMISSIONS, SCREENS } from '../../../services/casl/roles';
import { TEMPLATE_TYPE } from '../../../utils';
import { ALL } from '../../../utils/const';
import { filterAndOrderList } from '../../../utils/format';
import AuthLayout from '../../layouts/authLayout';
import Filters from './filters';
import List from './list';
import SearchBarForm from './SearchBarForm';

const MEDIC_TEMPLATES = 0;
const STORE_TEMPLATES = 1;
const INITIAL_SELECTED_FILTERS = {
  speciality: [ALL],
  surveyType: [ALL],
};

const Forms = ({
  types,
  specialities,
  surveyTypesRequest,
  createMedicTemplatesFromStoreId,
  medicUnactivateTemplateRequest,
  specialitiesRequest,
  medicTemplateDeleteRequest,
  medicTemplatesGetRequest,
  selectedTeam,
  medicTemplateList,
  storeTemplatesGetRequest,
  storeTemplateList,
  uploadedTemplateIds,
  displayInfo,
  currentMedic,
  isStoreTemplate,
}) => {
  const DEFAULT_DISPLAY_MODE = isStoreTemplate ? STORE_TEMPLATES : MEDIC_TEMPLATES;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const ability = useContext(AbilityContext);
  const [displayMode, setDisplayMode] = useState(DEFAULT_DISPLAY_MODE);
  const [selectedFilters, setSelectedFilters] = useState(INITIAL_SELECTED_FILTERS);
  const [searchedIdList, setSearchedIdList] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const [initialLists, setInitialLists] = useState({
    [MEDIC_TEMPLATES]: [],
    [STORE_TEMPLATES]: [],
  });
  const [filteredTemplatesList, setFilteredTemplatesList] = useState([]);

  const [displayedList, setDisplayedList] = useState([]);
  const [chunk, setChunk] = useState([]);

  const canAccesStore = useMemo(() => {
    return ability.can(PERMISSIONS.ACCESS, { __typename: MODEL_NAMES.SCREENS, value: SCREENS.STORE.value });
  }, [ability]);

  useEffect(() => {
    // Runs after the first render() lifecycle
    if (surveyTypesRequest) surveyTypesRequest();
    if (specialitiesRequest) specialitiesRequest();
  }, []);

  useEffect(() => {
    // Runs after the first render() lifecycle
    if (storeTemplatesGetRequest && canAccesStore) storeTemplatesGetRequest();
  }, [canAccesStore]);

  useEffect(() => {
    // Runs after the first render() lifecycle
    setIsLoading(true);
    if (selectedTeam) {
      setSelectedFilters(INITIAL_SELECTED_FILTERS);
      setDisplayMode(DEFAULT_DISPLAY_MODE);
      if (medicTemplatesGetRequest) medicTemplatesGetRequest(selectedTeam);
    }
  }, [selectedTeam]);

  useEffect(() => {
    setSelectedFilters(INITIAL_SELECTED_FILTERS);
    setSearchedIdList([]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayMode]);

  useEffect(() => {
    setChunk([]);
    setInitialLists({
      [MEDIC_TEMPLATES]: medicTemplateList || [],
      [STORE_TEMPLATES]: (canAccesStore && storeTemplateList) || [],
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(storeTemplateList), JSON.stringify(medicTemplateList), canAccesStore]);

  useEffect(() => {
    // reduce list base on filters
    // fct inside useEffect to avoid rendering loop
    const filterListAction = (elList, filtersList) => {
      const { surveyType, speciality } = filtersList;
      if (!speciality.includes(ALL)) {
        elList = elList.filter((el) => speciality.includes(el.speciality_id));
      }
      if (!surveyType.includes(ALL)) {
        elList = elList.filter((el) => surveyType.includes(el.type_id));
      }
      return elList;
    };
    // when filter is changing
    const newTemplates = filterListAction(initialLists[displayMode], selectedFilters);
    setFilteredTemplatesList([...newTemplates]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(selectedFilters), JSON.stringify(initialLists), displayMode]);


  useEffect(() => {
    // reduce list base on search
    const newDisplayedList = filterAndOrderList(filteredTemplatesList, searchedIdList);
    setDisplayedList(newDisplayedList);
  }, [searchedIdList, filteredTemplatesList]);

  useEffect(() => {
    setIsLoading(false);
    if (Array.isArray(displayedList)) {
      const list = [...displayedList.filter(el => el.isNew === true), ...displayedList.filter(el => !el.isNew)];
      setChunk(_.chunk(list, 10));
    }
    return function cleanUp(){
      setChunk();
    };
  }, [displayedList]);

  const onBackClick = () => {
    navigate(`/teams/${selectedTeam}`);
  };

  const handleSurveyClick = (id, mode) => {
    switch (mode) {
      case 'preview':
        navigate(`/teams/${selectedTeam}/createform/${id}`);
        break;
      case 'add':
        if ( createMedicTemplatesFromStoreId) {
          createMedicTemplatesFromStoreId( id );
        }
        break;
      case 'delete':
        const template = medicTemplateList.find((t) => t.id === id);
        if (template.store_id > 0 && medicUnactivateTemplateRequest) {
          medicUnactivateTemplateRequest(id);
        } else if (medicTemplateDeleteRequest) {
          medicTemplateDeleteRequest(id);
        }
        break;
      default:
    }
  };

  const handleSelectFilter = (receivedFilter) => {
    const { type, id: newFilter } = receivedFilter;
    let newList = selectedFilters[type];
    if (newFilter === ALL) {
      newList = [ALL];
    } else {
      if (selectedFilters[type].includes(ALL)) {
        // if current filter is all => replace with selected filter
        newList = [newFilter];
      } else {
        if (Array.isArray(selectedFilters[type])) {
          if (newList.some(v => `${v}` === `${newFilter}`)) {
            newList = newList.filter(v => v !== newFilter);
            if (!newList.length){
              newList = [ALL];
            }
          } else {
            newList = [...newList, newFilter];
          }
        }
      }
    }
    const newSelected = {
      ...selectedFilters,
      [type]: newList,
    };

    if (!_.isEqual(newSelected, selectedFilters)) {
      setIsLoading(true);
      setSelectedFilters(newSelected);
    }
  };

  const handleSearch = (result) => {
    const newSearchIdList = Array.isArray(result) ? result.map(el => el.item.id) : null;
    if (!_.isEqual(newSearchIdList, searchedIdList)) {
      setIsLoading(true);
      setSearchedIdList(newSearchIdList);
    }
  };

  const handleDisplayMode = (isStoreTemplate) => {
    const newDisplayMode = DEFAULT_DISPLAY_MODE;
    if (newDisplayMode !== displayMode) {
      setIsLoading(true);
      setDisplayMode(newDisplayMode);
    }
    navigate(`/teams/${selectedTeam}/${isStoreTemplate ? 'store' : 'forms'}`);
  };

  return (
    <AuthLayout>
      <div className={'form-screen'}>
        <Filters
          types={types}
          specialities={specialities}
          selectedFilters={selectedFilters}
          checked={displayMode === STORE_TEMPLATES}
          onSwitchChange={handleDisplayMode}
          onSelectFilter={handleSelectFilter}
          onBackClick={onBackClick}
          selectedTeam={selectedTeam}
          displayInfo={displayInfo}
          list={initialLists[displayMode] || []}
          canAccesStore={canAccesStore}
        />
        <div className={'form-screen-medic-form-list'}>
          <div className={'form-screen-medic-form-search'}>
            <SearchBarForm
              onSearch={handleSearch}
              placeholder={t(
                `form_card_search_placeholder_${
                  displayMode === MEDIC_TEMPLATES ? TEMPLATE_TYPE.MEDIC : TEMPLATE_TYPE.STORE
                }`,
              )}
              list={filteredTemplatesList || []}
              keys={['displayTranslation.title']}
            />
          </div>
          <List
            chunk={[...chunk]}
            onClick={handleSurveyClick}
            isTemplateStore={displayMode === STORE_TEMPLATES}
            uploadedTemplateIds={uploadedTemplateIds}
            isLoading={isLoading}
            types={types}
          />
        </div>
      </div>
    </AuthLayout>
  );
};

export default Forms;
