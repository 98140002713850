import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { capitalizeFirstLetter } from '../../../../utils/utils';
import ModalButton from '../../formV3/submit/ModalButton';
import Modal from '../../modal/Modal';
import ModalBtnContainer from '../../modal/ModalBtnContainer/ModalBtnContainer';
import ButtonChoice from '../ButtonChoice';
import ContactFormContainer from '../Steps/ContactFormContainer';
import InfosFormContainer from '../Steps/InfosFormContainer';

const MAX_TUTORS = 2;
const IS_DEBUG = process.env.NODE_ENV === 'development' && !!process.env.REACT_APP_DEBUG;

const CreatePatient = ({ onCloseModal, isModalOpen, selectedMedicTeam, currentMedicId, clearData, patientsCreateRequest }) => {
  const { t } = useTranslation();
  const [title, setTitle] = useState();
  const [formStep, setFormStep] = useState(0);
  const [formStepList, setFormStepList] = useState([0]);
  const [formValues, setFormValues] = useState({});

  useEffect(() => {
    const last = formStepList[formStepList.length - 1];
    if ( last !== formStep){
      setFormStepList([...formStepList, formStep]);
      handleTitle(formStep);
    }
  }, [formStep]);

  useEffect(() => {
    clearData && clearData(); // clear data on component mount
    handleTitle(formStep);
  }, []);

  const completeFormStep = async (newVal, key) => {
    if (newVal){
      const newFormValues = _.set(formValues, key, newVal);
      setFormValues(newFormValues);
    }
    setFormStep(cur => (newVal === parseInt(newVal, 10) ? newVal : cur + 1));
  };

  const previousFormStep = () => {
    const lastIndex = formStepList.length - 1;
    setFormStep(formStepList[formStepList.length - 2]);
    setFormStepList(formStepList.filter((_item, index) => index !== lastIndex));
  };

  const handleSubmit = (val, key) => {
    const newFormValues = _.set(formValues, key, val);
    const { patient, tutors } = newFormValues;
    const newPatientList = (Array.isArray(tutors) ? tutors : [])
      .concat(patient)
      .filter(el => el !== null)
      .map((data) => {
        return {
          is_tutor: _.get(data, 'info.is_tutor', false),
          gender: _.get(data, 'info.gender'),
          firstname: _.get(data, 'info.firstname'),
          lastname: _.get(data, 'info.lastname'),
          use_name: _.get(data, 'info.use_name'),
          security_number: _.get(data, 'info.security_number', ''),
          birthdate: _.get(data, 'info.birthdate'),
          birthplace_id: _.get(data, 'info.birthplace_id.value'),
          birthplace:  _.get(data, 'info.birthplace'),
          birthplace_postcode:  _.get(data, 'info.birthplace_postcode', '').replace(/_/g, '').replace(/\s/g, ''),
          city_id: _.get(data, 'contact.city_id.value'),
          city:_.get(data, 'contact.city'),
          postcode: _.get(data, 'contact.postcode', '').replace(/_/g, '').replace(/\s/g, ''),
          phone_number: _.get(data, 'contact.phone_number'),
          email: _.get(data, 'contact.email'),
          medic_id: currentMedicId,
          medical_teams_id: selectedMedicTeam.id,
          language_id: _.get(data, 'contact.language_id'),
        };
      });
    patientsCreateRequest(newPatientList);
    handlCloseModal();
  };

  const handlCloseModal = () => {
    clearData();
    setFormStep(0);
    if (onCloseModal) onCloseModal();
  };

  const handleTitle = (step) => {

    const medicTeamTitle = selectedMedicTeam && selectedMedicTeam.title && capitalizeFirstLetter(selectedMedicTeam.title);
    if (step >= 10){
      setTitle(selectedMedicTeam ? t('patientForms.modal.create_tutor.titleWithTeam', { medicTeamTitle: medicTeamTitle }) : t('patientForms.modal.create_tutor.title'));
    } else {
      setTitle(selectedMedicTeam ? t('patientForms.modal.create_patient.titleWithTeam', { medicTeamTitle: medicTeamTitle }) : t('patientForms.modal.create_patient.title'));
    }
  };

  function _renderStepContent(stepIndex) {
    let number;
    switch (true) {
      case stepIndex === 0:
        return (
          <InfosFormContainer
            defaultValues={_.get(formValues, 'patient.info')}
            onSubmit={(val) => completeFormStep(val, 'patient.info')}
            onCancel={handlCloseModal}
            id={'patient.info'}
            debug={IS_DEBUG}
          />
        );
      case stepIndex === 1:
        return (
          <>
            <ButtonChoice onPatientClick={completeFormStep} onTutorClick={() => completeFormStep(10)} onCancel={previousFormStep}/>
            <ModalBtnContainer>
              <ModalButton
                label={t('form.button.previous.label')}
                type="button"
                onClick={() => previousFormStep()}
                variant="ghost"
              />
            </ModalBtnContainer>
          </>
        );
      case stepIndex === 2:
        return (
          <ContactFormContainer
            defaultValues={_.get(formValues, 'patient.contact')}
            onSubmit={(val) => handleSubmit(val, 'patient.contact')}
            onCancel={previousFormStep}
            id={'patient.contact'}
            debug={IS_DEBUG}
          />
        );
      case stepIndex >= 10 && (stepIndex % 2 === 0): //check if the number is even 10, 12
        number = (stepIndex - 10) / 2 ;
        return (
          <InfosFormContainer
            defaultValues={_.get(formValues, `tutors.${number}.info`)}
            onSubmit={(val) => completeFormStep(val, `tutors.${number}.info`)}
            onCancel={previousFormStep}
            id={`tutors.${number}.info`}
            isTutor={true}
            debug={IS_DEBUG}
          />
        );
      case stepIndex >= 10 && (stepIndex % 2 === 1): // if the number is odd 11, 13
        number = (stepIndex - 11) / 2 ;
        return (
          <ContactFormContainer
            defaultValues={_.get(formValues, `tutors.${number}.contact`)}
            onSubmit={(val) => handleSubmit(val, `tutors.${number}.contact`)}
            onCancel={previousFormStep}
            id={`tutors.${number}.contact`}
            debug={IS_DEBUG}
            isNotFinalStep={number < MAX_TUTORS -1}
            onAddTutor={(val) => completeFormStep(val, `tutors.${number}.contact`)}
          />
        );
      default:
        return <div>Not Found</div>;
    }
  }

  return (
    <Modal
      title={title || `step ${formStep}`}
      isOpen={isModalOpen}
      onClose={() => handlCloseModal()}
      scrollable
      style={{ marginTop: '0px' }}
    >
      <div>
        { _renderStepContent(formStep) }
      </div>
    </Modal>
  );
};

CreatePatient.propTypes = { title: PropTypes.string };

export default CreatePatient;
