import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IconDeleteForm } from '../../../../../../assets/img/icons';
import ConfirmBtn from '../../../../ConfirmBtn';
import { ACTIONS } from '../../DocumentChoice';
import IconEditRender from '../../IconEditRender';
import IconSeeRender from '../../IconSeeRender';
import ShowActionCounterSign from '../../ShowActionCounterSign';

export function ActionsOnSignedConsent({
  survey,
  index,
  onClickEdit,
  onClickVoir,
  onClickCounterSign,
  canCountersign,
  onClick,
  hasNotDisplayed,
  disabled,
}) {
  const [showConfirm, setShowConfirm] = useState(false);
  const { t } = useTranslation();
  const status = survey.status;

  const handleConfirmClick = (_e, a) => {
    switch (a) {
      case 'delete':
        onClick('delete', null);
        setShowConfirm(false);
        break;
      case 'modal':
      default:
        setShowConfirm(false);
        break;
    }
    setShowConfirm(false);
  };

  if (hasNotDisplayed) return null;

  return (
    <>
      <IconEditRender
        element={survey}
        index={index}
        onClick={onClickEdit}
      />
      <IconSeeRender
        element={survey}
        index={index}
        status={status}
        onClick={onClickVoir}
      />
      <ShowActionCounterSign
        canCountersign={canCountersign}
        element={survey}
        status={status}
        index={index}
        referent={survey?.referent}
        onClick={onClickCounterSign}
        disabled={disabled}
      />
      <ConfirmBtn
        title={t('confirm_delete_template')}
        onConfirm={handleConfirmClick}
        action={ACTIONS.DELETE}
        isOpen={showConfirm}
      >
        <img
          className={'form-list-delete'}
          src={IconDeleteForm}
          data-tip={t('medicPatient.history.list.actionBtn.delete')}
          alt="patient_button_delete"
        />
      </ConfirmBtn>
    </>
  );
}

ActionsOnSignedConsent.default = {};

ActionsOnSignedConsent.propTypes = {
  survey: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  onClickEdit: PropTypes.func.isRequired,
  onClickVoir: PropTypes.func.isRequired,
  onClickCounterSign: PropTypes.func.isRequired,
  hasNotDisplayed: PropTypes.bool,
  disabled: PropTypes.bool.isRequired,
};

