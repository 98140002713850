import React, { useEffect, useRef, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import { ModalButtons } from '../../../components/buttons';
import Modal from '../../../components/modal';
import List from './List';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const reorderQuoteMap = ({ quoteMap, source, destination }) => {
  const current = [...quoteMap[source.droppableId]];
  const next = [...quoteMap[destination.droppableId]];
  const target = current[source.index];
  // moving to same list
  if (source.droppableId === destination.droppableId) {
    const reordered = reorder(current, source.index, destination.index);
    const result = {
      ...quoteMap,
      [source.droppableId]: reordered,
    };
    return result; // { quoteMap: result };
  }

  // moving to different list

  // remove from original
  current.splice(source.index, 1);
  // insert into next
  next.splice(destination.index, 0, target);

  const result = {
    ...quoteMap,
    [source.droppableId]: current,
    [destination.droppableId]: next,
  };

  return result; // { quoteMap: result };
};

const Sortable = ({ list, onExit, onFinish, deletableItems }) => {
  const { t } = useTranslation();
  const [data, setData] = useState(list || []);
  const listRef = useRef(null);

  useEffect(() => {
    if (list && !data.length) setData(list);
  }, [list]);

  const onDragEnd = (result) => {
    const { destination, source, type } = result;
    // dropped nowhere
    if (!destination) {
      return;
    }

    // did not move anywhere - can bail early
    if ( source.droppableId === destination.droppableId && source.index === destination.index ) {
      return;
    }

    if (type === 'list') {
      // reordering column
      setData({
        ...data,
        columnOrder: reorder(Array.from(data.columnOrder), source.index, destination.index),
      });
      return;
    }

    setData({
      ...data,
      itemsOrder: reorderQuoteMap({
        quoteMap: data.itemsOrder,
        source,
        destination,
      }),
    });
  };

  const handleOnExit = (event) => {
    if (onExit) onExit(event);
  };

  const handleOnFinish = () => {
    if (listRef && listRef.current && listRef.current.getItem() !== '') onAddItem(listRef.current.getItem());
    if (onFinish) onFinish(data);
  };

  const onDeleteItem = (id) => {
    const listId = id.split('-')[0];
    const newItems = data.items;
    const newLists = data.lists;
    delete newItems[id];
    newLists[listId].itemsIds.splice(newLists[listId].itemsIds.indexOf(id), 1);
    setData({ ...data, items: newItems, lists: newLists });
  };

  const onAddItem = (newItem) => {
    let newItems = data.items;
    const newLists = data.lists;
    const itemsKeys = Object.keys(newItems);
    const lastId = parseInt(itemsKeys[itemsKeys.length - 1].split('-')[1]);
    const newId = `0-${lastId + 1}`;
    newItems[newId] = { id: newId, content: newItem };
    newLists['0'].itemsIds.push(`${newId}`);
    setData({ ...data, items: newItems, lists: newLists });
  };

  const onItemChange = (newItem, itemId) => {
    let newItems = data.items;
    newItems[itemId] = { id: itemId, content: newItem };
    setData({ ...data, items: newItems });
  };

  return (
    <Modal onClose={handleOnExit} title={t('reorder_your_form')} scrollable isOpen>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          droppableId="board"
          type="list"
          direction="vertical"
        >
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              { data.columnOrder.map((column, index) => {
                return (
                  <div key={`sortable-list-${column.id || index}`}>
                    <List
                      ref={listRef}
                      list={column}
                      items={data.itemsOrder[column.id]}
                      index={index}
                      onAddItem={onAddItem}
                      onDeleteItem={onDeleteItem}
                      deletableItems={deletableItems}
                      onItemChange={onItemChange}
                    />
                  </div>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <ModalButtons firstText={'Annuler'} secondText={'Valider'} onFirstClick={handleOnExit} onSecondClick={handleOnFinish} htmlType="button" />
    </Modal>
  );
};

export default Sortable;
