import './style.css';

import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { editLetterCaseUsers } from '../../../../utils';
import Card from '../../../components/card';

const SurveyCard = ({ survey, className }) => {
  const { t } = useTranslation();
  const medic = survey && survey.referent && editLetterCaseUsers(survey.referent);
  return (
    <Card className={className || ''}>
      <div>
        <p className="consent-recap-titles"> {survey && survey.title} </p>
      </div>
      <div className="consent-recap-medics-infos">
        <p className="consent-recap-grey-texts"> {medic ? t(`medic_title.${medic.title}`) : ''} </p>
        <p className="consent-recap-black-texts"> {medic ? `${medic.firstname} ${medic.lastname}` : ''} </p>
      </div>
      <div className="consent-recap-medics-infos">
        <p className="consent-recap-grey-texts"> {t('establishment')} </p>
        <p className="consent-recap-black-texts"> {(survey && (_.get(survey, 'clinic.name') || _.get(survey, 'clinic.address'))) || ''} </p>
      </div>
    </Card>
  );
};

export default SurveyCard;