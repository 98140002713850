import './style.css';

import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import SearchInput from './SearchInput';
import TagWrapper from './TagWrapper';

function QueryAssist(
  { onSubmit, searchStyle, tags, wrapperStyle, selectTags, dropdownStyle, placeholder },
  ref,
) {
  const { t } = useTranslation();
  const inputRef = useRef();
  const tagWrapperRef = useRef();
  const inputWrapperRef = useRef();
  const [state, setState] = useState({
    currentTags: [], //["fruit: apple", "color: black"],
    gotRidOfScrollbar: false,
    hasPlaceholder: true,
    inputText: '',
  });

  useImperativeHandle(ref, () => ({ handleCancel: () => { handleCancel(); } }));

  const handleCancel = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.handleActionCancel();
      handleChange({ target: { innerText: 'E' } });
    }
  };

  const onCancelAction = () => {
    setState({
      inputText: '',
      hasPlaceholder: true,
      currentTags: [],
    });

    onSubmit(null);
  };

  const onTagClick = (tag) => {
    let newTags = state.currentTags || [];
    newTags.push(`${tag}:`);
    setState({ ...state, currentTags: newTags });
  };

  const handleChange = (event) => {
    let str = event.target.innerText || 'E';
    if (str === '' && (state.tags || []).length === 0) {
      setState({
        ...state,
        inputText: str,
        hasPlaceholder: true,
      });
      return;
    }
    let currentTags = state.currentTags;
    for (let tag of tags) {
      let regexp;
      if (event.key === 'Enter') {
        //When `Enter` was pressed we do not have to check for a comma or a space
        regexp = new RegExp(`@${t(tag.key)} ([^ ,\u00A0]+)`);
      } else {
        regexp = new RegExp(`@${t(tag.key)} ([^ ,\u00A0]+)[,\u00A0]`);
      }

      if (regexp.test(str)) {
        // User typed a new tag, add it to currentTags
        let selected = str.match(regexp)[1];
        currentTags.push(tag.key + ': ' + selected);
        let newText = str.replace(regexp, '');
        event.target.innerText = newText;
      }
    }

    onSubmit({
      tags: currentTags,
      str: str,
    });
  };

  const handleScrollLeft = () => {
    let scrollLeft = inputWrapperRef.current.scrollLeft;
    if (scrollLeft !== 0) {
      inputWrapperRef.current.scrollLeft = Math.max(0, scrollLeft - 8);
    }
  };

  //User is done with editing tag, so save it and re-render the tags.
  const saveTag = (index, text) => {
    let currentTags = state.currentTags;
    currentTags[index] = text;
    setState({ ...state, currentTags: currentTags });
    inputRef?.current?.focus();
    tagWrapperRef.current?.focus();
    onSubmit({
      tags: currentTags,
      str: state.str,
    });
  };

  //Deletes a tag
  const deleteTag = (index) => {
    let currentTags = state.currentTags;
    currentTags.splice(index, 1);
    setState({ ...state, currentTags: currentTags });

    onSubmit({
      tags: currentTags,
      str: state.str,
    });
  };

  const removePlaceholder = () => {
    setState({
      ...state,
      hasPlaceholder: false,
      inputText: '',
    });
  };

  return(
    <div className="SearchBar TextInput" style={searchStyle}>
      <div
        id="SearchBar__InputWrapper"
        className="SearchBar__InputWrapper"
        style={wrapperStyle}
        ref={inputWrapperRef}
      >
        <TagWrapper
          currentTags={state.currentTags}
          saveTag={saveTag}
          deleteTag={deleteTag}
          selectTags={selectTags}
        />
        <SearchInput
          ref={inputRef}
          dropdownStyle={dropdownStyle}
          placeholder={placeholder}
          tags={tags}
          inputText={state.inputText}
          onTagClick={onTagClick}
          onCancelAction={onCancelAction}
          onChange={handleChange}
          removePlaceholder={removePlaceholder}
          hasPlaceholder={state.hasPlaceholder}
          handleScrollLeft={handleScrollLeft}
        />
      </div>
    </div>
  );
}

export default forwardRef(QueryAssist);

