import React, { useEffect, useState } from 'react';

import SelectCountryFlag from '../SelectCountryFlag';

export default function SurveysWithFlagCard({ showLngSelect, selectDefaultLanguageId, template, index, onSelect, onKeyPressHandler, ...rest }) {
  const [templateLanguage, setTemplateLanguage] = useState({ ...template });

  useEffect(() => {
    if (template && Array.isArray(template.translation) && selectDefaultLanguageId) {
      const languageId = template.translation.some(t => t.language_id === selectDefaultLanguageId)
        ? selectDefaultLanguageId
        : template.translation[0]?.language_id;

      handleSelectLanguage(languageId);
    }
  }, [template && template.id, selectDefaultLanguageId]);

  const handleSelectLanguage = (languageId) => {
    setTemplateLanguage({ ...templateLanguage, language_id : languageId });
  };

  return (
    <>
      {showLngSelect && (
        <SelectCountryFlag
          selectedLanguageId={templateLanguage?.language_id}
          availableLanguageIds={template?.availableLngs}
          onSelectLanguage={handleSelectLanguage}
        />
      )}
      <div
        className="listSelectField-tile"
        onClick={(e) => onSelect(e, templateLanguage)}
        onKeyPress={(e) => onKeyPressHandler(e, templateLanguage)}
        role="button"
        tabIndex={index}
        {...rest}
      >
        <div className={'medic-form-list-item-container'}>
          <p className={'medic-form-list-item-title'}>{templateLanguage?.display?.title || templateLanguage.label || templateLanguage.title || 'Pas de titre'}</p>
        </div>
      </div>
    </>
  );
}
