import { connect } from 'react-redux';

import { FormsActions } from '../../../../../redux/forms';
import { PatientActions } from '../../../../../redux/patient';
import { SurveyActions } from '../../../../../redux/survey';
import MedicCounterSignModal from './MedicCounterSignModal';

const mapStateToProps = ({ survey, forms, auth }) => {
  return {
    consentAnswers: forms?.data?.consent,
    questionTypes: survey.questionTypes || {},
    isCodeValid: auth.isCodeValid && auth.isCodeValid === true,
  };
};

const mapDispatchToProps = (dispatch) => ({
  omitData: (key) => dispatch(FormsActions.omitData(key)),
  resetSurveyCounterSigned: () => dispatch(SurveyActions.resetSurveyCounterSigned()),
  genSurveySignUrlReset: () => dispatch(SurveyActions.genSurveySignUrlSuccess(null)),
  patientDetailRequest: (patientId, selectedTeam) => dispatch(PatientActions.patientDetailRequest(patientId, selectedTeam)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MedicCounterSignModal);