import './style.css';

import PropTypes from 'prop-types';
import React from 'react';

export default function ItemChoice({ active, onClick, imgSrc, title }) {

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      onClick();
    }
  };

  return (
    <div
      className={active ? 'is-active' : undefined}
      onClick={() => (active ? onClick() : null)}
      onKeyPress={handleKeyPress}
      role="button"
      tabIndex="0"
    >
      <img src={imgSrc} className={active ? '' : 'image-disabled'} alt={title} />
      <p>{title}</p>
    </div>
  );
}

ItemChoice.propTypes = {
  active: PropTypes.bool,
  onClick: PropTypes.func,
  imgSrc: PropTypes.string,
  title: PropTypes.string,
};