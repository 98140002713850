import { connect } from 'react-redux';

import QuestionHeader from './QuestionHeader';

const mapStateToProps = ({ survey }) => {
  return { questionTypeList: survey.questionTypes || {} };
};


export default connect(mapStateToProps, null)(QuestionHeader);
