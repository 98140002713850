
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AiFillEye, AiFillEyeInvisible, AiOutlineQuestionCircle } from 'react-icons/ai';

import { digitRegex } from '../../../../../utils/regex';
import Card from '../../../card';
import { PRESET } from '../../preset/preset';
import FormIcon from '../FormIcon';
import Input from './Input';
import InputMask from './InputMask';

const InputText = ({
  name,
  inputPreset,
  showPswdHelper,
  hidden,
  isLoading,
  containerClassName,
  type: typeProps,
  onBlur,
  readOnly,
  focusOnload,
  ...props
}) => {
  const { t } = useTranslation();
  const methods = useFormContext();
  const { watch, formState: { errors, touchedFields }, setFocus } = methods;
  const [type, setType] = useState(typeProps || 'text');

  useEffect(() => {
    if (focusOnload) setFocus(name);
  }, [focusOnload]);

  const value = watch(name);
  const error = _.get(errors, name);
  const isTouched = _.get(touchedFields, name);

  useEffect(() => {
    if (typeProps && type !== typeProps) {
      setType(typeProps);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeProps]);

  const [mandatory, setMandatory] = useState([
    { message: 'password.rules.number_character', checked: false },
    { message: 'password.rules.upper', checked: false },
    { message: 'password.rules.lower', checked: false },
    { message: 'password.rules.number', checked: false },
    { message: 'password.rules.special_character', checked: false },
  ]);
  const [score, setScore] = useState(0);
  const [step, setStep] = useState(0);
  const steps = [
    { title: 'password.strength.Veryweak', color: '#D1462F' },
    { title: 'password.strength.Weak', color: '#D1462F' },
    { title: 'password.strength.Good', color: '#57B8FF' },
    { title: 'password.strength.Strong', color: '#2FBF71' },
  ];
  const [isShown, setIsShown] = useState(0);
  const preset = PRESET[inputPreset] || {};


  useEffect(() => {
    if (showPswdHelper && value && value.length){
      let newScore = 0;
      mandatory[0].checked = value.length > 11;
      mandatory[1].checked = (/[A-Z]/g).test(value);
      mandatory[2].checked = (/[a-z]/g).test(value);
      mandatory[3].checked = (digitRegex).test(value);
      mandatory[4].checked = (/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/).test(value);
      newScore = mandatory.filter(el => el.checked === true);
      newScore = Math.floor(((newScore && newScore.length) || 0) / mandatory.length * 100);
      setScore(newScore);
      setIsShown(isShown === 2 ? 0 : isShown);
      setMandatory(mandatory);
      newScore = Math.floor(newScore / 100 * steps.length);
      setStep(Math.max(newScore - 1, 0) || (score >= 1 && /([^.a-z0-9]+)/i.test(value) ? newScore + 1 : newScore));
    }
  }, [value]);

  return (
    <>
      { showPswdHelper && (
        <div className={'p-2'}>
          {mandatory.map((e, index) => (
            <div className={'d-flex'} key={`error_${index}`}>
              <span className="order-2 px-2">{t(e.message)}</span>
              <FormIcon
                className={'order-1 password-form-icon'}
                type={e.checked ? 'validated' : 'error'}
              />
            </div>
          ))}
        </div>
      )}
      <div className={`${containerClassName || 'inputFormItem-container'} position-relative`}>
        {
          (preset && 'mask' in preset)
            ? (
              <InputMask
                {...preset.mask}
                {...props}
                name={name}
                preset={preset.preset}
                type={type}
                readOnly={readOnly}
              />
            )
            : <Input
              {...props}
              name={name}
              preset={preset.preset}
              type={type}
              readOnly={readOnly}
              onBlur={onBlur}
            />
        }
        <div className="inputFormItem-container-icon" >
          <div className="d-flex flex-row justify-content-end">
            { !readOnly && !hidden && isLoading && <FormIcon type="loading" className="p-1" /> }
            { !readOnly && !hidden && !isLoading && isTouched && error && <FormIcon type="error" className="p-1" /> }
            { !readOnly && !hidden && !isLoading && isTouched && !error && <FormIcon type="validated" className="p-1" /> }
            { (inputPreset && inputPreset === 'password' )
              ? (
                <div onClick={() => setType(v => (v === 'password' ? 'text' : 'password'))} className="p-1" >
                  {
                    type !== 'password'
                      ? <AiFillEyeInvisible size={32} style={{ fill: '#79828b' }} />
                      : <AiFillEye size={32} style={{ fill: '#79828b' }} />
                  }
                </div>
              ) : null
            }
            { showPswdHelper
              ? (
                <>
                  <p className={'strength-text'} style={{ color: `${_.get(steps, [step || 0, 'color'])}` }}>{t(_.get(steps, [step || 0, 'title'], ''))}</p>
                  <div className={'authentication-forgotten-password-separator'} />
                  <AiOutlineQuestionCircle
                    className={'mark p-1'}
                    size={24}
                    style={{ fill: ((error && value !== '') || isShown) ? '#ed5248' : '#79828b' }}
                    onMouseEnter={() => setIsShown(true)}
                    onMouseLeave={() => setIsShown(false)}
                  />
                  { isShown && (
                    <div className="ec-info-bulle">
                      <Card>
                        { mandatory.map((e, index) => (
                          <div className={'password-rule'} key={`error${index}`}>
                            <p>{t(e.message)}</p>
                            <FormIcon
                              className={'password-form-icon'}
                              type={e.checked ? 'validated' : 'error'}
                            />
                          </div>
                        ))
                        }
                      </Card>
                    </div>
                  )}
                </>
              ): null
            }
          </div>
        </div>
      </div>
      { showPswdHelper && (
        <div className="strength-bar">
          <div
            className="strength-bar-colored"
            style={{ width: `${score}%`, backgroundColor: `${_.get(steps, [step || 0, 'color'])}` }}
          />
        </div>
      )}
    </>
  );
};

const areEqual = (prevProps, nextProps) => {
  return prevProps.name !== nextProps.name;
};
/*
  return true if passing nextProps to render would return the same result as passing prevProps to render, otherwise return false
*/

InputText.propTypes = {
  register: PropTypes.func,
  name: PropTypes.string,
  control: PropTypes.object,
  defaultValue: PropTypes.string,
  rules: PropTypes.object,
  inputProps: PropTypes.object,
  inputPreset: PropTypes.oneOf(['security_number', 'password', 'email', 'postcode', 'rpps_number']),
  hidden: PropTypes.bool,
  isLoading: PropTypes.bool,
  error: PropTypes.bool,
  success: PropTypes.bool,
  type: PropTypes.oneOf(['button', 'checkbox', 'color', 'date', 'datetime-local', 'email', 'file', 'hidden', 'image', 'month', 'number', 'password', 'radio', 'range', 'reset', 'search', 'submit', 'tel', 'text', 'time', 'url', 'week']),
  disabled: PropTypes.bool,
};

export default React.memo(InputText, areEqual);
