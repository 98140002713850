import { createAction } from '../../utils';

// Types
export const types = {
  RESET_REDUCER: 'city_RESET_REDUCER',

  CITIES_FETCH_ALL: 'city_CITIES_FETCH_ALL',
  CITIES_FETCH_QUERY: 'city_CITIES_FETCH_QUERY',
  CITIES_FETCH_BEGIN: 'city_CITIES_FETCH_BEGIN',
  CITIES_FETCH_SUCCESS: 'city_CITIES_FETCH_SUCCESS',
  CITIES_FETCH_FAILURE: 'city_CITIES_FETCH_FAILURE',
  CITIES_CLEAR_LIST: 'city_CITIES_CLEAR_LIST',
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  fetchCityListWithPostcode: (postcode = '') => createAction(types.CITIES_FETCH_QUERY, { postcode }),
  fetchBegin: () => createAction(types.CITIES_FETCH_BEGIN),
  fetchCityListSuccess: (cities) => createAction(types.CITIES_FETCH_SUCCESS, { cities }),
  fetchCityListFailure: () => createAction(types.CITIES_FETCH_FAILURE),

  clearCityList: () => createAction(types.CITIES_CLEAR_LIST),
  fetchAllCities: () => createAction(types.CITIES_FETCH_ALL),

  resetReducer: () => createAction(types.RESET_REDUCER),
};
