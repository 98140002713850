import './style.css';

import React from 'react';

class CustomSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultSelectText: '',
      showOptionList: !!props.showListOnMount,
      optionsList: [],
      style: '',
    };
  }

  componentDidUpdate() {
    if (this.state.defaultSelectText !== this.props.defaultText) {
      this.setState({ defaultSelectText: this.props.defaultText });
    }
    if (this.state.style !== this.props.style) this.setState({ style: this.props.style });
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    this.setState({ defaultSelectText: this.props.defaultText });
    this.setState({ style: this.props.style });
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (e) => {
    if (!e.target.classList.contains('custom-select-option'))
      this.setState({ showOptionList: false });
  };

  handleListDisplay = () => {
    if (this.props.disabled) return;
    this.setState((prevState) => {
      return { showOptionList: !prevState.showOptionList };
    });
  };

  handleOptionClick = (e, option) => {
    this.setState({
      defaultSelectText: e.target.getAttribute('data-name'),
      showOptionList: false,
    });

    if (this.props.onChange) {
      this.props.onChange(option);
    }
  };

  render() {
    const { optionsList, disabled, noContainer } = this.props;
    const { showOptionList, defaultSelectText, style } = this.state;
    const style_container
      = `custom-select-container ${noContainer ? 'custom-select-no-container' : ''} ${disabled ? 'noHover' : ''}`;
    const style_presept = showOptionList
      ? 'selected-text active'
      : `selected-text${!disabled ? '' : '-disabled'}`;
    return (
      <div className={style_container}>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
        <div className={style ? style : style_presept} onClick={this.handleListDisplay} role="link" tabIndex="0">
          {defaultSelectText}
        </div>
        {showOptionList && !disabled && (
          <ul className="select-options">
            {optionsList.map((option, index) => {
              return (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
                <li
                  className="custom-select-option"
                  data-name={option.name}
                  key={`custom-select-option-list-${option.id || index}`}
                  onClick={(e) => this.handleOptionClick(e, option)}
                >
                  {option.name}
                </li>
              );
            })}
          </ul>
        )}
      </div>
    );
  }
}

export default CustomSelect;
