import { createAction } from '../../utils';

// Types
export const types = {
  RESET_REDUCER: 'medic_RESET_REDUCER',

  SET_MEDIC_FILTER: 'SET_MEDIC_FILTER',
  RESET_MEDIC_FILTER: 'RESET_MEDIC_FILTER',
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  setMedicFilter: (filter) => createAction(types.SET_MEDIC_FILTER, { filter }),
  resetMedicFilter: () => createAction(types.RESET_MEDIC_FILTER),
};
