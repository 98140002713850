// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#error {
  max-width: 1280px;
  min-height: calc(100vh - 72px - 107px);
  padding: 24px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  box-sizing: border-box;
}

#error .contact {
  margin-top: 16px;
  font-size: 18px;
}

#error .contact .email {
  cursor: pointer;
}

#error .contact .email:hover {
  text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/ui/screens/ErrorBoundary/error.scss","webpack://./src/ui/screens/ErrorBoundary/error.css"],"names":[],"mappings":"AAEA;EACE,iBAAiB;EACjB,sCAAsC;EACtC,aAAa;EAGb,aAAa;EAEb,qBAAgB;ECChB,mBAAmB;EDIjB,8BAAe;EAGb,sBAAe;ECEnB,sBAAsB;ADlBxB;;ACqBA;EACE,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":["\n#error {\n  max-width: 1280px;\n  min-height: calc(100vh - 72px - 107px);\n  padding: 24px;\n  display: flex;\n  align-content: center;\n  align-items: center;\n  justify-content: space-between;\n  flex-direction: column;\n  box-sizing: border-box;\n\n  .contact {\n    margin-top: 16px;\n    font-size: 18px;\n\n    .email {\n      cursor: pointer;\n\n      &:hover {\n        text-decoration: underline;\n      }\n    }\n  }\n}\n","#error {\n  max-width: 1280px;\n  min-height: calc(100vh - 72px - 107px);\n  padding: 24px;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-line-pack: center;\n  align-content: center;\n  -webkit-box-align: center;\n  -ms-flex-align: center;\n  align-items: center;\n  -webkit-box-pack: justify;\n  -ms-flex-pack: justify;\n  justify-content: space-between;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n  -ms-flex-direction: column;\n  flex-direction: column;\n  -webkit-box-sizing: border-box;\n  box-sizing: border-box;\n}\n\n#error .contact {\n  margin-top: 16px;\n  font-size: 18px;\n}\n\n#error .contact .email {\n  cursor: pointer;\n}\n\n#error .contact .email:hover {\n  text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
