import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import CustomPages from './CustomPages';
import CustomPDFButton from './CustomPDFButton';

function CustomNavigation({ page, pages, onPrevClick, onNextClick }) {
  const { t } = useTranslation();
  return (
    <div className="d-flex justify-content-around align-items-center ec-pdf-viewer-custom-navigation" >
      <CustomPDFButton
        disabled={parseInt(page, 10) <= 1}
        onClick={onPrevClick}
        label={t('pdfViewer.previous_page')}
        isPrevious
      />
      <CustomPages page={page} pages={pages} />
      <CustomPDFButton
        disabled={parseInt(page, 10) >= parseInt(pages, 10)}
        onClick={onNextClick}
        label={t('pdfViewer.next_page')}
      />
    </div>
  );
}

CustomNavigation.propTypes = {
  onPrevClick: PropTypes.func.isRequired,
  onNextClick: PropTypes.func.isRequired,
  page: PropTypes.number,
  pages: PropTypes.number,
};

export default CustomNavigation;
