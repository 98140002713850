/* Services exports goes here */

export { default as AuthenticationService } from './authentication';
export { default as MedicService } from './medic';
export { default as PatientService } from './patient';
export { default as SurveyService } from './survey';
export { default as TemplateService } from './template';
export { default as MedicTemplateService } from './medicTemplate';
export { default as ClinicService } from './clinic';
export { default as QuestionIntroService } from './questionIntroService';
export { default as CityService } from './city';
export { default as ConnecteurService } from './connecteur';
export { default as UserService } from './userService';

