import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import Card from '../../../../components/card';
import GlobalLoader from '../../../../components/loaders/GlobalLoader';
import IFrameRenderer from './IFrameRenderer';

function Iframe({ src, onMessage, cardClassName, isLoading, ...props }) {

  useEffect(() => {
    const messageHandler = (message) => {
      onMessage && onMessage(message?.data);
    };
    window.addEventListener('message', messageHandler);
    // clean up
    return () => window.removeEventListener('message', messageHandler);
  }, []); // empty array => run only once

  return (
    isLoading ? (
      <GlobalLoader />
    ) : (
      <Card classeName={cardClassName}>
        <IFrameRenderer src={src} {...props}/>
      </Card>
    )
  );
}

Iframe.propTypes = { src: PropTypes.string, onMessage: PropTypes.func };

export default Iframe;
