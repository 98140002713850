import 'core-js'; // issue : Error: An error occurred. See https://git.io/JUIaE#17 for more information.
import 'react-toastify/dist/ReactToastify.css';

import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import { theme } from './config/theme';
import { store } from './core';
import Router from './core/Router';
import AbilityContextProvider from './services/casl/Can';
import { BlockedModal } from './ui/components';
import ConnectionModals from './ui/components/firstConnexion';
import StyledToastContainer from './ui/components/styledComponent/StyledToastContainer';

function App() {
  return (
    <Provider store={store}>
      <HelmetProvider>
        <AbilityContextProvider>
          <ThemeProvider theme={theme}>
            <StyledToastContainer
              autoClose={3500}
              position="bottom-right"
              style={{ width: '80%' }}
              draggable={false}
            />
            <ConnectionModals />
            <Helmet prioritizeSeoTags>
              <title>Easy Consent</title>
              <html lang="fr" />
            </Helmet>
            <Router />
            <BlockedModal />
          </ThemeProvider>
        </AbilityContextProvider>
      </HelmetProvider>
    </Provider>
  );
}


export default App;
