

export const USER_MINIMAL_FIELDS = `
  fragment usersMinimalFields on users {
    id
    username
    role 
    renew_pwd
    edit_info
    accepted_cgu
    accepted_rgpd
  }
`;

export const USER_CORE_FIELDS = `
  fragment usersCoreFields on users {
    id
    username
    role 
    renew_pwd
    edit_info
    accepted_cgu
    accepted_rgpd
  }
`;