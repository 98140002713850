import './style.css';

import React from 'react';

const AvatarMedic = ({ data }) => (
  <span className="ec-avatar-medic-item" data-tip={data.firstname && `${data.title} ${data.firstname} ${data.lastname}`}>
    {data.label || `${data.firstname[0]}${data.lastname[0]}`}
  </span>
);

export default AvatarMedic;
