/**
 * GRAPHQL QUERIES
 */

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getLoginInfoFromToken: () => `
    query GetLoginInfoFromToken($value: String!) {
      access_tokens( where: {value: {_eq: $value} } ) {
        user {
          id
          username
          role
          accepted_cgu
          accepted_rgpd
          edit_info
          renew_pwd
        }
      }
    }
  `,
  loginInfoByUserId: () => `
    query GetLoginInfoById( $userId: Int! ) {
      users(
        where: {
          id: { _eq: $userId }
        }
      ) {
        id
        username
        role
        accepted_cgu
        accepted_rgpd
        edit_info
        renew_pwd
        users_roles(order_by: {role: {created_at: desc}}) {
          role {
            name
            permissions
          }
        }
      }
    }
  `,
  cgu: () => `
    query {
      cgu {
        value
      }
    }
  `,
};
