import { createMemoryHistory } from 'history';
const history = createMemoryHistory();

let oldLocation = null;

history.listen((location) => {
  // Hook to scroll to top when route changes occurs
  if (oldLocation !== null) window.scrollTo(0, 0);
  oldLocation = location;
});

export default history;