import { addSeconds } from 'date-fns';
import { createReducer } from 'reduxsauce';

import { types } from './actions';

const initialState = {
  isUserLoading: false,
  isUserLoggedIn: false,
  token: null,
  refresh_token: null,
  token_type: null,
  loginInfoSuccess: false,
  // used for autologin medic countersign in order to notify splash screen of error
  token_expired: false,
  attempts: 0,
  user_blocked: false,
  email: '',
  legalSuccess: false,
  isLogoutSuccess: false,
  otp: {
    username: null,
    password: null,
    code: null,
    uuid: null,
    otp: null,
  },
  user: {
    id: null,
    role: null,
    username: null,
    renew_pwd: false,
    accepted_cgu: true,
    accepted_rgpd: true,
    edit_info: true,
    users_roles: [],
    language: 'fr-FR',
  },
  rawAbilitiesRules: {
    medic: [],
    medicalTeam: [],
  },
  abilities_rules: {
    medic: [],
    user: [],
  },
  isCodeValid: null,
  // edit_info: false,
  // renew_pwd: false,
  // renew_infos: false,
  // accepted_rgpd: false,
  // accepted_cgu: false,
  ip: '',
  sms: {
    lastSent: null,
    sentQty: 0,
    nextCanBeSendAt: null,
    uuid: null,
    error: false,
  },
  isLoginFromQrCode: false,
  redirectUrl: null,
  isSplashLoginSuccess: null,
  casl: {},
};

// region Reducers
const loginAttempt = (state = { ...initialState }) => {
  const { attempts } = state;
  return {
    ...state,
    attempts: attempts + 1,
  };
};

const loginFailed = (state = { ...initialState }) => {
  const { attempts } = state;
  return {
    ...state,
    user_blocked: attempts >= 5,
  };
};

const loginSuccess = (state = { ...initialState }, action = {} ) => {
  const { token_type, access_token, refresh_token, authorization, username } = action;
  return {
    ...state,
    token: access_token,
    refresh_token,
    token_type,
    authorization,
    attempts: 0,
    email: username,
    username: username,
    loginInfoSuccess: false,
    isLogoutSuccess: false,
  };
};

const disabledTokenSuccess = (state = { ...initialState } ) => {
  return {
    ...state,
    token: null,
    refresh_token: null,
    token_type: null,
    // used for autologin medic countersign in order to notify splash screen of error
    token_expired: false,
    attempts: 0,
    authorization: null,
  };
};

const userSuccess = (state = { ...initialState }, action = {} ) => {
  const { user } = action;
  let { email } = state;
  if (!email || email.length === 0 || (email && email === 'undefined')) {
    email = user && user.username ? user.username : '';
  }
  return {
    ...state,
    email: email,
    user: { ...user },
  };
};

const roleSuccess = (state = { ...initialState }, action = {} ) => {
  const { role } = action;
  return {
    ...state,
    user: {
      ...(state.user || {}),
      role,
    },
  };
};

const getLoginInfoError = (state = { ...initialState }) => ({
  ...state,
  token_expired: true,
});

// const getUserLanguage = (state = { ...initialState }) => ({
//   ...state,
//   language: 'fr-FR',
// });

const checkCode = (state = { ...initialState }) => {
  return {
    ...state,
    isCodeValid: null,
  };
};

const checkCodeSuccess = (state = { ...initialState }) => {
  return {
    ...state,
    isCodeValid: true,
  };
};

const checkCodeFailure = (state = { ...initialState }) => {
  return {
    ...state,
    isCodeValid: false,
  };
};

const logoutSuccess = (_state = { ...initialState }, action = {} ) => {
  const { newInitialState } = action;
  return { ...initialState, ...(newInitialState || {}), isLogoutSuccess: true };
};

const resetStatus = (state = { ...initialState }) => checkCode(state);

const ipSuccess = (state = { ...initialState }, action = {} ) => {
  const { ip } = action;
  let formatedIP = ip ? ip.ip : null;
  if (formatedIP && formatedIP.includes(':')) {
    const index = formatedIP.lastIndexOf(':') + 1;
    formatedIP = formatedIP.substring(index);
  }
  return {
    ...state,
    ip: formatedIP,
  };
};

const renewInfos = (state = { ...initialState }, action = {} ) => {
  const { renew } = action;
  return {
    ...state,
    renew_infos: renew,
  };
};

const setLastSmsSent = (state = { ...initialState }, action = {} ) => {
  const { uuid } = action;
  const newSentQty = (state?.sms?.sentQty || 0) + 1;
  return {
    ...state,
    sms: {
      ...state.sms,
      lastSent: new Date(),
      sentQty: newSentQty,
      nextCanBeSendAt: addSeconds(new Date(), Math.pow(15, newSentQty), 'seconds'),
      uuid,
      error: false,
    },
  };
};

const clearLastSmsSent = (state = { ...initialState }) => {
  return {
    ...state,
    sms: {
      ...state.sms,
      sentQty: 0,
    },
  };
};

const smsSetError = (state = { ...initialState }) => {
  return {
    ...state,
    sms: {
      ...state.sms,
      error: true,
    },
  };
};

const smsClearError = (state = { ...initialState }) => {
  return {
    ...state,
    sms: {
      ...state.sms,
      error: false,
    },
  };
};

const setIsLoginFromQrCode = (state = { ...initialState }) => {
  return {
    ...state,
    isLoginFromQrCode: true,
  };
};

const setAccessToken = (state = { ...initialState }, action = {} ) => {
  const { token, refresh_token } = action;
  return {
    ...state,
    token,
    refresh_token,
    token_type: 'Bearer',
  };
};

const passwordSuccess = (state = { ...initialState }) => {
  const { user } = state;
  return {
    ...state,
    user: {
      ...user,
      renew_pwd: false,
    },
    legalSuccess: true,
  };
};

const cguSuccess = (state = { ...initialState }) => {
  const { user } = state;
  return {
    ...state,
    user: {
      ...user,
      accepted_cgu: true,
    },
    legalSuccess: true,
  };
};

const rgpdSuccess = (state = { ...initialState }) => {
  const { user } = state;
  return {
    ...state,
    user: {
      ...user,
      accepted_rgpd: true,
    },
    legalSuccess: true,
  };
};

const editInfoSuccess = (state = { ...initialState }) => {
  const { user } = state;
  return {
    ...state,
    user: {
      ...user,
      edit_info: true,
    },
    legalSuccess: true,
  };
};

const addAbilitiesRules = (state = { ...initialState }, action = {} ) => {
  const { abilities_rules } = state;
  let { rules, key } = action;
  return {
    ...state,
    abilities_rules: {
      ...abilities_rules,
      [key]: rules,
    },
  };
};

const saveAbilitiesRules = (state = { ...initialState }, action = {} ) => {
  const { rawAbilitiesRules } = state;
  let { rules, key } = action;
  return {
    ...state,
    rawAbilitiesRules: {
      ...rawAbilitiesRules,
      [key]: rules,
    },
  };
};

const setCaslRules = (state = { ...initialState }, action = {} ) => {
  const { casl } = state;
  const { rules } = action;
  return {
    ...state,
    casl: {
      ...casl,
      rules,
      date: Date.now(),
    },
  };
};

const userLoading = (state = { ...initialState } ) => {
  return {
    ...state,
    isUserLoading: true,
  };
};

const getLoginInfoRequest = (state = { ...initialState } ) => {
  return {
    ...state,
    loginInfoSuccess: false,
  };
};

const setRedirectUrl = (state = { ...initialState }, action = {} ) => {
  let { url } = action;
  return {
    ...state,
    redirectUrl: url,
  };
};

const clearRedirectUrl = (state = { ...initialState } ) => {
  return {
    ...state,
    redirectUrl: null,
  };
};


const getLoginInfoSuccess = (state = { ...initialState } ) => {
  return {
    ...state,
    loginInfoSuccess: true,
    isUserLoading: false,
    isUserLoggedIn: true,
  };
};

const authorizationSuccess = (state = { ...initialState }, action ) => {
  const { username, password, code, uuid, otp } = action;
  return {
    ...state,
    oauth:  { username, password, code, uuid, otp },
  };
};

const authorizationRequest = (state = { ...initialState } ) => {
  return {
    ...state,
    oauth:  { username: null, password: null, code: null, uuid: null, otp: null },
  };
};

// endregion

export default createReducer(initialState, {
  [types.AUTHORIZATION_REQUEST]: authorizationRequest,
  [types.AUTHORIZATION_SUCCESS]: authorizationSuccess,

  [types.LOGIN_REQUEST]: loginAttempt,
  [types.LOGIN_REFRESH]: loginAttempt,
  [types.LOGOUT_SUCCESS]: logoutSuccess,
  [types.LOGIN_FAILURE]: loginFailed,
  [types.LOGIN_SUCCESS]: loginSuccess,
  [types.ROLE_SUCCESS]: roleSuccess,
  [types.CHECK_CODE]: checkCode,
  [types.CHECK_CODE_SUCCESS]: checkCodeSuccess,
  [types.CHECK_CODE_FAILURE]: checkCodeFailure,
  [types.RESET_STATUS]: resetStatus,
  [types.IP_SUCCESS]: ipSuccess,
  [types.RENEW_INFOS]: renewInfos,
  [types.SET_REDIRECT_URL]: setRedirectUrl,
  [types.CLEAR_REDIRECT_URL]: clearRedirectUrl,
  [types.SET_LAST_SMS_SENT]: setLastSmsSent,
  [types.CLEAR_LAST_SMS_SENT]: clearLastSmsSent,
  [types.SMS_SET_ERROR]: smsSetError,
  [types.SMS_CLEAR_ERROR]: smsClearError,


  [types.SET_LOGIN_FROM_QR_CODE]: setIsLoginFromQrCode,
  [types.SET_TOKENS]: setAccessToken,

  [types.PASSWORD_SUCCESS]: passwordSuccess,
  [types.CGU_SUCCESS]: cguSuccess,

  [types.RGPD_SUCCESS]: rgpdSuccess,
  [types.EDIT_INFO_SUCCESS]: editInfoSuccess,


  [types.ADD_ABILITES_RULES]: addAbilitiesRules,
  [types.SAVE_ABILITES_RULES]: saveAbilitiesRules,

  [types.GET_LOGIN_INFO_ERROR]: getLoginInfoError,
  [types.DISABLED_TOKEN_SUCCESS]: disabledTokenSuccess,

  [types.USER_LOADING]: userLoading,


  [types.GET_LOGIN_INFO]: getLoginInfoRequest,
  [types.GET_LOGIN_INFO_SUCCESS ]: getLoginInfoSuccess,


  [types.USER_SUCCESS]: userSuccess,

  [types.SET_CASL_RULES]: setCaslRules,

  // [types.GET_USER_LANGUAGE]: getUserLanguage,
});
