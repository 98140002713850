/**
 * GRAPHQL MUTATIONS
 */

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  patchUser: `
    mutation PatchUser($userId: Int!, $userData: users_set_input!) {
      update_users(where: {id: {_eq: $userId}}, _set: $userData) {
        returning {
          id
          username
        }
      }
    }
  `,
};
