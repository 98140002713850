import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { buildText } from '../../../../utils/dataMappers';
import Modal from '../../../components/modal';
import { QuestionBasicsText, QuestionBasicsTextError, QuestionBasicsTextSuccess } from '../../../components/questionBasicsV2';

const IntroQuestionsModal = ({
  onCancel,
  questionIntroList,
  currentIndex,
  incrementStep,
  survey,
  isOpen,
  isAfter,
  hasLoad,
  onLastClick,
  token,
}) => {
  const { t } = useTranslation();
  const [approvedSteps, setApprovedSteps] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const questionIntro = useMemo(() => {
    if (!hasLoad) return null;
    const display = [
      {
        key: 'side',
        check: (survey && (!survey.side || survey.side === 'off')) ? false : true,
      },
      {
        key: 'radius',
        check: (survey && (!survey.radius || survey.radius === 'off')) ? false : true,
      },
      {
        key: 'date_warning',
        check: survey && survey.date_warning ? true : false,
      },
    ]
      .filter(d => d.check && d.check === true)
      .map(d => d.key);
    return (questionIntroList || [])
      .filter(q => {
        return q.is_after === (isAfter || false) && ( q.displayed_if === null || display.includes(q.displayed_if));
      }).map( el => el?.introduction_questions_translations[0]);
  }, [survey, questionIntroList, hasLoad, isAfter]);
  const currentQuestion = useMemo(() => (questionIntro && questionIntro[currentIndex]) || {}, [questionIntro, currentIndex]);
  const text = useMemo(() => {
    if (!_.isObject(survey)) {
      return '';
    }
    switch (activeStep) {
      case 0:
        return buildText(
          currentQuestion.value || null,
          survey,
        );
      case 1:
        return buildText(currentQuestion.text_success || null, survey);
      case 2:
        return buildText(currentQuestion.text_error || null, survey);
      default:
    }
  }, [activeStep, currentQuestion, survey]);


  useEffect(() => {
    if (questionIntro && currentIndex >= questionIntro.length) {
      onLastClick();
    }
    if ((currentQuestion?.value || '').trim() === ''){
      // if no value then auto validate as empty question
      onLastClick();
    }
  }, [currentIndex, questionIntro]);

  const handleOnSuccess = () => {
    if (currentQuestion?.text_success){
      setActiveStep(1);
    } else {
      handleOnValid();
    }
  };

  const handleOnRefuse = () => {
    setActiveStep(2);
  };

  const handleOnValid = () => {
    setActiveStep(0);
    setApprovedSteps([...approvedSteps, _.get(questionIntro, [currentIndex, 'id'])]);
    if (incrementStep) incrementStep(isAfter);
  };

  const handleOnReset = () => {
    setActiveStep(0);
  };

  const handleOnCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };
  if (!questionIntro || !questionIntro[currentIndex]) return <div />;

  const pdfUrl = currentQuestion.pdf_url || (currentQuestion.loid && `${process.env.REACT_APP_SIGN_API_URL}/file/type/introductionQuestions/oids/${currentQuestion.loid}`) || null;

  return (
    <Modal
      hideCloseBtn
      title={currentQuestion.title || ''}
      scrollable
      isOpen={isOpen}
    >
      {activeStep === 0 && (
        <QuestionBasicsText
          text={text}
          onValidate={handleOnSuccess}
          onRefuse={handleOnRefuse}
          firstText={currentQuestion?.first_button_label}
          secondText={currentQuestion?.second_button_label || t('Yes')}
          pdf_url={pdfUrl}
          pdfCheckboxLabel={currentQuestion?.pdf_checkbox_label}
          token={token}
        />
      )}
      {activeStep === 1 && (
        <QuestionBasicsTextSuccess
          text={text}
          onValidate={handleOnValid}
        />
      )}
      {activeStep === 2 && (
        <QuestionBasicsTextError
          text={text}
          onValidate={handleOnReset}
          onCancel={handleOnCancel}
        />
      )}
    </Modal>
  );
};

export default IntroQuestionsModal;
