import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { MEDIC_ROLES } from '../../utils';
import { PATIENT_HOME_PATH, PRO_HOME_PATH } from '../../utils/const';


const PublicRoutes = ({
  redirect,
  element,
  userRole,
  loginInfoSuccess,
}) => {
  const isPro = MEDIC_ROLES.includes(userRole);

  if (!loginInfoSuccess) {
    return element || <Outlet/>;
  }

  return <Navigate to={redirect || isPro ? PRO_HOME_PATH : PATIENT_HOME_PATH} />;
};

export default PublicRoutes;