/**
 * GRAPHQL QUERIES
 */

import { INTERVENTIONS_MINIMAL_FIELDS } from './interventionsFragments';


// eslint-disable-next-line import/no-anonymous-default-export
export default {
  fetchPatientSurveyList: () => `
    ${INTERVENTIONS_MINIMAL_FIELDS}
    query fetchPatientSurveyList($patientId: Int!) {
      surveys(order_by: {intervention: {number: desc}}, where:{
        _or: [
          {patient_id: {_eq: $patientId}},
          {tutored_id: {_eq: $patientId}},
        ]
      }) {
        id
        title
        is_signed
        status
        tutored_id
        patient_id
        survey_drawing_id
        survey_attachment_id
        is_ref_signature
        referent {
          id
          firstname
          lastname
          __typename
        }
        intervention {
          ...interventionsMinimalFields
          __typename
        }
        survey_steps_aggregate {
          aggregate {
            count(distinct: true)
            __typename
          }
          __typename
        }
        survey_type {
          should_countersign
          can_withdraw
          __typename
        }
        __typename
      }
    }
  `,
  getSurveys: () => `
    query getSurveys($languageId: Int! = 1) {
      surveys {
        id
        title
        status
        patient_id
        clinic_id
        side
        radius
        progress_index
        medical_teams_id
        updated_at
        created_at
        speciality {
          id
          specialities_translations(where: {language_id: {_eq: $languageId}}) {
            value
          }
        }
        survey_type {
          id
          survey_types_translations(where: {language_id: {_eq: $languageId}}) {
            title
            label_a
            label
            label_your
          }
        }
      }
    }
  `,
  surveysMedic: () => `
    query getMedicSurveys {
      medic_surveys {
        medic_id
        survey_id
      }
    }`,
  types: () => `
    query getTypes($languageIds: [Int!] = [1]) {
      survey_types {
        id
        is_ip_displayed
        should_countersign
        can_withdraw
        survey_types_translations(where: {language_id: {_in: $languageIds}}) {
          id
          language_id
          title
          label_a
          label
          label_your
        }
      }
    }
  `,
  questionTypes: () => `
    query getQuestionTypes{
      question_types {
        id
        value
      }
    }
  `,
  specialities: () => `
    query getSpecialities($languageIds: [Int!] = [1]) {
      specialities {
        id
        specialities_translations(where: {language_id: {_in: $languageIds}}) {
          language_id
          value
        }
      }
    }
  `,
  currentSurveyIntervention: () => `
    query getCurrentSurveyIntervention($id: Int!) {
      interventions(where: {surveys: {id: {_eq: $id}}}) {
        id
        date
        number
        type
      }
    }`,
};
