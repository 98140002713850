import './style.css';

import React, { useCallback, useEffect, useRef, useState } from 'react';

import Item from './Item';

const List = ({ chunk, onClick, isTemplateStore, uploadedTemplateIds, types }) => {
  const loader = useRef(null);
  const [page, setPage] = useState(1);
  const [list, setList] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  const handleObserver = useCallback((entries) => {
    if (hasMore){
      const target = entries[0];
      if (target.isIntersecting) {
        setPage((prev) => prev + 1);
      }
    }
  }, []);

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: '20px',
      threshold: 0,
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (loader.current) observer.observe(loader.current);
  }, [handleObserver]);

  useEffect(() => {
    // when new chunk => loading
    if (Array.isArray(chunk)) {
      loadFunc(1);
      setHasMore(true);
    } else {
      setList([]);
    }
  }, [JSON.stringify(chunk)]);

  useEffect(() => {
    return function cleanUp() {
      setList([]);
    };
  }, []);

  useEffect(() => {
    if (page) {
      loadFunc(page);
    }
  }, [page]);

  const loadFunc = (newPage) => {
    if (newPage !== page){
      setPage(newPage);
    }
    const index = newPage - 1 ;
    setList( chunk.slice(0, newPage).reduce((acc, val) => [...acc, ...val], []) );
    setHasMore(index < chunk.length);
  };

  const handleOnClick = (id, mode) => {
    if (onClick) {
      onClick(id, mode);
    }
  };

  return (
    <>
      {
        Array.isArray(list) && list.length
          ? (
            <div className={'medic-form-list-row mb-5'}>
              { list
                .map((form, i) => (
                  <Item
                    key={`form_list_card_${form.__typename}_${form.id}`}
                    onClick={handleOnClick}
                    form={form}
                    isTemplateStore={isTemplateStore}
                    tabIndex={i}
                    uploadedTemplateIds={uploadedTemplateIds}
                    types={types}
                  />
                ))
              }
            </div>
          ) : null
      }
      <div ref={loader} />
    </>
  );
};

export default List;
