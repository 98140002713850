import { format } from 'date-fns';

import { methods } from '../utils';
import { medicTemplateMutations, medicTemplateQueries } from './GraphQLRequest';
import { authenticatedMutation, authenticatedQuery, authenticatedService } from './middleware';

const { POST, GET, DELETE } = methods;

const PROXY_API_URL = process.env.REACT_APP_PROXY_API || process.env.REACT_APP_BASE_API_URL || '/api';
const PROXY_SIGN_URL = process.env.REACT_APP_PROXY_SIGN || process.env.REACT_APP_SIGN_API_URL || '/sign';

const routes = {
  upsertWithPDF: `${PROXY_SIGN_URL}/templates`,
  medicTemplates: `${PROXY_API_URL}/V2/medicTemplates`,
  medicTemplateId: medicTemplateId => `${PROXY_API_URL}/V2/medicTemplates/${medicTemplateId}`,
  generatePDF: (surveyId) => `${PROXY_SIGN_URL}/medic_templates/${surveyId}/downloadAllPdfImagesFromDbToFolder`,
  getMedicTemplateDetail: () => `${PROXY_API_URL}/V2/medicTemplates/detail`,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getAll: () => authenticatedQuery(medicTemplateQueries.getAll(), {}, { languageCode: 'fr-FR' }),
  getSome: (idList) => authenticatedQuery(medicTemplateQueries.getSome(), {}, { idList: Array.isArray(idList) ? idList : [idList] }),
  getFromMedicTeamId: (medicTeamId) => authenticatedService(GET, routes.medicTemplates, { }, { medicTeamId }),
  getDetails: (medicTemplateId) => authenticatedQuery(medicTemplateQueries.getDetails(), {}, { medicTemplateId }),
  getMedicTemplateDetail: (list) => authenticatedService(GET, routes.getMedicTemplateDetail(), {}, { list }),
  upsertMedicTemplates: (formData) => authenticatedService(POST, routes.medicTemplates, formData, {}),
  upsertWithPDF: (formData) => authenticatedService(POST, routes.upsertWithPDF, formData, {}),
  updateActiveState: (medicTemplateId, isPublished) => authenticatedMutation(medicTemplateMutations.updateActiveState(), {}, { medicTemplateId, isPublished }),
  deleteOne: (medicTemplateId) => authenticatedService(DELETE, routes.medicTemplateId(medicTemplateId)),
  generatePDF: (surveyId) => authenticatedService(GET, routes.generatePDF(surveyId)),
  archiveMedicTemplate: (medicTemplateId) => authenticatedMutation(medicTemplateMutations.archiveMedicTemplate(), {}, {
    medicTemplateId,
    archivedDate: format(new Date(), 'yyyy-MM-dd'),
  }),
};
