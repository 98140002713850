import _ from 'lodash';
import { connect } from 'react-redux';

import { AuthActions } from '../../../../../../redux/auth';
import { FormsActions } from '../../../../../../redux/forms';
import ResetPwd from './ResetPwd';

const mapStateToProps = ({ forms }, props) => {
  const { id } = props;
  return {
    formsData: _.get(forms.data, id),
    code: _.get(forms.data, 'ModalRenew.CodeStep.code'),
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateData: (key, data) => dispatch(FormsActions.updateData(key, data)),
  omitData: (key) => dispatch(FormsActions.omitData(key)),
  changePassword: (newPwd, code) => dispatch(AuthActions.changePassword(newPwd, code)),
  createPwd: (newPwd) => dispatch(AuthActions.createPassword(newPwd)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPwd);
