import './style.css';

import React, { useRef, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { IconAttachment, IconDraw } from '../../../../assets/img/icons';
import { toPng } from '../../../../utils';
import { ModalButtons } from '../../../components/buttons';
import Card from '../../../components/card';
import InputArea from '../../../components/formV3/controllers/InputArea';
import Modal from '../../../components/modal';
import PaintForm from '../../../components/PaintForm';
import AttachmentForm from '../Attachment';

const Commentary = ({ control }) => {
  const { t } = useTranslation();
  const [paint, setPaint] = useState(null);
  const [openPaint, setOpenPaint] = useState(false);
  const [openAttchment, setOpenAttachment] = useState(false);
  const [paintImg, setPaintImg] = useState(null);
  const [savedData, setSavedData] = useState(null);

  const paintFormRef = useRef({});
  const title = t('your_commentary');
  const formMethods = useFormContext();

  const attachmentWatch = useWatch({
    control: control || formMethods.control,
    name: 'attachment', // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
  });

  const handleDrawOnClick = (e) => {
    if ( paint) {
      toPng('my_canvas').then(({ resultFile, dataUrl }) => {
        setOpenPaint(false);
        setPaintImg(dataUrl);
        formMethods.setValue('paintAttachment', resultFile);
      });
      if (paintFormRef && paintFormRef.current) {
        const newSavedData = paintFormRef.current.getSaveData();
        setSavedData(newSavedData);
      }
    }
  };

  const handleDrawToggle = (e) => {
    setOpenPaint(openPaint !== true);
  };

  const handleDrawImageChange = (newPaint) => {
    // save image and push to history
    setPaint(newPaint);

    if (newPaint && newPaint.imgSrc === null && newPaint.noBackground === false) {
      setOpenPaint(false);
      setPaintImg(null);
    } else if (!newPaint && paintFormRef && paintFormRef.current) {
      setSavedData(paintFormRef.current.getSaveData());
      paintFormRef.current.setOldImg();
    }
  };

  const handleOnPaintChange = (data) => {
    /*setSavedData(data)*/
  };

  const onPaintReset = () => {
    if (paintFormRef && paintFormRef.current) {
      setSavedData(paintFormRef.current.getSaveData());
      formMethods.setValue('paintAttachment', null);
    }
  };

  const handleAttachmentToggle = () => {
    setOpenAttachment(openAttchment !== true);
  };

  const handleClearAttachment = name => {
    formMethods.setValue(name, null, { shouldValidate: true });
  };

  return (
    <Card>
      <div className="consent-preview-commentary-container" style={{ postion: 'relative' }}>
        <p className="consent-preview-commentary-title"> {title} </p>
        <InputArea
          id={'consent-preview-commentary-area'}
          name="comment"
          className="consent-preview-commentary-area"
          defaultValue={''}
          placeholder={title}
          dynamicLines
        />
        <div className="consent-preview-commentary-buttons-container">
          <button className="consent-preview-commentary-button" onClick={handleAttachmentToggle} type="button">
            <img
              className="consent-preview-commentary-button-image"
              src={IconAttachment}
              alt="Attachment"
            />
            { attachmentWatch
              ? (
                <p className="consent-preview-commentary-button-text">
                  {t('see_attachment')} <sup style={{ color: '#ef4f4f' }}> 1 </sup>
                </p>
              ) : (
                <p className="consent-preview-commentary-button-text">
                  {t('add_attachment')}
                </p>
              )}
          </button>
          <button className="consent-preview-commentary-button" onClick={handleDrawToggle} type="button">
            <img className="consent-preview-commentary-button-image" src={IconDraw} alt="Draw" />
            { paintImg
              ? (
                <p className="consent-preview-commentary-button-text">
                  {t('see_a_drawing')} <sup style={{ color: '#ef4f4f' }}> 1 </sup>
                </p>
              ) : (
                <p className="consent-preview-commentary-button-text">
                  {t('make_a_drawing')}
                </p>
              )}
          </button>
        </div>
      </div>
      <Modal
        title={t('make_a_drawing')}
        onClose={handleDrawToggle}
        fullWidth
        fullHeight
        isOpen={openPaint === true}
      >
        <PaintForm
          ref={paintFormRef}
          imgSrc={(paint && paint.imgSrc) || null}
          noBackground={(paint && paint.noBackground) || false}
          onImageChange={handleDrawImageChange}
          onPaintChange={handleOnPaintChange}
          onReset={onPaintReset}
          savedData={savedData}
        />
        { paint != null && (paint.imgSrc !== null || paint.noBackground === true) && (
          <ModalButtons
            firstText={t('previous')}
            onFirstClick={() => handleDrawImageChange(null)}
            secondText={t('validate')}
            onSecondClick={handleDrawOnClick}
          />
        )}
      </Modal>
      { openAttchment === true && (
        <AttachmentForm

          attachment={attachmentWatch}
          onClose={handleAttachmentToggle}
          onClearFile={handleClearAttachment}
        />
      )}
    </Card>
  );
};

export default Commentary;
