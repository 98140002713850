import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { IconWithdrawalBtn } from '../../../../../assets/img/icons';
import { SURVEY_STATUS } from '../../../../../utils';
import ConfirmBtn from '../../../ConfirmBtn';



function WithdrawalConsentBtn ({ survey, status, withdrawalConsent }) {
  const { t } = useTranslation();

  const isActive
    = [SURVEY_STATUS.ACCEPTED, SURVEY_STATUS.COUNTERSIGNED].includes(status) && survey.survey_type.can_withdraw;
  const confirmWithdrawal = () => {
    withdrawalConsent(survey.id);
  };
  return (
    <>
      {(isActive) && (
        <div>
          <ConfirmBtn
            title={t('confirm_withdrawal_consent')}
            onConfirm={confirmWithdrawal}
            isOpen={false}
          >
            <img
              className={'withdrawal-consent'}
              src={IconWithdrawalBtn}
              data-tip={t('medicPatient.history.list.actionBtn.withdrawal')}
              alt="patient_button_delete"
            />
          </ConfirmBtn>
        </div>
      )}
    </>
  );
}

WithdrawalConsentBtn.propTypes = {
  survey: PropTypes.object,
  status: PropTypes.string,
  withdrawalConsent: PropTypes.func,
};

export default WithdrawalConsentBtn ;
