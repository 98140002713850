// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* TeamsList.jsx */
.ec-teams-list-title {
  font-size: 18px;
}

/* AvatarMedicGroup.jsx*/
.ec-avatar-medic-group {
  display: flex;
  margin-bottom: 15px;
}

/* AvatarMedic.jsx*/
.ec-avatar-medic-item {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  background-color: #ffb56a;
  color: #ff7842;
  margin-right: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/ui/screens/profil/teamsList/style.css"],"names":[],"mappings":"AAAA,kBAAkB;AAClB;EACE,eAAe;AACjB;;AAEA,wBAAwB;AACxB;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA,mBAAmB;AACnB;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,cAAc;EACd,kBAAkB;AACpB","sourcesContent":["/* TeamsList.jsx */\n.ec-teams-list-title {\n  font-size: 18px;\n}\n\n/* AvatarMedicGroup.jsx*/\n.ec-avatar-medic-group {\n  display: flex;\n  margin-bottom: 15px;\n}\n\n/* AvatarMedic.jsx*/\n.ec-avatar-medic-item {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 50%;\n  width: 35px;\n  height: 35px;\n  background-color: #ffb56a;\n  color: #ff7842;\n  margin-right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
