import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import DOMPurify from '../../../../../utils/dompurify';
import { Button } from '../../../../components/styledComponent';

function ConsentRewording({ isConfirmMessage, question, onMisunderstood, onCloseModal, onRejectSurvey, onPrevious, readOnly }) {
  const { t } = useTranslation();
  return isConfirmMessage
    ? (
      <>
        <p className="consent-confirmation-message">{t('consentFormStep.action.Refuse.confirmation_message')}</p>
        <Button variant={'danger'} onClick={onRejectSurvey} type="button">
          {t('confirm')}
        </Button>
        <Button variant={'primary'} onClick={onPrevious} type="button">
          {t('return')}
        </Button>
      </>
    ) : (
      <>
        <div
          className={'ec-rich-text-container-quill my-3'}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(question?.translation?.rewording) }}
        />
        <div className={'d-flex justify-content-end mt-2'}>
          { !readOnly && (
            <Button variant={'ghost'} onClick={onMisunderstood} type="button">
              {t('not_understand')}
            </Button>
          )}
          <Button variant={'primary'} onClick={onCloseModal} type="button">
            {t(readOnly ? 'return' : 'understand')}
          </Button>
        </div>
      </>
    );
}

ConsentRewording.propTypes = {
  isConfirmMessage: PropTypes.bool,
  question: PropTypes.object,
  onMisunderstood: PropTypes.func,
  onCloseModal: PropTypes.func,
  onRejectSurvey: PropTypes.func,
  onPrevious: PropTypes.func,
  readOnly: PropTypes.bool,
};

export default ConsentRewording;
