// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.createform-screen {
  display: flex;
  justify-content: center;
  padding: 0 16px;
}

.create-form-screen-data-container {
  width: 100%;
}

.createform-screen-data-container {
  display: flex;
}

.createform-screen .custom-select-container {
  width: 100%;
}

.createform-main-container {
  display: flex;
  flex-direction: row;
  position: relative;
}

.createform-main-container .ec-card {
  width: 100%;
}

.createform-card-add-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 216px;
  outline: none;
  border: none;
  background-color: transparent;
}

.createform-logo {
  height: 21px;
  width: 21px;
}

.createform-header-content {
  display: flex;
  flex-direction: column;
}

.createform-validate-button-container {
  position: absolute;
  margin-top: -70px;
  right: 0;
}

.createform-validate-button-container > button {
  width: 118px;
  height: 58px;
  font-size: 20px;
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/ui/screens/createFormV2/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,eAAe;AACjB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;EACX,aAAa;EACb,aAAa;EACb,YAAY;EACZ,6BAA6B;AAC/B;;AAEA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,QAAQ;AACV;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,eAAe;EACf,iBAAiB;AACnB","sourcesContent":[".createform-screen {\n  display: flex;\n  justify-content: center;\n  padding: 0 16px;\n}\n\n.create-form-screen-data-container {\n  width: 100%;\n}\n\n.createform-screen-data-container {\n  display: flex;\n}\n\n.createform-screen .custom-select-container {\n  width: 100%;\n}\n\n.createform-main-container {\n  display: flex;\n  flex-direction: row;\n  position: relative;\n}\n\n.createform-main-container .ec-card {\n  width: 100%;\n}\n\n.createform-card-add-button {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 60px;\n  height: 216px;\n  outline: none;\n  border: none;\n  background-color: transparent;\n}\n\n.createform-logo {\n  height: 21px;\n  width: 21px;\n}\n\n.createform-header-content {\n  display: flex;\n  flex-direction: column;\n}\n\n.createform-validate-button-container {\n  position: absolute;\n  margin-top: -70px;\n  right: 0;\n}\n\n.createform-validate-button-container > button {\n  width: 118px;\n  height: 58px;\n  font-size: 20px;\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
