import { connect } from 'react-redux';

import { SurveyActions } from '../../../../../redux/survey';
import ContraliaIFrameCounterSignModal from './ContraliaIFrameCounterSignModal';


const mapStateToProps = ({ survey }) => {
  return {
    survey: survey.currentSurvey,
    signUrl: survey.signUrl,
  };
};

const mapDispatchToProps = (dispatch) => ({
  genSurveySignUrlReset: () => dispatch(SurveyActions.genSurveySignUrlSuccess(null)),
  contraliaCountersignUrl: (surveyId) => dispatch(SurveyActions.contraliaCountersignUrl(surveyId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContraliaIFrameCounterSignModal);
