import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { capitalizeFirstLetter } from '../../../../utils/utils';
import { InputText } from '../../../components/formV3/controllers';
import { CustomFormController } from '../../../components/formV3/innerWrapper';
import Modal from '../../../components/modal';
import { Button } from '../../../components/styledComponent';

function StepRenderModal({ isOpen, message, showInput, onClose, onAbort, type }) {
  const { t } = useTranslation();

  const handleAbort = () => {
    onAbort(type);
  };

  return (
    <Modal hideCloseBtn isOpen={isOpen} >
      <p className="consent-confirmation-message">{ message }</p>
      {
        showInput
          ? (
            <CustomFormController name={'error_message'} >
              <InputText />
            </CustomFormController>
          ): null
      }
      <div className='w-100 d-flex justify-content-end'>
        <Button
          type="button"
          variant={'ghost'}
          onClick={onClose}
        >
          {capitalizeFirstLetter(t('return'))}
        </Button>
        <Button
          type="button"
          variant={showInput ? 'primary' : 'danger'}
          onClick={handleAbort}
        >
          {capitalizeFirstLetter(t('confirm'))}
        </Button>
      </div>
    </Modal>
  );
}

StepRenderModal.propTypes = { isOpen: PropTypes.bool, message:PropTypes.string, showInput: PropTypes.bool, onClose: PropTypes.func, onAbort: PropTypes.func };

export default StepRenderModal;
