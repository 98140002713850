import { connect } from 'react-redux';

import { AuthActions } from '../../../redux/auth';
import { LoaderActions } from '../../../redux/loader';
import { MedicActions } from '../../../redux/medic';
import Accueil from './Accueil';

const mapStateToProps = ({ medic, auth }) => {
  return {
    autoLoginData: medic.autoLoginData,
    tokenExpired: auth.token_expired,
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleTeamChange: (teamId) => dispatch(MedicActions.changeSelectedMedicTeamRequest(teamId)),
  logout: () => dispatch(AuthActions.logout(false)),
  loaded: () => dispatch(LoaderActions.loaded()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Accueil);
export { Accueil };
