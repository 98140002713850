import { intlFormat } from 'date-fns';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import iconIllu from '../../../assets/img/illu-check-easyconsent@3x.png';
import iconIlluMail from '../../../assets/img/illu-mail-envoye@3x.png';
import { parseDate } from '../../../services/datefns';
import { patientContactMapper, TYPE_CONST } from '../../../utils';
import { getUserProperty } from '../../../utils/redux-helper';
import { validEmailRegex } from '../../../utils/regex';
import AppQrCode from '../../components/AppQrCode/AppQrCode';
import Modal from '../../components/modal';
import ContactFormContainer from '../../components/patientFormV2/Steps/ContactFormContainer';
import Button from './Button';

const ID = 'PatientContactFormConsentType';

export const CONSENT_TYPES = {
  HOME_BASED: 'home-based',
  LOCALLY: 'locally',
};

const ConsentType = ({
  initialValues,
  onFinish,
  onPatientUpdate,
  onEmailRequest,
  token,
  surveyId,
  tutor,
  currentPatient,
  signatory,
}) => {
  const { t } = useTranslation();
  const patientDisplay = currentPatient && getUserProperty(currentPatient, 'fullDisplayName');
  const tutorDisplay = tutor && getUserProperty(tutor, 'fullDisplayName');
  const interventionDate = _.get(initialValues, 'intervention.date');
  const interventionDateFormated = interventionDate
    ? intlFormat(parseDate(interventionDate), {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    })
    : '';
  const [consentType, setConsentType] = useState();
  const [step, setStep] = useState(0);
  const [initialEmail, setInitialEmail] = useState('');
  const [formData, setFormData] = useState();
  const [hideFormFields, setHideFormFields] = useState([]);

  useEffect(() => {
    if (step === 1) {
      // send email to patient
      onEmailRequest(surveyId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  useEffect(() => {
    setStep(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tutor && tutor.id]);

  useEffect(() => {
    if (currentPatient) {
      const testPatient = tutor || currentPatient;
      !formData && setFormData(patientContactMapper(testPatient));
      if (initialEmail === '') setInitialEmail(testPatient.email || '');
    }
    if (step === 10) {
      handleHomeBasedConsentBtn();
    }
    if (step === 11) {
      handleQRCodeConsentBtn();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPatient, tutor, step]);

  const handleHomeBasedConsentBtn = () => {
    setConsentType(CONSENT_TYPES.HOME_BASED);
    if (
      !signatory.phone_number
      || (signatory.phone_number.length < 5)
      || !signatory.email
      || (signatory.email && !validEmailRegex.test(signatory.email))
    ) {
      // if user does not have a phone and an email
      setHideFormFields([...hideFormFields, 'city']);
      setStep(10);
    } else {
      // if user have a phone and an email
      setStep(1);
    }
  };

  const handleQRCodeConsentBtn = () => {
    setConsentType(CONSENT_TYPES.LOCALLY);
    if (
      !signatory.phone_number
      || (signatory.phone_number.length < 5)
    ) {
      // if user does not have a phone number
      setHideFormFields([...hideFormFields, 'city', 'email']);
      setStep(11);
    } else {
      // if user have a phone number
      setStep(2);
    }
  };

  const handleOnFinish = () => {
    if (onFinish) onFinish(consentType);
  };

  const handlePatientUpdate = (data) => {
    if (onPatientUpdate){
      onPatientUpdate(data, tutor ? TYPE_CONST.TUTOR : TYPE_CONST.PATIENT, currentPatient.id, tutor && tutor.id);
      handleOnFinish();
    }
  };

  const stepDisplay = (step) => {
    switch (step) {
      case 0:
        return (
          <div className={'consent-preview-finish-modal'}>
            <img style={{ width: '85px', height: '85px' }} src={iconIllu} alt="text" />
            <p className={'consent-preview-text-regular-modal'}>
              {t('consent_preview_intervention', { type: '', patientDisplay: patientDisplay || '' })}
            </p>
            <p className={'consent-preview-text-regular-modal'}>
              {t('consent_preview_intervention_date', { interventionDate: interventionDateFormated })}
            </p>
            { tutor && (
              <p className={'consent-preview-text-regular-modal'}>
                {t('consent_tutor_is', { tutorDisplay: tutorDisplay || '' })}
              </p>
            )}
            <div className={'consent-preview-button-container-modal'}>
              <Button type={CONSENT_TYPES.HOME_BASED} onClick={handleHomeBasedConsentBtn} />
              <p className={'consent-preview-separator-text'}> {t('consent_or')} </p>
              <Button type={CONSENT_TYPES.LOCALLY} onClick={handleQRCodeConsentBtn} />
            </div>
          </div>
        );
      case 1:
        return (
          <div className={'consent-preview-finish-modal'}>
            <img style={{ width: '85px', height: '85px' }} src={iconIlluMail} alt="mail" />
            <p className={'consent-preview-text-regular-modal'}>
              {t('consent_preview_mail', { patientDisplay: tutorDisplay || patientDisplay || '' })}
            </p>
            <div className="consent-preview-last-buttons-container">
              <button onClick={() => setStep(0)} type="button" className="consent-preview-validate-button">
                {t('return')}
              </button>
              <button className={'consent-preview-validate-button'} type="button" onClick={handleOnFinish}>
                {t('consent_preview_merci')}
              </button>
            </div>
          </div>
        );
      case 2:
        return (

          <div className={'consent-preview-finish-modal'}>
            <AppQrCode
              bearerToken={token}
              survey={{ id: surveyId }}
            />
            <div className="consent-preview-last-buttons-container">
              <button onClick={() => setStep(0)} type="button" className="consent-preview-validate-button">
                {t('return')}
              </button>
              <button onClick={handleOnFinish} type="button" className="consent-preview-validate-button">
                {t('consent_preview_merci')}
              </button>
            </div>
          </div>
        );
      case 10:
      case 11:
        return (
          <Modal
            show
            title={t('update_info_title', { displayName: tutor ? tutorDisplay : patientDisplay })}
            onClose={() => setStep(0)}
            scrollable
            isOpen
          >
            <ContactFormContainer
              id={ID}
              onSubmit={handlePatientUpdate}
              defaultValues={_.pick(tutor || currentPatient, ['id', 'email', 'phone_number'])}
              hideFields={_.uniq(hideFormFields)}
            />
          </Modal>
        );
      default:
        // do nothing
        return <div />;
    }
  };

  return <>{stepDisplay(step)}</>;
};

export default ConsentType;
