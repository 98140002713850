import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { FRONT_AREA } from '../../../utils/enum';
import yup from '../../../utils/yup';
import { InputText } from '../../components/formV3/controllers';

const yupObject = {
  username: yup
    .string()
    .required('authForm.username.rules.required')
    .trim(),
  password: yup
    .string()
    .min(6, 'authForm.password.rules.min')
    .required('authForm.password.rules.required')
    .trim(),
};

const yupCodeObject = {
  ...yupObject,
  code: yup.string().length(6, 'modalRenewForm.code.rules.required').required(),
};

function AttachUser({ attachUserCodeRequest, defaultValues, clearData }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isCodeForm = useMemo(() => !!defaultValues);
  const yupSchema = yup.object().shape(isCodeForm ? yupCodeObject : yupObject);

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: useMemo(() => {
      return { ...(defaultValues || {}) };
    }, [defaultValues]),
    resolver: yupResolver(yupSchema),
    shouldFocusError: true,
  });
  const { handleSubmit, watch } = formMethods;

  useEffect(() => {
    if (defaultValues?.success) {
      clearData();
      navigate('/');
    }
  }, [defaultValues?.success]);

  const onSubmit = (data) => {
    // setIsCodeForm(true);
    if (defaultValues?.uuid) {
      data.uuid = defaultValues?.uuid;
    }
    attachUserCodeRequest(data);
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)} className={'auth-patient-form'}>
        <div className={'auth-patient-content d-flex flex-column  justify-content-center'} >
          <InputText
            inputPreset={'email'}
            name={'username'}
            // readOnly={isUserNameDisable}
            placeholder={t('auth.form.username.label', { context: FRONT_AREA.PATIENT })}
            hidden
            containerClassName={'w-100 mt-4'}
            className={'t'}
          />
          <InputText
            name={'password'}
            inputPreset={'password'}
            placeholder={t('auth.form.password.label')}
            hidden
            containerClassName={'w-100 mt-4'}
            className={'t'}
            type="password"
          />
          { isCodeForm && (
            <InputText
              name={'code'}
              // readOnly={isUserNameDisable}
              placeholder={t('modalRenewForm.code.placeholder')}
              hidden
              containerClassName={'w-100 mt-4'}
              className={'t'}
            />
          )}
        </div>
        <div className={'auth-patient-content-end mt-4'}>
          <input type="submit" value={t('auth.form.submit.label')} />
        </div>
      </form>
      {!!process.env.REACT_APP_DEBUG_FORM && (
        <pre>
          { JSON.stringify(watch(), null, 2) }
        </pre>
      )}
    </FormProvider>
  );
}

AttachUser.propTypes = {
  attachUserCodeRequest: PropTypes.func.isRequired,
  clearData: PropTypes.func.isRequired,
  defaultValues: PropTypes.object,
};

export default AttachUser;
