import { createAction } from '../../utils';

// Types
export const types = {

  RESET_REDUCER: 'medic_RESET_REDUCER',

  MEDIC_TEMPLATE_GET_REQUEST: 'MEDIC_TEMPLATE_GET_REQUEST',
  MEDIC_TEMPLATE_GET_SUCCESS: 'MEDIC_TEMPLATE_GET_SUCCESS',
  MEDIC_TEMPLATE_GET_FAILURE: 'MEDIC_TEMPLATE_GET_FAILURE',

  MEDIC_TEMPLATE_GET_DETAILS_REQUEST: 'MEDIC_TEMPLATE_GET_DETAILS_REQUEST',
  MEDIC_TEMPLATE_GET_DETAILS_SUCCESS: 'MEDIC_TEMPLATE_GET_DETAILS_SUCCESS',
  MEDIC_TEMPLATE_GET_DETAILS_FAILURE: 'MEDIC_TEMPLATE_GET_DETAILS_FAILURE',

  MEDIC_TEMPLATE_ASSIGN_SUCCESS: 'MEDIC_TEMPLATE_ASSIGN_SUCCESS',
  MEDIC_TEMPLATE_ASSIGN_FAILURE: 'MEDIC_TEMPLATE_ASSIGN_FAILURE',

  MEDIC_TEMPLATE_UNASSIGN_REQUEST: 'MEDIC_TEMPLATE_UNASSIGN_REQUEST',
  MEDIC_TEMPLATE_UNASSIGN_SUCCESS: 'MEDIC_TEMPLATE_UNASSIGN_SUCCESS',
  MEDIC_TEMPLATE_UNASSIGN_FAILURE: 'MEDIC_TEMPLATE_UNASSIGN_FAILURE',

  MEDIC_TEMPLATE_UPSERT_REQUEST: 'MEDIC_TEMPLATE_UPSERT_REQUEST',
  MEDIC_TEMPLATE_UPSERT_SUCCESS: 'MEDIC_TEMPLATE_UPSERT_SUCCESS',
  MEDIC_TEMPLATE_UPSERT_FAILURE: 'MEDIC_TEMPLATE_UPSERT_FAILURE',

  MEDIC_TEMPLATE_ACTIVATE_REQUEST: 'MEDIC_TEMPLATE_ACTIVATE_REQUEST',
  MEDIC_TEMPLATE_ACTIVATE_SUCCESS: 'MEDIC_TEMPLATE_ACTIVATE_SUCCESS',
  MEDIC_TEMPLATE_ACTIVATE_FAILURE: 'MEDIC_TEMPLATE_ACTIVATE_FAILURE',

  MEDIC_TEMPLATE_DELETE_REQUEST: 'MEDIC_TEMPLATE_DELETE_REQUEST',
  MEDIC_TEMPLATE_DELETE_SUCCESS: 'MEDIC_TEMPLATE_DELETE_SUCCESS',
  MEDIC_TEMPLATE_DELETE_FAILURE: 'MEDIC_TEMPLATE_DELETE_FAILURE',

  MEDIC_TEMPLATE_FROM_STORE_ID: 'MEDIC_TEMPLATE_FROM_STORE_ID',

  GENERATE_MASS_PDF_REQUEST: 'GENERATE_MASS_PDF_REQUEST',

  SET_NEW_MEDICTEMPLATE_ID: 'SET_NEW_MEDICTEMPLATE_ID',
  CLEAR_NEW_MEDICTEMPLATE_ID: 'CLEAR_NEW_MEDICTEMPLATE_ID',
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {

  medicTemplatesGetRequest: (selectedTeam) => createAction(types.MEDIC_TEMPLATE_GET_REQUEST, { selectedTeam }),
  medicTemplatesGetSuccess: (medic_templates) => createAction(types.MEDIC_TEMPLATE_GET_SUCCESS, { medic_templates }),
  medicTemplatesGetFailure: () => createAction(types.MEDIC_TEMPLATE_GET_FAILURE),

  medicTemplatesGetDetailsRequest: (id, lngId = null) => createAction(types.MEDIC_TEMPLATE_GET_DETAILS_REQUEST, { id, lngId }),
  medicTemplatesGetDetailsSuccess: (medic_templates) => createAction(types.MEDIC_TEMPLATE_GET_DETAILS_SUCCESS, { medic_templates }),
  medicTemplatesGetDetailsFailure: () => createAction(types.MEDIC_TEMPLATE_GET_DETAILS_FAILURE),

  medicTemplateAssignSuccess: (templates) => createAction(types.MEDIC_TEMPLATE_ASSIGN_SUCCESS, { templates }),
  medicTemplateAssignFailure: () => createAction(types.MEDIC_TEMPLATE_ASSIGN_FAILURE),

  medicTemplateUnassignRequest: (id) => createAction(types.MEDIC_TEMPLATE_UNASSIGN_REQUEST, { id }),
  medicTemplateUnassignSuccess: (templateIdsList) => createAction(types.MEDIC_TEMPLATE_UNASSIGN_SUCCESS, { templateIdsList }),
  medicTemplateUnassignFailure: () => createAction(types.MEDIC_TEMPLATE_UNASSIGN_FAILURE),

  medicTemplateUpsertRequest: (template) => createAction(types.MEDIC_TEMPLATE_UPSERT_REQUEST, { template }),
  medicTemplateUpsertSuccess: () => createAction(types.MEDIC_TEMPLATE_UPSERT_SUCCESS),
  medicTemplateUpsertFailure: () => createAction(types.MEDIC_TEMPLATE_UPSERT_FAILURE),

  medicTemplateActivateRequest: (templateID, isPublished) => createAction(types.MEDIC_TEMPLATE_ACTIVATE_REQUEST, { templateID, isPublished }),
  medicTemplateActivateSuccess: (template, isPublished) => createAction(types.MEDIC_TEMPLATE_ACTIVATE_SUCCESS, { template, isPublished }),
  medicTemplateActivateFailure: () => createAction(types.MEDIC_TEMPLATE_ACTIVATE_FAILURE),

  medicTemplateDeleteRequest: (ID) => createAction(types.MEDIC_TEMPLATE_DELETE_REQUEST, { ID }),
  medicTemplateDeleteSuccess: (templateID) => createAction(types.MEDIC_TEMPLATE_DELETE_SUCCESS, { templateID }),
  medicTemplateDeleteFailure: () => createAction(types.MEDIC_TEMPLATE_DELETE_FAILURE),

  generateMassPDFRequest: (pdfs) => createAction(types.GENERATE_MASS_PDF_REQUEST, { pdfs }),

  createMedicTemplatesFromStoreId: (storeId)=> createAction(types.MEDIC_TEMPLATE_FROM_STORE_ID, { storeId }),

  setNewMedicTemplateId: (id)=> createAction(types.SET_NEW_MEDICTEMPLATE_ID, { newMedicTemplateId: id }),
  clearNewMedicTemplateId: ()=> createAction(types.CLEAR_NEW_MEDICTEMPLATE_ID),

  resetReducer: () => createAction(types.RESET_REDUCER),

};
