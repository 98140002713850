import { SIDE_CONST } from '../../../../utils/enum';

export const INITIAL_SURVEY = {
  'old_id': '',
  'title': '',
  'template_id': [],
  'clinic_id': '',
  'patient_id': '',
  'type_id': '',
  'side': [],
  'radius': [],
  'referent_medic_id': '',
  'medical_teams_id': '',
  'medic_surveys': [],
  'intervention': {
    'date_consult': '',
    'date': '',
  },
  'order': [],
  'speciality_id': '',
  'steps': [],
  'survey_pdfs': [],
  'speciality': null,
  'type': null,
};

export const INITIAL_SURVEY_TEST = {
  'old_id': '999',
  'title': 'Test Cyril et test pdf',
  'template_id': [
    '999',
    '1001',
  ],
  'clinic_id': '13519',
  'patient_id': '3381',
  'type_id': '438',
  'side': [
    SIDE_CONST.LEFT,
  ],
  'radius': [
    {
      'isDisabled': false,
      'value': 1,
      'label': 'Auriculaire',
    },
    {
      'isDisabled': false,
      'value': 2,
      'label': 'Annulaire',
    },
    {
      'isDisabled': false,
      'value': 3,
      'label': 'Majeur',
    },
    {
      'isDisabled': false,
      'value': 4,
      'label': 'Index',
    },
    {
      'isDisabled': false,
      'value': 5,
      'label': 'Pouce',
    },
  ],
  'referent_medic_id': '760',
  'medical_teams_id': '481',
  'medic_surveys': [
    {
      'isOwner': true,
      'label': 'Dr GARBAY',
      'value': '760',
    },
    {
      'label': 'HOUSE',
      'value': '761',
    },
    {
      'label': 'Dr C',
      'value': '773',
    },
  ],
  'intervention': {
    'date_consult': '2021-07-29',
    'date': '2021-08-14',
  },
  'order': [
    {
      'id': -1,
      'order': 1,
    },
    {
      'id': 1001,
      'order': 2,
    },
  ],
  'speciality_id': 276,
  'steps': [
    {
      'title': "Titre de l'étape",
      'number': 1,
      'questions': [
        {
          'id': 312062,
          'question': 'Votre Question Choix Multiple',
          'number': 1,
          'data': {
            'list': [
              {
                'id': '3c40b1a3-92f4-4798-8f09-bbeaf09fef00',
                'value': 'Premier choix',
              },
              {
                'id': 'fbf68d88-f53a-4e01-8fae-ed29e8983b68',
                'value': '2',
              },
              {
                'id': 'b19a659c-b612-4f3a-a95c-1783746aa730',
                'value': '3',
              },
            ],
          },
          'desc': '<p>sdasdad</p>',
          'type_id': 5,
          'rewording': '',
          'texte_pdf': null,
          'incompris': null,
          '__typename': 'questions',
        },
        {
          'id': 312063,
          'question': 'Votre Question Choix Multiple',
          'number': 2,
          'data': {
            'list': [
              {
                'id': '86c0d854-5f88-4cb8-9e62-ed93caa3da3a',
                'value': 'Premier choix',
              },
            ],
          },
          'desc': '<p>test</p>',
          'type_id': 5,
          'rewording': '',
          'texte_pdf': null,
          'incompris': null,
          '__typename': 'questions',
        },
      ],
      '__typename': 'steps',
    },
  ],
  'survey_pdfs': [
    [
      {
        'id': 1380,
        'loid': 27080,
        'name': 'test pdf-Easy-Consent - Devis dev ete 2021 Melon Mellon signe 20-07-2021.pdf',
        '__typename': 'survey_pdfs',
      },
    ],
  ],
  'speciality': { 'value': 276 },
  'type': { 'value': 438 },
};