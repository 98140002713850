import './style.css';

import _ from 'lodash';
import React, { useEffect, useState } from 'react';

import InfiniteLoadList from './InfiniteLoadList';
import ItemSkeleton from './ItemSkeleton';
import ListHeader from './ListHeader';

const List = ({ patientsList, medicId, selectedFilter, onClick, isListLoading, selectedTeam, isClinicalMode }) => {
  const [chunk, setChunk] = useState();

  useEffect(() => {
    if ( Array.isArray(patientsList)) {
      setChunk(_.chunk(patientsList, 10));
    }
    return function cleanUp(){
      setChunk();
    };
  }, [JSON.stringify(patientsList)]);

  return (
    <div className={` mx-2 list-patients ${patientsList.length > 0 ? '' : 'list-patients-empty'}`}>
      <ListHeader />
      { isListLoading || !chunk
        ? _.times(8, (i) => <ItemSkeleton key={`patient-skeleton-${i}`} skeleton />)
        : <InfiniteLoadList
          chunk={chunk}
          onClick={onClick}
          selectedTeam={selectedTeam}
          isClinicalMode={isClinicalMode}
          medicId={medicId}
          selectedFilter={selectedFilter}
        />
      }
    </div>
  );
};

export default List;
