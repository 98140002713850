
import _ from 'lodash';
import { connect } from 'react-redux';

import { MedicActions } from '../../../redux/medic';
import { SurveyActions } from '../../../redux/survey';
import Profil from './Profil';


const mapStateToProps = ({ survey, medic }) => {
  const currentMedic = _.get(medic, 'currentMedic');
  const newMedicSpecialities = currentMedic && Array.isArray(currentMedic.medicSpecialityIds) ? currentMedic.medicSpecialityIds : [];
  return {
    currentMedic: currentMedic,
    medicTeam: Object.values(medic.medicalTeamList || {}),
    medicSpecialities: Object.values(_.chain(survey).get('specialities').pick(newMedicSpecialities).value() || {}),
    selectedTeam: medic.selectedTeam,
  };
};

const mapDispatchToProps = (dispatch) => ({

  updateMedicRequest: (medic) => dispatch(MedicActions.updateMedicRequest(medic)),
  medicUpdateNotifRequest: (medic) => dispatch(MedicActions.medicUpdateNotifRequest(medic)),
  specialitiesRequest: () => dispatch(SurveyActions.specialitiesRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profil);
