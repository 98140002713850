import './style.css';

import _ from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import iconButton from '../../../assets/img/cicon.png';
import { AbilityContext } from '../../../services/casl/Can';
import { MODEL_NAMES, PATIENT_PAGE, PERMISSIONS } from '../../../services/casl/roles';
import {
  prepareContactForUpdate,
  prepareInfoForUpdate,
  prepareTutorForUpdate,
  SURVEY_STATUS,
  TYPE_CONST,
  USER_ROLE,
} from '../../../utils';
import Card from '../../components/card';
import NavigateBack from '../../components/navigateBack';
import List from '../../components/patientDetails/list';
import { ACTIONS } from '../../components/patientDetails/list/DocumentChoice';
import EditPatientContact from '../../components/patientForm/EditPatientContact';
import EditPatientInfo from '../../components/patientForm/EditPatientInfo';
import EditTutorsContact from '../../components/patientForm/EditTutorsContact';
import { Button } from '../../components/styledComponent';
import CreateSurvey from '../../components/surveyFormV2';
import AuthLayout from '../../layouts/authLayout';
import CardButton from './cardButton';

const ROLE = USER_ROLE.MEDIC;

const InfoPatient = ({
  patientDetailRequest,
  logginUserRole,
  bearerToken,
  selectedPatient,
  patientList,
  patientUpdateRequest,
  patientArchiveRequest,
  checkIfEmailExistsInDb,
  resumeSurveyRequest,
  uploadSignRequest,
  resetSurveySigned,
  resetPatientUploadSuccess,
  generateSurveyPDF,
  generateSurveyDraw,
  resetGeneratedURL,
  selectedTeam,
  currentMedic,
  emailAvailable,
  emailLoading,
  uploadSuccess,
  surveyIsSigned,
  isLoadingPatient,
  deleteSurveyRequest,
  specialitiesRequest,
  surveyTypesRequest,
  types,
  autoLoginSurveyID,
  resetAutoLoginData,
  isAutoLoginLoaded,
  displayInfo,
  clinicRequest,
  deleteAuthToken,
  clearSelectedPatient,
  clearData,
  isClinicalMode,
  counterSigned,
}) => {
  const { patientId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const ability = useContext(AbilityContext);
  const [selectedTutorsIndex, setSelectedTutorsIndex] = useState(0);
  const [tutorsId, setTutorsId] = useState([]);
  const [surveyList, setSurveyList] = useState([]);
  const [isTutor, setIsTutor] = useState(false);
  const [tutors, setTutors] = useState();
  const [addButton, setAddButton] = useState(false);
  const [isOpenEditContactModal, setIsOpenEditContactModal] = useState(false);
  const [isOpenEditInfoModal, setIsOpenEditInfoModal] = useState(false);
  const [preselectedSurveyID, setPreselectedSurveyID] = useState(-1);
  const [modalToggle, setModalToggle] = useState({ show: false, type: null });
  const [isLoading, setIsLoading] = useState(true);
  const [displayedSurveys, setDisplayedSurveys] = useState([]);
  const [searched, setSearched] = useState(null);
  const patient = useMemo(() => selectedPatient || _.get(patientList, patientId, {}), [patientId, selectedPatient, patientList]);
  const canCreateConsent = useMemo(() => ability.can(PERMISSIONS.READ, { __typename: MODEL_NAMES.PATIENT_PAGE, value: PATIENT_PAGE.ADD_FORM_TO_PATIENT }), [ability]);

  useEffect(() => {
    if (specialitiesRequest) specialitiesRequest();
    if (surveyTypesRequest) surveyTypesRequest();
    if (clinicRequest) clinicRequest();
  }, []);

  useEffect(() => {
    if (patientDetailRequest && patientId) patientDetailRequest(patientId, selectedTeam);
    return () => {
      if (clearSelectedPatient) clearSelectedPatient();
    };
  }, [patientId, selectedTeam]);

  useEffect(() => {
    if (((typeof counterSigned === 'boolean') || (counterSigned instanceof Boolean)) && counterSigned === true) {
      if (patientId) patientDetailRequest && patientDetailRequest(patientId, selectedTeam);
    }
  }, [counterSigned]);

  useEffect(() => {
    setIsLoading(false);
  }, [JSON.stringify(displayedSurveys)]);

  useEffect(() => {
    const newDisplayedSurveys = filtered(surveyList, searched, tutorsId)
      .filter(s => s.medical_teams_id === selectedTeam);
    if (!_.isEqual(newDisplayedSurveys, displayedSurveys)) {
      setDisplayedSurveys(newDisplayedSurveys);
    }
  }, [JSON.stringify(surveyList), searched, tutorsId, selectedTeam]);

  useEffect(() => {
    if (patient.tutorsByPatientId && patient.tutorsByPatientId.length >= 1) {
      setIsTutor(true);
      setTutors(patient.tutorsByPatientId);
      setTutorsId(patient.tutorsByPatientId.map((t) => t.signatory.id));
    } else {
      setTutorsId([]);
      setIsTutor(false);
      setTutors([]);
    }
  }, [patient && patient.tutorsByPatientId]);

  useEffect(() => {
    const { surveys } = patient;
    if (Array.isArray(surveys)) {
      setSurveyList(surveys);
      /*
        autoLoginSurveyID comes from splash screen when medic connects from mail to counter sign
        it allows us to toggle counter sign modal from this
      */
      if (autoLoginSurveyID > -1) {
        const currentSurvey = surveys.filter(s => s.id === autoLoginSurveyID && [SURVEY_STATUS.ACCEPTED, SURVEY_STATUS.COUNTERSIGNED].includes(s.status))[0];
        if (currentSurvey) {
          let action = ACTIONS.BUILD_PDF;
          if (currentSurvey.status === SURVEY_STATUS.COUNTERSIGNED) {
            action = ACTIONS.COUNTERSIGNED_INFO_MESSAGE;
          }
          else setPreselectedSurveyID(autoLoginSurveyID);
          handleOnSurveyClick({
            action: action,
            survey: { id: autoLoginSurveyID },
            tutor: currentSurvey.tutored_id > 0 ? patient.tutorsByPatientId.filter(t => t.signatory && t.signatory.id === currentSurvey.tutored_id)[0] : null,
            toDisplay: false,
            saveUser: true,
          });
        }
        if (resetAutoLoginData) resetAutoLoginData();
      }
    }
  }, [patient && JSON.stringify(patient)]);

  useEffect(() => {
    if (uploadSuccess === true) {
      resetPatientUploadSuccess();
    }
  }, [uploadSuccess]);

  const onBackClick = () => {
    navigate(selectedTeam ? `/teams/${selectedTeam}` : '/');
  };

  const handleIndexChange = (newIndex) => {
    setSelectedTutorsIndex(newIndex);
  };

  const handleSearch = (filters) => {
    const newFilter = Array.isArray(filters) ? filters.map((f) => f.id) : null;
    if (!_.isEqual(searched, newFilter)) {
      setIsLoading(true);
      setSearched(newFilter);
    }
  };

  const onAvailableEmail = (email) => {
    if (checkIfEmailExistsInDb) checkIfEmailExistsInDb(email, patient && patient.user_id);
  };

  const handleCloseModal = (e) => {
    e.preventDefault();
    setIsOpenEditContactModal(false);
    setIsOpenEditInfoModal(false);
  };

  const handleModal = (val) => {
    setIsOpenEditContactModal(val);
  };

  const handleInfoModal= (val) => {
    setIsOpenEditInfoModal(val);
  };

  const onUpdate = (toUpdate, type) => {
    switch(type) {
      case TYPE_CONST.PATIENT:
        const formatedData = prepareContactForUpdate(toUpdate);
        if (patientUpdateRequest) {
          patientUpdateRequest(patientId, formatedData);
        }
        setIsOpenEditContactModal(false);
        break;
      case TYPE_CONST.TUTOR:
        const formatedDataT = prepareTutorForUpdate(toUpdate);
        if (patientUpdateRequest && tutors && Array.isArray(tutors)) {
          patientUpdateRequest(tutors[selectedTutorsIndex].signatory.id, formatedDataT, ROLE);
        }
        setIsOpenEditContactModal(false);
        break;
      case TYPE_CONST.INFO:
        const formatedDataI = prepareInfoForUpdate(toUpdate, patient.email);
        if (patientUpdateRequest) {
          patientUpdateRequest(patientId, formatedDataI, ROLE);
        }
        setIsOpenEditInfoModal(false);
        break;
      default:
        // code block
    }
  };

  const filtered = (list, searched, tutorsId) => {
    if (Array.isArray(searched)) {
      return searched.map(id => list.find(s => s?.intervention_id === id)).filter(el => !!el);
    } else if (tutorsId && tutorsId.length && selectedTutorsIndex !== 0) {
      return list.filter((s) => s.tutored_id === tutorsId[selectedTutorsIndex - 1]);
    }
    return list;
  };

  const handleOnSurveyClick = ({ action, survey, toDisplay = true }) => {
    switch (action) {
      case ACTIONS.BUILD_PDF:
        generateSurveyPDF(survey.id, toDisplay);
        break;
      case ACTIONS.RESET_PDF_URL:
        resetGeneratedURL();
        break;
      case ACTIONS.BUILD_DRAW:
        generateSurveyDraw(survey, toDisplay);
        break;
      case ACTIONS.BUILD_ATTACHMENT:
        generateSurveyDraw({
          ...survey,
          survey_drawing: survey.survey_attachment,
        }, toDisplay);
        break;
      case ACTIONS.UPLOAD_SIGN:
        uploadSignRequest({
          ...survey,
          patient: patient,
        });
        break;
      case ACTIONS.RESUME:
        survey && survey.id && resumeSurveyRequest(survey.id);
        break;
      case ACTIONS.DELETE:
        if (deleteSurveyRequest) {
          deleteSurveyRequest(patientId, survey.map(s => s.id));
        }
        break;
      case ACTIONS.COUNTERSIGNED_INFO_MESSAGE:
        if (displayInfo) displayInfo('survey_is_already_countersigned');
        break;
      case ACTIONS.DELETE_TOKEN:
        if (deleteAuthToken) deleteAuthToken();
        break;
      default:
        break;
    }
  };

  const onResetIsSurveySigned = () => {
    if (resetSurveySigned) {
      resetSurveySigned();
    }
  };

  const handleCardClick = (e, type) => {
    setModalToggle({
      ...modalToggle,
      show: true,
      type: type,
    });
  };

  const modaSurveylHandler = (e) => {
    e && e.preventDefault();
    clearData && clearData();

    setModalToggle({
      ...modalToggle,
      show: false,
    });
  };

  const handlePatientArchiveRequest = () => {
    patientArchiveRequest(patient.id, selectedTeam, navigate);
  };

  return (
    <AuthLayout>
      <div className={'infopatient-main-container'}>
        <div className={'infopatient-info'}>
          <div className={'infopatient-info-contact'}>
            <NavigateBack text={t('dashboard')} onClick={onBackClick} />
            <EditPatientInfo
              onCloseModal={handleCloseModal}
              patient={patient}
              onUpdate={(data) => onUpdate(data, TYPE_CONST.INFO)}
              isLoadingPatient={isAutoLoginLoaded || isLoadingPatient}
              isOpenModal={isOpenEditInfoModal}
              onModal={handleInfoModal}
              patientArchiveRequest={handlePatientArchiveRequest}
              selectedTeam={selectedTeam}
              isClinicalMode={isClinicalMode}
            />
          </div>
          <div className={'infopatient-info-information'}>
            <Card>
              { isTutor && tutors && tutors.length
                ? (
                  <EditTutorsContact
                    tutor={_.get(patient, ['tutorsByPatientId', selectedTutorsIndex, 'signatory'])}
                    tutorsCount={tutors.length}
                    indexChange={handleIndexChange}
                    onUpdate={(data) => onUpdate(data, TYPE_CONST.TUTOR)}
                    emailAvailable={emailAvailable}
                    emailLoading={emailLoading}
                    onAvailableEmail={onAvailableEmail}
                    isLoadingPatient={isAutoLoginLoaded || isLoadingPatient}
                    isOpenModal={isOpenEditContactModal}
                    onModal={handleModal}
                  />
                ) : (
                  <EditPatientContact
                    onUpdate={(data) => onUpdate(data, TYPE_CONST.PATIENT)}
                    patient={patient}
                    emailAvailable={emailAvailable}
                    emailLoading={emailLoading}
                    onAvailableEmail={onAvailableEmail}
                    isLoadingPatient={isAutoLoginLoaded || isLoadingPatient}
                    isOpenModal={isOpenEditContactModal}
                    onModal={handleModal}
                    isClinicalMode={isClinicalMode}
                  />
                )}
            </Card>
          </div>
        </div>
        <div className={'infopatient-historique'}>
          <Card>
            <div className={'infopatient-interventions'}>
              <div className={'infopatient-segmented-control'}>
                <div className={'infopatient-interventions-header'}>
                  <p className={'infopatient-text-interventions-name '}>
                    {t('patient_text_interventions')}
                  </p>
                </div>
                <div className={'infopatient-interventions-cotainer-button'}>
                  <Button
                    onClick={(e) => canCreateConsent && setAddButton(!addButton)}
                    minWidth={0}
                    padding={0}
                    disabled={!canCreateConsent}
                  >
                    <img
                      src={iconButton}
                      alt="infopatient-button"
                    />
                  </Button>
                  {addButton === true ? (
                    <CardButton
                      onClick={handleCardClick}
                      onClose={() => setAddButton(false)}
                    />
                  ) : null}
                </div>
              </div>
              { modalToggle.show && (
                <CreateSurvey
                  onCloseModal={modaSurveylHandler}
                  patient={patient}
                  paperMode={modalToggle.type === 'paper'}
                  isModalOpen={modalToggle.show}
                />
              )}
              <List
                logginUserRole={logginUserRole}
                bearerToken={bearerToken}
                medic={currentMedic}
                patient={patient}
                surveys={displayedSurveys}
                tutors={tutors}
                onClick={handleOnSurveyClick}
                isSigned={surveyIsSigned}
                onResetIsSigned={onResetIsSurveySigned}
                isLoading={isAutoLoginLoaded || isLoadingPatient || isLoading}
                onSearch={handleSearch}
                types={types}
                preselectedID={preselectedSurveyID}
                resetAutoLoginSurveyID={resetAutoLoginData}
                isClinicalMode={isClinicalMode}
              />
            </div>
          </Card>
        </div>
      </div>
    </AuthLayout>
  );
};

export default InfoPatient;
