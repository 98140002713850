
import PropTypes from 'prop-types';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import * as ReactInputMask from 'react-input-mask';

const InputMask = ({
  name,
  rules,
  inputPreset,
  showPswdHelper,
  hidden,
  isLoading,
  success,
  defaultValue,
  className,
  containerClassName,
  disabled,
  control,
  preset,
  mask,
  maskChar='_',
  alwaysShowMask=false,
  formatChars={
    '9': '[0-9]',
    'a': '[A-Za-z]',
    '*': '[A-Za-z0-9]',
  },
  permanents,
  ...extraInputProps
}) => {
  const methods = useFormContext();
  const { field: { ref, ...field } } = useController({
    name,
    control: control || methods.control,
    rules,
    defaultValue,
  });
  return (
    <ReactInputMask
      className={`${className || 'createPatient-Input'} ${disabled ? 'inputDisabled' : ''} `}
      mask={mask}
      maskChar={maskChar}
      alwaysShowMask={alwaysShowMask}
      formatChars={formatChars}
      permanents={permanents}
      inputRef={ref}
      {...preset}
      {...field}
      {...extraInputProps}
    />
  );
};

const areEqual = (prevProps, nextProps) => {
  return prevProps.name !== nextProps.name;
};

InputMask.propTypes = {
  register: PropTypes.func,
  name: PropTypes.string,
  control: PropTypes.object,
  defaultValue: PropTypes.string,
  rules: PropTypes.object,
  inputProps: PropTypes.object,
  inputPreset: PropTypes.oneOf(['security_number', 'password', 'email', 'postcode', 'rpps_number']),
  hidden: PropTypes.bool,
  isLoading: PropTypes.bool,
  error: PropTypes.bool,
  success: PropTypes.bool,
  type: PropTypes.oneOf(['button', 'checkbox', 'color', 'date', 'datetime-local', 'email', 'file', 'hidden', 'image', 'month', 'number', 'password', 'radio', 'range', 'reset', 'search', 'submit', 'tel', 'text', 'time', 'url', 'week']),
};

export default React.memo(InputMask, areEqual);
