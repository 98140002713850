import './style.css';

import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import iconBack from '../../../../assets/img/icon-back.png';
import { PUBLIC_HOME_PATH } from '../../../../utils/const';
import { FRONT_AREA } from '../../../../utils/enum';
import LoginLayout from '../../../layouts';
import { LOST_PSWD_STEP } from '..';
import ModalRenew from '../option/ModalRenew';
import AuthForm from './AuthForm';
import OtpForm from './OtpForm';
;

const INITIAL_STEP = [LOST_PSWD_STEP.A_FILL_USERNAME];

const Auth = ({
  loginRequest,
  isPro,
  omitData,
  oauthOtpUuid,
  loginFromOtpRequest,
  logout,
  authorizationRequest,
}) => {
  const { t } = useTranslation();
  const [defaultValues, setDefaultValues] = useState({ username: '', password: '' });
  const [isUserNameDisable, setIsUserNameDisable] = useState(false);
  const [steps, setSteps] = useState(INITIAL_STEP);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const handleForgottenPassword = (e) => {
    e.preventDefault();
    setSteps(INITIAL_STEP);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setSteps(INITIAL_STEP);
    omitData('ModalRenew');
    setIsModalOpen(false);
  };

  const onSubmit = data => {
    authorizationRequest(data.username, data.password);
    // loginRequest(data.username, data.password);
  };

  const handlePrevious = () => {
    navigate(PUBLIC_HOME_PATH);
  };

  useEffect(() => {
    const username = searchParams.get('username');
    const decodedUsername = username && decodeURIComponent(username);
    if (decodedUsername && decodedUsername !== _.get(defaultValues, 'username')) {
      setDefaultValues({ ...defaultValues, 'username': decodedUsername });
      setIsUserNameDisable(isPro ? true : false);
    }
  }, [searchParams]);

  const handleStepCompletion = async (val) => {
    const currentStep = steps[steps.length - 1];
    let newStep = val || currentStep + 1;
    if (newStep > _.size(LOST_PSWD_STEP) - 1 ) {
      handleModalClose();
    }
    switch (newStep) {
      case LOST_PSWD_STEP.A_FILL_EMAIL:
        if (val !== LOST_PSWD_STEP.A_FILL_EMAIL && currentStep === LOST_PSWD_STEP.A_FILL_USERNAME){
          newStep = LOST_PSWD_STEP.B_FILL_CODE;
        }
        break;
      case LOST_PSWD_STEP.A_FILL_BIRTHDATE: {
        newStep = LOST_PSWD_STEP.B_FILL_CODE;
        break;
      }
      default:
          // this gets called
    }
    setSteps([...steps, newStep]);
  };

  const handlePreviousStep = () => {
    const newStep = [...steps];
    newStep.pop();
    setSteps(newStep);
    if (!newStep.length){
      handleModalClose();
    }
  };

  const handleOtp = (val) => {
    if (val.code){
      loginFromOtpRequest(val.code);
    }
  };

  return (
    <LoginLayout>
      {
        !!oauthOtpUuid
          ? (
            <div className={`${!!oauthOtpUuid ? 'visible' : 'd-none'}` }>
              <OtpForm onSubmit={handleOtp} onCancel={logout} />
            </div>
          ) : (
            <div className={'auth-patient-card '}>
              <div className={'auth-patient-header-content'}>
                <button onClick={handlePrevious}>
                  <img src={iconBack} alt="previous" />
                </button>
                <p>{t('auth.title', { context: isPro ? FRONT_AREA.PRO : FRONT_AREA.PATIENT })}</p>
              </div>


              <AuthForm
                defaultValues={defaultValues}
                onSubmit={onSubmit}
                handleForgottenPassword={handleForgottenPassword}
                isUserNameDisable={isUserNameDisable}
                isPro={isPro}
              />
              { isModalOpen && (
                <ModalRenew
                  step={steps[steps.length - 1]}
                  onModalClose={handleModalClose}
                  isModalOpen={isModalOpen}
                  isPro={isPro}
                  onStepCompletion={handleStepCompletion}
                  onPreviousStep={handlePreviousStep}
                  shouldUseBirthDate={false}
                />
              )}
            </div>
          )
      }
    </LoginLayout>
  );
};

export default Auth;
