
import { connect } from 'react-redux';

import QuestionComponent from './QuestionComponent';

const mapStateToProps = ({ survey }) => {
  return { questionTypes: Object.values((survey && survey.questionTypes) || {}) };
};

export default connect(mapStateToProps)(QuestionComponent);
