import './style.css';

import React from 'react';

import { Button } from '../../styledComponent';

const ModalButtons = ({
  firstText,
  secondText,
  onFirstClick,
  onSecondClick,
  hideFirst,
  hideSecond,
  disableFirst,
  disableSecond,
}) => {

  const handleOnFirstClick = (e) => {
    if (disableFirst) return;
    if (onFirstClick) onFirstClick(e);
  };
  const handleOnSecondClick = (e) => {
    if (disableSecond) return;
    if (onSecondClick) onSecondClick(e);
  };
  return (
    <div className={'d-flex flex-column flex-sm-row justify-content-end style'}>
      {!hideFirst && (
        <>
          <Button
            variant="ghost"
            onClick={handleOnFirstClick}
            disabled={disableFirst}
            type="button"
          >
            {firstText}
          </Button>
        </>
      )}
      {!hideSecond && (
        <>
          <Button
            variant="primary"
            onClick={handleOnSecondClick}
            disabled={disableSecond}
            type="button"
          >
            {secondText}
          </Button>
        </>
      )}
    </div>
  );
};

export default ModalButtons;
