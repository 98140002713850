import '../style.css';

import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { AbilityContext } from '../../../../services/casl/Can';
import { CONSENT_COMPONENT, MODEL_NAMES, PERMISSIONS } from '../../../../services/casl/roles';
import { SIDE_CONST } from '../../../../utils/enum';
import Card from '../../../components/card';
import {
  InputCheckbox, InputGroupedCheckbox, InputSelect, InputSelectV2, InputTags, InputText,
} from '../../../components/formV3/controllers';
import { SurveyFormController } from '../../../components/formV3/innerWrapper';
import styles from './style.module.css';

// # consentPreview is used when attribution of the consent to a patient. edit step
// # isConsent is used when the patient is completing the consent
const ConsentHeader = ({
  disabled,
  surveyTypesOptions,
  specialitiesOptions,
  readOnly,
  medicTeam,
  isMultipleMedicTeams,
  preview,
  radiusOptions,
  isConsent,
  initialValues,
  survey,
  consentPreview,
}) => {
  const { t } = useTranslation();
  const ability = useContext(AbilityContext);

  const isTitleReadOnly = () => {
    if (consentPreview && initialValues.title === ''){
      return false;
    }
    return readOnly || !ability.can(PERMISSIONS.UPDATE, { __typename: MODEL_NAMES.CONSENT_COMPONENT, value: CONSENT_COMPONENT.CONSENT_HEADER } );
  };

  return (
    <div className={`${isConsent ? 'ec-consentheader-consent-mode' : 'w-100'}`}>
      <Card>
        <div className={'my-0 mx-4 d-flex flex-column'}>
          { isConsent
            ? (
              <p className={'ec-consentheader-title-static'} data-testid="consentHeader-title">
                {survey && survey.title}
              </p>
            ): (
              <>
                {/* TITLE */}
                <SurveyFormController name={[isConsent || consentPreview ? false : 'translation', 'title'].filter(el => !!el).join('.')} controlClassName="px-2 w-100" >
                  <InputText
                    className={`mb-2 w-100 ${styles.title}`}
                    placeholder={preview ? '' : t('surveyCreateForm.header.title.placeholder')}
                    readOnly={isTitleReadOnly()}
                    disabled={isTitleReadOnly()}
                  />
                </SurveyFormController>
                <div className="d-flex flex-column flex-md-row my-2">
                  <SurveyFormController name={'type_id'} controlClassName="px-2 w-100">
                    <InputSelectV2
                      disabled={ readOnly || !ability.can(PERMISSIONS.UPDATE, { __typename: MODEL_NAMES.CONSENT_COMPONENT, value: CONSENT_COMPONENT.CONSENT_HEADER }) }
                      options={ surveyTypesOptions}
                      components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                      placeholder={t('surveyCreateForm.header.type.placeholder')}
                      displayAsText={readOnly}
                    />
                  </SurveyFormController>
                  <SurveyFormController name={'speciality_id'} controlClassName="px-2 w-100">
                    <InputSelectV2
                      disabled={readOnly || !ability.can(PERMISSIONS.UPDATE, { __typename: MODEL_NAMES.CONSENT_COMPONENT, value: CONSENT_COMPONENT.CONSENT_HEADER })}
                      options={specialitiesOptions}
                      placeholder={t('surveyCreateForm.header.speciality.placeholder')}
                      components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                      displayAsText={readOnly}
                    />
                  </SurveyFormController>
                </div>
                {/*   Intervention Type           Intervention Specialty
                    Hide : if isPatientReading
              */}
                { consentPreview
                  ? (
                    <div >
                      { _.get(initialValues, 'side', 'off') !== 'off' && (
                        <SurveyFormController
                          name={'side'}
                          label={t('surveyCreateForm.header.side.label')}
                          labelClassName={'createPatient-text'}
                          controlClassName={'d-flex flex-column my-2'}
                        >
                          <InputGroupedCheckbox
                            readOnly={readOnly || !ability.can(PERMISSIONS.READ, { __typename: MODEL_NAMES.CONSENT_COMPONENT, value: CONSENT_COMPONENT.CONSENT_HEADER })}
                            options={Object.values(SIDE_CONST).map(s => ({
                              value: s,
                              label: t(s),
                            }))}
                            containerClassName="ec-checkbox-text-consent-header "
                          />
                        </SurveyFormController>
                      )}
                      { _.get(initialValues, 'radius', 'off') !== 'off' && (
                        <SurveyFormController
                          name={'radius'}
                          label={t('surveyCreateForm.header.radius.label')}
                          labelClassName={'createPatient-text'}
                        >
                          <InputSelect
                            readOnly={readOnly || !ability.can(PERMISSIONS.READ, { __typename: MODEL_NAMES.CONSENT_COMPONENT, value: CONSENT_COMPONENT.CONSENT_HEADER })}
                            options={radiusOptions}
                            isMulti
                            grouped
                          />
                        </SurveyFormController>
                      )}
                    </div>
                  ) : (
                    <>
                      <div className="my-2">
                        <SurveyFormController
                          name={'isRadius'}
                        >
                          <InputCheckbox
                            label={t('surveyCreateForm.header.isRadius.label')}
                            className={styles.radius}
                            disabled={readOnly}
                          />
                        </SurveyFormController>
                        <SurveyFormController
                          name={'isSide'}
                        >
                          <InputCheckbox
                            label={t('surveyCreateForm.header.isSide.label')}
                            className={styles.side}
                            disabled={readOnly}
                          />
                        </SurveyFormController>
                      </div>
                      <SurveyFormController name={[isConsent || consentPreview ? '' : 'translation', 'description'].join('.')}>
                        <InputTags
                          disabled={disabled}
                          placeholder={t('surveyCreateForm.header.description.placeholder')}
                        />
                      </SurveyFormController>
                      { isMultipleMedicTeams && (
                        <div className="d-flex justify-content-center w-100 my-2">
                          <div className="p-3 border border-danger rounded">
                            <Trans
                              i18nKey="surveyCreateForm.header.medicTeamDiscaler"
                              values={{ medicTeamTitle : _.get(medicTeam, 'title', '') }}
                              components={{ b: <strong /> }}
                            />
                          </div>
                        </div>
                      )}
                    </>
                  )
                }
              </>
            )
          }
        </div>
      </Card>
    </div>
  );
};

export default ConsentHeader;


ConsentHeader.propTypes = {
  isConsent: PropTypes.bool,
  isForm: PropTypes.bool,
  value:PropTypes.object,
  disabled: PropTypes.bool,
  surveyTypes: PropTypes.array,
  specialities: PropTypes.array,
  currentType: PropTypes.number,
  currentSpecialty: PropTypes.number,
  disabledType: PropTypes.bool,
  disabledSpecialty: PropTypes.bool,
  createMode: PropTypes.bool,
  readOnly: PropTypes.bool,
};
