import './style.css';

import React, { useState } from 'react';
import { isBrowser, isIOS13 } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import Question from '../../Menu/Question';


const Dropzone = ({ onChange, questionTypes, allowedValues }) => {
  const { t } = useTranslation();
  const [displayItems, setDisplayItems] = useState(false);

  const onDragOver = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'move';
  };

  const onDrop = (event) => {
    event.preventDefault();
    const type = event.dataTransfer.getData('text/plain');
    if (onChange) onChange(type);
  };

  const renderBrowserZone = () => (
    <div
      className="droppable consent-step-dropzone-container"
      onDragOver={(e) => onDragOver(e)}
      onDrop={(e) => onDrop(e)}
    >
      <p className="consent-step-dropzone-container-text">
        {t('form_screen_slide_a_componant')}
      </p>
    </div>
  );

  const handleClick = (value) => (e) => {
    e.stopPropagation();
    if (allowedValues){
      if (allowedValues && Array.isArray(allowedValues) ? allowedValues.includes(value) : allowedValues === value){
        onChange(value);
        setDisplayItems(false);
      }
    }
  };

  const renderMobileZone = () => (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-static-element-interactions
    <div
      className="droppable consent-step-dropzone-container"
      onClick={() => setDisplayItems(true)}
    >
      {!displayItems && (
        <p className="consent-step-dropzone-container-text">
          {t('form_screen_add_a_componant')}
        </p>
      )}
      { displayItems && (
        <div className={'consent-step-mobile-items'}>
          { questionTypes.map(({ value }) => {
            return (
              <Question
                key={`question_type_${value}`}
                type={value}
                text={t(`question_type_${value}`)}
                onClick={handleClick(value)}
              />
            );
          })}
        </div>
      ) }
    </div>
  );

  return <>{isBrowser && !isIOS13 ? renderBrowserZone() : renderMobileZone()}</>;
};

export default Dropzone;
