
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';

import Select from '../../form/InputSelect';

function SearchTag({ selectTags, index, saveTag, deleteTag, tagText }, ref) {
  const inputRef = useRef(null);
  const [editing, setEditing] = useState(true);

  useImperativeHandle(ref, () => ({ focus:() => handleOnFocus() }));

  const handleOnFocus = () => {
    inputRef?.current?.focus();
  };

  useEffect(() => {
    let input = inputRef.current;
    selectTags && selectTags.Status.map((i) => {
      if (i.isSelected) i.isSelected = false;
      return i;
    });
    if (input) {
      input.focus();
    }
  });

  useEffect(() => {
    let input = inputRef.current;
    if (input) {
      input.focus();
      let range = document.createRange();
      range.selectNodeContents(input);
      range.collapse(false);
      let selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }, [inputRef, document, window]);

  const setValideText = (text ) => {
    let tmp = '';
    switch (text) {
      case ' Refused':
        tmp = ' refusé';
        break;
      case ' Sent':
        tmp = ' en cours';
        break;
      case ' Accepted':
        tmp = ' signé';
        break;
      default:
        return tagText.split(':')[1];
    }
    return tmp;
  };

  const blockEnter = (event ) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      setEditing(false);
      let text = event.target.previousSibling.innerText + event.target.innerText;
      saveTag(index, text);
    }
    const keys = Object.keys(selectTags || {});
    if (keys.includes(event.name)) {
      setEditing(false);
      event.value.forEach((value, index) => {
        if (value.isSelected)
          saveTag(index, event.name + ': ' + value.type.db);
      });
    }
  };

  const handleDeleteTag = () => {
    deleteTag(index);
  };

  const startEditing = () => {
    setEditing(true);
  };

  const stopEditing = () => {
    setEditing(false);
  };

  const tagFormated = tagText.substring(0, tagText.indexOf(':') + 1);
  const tag = tagText.split(':')[0];
  const obj = (selectTags && selectTags[tag]) || null;

  return (
    editing ? (
      <div className="SearchBar__Tag">
        <span>{tagFormated}</span>
        {!obj ? (
          <span
            onKeyDown={blockEnter}
            className="SearchBar__TagEditor"
            contentEditable={true}
            ref={inputRef}
            onBlur={stopEditing}
            dangerouslySetInnerHTML={{ __html: tagText.substring(tagText.indexOf(':') + 1) }}
          ></span>
        ) : (
          <Select
            showListOnMount
            validate={(value) => blockEnter({
              name: tag,
              value: value,
            })}
            edit={true}
            className={'SearchBar__TagSelect'}
            value={obj}
            onBlur={stopEditing}
          />
        )}
        <div className="SearchBar__CloseCointainer">
          <button className="SearchBar__CloseButton" onClick={handleDeleteTag}>
            {'\u2716'}
          </button>
        </div>
      </div>
    ) : (
      <div className="SearchBar__Tag">
        <button className="SearchBar__TagText" onClick={startEditing} tabIndex="0">
          <span>
            <b>{tagText.split(':')[0]}:</b>
            {setValideText(tagText.split(':')[1])}
          </span>
        </button>
        <button className="SearchBar__CloseButton" onClick={handleDeleteTag}>
          {'\u2716'}
        </button>
      </div>
    )
  );
}


export default forwardRef(SearchTag);
