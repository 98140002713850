import './style.css';

import React, { useContext } from 'react';

import { AbilityContext } from '../../../../services/casl/Can';
import { MODEL_NAMES, PATIENT_FIELDS, PERMISSIONS } from '../../../../services/casl/roles';
import { useWindowDimensions } from '../../../../utils/hooks';
import ToolTip from '../../../components/tooltip';

const ItemCardTemplate = ({
  status,
  statusColour,
  fullName,
  birthName,
  birthdate,
  security_number,
  status_date,
  onClick,
  index,
}) => {
  const ability = useContext(AbilityContext);
  const { width: windowsWidth } = useWindowDimensions();
  const maxLength = (windowsWidth) => {
    switch (true) {
      case windowsWidth > 1140:
        return 22;
      case windowsWidth <= 660:
      case windowsWidth <= 980:
        return 14;
      case windowsWidth <= 1140:
        return 19;
      default:
        return 22;
    }
  };
  return (
    <>
      <ToolTip />
      <div className={'card-patient'} onClick={onClick} role="button" tabIndex={index}>
        <div className={`patient-survey-status ${statusColour}`} />
        <div className={'patient-text-name-container'}>
          <div
            className="patient-text-fullname truncate"
            data-tip={fullName && fullName.length > maxLength(windowsWidth) ? fullName : null}
          >
            {fullName}
          </div>
          {birthName && <div className="patient-text-birthName truncate">{birthName}</div>}
        </div>
        <div className={'patient-text-infos-container'}>
          <div className={'patient-text-birthdate-container'}>{birthdate}</div>
          { ability.can(PERMISSIONS.READ, { __typename: MODEL_NAMES.PATIENT_FIELDS, value: PATIENT_FIELDS.SECURITY_NUMBER }) && (
            <div className={'patient-text-securitynumber-container'}>{security_number}</div>
          )}
        </div>
        <div className={'patient-separator-intervention-rest'} />
        <div className={'d-flex flex-column justify-content-center patient-intervention-date-container'}>

          <div className={'truncate'}>{status}</div>
          <div className={''}>{status_date}</div>
        </div>
      </div>
    </>
  );
};

export default ItemCardTemplate;
