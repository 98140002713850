import './style.css';

import React from 'react';
import { useTranslation } from 'react-i18next';

import InputCheckbox from '../../components/form/InputCheckbox';

const Notification = ({ data, onChange }) => {
  const { t } = useTranslation();
  const handleSmsChange = (e) => {
    if (onChange) onChange({
      name: 'sms',
      value: e.target.value,
    });
  };

  const handleMailChange = (e) => {
    if (onChange) onChange({
      name: 'mail',
      value: e.target.value,
    });
  };

  return (
    <div className={'idconnexion-main-container'}>
      <div className={'notification-main-container'}>
        <p className={'notification-grey-text'}>{t('idconnection_notification')}</p>
        <div className={'notification-checkbox-container'}>
          <div className={'notification-block-container'}>
            <p className={'notification-black-text'}>{t('notification_text_email')}</p>
            <InputCheckbox value={data.notif_mail} onChange={handleMailChange} />
          </div>
          <div className={'notification-block-container'}>
            <p className={'notification-black-text'}>{t('notification_text_sms')}</p>
            <InputCheckbox value={data.notif_sms} onChange={handleSmsChange} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notification;
