
import _ from 'lodash';
import { connect } from 'react-redux';

import { ClinicActions } from '../../../redux/clinic';
import { FormsActions } from '../../../redux/forms';
import { LanguageActions } from '../../../redux/languages';
import { SurveyActions } from '../../../redux/survey';
import { APP_MODES } from '../../../utils/enum';
import CreateSurvey from './CreateSurvey';

const mapStateToProps = ({ auth, forms, medic, patient }) => {

  return {
    isClinicalStudyUser: !!_.get(auth, 'user.users_roles', []).find(ur => ur.role.name === APP_MODES.CLINICAL_STUDY),
    medicalTeamSettings: _.get(medic, ['medicalTeamList', medic.selectedTeam, 'medicalTeamSettings'], {}),
    selectedTeam: medic.selectedTeam,
    forms: forms.data,
    selectedPatientIsAutonomous: patient.selectedPatientIsAutonomous,
  };
};

const mapDispatchToProps = (dispatch) => ({
  surveyTypesRequest: () => dispatch(SurveyActions.surveyTypesRequest()),
  clinicRequest: () => dispatch(ClinicActions.clinicRequest()),

  createTemporarySurvey: (survey, navigate) => dispatch(SurveyActions.temporarySurveyCreate(survey, navigate)),

  paperSurveyCreate: (survey) => dispatch(SurveyActions.createSurveyPaper(survey)),
  clearData: () => dispatch(FormsActions.clearData()),
  omitData: (key) => dispatch(FormsActions.omitData(key)),
  fetchAllLanguages: () => dispatch(LanguageActions.fetchAll()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateSurvey);
