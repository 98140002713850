import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import GlobalLoader from '../../ui/components/loaders/GlobalLoader/GlobalLoader';


function RedirectOnceLogin({ redirectOnLogin, loginInfoSuccess, setRedirectUrl, clearSplash, getLoginInfo }) {

  useEffect(() => {
    // once login, navigateTo
    if (loginInfoSuccess && redirectOnLogin) {
      setRedirectUrl(redirectOnLogin);
      clearSplash();
    }
    if (!loginInfoSuccess) {
      getLoginInfo();
    }
  }, [loginInfoSuccess, redirectOnLogin]);

  return (loginInfoSuccess && !redirectOnLogin) ? <Outlet/> : (
    <>
      <GlobalLoader />
      <div>loading</div>
    </>);
}


export default RedirectOnceLogin;

