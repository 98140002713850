import _ from 'lodash';
import { call, put, takeLatest } from 'redux-saga/effects';

import { ClinicService } from '../../services';
import { SnackActions } from '../snackBar';
import { default as ClinicActions, types } from './actions';

/**
 * Start Fetching all patient for the current medic
 * @returns {Generator<*, void, ?>}
 */
function* clinicRequest() {
  const [error, response] = yield call(ClinicService.clinics);
  const clinics = _.get(response, 'clinics');
  if (!error && clinics) {
    yield put(ClinicActions.clinicSuccess(clinics));
  } else {
    yield put(ClinicActions.clinincFailure());
    yield put(SnackActions.displayError('patient_list_error'));
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default [takeLatest(types.CLINIC_REQUEST, clinicRequest)];
