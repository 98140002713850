import { format } from 'date-fns';
import _ from 'lodash';

import { methods } from '../utils';
import { medicMutations, medicQueries } from './GraphQLRequest';
import { authenticatedMutation, authenticatedQuery, authenticatedService } from './middleware';

const { GET } = methods;

/**
 * REST ROUTES
 */
const PROXY_API_URL = process.env.REACT_APP_PROXY_API || process.env.REACT_APP_BASE_API_URL || '/api';
const routes = {
  currentMedic: () => `${PROXY_API_URL}/V2/medics/current`,
  medicalTeamsRoute: `${PROXY_API_URL}/V2/medicalTeams`,
  medicalTeamsPatients: (teamId) => `${PROXY_API_URL}/V2/medicalTeams/${teamId}/patients`,
};

/*
 * SERVICES
 */
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  // currentMedic: (email) => authenticatedQuery(medicQueries.currentMedic(), {}, { email }),
  currentMedic: () => authenticatedService(GET, routes.currentMedic(), {}, {}),

  updateMedic: (medic) => authenticatedMutation(medicMutations.update(), {}, { _id: medic.id, _medic: _.omit(medic, ['id', 'user']), _uid: medic.user.uid, _user: { language_id: medic.user.language_id } }),
  updateNotif: (medic) => authenticatedMutation(medicMutations.updateNotif(), {}, { id: medic.id, medic: _.omit(medic, ['id']) }),
  medicTeam: (medicId) => authenticatedQuery(medicQueries.medicTeam(), {}, { medicId }),
  medicsTeamsMetrics: (teamId) => authenticatedQuery(medicQueries.medicsTeamsMetrics(), {}, { teamId, _gte: format(new Date(), 'yyyy-MM-dd') }),
  medicsTeamsMetricsDetails: (teamId) => authenticatedQuery(medicQueries.medicsTeamsMetricsDetails(), {}, { teamId, _gte: format(new Date(), 'yyyy-MM-dd') }),
  medicsMetrics: (medicId) => authenticatedQuery(medicQueries.medicsMetrics(), {}, { medicId, _gte: format(new Date(), 'yyyy-MM-dd') }),
  updateCGU: (id) => authenticatedMutation(medicMutations.updateCGU(), {}, { id }),
  updateRGPD: (id) => authenticatedMutation(medicMutations.updateRGPD(), {}, { id }),
  updateEditInfo: (id) => authenticatedMutation(medicMutations.updateEditInfo(), {}, { id }),
  medicTeamOwner: (id) => authenticatedQuery(medicQueries.medicTeamOwner(), {}, { id }),
  fetchMedicTeamData: () => authenticatedService(GET, routes.medicalTeamsRoute),
  fetchMedicTeamPatients: (teamId) => authenticatedService(GET, routes.medicalTeamsPatients(teamId)),
};
