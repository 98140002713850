import { connect } from 'react-redux';

import { MedicActions } from '../../redux/medic';
import { MEDIC_ROLES } from '../../utils';
import Router from './Router';


const mapStateToProps = ({ auth, medic, display }) => {
  return {
    isPro: auth.user?.role && MEDIC_ROLES.includes(auth.user.role),
    appMode: display.appMode,
    selectedTeam: medic.selectedTeam,
    redirectUrl: auth.redirectUrl,
    medicalTeamList: medic.medicalTeamList,
    role: auth.user?.role,
  };
};

const mapDispatchToProps = (dispatch) => ({ onTeamChange: (teamId) => dispatch(MedicActions.changeSelectedMedicTeamRequest(teamId)) });


export default connect(mapStateToProps, mapDispatchToProps)(Router);
