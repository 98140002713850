import './style.css';

import React from 'react';

import { PrimaryButton } from '../buttons';

const DropdownItem = ({ icon, text, onClick, buttonStyle, textStyle }) => {

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    onClick(event);
  };

  return (
    <li>
      <PrimaryButton
        active={false}
        image={icon}
        text={text}
        buttonStyle={buttonStyle}
        textStyle={textStyle}
        onClick={handleClick}
      />
    </li>
  );
};

export default DropdownItem;
