import { intlFormat } from 'date-fns';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { getUserProperty } from '../../../utils';
import Card from '../../components/card';
import SkeletonItem from '../../components/skeletonItem/SkeletonItem';

const Tutoredinfo = ({
  isLoading,
  currentPatientList,
  indexCurrentPatient,
  incrementIndexCurrentPatient,
  decrementIndexCurrentPatient,
}) => {
  const { t } = useTranslation();
  const patient = _.get(currentPatientList, [indexCurrentPatient, 'tutors', 0, 'patientByPatientId']) || _.get(currentPatientList, [indexCurrentPatient]);
  const isTutor = _.get(currentPatientList, [indexCurrentPatient, 'tutors', 0, 'patientByPatientId']) ? true : false;
  const birthdate = _.get(patient, 'birthdate');
  const postcode = _.get(patient, 'postcode');
  const city = _.get(patient, 'city');
  const address = `${postcode ? `${postcode} ` : ''}${city || ''}`;

  return (
    <Card >
      <div className={`d-flex justify-content-between ${indexCurrentPatient > 0 ? 'infopatient-tutor-background' : ''}`} style={{ height: '100%' }}>
        { Array.isArray(currentPatientList) && currentPatientList.length > 1 && (
          <button type="button" className="btn btn-light" onClick={decrementIndexCurrentPatient}>
            <i className="bi bi-skip-start" />
          </button>
        )}
        <div className="w-100" >
          { isTutor
            ? (
              <>
                <p className={'patient-text'}>{t('patient_tutored')}</p>
                <div className={'infopatient-row-container'}>
                  <p className={'infopatient-text-min'}>{t('tutors_text_name')}</p>
                  <div className={'infopatient-text-max'}>
                    { isLoading
                      ? <SkeletonItem animation />
                      : getUserProperty(patient, 'fullDisplayName')
                    }
                  </div>
                </div>
                <div className={'infopatient-row-container'}>
                  <p className={'infopatient-text-min'}>{t('patient_card_birthdate')}</p>
                  <div className={'infopatient-text-max'}>
                    { isLoading
                      ? <SkeletonItem animation />
                      : birthdate
                        ? intlFormat(birthdate, {
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                        })
                        : ''
                    }
                  </div>
                </div>
              </>
            )
            : (
              <>
                <p className={'patient-text'}>{t('patient_contact')}</p>
                <div className={'infopatient-row-container mr-4'}>
                  <p className={'infopatient-text-min'}>{t('patient_text_email')}</p>
                  <div className={'infopatient-text-max'}>
                    { isLoading
                      ? <SkeletonItem animation />
                      : (_.get(patient, 'email')) || ''
                    }
                  </div>
                </div>
                <div className={'infopatient-row-container mr-4'}>
                  <div className={'infopatient-text-min'}>{t('patient_text_address')}</div>
                  <div className={'infopatient-text-max'}>
                    { isLoading
                      ? <SkeletonItem width={160} animation />
                      : address
                    }
                  </div>
                </div>
              </>
            )}
        </div>
        { Array.isArray(currentPatientList) && currentPatientList.length > 1 && (
          <button type="button" className="btn btn-light" onClick={incrementIndexCurrentPatient}>
            <i className="bi bi-skip-end" />
          </button>
        ) }
      </div>
    </Card>
  );
};

export default Tutoredinfo;
