import {
  ADDING_A_PATIENT_REPRESENTATIVE_INDEX,
  DELETING_PATIENT_REPRESENTATIVE_INDEX,
  STEP_PATIENT_REPRESENTATIVE_INDEX,
  STEPS,
} from './definition';

/**
 * @description: This class is used to manage the steps of the survey form
 * @class StepsContainer
 * @example:
 * const stepsContainer = new StepsContainer(paperMode, selectedPatientIsAutonomous);
 * const steps = stepsContainer.getSteps();
 */
export class StepsContainer {
  /**
   * Steps
   * @type {string[]}
   */
  #steps = [];

  /**
   * Constructor
   * @param {boolean} paperMode
   * @param {boolean} selectedPatientIsAutonomous
   */
  constructor(paperMode, selectedPatientIsAutonomous) {
    const steps = Object.values(STEPS);
    if (paperMode) {
      this.#steps = steps.filter(step => step !== STEPS.TEMPLATE && step !== STEPS.SUP_INFO && step !== STEPS.PATIENT_REPRESENTATIVE);
    } else {
      const indexOfPatientRepresentative = steps.indexOf(STEPS.PATIENT_REPRESENTATIVE);
      if (indexOfPatientRepresentative !== STEP_PATIENT_REPRESENTATIVE_INDEX) {
        const deletedStepIndex = indexOfPatientRepresentative > STEP_PATIENT_REPRESENTATIVE_INDEX ? indexOfPatientRepresentative + DELETING_PATIENT_REPRESENTATIVE_INDEX : indexOfPatientRepresentative ;
        const addedStepIndex = indexOfPatientRepresentative > STEP_PATIENT_REPRESENTATIVE_INDEX ? STEP_PATIENT_REPRESENTATIVE_INDEX : ADDING_A_PATIENT_REPRESENTATIVE_INDEX;
        steps.splice(addedStepIndex, 0, STEPS.PATIENT_REPRESENTATIVE);
        steps.splice(deletedStepIndex, 1);
      }

      this.#steps = steps.filter(step => {
        if (selectedPatientIsAutonomous) {
          return step !== STEPS.PATIENT_REPRESENTATIVE && step !== STEPS.UPLOAD;
        }
        return step !== STEPS.UPLOAD;
      });
    }
  }

  /**
   * Get the steps
   * @return {string[]}
   */
  getSteps() {
    return this.#steps;
  }
}