import React from 'react';

import SkeletonItem from '../../../components/skeletonItem/SkeletonItem';
import ItemCardTemplate from './ItemTemplate';

const ItemSkeleton = () => {
  return (
    <ItemCardTemplate
      fullName={<SkeletonItem width={'100%'} height={'2em'} animation />}
      birthdate={<SkeletonItem animation />}
      security_number={<SkeletonItem animation />}
      status_date={<SkeletonItem animation />}
      intervention_number={<SkeletonItem animation />}
    />
  );
};

export default ItemSkeleton;
