import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';


function LoginFromToken({ loginFromToken, splash, isLogoutSuccess }) {

  useEffect(() => {
    if (isLogoutSuccess && !!splash?.token) {
      loginFromToken(splash.token, splash?.refresh_token);
    }
  }, [isLogoutSuccess, splash?.token]);

  return (
    <Outlet/>
  );
}

export default LoginFromToken;

