import './style.css';

import { format } from 'date-fns';
import _ from 'lodash';
import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconContext } from 'react-icons';
import { AiOutlineCheck } from 'react-icons/ai';

import { IconDelete, IconReadOnly } from '../../../../assets/img/icons';
import { AbilityContext } from '../../../../services/casl/Can';
import { PERMISSIONS } from '../../../../services/casl/roles';
import Card from '../../../components/card';
import ConfirmBtn from '../../../components/ConfirmBtn';
import ToolTip from '../../../components/tooltip';

const Item = ({ form, onClick, isTemplateStore, tabIndex, uploadedTemplateIds, types }) => {
  const { t } = useTranslation();
  const ability = useContext(AbilityContext);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const type = useMemo(() => (types || []).find(ty => ty.id === form.type_id), [types, form]);
  const { id, created_at, medic } = form;

  const handleOnClick = (e, mode) => {
    e.stopPropagation();
    switch (mode) {
      case 'preview':
        if (isTemplateStore || confirmDeleteModal) {
          return;
        }
        break;
      case 'delete':
        if (confirmDeleteModal) {
          setConfirmDeleteModal(!confirmDeleteModal);
        }
        break;
      default:
    }
    if (onClick) {
      onClick(id, mode);
    }
  };

  const handleKeyPress = (event, mode) => {
    if (event.key === 'Enter') handleOnClick(event, mode);
  };

  return (
    <Card>
      <ToolTip />
      <div
        className="medic-form-item-container"
        role="menuitem"
        onClick={(e) => handleOnClick(e, 'preview')}
        onKeyPress={(e) => handleKeyPress(e, 'preview')}
        tabIndex={tabIndex}
      >
        <div className={'medic-form-multiline-2-container'}>
          <p className={'medic-form-list-item-title medic-form-list-item-title-black'}>{form?.displayTranslation?.title}</p>
          { isTemplateStore
            ? null
            : ability.can(PERMISSIONS.DELETE, { ...form, medic_id: `${form.medic_id}` })
              ? (
                <ConfirmBtn
                  title={t('medic_templatesList.item.popup.delete.title')}
                  onConfirm={handleOnClick}
                  action="delete"
                >
                  <img
                    className="medic-form-delete-icon"
                    src={IconDelete}
                    alt='delete'
                    aria-hidden="true"
                    data-tip={t('medic_templatesList.item.popup.delete.title')}
                  />
                </ConfirmBtn>
              ) : (
                <img
                  className={`medic-form-delete-icon ${isTemplateStore ? 'invisible' : 'visible'}`}
                  src={IconReadOnly}
                  alt='read-only'
                  aria-hidden="true"
                  data-tip={ medic
                    ? t('medic_templatesList.item.popup.delete.iconTooltipWithName', { lastname: _.get(medic, 'lastname') })
                    : t('medic_templatesList.item.popup.delete.iconTooltip')
                  }
                />
              )}
          { isTemplateStore && uploadedTemplateIds.includes(id) && (
            <IconContext.Provider value={{
              color: '#00AECB',
              size: '1.5em',
            }}>
              <AiOutlineCheck />
            </IconContext.Provider>
          )}
        </div>
        <div className={'medic-form-list-item-divider'} />
        <div className={'medic-form-list-item-after-divider-container'}>
          <p className={'medic-form-list-item-data-text medic-form-list-item-data-type'}>
            {`# ${type?.translation?.label || ''}`}
          </p>
          { form.__typename === 'medic_templates'
            ? <p className={'medic-form-list-item-data-text medic-form-list-item-data-date'}>
              {t('medic_templatesList.card.date.prefix', { date : created_at ? format(new Date(created_at), 'dd/MM/yyyy') : '' })}
            </p>
            : <p className="medic-form-list-item-add-buton" onClick={(e) => handleOnClick(e, 'add')}>
              {t('medic_templatesList.card.actions.add_fom.title')}
            </p>
          }
        </div>
      </div>
    </Card>
  );
};

export default Item;
