import styled from 'styled-components';

import QAssist from './queryAssist';
//props => <QAssist {...props} />)`
export const QueryAssist = styled(QAssist)` 
  top: -20px;
`;

export const Footer = styled.div`
  padding: 15px;
  text-align: center;
`;

export const inputProps = {
  borderRadius: '4px',
  color: '#9FA2B2',
  placeholderColor: '#b4b4b4',
  tokenColor: '#00aecb',
  fontSize: '16px',
  fontWeight: 300,
  fontFamily: 'NunitoSans Regular',
  lineHeight: '20px',
};

export const dropdownProps = {
  bg: '#79828B',
  color: '#FFFFFF',
  borderRadius: '2px',
  fontSize: '14px',
  fontWeight: 400,
  fontFamily: 'NunitoSans Regular',
};

export const selectorProps = {
  bg: '#00aecb',
  border: '1px solid #00aecb',
  color: '#FFFFFF',
};
