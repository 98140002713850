import { intlFormat, isAfter, startOfToday } from 'date-fns';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { formatBirthdate, parseDate } from '../../../../services/datefns';
import { prepareUserForDisplay, secuNumbFormater } from '../../../../utils';
import { SURVEY_STATUS, SURVEY_STATUS_COLOUR } from '../../../../utils/enum';
import { isNullOrWhitespace } from '../../../../utils/utils';
import ItemCardTemplate from './ItemTemplate';

const getStatusClinicalMode = (surveys, medicId) => {
  let status;
  // if status is accepted = signed by patient
  // and medic needs to counter sign
  if (surveys.some(s => s.status === SURVEY_STATUS.WITHDRAWAL)) {
    status = SURVEY_STATUS.WITHDRAWAL;
  } else if (surveys.find(s =>
    s.status === SURVEY_STATUS.ACCEPTED
        && s.is_signed === true
        && _.get(s, 'survey_type.should_countersign', false) === true
        && `${s.referent_medic_id}` === `${medicId}`,
  )) {
    status = SURVEY_STATUS.ACCEPTED;
  } else if ( surveys.some(s => s.status === SURVEY_STATUS.REFUSED)){
    status = SURVEY_STATUS.REFUSED;
  } else if ( surveys.some(s => s.status === SURVEY_STATUS.FURTHER_INFO)){
    status = SURVEY_STATUS.FURTHER_INFO;
  } else if (
    surveys.length === surveys
      .filter(s => [SURVEY_STATUS.COUNTERSIGNED, SURVEY_STATUS.DRAFT]
        .includes(s.status),
      ).length
  ) {
    status = SURVEY_STATUS.COUNTERSIGNED;
  } else {
    status = SURVEY_STATUS.SENT;
  }
  return status;
};

const getStatusHospitMode = (surveys) => {
  let status;
  if (surveys.some(s => s.status === SURVEY_STATUS.WITHDRAWAL)) {
    status = SURVEY_STATUS.WITHDRAWAL;
  } else if ( surveys.some(s => s.status === SURVEY_STATUS.REFUSED)){
    status = SURVEY_STATUS.REFUSED;
  } else if ( surveys.some(s => s.status === SURVEY_STATUS.FURTHER_INFO)){
    status = SURVEY_STATUS.FURTHER_INFO;
  } else if (
    surveys.length === surveys
      .filter(s => [SURVEY_STATUS.ACCEPTED, SURVEY_STATUS.COUNTERSIGNED, SURVEY_STATUS.DRAFT]
        .includes(s.status),
      ).length
  ) {
    status = SURVEY_STATUS.ACCEPTED;
  } else {
    status = SURVEY_STATUS.SENT;
  }
  return status;
};

const getStatus = (surveys, medicId, isClinicalMode) => {
  if (!surveys || !surveys.length) {
    // if no intervention => empty status
    return undefined;
  }
  // filter survey with intervention from begining of day (in future)
  const filteredSurveys = surveys.filter(s => s?.intervention?.date && isAfter(new Date(s.intervention.date), startOfToday()));
  if (!filteredSurveys || !filteredSurveys.length) {
    // if no future intervention => empty status
    return undefined;
  }

  return (isClinicalMode)
    ? getStatusClinicalMode(surveys, medicId)
    : getStatusHospitMode(filteredSurveys);
};

const Item = ({ patient, selectedTeam, onClick, isClinicalMode, medicId, index }) => {
  const { t } = useTranslation();
  const [intervention, setIntervention] = useState({});
  const [referent, setReferent] = useState({});
  const status = useMemo(() => {
    if (patient && patient.surveys && medicId) {
      return getStatus(patient.surveys, medicId, isClinicalMode);
    }
  }, [patient && JSON.stringify(patient.surveys), medicId]);

  const { id, security_number, birthdate } = patient;
  const { birthNameLabel, fullContactName } = prepareUserForDisplay( patient, ['birthNameLabel', 'fullContactName'] );

  useEffect(() => {
    if (patient) {
      setIntervention(_.get(patient, ['surveys', '0', 'intervention'], {}));
      setReferent(_.get(patient, ['surveys', '0', 'referent'], {}));
    }
  }, [patient, selectedTeam]);

  const handleOnClick = () => {
    if (onClick) onClick(id);
  };

  return (
    <ItemCardTemplate
      statusColour={SURVEY_STATUS_COLOUR(status, isClinicalMode)}
      status={status && t(`medicPatient.history.list.status.pro.${isClinicalMode ? 'clinicalStudy' : 'core'}.${status}`)}
      fullName={fullContactName}
      birthName={birthNameLabel}
      birthdate={`${t('patient_card_birthdate')} ${formatBirthdate(birthdate, isClinicalMode)}`}
      security_number={!isNullOrWhitespace(security_number) ? `N° ${secuNumbFormater(security_number)}` : ''}
      status_date={
        intervention && intervention.date ? intlFormat(parseDate(intervention.date), {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        }) : ''
      }
      onClick={handleOnClick}
      index={index}
      referent={referent}
    />
  );
};

export default Item;
