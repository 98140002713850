// eslint-disable-next-line import/no-anonymous-default-export
export default {
  clinicalStudies: {
    medic: {
      sidemenu: {
        create_patient_btn: 'Créer un patient',
        new_form_btn: 'Attribuer un formulaire',
        store_btn: 'Modèles',
      },
      list: { headers: { surgeryDate: "Date limite pour l'inclusion du patient" } },
    },
    topMenu: { team: { label: 'Centre investigateur:' } },
    medicPatient: { history: { list: { headers: { date: 'Date limite pour signature' } } } },
    surveyPatientForm: {
      clinic_id: {
        label: 'Nom du centre',
        rules: { required: 'Merci de selectionner un nom de centre investigateur' },
      },
      medical_teams_id: {
        label: 'N° du centre ',
        rules: { required: 'Merci de selectionner un N° de investigateur' },
      },
      medic_surveys: {
        label: 'Composition du centre investigateur',
        rules: { required: 'Centre investigateur requis' },
      },
      referent_medic_id: {
        label: 'Investigateur',
        rules: { required: 'Un Investigateur est requis' },
      },
      intervention: {
        date_consult: { label: 'Date de consultation' },
        date: { label: "Date limite pour la fin d'inclusion du patient" },
      },
    },
    formsScreen: {
      filters: {
        speciality: { title: 'Filtrer Par Nom Etude' },
        type: { title: 'Filtrer Par Types' },
      },
    },
    consentFormStep: {
      action: {
        'further information': { confirmation_message: 'En cas de confirmation, votre consentement sera interrompu, vous devrez impérativement contacter votre investigateur afin de répondre à vos questions.' },
        Refuse: { confirmation_message: 'En cas de confirmation, votre participation à la recherche sera annulée.' },
        RefuseWithPurpose: { confirmation_message: 'En cas de confirmation, votre participation à la recherche sera annulée.' },
      },
    },
    ConsentRender: {
      rejection_message: {
        intro: 'Vous avez déclaré vouloir annuler votre participation à la recherche',
        refused: 'Vous avez déclaré vouloir annuler votre participation à la recherche',
      },
    },
  },
  common: {
    tutorForms: {
      warning: {
        add: {
          obligatory: 'Vous ne pouvez pas ajouter plus de {{limit}} représentants obligatoires',
          facultative: 'Vous ne pouvez pas ajouter plus de 1 représentant facultatif',
        },
      },
    },
    ShowActionCounterSign: { toBeCountersignBy : 'A contresigner par {{medic}}' },
    InfoPatient: { List : { Status : { signatureDate : 'Signé le {{date}}' } } },
    noMatch: { disclamer: 'No match for {{pathname}}.' },
    form: {
      fields: {
        checkbox: { rules: { required: 'Merci de valider ce champs' } },
        security_number: {
          rules: {
            required: 'Le numéro de sécurité sociale doit être renseigné.',
            size: 'Le numéro de sécurité social doit être composé de 15 chiffres.',
            format: 'Le numéro de sécurité social ne doit contenir que des chiffres.',
            not_valid: "Le numéro de sécurité social renseigné n'est pas valide.",
            key_not_valid: "La clé du numéro de sécurité social renseigné n'est pas valide.",
            sex: "Le premier chiffre n'est pas valide",
          },
        },
      },
      button: {
        cancel: { label: 'Annuler' },
        send: { label: 'Valider' },
        next: { label: 'Suivant' },
        previous: { label: 'Précédent' },
      },
      listSelectField: { more_info: "plus d'infos" },
    },
    DropdownList: { placeholder: 'Choisissez une réponse' },
    DocumentChoice: {
      title: {
        default: 'Visualiser les documents',
        build_pdf: 'Consentement PDF',
        build_draw: 'Pièce jointe',
        build_info: 'Visualiser les documents',
        build_attachment: 'Pièce jointe',
        question_pdf: 'PDF',
        edit_date: 'Modifier la date',
        qr_code: 'Consentement sur place',
      },
      sliderMessage: { intervention_date_have_been_updated: "La date d'intervention a été mise à jour." },
    },
    SummaryModal: {
      disclamer_header: 'Veuillez prendre le temps de bien lire les réponses, puis cliquez sur le bouton Continuer en fin de page. Pour rappel, vous devez impérativement avoir près de vous le téléphone mobile correspondant au numéro {{ phoneNumber }} sur lequel un SMS avec le code vous sera envoyé.',
      you_said: "L'analyse de vos réponses montre que votre consentement libre et éclairé peut être recueilli. En effet, lors de votre consentement vous avez déclaré :",
      answerList: {
        asked_more_info: 'Aprés une reformulation, vous avez répondu',
        start: 'Vous avez répondu',
        pdf: 'Vous avez lu le document',
        at_the_question: 'à la question: "{{ question }}".',
      },
    },
    summaryModal: {
      read_the_document: 'lu le document',
      at_the_question: 'à la question',
      summary_end: "Le questionnaire que vous venez de remplir n'est bien évidement pas exhaustif. Une complication particulièrement exceptionnelle peut toujours survenir, liée à un état de santé singulier et inconnu, à l'état local ou à une variabilité technique. Toutes les complications ne peuvent être précisées, ce que vous comprenez et acceptez.",
    },
    ConsentRender: {
      answer: { required: 'Réponse requise' },
      dropdown_list: { required: 'Réponse requise' },
      multiple_choice: { required: 'Réponse requise' },
      text_answer: { required: 'Réponse requise' },
      checkbox_full: { required: 'Pour pouvoir signer votre consentement, toutes les cases doivent être cochées.' },
      radio_multi_questions_to_table: { required: 'Réponse requise.' },
      pdf: { isRead: 'Le PDF doit être lu entièrement.' },
      validation: { required: 'Vous devez valider la question afin de poursuivre' },
      rejection_message: {
        intro: 'Vous avez déclarer ne pas vouloir poursuivre votre consentement',
        question: "En effet, malgré les explications complémentaires, vous n'avez pas compris la question {{ question }}",
        refused: 'Vous avez déclaré ne pas vouloir poursuivre votre consentement',
        'further information': "Vous avez déclaré avoir besoin d'informations supplémentaires pour signer votre consentement",
      },
    },
    medicCounterSignModal: { thanks_message: "Merci d'avoir utilisé Easy-Consent." },
    medicPatient: {
      history: {
        title: 'Historique des formulaires',
        list: {
          headers: {
            name: 'Nom du formulaire',
            refNumber: 'Numéro',
            date: 'Date limite de signature',
            status: 'Statut',
            documents: 'Documents',
            action: 'Action',
            tutorName: 'Représentant',
          },
          status: {
            patient: {
              core: {
                countersigned: 'contresigné',
                accepted: 'signé',
                sent: 'en cours',
                'further information': 'info supp',
                refused: 'refusé',
                draft: 'en cours',
                not_signed: 'Non signé',
                withdrawal: 'Retiré',
                pending: 'en attente',
              },
              clinicalStudy: {
                countersigned: 'contresigné',
                accepted: 'signé',
                sent: 'en cours',
                'further information': 'info supp',
                refused: 'refusé',
                draft: 'en cours',
                not_signed: 'Non signé',
                withdrawal: 'Retiré',
              },
            },
            pro: {
              core: {
                countersigned: 'signé',
                accepted: 'signé',
                sent: 'en cours',
                'further information': 'info supp',
                refused: 'refusé',
                draft: 'en cours',
                not_signed: 'Non signé',
                withdrawal: 'Retiré',

              },
              clinicalStudy: {
                countersigned: 'signé',
                accepted: 'à contresigner',
                sent: 'en cours',
                'further information': 'info supp',
                refused: 'refusé',
                draft: 'en cours',
                not_signed: 'Non signé',
                withdrawal: 'Retiré',
              },
            },
          },
          actionBtn: {
            attachments: 'Voir',
            parameters: 'Paramètres',
            signed: 'signé',
            countersigned: 'contresigné',
            not_signed: 'Non signé',
            sent: 'en cours',
            toSign: 'A signer',
            refused: 'refusé',
            countersign: 'Contre signer',
            resume: 'Relancer',
            delete: 'Supprimer',
            archive: 'Archiver le patient',
            'further information': 'Infos',
            withdrawal: 'Retirer le consentement',
          },
        },
      },
    },
    medic: {
      sidemenu: {
        create_patient_btn: 'Créer un patient',
        new_form_btn: 'Attribuer un formulaire',
        store_btn: 'Modèles',
        filters: {
          local_phone_number: { title: 'Numéro de téléphone' },
          phone_number: { title: 'Numéro de téléphone' },
          security_number: { title: 'Numéro de sécurité sociale' },
          lastname: { title: 'Non de famille' },
          firstname: { title: 'Prénom' },
          birthdate: { title: 'Date de naissance' },
          surveys: { title: "Date d'intervention" },
          fullName: { title: 'FullName' },
          fullNameReversed: { title: 'FullNameReversed' },
        },
      },
      filters: {
        title: 'Afficher',
        consent: 'consentements',
        patient: 'patient.es',
        surgery: 'interventions',
      },
      stats: {
        clinicalStudy: {
          countersigned: 'finalisé',
          accepted: 'à contresigner',
          in_progress: 'en cours',
          'further information': 'Informations complémentaires',
          refused: 'refus',
          not_signed: 'Non signé',
          withdrawal: 'Retiré',

        },
        hospitalisation: {
          accepted: 'signé',
          in_progress: 'en cours',
          refused: 'informations complémentaire',
          not_signed: 'Non signé',
          withdrawal: 'Retiré',
        },
      },
      list: {
        'Loading...': 'Chargement ...',
        headers: {
          patient: 'Patient',
          surgeryDate: 'Intervention',
        },
      },
    },
    surveylist: {
      filters: {
        date: { title: 'Date' },
        type: { title: 'Type' },
        number: { title: 'Numéro' },
        status: { title: 'Statut' },
        title: { title: 'Titre' },
      },
      configStatus: {
        countersigned: 'contresigné',
        accepted: 'signé',
        sent: 'en cours',
        'further information': 'info supp',
        refused: 'refusé',
        draft: 'en cours',
        not_signed: 'Non signé',
        withdrawal: 'Retiré',
      },
    },
    topMenu: {
      dropdown: {
        profil: 'Mon profil',
        logout: 'Déconnexion',
      },
      team: { label: 'Équipe:' },
    },
    auth: {
      forgotten_pswd: {
        label: 'Mot de passe oublié ou non reçu',
        Modal: {
          titleStep_0: 'Mot de passe oublié ou non reçu ?',
          titleStep_1: 'Mot de passe oublié ou non reçu ?',
          titleStep_2: 'Confirmez votre identité',
          titleStep_3: 'Redéfinition de votre mot de passe',
        },
      },
      title_pro: 'Espace professionnel',
      title_patient: 'Je suis un patient',
      form: {
        username: {
          label_pro: 'Identifiant',
          label_patient: 'Identifiant',
        },
        password: { label: 'Mot de passe' },
        submit: { label: 'Se Connecter' },
      },
    },
    formsScreen: {
      list: { 'Loading...': 'Chargement ...' },
      filters: {
        speciality: { title: 'Filtrer Par Spécialité' },
        type: { title: 'Filtrer Par Types' },
      },
    },
    richtext: {
      preview: 'Aperçu',
      click_here: 'Cliquer ici pour editer le texte',
    },
    all: 'Tous',
    blockedModal: { disclamer_portrait_orientation: 'Attention, interface logicielle optimisée en orientation paysage. Merci de tourner votre tablette à l\'horizontale.' },
    and: 'et',
    no_information: 'Non Renseignée',
    unauthorized: "Vous n'êtes pas autorisé à afficher cette page, veuillez vous connecter.",
    authentication_failed: 'Mot de passe et/ou identifiant incorrect.',
    authentication_wrong_code: 'Code incorrect.',
    authentication_pwd_changed: 'Mot de passe modifié avec succès.',
    reset_pwd_success: 'Mot de passe modifié avec succès. Merci de vous reconnecter',
    logout_error: 'Vous avez été automatiquement déconnecté, merci de vous reconnecter',
    logout_success: 'Déconnexion effectuée',
    generic_error: "Une erreur inconnue s'est produite lors du chargement des données.",
    fields_missing: 'Merci de renseigner tous les champs du formulaire.',
    survey_create_success: 'Formulaire créé avec succès',
    surveys_list_error: 'Echec de la récupération des formulaires',
    surveys_types_error: 'Echec de la récupération des types',
    surveys_specialty_error: 'Echec de la récupération des spécialité',
    surveys_question_type_error: 'Echec de la récupération des types de question',
    surveys_create_error: 'Echec de la création du formulaire',
    surveys_create_error_large_file: 'Echec de la création du formulaire due à une image trop volumineuse',
    survey_current_error: 'Erreur lors de la récupération du formulaire',
    survey_current_success: 'Récupération de votre formulaire effectuée',
    survey_current_update_error: "Erreur lors de l'enregistrement de vos réponses",
    survey_update_success: 'Formulaire mis à jour avec succès',
    survey_update_error: 'La mise à jour du formulaire a rencontré une erreur',
    survey_sign_success: 'Votre consentement est signé',
    survey_sign_error_0: "Votre consentement n'a pas pu être signé. Merci de patienter et de réessayer ultérieurement.",
    survey_sign_error: "Votre consentement n'a pas pu être signé, merci de contacter le secrétariat",
    surveys_delete_error: "L'archivage du formulaire a rencontré une erreur",
    surveys_delete_success: 'Archivage du formulaire effectué',
    survey_withdrawal_error: 'Le retrait du consentement a rencontré une erreur',
    survey_withdrawal_success: 'Retrait du consentement effectué',
    upload_sign_success: 'Récupération du PDF effectuée',
    upload_sign_error: 'La récupération du PDF a échoué',
    patient_list_error: 'Une erreur est survenue pendant la récupération de vos patients',
    patient_current_error: 'Une erreur est survenue pendant la récupération de vos données',
    create_patients_error: 'La création du patient a echoué',
    patients_detail_error: 'La récupération des données du patient a échoué',
    your_commentary: 'Votre commentaire',
    a_step_translation: 'Une étape',
    reorder_translation: 'Réorganiser',
    more_options: "plus d'options",
    add_a_pdf_translation: 'Un PDF',
    previous: 'Précédent',
    reorder_your_form: 'Réorganiser votre formulaire',
    current_medic_error: 'Erreur lors de la récupération de vos informations',
    update_medic_error: "Erreur lors de l'édition de vos informations",
    update_medic_success: 'Vos informations ont été éditées',
    template_update_error: "L'édition du formulaire courant a échoué",
    template_update_error_large_file: "L'édition du formulaire courant a échoué due à une image trop volumineuse",
    template_update_success: "L'édition du formulaire courant est effectuée",
    forgotten_password_too_many_tries: 'Vous avez essayé trop de fois. Veuillez réessayer plus tard ou contacter le support.',
    unkown_abbreviation: '',
    mister: 'Monsieur',
    miss: 'Madame',
    unkown: '',
    born_he: 'né',
    born_she: 'née',
    change_pwd: 'Création de votre mot de passe',
    verif_first_connexion: 'Vérification de vos informations',
    hello: 'Bonjour',
    accept_cgu: 'CGU',
    accept_rgpd: 'Politique de confidentialité',
    accepted: 'Accepter',
    refused: 'Refuser',
    cgu_deny: 'Êtes-vous sûr ? Vous allez etre redirigé vers page de connexion',
    cgu_checkbox_label: "J'ai lu et j'accepte les conditions générales d'utilisation",
    cgu_checkbox_label_withlink: "J'ai lu et j'accepte <a href='{{ href }}' target='_blank' rel='noopener noreferrer'>les conditions générales d'utilisation</a> (CGU)",
    rgpd_checkbox_label: "J'ai lu et j'accepte la politique de confidentialité",
    rgpd_checkbox_label_withlink: "J'ai lu et j'accepte la <a href='{{ href }}' target='_blank' rel='noopener noreferrer'>la politique de confidentialité</a>",
    cgu_checkbox_error: 'Merci de cocher la case',
    rgpd_checkbox_error: 'Merci de cocher la case',
    medic_template_delete_success: 'Suppression de votre formulaire effectuée',
    medic_template_delete_error: 'Erreur lors de la suppresion de votre formulaire',
    attachment: 'Pièce jointe',
    draw: 'Dessin',
    consent: 'Consentement signé',
    edit_date: 'Modifier la date',
    review_consent: 'Relecture du consentement',
    generate_qr_code: 'Consentement sur place',
    sign_in: "JE M'IDENTIFIE",
    connect: 'CONNEXION',
    dashboard: 'Tableau de bord',
    skip_form: 'Annuler la création',
    validate: 'Valider',
    Continue: 'Continuer',
    Refuse: 'Refuser',
    'further information': 'Souhaite des informations complémentaires',
    continue: 'continuer',
    return: 'Retour',
    undo: 'Annuler',
    download: 'Télécharger',
    download_the_attachment: 'Télécharger la pièce jointe',
    confirm: 'Confirmer',
    change_form: 'Changer de formulaire',
    add: 'Ajouter',
    add_attachment: 'Ajouter une pièce jointe',
    delete_attachment: 'Effacer le fichier',
    see_attachment: 'Visualiser votre pièce jointe',
    choose_file: 'Choisir un fichier',
    add_an_image: 'Ajouter une image',
    no_background: "Pas d'image de fond",
    return_to_image: "Retour à l'image",
    restart: 'Recommencer',
    make_a_drawing: 'Faire votre dessin',
    see_a_drawing: 'Visualiser votre dessin',
    search: 'Rechercher',
    cancel: 'Annuler',
    reset: 'Reset',
    next: 'Suivant',
    finish: 'Terminer',
    add_a_tutor: 'Ajouter un représentant légal',
    edit: 'Editer',
    yes: 'oui',
    no: 'non',
    Yes: 'Oui',
    No: 'Non',
    the: ' le ',
    at: ' à ',
    from: ' de ',
    one_male: 'Un',
    Gauche: 'Gauche',
    Droit: 'Droit',
    phone_text_message: 'SMS',
    delete_draw: 'Supprimer le dessin',
    consent_refused: 'Formulaire refusé',
    pdfViewer: {
      previous_page: 'Page précédente',
      next_page: 'Page suivante',
      Page_x_on_xx: 'Page {{page}} sur {{pages}}',
    },
    paint: {
      brushRadius: { label: 'taille du pinceau: {{radius}}' },
      btn: {
        reset: 'Effacer',
        undo: 'Annuler',
        delete_draw: 'Supprimer le dessin',
      },
    },
    surveyCreateForm: {
      button: {
        cancel: { label: 'Annuler' },
        send: { label: 'Valider' },
      },
      menu: {
        actionBtn: {
          addStep: { tooltip: 'Ajouter une étape' },
          reorder: { tooltip: 'Réorganiser' },
          upload: { tooltip: 'Ajouter un PDF' },
        },
      },
      header: {
        medicTeamDiscaler: "Le Formulaire sera créé dans l'équipe médicale : <b>{{medicTeamTitle}}</b>",
        title: {
          placeholder: 'Titre du formulaire',
          rules: { required: 'Le Titre doit être renseigné.' },
        },
        type: { placeholder: "Pour quel type d'intervention" },
        speciality: { placeholder: 'Pour quelle spécialité' },
        description: { placeholder: 'Tag' },
        isSide: { label: 'Choix du coté' },
        side: { label: 'Choisissez le côté' },
        isRadius: { label: 'Choix du rayon' },
        radius: { label: 'Choisissez le rayon' },
      },
      title: {
        label: 'Titre du formulaire',
        rules: {
          required: 'Un Titre doit être renseigné.',
          format: 'Le Titre ne doit contenir que des lettres.',
        },
      },
      type: {
        label: "Pour quel type d'intervention",
        rules: { required: "Le type d'intervention doit être renseigné." },
      },
      survey_pdfs: {
        rules: {
          size: 'Le fichier est trop lourd',
          format: 'Seul les fichiers PDF sont acceptés',
        },
      },
      steps: {
        rules: { min: 'Un formulaire doit contenir au moins une étape.' },
        title: {
          rules: {
            required: 'Le titre doit être renseigné.',
            format: 'Le titre ne doit contenir que des lettres.',
          },
        },
        comment: {
          rules: {
            required: 'Le détail de la question doit être renseigné.',
            format: "Le détail de la question n'est pas au bon format.",
          },
        },
        questions: {
          rules: { min: 'Une étape doit contenir au moins une question.' },
          question: {
            rules: {
              required: 'Le titre doit être renseigné.',
              format: 'Le titre ne doit contenir que des lettres.',
            },
          },
          desc: { rules: { required: 'Le détail de la question doit être renseigné.' } },
          validation: { first: { required: 'Le champs doit être renseigné.' } },
        },
      },
    },
    authForm: {
      username: {
        rules: {
          notAnEmail: 'Le champs username ne doit pas être un email',
          required: 'Le champs username doit être renseigné.',
        },
      },
      password: {
        placeholder: 'Entrez votre nouveau mot de passe',
        rules: {
          min: 'Au moins 12 caractères',
          required: 'Le mot de passe doit être renseigné.',
        },
      },
    },
    resetPswdForm: {
      password: {
        placeholder: 'Entrez votre nouveau mot de passe',
        rules: {
          required: 'Le mot de passe doit être renseigné.',
          min: 'Au moins 12 caractères',
          minOneMaj: 'Au moins 1 majuscule',
          minOneMinuscule: 'Au moins 1 minuscule',
          minOneNumber: 'Au moins 1 chiffre',
          minOneSpecialChar: 'Au moins 1 caractère spécial',
        },
      },
      passwordConfirmation: {
        placeholder: 'Confirmez le nouveau mot de passe',
        rules: { same: 'Les mots de passe doivent être identiques' },
      },
    },
    otpForm: {
      title: 'Entrez le code reçu par email',
      text: 'Pour vérifier votre identité, nous vous avons envoyé un code à usage unique par email. Ce code est valide 10 minutes',
      submit: { label: 'Se connecter' },
      cancel: { label: 'Annuler' },
      code: {
        placeholder: 'Code otp (email)',
        rules: { required: 'Le code doit être renseigné.' },
      },
    },
    modalRenewForm: {
      username: {
        description_text: "Veuillez renseigner votre identifiant, qui a été communiqué dans l'e-mail envoyé par Easy-consent.",
        description_text_pro: "Veuillez renseigner votre email ou votre identifiant, qui a été communiqué dans l'e-mail envoyé par Easy-consent.",
        go_to_email: 'Je ne connais pas mon identifiant',
        rules: {
          required: "L'identifiant doit être renseigné.",
          username: "L'identifiant doit être renseigné.",
        },
      },
      email: {
        description_text: 'Merci de bien vouloir renseigner votre adresse email',
        rules: {
          required: "L'email doit être renseigné.",
          email: "L'email doit être renseigné.",
        },
      },
      code: {
        placeholder: 'Entrez le code de sécurité',
        rules: { required: 'Le code doit être renseigné.' },
      },
      password: {
        placeholder: 'Entrez votre nouveau mot de passe',
        rules: {
          required: 'Le mot de passe doit être renseigné.',
          min: 'Au moins 12 caractères',
          minOneMaj: 'Au moins 1 majuscule',
          minOneMinuscule: 'Au moins 1 minuscule',
          minOneNumber: 'Au moins 1 chiffre',
          minOneSpecialChar: 'Au moins 1 caractère spécial',
        },
      },
      passwordConfirmation: {
        placeholder: 'Confirmez le nouveau mot de passe',
        rules: { same: 'Les mots de passe doivent être identiques' },
      },
    },
    medicForm: {
      lastname: {
        label: 'Nom de naissance',
        rules: {
          required: 'Le nom de famille doit être renseigné.',
          format: 'Le nom de famille ne doit contenir que des lettres.',
        },
      },
      firstname: {
        label: 'Prénom',
        rules: {
          required: 'Le prénom doit être renseigné.',
          format: 'Le prénom ne doit contenir que des lettres.',
        },
      },
      phone_number: {
        label: 'Téléphone fixe',
        rules: {
          format: "Le numéro de fixe renseigné n'est pas valide.",
          required: 'Le numéro de fixe doit être renseigné.',
        },
      },
      mobile_phone_number: {
        label: 'Portable',
        rules: {
          format: "Le numéro de portable renseigné n'est pas valide.",
          required: 'Le numéro de portable doit être renseigné.',
        },
      },
      rpps_number: {
        label: 'Numéro RPPS',
        rules: {
          format: 'Le numéro RPPS renseigné est composé de 11 caractères numériques et commencer par 10',
          required: 'Le numéro RPPS doit être renseigné.',
        },
      },
      country: {
        label: 'Pays',
        rules: {
          format: "Le Pays renseigné n'est pas valide.",
          required: 'Le Pays doit être renseigné.',
        },
      },
      language: {
        label: 'Language',
        rules: {
          format: "La langue renseignée n'est pas valide.",
          required: 'La langue doit être renseigné.',
        },
      },
    },
    uploadForm: {
      file: {
        rules: {
          required: 'Merci de séléctionner un fichier',
          too_large: 'Fichier est trop volumineux',
          unsupported_format: 'Format de fichier non géré',
        },
      },
    },
    surveyTemplateForm: {
      template: { rules: { required: 'Merci de séléctionner un template' } },
      type_id: {
        label: 'Type',
        rules: { required: 'Merci de faire votre choix parmis les types disponibles' },
      },
      switch: {
        single: 'Attribution simple',
        multiple: 'Attribution multiple',
      },
    },
    surveyPatientForm: {
      guardian: {
        obligatory: {
          label: 'Représentants obligatoires',
          button: 'Ajouter un représentant légal',
          rules: { required: 'ce champs est requis' },
        },
        facultative: {
          label: 'Représentants facultatifs',
          button: 'Ajouter un représentant facultatif',
        },
      },
      patient_id: {
        label: 'Patient',
        rules: { required: 'Merci de selectionner un patient' },
      },
      clinic_id: {
        label: 'Etablissement',
        rules: { required: 'Merci de selectionner un établissement' },
      },
      medical_teams_id: {
        label: 'Équipe medicale',
        rules: { required: 'Merci de selectionner une équipe médicale' },
      },
      medic_surveys: {
        label: "Composition de l'équipe medicale",
        rules: { required: "L'équipe medicale est requise" },
      },
      referent_medic_id: {
        label: 'Praticien référent',
        rules: { required: 'Un praticien référent est requis' },
      },
      intervention: {
        date_consult: {
          label: 'Date de consultation',
          rules: {
            date: 'La date est requise',
            format: "La date n'est pas au bon format JJ/MM/AAAA",
            required: 'La date est requise',
            min: 'La date est trop éloignée',
            max: 'La date est trop éloignée',
          },
        },
        date: {
          label: "Date de l'acte",
          rules: {
            date: 'La date est requise',
            format: "La date n'est pas au bon format JJ/MM/AAAA",
            required: 'La date est requise',
            min: 'La date est trop éloignée',
            max: 'La date est trop éloignée',
            manual: 'La date doit être postérieur à la consultation',
          },
        },
      },
      date_warning: {
        desc: "<p><span style='color: rgb(230, 0, 0);'>Attention, vous venez de programmer un soin dans {{daysToAct}} jours, soit un délai inférieur à 16 jours.</span></p>Nous vous rappelons qu'il est juridiquement habituel d'accorder à vos patients un délai de réflexion de 15 jours entre la date de la consultation et celle de l'intervention.<br/><br/>Ce délai de convenance correspond au temps de réflexion considéré comme nécessaire pour une adhésion complète du patient.<br><br>Par exemple :<br>Date de consultation le <strong>{{date_consult}}</strong> -> <strong>15 jours</strong> de réflexion <strong>entre</strong> la consultation et l'opération -> date d'opération le <strong>{{ theorical_date_acte }}</strong>.<br><br>Néanmoins, il se peut que dans le cas de votre patient, {{contactNameWithTitle}}, il s'agisse :",
        options: {
          first: "d'une urgence médicalement justifiable",
          second: "d'une demande spécifique du patient",
          third: "d'un accord entre les deux parties",
        },
      },
      supInfoForm: {
        side_answer: {
          label: 'Choisissez le côté',
          rules: {
            format: '',
            required: '',
            min: 'Le côté doit être renseigné.',
            max: 'Merci de choisir un ou deux cotés',
          },
        },
        radius_answer: {
          label: 'Choisissez le rayon',
          rules: {
            format: '',
            required: '',
            min: 'Le rayon doit être renseigné.',
          },
        },
      },
      uploadForm: {
        title_paper: {
          label: 'Titre du document',
          rules: {
            format: '',
            required: '',
          },
        },
        file_paper: {
          label: 'Uploader votre fichier',
          rules: {
            format: '',
            required: '',
          },
        },
      },
    },
    patientForms: {
      modal: {
        create_patient: {
          title: "Création d'un patient",
          titleWithTeam: "Création d'un patient<br/>{{ medicTeamTitle }}",
        },
        create_tutor: {
          title: "Création d'un représentant légal",
          titleWithTeam: "Création d'un représentant légal<br/>{{ medicTeamTitle }}",
        },
      },
      button_choice: {
        tutor: {
          title: 'patient',
          subTitle: 'non autonome',
        },
        independant: {
          title: 'patient',
          subTitle: 'autonome',
        },
      },
      button: { add_tutor: { label: 'Ajouter un représentant légal' } },
      gender: {
        label: 'Genre',
        rules: { required: 'Le genre doit être renseigné.' },
      },
      language_id: {
        label: 'Language',
        rules: { required: 'Le language doit être renseigné.' },
      },
      security_number: {
        label: 'Numéro de sécurité sociale',
        rules: {
          required: 'Le numéro de sécurité sociale doit être renseigné.',
          size: 'Le numéro de sécurité social doit être composé de 15 chiffres.',
          format: 'Le numéro de sécurité social ne doit contenir que des chiffres.',
          not_valid: "Le numéro de sécurité social renseigné n'est pas valide.",
          key_not_valid: "La clé du numéro de sécurité social renseigné n'est pas valide.",
        },
      },
      lastname: {
        label: 'Nom de naissance',
        rules: {
          required: 'Le nom de famille doit être renseigné.',
          format: 'Le nom de famille ne doit contenir que des lettres.',
        },
      },
      use_name: {
        label: "Nom d'usage",
        rules: {
          required: "Le nom d'usage ne doit contenir que des lettres.",
          format: "Le nom d'usage ne doit contenir que des lettres.",
        },
      },
      firstname: {
        label: 'Prénom',
        rules: {
          required: 'Le prénom doit être renseigné.',
          format: 'Le prénom ne doit contenir que des lettres.',
        },
      },
      birthdate: {
        label: 'Date de naisance',
        rules: {
          date: "La date demandée n'existe pas.",
          required: 'La date doit être renseignée.',
          format: 'Le format de la date doit être. JJ/MM/AAAA',
          max: "Impossible de renseigner une date de naissance ultérieure à aujourd'hui.",
        },
      },
      birthplace_postcode: {
        label: 'Code postal de naissance',
        rules: {
          required: 'Le code postal doit être renseigné.',
          format: "Le code postal renseigné n'est pas français.",
        },
      },
      birthplace_id: {
        label: 'Ville de naissance',
        rules: {
          required: 'La ville doit être renseigné',
          format: 'La ville ne doit contenir que des lettres.',
        },
      },
      birthplace: {
        label: 'Ville de naissance',
        rules: {
          required: 'La ville doit être renseigné',
          format: 'La ville ne doit contenir que des lettres.',
        },
      },
      city_postcode: {
        label: 'Code postal',
        rules: {
          required: 'Le code postal doit être renseigné.',
          format: "Le code postal renseigné n'est pas français.",
        },
      },
      city_id: {
        label: 'Ville de résidence',
        rules: {
          required: 'La ville doit être renseigné',
          format: 'La ville ne doit contenir que des lettres.',
        },
      },
      city: {
        label: 'Ville de résidence',
        rules: {
          required: 'La ville doit être renseigné',
          format: 'La ville ne doit contenir que des lettres.',
        },
      },
      phone_number: {
        label: 'Portable',
        rules: {
          format: "Le numéro de portable renseigné n'est pas valide.",
          required: 'Le numéro de portable doit être renseigné.',
        },
      },
      email: {
        label: 'Email',
        rules: {
          required: "L'adresse email doit être renseignée.",
          email: "L'adresse email renseignée n'est pas valide.",
          unavailable: "L'adresse email renseignée n'est pas disponible.",
        },
      },
      email_confirm: {
        label: 'Email (Vérification)',
        placeholder: 'email@service.com',
        rules: {
          required: "Merci de confirmer l'adresse email.",
          must_be_the_same: 'Les adresses email ne correspondent pas.',
        },
      },
    },
    medic_templatesList: {
      list: {},
      item: {
        popup: {
          delete: {
            title: 'Voulez-vous vraiment supprimer ce formulaire ?',
            iconTooltip: 'Lecture seule',
            iconTooltipWithName: 'Lecture seule, appartient au Dr {{ lastname }}',
          },
        },
      },
      card: {
        date: { prefix: 'Créé le {{ date }}' },
        actions: { add_fom: { title: 'Ajouter le formulaire' } },
      },
    },
    side_require: 'Le côté doit être renseignée.',
    radius_require: 'Le rayon doit être renseignée.',
    username_require: "L'identifiant doit être renseigné.",
    patient_require: 'Un(e) patient(e) doit être renseigné(e).',
    etablissement_require: "L'établissement doit être renseigné.",
    type_require: "Le type d'intervention doit être renseigné.",
    empty_list: 'Liste vide',
    past_date: "La date renseigné doit être ultérieure à aujourd'hui.",
    invalid_date: 'Date invalide.',
    medical_team_require: "L'équipe médicale doit être renseignée.",
    medical_referent_require: 'Le praticien rérérent doit être renseigné.',
    home_phone_format: "Le numéro de téléphone renseigné n'est pas valide.",
    login_patient: 'Je suis un patient',
    accueil_connect: 'Se connecter',
    accueil_first_connect: 'Premiere connection',
    accueil_min_text: 'Je suis',
    accueil_patient_text: 'un patient',
    acceuil_patient_text_uppercase: 'Un patient',
    acceuil_menu_add_form: 'Un formulaire',
    accueil_medecin_text: 'professionnel',
    accueil_medecin_min_text: 'Espace',
    modal_create_interv_form: "Attribution d'un formulaire",
    modal_edit_medic_form: 'Edition du profil',
    countersign_invalid_number_redirect: 'Vous serez redirigé vers la fenêtre de contresignature après validation de votre numéro de téléphone.',
    countersign_invalid_number: 'Vous devez renseigner un numéro de téléphone valide pour effectuer une Contresignature',
    edit_phone_number: 'Editer mon numéro de téléphone',
    username_placeholder: 'Identifiant',
    email_placeholder: 'Adresse E-mail',
    password_placeholder: 'Mot de passe',
    invalid_credentials: 'Identifiant et/ou mot de passe invalide',
    forgotten_password: 'Mot de passe oublié',
    forgotten_password_step1: 'Mot de passe oublié ?',
    forgotten_password_step2: 'Confirmez votre identité',
    forgotten_password_step3: 'Redéfinition de votre mot de passe',
    forgotten_password_code: 'Si les informations saisies correspondend à un compte, vous recevrez un code de validation par SMS',
    first_connexion: 'Premiere connection',
    first_connexion_reset_pwd: 'Création de votre mot de passe',
    first_connexion_desc: "Si c'est votre premiere connection veuillez entrer le code qui vous a était envoyé par SMS",
    password_modify_error: 'La modification de votre mot de passe a échouée',
    password_modify_success: 'Votre mot de passe a été modifié avec succès',
    contentheader_title_form: 'Titre du formulaire',
    auth_header_profile: 'Mon profil',
    auth_header_logout: 'Déconnexion',
    patient_list_patient: 'Patient',
    patient_list_surgery: 'Intervention',
    filter_card_display: 'Afficher',
    filter_card_surgery: 'interventions',
    filter_card_consent: 'consentements',
    filter_card_patient: 'patient.es',
    stat_card_in_progress: 'en cours',
    stat_card_reformulated: 'reformulations',
    stat_card_signed: 'signatures',
    stat_card_refused: 'refus',
    patient_card_title: 'Infos patient',
    patient_card_empty_list: 'Aucun patient',
    patient_card_empty_list_surgery: 'Aucune intervention',
    patient_card_empty_list_consent: 'Aucun consentement',
    patient_card_empty_list_patient: 'Aucun patient',
    patient_card_birthdate: 'Né le',
    patient_card_birthplace: 'Lieu de naissance',
    patient_card_legal: 'Représentation légal',
    patient_card_intervention_securite: 'Numéro de sécurité sociale',
    patient_card_intervention_gender: 'Sexe',
    patient_card_intervention_number: 'Numéro :',
    patient_card_birthname: 'Nom de naissance :',
    research: 'Recherche',
    valid_document: 'Validation du document',
    invalid_number: 'Numéro de téléphone invalide',
    modal_contact_support_title: 'Contactez le support',
    modal_contact_support_body: 'Merci de bien vouloir contacter le service clientèle afin de vous ajouter à une équipe médicale',
    male: 'Homme',
    female: 'Femme',
    Homme: 'Homme',
    Femme: 'Femme',
    form_card_add_title: 'Nouveau formulaire',
    form_card_item_date: 'Créé le ',
    form_card_search_placeholder: "Recherche d'un formulaire",
    form_card_search_placeholder_medic: "Recherche d'un formulaire praticien",
    form_card_search_placeholder_store: "Recherche d'un formulaire store",
    form_screen_second_list_title: 'Formulaires disponibles',
    create_patient_success: 'Patient créé',
    question_types: 'Types de questions',
    question_type_validation: 'Validation',
    question_type_text_answer: 'Réponse texte',
    question_type_multiple_choice: 'Choix multiple',
    question_type_dropdown_list: 'Liste déroulante',
    question_type_pdf: 'PDF',
    question_type_checkbox_full: 'Cases à cocher',
    question_type_radio_multi_questions_to_table: 'Cases à cocher to table',
    cancel_form_creation: 'Annuler la création',
    form_step_title: "Titre de l'étape",
    form_screen_slide_a_componant: 'Glisser un composant ici',
    form_screen_add_a_componant: 'Cliquer pour ajouter un composant ici',
    form_screen_write_details: 'Ajouter une reformulation',
    form_screen_delete_question: 'Supprimer la question',
    form_screen_title_required: 'Requis',
    form_screen_type_required: 'Requis',
    form_screen_speciality_required: 'Requis',
    rewording_card_title: 'Reformulation',
    answer: 'Réponse',
    radius_choice: 'Choix du rayon',
    side_choice: 'Choix du coté',
    medic_assign_from_store_error: "Erreur lors de l'attribution du formulaire provenant du store",
    medic_assign_from_store_success: 'Attribution du formulaire provenant du store effectuée',
    medic_unassign_from_store_success: 'Suppression du formulaire effectuée',
    medic_unassign_from_store_error: 'Erreur lors de la suppression du formulaire',
    hide_template_store: 'Mes formulaires',
    display_template_store: 'Store',
    confirm_delete_template: 'Voulez-vous vraiment supprimer ce formulaire ?',
    confirm_archive_patient: 'Voulez-vous vraiment supprimer ce patient ? Vous ne pourrez plus revenir en arrière.',
    confirm_withdrawal_consent: 'Voulez-vous vraiment retirer votre consentement ? Vous ne pourrez plus revenir en arrière.',
    confirm_delete_steps: 'Ajouter un PDF supprimera les étapes. Êtes-vous sûr ?',
    confirm_change_lng: 'Des changements ne sont pas validés. Confirmez-vous le changement de langue ?',
    upload_document: 'Charger nouveau document',
    delete_document: 'Supprimer le document',
    hide_document: 'Cacher le document',
    show_document: 'Voir le document',
    document_loading: 'Votre document est en cours de chargement...',
    error_create_form_medic_team: "Impossible de créer un nouveau formulaire. Vous n'appartenez à aucune équipe médicale.",
    card_button_numerique: 'Un acte numérique',
    card_button_papers: 'Un acte papier',
    patient_multiple_choice_error_message: "Pour pouvoir signer votre consentement, toutes les cases doivent être cochées. Si vous ne souhaitez pas cocher l'une de ces cases, vous pouvez cliquer sur \"{{ furtherBtnText }}\" ou \"{{ refuseBtnText }}\" situés en bas de votre écran. Votre consentement sera interrompu.",
    patient_screen: { interventionsList: { title: 'Historique des formulaires' } },
    btn: {
      edit: 'Éditer',
      edit_the_informations: 'Éditer les informations',
    },
    patient_button_edit: 'Éditer',
    patient_button_relancer: 'Relancer',
    patient_button_upload: 'Upload',
    patient_button_delete: 'Supprimer',
    patient_button_contact: 'Contacter',
    patient_text_contacts: 'Contacts patient',
    patient_text_phone: 'Téléphone',
    patient_text_email: 'Email',
    patient_text_username: 'Identifiant',
    patient_text_address: 'Adresse',
    patient_text_interventions: 'Historique des formulaires',
    patient_title_type: 'Nom du formulaire',
    patient_title_numero: 'Numéro',
    patient_title_date: 'Date',
    patient_title_status: 'Statut',
    patient_title_documents: 'Documents',
    patient_title_action: 'Action',
    patient_title_representant: 'Représentant',
    patient_item_voir: 'Voir',
    patient_intervention_signed: 'signé',
    patient_intervention_countersigned: 'contresigné',
    patient_intervention_sended: 'en cours',
    patient_intervention_to_sign: 'signer',
    patient_intervention_unsigned: 'refusé',
    patient_item_contre_signature: 'Contre signer',
    countersign_modal: 'Contresignature',
    patient_edit_error: "Erreur lors de l'édition du patient",
    patient_edit_success: 'Patient édité avec succès',
    surgeon: 'Chirurgien',
    establishment: 'Établissement',
    pathology: 'Pathologie',
    date_acte: 'Acte médical programmé le',
    commentary: 'Commentaire',
    no_commentary: "Aucun commentaire n'est associé à ce formulaire.",
    preview_answers: 'Visualisation de vos réponses',
    summary_details_first_part: 'Veuillez prendre le temps de bien lire les réponses, puis cliquez sur le bouton Continuer en fin de page. Pour rappel, vous devez impérativement avoir près de vous le téléphone mobile correspondant au numéro ',
    summary_details_last_part: ' sur lequel un SMS avec le code vous sera envoyé.',
    sumary_details_patient_part: "L'analyse de vos réponses montre que votre consentement libre et éclairé peut être recueilli. En effet, lors de votre consentement vous avez déclaré :",
    medic_signature: 'Je soussigné {{ medic }} investigateur au centre {{ clinic }}, déclare contresigner le consentement signé par le patient le {{ date }}.',
    medic_signature_text_one: 'Je soussigné ',
    medic_signature_text_two: ' investigateur du protocole de recherche clinique ',
    medic_signature_text_three: ', déclare contresigner le consentement signé par le patient ',
    tutors_text_contacts: 'Contacts représentant légal',
    tutors_text_name: 'Nom',
    profil_coordonnees: 'Coordonnées',
    profil_id_connexion: 'Identifiants de connexion',
    profil_specialites: 'Spécialités',
    no_specialites_disclaimer: 'Pas de spécialité. Rapprochez-vous du service technique',
    no_medic_team_disclaimer: "Pas d'équipe médicale. Rapprochez-vous du service technique",
    profil_notifications: 'Notifications',
    patient_update_cgu_error: 'Vous venez de refuser les CGU',
    patient_update_cgu_success: 'Vous avez accepté les CGU',
    patient_update_rgpd_error: 'Vous venez de refuser les politiques de confidentialité',
    patient_archive_success: 'Le patient a bien été supprimé',
    patient_archive_error: 'Vous n\'avez pas la permission de supprimer ce patient',
    patient_update_rgpd_success: 'Vous avez accepté les politiques de confidentialité',
    patient_update_edit_info_error: 'Erreur lors de la mise à jour des informations',
    medic_fetch_metrics_error: 'erreur lors de la récupération des statistiques',
    info_name: 'Nom',
    info_rpps: 'N° RPPS',
    info_inscription: "Département d'inscription",
    info_country: 'Pays',
    info_language: 'Language',
    idconnection_notification: 'Recevoir les notifications par',
    idconnection_email: 'Email',
    notification_text_sms: 'Sms',
    notification_text_email: 'Email',
    specialite_title_pathologies: 'Pathologies',
    specialites_pathologies_active: 'pathologies sont activées',
    medicals_teams_title: 'Equipes Médicales',
    medicals_teams_label: 'Équipe:',
    practitioner: 'Praticien',
    no_characteristique_chosen: "Aucune caractéristiques n'est activée",
    consent_preview_intervention: 'Le consentement de {{patientDisplay}} est bien créé !',
    consent_preview_creation: 'est bien créée !',
    consent_preview_consentement: 'Consentement',
    consent_preview_locally: 'sur place',
    consent_preview_home_based: 'à domicile',
    consent_preview_intervention_date: 'La date limite pour la signature du consentement est le {{interventionDate}}',
    consent_preview_procedure: "afin qu'il puisse terminer la procédure.",
    consent_preview_mail: "Un mail va être envoyé à {{patientDisplay}} afin qu'il puisse terminer la procédure.",
    consent_preview_merci: 'Merci',
    consent_tutor_is: 'Tuteur de la procédure: {{tutorDisplay}}',
    consent_or: 'OU',
    update_info_title: 'Mise à jour des infos de contact {{displayName}}',
    patient_survey_resume_error: "L'envoi de mail à échoué, veuillez réessayer plus tard ou contacter le support",
    patient_survey_resume_success: "Un email vient d'être envoyé au patient",
    patient_informations: 'Vos Informations',
    patient_tutored: 'Tuteur de',
    patient_contact: 'Contact',
    patient_no_tutored: 'Patient autonome',
    empty_tab_patient: "Pas encore d'interventions",
    empty_cache: 'Vider le cache du navigateur',
    password: {
      rules: {
        number_character: 'Au moins 12 caractères',
        upper: 'Au moins 1 majuscule',
        lower: 'Au moins 1 minuscule',
        number: 'Au moins 1 chiffre',
        special_character: 'Au moins 1 caractère spécial',
      },
      strength: {
        Veryweak: 'Trop faible',
        Weak: 'Faible',
        Good: 'Moyen',
        Strong: 'Fort',
      },
    },
    identity_modal_title: 'Verification de votre identité',
    verify_identity_error: 'Attention, il semblerait que vous ne soyez pas la personne correspondant au mot de passe que vous avez saisi.',
    verify_identity_error_confirm: 'Vous nous confirmez ne pas être:',
    verify_identity_error_first_choice: "1. J'ai fait une erreur de saisie et je souhaite revenir à la question",
    verify_identity_error_second_choice: '2. Vous confirmez vouloir arrêter la procédure ?',
    verify_identity_error_first_button: 'Retour à la question',
    verify_identity_error_second_button: 'OUI, je confirme',
    verify_identity_error_stop_consent: 'En cas de confirmation, votre consentement sera interrompu, vous devrez impérativement contacter votre praticien car votre intervention va être annulée.',
    verify_identity_success_confirm: "Dans le cadre des procédures d'identitovigilance, vous nous avez confirmé votre identité.",
    verify_identity_success_consent_begin: 'La procédure de receuil du consentement libre et eclairé peut donc commencer.',
    complementary_info: 'Informations complémentaires',
    consent_modal_end: 'Maintenant que vous avez pu avoir ces informations complémentaires, avez-vous compris ?',
    consent_modal_title: "Attention, vous n'avez pas compris la question suivante:",
    consentFormStep: {
      action: {
        'further information': {
          btn: 'Souhaite des informations complémentaires',
          confirmation_message: "En cas de confirmation, votre consentement sera interrompu. Merci de contacter votre praticien afin qu'il puisse répondre à vos interrogations.",
        },
        previous: { btn: 'Précédent' },
        return_patient: { btn: 'Retourner à la fiche Patient' },
        Continue: { btn: 'Continuer' },
        Refuse: {
          btn: 'Refuser',
          confirmation_message: "En cas de confirmation, votre consentement sera interrompu, vous devrez impérativement contacter votre praticien dans les plus brefs délais pour qu'il vous explique ce que vous n'avez pas compris.",
        },
        ConfirmRefuse: { message: 'Êtes-vous sûr ?' },
        RefuseWithPurpose: {
          message: 'Veuillez indiquer le motif de votre refus de participation',
          btn: 'Refuser',
          confirmation_message: 'En cas de confirmation, votre rendez-vous avec le praticien pourra être annulé.',
        },
      },
    },
    understand: "Oui, j'ai compris",
    not_understand: "Non, je n'ai pas compris",
    sign_your_consent: 'Signature de votre consentement',
    send_code_back: 'Renvoyez le code',
    send_code_back_in_given_time: 'Renvoyez un code dans {{ time }} s',
    send_code_too_many_tries: 'Vous avez essayé trop de fois. Veuillez réessayer plus tard ou contacter le support.',
    consent_signed: 'Consentement signé',
    consent_error: 'Erreur de signature',
    forbidden: 'Accès interdit',
    forbidden_access_not_allowed: "L'accès à ce contenu est interdit",
    forbidden_user_access_not_allowed: "L'utilisateur ne peut pas accéder à ce contenu",
    unauthorized_invalid_appKey: 'Accès non authorisé: default de appKey',
    user_with_this_email_already_exists: "Création de patient impossible : L'email choisi est dejà utilisé pour un autre utilisateur. Merci de créer un nouvel utilisateur manuellement ou l'éditer",
    error_on_updating_patient: 'Une erreur est survenue lors de la mise à jour du patient',
    forbidden_query_from_different_account: "Vous effectuez la demande depuis un compte qui n'est pas celui attendu. Merci de vous reconnecter",
    generic_error_contact: 'contact.easyconsent@docaposte.fr',
    generic_error_subject: 'Erreur',
    generic_error_message: 'Erreur',
    generic_error_contact_message: 'Vous rencontrez une erreur, veuillez contacter votre support :',
    not_allowed_by_cors: "La requete n'est pas autorisée depuis ce poste, merci de contacter l'équipe support technique Easy Consent",
    connector_forbidden_access_not_allowed: "Connecteur: L'accès au contenu est interdit",
    connector_forbidden_user_access_not_allowed: "Connecteur: L'utilisateur ne peut pas accéder à ce contenu",
    connector_forbidden_query_from_different_account: "Connecteur: Vous effectuez la demande depuis un compte qui n'est pas celui attendu. Merci de vous reconnecter",
    connector_forbidden: 'Connecteur: Accès interdit',
    connector_unauthorized_invalid_appKey: 'Connecteur: Accès non authorisé suite à un default de appKey',
    connector_forbidden_access_path: "Connecteur: l'accès à la route demandée n'est pas authorisée avec votre profile",
    connector_forbidden_medic_is_not_from_clinic: "Connecteur: Le compte n'est pas rattaché à la clinique. Merci de vous rapprocher d'un administrateur",
    mail_error_invalid_email: "Le email fournit n'est pas valide",
    sms_error_invalid_email: "Le numéro de téléphone fournit n'est pas valide",
    survey_is_already_countersigned: 'Ce formulaire est déjà contre signé',

    default_form_data: {
      title: '',
      step: { title: "Titre de l'étape" },
      questions: {
        multiple_choice: {
          question: 'Votre Question Choix Multiple',
          list: { firstChoice: 'Premier choix' },
        },
        text_answer: { question: 'Votre Question Texte' },
        dropdown_list: {
          question: 'Votre Question Menu déroulant',
          list: { firstChoice: 'Premier choix' },
        },
        pdf: { question: 'Votre question PDF' },
        checkbox_full: {
          question: 'Votre question cases à cocher',
          list: { firstChoice: 'Premier choix' },
        },
        radio_multi_questions_to_table: {
          question: 'Votre question cases à cocher',
          list: { yes: 'Oui', no: 'Non', notApplicable: 'Non applicable', notConcerned: 'Non concerné' },
        },
        validation: {
          question: 'Votre Question Validation',
          data: { first: "Oui, j'ai compris", second: "Non, je désire de l'information supplémentaire" },
        },
      },
    },
    link_is_expired: 'Votre lien est expiré. Merci de vous reconnecter',
    errors: {
      too_many_attempts: 'Trop de tentatives. Votre compte a été bloqué.',
      not_allowed: 'Mot de passe et/ou identifiant incorrect.',
    },
    options: 'Options',
    medic_title: {
      dr: 'Dr.',
      pr: 'Pr.',
      mr: 'M.',
      ms: 'Mme',
    },
    error: 'Erreur',
    'too_many_attempt_please_try_again_later': "Vous avez atteint la limite d'envoi de message. Merci d'essayer ultérieurement.",
    'too_many_user_matching_query': 'Merci de contacter le service technique.',
    'error_with_code': "Une erreur c'est produite. Merci d'essayer ultérieurement ou contacter le support.",
    'missing_a_pro_phone_number': 'Merci de prendre contact avec votre investigateur afin qu\'il complete son numéro de téléphone portable.',
    already_signed: 'Le document est déjà signé. Il est maintenant accessible en lecture',
    add_tutor_success: 'Le représentant légal a bien été ajouté',
    add_tutor_error: 'Erreur lors de l\'ajout du représentant légal',
  },
};