
import * as mask from '../../../../formV3/utils/masks';

export const INITIAL_CONTACT = {
  city_postcode: mask.postcode,
  city_id: undefined,
  city: '',
  phone_number: '',
  email: '',
  email_confirm: '',
};