
import { format } from 'date-fns';
import _ from 'lodash';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { PatientService, UserService } from '../../services';
import { USER_ROLE } from '../../utils';
import { phoneNumberMapper } from '../../utils/dataMappers';
import { isNullOrWhitespace } from '../../utils/utils';
import { AuthActions } from '../auth';
import { FormsActions } from '../forms';
import { LoaderActions } from '../loader';
import { MedicActions } from '../medic';
import { SnackActions } from '../snackBar';
import { default as PatientActions, types } from './actions';

/**
 * Start Fetching all patient for the current medic
 * @returns {Generator<*, void, ?>}
 */
function* patientsRequest({ ids }) {
  yield put(PatientActions.startLoader());
  const [error, data] = ids
    ? yield call(PatientService.patientsByIds, ids)
    : yield call(PatientService.patients);
  if (error || (data && !data.patients)) {
    yield put(PatientActions.patientsFailure());
    yield put(SnackActions.displayError('patient_list_error'));
    return;
  }
  const { patients } = data;
  const shouldMerge = true;
  yield put(PatientActions.patientsSuccess(patients, shouldMerge));
  yield put(PatientActions.stopLoader());
}

/**
 * Start Creating or Update many patient. It will create an account for created patient
 * @param patients
 * @param emails
 * @returns {Generator<*, void, ?>}
 */
function* patientsCreateRequest({ patients }) {
  yield put(LoaderActions.loading());
  const [error, response] = yield call(PatientService.create, patients.map(p => {
    p.birthdate = p.birthdate ? format(p.birthdate, 'yyyy-MM-dd') : null;
    return p;
  }));

  if (error || !_.get(response, 'data')) {
    yield put(PatientActions.patientsCreateFailure());
    yield put(SnackActions.displayError('create_patients_error'));
  } else {
    yield put(SnackActions.displayInfo('create_patient_success'));

    const { patients: resPatients } = response && response.data;
    // fetch patients and add to patients_list
    yield put(PatientActions.patientsCreateSuccess(resPatients.filter(p => p.is_tutor === false)));
    yield put(MedicActions.medicTeamAddPatient(resPatients.filter(p => p.is_tutor === false)));
    yield put(FormsActions.clearData());
  }
  yield put(LoaderActions.loaded());
}

function* tutorCreateForPatient({ tutor, patientId }) {
  const [error, response] = yield call(PatientService.tutorCreateForPatient, patientId, tutor);

  if (error || !response) {
    yield put(SnackActions.displayError('add_tutor_error'));
    return;
  }

  const { patient } = response && response.data;
  yield put(SnackActions.displayInfo('add_tutor_success'));
  yield put(PatientActions.selectedPatientUpdateSuccess(patient));
  yield put(PatientActions.selectedPatientAddFormulaire(patient.id));
}

function* patientUpdateRequest({ id, patient }) {
  const { user } = yield select((state) => state.auth);
  const pickFields = user.role !== USER_ROLE.PATIENT
    ? ['gender', 'firstname', 'lastname', 'use_name', 'security_number', 'email', 'phone_number', 'local_phone_number', 'address', 'city_id', 'city', 'postcode', 'birthdate', 'birthplace_id', 'birthplace', 'birthplace_postcode']
    : ['security_number', 'phone_number', 'local_phone_number', 'address', 'city_id', 'city', 'postcode', 'birthplace_id', 'birthplace', 'birthplace_postcode'];
  yield put(LoaderActions.loading());
  if (patient.birthplace_postcode) {
    patient.birthplace_postcode = _.get(patient, 'birthplace_postcode', '').replace(/_/g, '').replace(/\s/g, '');
  }
  if (patient.postcode) {
    patient.postcode = _.get(patient, 'postcode', '').replace(/_/g, '').replace(/\s/g, '');
  }
  if (patient.phone_number) {
    const { local_phone_number, phone_number } = phoneNumberMapper(patient.phone_number);
    if (phone_number) {
      patient.phone_number = phone_number;
    }
    if (local_phone_number) {
      patient.local_phone_number = local_phone_number;
    }
  }
  const [error, response] = yield call(PatientService.patchPatient, id, _.pick(patient, pickFields));
  if (error && !response) {
    yield put(PatientActions.patientUpdateFailure());
    yield put(SnackActions.displayError('patient_edit_error'));
  } else {
    yield put(PatientActions.patientUpdateSuccess({ ...patient, id }));
    const currentUserId = yield select((state) => _.get(state, 'auth.user.id'));
    if (currentUserId) {
      // if login user is updating is data then mark edit_info: true
      yield put(AuthActions.updateUserEditInfoRequest(currentUserId));
    }
    yield put(SnackActions.displayInfo('patient_edit_success'));
  }
  yield put(LoaderActions.loaded());
}

/**
 * Fetch a single patient.
 * @param id
 * @returns {Generator<*, void, ?>}
 */
function* patientDetailRequest({ patientId, selectedTeam, params }) {
  yield put(PatientActions.clearSelectedPatient());
  const { list } = yield select((state) => state.patient);
  if (params.loading === true) yield put(LoaderActions.loading());
  if (list[patientId]) {
    yield put(PatientActions.patientDetailSuccess(list[patientId]));
  } else {
    if (params.loading === true) yield put(LoaderActions.loaded());
  }
  const [error, response] = selectedTeam
    ? yield call(PatientService.patientDetails, selectedTeam, patientId)
    : yield call(PatientService.getPatientDetail, patientId);
  const patient = _.get(response, 'data.result');
  if (error || !patient) {
    yield put(PatientActions.patientDetailFailure());
    yield put(SnackActions.displayError('patients_detail_error'));
  } else {
    yield put(PatientActions.patientDetailSuccess(patient));
  }
  yield put(LoaderActions.loaded());
}

function* tutoredCurrentDetailRequest() {
  const [error, response] = yield call(PatientService.tutored);
  if (error) {
    yield put(PatientActions.tutoredCurrentDetailFailure());
  }
  const tutored = response && response.tutors[0] && response.tutors[0].patientByPatientId;
  if (tutored) {
    yield put(PatientActions.tutoredCurrentDetailSuccess(tutored));
    return;
  }
  yield put(PatientActions.tutoredCurrentDetailFailure());
}

function* checkIfEmailExistsInDb({ email, userId }) {
  const [error, response] = yield call(UserService.doesEmailExist, email, userId);
  if (!error && response) {
    const countPatients = _.get(response, 'patients_aggregate.aggregate.count', 0);
    const countMedics = _.get(response, 'medics_aggregate.aggregate.count', 0);
    yield put(PatientActions.patientsEmailSuccess((countPatients + countMedics) === 0));
    return;
  }
  yield put(PatientActions.patientsEmailFailure());
}

function* patientsSurveyResumeRequest({ surveyId }) {
  const [error, response] = yield call(PatientService.resumeSurvey, surveyId);
  if (response && !error) {
    yield put(PatientActions.resumeSurveySuccess(surveyId));
    yield put(SnackActions.displayInfo('patient_survey_resume_success'));
  } else {
    yield put(SnackActions.displayError('patient_survey_resume_error'));
  }
}

function* patientsUpdateRGPDRequest({ id }) {
  const [error, response] = yield call(PatientService.updateRGPD, id);
  if (error) {
    yield put(SnackActions.displayError('patient_update_rgpd_error'));
  } else if (response && response.update_users.returning[0].accepted_rgpd) {
    yield put(SnackActions.displayInfo('patient_update_rgpd_success'));
    yield put(AuthActions.rgpdSuccess());
  }
}

function* patientsUpdateCguRequest({ id }) {
  const [error, response] = yield call(PatientService.updateCGU, id);
  if (error) {
    yield put(SnackActions.displayError('patient_update_cgu_error'));
  } else if (response && response.update_users.returning[0].accepted_cgu) {
    yield put(SnackActions.displayInfo('patient_update_cgu_success'));
    yield put(AuthActions.cguSuccess());

  }
}

function* patientsUpdateEditInfoRequest({ id }) {
  const [error, response] = yield call(PatientService.updateEditInfo, id);
  if (error) {
    yield put(SnackActions.displayError('patient_update_edit_info_error'));
  } else if (response && response.update_users.returning[0].edit_info) {
    yield put(AuthActions.editInfoSuccess());
  }
}

function* generateSignatureCode({ surveyId }) {
  const [error, response] = yield call(PatientService.generateSignatureCode, surveyId);
  if (!error && response) {
    const { uuid, code } = response.data;
    yield put(AuthActions.setLastSmsSent(uuid));
    if (!isNullOrWhitespace(code)) {
      yield put(SnackActions.displayInfo(code));
    }

  }
}

function* patientUpdateIntervention({ interventionNumber, date, dateWarning }) {
  const [error, response] = yield call(PatientService.updateInterventionInfo, interventionNumber, format(date, 'dd/MM/yyyy'), dateWarning);
  if (error || (response && !response.data.success)) {
    yield put(PatientActions.patientsPatchFailure());
    yield put(SnackActions.displayError('patients_patch_error'));
    return;
  }

  const { selectedPatient } = yield select((state) => state.patient);
  const index = (selectedPatient.surveys || []).findIndex(s => (s?.intervention?.number && s.intervention.number === interventionNumber));
  const targetSurvey = selectedPatient.surveys[index];
  const newSurveys = [
    ...selectedPatient.surveys.slice(0, index),
    {
      ...targetSurvey,
      intervention: {
        ...targetSurvey.intervention,
        date: date,
        reasonWhy: dateWarning,
      },
    },
    ...selectedPatient.surveys.slice(index + 1),
  ];

  const newPatient = {
    ...selectedPatient,
    surveys: newSurveys,
  };

  yield put(PatientActions.patientUpdateSuccess(newPatient));
  yield put(MedicActions.updateMedicalTeamPatient(newPatient));

  yield put(SnackActions.displayInfo('DocumentChoice.sliderMessage.intervention_date_have_been_updated'));

}

function* patientsPatchRequest({ patientId, data }) {
  // fetch patient
  const [error, response] = yield call(PatientService.patchPatient, patientId, data);
  if (error || (response && !response.update_patients)) {
    yield put(PatientActions.patientsPatchFailure());
    yield put(SnackActions.displayError('patients_patch_error'));
    return;
  }
  const { update_patients: { returning } } = response;
  const patient = returning[0];
  yield put(PatientActions.patientUpdateSuccess(patient));
}

function* patientArchiveRequest({ patientId, selectedTeam }) {
  const [error, response] = yield call(PatientService.patientArchiveRequest, patientId, selectedTeam);
  if (error) {
    yield put(SnackActions.displayError('patient_archive_error'));
  } else if (response.data.success) {
    yield put(PatientActions.clearSelectedPatient());
    yield put(SnackActions.displayInfo('patient_archive_success'));
    yield put(AuthActions.setRedirectUrl('/'));
  }
}

/**
 * MAPPING
 */
// eslint-disable-next-line import/no-anonymous-default-export
export default [
  takeLatest(types.PATIENTS_REQUEST, patientsRequest),
  takeLatest(types.PATIENTS_CREATE_REQUEST, patientsCreateRequest),
  takeLatest(types.PATIENTS_UPDATE_REQUEST, patientUpdateRequest),
  takeLatest(types.PATIENTS_DETAIL_REQUEST, patientDetailRequest),
  takeLatest(types.PATIENTS_EMAIL_REQUEST, checkIfEmailExistsInDb),
  takeLatest(types.PATIENTS_SURVEY_RESUME_REQUEST, patientsSurveyResumeRequest),
  takeLatest(types.PATIENTS_UPDATE_CGU_REQUEST, patientsUpdateCguRequest),
  takeLatest(types.PATIENTS_UPDATE_EDIT_INFO_REQUEST, patientsUpdateEditInfoRequest),
  takeLatest(types.PATIENTS_UPDATE_RGPD_REQUEST, patientsUpdateRGPDRequest),
  takeLatest(types.TUTORED_CURRENT_DETAIL_REQUEST, tutoredCurrentDetailRequest),
  takeLatest(types.PATIENTS_GENERATE_SIGNATURE_CODE, generateSignatureCode),
  takeLatest(types.PATIENTS_INTERVENTION_UPDATE_REQUEST, patientUpdateIntervention),

  takeLatest(types.PATIENTS_PATCH_REQUEST, patientsPatchRequest),

  takeLatest(types.PATIENTS_TO_ARCHIVE, patientArchiveRequest),
  takeLatest(types.PATIENTS_ADDING_A_PATIENT_TUTOR, tutorCreateForPatient),

];
