
import React from 'react';
import { useTranslation } from 'react-i18next';

import ContraliaIFrameCounterSignModal from '../../../../screens/ConsentV2/ConsentRecap/ContraliaIFrameCounterSignModal';
import Modal from '../../../modal';

const MedicCounterSignModal = ({
  onClose,
  survey,
  onSignSuccess,
  resetSurveyCounterSigned,
  patientDetailRequest,
}) => {
  const { t } = useTranslation();

  const handleSignSuccess = () => {
    patientDetailRequest(survey?.patient_id, survey?.medical_teams_id);

    onSignSuccess();
    resetSurveyCounterSigned();
  };

  if (!survey?.id) return <div />;
  return (
    <Modal isOpen title={t('countersign_modal')} onClose={onClose} >
      <ContraliaIFrameCounterSignModal
        surveyId={survey?.id}
        onSignSuccess={handleSignSuccess}
      />
    </Modal>
  );
};

export default MedicCounterSignModal;
