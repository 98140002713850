import _ from 'lodash';
import { connect } from 'react-redux';

import { interpolate } from '../../../../../utils/utils';
import InputSelectV2 from './InputSelectV2';

const mapStateToProps = (state, props) => {
  // storeOptionPath is location to date in Redux -> city.cities
  // storeItemValue is the key of the object -> id for obejct city from array cities
  // storeItemLabel is a mask in order to give a label-> {{ name }}, {{ id }}
  const { storeOptionPath, storeItemValue, storeItemLabel, options } = props;
  let reduxOptions;
  if (storeOptionPath){
    const storeList = _.get(state, storeOptionPath);
    // get the label from storeItemLabel

    reduxOptions = (options || (_.isObject(storeList) && _.values(storeList)) || (storeList && Array.isArray(storeList) && storeList) || []).map(
      (el) => ({
        value: _.get(el, storeItemValue, el.value || el.id || ''),
        label: (storeItemLabel && interpolate(storeItemLabel, el)) || el.title || el.name || el.value || el.id,
      }),
    );
  }
  return { options: options || reduxOptions };
};

export default connect(mapStateToProps, null)(InputSelectV2);
