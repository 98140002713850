import { connect } from 'react-redux';

import { SurveyActions } from '../../../../../redux/survey';
import ContraliaIFrameSignatureModal from './ContraliaIFrameSignatureModal';


const mapStateToProps = ({ survey, forms, auth }) => {
  return {
    survey: survey.currentSurvey,
    consentAnswers: forms?.data?.consent,
    ip: auth.ip,
    signUrl: survey.signUrl,
    signUrlError: survey.signUrlError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  genSurveySignUrl: (surveyId, questions, ip, startDate, endDate) => dispatch(SurveyActions.genSurveySignUrl(surveyId, questions, ip, startDate, endDate)),
  genSurveySignUrlReset: () => dispatch(SurveyActions.genSurveySignUrlSuccess(null)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContraliaIFrameSignatureModal);
