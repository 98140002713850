// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.createPatient-card-container {
  display: flex;
  flex-direction: column;
  overflow: auto;
  border-radius: 8px;
  overscroll-behavior-y: contain;
  height: 405px;
  width: 100%;
}

.medic-form-list-item-container {
  margin-left: 10px;
}

.SurveyTemplateForm-switch .form-screen-switch-template-label {
  margin-left: auto;
}

.form-screen-switch-template-label-selector {
  height: 30px;
  width: 30px;
  margin-right: 5px;
  background-color: #eeeef1;
  border-radius: 5px;
}

.form-screen-switch-template-label-selector-validate {
  background-color: #00aecb;
  border-radius: 5px;
  color: white;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.9px;
  margin: auto 5px auto 0;
  padding: 5px 5px;
}


.paper-title-Input {
  width: 94%;
  height: 27px;
  border-radius: 8px;
  border: solid 1px #cfcfcf;
  background-color: #ffffff;
  outline: none;
  padding: 16px;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.7;
  color: #393d52;
}
`, "",{"version":3,"sources":["webpack://./src/ui/components/surveyFormV2/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,cAAc;EACd,kBAAkB;EAClB,8BAA8B;EAC9B,aAAa;EACb,WAAW;AACb;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,iBAAiB;EACjB,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,YAAY;EACZ,eAAe;EACf,iBAAiB;EACjB,qBAAqB;EACrB,uBAAuB;EACvB,gBAAgB;AAClB;;;AAGA;EACE,UAAU;EACV,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;EACzB,yBAAyB;EACzB,aAAa;EACb,aAAa;EACb,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,cAAc;AAChB","sourcesContent":[".createPatient-card-container {\n  display: flex;\n  flex-direction: column;\n  overflow: auto;\n  border-radius: 8px;\n  overscroll-behavior-y: contain;\n  height: 405px;\n  width: 100%;\n}\n\n.medic-form-list-item-container {\n  margin-left: 10px;\n}\n\n.SurveyTemplateForm-switch .form-screen-switch-template-label {\n  margin-left: auto;\n}\n\n.form-screen-switch-template-label-selector {\n  height: 30px;\n  width: 30px;\n  margin-right: 5px;\n  background-color: #eeeef1;\n  border-radius: 5px;\n}\n\n.form-screen-switch-template-label-selector-validate {\n  background-color: #00aecb;\n  border-radius: 5px;\n  color: white;\n  font-size: 14px;\n  font-weight: bold;\n  letter-spacing: 0.9px;\n  margin: auto 5px auto 0;\n  padding: 5px 5px;\n}\n\n\n.paper-title-Input {\n  width: 94%;\n  height: 27px;\n  border-radius: 8px;\n  border: solid 1px #cfcfcf;\n  background-color: #ffffff;\n  outline: none;\n  padding: 16px;\n  font-size: 20px;\n  font-weight: 600;\n  line-height: 1.7;\n  color: #393d52;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
