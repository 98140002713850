import './style.css';

import React, { useContext, useRef, useState } from 'react';
import { isBrowser, isIOS13 } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import { IconMenuForm, IconMoreOptions, IconPlus } from '../../../../assets/img/icons';
import { AbilityContext } from '../../../../services/casl/Can';
import { PERMISSIONS } from '../../../../services/casl/roles';
import { CONTENT_TYPE_MAPPER } from '../../../../utils/enum';
import { PrimaryButton } from '../../../components/buttons';
import Card from '../../../components/card';
import ConfirmPopup from '../../../components/confirmPopup';
import { FileUploader } from '../../../components/formV3/controllers';
import NavigateBack from '../../../components/navigateBack';
import ToolTip from '../../../components/tooltip';
import Question from './Question';

const Menu = ({
  questionTypes,
  onBackClick,
  onAddStep,
  onReorder,
  hasToConfirmPDF,
  readOnly,
  disabledSteps,
}) => {
  const { t } = useTranslation();
  const ability = useContext(AbilityContext);
  const [showConfirm, setShowConfirm] = useState(false);

  const fileUploaderRef = useRef({});

  const handleOnBackClick = () => {
    if (onBackClick) onBackClick();
  };

  const handleOnAddStep = (event) => {
    event.preventDefault();
    if (onAddStep) onAddStep(event);
  };

  const handleOnReorder = (event) => {
    event.preventDefault();
    if (onReorder) onReorder();
  };

  const handleOnConfirm = () => {
    setShowConfirm(!showConfirm);
  };

  const handleConfirmClick = (_event, action) => {
    setShowConfirm(false);
    switch (action) {
      case 'modal':
        break;
      case 'delete':
        if (fileUploaderRef && fileUploaderRef.current) {
          fileUploaderRef.current.toggleInput();
        }
        break;
      default:
    }
  };

  return (
    <>
      <div className="createform-menu-container" style={{ width: readOnly && '10px' }}>
        <ToolTip id='hideHeightOver800' />
        <NavigateBack
          text={!readOnly ? t('cancel_form_creation') : t('return')}
          onClick={handleOnBackClick}
        />
        { !readOnly && (
          <div className="createform-menu-cards-container">
            <Card>
              <p className="createform-menu-card-title"> {'Action'} </p>
              <PrimaryButton
                center={'left'}
                active={false}
                image={IconPlus}
                text={t('a_step_translation')}
                onClick={handleOnAddStep}
                tooltip={t('surveyCreateForm.menu.actionBtn.addStep.tooltip')}
                tooltipId={'hideHeightOver800'}
                disabled={disabledSteps}
              />
              <PrimaryButton
                center={'left'}
                active={false}
                image={IconMoreOptions}
                text={t('reorder_translation')}
                onClick={handleOnReorder}
                tooltip={t('surveyCreateForm.menu.actionBtn.reorder.tooltip')}
                tooltipId={'hideHeightOver800'}
                disabled={disabledSteps}
              />
              <div
                className="create-form-upload-pdf-button-container"
                data-tip={t('surveyCreateForm.menu.actionBtn.upload.tooltip')}
                data-for='hideHeightOver800'
              >
                <FileUploader
                  name={'survey_pdfs.0.file'}
                  ref={fileUploaderRef}
                  buttonTitle={t('add_a_pdf_translation')}
                  buttonClassName="ec-primary-button ec-primary-button-left"
                  buttonTitleClassName="ec-primary-button-text"
                  uploadIcon={IconMenuForm}
                  iconInside
                  onConfirm={hasToConfirmPDF ? handleOnConfirm : null}
                  accept={`${CONTENT_TYPE_MAPPER.PDF}`}
                />
              </div>
            </Card>
            { isBrowser && !isIOS13 && (
              <Card>
                <p className="createform-menu-card-title">{t('question_types')}</p>
                { questionTypes.filter(q => ability.can(PERMISSIONS.READ, q)).map((type) => {
                  const { id, value } = type;
                  return (
                    <Question
                      key={`question_type_${id}_${value}`}
                      type={type}
                      text={t(`question_type_${value}`)}
                      disabled={disabledSteps}
                    />
                  );})}
              </Card>
            )}
          </div>
        )}
      </div>
      <ConfirmPopup
        isOpen={showConfirm}
        title={t('confirm_delete_steps')}
        onConfirm={handleConfirmClick}
        onClose={(v) => setShowConfirm(v)}
        action='delete'
      />
    </>
  );
};

export default Menu;
