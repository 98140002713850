import _ from 'lodash';
import { call, put, takeLatest } from 'redux-saga/effects';

import languageService from '../../services/languageService';
import { default as LanguageActions, types } from './actions';

function* fetchAll() {
  yield put(LanguageActions.startLoader());
  const [error, data] = yield call(languageService.fetchAll);
  if (!error && data) {
    yield put(LanguageActions.fetchAllSuccess(_.get(data, 'languages')));
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default [takeLatest(types.FETCH_ALL, fetchAll)];
