import 'react-phone-input-2/lib/style.css';

import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import ReactPhoneInput from 'react-phone-input-2';

const PhoneInput = ({
  name,
  rules = { required: false },
  placeholder,
  disabled,
  defaultValue,
  containerClassName,
  inputClassName,
  ...extraInputProps
}) => {
  const { control } = useFormContext(); // retrieve all hook methods
  const {
    field: { ref, value, onChange, ...field }, // field : { onChange, onBlur, value, name: fieldName, ref, ...field },
    // field : { onChange, onBlur, value, name: fieldName, ref, ...field },
    // fieldState: { invalid, isTouched, isDirty, error },
    // formState: { errors, isDirty, isSubmitting, touched, submitCount },
  } = useController({
    name,
    control,
    rules: { ...rules },
    defaultValue: defaultValue || _.get(control, ['defaultValuesRef', 'current', name]) || '',
  });


  const handleChange = (value, country, e, formattedValue) => {
    onChange(value);
    // setValue({ areaCode: country.dialCode });
  };

  return (
    <div className={`${containerClassName || 'inputFormItem-container'}`}>
      <ReactPhoneInput
        inputProps={{
          name: 'phone',
          required: true,
        }}
        inputClass={`${inputClassName || 'ec-form-phone-number-input'}`}
        onlyCountries={['al', 'dz', 'ad', 'au', 'at', 'by', 'be',
          'ba', 'bg', 'ca', 'cn', 'hr', 'cy', 'cz', 'dk', 'ee', 'fi', 'fr',
          'de', 'gr', 'gp', 'gy', 'ie', 'it', 'jp', 'xk', 'lv', 'li', 'lu',
          'mk', 'mt', 'md', 'mc', 'me', 'ma', 'nl', 'no', 'pl', 'pt', 'ro', 'sk',
          'si', 'es', 'se', 'ch', 'tr', 'ua', 'gb', 'us', 'va',

        ]}
        country={'fr'} // initial country
        preferredCountries={['fr']}
        disabled={disabled}
        containerStyle={{ height: '68px' }}
        inputStyle={{ height: '100%', width: '100%' }}
        placeholder={placeholder}
        inputRef={ref}
        // disableDropdown
        // countryCodeEditable={true}
        // isValid={(inputNumber, country, countries) => {
        //   return countries.some((country) => {
        //     return _.startsWith(inputNumber, country.dialCode) || _.startsWith(country.dialCode, inputNumber);
        //   });
        // }}
        onChange={handleChange}
        value={value}
        {...field}
        {...extraInputProps}
      />
    </div>
  );
};

PhoneInput.propTypes = {
  name: PropTypes.string,
  rules: PropTypes.object,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  extraInputProps: PropTypes.object,

};

export default PhoneInput;
