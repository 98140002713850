/**
 * GRAPHQL QUERIES
 */
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  fetchAll: `
    query GetLanguages {
      languages(order_by: { id: asc }) {
        id
        language_code 
        name
        date_format 
        country_code_flag
      }
    }
  `,
};
