import '../style.scss';

import PropTypes from 'prop-types';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';

function InputGroupedCheckboxV2({ name, options, className, readOnly = false, control, ...rest }) {
  const methods = useFormContext();
  const [checkList, setCheckList] = React.useState();
  const {
    field: { onChange, value, ...field },
    fieldState: { error },
  } = useController({
    name,
    control: control || methods.control,
  });

  React.useEffect(() => {
    if (!checkList && Array.isArray(options)) {
      setCheckList(Array.isArray(value) ? value : []);
      !readOnly && onChange(Array.isArray(value) ? value : []);
    }
  }, [value]);

  const handleChange = (event) => {
    if (readOnly) return;
    const { target: { checked, value: v } } = event;
    let newCheckList = [...(checkList|| [])];
    if (checked) {
      if (!(checkList|| []).includes(v)){
        newCheckList = [...newCheckList, v];
      }
    } else {
      newCheckList = newCheckList.filter( val => val !== v);
    }
    setCheckList(newCheckList);
    onChange(newCheckList);
  };
  return (
    <div className='d-flex flex-column'>
      {options.map(({ label, value: optionVal }, index) => {
        return (
          <label
            key={`${name}_${optionVal}_${index}`}
            htmlFor={`${name}_${optionVal}_${index}`}
            className={'checkboxContainer  mb-2'}
          >
            <input
              id={`${name}_${optionVal}_${index}`}
              type="checkbox"
              value={`${optionVal}`}
              disabled={readOnly}
              checked={Array.isArray(checkList) && checkList.includes(`${optionVal}`)}
              aria-invalid={error ? 'true' : 'false'}
              onChange={handleChange}
              className={`${className ||''} ${readOnly ? 'noHover' : ''}`}
              {...rest}
              {...field}
            />
            <span className="label">{label}</span>
          </label>
        );
      })}
    </div>
  );
}

InputGroupedCheckboxV2.propTypes = {
  register: PropTypes.func,
  name: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number]),
  })),
};

export default InputGroupedCheckboxV2;
