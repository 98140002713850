import { connect } from 'react-redux';

import { AuthActions } from '../../redux/auth';
import Redirect from './Redirect';


const mapStateToProps = ({ auth, medic }) => {
  return {
    redirectUrl: auth.redirectUrl,
    selectedTeam: medic.selectedTeam,
  };
};

const mapDispatchToProps = (dispatch) => ({ clearRedirectUrl: () => dispatch(AuthActions.clearRedirectUrl()) });


export default connect(mapStateToProps, mapDispatchToProps)(Redirect);
