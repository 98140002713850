/**
 * GRAPHQL MUTATIONS
 */

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  delete: () => `
    mutation Delete($medicTemplateId: Int!) {
      delete_questions(where: {step: {medic_template_steps: {medic_template_id: {_eq: $medicTemplateId}}}}) {
        returning {
          id
        }
      }
      delete_steps(where: {medic_template_steps: {medic_template_id: {_eq: $medicTemplateId}}}) {
        returning {
          id
        }
      }
      delete_medic_template_steps(where: {medic_template_id: {_eq: $medicTemplateId}}) {
        returning {
          medic_template_id
        }
      }
      delete_medic_templates(where: {id: {_eq: $medicTemplateId}}) {
        returning {
          id
        }
      }
    }
  `,
  archiveMedicTemplate: () => `
    mutation ArchiveMedicTemplate($medicTemplateId: Int!, $archivedDate: timestamptz!) {
      update_medic_templates(where: {id: {_eq: $medicTemplateId}}, _set: {is_archived: true, archived_date: $archivedDate}) {
        returning {
          id
        }
      }
    }
  `,
  updateActiveState: () => `
    mutation UpdateActiveState($medicTemplateId: Int!, $isPublished: Boolean!) {
      update_medic_templates(where: {id: {_eq: $medicTemplateId}}, _set: { is_published: $isPublished  }) {
        returning {
          ...MedicTemplatesCoreFields
        }
      } 
    }
  `,
  deleteOne: () => `
    mutation DeleteOne($medicTemplateId: Int!) {
      delete_questions(where: {step: {medic_template_steps: {medic_template_id: {_eq: $medicTemplateId}}}}) {
        returning {
          id
        }
      }
      delete_steps(where: {medic_template_steps: {medic_template_id: {_eq: $medicTemplateId}}}) {
        returning {
          id
        }
      }
      delete_medic_template_steps(where: {medic_template_id: {_eq: $medicTemplateId}}) {
        returning {
          medic_template_id
        }
      }
      delete_survey_pdfs(where: {medic_template_id: {_eq: $medicTemplateId}}) {
        returning {
          id
        }
      }
      delete_medic_templates(where: {id: {_eq: $medicTemplateId}}) {
        returning {
          id
        }
      }
    }
  `,
};
