// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* COMMON */
.ec-question-basics-common-text {
  white-space: pre-line;
  text-align: justify;
}

.ec-question-basics-common-red-text {
  color: red;
}

.ec-question-basics-button {
  border: none;
  outline: none;
  padding: 10px;
  border-radius: 8px;
  background-color: white;
  margin-top: 10px;
}

.ec-question-basics-red-buttons:hover {
  opacity: 0.5;
}

.ec-bg-white-onhover:hover {
  background-color: #eeeef1;
}

.ec-question-basics-blue-buttons {
  background-image: linear-gradient(252deg, #00d7e7, #00aecb);
}

.ec-question-basics-red-buttons {
  background-color: red;
}

.ec-question-basics-blue-buttons:hover {
  background-image: linear-gradient(255deg, #06c3d4, #06aac3);
}

.ec-question-basics-modal-separator {
  border: 1px solid grey;
  margin-top: 2px;
  margin-bottom: 2px;
}

/* VerifyIdentity.jsx */
`, "",{"version":3,"sources":["webpack://./src/ui/components/questionBasicsV2/style.css"],"names":[],"mappings":"AAAA,WAAW;AACX;EACE,qBAAqB;EACrB,mBAAmB;AACrB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,aAAa;EACb,kBAAkB;EAClB,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,2DAA2D;AAC7D;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,2DAA2D;AAC7D;;AAEA;EACE,sBAAsB;EACtB,eAAe;EACf,kBAAkB;AACpB;;AAEA,uBAAuB","sourcesContent":["/* COMMON */\n.ec-question-basics-common-text {\n  white-space: pre-line;\n  text-align: justify;\n}\n\n.ec-question-basics-common-red-text {\n  color: red;\n}\n\n.ec-question-basics-button {\n  border: none;\n  outline: none;\n  padding: 10px;\n  border-radius: 8px;\n  background-color: white;\n  margin-top: 10px;\n}\n\n.ec-question-basics-red-buttons:hover {\n  opacity: 0.5;\n}\n\n.ec-bg-white-onhover:hover {\n  background-color: #eeeef1;\n}\n\n.ec-question-basics-blue-buttons {\n  background-image: linear-gradient(252deg, #00d7e7, #00aecb);\n}\n\n.ec-question-basics-red-buttons {\n  background-color: red;\n}\n\n.ec-question-basics-blue-buttons:hover {\n  background-image: linear-gradient(255deg, #06c3d4, #06aac3);\n}\n\n.ec-question-basics-modal-separator {\n  border: 1px solid grey;\n  margin-top: 2px;\n  margin-bottom: 2px;\n}\n\n/* VerifyIdentity.jsx */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
