
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const variantStyles = ({ theme, variant = 'primary' }) =>
  ({
    'outline-primary': css`
      color: ${theme.buttons.outlinePrimary.color};
      border-color: ${theme.buttons.outlinePrimary.borderColor};
      background: ${theme.buttons.outlinePrimary.background};
        &:hover {
          background: ${theme.buttons.outlinePrimary.hover.background};
          color: ${theme.buttons.outlinePrimary.hover.color};
        }
    `,
    primary: css`
      color: ${theme.buttons.primary.color};
      background: ${theme.buttons.primary.background};
        &:hover {
          background: ${theme.buttons.primary.hover.background};
          color: ${theme.buttons.primary.hover.color};
        }
      `,
    secondary: css`
      color: ${theme.buttons.secondary.color};
      background: ${theme.buttons.secondary.background};
      &:hover {
        background: ${theme.buttons.primary.hover.background};
        color: ${theme.buttons.primary.hover.color};
      }
    `,
    link: css`
      color: ${theme.buttons.ghost.color};
      background: transparent;
      &:hover {
        color: ${theme.buttons.ghost.hover.color};
      }
    `,
    ghost: css`
      color: ${theme.buttons.ghost.color};
      background: ${theme.buttons.ghost.background};
      &:hover {
        background: ${theme.buttons.ghost.hover.background};
        color: ${theme.buttons.ghost.hover.color};
      }
    `,
    'outline-danger': css`
      color: ${theme.buttons.outlineDanger.color};
      border-color: ${theme.buttons.outlineDanger.borderColor};
      background: ${theme.buttons.outlineDanger.background};
        &:hover {
          background: ${theme.buttons.outlineDanger.hover.background};
          color: ${theme.buttons.outlineDanger.hover.color};
        }
    `,
    danger: css`
      color: ${theme.buttons.danger.color};
      background: ${theme.buttons.danger.background};
      &:hover {
        background: ${theme.buttons.danger.hover.background};
        color: ${theme.buttons.danger.hover.color};
      }
    `,
    warning: css`
      color: ${theme.buttons.warning.color};
      background: ${theme.buttons.warning.background};
      &:hover {
        background: ${theme.buttons.warning.hover.background};
        color: ${theme.buttons.warning.hover.color};
      }
    `,
    icon: css`
      width: 58px;
      height: 58px;
      border-radius: 20px;
      color: ${theme.buttons.icon.color};
      background: ${theme.buttons.icon.background};
      border: none;
      outline: none;
      padding: 16px;
      margin: 0;
      &:hover {
        &> img {
          filter: invert(54%) sepia(38%) saturate(5038%) hue-rotate(154deg) brightness(96%) contrast(101%);
        }
        background: ${theme.buttons.icon.hover.background};
        color: ${theme.buttons.icon.hover.color};
      }
    `,
  }[variant]);

const Button = styled.button`
  min-width: ${props => props.minWidth};
  padding: ${props => props.padding};
  height: ${props => props.height};
  width: ${props => props.width};
  margin: ${props => props.margin || '10px'};
  border: 1px solid transparent;
  border-radius: 8px;
  appearance: none;
  cursor: pointer;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -moz-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  opacity: ${props => (props.disabled ? '0.4' : '1')};
  ${(props) => variantStyles(props)}
`;

Button.defaultProps = { variant: 'primary', padding: '5px 12px' };


export default Button;

Button.propTypes = {
  variant: PropTypes.oneOf(['primary', 'secondary', 'ghost', 'danger', 'warning', 'icon', 'link', 'outline-primary', 'outline-danger']),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  padding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};