import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FRONT_AREA } from '../../../../utils/enum';
import { validEmailRegex } from '../../../../utils/regex';
import yup from '../../../../utils/yup';
import { InputText } from '../../../components/formV3/controllers';
import { Message } from '../../../components/formV3/errors/ErrorMessage/ErrorMessage';

const AuthForm = ({ defaultValues = {}, onSubmit, handleForgottenPassword, isUserNameDisable, isPro }) => {
  const { t } = useTranslation();
  const [alert, setAlert] = useState('');
  const yupSchema = yup.object().shape({
    username: yup
      .string()
      .required('authForm.username.rules.required')
      .trim(),
    password: yup
      .string()
      .min(4, 'authForm.password.rules.min')
      .required('authForm.password.rules.required')
      .trim(),
  });

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: useMemo(() => {
      return defaultValues;
    }, [defaultValues]),
    resolver: yupResolver(yupSchema),
    shouldFocusError: true,
  });

  useEffect(() => {
    formMethods.reset({ ...defaultValues });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  const { control, handleSubmit, watch } = formMethods;
  const username = useWatch({
    control : control,
    name: 'username', // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
    defaultValue: defaultValues.username || '', // default value before the render
  });

  const handleKeyPress = event => {
    event.preventDefault();
  };

  useEffect(() => {
    setAlert(!isPro && validEmailRegex.test(username) ? t('authForm.username.rules.notAnEmail') : '');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username]);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)} className={'auth-patient-form'}>
        <div className={'auth-patient-content d-flex flex-column  justify-content-center'} >
          <Message
            message={alert}
            style={{
              background: 'lightgray',
              borderRadius: '2px',
              padding: '0 4px',
            }}
          />
          <InputText
            inputPreset={'email'}
            name={'username'}
            readOnly={isUserNameDisable}
            placeholder={t('auth.form.username.label', { context: isPro ? FRONT_AREA.PRO : FRONT_AREA.PATIENT })}
            hidden
            containerClassName={'w-100'}
            className={'t'}
            defaultValue={_.get(defaultValues, 'username')}
            focusOnload
          />
          <InputText
            name={'password'}
            inputPreset={'password'}
            placeholder={t('auth.form.password.label')}
            hidden
            containerClassName={'w-100 mt-4'}
            className={'t'}
            type="password"
            defaultValue={_.get(defaultValues, 'password')}
          />
          <div className={'auth-patient-text-content'}>
            <div onClick={handleForgottenPassword} role="button" tabIndex={'-1'} onKeyPress={handleKeyPress}>
              <p>{t('auth.forgotten_pswd.label')}</p>
            </div>
          </div>
        </div>
        <div className={'auth-patient-content-end'}>
          <input type="submit" value={t('auth.form.submit.label')} />
        </div>
      </form>
      {!!process.env.REACT_APP_DEBUG_FORM && (
        <pre>
          { JSON.stringify(watch(), null, 2) }
        </pre>
      )}
    </FormProvider>
  );
};

AuthForm.propTypes = {};

export default AuthForm;
