import '../style.scss';

import React from 'react';

import iconValide from '../../../../assets/img/email-verifie@3x.png';
import iconError from '../../../../assets/img/icon-error@3x.png';
import iconLoading from '../../../../assets/img/icon-signe@3x.png';

const FormIcon = ({ type, className }) => {
  const imageForType = () => {
    switch (type) {
      case 'error':
        return iconError;
      case 'validated':
        return iconValide;
      case 'loading':
        return iconLoading;
      default:
      // do nothing
    }
  };

  return (
    <div className={className || 'form-icon'}>
      <img src={imageForType()} alt={`${type}`} />
    </div>
  );
};

export default FormIcon;
