import { clinicQueries } from './GraphQLRequest';
import { authenticatedQuery } from './middleware';

// const {POST} = methods;

/**
 * REST ROUTES
 */
// const routes = {};

/*
 * SERVICES
 */
// eslint-disable-next-line import/no-anonymous-default-export
export default { clinics: () => authenticatedQuery(clinicQueries.clinics()) };
