import React, { useEffect, useMemo } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { PUBLIC_HOME_PATH } from '../../utils/const';


function RequireAuth({ redirectTo = PUBLIC_HOME_PATH, token, isUserLoading }) {
  const location = useLocation();
  const navigate = useNavigate();
  const fromUrl = useMemo(() => `${location.pathname}${location.search || ''}`, [location.pathname]);

  useEffect(() => {
    // once login, navigateTo
    if (!token && !isUserLoading) {
      navigate(redirectTo, { state: { fromUrl } } );
    }
  }, [token, isUserLoading]);

  return <Outlet />;
}

export default RequireAuth;
