
import _ from 'lodash';
import { connect } from 'react-redux';

import { FiltersActions } from '../../../redux/filter';
import { FormsActions } from '../../../redux/forms';
import { PatientActions } from '../../../redux/patient';
import { SurveyActions } from '../../../redux/survey';
import { SURVEY_STATUS } from '../../../utils';
import Medic from './Medic';

const mapStateToProps = ({ display, filters, medic, patient, survey }) => {
  const patients = _.get(medic, ['medicalTeamList', medic.selectedTeam, 'patients'], []);
  const initialSurveyList = Array.isArray(patients)
    ? patients
      .reduce((acc, p) => (Array.isArray(p.surveys) ? [...acc, ...p.surveys] : acc), [])
      .filter(s => s.status !== SURVEY_STATUS.NOT_SIGNED && s.intervention?.date && new Date(s.intervention.date).getTime() > new Date().getTime())
      .map(s => _.pick(s, ['number', 'patient_id', 'status', 'type_id', 'intervention']),
      )
    : [];
  return {
    patients,
    initialSurveyList,
    surveyTypes: Object.values(survey.types || {}),
    isListLoading: patient.loading,
    temporarySurvey: survey.temporarySurvey,
    medic: medic.currentMedic,
    selectedTeamId: medic.selectedTeam,
    activeFilter: filters.medicFilter,
    appMode: display.appMode,
    isClinicalMode: display.isClinicalStudy,
    teams: Object.values(medic?.medicalTeamList || {}),
  };
};

const mapDispatchToProps = (dispatch) => ({
  deleteTemporarySurvey: () => dispatch(SurveyActions.temporarySurveyDelete()),
  questionTypesRequest: () => dispatch(SurveyActions.questionTypesRequest()),
  specialitiesRequest: () => dispatch(SurveyActions.specialitiesRequest()),
  surveyTypesRequest: () => dispatch(SurveyActions.surveyTypesRequest()),
  setActiveFilter: (filter) => dispatch(FiltersActions.setMedicFilter(filter)),
  clearSelectedPatient: () => dispatch(PatientActions.clearSelectedPatient()),
  clearData: () => dispatch(FormsActions.clearData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Medic);
