
import _ from 'lodash';
import { createReducer } from 'reduxsauce';

import { SURVEY_STATUS } from '../../utils';
import { editLetterCaseUsers } from '../../utils/redux-helper';
import { types } from './actions';

export const initialState = {
  loading: false,
  currentMedic: null,
  selectedTeam: null,
  stats: [],
  legalSuccess: false,
  surveyIds: '',
  // used for countersign auto login medic
  autoLoginSurveyID: -1,
  isAutoLoginLoaded: false,
  autoLoginData: null,
  medicalTeamList: null,
};

const startLoader = (state = { ...initialState }) => ({
  ...state,
  loading: true,
});

const stopLoader = (state = { ...initialState }) => ({
  ...state,
  loading: false,
});

const currentMedicSuccess = (state = { ...initialState }, action = {}) => {
  const { currentMedic } = state;
  const { medic: newCurrentMedic } = action;
  return {
    ...state,
    currentMedic: _.merge(currentMedic, newCurrentMedic),
    loading: false,
  };
};

const medicStatsSuccess = (state = { ...initialState }, action = {}) => {
  const { stats } = action;
  const total = stats['total'].aggregate.count;
  const data = [SURVEY_STATUS.SENT, SURVEY_STATUS.REFUSED, SURVEY_STATUS.ACCEPTED, SURVEY_STATUS.FURTHER_INFO].map((key) => {
    const stat = stats[key];
    return (stat.aggregate.count * 100) / total;
  });
  return {
    ...state,
    stats: data,
  };
};

const medicTeamSuccessV2 = (state = { ...initialState }, action = {}) => {
  const { teams } = action;
  const { medicalTeamList } = state;
  return {
    ...state,
    medicalTeamList: _.chain(teams).keyBy('id').mapValues(t => ({
      ...t,
      patients: _.get(medicalTeamList, [t.id, 'patients'], []),
    })).value(),
    loading: false,
  };
};

const medicTeamOwnerSuccess = (state = { ...initialState }, action = {}) => {
  const { teams } = action;
  const data = {};
  if (Array.isArray(teams) && teams.length) {
    teams.forEach((team) => {
      const { medical_teams_medics, ...t } = team;
      const medics = medical_teams_medics.map((m) => editLetterCaseUsers(m.medic));
      data[t.id] = {
        team: {
          ...t,
          owner: editLetterCaseUsers(t.owner),
        },
        medics: medics,
      };
    });
  }
  return {
    ...state,
    medicTeamOwner: data,
    loading: false,
  };
};

const updateMedicSuccess = (state = { ...initialState }, action = {}) => {
  const { medic } = action;
  const { currentMedic } = state;
  return {
    ...state,
    currentMedic: _.merge(currentMedic, medic),
    loading: false,
  };
};

const changeSelectedMedicTeamSuccess = (state = { ...initialState }, action = {}) => {
  const { teamId } = action;
  return {
    ...state,
    loading: false,
    selectedTeam: parseInt(teamId, 10),
  };
};

const medicSetAutoLoginData = (state = { ...initialState }, action = {}) => {
  const { surveyID, patientID, teamID } = action.data;

  return {
    ...state,
    autoLoginData: {
      surveyID: surveyID,
      patientID: patientID,
      teamID: teamID,
    },
    selectedTeam: parseInt(teamID, 10),
    isAutoLoginLoaded: true,
  };
};

const medicResetAutoLoginData = (state = { ...initialState }) => ({
  ...state,
  autoLoginData: null,
  isAutoLoginLoaded: false,
});

const medicTeamAddPatient = (state = { ...initialState }, action = {}) => {
  const { patients, teamId } = action;
  const { medicalTeamList, selectedTeam } = state;
  let newMedicTeamV2 = { ...medicalTeamList };
  patients.forEach(p => {
    newMedicTeamV2 = _.set(newMedicTeamV2, [p.medical_teams_id || teamId || selectedTeam, 'patients'], [..._.get(newMedicTeamV2, [p.medical_teams_id || teamId || selectedTeam, 'patients'], []), { ...p, isNew: true }]);
  });
  return {
    ...state,
    medicalTeamList: newMedicTeamV2,
    loading: false,
  };
};

const medicTeamAddPatientList = (state = { ...initialState }, action = {}) => {
  const { teamId, patients } = action;
  return {
    ...state,
    medicalTeamList: {
      ...(state.medicalTeamList || {}),
      [teamId]: {
        ...(state.medicalTeamList || {})[teamId],
        patients: patients || [],
      },
    },
    loading: false,
  };
};

const medicTeamPatientSurveyDelete = (state = { ...initialState }, action = {}) => {
  // remove surveys from medic. medicalTeam based on survey ids and patient id
  const { patientId, IDs } = action;
  const { medicalTeamList, selectedTeam } = state;
  const patients = medicalTeamList[selectedTeam]?.patients || [];
  const patientIndex = patients.findIndex(p => `${p.id}` === `${patientId}`);

  if (patientIndex >= 0) {
    const targetPatient = patients[patientIndex];
    const newMedicTeamList = {
      ...medicalTeamList,
      [selectedTeam]: {
        ...medicalTeamList[selectedTeam],
        patients: [
          ...patients.slice(0, patientIndex),
          {
            ...targetPatient,
            surveys: (targetPatient?.surveys || []).filter(s => !IDs.includes(s.id)),

          },
          ...patients.slice(patientIndex + 1),
        ],
      },
    };
    return {
      ...state,
      medicalTeamList: newMedicTeamList,
      loading: false,
    };
  } else {
    return state;
  }
};

const updateMedicalTeamPatient = (state = { ...initialState }, action = {}) => {
  const { selectedTeam, medicalTeamList } = state;
  const { newPatient } = action;
  const patients = medicalTeamList[selectedTeam]?.patients || [];
  const patientIndex = patients.findIndex(p => `${p.id}` === `${newPatient.id}`);

  return {
    ...state,
    medicalTeamList: {
      ...medicalTeamList,
      [selectedTeam]: {
        ...medicalTeamList[selectedTeam],
        patients: [
          ...patients.slice(0, patientIndex),
          newPatient,
          ...patients.slice(patientIndex + 1),

        ],
      },
    },
  };
};

const resetReducer = () => {
  return { ...initialState };
};


export default createReducer(initialState, {
  [types.RESET_REDUCER]: resetReducer,

  [types.CURRENT_MEDIC_REQUEST]: startLoader,
  [types.CURRENT_MEDIC_SUCCESS]: currentMedicSuccess,
  [types.CURRENT_MEDIC_FAILURE]: stopLoader,

  [types.MEDIC_STATS_SUCCESS]: medicStatsSuccess,

  [types.UPDATE_MEDIC_REQUEST]: startLoader,
  [types.UPDATE_MEDIC_SUCCESS]: updateMedicSuccess,
  [types.UPDATE_MEDIC_FAILURE]: stopLoader,

  [types.MEDIC_UPDATE_CGU_REQUEST]: startLoader,
  [types.MEDIC_UPDATE_CGU_FAILURE]: stopLoader,

  [types.MEDIC_UPDATE_RGPD_REQUEST]: startLoader,
  [types.MEDIC_UPDATE_RGPD_FAILURE]: stopLoader,

  [types.MEDIC_UPDATE_NOTIF_REQUEST]: stopLoader,
  [types.MEDIC_UPDATE_NOTIF_FAILURE]: stopLoader,

  [types.MEDIC_UPDATE_EDIT_INFO_REQUEST]: startLoader,
  [types.MEDIC_UPDATE_EDIT_INFO_FAILURE]: stopLoader,

  [types.MEDIC_TEAM_REQUEST]: startLoader,
  [types.MEDIC_TEAM_SUCCESS_V2]: medicTeamSuccessV2,
  [types.MEDIC_TEAM_OWNER_SUCCESS]: medicTeamOwnerSuccess,
  [types.MEDIC_TEAM_FAILURE]: stopLoader,

  [types.MEDIC_CHANGE_SELECTED_MEDIC_TEAM_SUCCESS]: changeSelectedMedicTeamSuccess,
  [types.MEDIC_SET_AUTO_LOGIN_DATA]: medicSetAutoLoginData,
  [types.MEDIC_RESET_AUTO_LOGIN_DATA]: medicResetAutoLoginData,

  [types.MEDIC_MEDICAL_TEAM_ADD_PATIENT]: medicTeamAddPatient,

  [types.MEDIC_TEAM_ADD_PATIENT_LIST]: medicTeamAddPatientList,

  [types.MEDICAL_TEAM_PATIENT_SURVEY_DELETE]: medicTeamPatientSurveyDelete,
  [types.UPDATE_MEDICAL_TEAM_PATIENT]: updateMedicalTeamPatient,


});
