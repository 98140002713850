// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.authlayout-wrapper {
  z-index: 1;
  margin-top: 209px;
  width: 100%;
  max-width: 1090px;
}

.authlayout-font-container {
  display: flex;
  z-index: -5;
  justify-content: center;
  align-items: flex-start;
  min-height: 100vh;
  background-image: linear-gradient(126deg, #f5f7fa 1%, #f4f6f8 99%);
}

@media screen and (max-width: 600px) {
  .authlayout-wrapper {
    margin-top: 163px;
  }
}

@media screen and (max-width: 500px) {
  .authlayout-wrapper {
    margin-top: 83px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/ui/layouts/authLayout/style.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,iBAAiB;EACjB,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,uBAAuB;EACvB,uBAAuB;EACvB,iBAAiB;EACjB,kEAAkE;AACpE;;AAEA;EACE;IACE,iBAAiB;EACnB;AACF;;AAEA;EACE;IACE,gBAAgB;EAClB;AACF","sourcesContent":[".authlayout-wrapper {\n  z-index: 1;\n  margin-top: 209px;\n  width: 100%;\n  max-width: 1090px;\n}\n\n.authlayout-font-container {\n  display: flex;\n  z-index: -5;\n  justify-content: center;\n  align-items: flex-start;\n  min-height: 100vh;\n  background-image: linear-gradient(126deg, #f5f7fa 1%, #f4f6f8 99%);\n}\n\n@media screen and (max-width: 600px) {\n  .authlayout-wrapper {\n    margin-top: 163px;\n  }\n}\n\n@media screen and (max-width: 500px) {\n  .authlayout-wrapper {\n    margin-top: 83px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
