
import { createReducer } from 'reduxsauce';

import { types } from './actions';

const initialState = {
  cities: {},
  isCitiesLoading: false,
};

const fetchBegin = (state = { ...initialState }) => ({ ...state, isCitiesLoading: true });

const fetchCityListFailure = (state = { ...initialState }) => ({ ...state, isCitiesLoading: false });

const fetchCityListSuccess = (state = { ...initialState }, action = {} ) => {
  const { cities : newCities } = action;
  return { ...state, cities: { ...newCities }, isCitiesLoading: false };
};

const clearCityList = (state = { ...initialState }) => ({ ...state, ...initialState });

const resetReducer = () => {
  return { ...initialState };
};

export default createReducer(initialState, {
  [types.RESET_REDUCER]: resetReducer,

  // [types.CITIES_FETCH_QUERY]: fetchBegin,
  [types.CITIES_FETCH_BEGIN]: fetchBegin,
  [types.CITIES_FETCH_SUCCESS]: fetchCityListSuccess,
  [types.CITIES_FETCH_FAILURE]: fetchCityListFailure,
  [types.CITIES_CLEAR_LIST]: clearCityList,
});
