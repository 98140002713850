import '../form/style.css';

import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { patientContactMapper } from '../../../utils';
import Modal from '../modal';
import ContactFormContainer from '../patientFormV2/Steps/ContactFormContainer';
import { Button } from '../styledComponent';
import EditPatientContactCard from './EditPatientContactCard';

const EditPatientContact = ({
  patient,
  onUpdate,
  isLoadingPatient,
  isOpenModal,
  onModal,
  isClinicalMode,
}) => {
  const { t } = useTranslation();

  const handleOnExit = (e) => {
    e.preventDefault();
    handleCloseModal();
  };

  const handleSubmit = (data) => {
    if (onUpdate) onUpdate(data);
  };

  const handleOpenModal = () => onModal(true);
  const handleCloseModal = () => onModal(false);

  return (
    <>
      <div className={'infopatient-information'}>
        <EditPatientContactCard
          telephone={(patient?.local_phone_number) || ''}
          email={(patient?.email) || ''}
          address={(patient.postcode ? patient.postcode + ' ' : '') + (patient.city || '')}
          username={patient?.user?.username || ''}
          isloading={isLoadingPatient}
          isClinicalMode={isClinicalMode}
        />
        <div className={'infopatient-bottom-container'}>
          <Button onClick={handleOpenModal} >
            {t('btn.edit')}
          </Button>
          { isOpenModal && (
            <Modal
              show
              title={t('update_info_title', { displayName : '' })}
              onClose={handleOnExit}
              scrollable
              isOpen={isOpenModal}
            >
              <ContactFormContainer
                id={'editP-ContactFormContainer'}
                defaultValues={patientContactMapper({ ...patient })}
                onCancel={handleOnExit}
                onSubmit={handleSubmit}
                userId={_.get(patient, 'user.id')}
              />
            </Modal>
          )}
        </div>
      </div>
    </>
  );
};

export default EditPatientContact;
