// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
/* Commentary.jsx */
.consent-preview-commentary-container {
  display: flex;
  flex-direction: column;
  padding-left: 28px;
  padding-right: 28px;
  padding-bottom: 23px;
}

.consent-preview-commentary-title {
  font-size: 30px;
  font-weight: bold;
  line-height: 0.8;
  color: #20253a;
}

.consent-preview-commentary-area {
  width: 100%;
  border-radius: 6px;
  border: dashed 3px rgba(0, 0, 0, 0.07);
  background-color: rgba(222, 222, 222, 0.07);
  outline: none;
  resize: none;
  padding: 11px 20px 11px 20px;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.4;
  color: #79828b;
}

.consent-preview-commentary-buttons-container {
  display: flex;
  justify-content: space-between;
}

.consent-preview-commentary-button {
  border: none;
  outline: none;
  display: flex;
  background: none;
  align-items: center;
  align-self: flex-start;
  cursor: pointer;
}

.consent-preview-commentary-button-image {
  width: 10%;
  height: 50%;
  margin-right: 9px;
}

.consent-preview-commentary-button-text {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.89;
  color: #00aecb;
}
`, "",{"version":3,"sources":["webpack://./src/ui/screens/ConsentPreviewV2/Commentary/style.css"],"names":[],"mappings":";AACA,mBAAmB;AACnB;EACE,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,mBAAmB;EACnB,oBAAoB;AACtB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,sCAAsC;EACtC,2CAA2C;EAC3C,aAAa;EACb,YAAY;EACZ,4BAA4B;EAC5B,eAAe;EACf,mBAAmB;EACnB,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,aAAa;EACb,gBAAgB;EAChB,mBAAmB;EACnB,sBAAsB;EACtB,eAAe;AACjB;;AAEA;EACE,UAAU;EACV,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,iBAAiB;EACjB,cAAc;AAChB","sourcesContent":["\n/* Commentary.jsx */\n.consent-preview-commentary-container {\n  display: flex;\n  flex-direction: column;\n  padding-left: 28px;\n  padding-right: 28px;\n  padding-bottom: 23px;\n}\n\n.consent-preview-commentary-title {\n  font-size: 30px;\n  font-weight: bold;\n  line-height: 0.8;\n  color: #20253a;\n}\n\n.consent-preview-commentary-area {\n  width: 100%;\n  border-radius: 6px;\n  border: dashed 3px rgba(0, 0, 0, 0.07);\n  background-color: rgba(222, 222, 222, 0.07);\n  outline: none;\n  resize: none;\n  padding: 11px 20px 11px 20px;\n  font-size: 16px;\n  font-weight: normal;\n  line-height: 1.4;\n  color: #79828b;\n}\n\n.consent-preview-commentary-buttons-container {\n  display: flex;\n  justify-content: space-between;\n}\n\n.consent-preview-commentary-button {\n  border: none;\n  outline: none;\n  display: flex;\n  background: none;\n  align-items: center;\n  align-self: flex-start;\n  cursor: pointer;\n}\n\n.consent-preview-commentary-button-image {\n  width: 10%;\n  height: 50%;\n  margin-right: 9px;\n}\n\n.consent-preview-commentary-button-text {\n  font-size: 18px;\n  font-weight: bold;\n  line-height: 1.89;\n  color: #00aecb;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
