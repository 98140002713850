import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import i18n from '../../services/i18n/i18n';
import ClearCache from '../../ui/components/ClearCache';
import LegalModal from '../../ui/components/legalModal';
import GlobalLoader from '../../ui/components/loaders/GlobalLoader';
import TeamLoader from '../../ui/components/TeamLoader';
import {
  Accueil,
  AttachUser,
  Auth,
  Consent,
  ConsentPreview,
  ConsentRecap,
  Forms,
  InfoPatient,
  Medic,
  NoMatch,
  Patient,
  Profil,
  Splash,
  SurveysCreate,
} from '../../ui/screens';
import { MEDIC_ROLES, USER_ROLE } from '../../utils';
import { PATIENT_HOME_PATH, PATIENT_LOGIN_PATH, PRO_HOME_PATH, PRO_LOGIN_PATH, PUBLIC_HOME_PATH } from '../../utils/const';
import { APP_MODES } from '../../utils/enum';
import { delay } from '../../utils/utils';
import CatchVersion from '../CatchVersion';
import LoginFromToken from '../LoginFromToken';
import ProtectedRoutes from '../ProtectedRoutes';
import PublicRoutes from '../PublicRoutes';
import Redirect from '../Redirect';
import RedirectOnceLogin from '../RedirectOnceLogin';
import RequireAuth from '../RequireAuth';


// Main router which handle user navigation
const Router = ({ appMode, selectedTeam, isPro, medicalTeamList }) => {

  const [savedAppMode, setSavedAppMode] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (savedAppMode !== appMode) {
      setIsLoading(true);
      setSavedAppMode(appMode);
      i18n.loadNamespaces(appMode || APP_MODES.COMMON, () => {
        i18n.setDefaultNamespace(appMode || APP_MODES.COMMON);
        delay(400).then(() => {
          setIsLoading(false);
        });
      });
    }
  }, [appMode]);

  const proRedirectUrl = useMemo(() => {
    if (isPro) {
      if (!_.isEmpty(medicalTeamList)) {
        if (selectedTeam in medicalTeamList) {
          return `/teams/${selectedTeam}`;
        } else {
          const teamsArray = Object.values(medicalTeamList);
          const defaultTeam = teamsArray.find(el => el.isDefault);
          if (defaultTeam) {
            return `/teams/${defaultTeam.id}`;
          } else {
            const firstTeam = Array.isArray(teamsArray) && teamsArray.length && teamsArray[0];
            return firstTeam?.id ? `/teams/${firstTeam?.id}` : null;
          }
        }
      }
    }
    return null;
  }, [selectedTeam, medicalTeamList]);

  return (
    <>
      { isLoading
        ? <GlobalLoader infinitLoading/>
        : (
          <BrowserRouter >
            <Routes>
              <Route element={<Redirect/>} >
                <Route element={<CatchVersion/>} >
                  <Route element={<RequireAuth />}>
                    <Route element={<RedirectOnceLogin/>} >
                      { isPro
                        ? (
                          <Route element={<ProtectedRoutes allowedRoles={MEDIC_ROLES} redirect={proRedirectUrl} />}>
                            <Route path={PRO_HOME_PATH} element={proRedirectUrl ? <Navigate to={proRedirectUrl} /> : <Medic />} />
                            <Route path="teams/:teamId" element={<TeamLoader />} >
                              <Route index element={<Medic />} />
                              <Route path="patients/:patientId" element={<InfoPatient />} />
                              <Route path='forms' element={ <Forms /> } />
                              <Route path='store' element={ <Forms isStoreTemplate /> } />
                              <Route path='createform' element={<SurveysCreate />}/>
                              <Route path='createform/:templateId' element={<SurveysCreate />}/>
                            </Route>
                            <Route path='/patients/:patientId' element={ <InfoPatient /> } />
                            <Route path='/profil' element={ <Profil /> } />
                            <Route path='/preview/consent' element={ <ConsentPreview /> } />
                          </Route>
                        ) : (
                          <Route element={<ProtectedRoutes allowedRoles={USER_ROLE.PATIENT} redirect={PATIENT_HOME_PATH} />}>
                            <Route element={ <LegalModal /> } >
                              <Route path={'/'} element={<Navigate to={PATIENT_HOME_PATH} />} />
                              <Route
                                path={PATIENT_HOME_PATH}
                                element={<Patient />}
                              />
                              <Route path='/consent/:consentId' element={ <Consent /> } />
                              <Route path='/consent/:consentId/recap' element={ <ConsentRecap /> } />
                              <Route path='/attach' element={ <AttachUser /> } />
                            </Route>
                          </Route>
                        )}
                    </Route>
                  </Route>
                  <Route element={<PublicRoutes />} >
                    {/* not logged in user */}
                    <Route element={<LoginFromToken/>} >
                      <Route path={PUBLIC_HOME_PATH} element={ <Accueil /> } />
                      <Route path={PATIENT_LOGIN_PATH} element={ <Auth /> } />
                      <Route path={PRO_LOGIN_PATH} element={ <Auth isPro /> } />
                    </Route>
                  </Route>
                  {/* public */}
                  <Route path='/cache' element={<ClearCache />} />
                  <Route path='/splash' element={<Splash />} />
                  <Route path='*' element={<NoMatch />} />
                </Route>
              </Route>
            </Routes>
          </BrowserRouter>
        )}
    </>
  );
};

export default Router;
