import Fuse from 'fuse.js';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';

import { IconSearch } from '../../../assets/img/icons';
import { removeAccents } from '../../../utils/format';
import Input from '../../components/form/Input';

// basic Search function with Input as input text
// put a variable name for component for better rendering on debug
function SearchBarForm({ onSearch, disabled, placeholder, list, keys, threshold = 0.3, ...props }, forwardRef) {
  let [query, setQuery] = useState('');
  const [options, setOptions] = useState({});
  const searchInput = useRef(null);

  useImperativeHandle(forwardRef, () => ({ focus : () => handleFocus() }));

  useEffect(() => {
    if (keys !== options.keys) {
      setOptions({
        isCaseSensitive: false,
        includeScore: true,
        includeMatches: true,
        minMatchCharLength: 1,
        shouldSort: true,
        keys: keys,
        tokenize: true,
        ignoreLocation: true,
        threshold: threshold,
        getFn: (obj, path) => {
          // return remove(Fuse.config.getFn.apply(this, arguments));
          // Use the default `get` function
          var value = Fuse.config.getFn(obj, path);
          // ... do something with `value`
          if (Array.isArray(value)) {
            return value.map((query) => removeAccents(query));
          }
          return removeAccents(value);
        },
      });
    }
  }, [keys]);

  const fuse = new Fuse(list, options);

  const onFuseSearch = (value) => {
    if (!value || value === '') {
      onSearch(null);
      return;
    }
    const result = fuse.search(removeAccents(value));
    onSearch(result);
  };

  const handleInputChange = (event) => {
    if (disabled) return;
    const q = event.target.value;
    setQuery(q);
    onFuseSearch(q);
  };

  const handleFocus = () => {
    searchInput && searchInput.current && searchInput.current.focus();
  };

  return (
    <div className="searchbar-form">
      <img
        className="searchbar-form-input-prefix-img"
        src={IconSearch}
        alt="searchbar-form-input-prefix"
      />
      <Input
        className={'searchbar-form-input'}
        value={query}
        placeholder={placeholder}
        onChange={handleInputChange}
        disabled={disabled}
        ref={searchInput}
        {...props}
      />
    </div>
  );
}

export default React.forwardRef(SearchBarForm);
