import '../form/style.css';

import { differenceInDays, format } from 'date-fns';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { extractParams } from '../../../utils/router-helper';
import Modal from '../../components/modal/Modal';
import RenderStepContent from './RenderStepContent';
import { STEPS } from './utils/steps/definition';
import { StepsContainer } from './utils/steps/StepsContainer';

const CreateSurvey = ({
  surveyTypesRequest,
  clinicRequest,
  paperSurveyCreate,
  createTemporarySurvey,
  patientsList,
  medicalTeamSettings,
  paperMode,
  isModalOpen,
  onCloseModal,
  forms,
  isClinicalStudyUser,
  omitData,
  patient,
  fetchAllLanguages,
  selectedPatientIsAutonomous,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [formStepHistory, setFormStepHistory] = useState([0]);
  const [selectedPatient, setSelectedPatient] = useState();
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [urlParams, setUrlParams] = useState();
  const [selectedMedicalTeamId, setSelectedMedicalTeamId] = useState();

  const steps = useMemo(() => {
    const container = new StepsContainer(paperMode, selectedPatientIsAutonomous);
    return container.getSteps();
  }, [paperMode, selectedPatientIsAutonomous]);


  // fetch data
  useEffect(() => {
    if (surveyTypesRequest) surveyTypesRequest();
    if (clinicRequest) clinicRequest();
    fetchAllLanguages && fetchAllLanguages();
  }, []);

  // on activeStepIndex change, add to history
  useEffect(() => {
    if (activeStepIndex > steps.length - 1 ){
      // send the form
      const data = _.get(forms, 'createSurvey');
      submitForm(data);
      onCloseModal();
    }
  }, [activeStepIndex]);

  // get data on url query
  useEffect(() => {
    const params = extractParams(searchParams);
    if (_.isObject(params) && !_.isEmpty(params)){
      const temp = _.pickBy({
        patient_id: params?.patient ? parseInt(params.patient) : {},
        _interface_patient_id: params._interface_patient_id,
        intervention:  _.pickBy({
          date: params.date_operation,
          date_consult: params.date_consultation,
        }, _.identity),
        clinic_id: params.clinic ? parseInt(params.clinic) : { },
      }, _.identity);
      setUrlParams(temp);
    }
  }, [searchParams]);

  const handlerCloseModal = (e) => {
    setActiveStepIndex(0);
    if (onCloseModal) onCloseModal(e);
  };

  const submitForm = (data) => {
    const patientId = _.get(data, 'patient.patient_id');

    const templates = _.get(data, 'surveyTemplateForm.template');
    const paperData = _.get(data, 'paper');
    const temporarySurvey = {
      id_event_number: _.get(data, 'patient.id_event_number'),
      clinic_id: _.get(data, 'patient.clinic_id'),
      patient_id: patientId,
      date_warning: _.get(data, 'dateWarning.answer'),
      side: _.get(data, 'supInfoForm.side_answer'),
      radius: _.get(data, 'supInfoForm.radius_answer'),
      referent_medic_id: _.get(data, 'patient.referent_medic_id'),
      medical_teams_id: _.get(data, 'patient.medical_teams_id'),
      medic_surveys:  _.get(data, 'patient.medic_surveys'),
      intervention: {
        date_consult: format(_.get(data, 'patient.intervention.date_consult'), 'yyyy-MM-dd'),
        date: format(_.get(data, 'patient.intervention.date'), 'yyyy-MM-dd'),
      },
      tutorsRetained: _.get(data, 'tutorsRetained'),
      //paper: data.paper
    };
    if (paperData) {
      temporarySurvey.title = paperData?.title_paper;
      temporarySurvey.type_id = _.get(paperData, 'type_id');
    } else {
      temporarySurvey.type_id = _.get(templates, '0.type_id');
      temporarySurvey.templateList = templates.map(temp => ({ id: parseInt(temp.value, 10), lng: temp.language_id }));
      temporarySurvey.speciality_id = _.get(templates, '0.speciality_id');
    }

    if (paperMode) {
      temporarySurvey.paper = paperData;
      // return;
      paperSurveyCreate(temporarySurvey);
    } else if (createTemporarySurvey){
      createTemporarySurvey(temporarySurvey, navigate);
    }
  };

  const completeFormStep = async (newVal, formData) => {
    const requestedNewIndex = newVal || activeStepIndex + 1;
    let newIndex = requestedNewIndex;
    const selectedStep = steps[requestedNewIndex];

    if (formData.medical_teams_id){
      setSelectedMedicalTeamId(formData.medical_teams_id);
    }

    if (formData?.patient_id){
      const newSelectedPatient = _.get(patientsList, formData?.patient_id);
      setSelectedPatient(newSelectedPatient);
    }

    if (selectedStep){
      switch (selectedStep) {
        case STEPS.DATE_WARNING:
          // Should we skip DATE_WARNING
          const date_acte = _.get(formData, 'intervention.date');
          const date_consult = _.get(formData, 'intervention.date_consult');
          if (!medicalTeamSettings.show_delay_reason || isClinicalStudyUser || differenceInDays(new Date(date_acte), new Date(date_consult)) > 15) {
            // if isClinicalStudyUser then skip DATE_WARNING
            // or if date_consult + 15 days < date_acte => OK the delay => skip DATE_WARNING
            newIndex = newIndex + 1;
          }
          break;
        case STEPS.SUP_INFO:
          // Should we skip SUP_INFO
          const template = _.get(formData, 'template.0');
          if (template && (template.side && template.side !== 'on' && template.radius && template.radius !== 'on')){
            // + 100 so we go to submit
            newIndex = newIndex + 100;
          }
          break;
        default:
          break;
      }
    }

    if (newIndex !== requestedNewIndex) {
      // if newIndex have changes during fct, call again to skip step if needed
      await completeFormStep(newIndex, formData);
    } else {
      setActiveStepIndex(newIndex);
      setFormStepHistory([...formStepHistory, newIndex]);
    }
  };

  const previousFormStep = (id) => {
    if (id) omitData && omitData(id);
    if (formStepHistory.length === 0){
      handlerCloseModal();
    } else {
      const newFormStepHistory = [...formStepHistory];
      newFormStepHistory.pop();
      setActiveStepIndex(newFormStepHistory.length > 0 ? newFormStepHistory[newFormStepHistory.length - 1] : 0);
      setFormStepHistory(newFormStepHistory);
    }
  };

  return (
    <Modal
      title={t('modal_create_interv_form')}
      isOpen={isModalOpen}
      onClose={handlerCloseModal}
      scrollable
    >
      <RenderStepContent
        activeStepIndex={activeStepIndex}
        urlParams={urlParams}
        forms={forms}
        selectedMedicalTeamId={selectedMedicalTeamId}
        completeFormStep={completeFormStep}
        previousFormStep={previousFormStep}
        patient={patient}
        activeStep={steps[activeStepIndex]}
        selectedPatient={selectedPatient}
      />
    </Modal>
  );
};

export default CreateSurvey;

