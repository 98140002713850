import '../style.scss';

import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';

function InputCheckbox({ name, label, rules = { required: false }, defaultValue, className, readOnly = false, disabled, ...rest }) {
  const { control } = useFormContext(); // retrieve all hook methods
  const inputCheckbox = React.useRef(null);
  const {
    field: { value, ...field }, // field : { onChange, onBlur, value, name: fieldName, ref, ...field },
    fieldState: { error }, // fieldState: { invalid, isTouched, isDirty, error },
    // formState: { errors, isDirty, isSubmitting, touched, submitCount },
  } = useController({
    name,
    control,
    rules: { ...rules },
    defaultValue: defaultValue || '',
  });


  React.useEffect(() => {
    if (disabled && !_.isEmpty(error) && inputCheckbox && inputCheckbox.current) {
      inputCheckbox.current.scrollIntoView();
    }
  }, [error]);

  return (
    <>
      <div ref={inputCheckbox}></div>
      <label htmlFor={`${name}`} className={`${className || ''}`} >
        <input
          type="checkbox"
          aria-invalid={error ? 'true' : 'false'}
          id={`${name}`}
          {...rest}
          {...field}
          checked={value || false}
          readOnly={readOnly}
          className="mr-2 "
          disabled={disabled}
        />
        <span className="ml-2 ">{label}</span>
      </label>
    </>
  );
}

InputCheckbox.propTypes = {
  register: PropTypes.func,
  name: PropTypes.string,
};

export default InputCheckbox;
