import { intlFormat } from 'date-fns';
import Fuse from 'fuse.js';
import _ from 'lodash';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { parseDate } from '../../../services/datefns';
import { normalizeString } from '../../../utils/utils';
import QueryAssist from './queryAssist';
import { dropdownProps, inputProps, selectorProps } from './style.js';

const SearchBar = (
  { searchStyle, wrapperStyle, dropdownStyle, list, onSearch, tags, selectTags, fullKeyList },
  ref,
) => {
  const { t } = useTranslation();
  const assistRef = useRef();

  const [dataFiltred, setDataFiltred] = useState([]);

  useEffect(() => {
    setDataFiltred(tags.filter((d) => !d.hide));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(tags)]);

  useImperativeHandle(ref, () => ({ reset: () => (assistRef && assistRef.current) && assistRef.current.handleCancel() }));

  const newList = (list || []).map((el) => {
    let toRet = { ...el };
    for (let i = 0, len = tags.length; i < len; ++i) {
      const { type, key } = tags[i];
      switch (type) {
        case 'string':
          toRet[key] = toRet[key]
            ? normalizeString(toRet[key])
            : null;
          break;

        case 'date':
          toRet[key] = intlFormat(parseDate(toRet[key]), {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          });
          break;

        case 'intervention':
          if (toRet[key] && toRet[key][0]) {
            const value = _.get(toRet, [key, 0, 'intervention', 'date']);
            toRet[key] = value ? intlFormat(parseDate(value), {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }) : null;
          }
          break;
        default:
          break;
      }
    }
    return toRet;
  });

  const options = {
    shouldSort: true,
    tokenize: true,
    includeScore: true,
    threshold: 0.25,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 3,
    keys: Array.isArray(fullKeyList) ? fullKeyList : tags.map((d) => d.key),
  };

  const optionsPerfectMatch = {
    ...options,
    threshold: 0.1,
    distance: 10,
    keys: tags.map((d) => d.key),
  };

  const optionsDateMatch = {
    ...options,
    threshold: 0.1,
    distance: 10,
    minMatchCharLength: 11,
    keys: tags.filter(el => el.type === 'date').map((d) => d.key),
  };

  const optionsSecuMatch = {
    ...options,
    threshold: 0.1,
    distance: 10,
    minMatchCharLength: 15,
    keys: tags.filter(el => el.type === 'security_number').map((d) => d.key),
  };

  const optionNum = {
    ...options,
    threshold: 0.1,
    distance: 10,
    minMatchCharLength: 1,
    keys: tags.filter(el => ['number', 'phone_number'].includes(el.type)).map((d) => d.key),
  };

  const fuse = new Fuse(newList || [], options);
  // fuse date
  const perfectFuse = new Fuse(newList || [], optionsPerfectMatch);
  // fuse date
  const dateFuse = new Fuse(newList || [], optionsDateMatch);
  // num fuse
  const numFuse = new Fuse(newList || [], optionNum);
  // secu Fuse
  const secuFuse = new Fuse(newList || [], optionsSecuMatch);

  const handleOnChange = (event) => {
    if (!event) {
      handleSearch(null);
    } else {
      const { str, tags: selectedTags } = event;
      if ((!selectedTags || !selectedTags.length) && (!str || str.length <= 1)){
        handleSearch(null);
      }
      let result = [];
      // search from tags
      Array.isArray(selectedTags) && selectedTags.length
        && selectedTags.forEach((tag) => {
          let toRet = [];
          const [element, pattern] = tag.split(':');
          const selectedTag = tags.find(el => el.key === element);
          const normalized = normalizeString(pattern);
          switch (selectedTag.type) {
            case 'phone_number':
              toRet = numFuse.search(normalized);
              break;
            case 'security_number':
              toRet = secuFuse.search(normalized);
              break;
            case 'date':
              toRet = dateFuse.search(normalized);
              break;
            default:
              toRet = perfectFuse.search(normalized);
              break;
          }
          const IDs = toRet.map((t) => t.item.id);
          if (result.length) {
            result = result.filter((r) => IDs.includes(r.id));
          } else {
            result = toRet.map((t) => t.item);
          }
        });
      // search from text input
      if (str && str.length > 1) {
        let toRet = [];
        if (str.includes('/')) {
          toRet = dateFuse.search(str);
        } else {
          toRet = fuse.search(str);
        }
        const IDs = toRet.map((t) => t.item.id);
        if (result.length) {
          result = result.filter((r) => IDs.includes(r.id));
        } else {
          result = toRet.map((t) => t.item);
        }
      }
      handleSearch(result);
    }
  };

  const handleSearch = (toRet) => {
    if (onSearch) onSearch(toRet);
  };

  return (
    <QueryAssist
      ref={assistRef}
      placeholder={t('search')}
      onSubmit={handleOnChange}
      tags={dataFiltred}
      searchStyle={searchStyle}
      wrapperStyle={wrapperStyle}
      dropdownStyle={dropdownStyle}
      inputProps={inputProps}
      dropdownProps={dropdownProps}
      selectorProps={selectorProps}
      selectTags={selectTags}
    />
  );
};

export default forwardRef(SearchBar);
