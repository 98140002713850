import './style.css';

import React from 'react';

const FilterTextLines = ({ primaryText, secondaryText, active }) => {
  return (
    <div className={'d-flex flex-column align-items-start ec-filter-text-lines-container'}>
      <div className={`ec-filter-primarytext-lines ${active ? 'activated' : ''}`} >
        {primaryText}
      </div>
      <div className={`ec-filter-secondarytext-lines ${active ? 'activated' : ''}`} >
        {secondaryText}
      </div>
    </div>
  );
};

export default FilterTextLines;