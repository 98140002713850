import '../style.css';

import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { sortableToSurvey, surveyToSortable } from '../../../../utils';
import { getCircularReplacer, shortidGenerate } from '../../../../utils/utils';
import FormLanguageSelector from '../../../components/FormLanguageSelector';
import ErrorMessage from '../../../components/formV3/errors/ErrorMessage';
import PDFViewer from '../../../components/PDFViewer';
import { Button } from '../../../components/styledComponent';
import PdfRender from '../../ConsentV2/PdfRender';
import ConsentHeader from '../ConsentHeader';
import { INITIAL_STEP, INITIAL_SURVEY } from '../FormModel/formInitialValues';
import { yupSchemaMedicTemplate } from '../FormModel/validationSchema';
import Menu from '../Menu';
import Sortable from '../Sortable';
import Step from '../Step';

const CreateForm = ({
  surveyCurrentDelete,
  questionTypes,
  templateUpsertRequest,
  currentMedic,
  selectedTeam,
  medicTeam,
  initialValues,
  onError,
  selectedLngId,
  readOnlyForm,
  newMedicTemplateId,
  clearNewMedicTemplateId,
  canSave,
}) => {
  const navigate = useNavigate();
  const { templateId } = useParams();
  const { t } = useTranslation();
  const [isReOrderModalOpen, setIsReOrderModalOpen] = useState(false);
  const [surveyPdfFile, setSurveyPdfFile] = useState([]);
  const formMethods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: useMemo(() => {
      return initialValues || INITIAL_SURVEY;
    }, [initialValues]),
    resolver: yupResolver(yupSchemaMedicTemplate),
    criteriaMode: 'firstError',
    shouldFocusError: true,
  });
  const { handleSubmit, watch, setValue, control, reset } = formMethods;

  useEffect(() => {
    clearNewMedicTemplateId();
  }, []);

  useEffect(() => {
    reset({ ...(initialValues || INITIAL_SURVEY) });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  const shouldConfirm = () => {
    // replace isDirty
    const wat = watch();
    const diff = difference({ ...(initialValues || INITIAL_SURVEY) }, wat);
    return !_.isEmpty(diff);
  };

  function difference(object, base) {
    function changes(object, base) {
      return _.transform(object, function(result, value, key) {
        if (!_.isEqual(value, base[key])) {
          result[key] = (_.isObject(value) && _.isObject(base[key])) ? changes(value, base[key]) : value;
        }
      });
    }
    return changes(object, base);
  }

  useEffect(() => {
    if (newMedicTemplateId) {
      if (templateId !== newMedicTemplateId) {
        navigate(`/teams/${selectedTeam}/createform/${newMedicTemplateId}`);
      }
      navigate(`/teams/${selectedTeam}/forms`);
    }
  }, [newMedicTemplateId]);

  const { fields: stepFields, remove, append } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'steps', // unique name for your Field Array
    keyName: 'key', // When your array field contains objects with the key name id, useFieldArray will overwrite and remove it. If you want to keep the id field in your array of objects, you must use keyName prop to change to other name.
  });
  const pdfsWatch = useWatch({
    control,
    name: 'survey_pdfs',
  });

  const stepsWatch = useWatch({
    control,
    name: 'steps',
  });

  useEffect(() => {
    setSurveyPdfFile(pdfsWatch?.file ? [{ file: pdfsWatch?.file[0] }] : null);
    if (pdfsWatch && pdfsWatch?.file) {
      // clear steps
      setValue('steps', []);
    }
  }, [pdfsWatch]);

  const onBackClick = () => {
    if (surveyCurrentDelete) {
      surveyCurrentDelete();
    }
    navigate(`/teams/${selectedTeam}/forms`);
  };

  const onAddStep = (event) => {
    event.preventDefault();
    const newStep = INITIAL_STEP(stepFields.length + 1);
    append({ ...newStep });
  };

  const handleValidate = (data) => {
    const pdf = _.get(surveyPdfFile, '0.file');
    let stepIndex = 0;
    const template = {
      id: templateId || -1,
      side: data.isSide ? 'on' : 'off',
      radius: data.isRadius ? 'on' : 'off',
      type_id: data.type_id,
      speciality_id: data.speciality_id,
      // comment: data.comment,
      is_published: true,
      translation: {
        id: data.translation?.id,
        language_id: selectedLngId,
        title: data.translation?.title || null,
        description:
          Array.isArray(data.translation?.description) && data.translation?.description.length
            ? JSON.stringify(data.translation?.description.map((t) => t.value))
            : null,
        comment: data.translation?.comment || null,
      },
      // if there is a pdf -> we remove the steps
      steps: (!pdf && Array.isArray(data.steps))
        ? data.steps.map((s) => {
          stepIndex = stepIndex + 1;
          return formatStepOnSubmit(s, stepIndex, selectedLngId);
        })
        : [],
      medic_id: currentMedic.id,
      medical_teams_id: selectedTeam,
      survey_pdfs: data.survey_pdfs,
    };
    templateUpsertRequest(template);
  };

  const onSubmits = (data) => {
    if (readOnlyForm) {
      return;
    }
    handleValidate(data);
  };

  const onErrors = (errors) => {
    // eslint-disable-next-line no-console
    console.error('errors :>> ', errors);
    if (onError) onError(errors);
  };

  const handleSortableClose = () => {
    setIsReOrderModalOpen(false);
  };

  const onReorderComplete = (newData) => {
    const newSteps = sortableToSurvey(newData, stepsWatch);
    newSteps.forEach((step, index) => {
      setValue(`steps.${index}.questions`, step.questions);
      setValue(`steps.${index}`, step);
    });
    setIsReOrderModalOpen(false);
  };

  const openReOrderModalOpen = () => {
    setIsReOrderModalOpen(true);
  };

  let counter = 0;

  return (
    <>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmits, onErrors)} className="createform-screen">
          <Menu
            onBackClick={onBackClick}
            onAddStep={onAddStep}
            onReorder={openReOrderModalOpen}
            hasToConfirmPDF={stepFields && stepFields.length > 0}
            readOnly={readOnlyForm}
            disabledSteps={pdfsWatch && !!pdfsWatch.file}
          />
          <div className="create-form-screen-data-container" >
            <div className={'createform-main-container'}>
              <div className="w-100">
                <FormLanguageSelector shouldConfirm={shouldConfirm} selectedLngId={selectedLngId} />
                <ConsentHeader
                  survey={initialValues}
                  createMode
                  readOnly={readOnlyForm}
                  medicTeam={medicTeam && selectedTeam in medicTeam && _.omit(medicTeam[selectedTeam], ['patients', 'medics']) }
                  isMultipleMedicTeams={medicTeam && Object.keys(medicTeam).length}
                  initialValues={initialValues}
                />
              </div>
              { (!templateId || (canSave && !readOnlyForm)) && (
                <div className="createform-validate-button-container" >
                  <Button type="submit">
                    {t('surveyCreateForm.button.send.label')}
                  </Button>
                </div>
              )}
            </div>
            {
              (pdfsWatch || []).map((p, index) => (
                <div key={p.id || index}>
                  {(p.file && p.file.length)
                    ? <PDFViewer documentData={{ file: _.get(p, 'file.0') }} customNavigation />
                    : (p.loid)
                      ? <PdfRender pdf={p} hideBtn />
                      : <div />
                  }
                </div>
              ))
            }
            <div className={`${!(surveyPdfFile && surveyPdfFile.length) ? 'visible' : 'invisible'}`}>
              { (stepFields || []).map((step, index) => (
                <div key={step.key}>
                  <Step
                    step={step}
                    editMode={!!templateId}
                    questionTypes={questionTypes}
                    createMode
                    readOnly={readOnlyForm}
                    disabled={readOnlyForm}
                    index={index}
                    prefixName={`steps.${index}`}
                    onDeleteStep={() => remove(index)}
                    initialValues={initialValues}
                    control={control}
                  />
                </div>
              ))}
            </div>
          </div>
        </form>
        {!!process.env.REACT_APP_DEBUG_FORM && (
          <div className="container-fluid w-50">
            <div style={{ flexDirection: 'column', marginRight: 0, marginLeft: 'auto' }}>
              <div style={{ margin: 'auto' }}>
                Render : <span>{counter += 1} time</span>
              </div>
              <div style={{ margin: 'auto' }}>
                <ErrorMessage />
              </div>
              <div style={{ margin: 'auto' }} >
                <pre>{JSON.stringify(watch(), getCircularReplacer(), 2)}</pre>
              </div>
            </div>
          </div>
        )}
      </FormProvider>
      { isReOrderModalOpen && (
        <Sortable list={surveyToSortable(stepsWatch)} onExit={handleSortableClose} onFinish={onReorderComplete} />
      )}
    </>
  );
};

export default CreateForm;


const formatQuestionOnSubmit = (q, s, index, localId) => {
  const data = q.translation?.data;
  if (data?.list && Array.isArray(data.list)) {
    data.list = data.list.map(el => ( (!el || el.value) ? el : ({ ...el, value: shortidGenerate() })));
  }
  return {
    id: q.isNew ? -1 : q.id,
    step_id: s.isNew === true ? -1 : s.id,
    type_id: q.type_id,
    number: index,
    translation: {
      id: q.translation?.id,
      language_id: localId,
      question_id: q.translation?.question_id,
      question: q.translation?.question,
      data: q.translation?.data,
      desc: q.translation?.desc,
      rewording: q.translation?.rewording,
      texte_pdf: q.translation?.texte_pdf,
      incompris: q.translation?.incompris,
    },
  };
};

const formatStepOnSubmit = (s, index, localId) => {
  let questionIndex = 0;
  return {
    id: s.isNew === true ? -1 : s.id,
    number: index,
    translation: {
      id: s.translation?.id,
      language_id: localId,
      // step_id: s.translation?.step_id,
      title: s.translation?.title,
    },
    questions: s.questions.map((q) => {
      questionIndex = questionIndex + 1;
      return formatQuestionOnSubmit(q, s, questionIndex, localId);
    }),
  };
};