import { createAction } from '../../utils';

// Types
export const types = {
  RESET_REDUCER: 'medic_RESET_REDUCER',

  CLINIC_REQUEST: 'CLINIC_REQUEST',
  CLINIC_SUCCESS: 'CLINIC_SUCCESS',
  CLINIC_FAILURE: 'CLINIC_FAILURE',
  CLINIC_DELETE: 'CLINIC_DELETE',
};

// Actions
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  /** REQUEST */
  clinicRequest: () => createAction(types.CLINIC_REQUEST),
  clinicSuccess: (clinics) => createAction(types.CLINIC_SUCCESS, { clinics }),
  clinincFailure: () => createAction(types.CLINIC_FAILURE),
  clinicDelete: () => createAction(types.CLINIC_DELETE),

  resetReducer: () => createAction(types.RESET_REDUCER),
};
