import _ from 'lodash';
import React, { useMemo } from 'react';
import ReactFlagSelect from 'react-flags-select';

import { cTranslate } from './cTranslate';

export default function SelectCountryFlag({ languageList, selectedLanguageId, availableLanguageIds, onSelectLanguage }) {
  const languagesCodesOptions = useMemo(() => {
    return (Object.values(languageList || {})).map(({ language_code }) => language_code);
  }, [availableLanguageIds, languageList]);

  const selectedLanguage = useMemo(() => {
    return (languageList && selectedLanguageId) && _.get(languageList, selectedLanguageId);
  }, [languagesCodesOptions, selectedLanguageId]);

  const handleSelectLanguage = (code) => {
    const languageCode = Object.keys(cTranslate).find(key => cTranslate[key] === code);
    const language = Object.values(languageList).find(el => el.language_code === languageCode);
    onSelectLanguage(language?.id);
  };

  return (
    <ReactFlagSelect
      selected={selectedLanguage?.country_code_flag}
      onSelect={handleSelectLanguage}
      showSelectedLabel={false}
      showSecondarySelectedLabel={false}
      showOptionLabel={false}
      showSecondaryOptionLabel={false}
      selectedSize={25}
      optionsSize={25}
      countries={(languagesCodesOptions || []).map(el => el?.country_code_flag || cTranslate[el])}
      fullWidth={false}
      disabled={(!availableLanguageIds || availableLanguageIds.length <= 1)}
    />
  );
}
