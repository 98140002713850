

import _ from 'lodash';
import { connect } from 'react-redux';

import { AuthActions } from '../../../redux/auth';
import { FormsActions } from '../../../redux/forms';
import AttachUser from './AttachUser';

const mapStateToProps = ({ forms }) => {
  return { defaultValues: _.get(forms.data, 'attachUserCode') };
};

const mapDispatchToProps = (dispatch) => ({
  //
  clearData: () => dispatch(FormsActions.clearData('attachUserCode')),
  attachUserCodeRequest: (formData) => dispatch(AuthActions.attachUserCodeRequest(formData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AttachUser);


