import axios from 'axios';
import CryptoJS from 'crypto-js';
import _ from 'lodash';

import { fetch } from './service';

export const normalizeString = (str) =>
  (typeof str !== 'string' ? (str || '').toString() : str)
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');

export function delay(milliseconds) {
  return new Promise(resolve => {
    setTimeout(resolve, milliseconds);
  });
}

export const getCircularReplacer = () => {
  const seen = new WeakSet();
  return (_key, value) => {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    return value;
  };
};

export const verifyURLAvailability = (url) => {
  return new Promise((resolve, reject) => {
    let nbRetry = 0;
    const loop = () => {
      setTimeout(() => {
        fetch({ method: 'get', url: url })
          .then(([error, response]) => {
            if (error && nbRetry < 5) {
              nbRetry += 1;
              loop();
            } else if (nbRetry >= 5) {
              reject(`Error: ${error}`);
            } else if (response && response.status === 200) {
              resolve('Success: URL is available');
            }
          })
          .catch((err) => reject(`Error: ${err}`));
      }, 5000);
    };
    loop();
  });
};

export function isJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export function testJSON(text) {
  if (!text || typeof text !== 'string') {
    return false;
  }
  try {
    JSON.parse(text);
    return true;
  } catch (error) {
    return false;
  }
}

export const capitalizeFirstLetter = (str) => {
  if (str && (typeof str === 'string' || str instanceof String)) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  } else {
    return '';
  }
};

export const formatFilename = (filename) => {
  if (!filename) {
    return '';
  }
  let formatedFilename = filename.replace(/[/\\<>|:&?*."[\];,]/g, '-');
  filename = formatedFilename.length >= 80 ? `${formatedFilename.slice(0, 77)}...` : formatedFilename;
  return filename;
};

export const isNullOrWhitespace = (input) => {
  if (typeof input === 'undefined' || input == null) return true;
  return input.replace(/\s/g, '').length < 1;
};

export const interpolate = (string, data, regexInterpolate = /{{([\s\S]{1,50})}}/g) => {
  _.templateSettings = { interpolate: regexInterpolate };
  const compiled = _.template(string);
  return compiled({ ...data });
};

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
    /* you can also use 'auto' behaviour
       in place of 'smooth' */
  });
};

export const isValidPhoneNumber = async (phoneNumber, bearerToken) => {
  if (phoneNumber) {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_API_URL}/V2/phone-number/is-valid-phone-number`,
      { phoneNumber: phoneNumber, client_id: process.env.REACT_APP_CLIENT_ID, client_secret: process.env.REACT_APP_CLIENT_SECRET },
      { headers: bearerToken ? { Authorization: `Bearer ${bearerToken }` } : { } },
    );
    return response.data;
  }
  return false;
};

export const escapeMetaCharacter = (string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
};

export const shortidGenerate = () => {
  var hexstring = CryptoJS.enc.Hex.stringify(CryptoJS.lib.WordArray.random(16));
  return hexstring.substring(0, 8);
};
