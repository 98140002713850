
import _ from 'lodash';
import { connect } from 'react-redux';

import { cTranslate } from './cTranslate';
import SelectCountryFlag from './SelectCountryFlag';

const mapStateToProps = ({ language }, { availableLanguageIds }) => {
  return ({ languageList:  _.chain(language.list || {}).keyBy('id').pick(availableLanguageIds).value() });
};


export default connect(mapStateToProps, null)(SelectCountryFlag);

export { cTranslate };