import '../form/style.css';

import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SegmentedControl } from 'segmented-control';

import { PERMISSIONS } from '../../../services/casl/roles';
import { getUserProperty, tutorMapper } from '../../../utils';
import { validEmailRegex } from '../../../utils/regex';
import { Button } from '../../components/styledComponent';
import Modal from '../modal';
import ContactFormContainer from '../patientFormV2/Steps/ContactFormContainer';
import InfosFormContainer from '../patientFormV2/Steps/InfosFormContainer';

const EditTutorsContact = ( {
  tutor,
  tutorsCount,
  onUpdate,
  indexChange,
  disabled,
  isOpenModal,
  onModal,
  isClinicalMode,
}) => {
  const { t } = useTranslation();
  const [initialData, setInitialData] = useState();
  const [dataStep1, setDataStep1] = useState();
  const [data, setData] = useState();
  const [step, setStep] = useState(1);
  const fullDisplayName = getUserProperty(tutor, 'fullDisplayName');

  useEffect(() => {
    if (tutor && tutor.id) {
      const formatedTutor = tutorMapper({ ...tutor });
      setInitialData({ ...formatedTutor });
      setData({ ...formatedTutor });
    }

  }, [isOpenModal, tutor && tutor.id]);

  const handleIndexChange = (newValue) => {
    if (indexChange) indexChange(newValue);
  };

  const handleSubmit = (data) => {
    const submitData = { ...dataStep1, ...data };
    if (onUpdate) onUpdate(submitData);
    setStep(1);
  };

  const handleOnExit = (e) => {
    e.preventDefault();
    setDataStep1();
    setData({ ...initialData });
    setStep(1);
    handleCloseModal();
  };


  const handleOpenModal = () => onModal(true);
  const handleCloseModal = () => onModal(false);

  const handleNextStep = data => {
    setDataStep1(data);
  };

  useEffect(() => {
    if (dataStep1) {
      setStep(2);
    }
  }, [dataStep1]);

  return (
    <>
      <div className={'infopatient-information'}>
        <div className={'infopatient-choose-tutors'}>
          <p className={'infopatient-tutors-text-name'}>{t('tutors_text_contacts')}</p>
          { tutorsCount === 2
            ? (
              <div className={'infopatient-segmented-control'}>
                <SegmentedControl
                  name="oneDisabled"
                  setValue={(newValue) => handleIndexChange(newValue)}
                  options={[
                    {
                      label: '1',
                      value: 0,
                      default: true,
                    },
                    {
                      label: '2',
                      value: 1,
                    },
                  ]}
                  style={{
                    borderRadius: '8px',
                    color: '#00aecb',
                  }}
                />
              </div>
            )
            : null}
        </div>
        <div className={'infopatient-row-container'}>
          <p className={'infopatient-information-text-min'}>{t('tutors_text_name')}</p>
          <p className={'infopatient-text-max'}>{fullDisplayName}</p>
        </div>
        <div className={'infopatient-row-container'}>
          <p className={'infopatient-information-text-min'}>{t('patient_text_phone')}</p>
          <p className={'infopatient-text-max'}>{tutor && tutor.local_phone_number}</p>
        </div>
        <div className={'infopatient-row-container'}>
          <p className={'infopatient-information-text-min'}>{t('patient_text_email')}</p>
          <p className={'infopatient-text-max'}>
            {tutor && tutor.email && validEmailRegex.test(tutor.email) ? tutor.email : null}
          </p>
        </div>
        <div className={'infopatient-row-container'}>
          <p className={'infopatient-information-text-min'}>{t('patient_text_username')}</p>
          <p className={'infopatient-text-max'}>
            {tutor?.user?.username || ''}
          </p>
        </div>

        { !isClinicalMode && (
          <div className={'infopatient-row-container'}>
            <p className={'infopatient-information-text-min'}>{t('patient_text_address')}</p>
            <p className={'infopatient-text-max'}>
              {(tutor?.postcode ? tutor.postcode + ' ' : '') + (tutor?.city || '')}
            </p>
          </div>
        )}
        <div className={'infopatient-bottom-container'}>
          {!disabled && (
            <>
              <Button onClick={handleOpenModal} type="button">
                {t('btn.edit')}
              </Button>
              <Modal
                title={t('update_info_title', { displayName : '' })}
                onClose={handleOnExit}
                scrollable
                isOpen={ isOpenModal }
              >
                {step === 1 && (
                  <div className={step === 1 ? '' : 'd-none'}>
                    <InfosFormContainer
                      defaultValues={_.pick(data, ['gender', 'security_number', 'lastname', 'use_name', 'firstname', 'birthdate', 'birthplace', 'birthplace_postcode', 'birthplace_id'])}
                      onSubmit={handleNextStep}
                      onCancel={handleOnExit}
                      id={'editT-InfosFormContainer'}
                      canPermission={PERMISSIONS.UPDATE}
                    />
                  </div>
                )}
                {step === 2 && (
                  <div className={step === 2 ? '' : 'd-none'}>
                    <ContactFormContainer
                      id={'editT-ContactFormContainer'}
                      defaultValues={_.pick(data, ['city_id', 'city', 'city_postcode', 'phone_number', 'email', 'email_confirm', 'language_id'])}
                      onCancel={handleOnExit}
                      onSubmit={handleSubmit}
                      userId={_.get(tutor, 'user.id')}
                    />
                  </div>
                )}
              </Modal>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default EditTutorsContact;
