import _ from 'lodash';

import { regexSecu, validAlphaDigitRegex, validAlphaRegex, validDigitRegex, validEmailRegex } from '../../../utils/regex';
import { normalizeString } from '../../../utils/utils';

export const required = ({ value }) => {
  return value != null && value.length !== 0;
};

export const length = ({ value }, size) => {
  if (value) return value.length === size;
  else return true;
};

export const min = ({ value }, minSize) => {
  if (value) return value.length >= minSize;
  else return false;
};

export const max = ({ value }, maxSize) => {
  if (value) return value.length >= maxSize;
  else return false;
};

export const email = ({ value }) => {
  if (!value) return true;
  return validEmailRegex.test(value);
};

export const same = ({ value, form }, key) => {
  if (!value) return true;
  return value === form[key];
};

export const isValidSecuNumFromRegex = ({ value }) => {
  if (value) {
    return regexSecu.test(value);
  }
};

export const isValidSecuKey = ({ value }) => new Promise((resolve, reject) => {
  let numss = value && value.replace(/\s/g, '');
  if (!numss || !regexSecu.test(numss)) {
    return reject(new Error('form.fields.security_number.rules.not_valid'));
  } else {
    const matchRegex= value.match(regexSecu); // [secu, sex, year, month, department, insee, range, key]
    const sex = matchRegex[1];
    const department = matchRegex[4];
    const key = matchRegex[7];
    const filter_sexe = /^[1-478]$/;
    if (!filter_sexe.test(sex)) {
      return reject(new Error('form.fields.security_number.rules.sex'));
    }
    let deb13;
    if (['2A', '2B'].includes(department)) {
      numss = numss.substring(0, 6) + '0' + numss.substring(7, 15);
      deb13 = parseInt(numss.substring(0, 13));
      // Corse
      if (department === '2A') deb13 -= 1000000;
      if (department === '2B') deb13 -= 2000000;
    } else {
      deb13 = parseInt(numss.substring(0, 13));
    }

    const div97 = 97 - (deb13 % 97);
    let sdiv97 = String(div97);
    if (key === ('0' + sdiv97).slice(-2)) {
      resolve(value);
    } else {
      reject(new Error('form.fields.security_number.rules.key_not_valid'));
    }
  }
});

export const isEmailAvailable = ({ value }, availability, initialEmail) => {
  if (!value) return true;
  if (value === initialEmail) return true;
  return availability;
};

export const iscity = ({ value }) => {
  if (!value) {
    return true;
  } else {
    return (typeof value === 'string' || value instanceof String)
      ? validAlphaDigitRegex.test(normalizeString(value))
      : _.isObject(value) && validAlphaDigitRegex.test(normalizeString(value.label));
  }
};

export const alpha = ({ value }) => {
  return !value
    ? true
    : (typeof value === 'string' || value instanceof String) && validAlphaRegex.test(value.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
};

export const digit = ({ value }) => {
  return !value ? true : validDigitRegex.test(value);
};
/* Create form rules */

export const modified = ({ value }, originalValue) => {
  return value != null && value !== originalValue && value !== '';
};

export const isTypeSelected = ({ value }) => {
  return value != null && value.filter((val) => val.isSelected).length > 0;
};

export const answered = ({ value }) => {
  return value !== null;
};

export const none = ({ value }) => {
  return value !== undefined && value !== null && value !== 'none';
};

export const isNotEmptyArray = ({ value }) => {
  return !!value && Array.isArray(value) && value.length !== 0;
};

export const isFullArray = ({ value }, fullSize) => {
  return !!value && Array.isArray(value) && fullSize && value.length === fullSize;
};

export const text_answered = ({ value }) => {
  return (value && typeof value !== 'object' && value !== '');
};

export const multiple_answered = ({ value }) => {
  return (value && Array.isArray(value) && value.filter((v) => v.isSelected).length);
};
