
import { userMutations, userQueries } from './GraphQLRequest';
import { authenticatedMutation, authenticatedQuery } from './middleware';


/*
 * SERVICES
 */
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  doesEmailExist: (email, userId = null) =>
    authenticatedQuery(userQueries.doesEmailExist, {}, getValuesDoesEmailExists(email, userId)),
  patchUser: (userId, userData) => authenticatedMutation(userMutations.patchUser, {}, { userId, userData }),

};

export const getValuesDoesEmailExists = (email, userId) => {
  let wherePatient = { email: { _ilike: email } };
  let whereMedic = { email: { _ilike: email } };
  if (userId && userId !== null) {
    wherePatient['user_id'] = { _neq: userId };
    whereMedic['user_id'] = { _neq: userId };
  }
  return {
    wherePatient,
    whereMedic,
  };
};