import _ from 'lodash';
import { createReducer } from 'reduxsauce';

import { types } from './actions';

const initialState = {
  list: {},
  loading: false,
};

const startLoader = (state = { ...initialState }) => ({
  ...state,
  loading: true,
});

const stopLoader = (state = { ...initialState }) => ({
  ...state,
  loading: false,
});

const templatesGetSuccess = (state = { ...initialState }, action = {} ) => {
  const { templates } = action;
  const data = state.list;

  return {
    ...state,
    list: { ...data, ..._.keyBy(templates, 'id') },
    loading: false,
  };
};

const resetReducer = () => {
  return { ...initialState };
};

export default createReducer(initialState, {
  [types.RESET_REDUCER]: resetReducer,

  [types.TEMPLATES_GET_REQUEST]: startLoader,
  [types.TEMPLATES_GET_SUCCESS]: templatesGetSuccess,
  [types.TEMPLATES_GET_FAILURE]: stopLoader,
});
