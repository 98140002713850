// utils/hooks/useDeviceDetect.js
// https://www.npmjs.com/package/react-device-detect

import { useEffect, useState } from 'react';

const useDeviceDetect = () => {
  const [isMobile, setMobile] = useState(false);
  const [isOpera, setIsOpera] = useState(false);
  const [isFirefox, setIsFirefox] = useState(false);
  const [isSafari, setIsSafari] = useState(false);
  const [isIE, setIsIE] = useState(false);
  const [isEdge, setIsEdge] = useState(false);
  const [isChrome, setIsChrome] = useState(false);
  const [isBlink, setIsBlink] = useState(false);

  useEffect(() => {
    // can also be written as 'navigator.userAgent'
    const userAgent = typeof navigator === 'undefined' ? '' : navigator.userAgent;

    // Mobile
    const mobile = Boolean(
      userAgent.match(
        /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i,
      ),
    );
    setMobile(mobile);
    // Opera 8.0+
    // eslint-disable-next-line no-undef
    setIsOpera((!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0);
    // Firefox 1.0+
    setIsFirefox(navigator.userAgent.indexOf('Firefox') !== -1);
    // Safari 3.0+ "[object HTMLElementConstructor]"
    // eslint-disable-next-line no-undef
    setIsSafari(/constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === '[object SafariRemoteNotification]'; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification)));
    // Internet Explorer 6-11
    setIsIE(/*@cc_on!@*/false || !!document.documentMode);
    // Edge 20+
    setIsEdge(!isIE && !!window.StyleMedia);
    // Chrome 1 - 71
    setIsChrome(!!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime));
    // Blink engine detection
    setIsBlink((isChrome || isOpera) && !!window.CSS);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isMobile, isOpera, isFirefox, isSafari, isIE, isEdge, isChrome, isBlink, browserName: checkBrowser() };
};

export default useDeviceDetect;

const checkBrowser = () => {
  var sBrowser, sUsrAg = navigator.userAgent;
  if (sUsrAg.indexOf('Firefox') > -1) {
    sBrowser = 'Mozilla Firefox';
  } else if (sUsrAg.indexOf('SamsungBrowser') > -1) {
    sBrowser = 'Samsung Internet';
  } else if (sUsrAg.indexOf('Opera') > -1 || sUsrAg.indexOf('OPR') > -1) {
    sBrowser = 'Opera';
  } else if (sUsrAg.indexOf('Trident') > -1) {
    sBrowser = 'Microsoft Internet Explorer';
  } else if (sUsrAg.indexOf('Edge') > -1) {
    sBrowser = 'Microsoft Edge';
  } else if (sUsrAg.indexOf('Chrome') > -1) {
    sBrowser = 'Google Chrome';
  } else if (sUsrAg.indexOf('Safari') > -1) {
    sBrowser = 'Apple Safari';
  } else {
    sBrowser = 'unknown';
  }
  return sBrowser;
};