import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

function CustomPages({ page, pages }) {
  const { t } = useTranslation();
  return (
    <p className="ec-pdf-viewer-navigation-text">
      <span className='d-none d-sm-block'>{t('pdfViewer.Page_x_on_xx', { page, pages })}</span>
      <span className='d-block d-sm-none'>{`${page} / ${pages}`}</span>
    </p>
  );
};

CustomPages.propTypes = { page: PropTypes.number, pages: PropTypes.number };

export default CustomPages;
