import './style.css';

import axios from 'axios';
import parse from 'html-react-parser';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CONTENT_TYPE_MAPPER } from '../../../utils/enum';
import { ModalButtons } from '../buttons';
import Checkbox from '../form/Checkbox';

const QuestionBasicsText = ({
  onValidate,
  onRefuse,
  text,
  firstText,
  secondText,
  pdf_url,
  pdfCheckboxLabel,
  token,
}) => {
  const { t } = useTranslation();
  const [check, setCheck] = useState(false);
  const [error, setError] = useState(false);
  const [pdfUrl, setPdfUrl] = useState();
  const url = useMemo(() => {
    if (!pdf_url || !pdf_url.length) {
      return null;
    }
    if (/^https?:\/\//gi.test(pdf_url)) {
      return pdf_url;
    } else {
      return pdf_url && `${process.env.REACT_APP_BASE_API_URL}/${pdf_url}`;
    }
  });

  useEffect(() => {
    const fetchData = async (url) => {
      const responseType = 'blob'; // 'arraybuffer' || 'blob';
      const res = await axios.get(url, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/pdf',
          Authorization: `Bearer ${token}`,
        },
        responseType,
      });
      const newFileType = res.headers['content-type'];
      const objectUrl = window.URL.createObjectURL(res.data);
      setPdfUrl(newFileType === CONTENT_TYPE_MAPPER.PDF ? objectUrl : null);
    };
    if (url && token) {
      fetchData(url);
    }
  }, [url, token]);

  const handleOnValid = () => {
    if (pdfCheckboxLabel && check === false) {
      setError(true);
    } else {
      onValidate();
    }
  };

  const handleOnRefuse = () => {
    if (onRefuse) {
      onRefuse();
    } else {
      setError(true);
    }
  };
  const regexMarkedownLink = /([^[]{0,100000})\[([^\]]{0,100})]\(([^)]{0,100})\)/;
  const test = pdfCheckboxLabel && regexMarkedownLink.test(pdfCheckboxLabel) && pdfCheckboxLabel.match(regexMarkedownLink);

  return (
    <>
      {parse("<p class='ec-question-basics-common-text'>" + text + '</p>')}
      {pdfCheckboxLabel
        ? <>
          <Checkbox
            label={
              test
                ? (
                  <span>
                    {test[1]}{' '}
                    <a
                      href={pdfUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {test[2]}
                    </a>
                  </span>
                )
                : <span>{ pdfCheckboxLabel }</span>
            }
            className="ec-checkbox-text-consent-header"
            onChange={(e) => {
              setCheck(e.target.value);
              setError(false);
            }}
            value={check}
          />
          {error && (
            <p className={'ec-question-basics-common-red-text'}>{t('form.fields.checkbox.rules.required')}</p>
          )}
        </>
        : null
      }
      <ModalButtons
        hideFirst={!firstText}
        firstText={firstText}
        secondText={secondText}
        onFirstClick={handleOnRefuse}
        onSecondClick={handleOnValid}
      />
    </>
  );
};

export default QuestionBasicsText;
