import { format } from 'date-fns';
import _ from 'lodash';

import { getGender } from './redux-helper';

export const prepareContactForUpdate = (contact) => {
  const address = [contact.city, contact.city_postcode].filter(el => !!el && el !== '').join(' ,');
  return {
    city_id: _.get(contact, 'city_id.value') || _.get(contact, 'city_id') || null,
    city: contact.city,
    postcode: contact.city_postcode || null,
    phone_number: contact.phone_number,
    email: contact.email,
    address: address && address.length ? address : undefined,
    language_id: contact.language_id,
  };
};

export const prepareInfoForUpdate = (info) => {
  return {
    firstname: info.firstname,
    lastname: info.lastname,
    use_name: info.use_name,
    security_number: info.security_number,
    birthdate: format(info.birthdate, 'yyyy-MM-dd'),
    birthplace_id: _.get(info, 'birthplace_id.value') || _.get(info, 'birthplace_id') || undefined,
    birthplace: info.birthplace,
    birthplace_postcode: info.birthplace_postcode || undefined,
    gender: getGender(info.gender),
  };
};

export const prepareTutorForUpdate = (tutor) => {
  const { birthplace_postcode, birthplace_id, postcode, city_id } = tutor;
  let birthplace_name;
  if (birthplace_id) {
    if (typeof birthplace_id === 'string' || birthplace_id instanceof String){
      birthplace_name = birthplace_id;
    } else {
      birthplace_name = birthplace_id.label && birthplace_id.label.split(',');
      birthplace_name = Array.isArray(birthplace_name) && birthplace_name.length
        ? birthplace_name.pop().trim()
        : '';
    }
  } else {
    birthplace_name = tutor.birthplace;
  }

  let city;
  if (typeof city_id === 'string' || city_id instanceof String){
    city = city_id;
  } else {
    city = city_id && city_id.label && city_id.label.split(',');
    city = Array.isArray(city) && city.length ? city.pop().trim() : undefined;
  }
  const address = [city, tutor.postcode].filter(el => !!el && el !== '').join(' ,');

  return {
    firstname: tutor.firstname,
    lastname: tutor.lastname,
    use_name: tutor.use_name,
    security_number: tutor.security_number,
    birthdate:tutor.birthdate ? format(tutor.birthdate, 'yyyy-MM-dd') : null,
    birthplace_id: _.get(tutor, 'birthplace_id.value') || _.get(tutor, 'birthplace_id'),
    birthplace_postcode: (birthplace_postcode && birthplace_postcode !== '') ? birthplace_postcode : null,
    birthplace: birthplace_name,
    city_id: _.get(tutor, 'city_id.value') || _.get(tutor, 'city_id'),
    postcode: (postcode && postcode !== '') ? postcode : null,
    city: city,
    phone_number: tutor.phone_number,
    email: tutor.email,
    gender: getGender(tutor.gender),
    address: address && address.length ? address : undefined,
    language_id: tutor.language_id,
  };
};
