import PropTypes from 'prop-types';
import React from 'react';

function IFrameRenderer({ src, iframeRef, title='iframe-preview', width = '100%', height = '100%' }) {
  return (
    <iframe
      ref={iframeRef}
      src={src}
      title={title}
      width={width}
      height={height}
    >
    </iframe>
  );
}

IFrameRenderer.propTypes = {
  src: PropTypes.string,
  iframeRef: PropTypes.object,
  title : PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default IFrameRenderer;
