import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { AbilityContext } from '../../../../services/casl/Can';
import { MODEL_NAMES, PERMISSIONS } from '../../../../services/casl/roles';
import Card from '../../../components/card';
import Filter from '../../../components/filter';

export const FILTER_MAPPER = {
  SURGERY: 'surgery',
  CONSENT: 'consent',
  PATIENT: 'patient',
};

const FilterCard = ({ onFilter, selectedFilter = FILTER_MAPPER.PATIENT, medicMetrics }) => {
  const { t } = useTranslation();
  const ability = useContext(AbilityContext);

  const handleOnClick = (filter) => {
    if (onFilter) {
      onFilter(filter);
    }
  };

  return (
    <Card>
      <p className={'sidemenu-filter-header-text'}>{t('medic.filters.title')}</p>
      {Object.values(medicMetrics)
        .filter(el => ability.can( PERMISSIONS.READ, { ...el, __typename: MODEL_NAMES.HOME_FILTER }))
        .map(function({ number, id, value }) {
          return (
            <Filter
              key={`filter_card_${value}_${number}`}
              primaryText={number}
              secondaryText={t(`medic.filters.${value}`)}
              value={value}
              type={'lines'}
              onClick={handleOnClick}
              activated={selectedFilter === id}
            />
          );
        })}
    </Card>
  );
};

export default FilterCard;
