// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ec-dropdown {
  position: relative;
  display: inline-block;
}

.ec-dropdown ul {
  list-style-type: none;
  margin: 0;
  border-radius: 8px;
  right: -10px;
  background-color: white;
  font-weight: bold;
  position: absolute;
  padding-inline-start: 0px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 10;
}

.ec-dropdown li a {
  color: #000;
  text-decoration: none;
}

.ec-dropdown li:last-child {
  border-bottom: none;
}
.ec-dropdown li:hover {
  color: white;
}

.ec-dropdown > .ec-dropdown-title {
  font-size: 10px;
  line-height: 9px;
  height: 17px;
  position: absolute;
  margin-left: 18px;
  padding-left: 6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

@media screen and (max-width: 600px) {
  .ec-dropdown > .ec-dropdown-title {
    font-size: 8px;
    margin-top: 15px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/ui/components/dropdown/style.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;EACrB,SAAS;EACT,kBAAkB;EAClB,YAAY;EACZ,uBAAuB;EACvB,iBAAiB;EACjB,kBAAkB;EAClB,yBAAyB;EACzB,+CAA+C;EAC/C,WAAW;AACb;;AAEA;EACE,WAAW;EACX,qBAAqB;AACvB;;AAEA;EACE,mBAAmB;AACrB;AACA;EACE,YAAY;AACd;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,kBAAkB;EAClB,iBAAiB;EACjB,iBAAiB;EACjB,mBAAmB;EACnB,gBAAgB;EAChB,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE;IACE,cAAc;IACd,gBAAgB;EAClB;AACF","sourcesContent":[".ec-dropdown {\n  position: relative;\n  display: inline-block;\n}\n\n.ec-dropdown ul {\n  list-style-type: none;\n  margin: 0;\n  border-radius: 8px;\n  right: -10px;\n  background-color: white;\n  font-weight: bold;\n  position: absolute;\n  padding-inline-start: 0px;\n  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);\n  z-index: 10;\n}\n\n.ec-dropdown li a {\n  color: #000;\n  text-decoration: none;\n}\n\n.ec-dropdown li:last-child {\n  border-bottom: none;\n}\n.ec-dropdown li:hover {\n  color: white;\n}\n\n.ec-dropdown > .ec-dropdown-title {\n  font-size: 10px;\n  line-height: 9px;\n  height: 17px;\n  position: absolute;\n  margin-left: 18px;\n  padding-left: 6px;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  text-align: left;\n}\n\n@media screen and (max-width: 600px) {\n  .ec-dropdown > .ec-dropdown-title {\n    font-size: 8px;\n    margin-top: 15px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
