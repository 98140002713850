import React, { useRef } from 'react';
import { useFormContext } from 'react-hook-form';

const InputArea = ({
  name,
  className,
  containerClassname,
  readOnly = false,
  placeholder,
  maxLength = 200,
  dynamicLines,
  ...props
}) => {
  const textAreaRef = useRef(null);
  const methods = useFormContext();
  const { register } = methods;

  const { ref, ...rest } = register(name);

  const autoGrow = (e) => {
    if ((e.key === 'Enter' || e.keyCode === 13) && dynamicLines && textAreaRef.current) {
      const length = (`${textAreaRef.current.value}`.match(/\n/g) || []).length + 1;
      const newRows = length < 2 ? 2 : Math.min(length, 6);
      if (newRows !== textAreaRef.current.rows) {
        textAreaRef.current.rows = newRows;
      }
    }
  };

  return (
    <div className={containerClassname || 'inputAreaFormItem-container'}>
      <textarea
        id={name}
        className={className}
        readOnly={readOnly}
        maxLength={maxLength}
        rows={2}
        placeholder={placeholder}
        onKeyUp={autoGrow}
        {...rest}
        name={name}
        ref={(e) => {
          ref(e);
          textAreaRef.current = e; // you can still assign to ref
        }}
        {...props}
      >
      </textarea>
    </div>
  );
};

export default InputArea;
