import './style.css';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Card from '../card';
import Modal from '../modal';
import { Button } from '../styledComponent';

const ConfirmPopup = ({ title, isOpen, onClose, onConfirm, action }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if ( (typeof isOpen === 'boolean') && isOpen !== open) {
      setOpen(isOpen);
    }
  }, [isOpen]);

  const handleConfirm = (e) => {
    e.stopPropagation();
    if (onConfirm) {
      onConfirm(e, action);
      handleCloseModal(e);
    }
  };

  const handleCloseModal = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setOpen(false);
    onClose && onClose(false);
  };

  const handleClick = (e) => {
    e.stopPropagation();
  };

  const handleKeyPress = (e) => {
    e.stopPropagation();
  };

  return (
    <Modal
      isOpen={isOpen}
      hideCloseBtn
    >
      <div
        className={`${open ? '' : 'd-none'}`}
        onClick={handleClick}
        onKeyPress={handleKeyPress}
        role="button"
        tabIndex="0"
      >
        <Card>
          <p className={'ec-delete-popup-title'}>{title}</p>
          <div className="d-flex justify-content-around">
            <Button
              onClick={handleCloseModal}
              variant={'outline-primary'}
            >
              {t('No')}
            </Button>
            <Button
              onClick={handleConfirm}
              variant={'outline-danger'}
            >
              {t('Yes')}
            </Button>
          </div>
        </Card>
      </div>
    </Modal>
  );
};

export default ConfirmPopup;

ConfirmPopup.propTypes = {
  t: PropTypes.func,
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  action: PropTypes.string,
  positionClassName: PropTypes.string,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
  children: PropTypes.element,
};