import { connect } from 'react-redux';

import { FormsActions } from '../../../../redux/forms';
import { SurveyActions } from '../../../../redux/survey';
import ConsentRecap from './ConsentRecap';

const mapStateToProps = ({ survey, forms, auth }) => {
  return {
    survey: survey.currentSurvey,
    consentAnswers: forms?.data?.consent,
    questionTypes: survey.questionTypes || {},
  };
};

const mapDispatchToProps = (dispatch) => ({

  omitData: (key) => dispatch(FormsActions.omitData(key)),
  resetSurveySigned: () => dispatch(SurveyActions.resetSurveySigned()),
  genSurveySignUrlReset: () => dispatch(SurveyActions.genSurveySignUrlSuccess(null)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConsentRecap);