import './style.css';

import _ from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Switch from 'react-switch';

import { IconStore, IconUserMd } from '../../../../assets/img/icons';
import { AbilityContext } from '../../../../services/casl/Can';
import { MODEL_NAMES, PERMISSIONS } from '../../../../services/casl/roles';
import NavigateBack from '../../../components/navigateBack';
import AddButton from '../list/AddButton';
import FilterSelect from './FilterSelect';

const Filters = ({
  selectedFilters,
  types = [],
  specialities,
  onSelectFilter,
  displayInfo,
  selectedTeam,
  onBackClick,
  list,
  onSwitchChange,
  checked,
  canAccesStore,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const ability = useContext(AbilityContext);
  const canCreateMedicTemplate = useMemo(() => ability.can(PERMISSIONS.CREATE, MODEL_NAMES.MEDIC_TEMPLATES), [ability]);
  const [filtersQty, setFiltersQty] = useState();
  const { surveyType, speciality } = selectedFilters;

  useEffect(() => {
    let quantities = {
      speciality: _.chain(list).groupBy('speciality_id').mapValues(o => o.length).value(),
      surveyType: _.chain(list).groupBy('type_id').mapValues(o => o.length).value(),
    };
    quantities = _.mapValues(quantities, o => ({ ...o, all: Object.values(o).reduce((a, b) => a + b, 0) }));
    setFiltersQty(quantities);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list, specialities, types]);

  const handleSelectFilter = (filter) => {
    if (onSelectFilter) {
      onSelectFilter(filter);
    }
  };

  const onCreateForm = () => {
    if (canCreateMedicTemplate) {
      navigate(`/teams/${selectedTeam}/createform`);
    }
  };

  const handleAlertNoMedicTeam = () => {
    displayInfo(t('error_create_form_medic_team'));
  };

  const handleOnBackClick = () => {
    if (onBackClick) {
      onBackClick();
    }
  };

  const handleOnSwitchChange = (value) => {
    if (onSwitchChange) {
      onSwitchChange(value);
    }
  };
  return (
    <div className={'form-screen-sidemenu'}>
      <NavigateBack text={t('dashboard')} onClick={handleOnBackClick} />
      <>
        {canCreateMedicTemplate && (
          <AddButton
            onCreateForm={selectedTeam ? onCreateForm : handleAlertNoMedicTeam}
            disabled={!canCreateMedicTemplate}
          />
        )}
        <div style={{ marginTop: '42px' }} />
      </>
      {canAccesStore && (
        <div className="form-screen-switch-template-container">
          <span className="form-screen-switch-template-label">
            {t(checked ? 'display_template_store' : 'hide_template_store')}
          </span>
          <Switch
            className="form-screen-switch-template-switch"
            checked={checked}
            onChange={handleOnSwitchChange}
            offColor="#00AECB"
            offHandleColor="#00D7E7"
            onColor="#00D7E7"
            onHandleColor="#00AECB"
            uncheckedIcon={<IconUserMd className="mysvg" />}
            checkedIcon={<IconStore className="mysvg" />}
          />
        </div>
      )}
      <FilterSelect
        title={t('formsScreen.filters.type.title')}
        onSelect={handleSelectFilter}
        list={(types || []).map(el => ({ value: el.id, label:el?.translation?.label }))}
        type='surveyType'
        selected={surveyType}
        qty={filtersQty?.surveyType}
      />
      <FilterSelect
        title={t('formsScreen.filters.speciality.title')}
        onSelect={handleSelectFilter}
        list={(specialities || []).map(el => ({ value: el.id, label: el?.translation?.value }))}
        type='speciality'
        selected={speciality}
        qty={filtersQty?.speciality}
      />
    </div>
  );
};

export default Filters;
