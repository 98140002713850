
import _ from 'lodash';
import { connect } from 'react-redux';

import { AuthActions } from '../../../redux/auth';
import { ClinicActions } from '../../../redux/clinic';
import { FormsActions } from '../../../redux/forms';
import { MedicActions } from '../../../redux/medic';
import { PatientActions } from '../../../redux/patient';
import { SnackActions } from '../../../redux/snackBar';
import { SurveyActions } from '../../../redux/survey';
import InfoPatient from './InfoPatient';

const mapStateToProps = ({ display, patient, auth, medic, survey }) => {
  return {
    logginUserRole: _.get(auth, 'user.role'),
    bearerToken: auth && auth.token,
    selectedPatient: patient.selectedPatient,
    patientList: patient.list || [],
    isLoadingPatient: patient.loadingPatient,
    emailAvailable: patient.available || { isAvailable: false },
    emailLoading: patient.emailLoading,
    uploadSuccess: patient.uploadSuccess,
    surveyIsSigned: survey.isSurveySigned,
    types: Object.values((survey && survey.types) || {}),
    selectedTeam: medic.selectedTeam,
    currentMedic: medic.currentMedic,
    autoLoginSurveyID: _.get(medic, ['autoLoginData', 'surveyID'], -1),
    isAutoLoginLoaded: medic.isAutoLoginLoaded,
    isClinicalMode: display.isClinicalStudy,
    counterSigned: survey?.counterSigned,
  };
};

const mapDispatchToProps = (dispatch) => ({

  clinicRequest: () => dispatch(ClinicActions.clinicRequest()),
  patientDetailRequest: (patientId, selectedTeam) => dispatch(PatientActions.patientDetailRequest(patientId, selectedTeam)),
  clearSelectedPatient: () => dispatch(PatientActions.clearSelectedPatient()),
  patientUpdateRequest: (id, patient) => dispatch(PatientActions.patientUpdateRequest(id, patient)),
  checkIfEmailExistsInDb: (email, userId) => dispatch(PatientActions.checkIfEmailExistsInDb(email, userId)),
  resumeSurveyRequest: (survey) => dispatch(PatientActions.resumeSurveyRequest(survey)),
  uploadSignRequest: (survey) => dispatch(SurveyActions.surveyUploadSign(survey)),
  generateSurveyPDF: (surveyId, toDisplay) => dispatch(SurveyActions.generateSurveyPDF(surveyId, toDisplay)),
  generateSurveyDraw: (surveyImage, toDisplay) => dispatch(SurveyActions.generateSurveyDraw(surveyImage, toDisplay)),
  resetPatientUploadSuccess: () => dispatch(PatientActions.resetUploadSuccess()),
  resetSurveySigned: () => dispatch(SurveyActions.resetSurveySigned()),
  deleteSurveyRequest: (patientId, IDs) => dispatch(SurveyActions.surveyDeleteRequest(patientId, IDs)),
  specialitiesRequest: () => dispatch(SurveyActions.specialitiesRequest()),
  surveyTypesRequest: () => dispatch(SurveyActions.surveyTypesRequest()),
  resetGeneratedURL: () => dispatch(SurveyActions.resetGenerateSuccess()),
  resetAutoLoginData: () => dispatch(MedicActions.medicResetAutoLoginData()),
  displayInfo: (message) => dispatch(SnackActions.displayInfo(message)),
  deleteAuthToken: () => dispatch(AuthActions.deleteCurrentToken()),
  clearData: () => dispatch(FormsActions.clearData()),
  patientArchiveRequest: (patientId, selectedTeam) => dispatch(PatientActions.patientArchiveRequest(patientId, selectedTeam)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InfoPatient);
