import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import Select, { components } from 'react-select';

import reactSelectStyle from './reactSelectStyle';

const InputGroupSelect = ({
  name,
  rules = { required: false },
  options,
  isMulti = false,
  placeholder,
  disabled,
  isClearable,
  changeTheme,
  customStyles,
  isLoading,
  inputValue,
  defaultValue,
  components : componentsProps,
  className,
  ...extraInputProps
}) => {
  const { control } = useFormContext(); // retrieve all hook methods
  const {
    field: { ref, value, onChange, ...field }, // field : { onChange, onBlur, value, name: fieldName, ref, ...field },
    // field : { onChange, onBlur, value, name: fieldName, ref, ...field },
    fieldState: { error }, // fieldState: { invalid, isTouched, isDirty, error },
    // formState: { errors, isDirty, isSubmitting, touched, submitCount },
  } = useController({
    name,
    control,
    rules: { ...rules },
    defaultValue: defaultValue || null,
  });

  useEffect(() => {
    if (!_.isObject(value) && _.isString(value)) {
      const selected = options.find(o => o.value === value);
      onChange(selected);
    }
  }, [value]);

  const handleChange = (newValue) => {
    onChange(newValue);
  };

  const handleGroupHeadingClick = (_event, data) => {
    onChange(data.options);
  };

  const GroupHeading = props => {
    const { data } = props;
    const groupStyles = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    };
    return (
      <div style={groupStyles} onClick={(event) => handleGroupHeadingClick(event, data)} >
        <components.GroupHeading {...props} >{data.label}</components.GroupHeading>
      </div>
    );};

  return (
    <div className={className}>
      <Select
        isDisabled={disabled || false}
        styles={reactSelectStyle(customStyles || {})}
        options={options}
        placeholder={placeholder || ''}
        isMulti={isMulti}
        isClearable={isClearable}
        theme={changeTheme}
        isOptionDisabled={(option) => option.disabled || option.isDisabled}
        noOptionsMessage={() => 'Pas de choix disponible.'}
        inputValue={inputValue}
        value={value}
        ref={ref}
        onChange={handleChange}
        isLoading={isLoading}
        components={{ ...componentsProps, GroupHeading }}
        aria-invalid={error ? 'true' : 'false'}
        {...field}
        {...extraInputProps}
      />
    </div>
  );
};

InputGroupSelect.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    label: PropTypes.string,
  })).isRequired,
  rules: PropTypes.object,
  defaultValue: PropTypes.string,
  extraInputProps: PropTypes.object,
  isMulti: PropTypes.bool,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  isClearable: PropTypes.bool,
  changeTheme: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      borderRadius: PropTypes.number.isRequired,
      colors: PropTypes.object.isRequired,
      spacing: PropTypes.shape({
        baseUnit: PropTypes.number.isRequired,
        controlHeight: PropTypes.number.isRequired,
        menuGutter: PropTypes.number.isRequired,
      }).isRequired,
    }),
    PropTypes.shape({
      borderRadius: PropTypes.number.isRequired,
      colors: PropTypes.object.isRequired,
      spacing: PropTypes.shape({
        baseUnit: PropTypes.number.isRequired,
        controlHeight: PropTypes.number.isRequired,
        menuGutter: PropTypes.number.isRequired,
      }).isRequired,
    }),
    PropTypes.shape({
      borderRadius: PropTypes.number.isRequired,
      colors: PropTypes.object.isRequired,
      spacing: PropTypes.shape({
        baseUnit: PropTypes.number.isRequired,
        controlHeight: PropTypes.number.isRequired,
        menuGutter: PropTypes.number.isRequired,
      }),
    }),
    PropTypes.shape({
      borderRadius: PropTypes.number.isRequired,
      colors: PropTypes.object.isRequired,
      spacing: PropTypes.shape({
        baseUnit: PropTypes.number.isRequired,
        controlHeight: PropTypes.number.isRequired,
        menuGutter: PropTypes.number.isRequired,
      }),
    }),
  ]),
};

export default InputGroupSelect;
