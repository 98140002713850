
export const SURVEYS_TYPES_MINIMAL_FIELDS = `
  fragment surveyTypesMinimalFields on survey_types {
    id
    survey_types_translations {
      language_id
      language {
        language_code
      }
      title
      label_a
      label
      label_your
    }
  }
`;