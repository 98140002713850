import './style.css';

import React from 'react';
import { useTranslation } from 'react-i18next';

import Card from '../../../components/card';
import { ACTIONS } from '../../../components/patientDetails/list/DocumentChoice';
import SurveyDocumentViewer from '../../../components/patientDetails/list/SurveyDocumentViewer';

const CommentaryCard = ({ survey, className }) => {
  const { t } = useTranslation();

  return (
    <Card className={className}>
      <p className="consent-recap-titles"> {t('commentary')} </p>
      <p className="consent-recap-black-texts"> {(survey && survey.comment) || t('no_commentary')} </p>
      {survey && survey.survey_drawing_id && (
        <div className="px-4">
          <SurveyDocumentViewer action={ACTIONS.BUILD_DRAW} downloadOnClick={true} survey={survey} showBtn={false} />
        </div>
      )}
      {survey && survey.survey_attachment_id && (
        <SurveyDocumentViewer action={ACTIONS.BUILD_ATTACHMENT} survey={survey} hidePreview firstText={t('download_the_attachment')} firstButtonStyle={'btn btn-link'} />
      )}
    </Card>
  );
};

export default CommentaryCard;
