// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_title__WZWYq {
  font-size: 30px;
  font-weight: bold;
  min-height: 30px;
}

.style_step-card-image__REdr\\+ {
  margin-right: 30px;
  cursor: pointer;
  width: 60px;
  height: 60px;
}`, "",{"version":3,"sources":["webpack://./src/ui/screens/createFormV2/Step/style.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,WAAW;EACX,YAAY;AACd","sourcesContent":[".title {\n  font-size: 30px;\n  font-weight: bold;\n  min-height: 30px;\n}\n\n.step-card-image {\n  margin-right: 30px;\n  cursor: pointer;\n  width: 60px;\n  height: 60px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `style_title__WZWYq`,
	"step-card-image": `style_step-card-image__REdr+`
};
export default ___CSS_LOADER_EXPORT___;
