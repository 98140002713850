import React, { useMemo } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import GlobalLoader from '../../ui/components/loaders/GlobalLoader/GlobalLoader';


const ProtectedRoutes = ({
  userRole,
  allowedRoles,
  element,
}) => {
  const isAllowed = useMemo(() => ( Array.isArray(allowedRoles) ? (userRole && allowedRoles.includes(userRole)) : allowedRoles === userRole), [allowedRoles, userRole]);

  if (!userRole) {
    return (
      <>
        <GlobalLoader />
        <div>loading ProtectedRoutes</div>
      </>
    );
  }

  if (userRole && !isAllowed) {
    return <Navigate to={'/'} />;
  }

  return element || <Outlet/>;
};

export default ProtectedRoutes;