// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div#blockedModal {
  display: none;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: calc(100% - 40px);
  margin-left: 20px;
  height: 200px;
  background-color: white;
  border-radius: 5px;
  top: 20px;
  box-shadow: 0 0 15px rgb(0 0 0 / 15%);
  padding: 30px;
  box-sizing: border-box;
}

div#blockedModal p {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 24px;
  text-align: left;
}

div#blockedModal img {
  width: 50px;
  margin-right: 20px;
}`, "",{"version":3,"sources":["webpack://./src/ui/components/blockedModal/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;EACf,wBAAwB;EACxB,iBAAiB;EACjB,aAAa;EACb,uBAAuB;EACvB,kBAAkB;EAClB,SAAS;EACT,qCAAqC;EACrC,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;EACtB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,kBAAkB;AACpB","sourcesContent":["div#blockedModal {\n  display: none;\n  justify-content: center;\n  align-items: center;\n  position: fixed;\n  width: calc(100% - 40px);\n  margin-left: 20px;\n  height: 200px;\n  background-color: white;\n  border-radius: 5px;\n  top: 20px;\n  box-shadow: 0 0 15px rgb(0 0 0 / 15%);\n  padding: 30px;\n  box-sizing: border-box;\n}\n\ndiv#blockedModal p {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n  font-size: 24px;\n  text-align: left;\n}\n\ndiv#blockedModal img {\n  width: 50px;\n  margin-right: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
