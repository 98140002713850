exports.__esModule = true;
exports.default = drawImageProp;
/**
 * Original from: https://stackoverflow.com/questions/21961839/simulation-background-size-cover-in-canvas
 * Original By Ken Fyrstenberg Nilsen
 *
 * Note: img must be fully loaded or have correct width & height set.
 */
function drawImageProp() {
  const _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    ctx = _ref.ctx,
    img = _ref.img,
    wrh = img.width / img.height;

  let newWidth = ctx.canvas.width,
    newHeight = newWidth / wrh;

  if (newHeight > ctx.canvas.height) {
    newHeight = ctx.canvas.height;
    newWidth = newHeight * wrh;
  }
  const xOffset = newWidth < ctx.canvas.width ? (ctx.canvas.width - newWidth) / 2 : 0;
  const yOffset = newHeight < ctx.canvas.height ? (ctx.canvas.height - newHeight) / 2 : 0;

  ctx.drawImage(img, xOffset, yOffset, newWidth, newHeight);
}
module.exports = exports['default'];
