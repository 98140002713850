import './style.css';

import React from 'react';
import { useTranslation } from 'react-i18next';

const Idconnexion = ({ data }) => {
  const { t } = useTranslation();
  return (
    <div className={'idconnexion-main-container'}>
      <div className={'info-block-container'}>
        <p className={'idconnexion-grey-text'}>{t('idconnection_email')}</p>
        <p className={'idconnexion-black-text'}>{data ? data.email : '--'}</p>
      </div>
      <div className={'info-block-container'}>
        <p className={'idconnexion-grey-text'}>{t('password_placeholder')}</p>
        <p className={'idconnexion-black-text'}>{data ? '••••••••••••' : '--'}</p>
      </div>
    </div>
  );
};

export default Idconnexion;
