// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ec-question-pdf-container {
  margin-left: 10px;
  margin-bottom: 15px;
}

.ec-question-pdf-loading-text {
  font-size: 15px;
  margin-left: 4px;
}

.ec-question-pdf-buttons-container {
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
}

.ec-question-pdf-button {
  width: 40%;
  height: 40px;
  border-radius: 15px;
  background-color: #00aecb;
  border-width: 0px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/ui/screens/createFormV2/QuestionTypes/PDF/style.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,UAAU;EACV,YAAY;EACZ,mBAAmB;EACnB,yBAAyB;EACzB,iBAAiB;EACjB,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB","sourcesContent":[".ec-question-pdf-container {\n  margin-left: 10px;\n  margin-bottom: 15px;\n}\n\n.ec-question-pdf-loading-text {\n  font-size: 15px;\n  margin-left: 4px;\n}\n\n.ec-question-pdf-buttons-container {\n  display: flex;\n  justify-content: space-between;\n  padding-right: 10px;\n}\n\n.ec-question-pdf-button {\n  width: 40%;\n  height: 40px;\n  border-radius: 15px;\n  background-color: #00aecb;\n  border-width: 0px;\n  color: white;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
