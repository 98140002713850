import { connect } from 'react-redux';

import { CityActions } from '../../../../../redux/city';
import CityPicker from './CityPicker';

const mapStateToProps = ({ city }) => ({
  citiesOptions: Object.values(city.cities || {}).map((t) => ({
    value: t.id,
    label: t.name,
    postcode: t.postcode,
  })),
  isCityLoading: city.isCitiesLoading,
});

const mapDispatchToProps = (dispatch) => ({
  //
  clearCityList: () => dispatch(CityActions.clearCityList()),
  fetchCityListWithPostcode : (postcode) => dispatch(CityActions.fetchCityListWithPostcode(postcode)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CityPicker);