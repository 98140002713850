import _ from 'lodash';

import { regexSecu, validAlphaDigitRegex, validAlphaRegex, validDigitRegex, validEmailRegex } from '../../../../utils/regex';

export const required = ({ value }) => {
  return value != null && value.length !== 0;
};

export const length = ({ value }, size) => {
  if (value) return value.length === size;
  else return true;
};

export const min = ({ value }, minSize) => {
  if (value) return value.length >= minSize;
  else return false;
};

export const max = ({ value }, maxSize) => {
  if (value) return value.length >= maxSize;
  else return false;
};

export const email = ({ value }) => {
  if (!value) return true;
  return validEmailRegex.test(value);
};

export const same = ({ value, form }, key) => {
  if (!value) return true;
  return value === form[key];
};

export const isValidSecuNumFromRegex = ({ value }) => {
  if (value) {
    return regexSecu.test(value);
  }
};

export const isValidSecuKey = ({ value }) => {
  if (value) {
    let numss = value;
    const s7 = numss.substring(6, 7);
    if (s7 === 'A' || s7 === 'B') numss = numss.substring(0, 6) + '0' + numss.substring(7, 15);
    const filter = /^\d{15}$/;
    if (!filter.test(numss)) return false;
    else {
      const filter_sexe = /^[1-4]$/;
      if (
        !filter_sexe.test(numss.substring(0, 1))
        && numss.substring(0, 1) !== '7'
        && numss.substring(0, 1) !== '8'
      )
        return false;
      let deb13 = parseInt(numss.substring(0, 13));
      // corse
      if (s7 === 'A') deb13 -= 1000000;
      if (s7 === 'B') deb13 -= 2000000;

      const div97 = 97 - (deb13 % 97);
      let sdiv97 = String(div97);
      if (sdiv97.length === 1) sdiv97 = '0' + sdiv97;
      if (numss.slice(-2) !== sdiv97) return false;
    }
    return true;
  }
};

export const isEmailAvailable = ({ value }, availability, initialEmail) => {
  if (!value) return true;
  if (value === initialEmail) return true;
  return availability;
};

export const iscity = ({ value }) => {
  if (!value) {
    return true;
  } else {
    return (typeof value === 'string' || value instanceof String)
      ? validAlphaDigitRegex.test(value.normalize('NFD').replace(/[\u0300-\u036f]/g, ''))
      : _.isObject(value) && validAlphaDigitRegex.test(value.label.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
  }
};

export const alpha = ({ value }) => {
  return !value
    ? true
    : (typeof value === 'string' || value instanceof String) && validAlphaRegex.test(value.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
};

export const digit = ({ value }) => {
  return !value ? true : validDigitRegex.test(value);
};
/* Create form rules */

export const modified = ({ value }, originalValue) => {
  return value != null && value !== originalValue && value !== '';
};

export const isTypeSelected = ({ value }) => {
  return value != null && value.filter((val) => val.isSelected).length > 0;
};

export const answered = ({ value }) => {
  return value !== null;
};

export const none = ({ value }) => {
  return value !== undefined && value !== null && value !== 'none';
};

export const isNotEmptyArray = ({ value }) => {
  return value !== null && value.length !== 0;
};

export const text_answered = ({ value }) => {
  return (value && typeof value !== 'object' && value !== '');
};

export const multiple_answered = ({ value }) => {
  return (value && Array.isArray(value) && value.filter((v) => v.isSelected).length);
};
