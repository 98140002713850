
import _ from 'lodash';

import { QUESTION_TYPES } from '../../../utils/enum';
import yup from '../../../utils/yup';

const yupSchema = questionTypes => yup.object().shape({
  is_published: yup.boolean(),
  title: yup.string('surveyCreateForm.title.format.required').trim(),
  description: yup
    .array()
    .of( yup.string().trim() )
    .nullable(),
  type_id: yup.string(),
  speciality_id: yup.string(),
  survey_pdfs:
    yup
      .mixed()
      .nullable()
      .notRequired(),
  steps: yup
    .array()
    .of(
      yup
        .object()
        .shape(
          {
            title: yup.string('surveyCreateForm.steps.title.rules.format').trim(),
            number: yup.number(),
            survey_id: yup.string().nullable(),
            questions: yup
              .array()
              .of(
                yup.lazy((question) => {
                  const type = _.get(questionTypes, [question.type_id, 'value']);
                  const getData = (questionType) => {
                    switch (questionType) {
                      case QUESTION_TYPES.dropdown_list:
                        // dropdown_list
                        return yup.object().shape({
                          list: yup
                            .array()
                            .of(
                              yup.object().shape({
                                value: yup.string(),
                                label: yup.boolean(),
                              }),
                            ),
                        });
                      case QUESTION_TYPES.radio_multi_questions_to_table:
                      case QUESTION_TYPES.multiple_choice:
                      case QUESTION_TYPES.checkbox_full:
                        // multiple_choice
                        // checkbox_full
                        return yup.object().shape({
                          list: yup.array().of(
                            yup.object().shape({
                              value: yup.string(),
                              label: yup.boolean(),
                            })),
                        });
                      case QUESTION_TYPES.text_answer:
                        // text_answer
                        return yup.object().shape({});
                      case QUESTION_TYPES.pdf:
                        // pdf
                        return yup.object().shape({
                          oid: yup.string(),
                          isPDFType: yup.boolean(), // .nullable(),
                        });
                      case QUESTION_TYPES.validation:
                        // validation
                        return yup.object().shape({
                          first: yup.string(),
                          second: yup.string(),
                        });
                      default:
                    }
                  };

                  const getAnswer = (questionType) => {
                    switch (questionType) {
                      case QUESTION_TYPES.dropdown_list:
                        // dropdown_list
                        return yup.string().nullable().required('ConsentRender.dropdown_list.required').min(1, 'ConsentRender.dropdown_list.required');
                      case QUESTION_TYPES.multiple_choice:
                        // multiple_choice
                        return yup.array().of(yup.string()).nullable().required('ConsentRender.dropdown_list.required').min(1, 'ConsentRender.answer.required');
                      case QUESTION_TYPES.checkbox_full:
                        // checkbox_full
                        return yup.array().of(yup.string()).nullable().required('ConsentRender.checkbox_full.required');
                        // checkbox_multi_question_to_table
                      case QUESTION_TYPES.radio_multi_questions_to_table:
                        // checkbox_full
                        return yup.string().required('ConsentRender.radio_multi_questions_to_table.required');
                        // text_answer
                      case QUESTION_TYPES.text_answer:
                        return yup
                          .string()
                          .nullable()
                          .required('ConsentRender.text_answer.required')
                          .min(1, 'ConsentRender.text_answer.required')
                          .max(200, 'ConsentRender.text_answer.required');
                        // pdf
                      case QUESTION_TYPES.pdf:
                        return yup.object().shape({ isRead: yup.boolean().oneOf([true], 'ConsentRender.pdf.isRead') });
                      case QUESTION_TYPES.validation:
                        // validation
                        return yup.mixed().oneOf(['first'], 'ConsentRender.validation.required');
                      default:
                    }
                  };
                  return yup.object().shape({
                    question: yup.string('surveyCreateForm.steps.questions.question.rules.format'),
                    comment: yup.string('surveyCreateForm.steps.comment.rules.format'),
                    data: getData(type),
                    step_id: yup.string(),
                    type_id: yup.string(),
                    number: yup.number(),
                    desc: yup.string(),
                    rewording: yup.string().nullable(),
                    asked_more_info: yup.boolean().nullable(),
                    texte_pdf: yup.string().nullable(),
                    incompris: yup.string().nullable(),
                    answer: getAnswer(type),
                  });
                }),
              )
              .min(1, 'surveyCreateForm.steps.questions.rules.min'),
          },
          'surveyCreateForm.type.rules.format',
        )
        .nullable(),
    )
    .when(['survey_pdfs'], {
      is: (survey_pdfs) => {
        return !(!!survey_pdfs);
      },
      then: yup.array().min(1, 'surveyCreateForm.steps.rules.min'),
    }),
});

export default yupSchema;
