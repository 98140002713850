import './style.css';

import React from 'react';

import * as Images from '../../../../assets/img/icons';
import { SURVEY_STATUS } from '../../../../utils';
import { normalizeString } from '../../../../utils/utils';

const FilterIcon = ({ image, active }) => {
  const iconForImage = () => {
    switch (normalizeString(image)) {
      /* MEDIC SCREEN FILTER IMAGE*/
      case 'surgery':
        return Images.IconSurgery;
      case 'consent':
        return Images.IconConsent;
      case 'patient':
        return Images.IconPatient;
      case normalizeString('in_progress'):
        return Images.IconReformulate;
      case normalizeString(SURVEY_STATUS.COUNTERSIGNED):
        return Images.IconCountersigned;
      case normalizeString(SURVEY_STATUS.ACCEPTED):
        return Images.IconSigned;
      case normalizeString(SURVEY_STATUS.REFUSED):
        return Images.IconRefused;
      case normalizeString(SURVEY_STATUS.FURTHER_INFO):
        return Images.IconFurtherInfo;
      case normalizeString(SURVEY_STATUS.WITHDRAWAL):
        return Images.IconWithdrawalStat;

      /* FORM SCREEN FILTER IMAGES*/
      case 'anesthesie':
        return Images.IconAnesthesia;
      case 'chirurgie':
        return Images.IconSurgery;
      case 'examen':
        return Images.IconExams;
      case 'radiologie':
        return Images.IconInterventionalRadiology;
      case 'all':
        return Images.IconAll;

      default:
        return Images.IconAll;
    }
  };
  return (
    <div className={'ec-filter-image'}>
      <img
        className={active ? 'ec-filter-button-image-activated' : ''}
        src={iconForImage()}
        alt={`ec-filter-button ${image}`}
      />
    </div>
  );
};

export default FilterIcon;