import { createAction } from '../../utils/index';

// Types
export const types = {
  RESET_ALL_REDUCERS: 'RESET_ALL_REDUCERS',
  RESET_REDUCERS: 'RESET_REDUCERS',
};

// Actions
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  resetAllReducers: () => createAction(types.RESET_ALL_REDUCERS),
  resetReducers: () => createAction(types.RESET_REDUCERS),
};
