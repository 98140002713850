/* eslint-disable no-console */
import axios from 'axios';
import _ from 'lodash';

const getNewVersion = async (hostname = window.location.hostname) => {
  try {
    let env;
    if (/^recette./.test(hostname)){
      env = 'recette';
    } else if (/^sante./.test(hostname)){
      env = 'prod';
    } else {
      env = 'dev';
    }
    const result = await axios.get(`${process.env.REACT_APP_BASE_API_URL}/V2/healthcheck/version`, { params: { env } });
    const nextCacheVersion = `${_.get(result, 'data.result', '')}`;
    return nextCacheVersion;
  } catch (err) {
    return 'error';
  }
};
const forceEmptyCache = ({ hardReload = true }) => {
  refreshCacheAndReload({ hardReload });
  clearLocalStorageButList({ exceptions: ['cacheVersion'] });
  return Promise.resolve();
};

const emptyCache = ({ nextCacheVersion, hardReload = true }) => {
  const currentCacheVersion = localStorage.getItem('cacheVersion');
  localStorage.setItem('cacheVersion', nextCacheVersion);
  if (currentCacheVersion && currentCacheVersion !== nextCacheVersion) {
    forceEmptyCache({ hardReload });
  }
};

const refreshCacheAndReload = ({ hardReload = true }) => {
  if ('caches' in window) {
    // Service worker cache should be cleared with caches.delete()
    caches.keys().then((names) => {
      // Delete all the cache files
      names.forEach(name => caches.delete(name));
    });
    if (hardReload) {
      // delete browser cache and hard reload
      console.log('hard reload');
      window.location.reload(true);
    }
  }
  return Promise.resolve(true);
};

const clearLocalStorageButList = ({ exceptions }) => {
  const keys = Object.keys(localStorage || {}).filter(el => (exceptions ? (Array.isArray(exceptions) ? !exceptions.includes(el) : exceptions !== el) : true));
  keys.forEach(key => localStorage.clear(key));
};


export { emptyCache, getNewVersion, forceEmptyCache };
export default emptyCache;