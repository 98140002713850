import { createAction } from '../../utils';

// Types
export const types = {
  SET: 'splash_SET',
  SET_SUCCESS: 'splash_SET_SUCCESS',
  CLEAR: 'splash_CLEAR',
  CLEAR_TOKENS: 'splash_CLEAR_TOKENS',

  SET_REDIRECT_PAYLOAD: 'splash_SET_REDIRECT_PAYLOAD',
  CLEAR_REDIRECT_PAYLOAD: 'splash_CLEAR_REDIRECT_PAYLOAD',
  SET_LOGIN_FROM_QR_CODE: 'splash_SET_LOGIN_FROM_QR_CODE',
};

// Actions
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  set: (payload) => createAction(types.SET, { payload }),
  setSuccess: (payload) => createAction(types.SET_SUCCESS, { payload }),
  clear: () => createAction(types.CLEAR),
  clearTokens: () => createAction(types.CLEAR_TOKENS),
  setRedirectPayload: (url) => createAction(types.SET_REDIRECT_PAYLOAD, { url }),
  clearRedirectPayload: () => createAction(types.CLEAR_REDIRECT_PAYLOAD),
  setIsLoginFromQrCode: () => createAction(types.SET_LOGIN_FROM_QR_CODE),
};
