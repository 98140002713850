
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import AppQrCode from '../../AppQrCode/AppQrCode';
import { ACTIONS } from './DocumentChoice';
import EditDateForm from './EditDateForm';
import SurveyDocumentViewer from './SurveyDocumentViewer';

const DocumentChoiceRender = ({ action, survey, patient, onModalClose, bearerToken, onClearAction }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (action === ACTIONS.BUILD_INFO) {
      navigate(`/consent/${survey.id}`);
    }
  }, [action]);

  switch (action) {
    case ACTIONS.EDIT_DATE:
      return <EditDateForm survey={survey} patient={patient} onModalClose={onModalClose} />;
    case ACTIONS.GENERATE_QRCODE:
      return !survey.is_signed
        ? (
          <AppQrCode
            bearerToken={bearerToken}
            survey={survey}
          />
        ) : (
          <SurveyDocumentViewer
            action={action}
            survey={survey}
            onClearAction={onClearAction}
            canCancel
          />
        );
    case ACTIONS.BUILD_INFO:
      return <div />;
    default:
      return <SurveyDocumentViewer
        action={action}
        survey={survey}
        onClearAction={onClearAction}
        canCancel
      />;
  };
};


export default React.memo(DocumentChoiceRender);