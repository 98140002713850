import { createContextualCan } from '@casl/react';
import React, { createContext } from 'react';

import { ability } from './roles';

export const AbilityContext = createContext();

export const Can = createContextualCan(AbilityContext.Consumer);

export default function AbilityContextProvider({ children }) {
  return (
    <AbilityContext.Provider value={ability}>
      {children}
    </AbilityContext.Provider>
  );
};