
import './utils/css-helper';
import './assets/style.scss';
// import i18n (needs to be bundled ;))
import './services/i18n/i18n';

import { setDefaultOptions } from 'date-fns';
import { fr } from 'date-fns/locale';
import React from 'react';
import { isBrowser, isIOS13 } from 'react-device-detect';
import ReactDOM from 'react-dom';

import App from './App';

if (!isBrowser || isIOS13) {
  import('./assets/tablet-style.css').then();
}
setDefaultOptions({ locale: fr });

// App entry point
ReactDOM.render(
  <App />,
  document.getElementById('root'),
);