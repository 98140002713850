
import _ from 'lodash';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { MedicTemplateService, TemplateService } from '../../services';
import { TEMPLATE_TYPE } from '../../utils';
import { LoaderActions } from '../loader';
import { MedicActions } from '../medic';
import { SnackActions } from '../snackBar';
import { SurveyActions } from '../survey';
import { default as MedicTemplateActions, types } from './actions';

function* medicTemplatesGetRequest({ selectedTeam }) {
  const { list } = yield select((state) => state.medicTemplate);
  if (_.isEmpty(list)) {
    yield put(LoaderActions.loading());
  }
  const [error, response] = yield call(MedicTemplateService.getFromMedicTeamId, selectedTeam);
  if (!error && response.data.success) {
    const medicTemplates = [...response.data.medicTemplates].map( t => ({
      ...t,
      translation: _.keyBy(t.translation, 'language_id'),
    }));
    yield put(MedicTemplateActions.medicTemplatesGetSuccess(medicTemplates));
  } else {
    yield put(SnackActions.displayError('survey_current_error'));
    yield put(MedicTemplateActions.medicTemplatesGetFailure());
  }
  yield put(LoaderActions.loaded());
}

function* getDetail(templateID, lngId, mode) {
  let res, error, response;
  if (mode === TEMPLATE_TYPE.STORE) {
    [error, response] = yield call(TemplateService.getDetails, templateID);
    res = response;
    response.medic_templates = Array.isArray(response.medic_templates) && response.medic_templates.map(mt => {
      return {
        ..._.omit(mt, 'medic_template_steps'),
        steps: mt && (mt.steps || Array.isArray(mt.medic_template_steps) ? mt.medic_template_steps.map((st) => st.step) : []),
      };
    });
  } else {
    [error, response] = yield call(MedicTemplateService.getMedicTemplateDetail, [{ id: templateID, lng: lngId }]);
    res = response.data;
    res.medic_templates = res.medic_templates.map(t => ({
      ...t,
      translation: _.keyBy(t.translation, 'language_id'),
      steps: t.steps ? t.steps.map(s => ({
        ...s,
        translation: _.keyBy(s.translation, 'language_id'),
        questions: s.questions ? s.questions.map(q => ({
          ...q,
          translation: _.keyBy(q.translation, 'language_id'),
        })) : [],
      })) : [],
    })) ;
  }
  if (error || !res.medic_templates || !res.medic_templates.length) {
    yield put(MedicTemplateActions.medicTemplatesGetDetailsFailure());
    yield put(SnackActions.displayError('survey_current_error'));
    yield put(LoaderActions.loaded());
  }
  return [error, res];
}

function* medicTemplateGetDetail({ id, lngId }) {
  yield put(LoaderActions.loading());
  const [error, response] = yield call(getDetail, id, lngId, TEMPLATE_TYPE.MEDIC);
  if (response && !error) {
    yield put(MedicTemplateActions.medicTemplatesGetDetailsSuccess(response.medic_templates));
    yield put(SurveyActions.surveyCurrentSuccess(response.medic_templates));
    yield put(SnackActions.displayInfo('survey_current_success'));
  } else {
    yield put(SnackActions.displayError('survey_current_error'));
  }
  yield put(LoaderActions.loaded());
}

function* createMedicTemplatesFromStoreId({ storeId }) {
  yield put(LoaderActions.loading());
  const { selectedTeam } = yield select((state) => state.medic);
  const [error, reponse] = yield call(TemplateService.createMedicTemplatesFromStoreId, storeId, selectedTeam);
  if (error || !reponse?.data) {
    yield put(MedicTemplateActions.medicTemplateAssignFailure());
    yield put(SnackActions.displayError('medic_assign_from_store_error'));
  } else {
    const { medicTemplates } = reponse.data;
    const medicTemplatesId = Array.isArray(medicTemplates) ? medicTemplates.map(el => el.id) : medicTemplates?.id;
    yield call(fetchExtraTemplatesFromIds, medicTemplatesId, true);
    yield put(SnackActions.displayInfo('medic_assign_from_store_success'));
  }
  yield put(LoaderActions.loaded());
}


function* medicTemplateUnassign({ id }) {
  yield put(LoaderActions.loading());

  const [error, response] = yield call(MedicTemplateService.archiveMedicTemplate, id);
  if (error || !response) {
    yield put(MedicTemplateActions.medicTemplateUnassignFailure());
    yield put(SnackActions.displayError('medic_unassign_from_store_error'));
    yield put(LoaderActions.loaded());
    return;
  }

  yield put(MedicTemplateActions.medicTemplateUnassignSuccess([id]));
  yield put(SnackActions.displayInfo('medic_unassign_from_store_success'));
  yield put(LoaderActions.loaded());
}


const prepareFormData = (template) => {
  const formData = new FormData();
  const file = (template.survey_pdfs || []).map(p => _.get(p, 'file.0')).find(p => !!p);
  let hasPDFQuestions = false;
  if (file) {
    // if file => then, remove steps
    formData.append('file', file);
    template.steps = [];
    delete template['survey_pdfs'];
  } else {
    template.survey_pdfs = (template.survey_pdfs || []).map(el =>(el ? _.omit(el, 'file') : el));
    template.steps = template.steps.map((s, sIndex) => {
      s.questions = s.questions.map((q, qIndex) => {
        if (q.translation?.data?.pdf) {
          formData.append(`file-question-${sIndex}-${qIndex}`, q.translation.data.pdf);
          hasPDFQuestions = true;
          return {
            ...q,
            translation: {
              ...q.translation,
              data: { isPDFType: true },
            },
          };
        }
        return q;
      });
      return s;
    });
  }
  const fileMode = file ? 'full_pdf_template' : 'classic_template';
  const mode = hasPDFQuestions ? 'pdf_template' : fileMode;
  formData.append('file_insert_mode', mode);


  // JSON stringify and split keeps types as boolean
  const payload = JSON.stringify(template).match(/.{1,900000}/g);
  Array.isArray(payload) && payload.forEach((chunk, index) => formData.append(`payload_${index}`, chunk));
  formData.append('upsert_mode', 'medic_template');

  return formData;
};

function* medicTemplateUpsert({ template }) {
  yield put(LoaderActions.loading());
  const formData = prepareFormData(template);

  const [error, response] = yield call(MedicTemplateService.upsertMedicTemplates, formData);
  if (!error && response?.data?.success) {
    const templateId = _.get(response, 'data.medicTemplate.id');
    yield put(MedicTemplateActions.medicTemplatesGetDetailsRequest(templateId, template?.translation?.language_id || 1) );
    yield put(
      SnackActions.displayInfo(
        (!template.id || template.id < 0) ? 'survey_create_success' : 'template_update_success',
      ),
    );

    yield put(MedicTemplateActions.setNewMedicTemplateId(templateId));
  } else {
    yield put(MedicTemplateActions.medicTemplateUpsertFailure());
    yield put( SnackActions.displayError( (!template.id || template.id < 0) ? 'surveys_create_error' : 'template_update_error' ));
  }
  yield put(LoaderActions.loaded());
}

function* medicTemplateActivate({ templateID, isPublished }) {
  yield put(LoaderActions.loading());

  if (!isPublished) {
    const [unassignError, unassignReponse] = yield call(MedicTemplateService.archiveMedicTemplate, templateID);
    if (unassignError || !unassignReponse) {
      yield put(SnackActions.displayError('medic_assign_from_store_error'));
      yield put(LoaderActions.loaded());
      return;
    }
  }
  const [error, response] = yield call(MedicTemplateService.updateActiveState, templateID, isPublished);
  if (
    error
    || !response
    || !response.update_medic_templates
    || !response.update_medic_templates.returning
  ) {
    yield put(SnackActions.displayError('medic_assign_from_store_error'));
    yield put(LoaderActions.loaded());
    return;
  }

  const templates = response.update_medic_templates.returning;
  yield put(MedicActions.currentOwnerRefreshTemplates(templates));
  yield put(SnackActions.displayInfo('medic_assign_from_store_success'));
  yield put(LoaderActions.loaded());
}

function* medicTemplateDelete({ ID }) {
  yield put(LoaderActions.loading());
  const [error, response] = yield call(MedicTemplateService.deleteOne, ID);
  if (error || !response) {
    yield put(SnackActions.displayError('medic_template_delete_error'));
    yield put(LoaderActions.loaded());
    return;
  }
  yield put(MedicTemplateActions.medicTemplateDeleteSuccess(ID));
  yield put(SnackActions.displayInfo('medic_template_delete_success'));
  yield put(LoaderActions.loaded());
}

function* fetchExtraTemplatesFromIds(IDs, isNew = false) {
  const [error, data] = yield call(MedicTemplateService.getSome, IDs);
  if (data && !error){
    const { medic_templates } = data;
    yield put(MedicTemplateActions.medicTemplateAssignSuccess(isNew ? medic_templates.map(el => ({ ...el, isNew:true })) : medic_templates));
  } else {
    yield put(SnackActions.displayError('genereic_error'));
  }
  yield put(LoaderActions.loaded());
}

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  takeLatest(types.MEDIC_TEMPLATE_GET_REQUEST, medicTemplatesGetRequest),
  takeLatest(types.MEDIC_TEMPLATE_GET_DETAILS_REQUEST, medicTemplateGetDetail),
  takeLatest(types.MEDIC_TEMPLATE_UNASSIGN_REQUEST, medicTemplateUnassign),
  takeLatest(types.MEDIC_TEMPLATE_UPSERT_REQUEST, medicTemplateUpsert),
  takeLatest(types.MEDIC_TEMPLATE_ACTIVATE_REQUEST, medicTemplateActivate),
  takeLatest(types.MEDIC_TEMPLATE_DELETE_REQUEST, medicTemplateDelete),
  takeLatest(types.MEDIC_TEMPLATE_FROM_STORE_ID, createMedicTemplatesFromStoreId),
];
