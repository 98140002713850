import * as pdfobject from 'pdfobject';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

function PDFObject({ url, containerId, ...props }) {
  useEffect(() => {
    const { containerProps, ...options } = props;
    if (pdfobject) {
      pdfobject.embed(url, `#${containerId}`, { pagemode: 'none', ...(options || {}) });
    }
  }, [url]);

  return (
    <div {...props.containerProps} id={containerId} />
  );
}

PDFObject.defaultProps = {
  width: '100%',
  height: '800px',
  containerId: 'pdfobject',
  forcePDFJS: false,
  assumptionMode: true,
  forceIframe: false,
};

PDFObject.propTypes = {
  url: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  containerId: PropTypes.string,
  forcePDFJS: PropTypes.bool,
  assumptionMode: PropTypes.bool,
  forceIframe: PropTypes.bool,
};

export default PDFObject;
