import { default as i18n } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { DEFAULT_LANGUAGE_CODE, isProduction, isTest } from '../../utils/const.js';
import { APP_MODES } from '../../utils/enum.js';
import { capitalizeFirstLetter } from '../../utils/utils.js';
import { setDefaultLng } from '../datefns.js';
import translationEn from './en/en.js';
import translationFR from './fr-FR/fr-FR.js';
const resources = { fr: translationFR, 'fr-FR': translationFR, en: translationEn };

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: !(isProduction || isTest),
    fallbackLng: DEFAULT_LANGUAGE_CODE,
    ns: [APP_MODES.COMMON],
    defaultNS: APP_MODES.COMMON,
    // fallback, can be a string or an array of namespaces
    fallbackNS: APP_MODES.COMMON,
    interpolation: {
      // not needed for react as it escapes by default
      escapeValue: false,
    },
    resources,
  });

export default i18n;

// after i18next.init(options);
i18n.services.formatter.add('lowercase', (value, lng, options) => {
  return value.toLowerCase();
});
i18n.services.formatter.add('underscore', (value, lng, options) => {
  return value.replace(/\s+/g, '_');
});
i18n.services.formatter.add('capitalizeFirstLetter', (value, lng, options) => {
  return capitalizeFirstLetter(value);
});


const changeLanguage = (newLng = DEFAULT_LANGUAGE_CODE) => {
  const lng = newLng || DEFAULT_LANGUAGE_CODE;
  // overly simplistic way of toggling between languages
  if (Object.keys(resources).includes(lng)) {
    localStorage.setItem('i18nextLng', lng);
    i18n.changeLanguage(lng);
  }
};


i18n.on('languageChanged', function(lng) {
  // Keep the language on moment in sync with i18next by listening to the change language event:
  setDefaultLng(lng);
});



export { changeLanguage };
