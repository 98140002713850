
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PERMISSIONS } from '../../../services/casl/roles';
import { formatBirthdate } from '../../../services/datefns';
import { getUserProperty, patientInfoMapper } from '../../../utils';
import { isNullOrWhitespace } from '../../../utils/utils';
import Card from '../card';
import Modal from '../modal';
import InfosFormContainer from '../patientFormV2/Steps/InfosFormContainer';
import { Button } from '../styledComponent';
import EditPatientInfoCard from './EditPatientInfoCard';


const EditPatientInfo = ({
  patient,
  onUpdate,
  isOpenModal,
  disabled,
  isLoadingPatient,
  onModal,
  patientArchiveRequest,
  selectedTeam,
  isClinicalMode,
}) => {
  const { t } = useTranslation();
  const { security_number, medical_teams_id, gender, use_name } = patient;
  const INITIAL_DATA = !_.isEmpty(patient) && patientInfoMapper(patient);
  const [defaultValues, setDefaultValues] = useState(INITIAL_DATA);

  useEffect(() => {
    if (patient) setDefaultValues(patientInfoMapper(patient));
  }, [patient]);


  const handleOnInfo = () => {
    if (disabled) return;
    if (onModal) onModal(true);
  };

  const handleSubmit = (data) => {
    if (onUpdate) onUpdate(data);
  };

  const handleOnExit = (e) => {
    setDefaultValues(INITIAL_DATA);
    if (onModal) onModal(false);
  };

  return (
    <Card>
      <div className={'infopatient-contact'}>
        <EditPatientInfoCard
          title={getUserProperty(patient, 'fullContactName')}
          birthname={use_name && getUserProperty(patient, 'birthName')}
          gender={gender ? t(gender) : ''}
          birthdate={!isNullOrWhitespace(patient?.birthdate) ? formatBirthdate(patient.birthdate, isClinicalMode) : undefined}
          birthplace={_.get(patient, ['cityByBirthplaceId', 'name'])}
          security_number={security_number || ''}
          isloading={isLoadingPatient}
          medical_teams_id={medical_teams_id}
          patientArchiveRequest={patientArchiveRequest}
          selectedTeam={selectedTeam}
          patientId={patient.id}
          medicTeamId={patient.medical_teams_id}
        />
        <div className={'infopatient-bottom-container'}>
          {!disabled && (
            <Button onClick={handleOnInfo} >
              {t('btn.edit')}
            </Button>
          )}
          <div className={'infopatient-madal'}>
            <Modal show title={t('btn.edit_the_informations')} onClose={handleOnExit} isOpen={!disabled && isOpenModal}>
              <InfosFormContainer
                id={'editP-InfosFormContainer'}
                defaultValues={_.pick(defaultValues, ['gender', 'security_number', 'lastname', 'use_name', 'firstname', 'birthdate', 'birthplace', 'birthplace_postcode', 'birthplace_id'])}
                onSubmit={handleSubmit}
                onCancel={handleOnExit}
                canPermission={PERMISSIONS.UPDATE}
              />
            </Modal>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default EditPatientInfo;
