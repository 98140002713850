import { createAction } from '../../utils';

// Types
export const types = {
  RESET_REDUCER: 'medic_RESET_REDUCER',

  TEMPLATES_GET_REQUEST: 'TEMPLATES_GET_REQUEST',
  TEMPLATES_GET_SUCCESS: 'TEMPLATES_GET_SUCCESS',
  TEMPLATES_GET_FAILURE: 'TEMPLATES_GET_FAILURE',
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  templatesGetRequest: () => createAction(types.TEMPLATES_GET_REQUEST),
  templatesGetSuccess: (templates) => createAction(types.TEMPLATES_GET_SUCCESS, { templates }),
  templatesGetFailure: () => createAction(types.TEMPLATES_GET_FAILURE),
  resetReducer: () => createAction(types.RESET_REDUCER),
};
