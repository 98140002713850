import '../../form/style.css';
import './../style.css';

import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { isRequiredField, ModalButtonsGroup } from '../../formV3';
import { InputText } from '../../formV3/controllers';
import InputFileUpload from '../../formV3/controllers/InputFileUpload';
import InputSelectV2 from '../../formV3/controllers/InputSelectV2';
import { CustomFormController } from '../../formV3/innerWrapper';

const FILE_SIZE = 1600 * 1024;
const SUPPORTED_FORMATS = ['application/pdf'];

const UploadForm = ({ id='uploadForm', value, defaultValues, onError, updateData, surveyTypes, onSubmit, onCancel }) => {
  const [formTypesOption, setFormTypesOption] = useState([]);

  const { t } = useTranslation();
  const yupSchema = yup.object().shape({
    type_id: yup.string().required('surveyTemplateForm.type_id.rules.required'),
    title_paper: yup.string().required(),
    file_paper: yup
      .mixed()
      .required('uploadForm.file.rules.required')
      .test(
        'fileRequired',
        'uploadForm.file.rules.required',
        value => !!value,
      )
      .test(
        'fileSize',
        'uploadForm.file.rules.too_large',
        value => value && value.size <= FILE_SIZE,
      )
      .test(
        'fileFormat',
        'uploadForm.file.rules.unsupported_format',
        value => value && SUPPORTED_FORMATS.includes(value.type),
      ),
  });

  useEffect(() => {
    const newFilteredTypes = (surveyTypes || [])
      .filter(el => el?.translation?.label)
      .map(el => ({
        value: el.id,
        label: el.translation.label,
      }));
    setFormTypesOption(newFilteredTypes);
  }, [surveyTypes]);

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: useMemo(() => {
      return defaultValues;
    }, [defaultValues]),
    resolver: yupResolver(yupSchema),
    shouldFocusError: true,
  });

  const { watch, trigger, handleSubmit } = formMethods;

  const onSubmits = (data) => {
    trigger();
    if (updateData) updateData(id, data);
    if (onSubmit) onSubmit(null, data);
  };

  // eslint-disable-next-line no-console
  const onErrors = (errors) => console.error('errors :>> ', errors);

  const handleCancel = (id) => {
    if (onCancel) onCancel(id);
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmits, onErrors)} className={'custom-formV2'} >
        <div className={'createPatient-Container-part2'}>
          <CustomFormController
            name={'type_id'}
            label={t('surveyTemplateForm.type_id.label')}
            isRequiredField={isRequiredField(yupSchema, 'type_id')}
          >
            <InputSelectV2
              options={formTypesOption}
              defaultValue={formTypesOption[0]}
            />
          </CustomFormController>
          <CustomFormController
            name={'title_paper'}
            label={t('surveyPatientForm.uploadForm.title_paper.label')}
            isRequiredField={true}
          >
            <InputText />
          </CustomFormController>
          <br />
          <br />
          <CustomFormController
            name={'file_paper'}
            label={t('surveyPatientForm.uploadForm.file_paper.label')}
            isRequiredField={isRequiredField(yupSchema, 'file_paper')}
          >
            <InputFileUpload
              buttonClassName={'infopatient-button-action'}
              buttonTitle={t('patient_button_upload')}
            />
          </CustomFormController>
        </div>
        <ModalButtonsGroup onCancel={handleCancel} submitLabel={t('form.button.send.label')} />
      </form>
      {!!process.env.REACT_APP_DEBUG_FORM && (
        <pre>
          { JSON.stringify(watch(), null, 2) }
        </pre>
      )}
    </FormProvider>
  );
};

export default UploadForm;
