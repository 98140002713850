
import { connect } from 'react-redux';

import { AuthActions } from '../../../../../redux/auth';
import { FormsActions } from '../../../../../redux/forms';
import ModalRenew from './ModalRenew';


const mapStateToProps = ({ auth }) => {
  return { lastSmsSent: auth?.sms?.lastSent };
};

const mapDispatchToProps = (dispatch) => ({
  omitData: (key) => dispatch(FormsActions.omitData(key)),
  clearLastSmsSent: () => dispatch(AuthActions.clearLastSmsSent()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalRenew);
