
import _ from 'lodash';

import { CONTENT_TYPE_MAPPER, QUESTION_TYPES } from '../../../../utils/enum';
import yup from '../../../../utils/yup';

const yupSelectListElement = yup.object().shape({
  value: yup.string(),
  label: yup.string(),
});

const yupQuestionData = (type) => {
  switch (type) {
    case QUESTION_TYPES.dropdown_list:
      // dropdown_list
      return yup.object().shape({
        list: yup
          .array()
          .of(yupSelectListElement)
          .min(1)
          .required(),
      });
    case QUESTION_TYPES.multiple_choice:
    case QUESTION_TYPES.checkbox_full:
      // multiple_choice or checkbox_full
      return yup.object().shape({
        list: yup
          .array()
          .of(yupSelectListElement)
          .min(1)
          .required(),
        answer: yup.array().of(yup.string()).nullable(),
      });
    case QUESTION_TYPES.text_answer:
      // text_answer
      return yup.object().shape({ answer: yup.array().of(yup.string()).nullable() });
    case QUESTION_TYPES.pdf:
      // pdf
      return yup.mixed().test('fileList', 'No files', (value) => {
        return (value && value.pdf && value.pdf.type === 'application/pdf') || (value.oid && value.oid > 0);
      });
    case QUESTION_TYPES.validation:
      // validation
      return yup.object().shape({
        first: yup.string().required(),
        second: yup.string().required(),
      });
    default:
  }
};

const yupQuestion = yup.lazy((question) => {
  return yup.object().shape({
    translation: yup.object().shape({
      question: yup.string().trim().min(1, 'surveyForm.steps.questions.question.rules.required'),
      comment: yup.string('surveyForm.steps.comment.rules.format'),
      data: yupQuestionData(question?.type_name),
      desc: yup.string().trim().min(1, 'surveyForm.steps.questions.desc.rules.required'),
      rewording: yup.string().nullable(),
      asked_more_info: yup.boolean().nullable(),
      texte_pdf: yup.string().nullable(),
      incompris: yup.string().nullable(),
    }),
    // .required('surveyForm.steps.comment.rules.required')
    step_id: yup.string(),
    type_id: yup.string(),
    number: yup.number(),
  });
});

const yupStep = yup
  .object()
  .shape(
    {
      number: yup.number().required(),
      survey_id: yup.string(),
      translation: yup.object().shape({ title: yup.string('surveyForm.steps.title.rules.format').required('surveyForm.steps.title.rules.required').trim() }),
      questions: yup.array().of(yupQuestion).min(1, 'surveyForm.steps.questions.rules.min'),
    },
    'surveyForm.type.rules.format',
  )
  .nullable();

const yupSchemaMedicTemplate = yup.object().shape({
  id: yup.string().nullable(),
  translation: yup.object().shape({
    id: yup.string().nullable(),
    language_id: yup.string().nullable(),
    title: yup.string('surveyForm.title.format.required').required('surveyForm.title.rules.required').trim(),
    description: yup
      .array()
      .of(
        yup.object().shape({ value: yup.string() }),
      )
      .nullable(),
  }),
  isRadius: yup.boolean(),
  isSide: yup.boolean(),
  // medic_id: yup.string().required().trim().min(1),
  // medical_teams_id: yup.string().required().trim().min(1),
  type_id: yup.string().required().trim().min(1),
  speciality_id: yup.string().required().trim().min(1),
  is_published: yup.boolean(),
  steps: yup.array().of(yupStep)
    .when('survey_pdfs', {
      is: pdf => {
        return !pdf || !pdf.length;
      },
      then: (schema) => schema.min(1),
      otherwise: (schema) => schema.min(0),
    }),
  survey_pdfs: yup.array().of(yup.object().nullable()),
});

const yupSchema = yup.object().shape({
  is_published: yup.boolean(),
  translation: yup.object().shape({ title: yup.string('surveyCreateForm.title.format.required').required('surveyCreateForm.title.rules.required').trim() }),
  description: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string()
          .trim(),
      }),
    )
    .nullable(),
  isRadius: yup.boolean(),
  isSide: yup.boolean(),
  type: yup
    .object()
    .shape({
      value: yup.string().required(),
      label: yup.string(),
    })
    .required(),
  speciality: yup
    .object()
    .shape({
      value: yup.string().required(),
      label: yup.string(),
    })
    .required(),
  survey_pdfs:
    yup
      .mixed()
      .nullable()
      .notRequired()
      // .test('FILE_SIZE', 'surveyCreateForm.survey_pdfs.rules.size', value => !value || (value && value[0].size <= FILE_SIZE))
      // .test('FILE_FORMAT', 'surveyCreateForm.survey_pdfs.rules.format', value => !value || (value && SUPPORTED_FORMATS.includes(value[0].type)))
  ,
  steps: yup
    .array()
    .of(
      yup
        .object()
        .shape(
          {
            title: yup
              .string('surveyCreateForm.steps.title.rules.format')
              .required('surveyCreateForm.steps.title.rules.required')
              .trim(),
            number: yup.number().required(),
            survey_id: yup.string(),
            isNew: yup.boolean(),
            questions: yup
              .array()
              .of(
                yup.lazy((question) => {
                  const type = _.get(question, 'question_type.value') || _.get(question, 'type');
                  const getData = (survey_type) => {
                    switch (survey_type) {
                      case QUESTION_TYPES.dropdown_list:
                        // dropdown_list
                        return yup.object().shape({
                          list: yup
                            .array()
                            .of(
                              yup.object().shape({
                                id: yup.string(),
                                type: yup.object().shape({
                                  value: yup.string().required(),
                                  isSelected: yup.boolean(),
                                }),
                              }),
                            )
                            .min(1),
                        });
                      case QUESTION_TYPES.multiple_choice:
                        // multiple_choice
                        return yup.object().shape({
                          list: yup
                            .array()
                            .of(
                              yup.object().shape({ value: yup.string().required() }),
                            )
                            .min(1),
                          answer: yup.array().of(yup.string()).nullable(),
                        });
                      case QUESTION_TYPES.checkbox_full:
                        // checkbox_full
                        return yup.object().shape({
                          list: yup
                            .array()
                            .of(
                              yup.object().shape({ value: yup.string().required() }),
                            )
                            .min(1)
                            .required(),
                          answer: yup.array().of(yup.string()).nullable(),
                        });
                      case QUESTION_TYPES.text_answer:
                        // text_answer
                        return yup.object().shape({ answer: yup.array().of(yup.string()).nullable() });
                      case QUESTION_TYPES.pdf:
                        // pdf
                        return yup.mixed().test('fileList', 'No files', value => {
                          return ((value.oid && value.oid > 0) || (value.pdf && value.pdf.type === CONTENT_TYPE_MAPPER.PDF));
                        });
                      case QUESTION_TYPES.validation:
                        // validation
                        return yup.object().shape({
                          first: yup.string().required(),
                          second: yup.string().required(),
                          asked_more_info: yup.boolean().nullable(),
                        });
                      default:
                    }
                  };
                  return yup.object().shape({
                    question: yup
                      .string('surveyCreateForm.steps.questions.question.rules.format')
                      .required('surveyCreateForm.steps.questions.question.rules.required'),
                    comment: yup.string('surveyCreateForm.steps.comment.rules.format'),
                    // .required('surveyCreateForm.steps.comment.rules.required')
                    data: getData(type),
                    step_id: yup.string(),
                    type_id: yup.string(),
                    number: yup.number(),
                    desc: yup.string().required('surveyCreateForm.steps.questions.desc.rules.required'),
                    rewording: yup.string().nullable(),
                    asked_more_info: yup.boolean().nullable(),
                    texte_pdf: yup.string().nullable(),
                    incompris: yup.string().nullable(),
                    isNew: yup.boolean(),
                  });
                }),
              )
              .min(1, 'surveyCreateForm.steps.questions.rules.min'),
          },
          'surveyCreateForm.type.rules.format',
        )
        .nullable(),
    )
    .when(['survey_pdfs'], {
      is: (survey_pdfs) => {
        return !(!!survey_pdfs);
      },
      then: yup.array().min(1, 'surveyCreateForm.steps.rules.min'),
    }),
});

export { yupSchemaMedicTemplate, yupSchema, yupStep };
