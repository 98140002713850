
import _ from 'lodash';
import { connect } from 'react-redux';

import { PatientActions } from '../../../../../redux/patient';
import EditDateForm from './EditDateForm';

const mapStateToProps = ({ patient, survey, medic }) => {
  return {
    selectedPatient: patient.selectedPatient,
    types: Object.values((survey && survey.types) || {}),
    medicalTeamSettings: _.get(medic, ['medicalTeamList', medic.selectedTeam, 'medicalTeamSettings'], {}),
  };
};

const mapDispatchToProps = (dispatch) => ({ updateIntervention: (interventionNumber, date, dateWarning) => dispatch(PatientActions.interventionUpdateRequest(interventionNumber, date, dateWarning)) });

export default connect(mapStateToProps, mapDispatchToProps)(EditDateForm);
