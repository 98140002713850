import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { IconCounterSign } from '../../../../assets/img/icons';

function ShowActionCounterSign({ element, index, onClick, canCountersign, referent, disabled=false }) {
  const { t } = useTranslation();

  const getDataTip = () => {
    if (disabled) {
      return 'A déjà été contresigné';
    } else if (canCountersign) {
      return t('medicPatient.history.list.actionBtn.countersign');
    } else {
      return t('ShowActionCounterSign.toBeCountersignBy', { medic: `${referent?.firstname || ''} ${referent?.lastname || ''}` });
    }
  };

  if (element.survey_type && element.survey_type.should_countersign) {
    return (
      <button className="action-wrapper p-0 link" onClick={() => canCountersign && onClick(element, index, canCountersign)} disabled={disabled}>
        <img
          src={IconCounterSign}
          data-tip={getDataTip()}
          className={canCountersign ? '' : 'image-disabled'}
          alt={canCountersign ? 'image' : 'image-disabled'}
          style={{ width: '36px', height: '36px' }}
        />
      </button>
    );
  } else {
    return <div />;
  }
}

ShowActionCounterSign.propTypes = { referent: PropTypes.object, element: PropTypes.object, status: PropTypes.string, onClick: PropTypes.func, index: PropTypes.number, disabled: PropTypes.bool, canCountersign: PropTypes.bool };

export default ShowActionCounterSign;
