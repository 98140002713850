import './style.css';

import PropTypes from 'prop-types';
import React from 'react';

import { InputArea } from '../../../../components/formV3/controllers';
import { SurveyFormController } from '../../../../components/formV3/innerWrapper';
import { arrayToFormName } from '..';
import QuestionHeader from '../QuestionHeader';

const TextAnswer = ({ question, onDelete, prefixName, index, nestIndex, disabled, isConsent, readOnly }) => {
  return (
    <QuestionHeader
      question={question}
      onDelete={onDelete}
      prefixName={prefixName}
      index={index}
      nestIndex={nestIndex}
      disabled={disabled}
      isConsent={isConsent}
    >
      <SurveyFormController name={arrayToFormName([prefixName, 'answer'])}>
        <InputArea className="question-type-text-answer-input-area" disabled={disabled || !isConsent} dynamicLines readOnly={readOnly} />
      </SurveyFormController>
    </QuestionHeader>
  );
};

export default TextAnswer;

TextAnswer.propTypes = {

  question: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  prefixName: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  nestIndex: PropTypes.number,
};
