// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customDatePicker,
.customDatePicker > div.react-datepicker-wrapper,
.customDatePicker
  > div
  > div.react-datepicker__input-container
  .customDatePicker
  > div
  > div.react-datepicker__input-container
  input {
  width: 100%;
  height: 100%;
}

.react-datepicker__input-container {
  width: inherit;
  height: inherit;
}

.react-datepicker__input-container input {
  width: 100%;
  border-radius: 8px;
  border: solid 1px #cfcfcf;
  background-color: #ffffff;
  outline: none;
  padding: 16px;
  text-align: left;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.7;
  color: #393d52;
  box-sizing: border-box;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__close-icon::after {
  margin: auto;
}`, "",{"version":3,"sources":["webpack://./src/ui/components/formV3/controllers/DatePicker/DatePicker.css"],"names":[],"mappings":"AAAA;;;;;;;;;EASE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,yBAAyB;EACzB,yBAAyB;EACzB,aAAa;EACb,aAAa;EACb,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,cAAc;EACd,sBAAsB;AACxB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,YAAY;AACd","sourcesContent":[".customDatePicker,\n.customDatePicker > div.react-datepicker-wrapper,\n.customDatePicker\n  > div\n  > div.react-datepicker__input-container\n  .customDatePicker\n  > div\n  > div.react-datepicker__input-container\n  input {\n  width: 100%;\n  height: 100%;\n}\n\n.react-datepicker__input-container {\n  width: inherit;\n  height: inherit;\n}\n\n.react-datepicker__input-container input {\n  width: 100%;\n  border-radius: 8px;\n  border: solid 1px #cfcfcf;\n  background-color: #ffffff;\n  outline: none;\n  padding: 16px;\n  text-align: left;\n  font-size: 20px;\n  font-weight: 600;\n  line-height: 1.7;\n  color: #393d52;\n  box-sizing: border-box;\n}\n\n.react-datepicker-wrapper {\n  width: 100%;\n}\n\n.react-datepicker__close-icon::after {\n  margin: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
