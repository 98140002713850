import { countries } from 'countries-list';
import PropTypes from 'prop-types';
import React from 'react';

function CountryFlag({ countryCode, hideText = false }) {
  var localized = require('localized-countries')(require('localized-countries/data/fr'));
  const text = !hideText ? localized.get(countryCode) : '';
  return countryCode && countries[countryCode]
    ? (
      <>{countries[countryCode].emoji} {text}</>
    ) : <div/>;
}

CountryFlag.propTypes = { countryCode: PropTypes.string, hideText: PropTypes.bool };

export default CountryFlag;
