import { put, select, takeLatest } from 'redux-saga/effects';

import { APP_MODES } from '../../utils/enum';
import { LoaderActions } from '../loader';
import { DisplayActions } from '.';
import { types } from './actions';

function* setAppMode({ isClinicalStudy }) {
  const { appMode } = yield select((state) => state.display);
  yield put(DisplayActions.setIsClinicalStudy(isClinicalStudy));
  const newAppMode = isClinicalStudy ? APP_MODES.CLINICAL_STUDY : APP_MODES.COMMON;
  if (newAppMode !== appMode){
    yield put(LoaderActions.loading());

    yield put(DisplayActions.setAppModeSuccess(newAppMode));
    // window.location.reload(); // reload page to load proper i18n namespace
    yield put(LoaderActions.loaded());
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  takeLatest(types.SET_APP_MODE, setAppMode),
];
