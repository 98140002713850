import './style.css';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { getUserProperty } from '../../../utils';

const Info = ({ info }) => {
  const { t } = useTranslation();
  return (
    <div className={'info-main-container'}>
      <div className={'info-block-container'}>
        <p className={'info-grey-text'}>{t('info_name')}</p>
        <p className={'info_black_text'}>{info ? getUserProperty(info, 'titledName') : '--'}</p>
      </div>
      <div className={'info-block-container'}>
        <p className={'info-grey-text'}>{t('info_rpps')}</p>
        <p className={'info_black_text'}>{info ? info.rpps_number : '--'}</p>
      </div>
      <div className={'info-block-container'}>
        <p className={'info-grey-text'}>{t('info_inscription')}</p>
        <p className={'info_black_text'}>{'--'}</p>
      </div>
      <div className={'info-block-container'}>
        <p className={'info-grey-text'}>{t('info_country')}</p>
        <p className={'info_black_text'}>{info ? info.country : '--'}</p>
      </div>
      <div className={'info-block-container'}>
        <p className={'info-grey-text'}>{t('info_language')}</p>
        <p className={'info_black_text'}>{info ? info.user.language.language_code : '--'}</p>
      </div>
    </div>
  );};

export default Info;
