
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

function SearchInput(
  {
    removePlaceholder, onTagClick, tags, dropdownStyle, inputText, placeholder,
    hasPlaceholder, onChange, handleScrollLeft, onCancelAction,
  },
  ref) {
  const { t } = useTranslation();
  const tagsRef = useRef();
  const inputRef = useRef();
  const [editing, setEditing] = useState(false);

  useImperativeHandle(ref, () => ({
    handleActionCancel: () => handleActionCancel(),
    focus:() => {
      handleOnFocus();
      inputRef?.current?.focus();
    },
  }));

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside, true);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside, true);
    };
  }, [tagsRef]);

  const handleActionCancel = () => {
    stopEditing();
    onCancelAction();
  };

  const handleClickOutside = (event) => {
    if (editing && tagsRef.current && !tagsRef.current.contains(event.target)) {
      stopEditing();
    }
  };

  const handleRemovePlaceholder = () => {
    removePlaceholder();
    startEditing();
  };

  const handleTagClick = (tag) => {
    onTagClick(tag);
    stopEditing();
  };

  const handleChange = (event) => {
    if (event.target.innerText) {
      stopEditing();
    }
    onChange(event);
  };

  //When pressing enter, prevent it from creating a newline, finish tag instead
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleChange(event);
    } else if (event.key === 'ArrowLeft' && window.getSelection().anchorOffset === 0) {
      handleScrollLeft();
    }
  };

  const handleOnFocus = () => {
    startEditing();
  };

  const startEditing = () =>{
    setEditing(true);
  };

  const stopEditing = () => {
    setEditing(false);
  };

  return (hasPlaceholder && editing === false) ? (
    <span
      suppressContentEditableWarning={true}
      onClick={handleRemovePlaceholder}
      onInput={handleChange}
      onKeyDown={handleKeyDown}
      className="SearchBar__Input border"
      role="button"
      tabIndex="0"
    >
      {placeholder}
    </span>
  ) : (
    <>
      <span
        suppressContentEditableWarning={true}
        onInput={handleChange}
        onKeyDown={handleKeyDown}
        className="SearchBar__Input"
        contentEditable={true}
        onClick={handleOnFocus}
        ref={inputRef}
        role="button"
        tabIndex="0"
      >
        {inputText}
      </span>
      {
        editing && Array.isArray(tags) ? (
          <>
            <ul ref={tagsRef} className={'SearchBar__UL'} style={dropdownStyle}>
              { tags.map((tag, index) => (
                <li
                  key={`searchbar-list-index-number-${index}`}
                  className={'SearchBar__LI'}
                  onClick={() => handleTagClick(tag.key)}
                >
                  {t(tag.title)}
                </li>
              ))}
              <li className={'SearchBar___LI_Separator'}></li>
              <li className={'SearchBar__LI_Action'} onClick={handleActionCancel}>
                {t('cancel')}
              </li>
            </ul>
          </>
        ) : null
      }
    </>
  );
}


export default forwardRef(SearchInput);
