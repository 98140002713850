import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import yup from '../../../../../utils/yup';
import { ModalButtonsGroup } from '../../../../components/formV3';
import { InputText } from '../../../../components/formV3/controllers';
import ErrorMessage from '../../../../components/formV3/errors/ErrorMessage';
import { CustomFormController } from '../../../../components/formV3/innerWrapper';

function OtpForm({ onSubmit, onCancel }) {
  const { t } = useTranslation();
  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onSubmit',
    defaultValues: { code: '' },
    resolver: yupResolver(yup.object().shape({ code: yup.string().length(6, 'otpForm.code.rules.required').required() })),
    shouldFocusError: true,
  });
  const { control, handleSubmit, formState: { errors, isValid } } = formMethods;
  const codeWatch = useWatch({ control, name: 'code' });

  useEffect(() => {
    if (codeWatch && codeWatch.length === 6 ) {
      handleSubmit(data => onSubmit(data))();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codeWatch]);

  return (
    <div className={'auth-patient-card'}>
      <p className={'authentication-forgotten-password-text-desc'}>
        {t('otpForm.title')}
      </p>
      <p className={'authentication-forgotten-password-text-desc'}>
        {t('otpForm.text')}
      </p>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)} >
          <div >
            <CustomFormController
              name={'code'}
              rules={{ required: true }}
            >
              <InputText
                hidden
                placeholder={t('otpForm.code.placeholder')}
                autoComplete="one-time-code"
                type="text"
                maxLength="6"
                pattern="[0-9]*"
                inputMode="numeric"
              />
            </CustomFormController>
            {errors.status && <ErrorMessage name={'status'} />}

            <ModalButtonsGroup
              cancelLabel={t('otpForm.cancel.label')}
              submitLabel={t('otpForm.submit.label')}
              onCancel={onCancel}
              disabledSubmit={!isValid}
              hideSecond={!isValid}
            />
          </div>
        </form>
      </FormProvider>
    </div>
  );
}

OtpForm.propTypes = { onSubmit: PropTypes.func.isRequired, onCancel: PropTypes.func.isRequired };

export default OtpForm;
