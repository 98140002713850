import './style.css';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { IconAdd } from '../../../../assets/img/icons';
import AddIcon from '../../../components/icons';

const AddButton = ({ onCreateForm, disabled, activated = false }) => {
  const { t } = useTranslation();
  const handleOnForm = () => {
    if (onCreateForm) onCreateForm();
  };

  return (
    <button
      className={`medic-form-list-card-add-button${activated ? '-activated' : ''} ${disabled ? 'noHover' : ''}`}
      onClick={handleOnForm}
      disabled={disabled}
    >
      <p className={'medic-form-list-item-title medic-form-list-item-title-white'}>
        {t('form_card_add_title')}
      </p>
      <div className={'medic-form-list-icon-container'}>
        <AddIcon icon={IconAdd} />
      </div>
    </button>
  );
};

export default AddButton;
