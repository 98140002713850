import axios from 'axios';
import PropTypes from 'prop-types';
import QRCodeStyling from 'qr-code-styling';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTheme } from 'styled-components';

import logoEasyConsent from '../../../assets/img/illu-check-easyconsent.png';
import SmallLoader from '../loaders/SmallLoader';
export default function AppQrCode({ bearerToken, survey }) {

  const [urlQrCode, setUrlQrCode] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const refQrCode = useRef(null);
  const theme = useTheme();

  const qrCode = useMemo(() => (
    new QRCodeStyling({
      width: 250,
      height: 250,
      image: logoEasyConsent,
      dotsOptions: {
        type: 'dots',
        gradient: {
          type: 'radial',
          colorStops: [{ offset: 0, color: theme.qrCode.primary.color }, { offset: 1, color: theme.qrCode.secondary.color }],
        },
      },
      imageOptions: {
        margin: 0,
        hideBackgroundDots: false,
        imageSize: 0.4,
      },
      cornersSquareOptions: {
        type: 'extra-rounded',
        color: '#ed695a',
      },
    }))
  , [theme.qrCode.primary.color, theme.qrCode.secondary.color]);

  const getQrCodeUrl = async () => {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_API_URL}/V2/surveys/${survey.id}/qr-connect`,
      {},
      { headers: { Authorization: `Bearer ${bearerToken}` } },
    );
    return response?.data?.message;
  };

  useEffect(() => {
    qrCode.append(refQrCode.current);
  }, [isLoading, qrCode]);

  useEffect(() => {
    getQrCodeUrl()
      .then((url) => {
        setUrlQrCode(url);
      }).finally(() => {
        setIsLoading(false);
      });
  }, [survey, bearerToken]);

  useEffect(() => {
    qrCode.update({ data: urlQrCode });
  }, [qrCode, urlQrCode]);

  return (
    <div className="consent-preview-finish-modal">
      <div className="consent-preview-qr-code-container">
        {!isLoading && urlQrCode
          ? (
            <>
              <a href={urlQrCode} target="_blank" rel="noopener noreferrer" >
                <div id="idQrCode" ref={refQrCode} />
              </a>
            </>
          )
          : <SmallLoader />
        }
      </div>
    </div>
  );
};

AppQrCode.propTypes = {
  bearerToken: PropTypes.string,
  survey: PropTypes.object,
};