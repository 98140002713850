import _ from 'lodash';
import { connect } from 'react-redux';

import { getUserProperty } from '../../../../../utils';
import InputGroupSelect from './InputGroupSelect';


// get the label from storeItemLabel
_.templateSettings.interpolate = /{{([\s\S]{1,50})}}/g;

const mapStateToProps = (state, props) => {
  // storeOptionPath is location to date in Redux -> city.cities
  // storeItemValue is the key of the object -> id for obejct city from array cities
  // storeItemLabel is a mask in order to give a label-> {{ name }}, {{ id }}
  const { storeOptionPath, storeItemValue, storeItemLabel, storeGroupLabel, storeGroupKey, options } = props;
  const compiled = _.template(storeItemLabel);
  const storeList = _.get(state, storeOptionPath);
  let reduxOptions;
  if (!options) {
    if ( storeGroupLabel && storeGroupKey ) {
      const compiledStoreGroupLabel = _.template(storeGroupLabel);
      reduxOptions = ((_.isObject(storeList) && _.values(storeList)) || (storeList && Array.isArray(storeList) && storeList) || []).map(el => ({
        label: compiledStoreGroupLabel(el),
        options: (_.get(el, storeGroupKey)).map(o => ({
          ...o,
          label: 'lastname' in o ? getUserProperty(o, 'fullDisplayName') : (storeItemLabel && compiled(o)) || (o.title || o.name || o.value || o.id) || o,
          value: _.get(o, storeItemValue, ''),
          isDisabled: true,
        })),
      }));
    } else {
      reduxOptions = ((_.isObject(storeList) && _.values(storeList)) || (storeList && Array.isArray(storeList) && storeList) || [])
        .map(el => ({
          value: _.get(el, storeItemValue, el.value || el.id || ''),
          label: (storeItemLabel && compiled(el)) ? (el.title || el.name || el.value || el.id) : el,
        }));
    }
  }

  return { options: options || reduxOptions };
};

export default connect(mapStateToProps, null)(InputGroupSelect);

