import { connect } from 'react-redux';

import { MedicActions } from '../../../redux/medic';
import TeamLoader from './TeamLoader';

const mapStateToProps = ({ medic }) => {
  return {
    medicalTeamList: medic.medicalTeamList,
    selectedTeam: medic.selectedTeam,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onTeamChange: (teamId) => dispatch(MedicActions.changeSelectedMedicTeamRequest(teamId)),
  fetchMedicTeamPatients: (teamId) => dispatch(MedicActions.fetchMedicTeamPatients(teamId)),
  setMedicalTeamParams: () => dispatch(MedicActions.setMedicalTeamParams()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamLoader);
