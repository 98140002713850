import './style.scss';

import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ListSurveysWithFlag from '../../../ListSurveysWithFlag/ListSurveysWithFlag';
import ToolTip from '../../../tooltip';

function ListSelectField({
  name, rules = { required: false }, options, className: _className,
  containerClassName, maxItemQty = 1, keysToReturn = ['value'], defaultValue, onChange: onChangeProp,
  selectDefaultLanguageId, ...rest
}) {
  const showLngSelect = useMemo(() => options.some(el => el && Array.isArray(el.availableLngs) && el.availableLngs.length > 1), [options]);

  const { control } = useFormContext(); // retrieve all hook methods
  const { t } = useTranslation();
  const {
    field: { ref, value, onChange, onBlur }, // field : { onChange, onBlur, value, name: fieldName, ref, ...field },
    // field : { onChange, onBlur, value, name: fieldName, ref, ...field },
    // fieldState: { invalid, isTouched, isDirty, error },
    // formState: { errors, isDirty, isSubmitting, touched, submitCount },
  } = useController({
    name,
    control,
    rules: { ...rules },
    defaultValue: defaultValue || [],
  });

  const filteredOption = useMemo(() => {
    const selectedIds = (value || []).map(v => v.value);
    return Array.isArray(options) && options.length
      ? options
      // remove from option element inside value
        .filter((o) => !selectedIds.includes(o.value))
        .filter((o) => o.availableLngs?.length || 0)
      : [];
  }, [options, value]);

  const handleSelect = async (event, newValue) => {
    event.preventDefault();
    let formatedValue = [...(Array.isArray(value) ? value : []), _.pick(newValue, keysToReturn)];
    if (Array.isArray(formatedValue) && formatedValue.length <= maxItemQty) {
      if (onChangeProp) formatedValue = await onChangeProp(formatedValue) || formatedValue;
      onChange(formatedValue);
    }
    onBlur();
  };

  const onKeyPressHandler = (event, value) => {
    event.preventDefault();
    if(event.key === 'Enter'){
      handleSelect(value);
    }
  };

  const handleRemove = (index) => {
    let formatedValue = [...value.slice(0, index), ...value.slice(index + 1)];
    onChange(formatedValue);
  };

  return (
    <>
      <ToolTip />
      <div
        className="d-flex flex-column justify-content-end"
        ref={ref}
        onBlur={onBlur}
        role="button"
        tabIndex={0}
      >
        { Array.isArray(value) && value.map((field, index) => (
          <div key={`selected_${field.value}`} className={'my-1 position-relative visible'} >
            <div className="btn-delete" onClick={() => handleRemove(index)} aria-hidden="true" />
            <div
              className={'form-screen-switch-template-label-selector-validate px-1 text-nowrap'}
              key={`selector-validate_${index}`}
            >
              {field?.label}
            </div>
          </div>
        )) }
        { _.times(maxItemQty - (Array.isArray(value) ? value.length : 0), (i) => (
          <div
            className={'my-1 form-screen-switch-template-label-selector visible '}
            key={`selector_${i}`}
          />
        )) }
      </div>
      {(!value || (Array.isArray(value) && value.length < maxItemQty)) && (
        <div className={`${containerClassName || 'd-flex flex-column justify-content-between align-items-stretch listSelectField-container'}`}>
          {
            Array.isArray(filteredOption) && filteredOption.length
              ? (
                <ListSurveysWithFlag
                  selectDefaultLanguageId={selectDefaultLanguageId}
                  onSelect={handleSelect}
                  onKeyPressHandler={onKeyPressHandler}
                  rest={rest}
                  showLngSelect={showLngSelect}
                  options={filteredOption}
                />
              ) : (
                <div className="listSelectField-tile" >
                  <div className={'medic-form-list-item-container'}>
                    <p className={'medic-form-list-item-title'}>{t('empty_list')}</p>
                  </div>
                </div>
              )}
        </div>
      )}
    </>
  );
}

ListSelectField.propTypes = {
  register: PropTypes.func,
  name: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
  ),
};

export default ListSelectField;
