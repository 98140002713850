import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { validEmailRegex } from '../../../utils/regex';
import SkeletonItem from '../skeletonItem/SkeletonItem';

const EditPatientContactCard = ({ telephone, email, address, username, isloading, isClinicalMode }) => {
  const { t } = useTranslation();
  const displayEmail = useMemo(() => (validEmailRegex.test(email) ? email : null), [email]);
  return (
    <>
      <div className="infopatient-text-container">
        <p className={'infopatient-text-name'}>{t('patient_text_contacts')}</p>
      </div>
      <div className={'infopatient-row-container'}>
        <p className={'infopatient-information-text-min'}>{t('patient_text_phone')}</p>
        <p className={'infopatient-text-max'}>
          {isloading ? (
            <SkeletonItem width={112} height={20} animation />
          ) : (
            telephone
          )}
        </p>
      </div>
      <div className={'infopatient-row-container'}>
        <p className={'infopatient-information-text-min'}>{t('patient_text_email')}</p>
        <p className={'infopatient-text-max'}>
          {isloading
            ? (
              <SkeletonItem width={300} height={20} animation />
            )
            : displayEmail
          }
        </p>
      </div>
      <div className={'infopatient-row-container'}>
        <p className={'infopatient-information-text-min'}>{t('patient_text_username')}</p>
        <p className={'infopatient-text-max'}>
          {isloading
            ? (
              <SkeletonItem width={300} height={20} animation />
            )
            : username || null
          }
        </p>
      </div>
      { !isClinicalMode && (
        <div className={'infopatient-row-container'}>
          <p className={'infopatient-information-text-min'}>{t('patient_text_address')}</p>
          <p className={'infopatient-text-max'}>
            {isloading ? <SkeletonItem width={300} height={20} animation /> : address}
          </p>
        </div>
      )}
    </>
  );
};

export default EditPatientContactCard;
