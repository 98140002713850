import React from 'react';

import SurveysWithFlagCard from './SurveysWithFlagCard';

export default function ListSurveysWithFlag({ options, showLngSelect = false, selectDefaultLanguageId, onSelect, onKeyPressHandler, ...rest }) {
  return options
    .map((temp, i) => (
      <div style={{ display: 'flex' }} key={`ListSelect_card_${temp.value}`}>
        <SurveysWithFlagCard
          selectDefaultLanguageId={selectDefaultLanguageId}
          template={temp}
          index={i}
          onSelect={onSelect}
          onKeyPressHandler={onKeyPressHandler}
          rest={rest}
          showLngSelect={showLngSelect}
        />
      </div>
    ));
}
