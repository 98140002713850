import { yupResolver } from '@hookform/resolvers/yup';
import { addYears, differenceInDays, format, isBefore, startOfDay } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { parseDate } from '../../../../../services/datefns';
import yup from '../../../../../utils/yup';
import DateWarning from '../../../../components/surveyFormV2/DateWarning';
import { DatePicker } from '../../../formV3/controllers';
import { CustomFormController } from '../../../formV3/innerWrapper';
import ModalButtonsGroup from '../../../formV3/submit/ModalButtonsGroup';

const getMinDate = (date) => {
  const today = startOfDay(new Date());
  const momentDate = startOfDay(new Date(date));
  return isBefore(momentDate, today) ? momentDate : today ;
};

const yupSchema = (minDate, maxDate) => yup.object().shape({
  intervention: yup
    .object()
    .shape({
      date: yup
        .date()
        .label('surveyPatientForm.intervention.date.rules.date')
        .format('dd/MM/yyyy', 'surveyPatientForm.intervention.date.rules.format')
        .min(minDate, 'surveyPatientForm.intervention.date.rules.min')
        .max(maxDate, 'surveyPatientForm.intervention.date.rules.max')
        .required('surveyPatientForm.intervention.date.rules.required'),
    }),
});

export default function EditDateForm({ survey, patient, onModalClose, updateIntervention, medicalTeamSettings }) {
  const { t } = useTranslation();
  const [isDateWarningFormOpen, setIsDateWarningFormOpen] = useState();
  const [requestedDateInter, setRequestedDateInter] = useState();

  const minDate = useMemo(() => {
    return getMinDate(survey?.intervention?.date_consult);
  }, [survey?.intervention?.date_consult]);

  const maxDate = addYears(new Date(), 1);

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: useMemo(() => {
      return { intervention: { date: format(new Date(survey.intervention.date), 'dd/MM/yyyy') } };
    }, [survey]),
    resolver: yupResolver(yupSchema(minDate, maxDate)),
    shouldFocusError: true,
  });

  const { handleSubmit } = formMethods;

  useEffect(() => {
    if (requestedDateInter) {
      // compare date_consult from initial survey and date from form
      const dateConsult = new Date(survey.intervention.date_consult);
      const dateDiff = differenceInDays(requestedDateInter, dateConsult);

      const displayDateWarningForm = medicalTeamSettings.show_delay_reason && dateDiff >= 0 && dateDiff < 15;
      // show reason modal if < 15 days
      setIsDateWarningFormOpen(displayDateWarningForm);
      if (displayDateWarningForm === false) {
        handleDateWarningSubmit();
      }
    }
  }, [requestedDateInter]);

  const handleDateWarningSubmit = (_, data) => {
    const warningMessage = data?.answer;
    updateIntervention(survey.intervention.number, requestedDateInter, warningMessage);
    onModalClose();

    // close DateWarningForm
    setIsDateWarningFormOpen(false);
  };

  const handleInterDateFormSubmit = data => {
    // get date from form data and save to state
    const date = data?.intervention?.date && new Date(data?.intervention?.date);
    setRequestedDateInter(date || null);
  };

  // eslint-disable-next-line no-console
  const onError = (error) => console.log('error', error);

  return (
    <div>
      {isDateWarningFormOpen
        ? (
          <DateWarning
            id="editDate.dateWarning"
            patient={patient}
            date_consult={format(parseDate(survey.intervention.date_consult), 'dd MMMM')}
            theorical_date_acte={format(requestedDateInter).format('dd MMMM')}
            daysToAct={differenceInDays(requestedDateInter, new Date())
            }
            onSubmit={handleDateWarningSubmit}
            onCancel={() => setIsDateWarningFormOpen(false)}
            isLast={true}
          />
        )
        : (
          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(handleInterDateFormSubmit, onError)} className={'custom-formV2 d-flex flex-column'} >
              <CustomFormController
                name={'intervention.date'}
                label={t('surveyPatientForm.intervention.date.label')}
              >
                <DatePicker
                  maxDate={maxDate}
                  minDate={minDate}
                />
              </CustomFormController>
              <ModalButtonsGroup
                onCancel={onModalClose}
                submitLabel={t('form.button.send.label')}
              />
            </form>
          </FormProvider>
        )}
    </div>
  );
}

EditDateForm.propTypes = {
  survey: PropTypes.object,
  patient: PropTypes.object,
  onModalClose: PropTypes.func,
  updateIntervention: PropTypes.func,
};