/* Core exports */
import { connect } from 'react-redux';

import PublicRoutes from './PublicRoutes';

const mapStateToProps = ({ auth }) => {
  return {
    userRole: auth.user?.role,
    loginInfoSuccess: auth.loginInfoSuccess,
  };
};

export default connect(mapStateToProps, null)(PublicRoutes);
