import _ from 'lodash';
import { connect } from 'react-redux';

import { LanguageActions } from '../../../../../redux/languages';
import { PatientActions } from '../../../../../redux/patient';
import ContactFormContainer from './ContactFormContainer';
import { INITIAL_CONTACT } from './FormModel/formInitialValues';

const mapStateToProps = ({ language, medic, display }, props) => {
  const { defaultValues, hideFields } = props;
  const defValues = { ...INITIAL_CONTACT, language_id: medic?.currentMedic?.user?.language_id || 1, ...defaultValues };
  // hide city or birthday city fields
  const defValuess = _.omitBy(defValues, (_val, key) => {
    return (hideFields || [])
      .some(k => `${key}` === `${k}` || `${key}`
        .startsWith(`${k}_`));
  });
  return {
    languageOptions: Object.values(language?.list || {}).map(el => ({
      value:el.id,
      label: el.name,
    })),
    defaultValues: defValuess,
    isClinicalMode: display.isClinicalStudy,
  };
};

const mapDispatchToProps = (dispatch) => ({
  patientsEmailRequest: (email) => dispatch(PatientActions.patientsEmailRequest(email)),
  languagesRequest: () => dispatch(LanguageActions.fetchAll()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactFormContainer);
