import React, { forwardRef, useEffect, useState } from 'react';

const Input = (
  {
    className,
    value,
    type,
    name,
    onChange,
    onKeyPress,
    disabled,
    placeholder,
    onBlur,
    id,
    autoCorrect = 'off',
    autoCapitalize = 'off',
    spellCheck = 'off',
  },
  ref,
) => {
  const [val, setVal] = useState(null);

  useEffect(() => {
    if (value !== val) {
      setVal(value);
    }
  }, [value]);

  const handleChange = (e) => {
    if (disabled) return;
    if (onChange) onChange(e);
    setVal(e.target.value);
  };

  const handleOnBlur = () => {
    if (onBlur) onBlur();
  };

  return (
    <input
      className={className}
      type={type || 'text'}
      name={name}
      placeholder={placeholder || ''}
      value={val || ''}
      onChange={handleChange}
      onKeyPress={onKeyPress}
      disabled={disabled}
      onBlur={handleOnBlur}
      id={id}
      ref={ref}
      autoCorrect={autoCorrect}
      autoCapitalize={autoCapitalize}
      spellCheck={spellCheck}
    />
  );
};

export default forwardRef(Input);
