// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoginLayout-header {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
  background-image: linear-gradient(124deg, #00aecb 9%, #239fd2);
  z-index: -1;
}

.LoginLayout-header img {
  height: 100%;
  width: 20%;
  display: flex;
}

.LoginLayout-container {
  z-index: 2;
  padding-top: 20%;
  padding-bottom: 25px;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  box-sizing: content-box;
}

.LoginLayout-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.LoginLayout-Logo-Container {
  width: 100%;
  margin-top: 20px;
  position: absolute;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media screen and (max-width: 640px) {
  .LoginLayout-container {
    padding-bottom: 20px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/ui/layouts/style.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,MAAM;EACN,OAAO;EACP,SAAS;EACT,gBAAgB;EAChB,8DAA8D;EAC9D,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,UAAU;EACV,aAAa;AACf;;AAEA;EACE,UAAU;EACV,gBAAgB;EAChB,oBAAoB;EACpB,iBAAiB;EACjB,sCAAsC;EACtC,WAAW;EACX,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,kBAAkB;EAClB,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE;IACE,oBAAoB;EACtB;AACF","sourcesContent":[".LoginLayout-header {\n  position: absolute;\n  width: 100%;\n  top: 0;\n  left: 0;\n  bottom: 0;\n  overflow: hidden;\n  background-image: linear-gradient(124deg, #00aecb 9%, #239fd2);\n  z-index: -1;\n}\n\n.LoginLayout-header img {\n  height: 100%;\n  width: 20%;\n  display: flex;\n}\n\n.LoginLayout-container {\n  z-index: 2;\n  padding-top: 20%;\n  padding-bottom: 25px;\n  min-height: 100vh;\n  min-height: calc(var(--vh, 1vh) * 100);\n  width: 100%;\n  box-sizing: content-box;\n}\n\n.LoginLayout-wrapper {\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.LoginLayout-Logo-Container {\n  width: 100%;\n  margin-top: 20px;\n  position: absolute;\n  justify-content: center;\n  align-items: center;\n  display: flex;\n}\n\n@media screen and (max-width: 640px) {\n  .LoginLayout-container {\n    padding-bottom: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
