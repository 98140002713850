import '../style.css';

import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  IconAskForDetails,
  IconCheckbox,
  IconCheckboxFull,
  IconDropDownList,
  IconMoreOptions,
  IconPDF,
  IconRadioMultiQuestionToTable,
  IconTextAnswerSmall,
  IconValidation,
} from '../../../../../assets/img/icons';
import { AbilityContext } from '../../../../../services/casl/Can';
import { CONSENT_COMPONENT, MODEL_NAMES, PERMISSIONS } from '../../../../../services/casl/roles';
import { QUESTION_TYPES } from '../../../../../utils/enum';
import { isNullOrWhitespace } from '../../../../../utils/utils';
import { InputText } from '../../../../components/formV3/controllers';
import RichTextWithDraft from '../../../../components/formV3/controllers/RichTextWithDraft';
import ErrorMessage from '../../../../components/formV3/errors/ErrorMessage';
import { SurveyFormController } from '../../../../components/formV3/innerWrapper';
import { Button } from '../../../../components/styledComponent';
import { arrayToFormName } from '..';
import OnMoreInfo from '../OnMoreInfo';
import Rewording from '../Rewording';

const QuestionHeader = ({ questionTypeList, question, isRewordModalOpen, children, onDelete, onMoreOptions, prefixName, disabled = false, readOnly, onCloseRewording, isConsent, disableRewording = false, hasRewording }) => {
  const ability = useContext(AbilityContext);
  const prefix = arrayToFormName([prefixName, 'translation']);
  const { t } = useTranslation();
  const [isRewordingOpen, setIsRewordingOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const canEditQuestion = useMemo(() =>
    ability.can(PERMISSIONS.UPDATE, { __typename: MODEL_NAMES.CONSENT_COMPONENT, value: CONSENT_COMPONENT.QUESTION })
  , [ability]);

  const type = useMemo(() => questionTypeList && question?.type_id && questionTypeList[question.type_id], [question, questionTypeList]);
  const questionText = question?.translation?.question || '';
  const rewording = question?.translation?.rewording || null;

  const image = (questionType) => {
    if (questionType){
      switch (questionType.value) {
        case QUESTION_TYPES.pdf:
          return IconPDF;
        case QUESTION_TYPES.checkbox_full:
          return IconCheckboxFull;
        case QUESTION_TYPES.radio_multi_questions_to_table:
          return IconRadioMultiQuestionToTable;
        case QUESTION_TYPES.multiple_choice:
          return IconCheckbox;
        case QUESTION_TYPES.text_answer:
          return IconTextAnswerSmall;
        case QUESTION_TYPES.dropdown_list:
          return IconDropDownList;
        case QUESTION_TYPES.validation:
        default:
          return IconValidation;
      }}
  };

  const handleMoreOptionsClick = () => {
    setIsOpen(false);
    if (onMoreOptions) {
      onMoreOptions();
    }
  };

  const handleOnDeleteClick = () => {
    if (onDelete) {
      onDelete();
    }
  };

  const handleCloseRewording = () => {
    setIsOpen(false);
    setIsRewordingOpen(false);
    onCloseRewording && onCloseRewording();
  };

  const handleOpenRewording = () => {
    setIsRewordingOpen(true);
  };

  return (
    <div className="question-type-container d-flex flex-column align-items-start mb-4" >
      <div className="question-type-image-title-container d-flex align-items-center">
        <div className="question-type-image-container">
          <img src={image(type)} alt={type.value || 'image'} />
        </div>
        {
          isConsent
            ? (
              <>
                <div className="question-type-question-title">{questionText}</div>
                {
                  readOnly && rewording !== '' && (
                    <Button
                      type="button"
                      onClick={handleOpenRewording}
                      variant="ghost"
                      className='p-0 m-0'
                    >
                      <img
                        className={`${rewording ? '' : 'invisible'}`}
                        src={IconAskForDetails}
                        alt={t('rewording_card_title')}
                        title={t('rewording_card_title')}
                      />
                    </Button>
                  )}
              </>
            ) : (
              <>
                <SurveyFormController name={arrayToFormName([prefix, 'question'])} isRequiredField={true} hideError >
                  <InputText
                    className={`question-type-question-title ${isNullOrWhitespace(question?.translation?.question || '') ? 'border' : ''}`}
                    readOnly={disabled || !canEditQuestion}
                    placeholder={t(`default_form_data.questions.${type.value || 'validation'}.question`)}
                  />
                </SurveyFormController>
                <div className="question-type-button-and-details-container">
                  <Button
                    type="button"
                    onClick={handleOpenRewording}
                    variant="ghost"
                    className={`p-0 m-0 ${rewording ? '' : 'invisible'}`}
                  >
                    <img
                      src={IconAskForDetails}
                      alt={t('rewording_card_title')}
                      title={t('rewording_card_title')}
                    />
                  </Button>
                  { !disabled && (
                    <Button
                      type="button"
                      onClick={() => setIsOpen(v => !v)}
                      variant="ghost"
                      className='p-0 m-0'
                      style={{ minWidth: '66px' }}
                    >
                      <img
                        className={`${canEditQuestion ? '' : 'invisible'} `}
                        src={IconMoreOptions}
                        alt={t('options')}
                        title={t('options')}
                      />
                    </Button>
                  )}
                  <div className={`${isOpen ? '' : 'd-none'}`}>
                    <OnMoreInfo
                      onDeleteClick={handleOnDeleteClick}
                      onDetailClick={handleOpenRewording}
                      onClickOutside={handleMoreOptionsClick}
                      disableRewording={disableRewording}
                      hasRewording={hasRewording}
                    />
                  </div>
                </div>
              </>
            )}
      </div>
      <ErrorMessage name={arrayToFormName([prefix, 'question'])} />
      <div className={'question-type-question-inside-container'}>
        <div className={'question-type-title-richtext-container'}>
          <SurveyFormController name={arrayToFormName([prefix, 'desc'])} isRequiredField={true}>
            <RichTextWithDraft
              isConsent={isConsent}
              editableOnClick={canEditQuestion}
              disabled={disabled || !canEditQuestion}
            />
          </SurveyFormController>
        </div>
        {children}
      </div>
      { hasRewording && (
        <Rewording
          isOpen={isRewordModalOpen || isRewordingOpen}
          disabled={disabled || !canEditQuestion}
          onExit={handleCloseRewording}
          prefixName={prefixName}
          question={question}
          isConsent={isConsent}
          readOnly={readOnly}
        />
      )}
    </div>
  );
};

export default QuestionHeader;
