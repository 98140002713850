import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { emptyCache, getNewVersion } from '../../utils/cache';

function CatchVersion() {
  const [version, setVersion] = useState(localStorage.getItem('cacheVersion'));
  const location = useLocation();

  useEffect(() => {
    const getVersion = async () => getNewVersion();
    getVersion().then(newVersion => {
      if (newVersion && newVersion !== version) {
        setVersion(newVersion);
        if ((location?.search === '') && !['/splash'].includes(location.pathname) ) {
          emptyCache({ nextCacheVersion: newVersion, hardReload: true });
        }
      }
      // eslint-disable-next-line no-console
    }).catch(console.error);
  }, []);

  return (
    <>
      <Outlet />
    </>
  );
}

export default CatchVersion;

