import { AbilityBuilder, createMongoAbility } from '@casl/ability';
import _ from 'lodash';

import { SIDEMENU_BUTTONS } from '../../ui/screens/medic/menu/Button';
import { MEDIC_ROLES, USER_ROLE } from '../../utils';
import { CLINICAL_STUDY_ROLE } from '../../utils/enum';
import { interpolate } from '../../utils/utils';
import subjectTypeFromGraphql from './subjectTypeFromGraphql';

const PERMISSIONS = {
  MANAGE: 'manage',
  CREATE: 'create',
  READ: 'read',
  UPDATE: 'update',
  DELETE: 'delete',
  ACCESS: 'access',
};

const MODEL_NAMES = {
  PATIENTS: 'patients',
  CONSENT: 'CONSENT',
  MEDIC_TEMPLATES: 'medic_templates',
  QUESTION_TYPE: 'question_types',
  HOME_FILTER: 'HOME_FILTER',
  PATIENT_FIELDS: 'PATIENT_FIELDS',
  CONSENT_COMPONENT: 'CONSENT_COMPONENT',
  SIDEMENU_BUTTONS: 'SIDEMENU_BUTTONS',
  SCREENS: 'SCREENS',
  HOME_MODULES: 'HOME_MODULES',
  PAGES: 'PAGES',
  PATIENT_PAGE: 'PATIENT_PAGE',
};

export const CONSENT_COMPONENT = { COMMENTARY:  'COMMENTARY', CONSENT_HEADER: 'CONSENT_HEADER', QUESTION: 'QUESTION', STEP: 'STEP' };

export const PATIENT_FIELDS = {
  SECURITY_NUMBER: 'security_number',
  BIRTHPLACE: 'birthplace',
};

export const HOME_MODULES = { SIDE_MENU: 'SIDE_MENU', PATIENT_LIST: 'PATIENT_LIST' };

export const PATIENT_PAGE = {
  EDIT_PATIENT_INFO: 'EDIT_PATIENT_INFO',
  EDIT_PATIENT_CONTACT: 'EDIT_PATIENT_CONTACT',
  EDIT_TUTOR_CONTACT: 'EDIT_TUTOR_CONTACT',
  ADD_FORM_TO_PATIENT: 'ADD_FORM_TO_PATIENT',
};

export const SCREENS = {
  PROFIL: { value: 'PROFIL', path: '/profil' },
  HOME: { value: 'HOME', path: '/' },
  STORE: { value: 'STORE', path: '/teams/:teamId/store' },
  FORM:{ value: 'FORM', path: '/teams/:teamId/forms' },
  CREATE_FORM:{ value: 'CREATE_FORM', path: '/teams/:teamId/createform' },
  EDIT_FORM:{ value: 'EDIT_FORM', path: '/teams/:teamId/createform/:templateId' },
};

export const HOME_ACTIONS = {
  CREATE_PATIENT: 'CREATE_PATIENT',
  ADD_CONSENT_TO_PATIENT: 'ADD_CONSENT_TO_PATIENT',
};

// const path ={
//   '$in': [
//     '/',
//     '/teams/:teamId',
//     '/teams/:teamId/patients/:patientId',
//     '/teams/:teamId/forms',
//     '/teams/:teamId/createform',
//     '/teams/:teamId/createform/:templateId',
//     '/patients/:patientId',
//     '/profil',
//     '/preview/consent',
//     '/teams/:teamId/store',
//   ],
// };

const { build } = new AbilityBuilder(createMongoAbility);

const ability = build({ detectSubjectType: subjectTypeFromGraphql }); //defaults to no permissions

const abilityUpdate = (rules) => {
  return ability.update(rules);
};

const addRules = (newRules) => {
  const abilityBuilder = new AbilityBuilder();
  // other rules
  newRules.forEach(rule => {
    abilityBuilder[rule.inverted ? 'cannot' : 'can'](rule.action, rule.subject, !_.isEmpty(rule.conditions) ? rule.conditions : null);
  });

  return abilityBuilder.rules;
};

const generateRulesFromData = (rules, data) => {
  const regexInterpolation = /\$\{(.+?)\}/g; // ${ value }
  return (rules || [])
    .map(ur => {
      const conditions = ur.conditions ? JSON.parse(interpolate(_.isObject(ur.conditions) ? JSON.stringify(ur.conditions) : ur.conditions, data, regexInterpolation)) : null;
      return {
        ...ur,
        conditions,
      };
    });
};

const abilityaddRules = (rules) => abilityUpdate(addRules(rules));

const defineAbilitiesFor = ({ user, medic, patient, isClinicalMode, medicalTeamSettings, userRules }) => {
  const { can, cannot, rules } = new AbilityBuilder();
  setAbilityForUser({ user, medic, patient, isClinicalMode, medicalTeamSettings, can, cannot });
  const additionalRules = userRules ? addRules(userRules) : [];
  abilityUpdate([...rules, ...additionalRules]);
  return rules;
};

const setAbilityForUser = ({ user, medic, patient, isClinicalMode, medicalTeamSettings, can, cannot }) => {
  // can read all medic home modules
  can(PERMISSIONS.DELETE, MODEL_NAMES.PATIENTS);
  // can read all medic home modules
  can(PERMISSIONS.READ, MODEL_NAMES.HOME_MODULES);
  // can read all urls
  can(PERMISSIONS.ACCESS, MODEL_NAMES.PAGES);
  // can read all question types
  can(PERMISSIONS.READ, MODEL_NAMES.QUESTION_TYPE);
  // can read all patient fields
  can([PERMISSIONS.UPDATE, PERMISSIONS.READ], MODEL_NAMES.PATIENT_FIELDS);
  // can read home filter
  can(PERMISSIONS.READ, MODEL_NAMES.HOME_FILTER);
  // can click on side menu buttons
  can(PERMISSIONS.READ, MODEL_NAMES.SIDEMENU_BUTTONS);
  // can read screens => go to path
  can(PERMISSIONS.ACCESS, MODEL_NAMES.SCREENS);
  // can use buttons on patient page (add form)
  can(PERMISSIONS.READ, MODEL_NAMES.PATIENT_PAGE);
  if (MEDIC_ROLES.includes(user?.role)){
    // can add commentary to consent
    can([PERMISSIONS.CREATE, PERMISSIONS.UPDATE, PERMISSIONS.DELETE], MODEL_NAMES.CONSENT_COMPONENT);
    // can create a new medic template
    can([PERMISSIONS.CREATE, PERMISSIONS.UPDATE, PERMISSIONS.DELETE], MODEL_NAMES.CONSENT);
    // can update or delete medic template if owner
    if (medic?.id && medic.role !== CLINICAL_STUDY_ROLE.ADMIN_CLINICAL_STUDY) {
      can([PERMISSIONS.UPDATE, PERMISSIONS.DELETE], MODEL_NAMES.MEDIC_TEMPLATES, { medic_id: { $in: [`${medic.id}`] } });
    }
    // can create a new medic template
    can([PERMISSIONS.CREATE, PERMISSIONS.READ], MODEL_NAMES.MEDIC_TEMPLATES);
    if (!medic?.is_premium) {
      cannot(PERMISSIONS.ACCESS, MODEL_NAMES.PAGES, { path: { $in: [SCREENS.STORE.path] } }).because('You are not premium');
      cannot(PERMISSIONS.ACCESS, MODEL_NAMES.SCREENS, { value: { $in: [SCREENS.STORE.value] } });
    }
    if (medic?.is_secretary) {
      cannot(PERMISSIONS.CREATE, MODEL_NAMES.MEDIC_TEMPLATES);
    } else {
      can(PERMISSIONS.CREATE, MODEL_NAMES.MEDIC_TEMPLATES);
    }
  } else if (USER_ROLE.PATIENT === user?.role) {
    // cannot access to profile page
    cannot(PERMISSIONS.ACCESS, MODEL_NAMES.SCREENS, { path:{ $in: [SCREENS.PROFIL.value] } });
    cannot(PERMISSIONS.READ, MODEL_NAMES.PAGES, { path:{ $in: [SCREENS.PROFIL.path] } });
  }
  if (isClinicalMode || patient?.medicalTeam?.is_clinical_study) {
    // cannot create a new medic template
    cannot(PERMISSIONS.CREATE, MODEL_NAMES.MEDIC_TEMPLATES);
    cannot(PERMISSIONS.UPDATE, MODEL_NAMES.CONSENT_COMPONENT, { value: { $in: [CONSENT_COMPONENT.COMMENTARY, CONSENT_COMPONENT.CONSENT_HEADER] } });
    cannot(PERMISSIONS.CREATE, MODEL_NAMES.CONSENT);
    cannot([PERMISSIONS.UPDATE, PERMISSIONS.DELETE], MODEL_NAMES.CONSENT);
    cannot([PERMISSIONS.UPDATE, PERMISSIONS.READ], MODEL_NAMES.PATIENT_FIELDS, { value: { $in: [PATIENT_FIELDS.SECURITY_NUMBER] } });
    if (medic.role === CLINICAL_STUDY_ROLE.EASY_CONSENT) {
      can(PERMISSIONS.READ, MODEL_NAMES.PATIENT_PAGE, { value: { $in: [PATIENT_PAGE.ADD_FORM_TO_PATIENT] } });
      can(PERMISSIONS.READ, MODEL_NAMES.SIDEMENU_BUTTONS, { value: { $in: [SIDEMENU_BUTTONS.ADD_PATIENT, SIDEMENU_BUTTONS.ADD_FORM_TO_PATIENT] } });
    } else {
      cannot(PERMISSIONS.READ, MODEL_NAMES.PATIENT_PAGE, { value: { $in: [PATIENT_PAGE.ADD_FORM_TO_PATIENT] } });
      cannot(PERMISSIONS.READ, MODEL_NAMES.SIDEMENU_BUTTONS, { value: { $in: [SIDEMENU_BUTTONS.ADD_PATIENT, SIDEMENU_BUTTONS.ADD_FORM_TO_PATIENT] } });
    }
    // cannot(PERMISSIONS.READ, MODEL_NAMES.QUESTION_TYPE, { 'value':{ '$in': ['teams/:teamId/store'] } });
    if (medic.role === CLINICAL_STUDY_ROLE.INVESTIGATOR) {
      // cannot go to store or team store or create form
      cannot(PERMISSIONS.ACCESS, MODEL_NAMES.SCREENS, {
        value : {
          $in: [
            SCREENS.FORM.value,
            SCREENS.CREATE_FORM.value,
            SCREENS.EDIT_FORM.value,
          ],
        },
      });
      cannot(PERMISSIONS.ACCESS, MODEL_NAMES.PAGES, {
        path:{
          $in: [
            SCREENS.FORM.path,
            SCREENS.CREATE_FORM.path,
            SCREENS.EDIT_FORM.path,
          ],
        },
      });
      cannot([PERMISSIONS.CREATE, PERMISSIONS.UPDATE, PERMISSIONS.DELETE], MODEL_NAMES.CONSENT_COMPONENT);
    } else if (medic.role === CLINICAL_STUDY_ROLE.ADMIN_CLINICAL_STUDY) {
      //
      cannot(PERMISSIONS.CREATE, MODEL_NAMES.MEDIC_TEMPLATES);

      can([PERMISSIONS.UPDATE, PERMISSIONS.DELETE], MODEL_NAMES.MEDIC_TEMPLATES);
      can([PERMISSIONS.CREATE, PERMISSIONS.UPDATE, PERMISSIONS.DELETE], MODEL_NAMES.CONSENT_COMPONENT);
      // cannot see patient list
      cannot(PERMISSIONS.READ, MODEL_NAMES.HOME_MODULES, { value: { $in: [HOME_MODULES.PATIENT_LIST] } });
    }
  }
  if (medicalTeamSettings?.is_security_number_hiden) {
    cannot([PERMISSIONS.UPDATE, PERMISSIONS.READ], MODEL_NAMES.PATIENT_FIELDS, { value: { $in: [PATIENT_FIELDS.SECURITY_NUMBER] } });
  }
};

export {
  ability,
  PERMISSIONS,
  MODEL_NAMES,
  abilityaddRules,
  abilityUpdate,
  generateRulesFromData,
  defineAbilitiesFor,
};
