
import i18n from '../../src/services/i18n/i18n';
import { firstLetterRegex, manRegex, regexSecu, unkownRegex, womanRegex } from './regex';

/**
 * Search for obj at a given path in state obj
 * @param {string} path
 * @param {object} obj
 * @returns {object}
 */
export const pathResolver = (path, obj) => {
  return path.split('.').reduce((prev, curr) => {
    return prev ? prev[curr] : null;
  }, obj);
};

/**
 * Validate data and return a simple action object
 * @param {string} type
 * @param {object} [data]
 */
export const createAction = (type, data) => {
  if (data && data.type) {
    throw new Error('Type is a reserved name !');
  }

  if (data) {
    Object.keys(data).forEach((key) => {
      if (data[key] === undefined) {
        throw new Error(`${key} is undefined !`);
      }
    });
  }

  return {
    type,
    ...data,
  };
};


export const getGender = (
  gender,
  maleString = 'male',
  femaleString = 'female',
  unkownString = 'unknown',
) => {
  if (!gender) return gender;
  gender = gender.trim();
  let newGender;
  switch (true) {
    case womanRegex.test(gender):
      newGender = femaleString;
      break;
    case manRegex.test(gender):
      newGender = maleString;
      break;
    case unkownRegex.test(gender):
      newGender = unkownString;
      break;
    default:
      newGender = gender;
      break;
  }
  return newGender;
};

export const editLetterCaseUsers = (params) => {
  if (!params) return params;
  const { firstname, lastname, use_name, ...prevUser } = params;
  let newUser = {};
  if (firstname)
    newUser.firstname = firstname.toLowerCase().replace(firstLetterRegex, (l) => l.toUpperCase());
  if (lastname) newUser.lastname = lastname.toUpperCase();
  if (use_name && use_name !== '') newUser.use_name = use_name.toUpperCase();
  return {
    ...prevUser,
    ...newUser,
  };
};

export const secuNumbFormater = (secu) => {
  if (secu && secu.length === 15) secu = secu.replace(regexSecu, '$1 $2 $3 $4 $5 $6 $7');
  return secu || '';
};

export const getUserProperty = (user, type, overrideIsDoctor = false) => {
  if (!user) return user;
  user = editLetterCaseUsers(user);
  const isDoctor = (user.__typename === 'medics' || 'rpps_number' in user) && user.is_secretary === false;
  const isWoman = 'gender' in user && user.gender.match(womanRegex);
  let title = (overrideIsDoctor === false && isDoctor === true)
    ? i18n.t(`medic_title.${user.title}`)
    : getGender(
      user.gender,
      i18n.t('medic_title.mr') || 'Monsieur',
      i18n.t('medic_title.ms') || 'Madame',
      '',
    );
  if (overrideIsDoctor && title === 'unknown') title = '';
  switch (type) {
    case 'title':
      return title;
    case 'titledName':
      return `${title ? `${title} ` : ''}${
        user.use_name ? `${user.use_name}` : user.lastname || ''
      }`;
    case 'fullContactName':
      return isDoctor
        ? `${user.firstname ? `${user.firstname} ` : ''}${user.lastname || ''}`
        : `${user.firstname ? `${user.firstname} ` : ''}${
          user.use_name ? `${user.use_name}` : user.lastname || ''
        }`;
    case 'contactNameWithTitle':
      return isDoctor
        ? `${title ? `${title} ` : ''}${user.firstname ? `${user.firstname} ` : ''}${
          user.lastname || ''
        }`
        : `${title ? `${title} ` : ''}${user.firstname ? `${user.firstname} ` : ''}${
          user.use_name ? `${user.use_name}` : user.lastname || ''
        }`;
    case 'fullDisplayName':
      return isDoctor
        ? `${user.firstname ? `${user.firstname} ` : ''}${user.lastname || ''}`
        : `${user.firstname ? `${user.firstname} ` : ''}${
          user.use_name ? `${user.use_name} ${i18n.t(isWoman ? 'born_she' : 'born_he')} ` : ''
        }${user.lastname || ''}`;
    case 'birthName':
      return user.lastname;
    case 'birthNameLabel':
      return user.use_name && `${i18n.t(isWoman ? 'born_she' : 'born_he')} ${user.lastname}`;
    default:
      return '';
  }
};

export const prepareUserForDisplay = (user, types = []) => {
  if (!user) return user;
  user = editLetterCaseUsers(user);
  const isDoctor = 'rpps_number' in user;
  const isWoman = 'gender' in user && user.gender && user.gender.match(womanRegex);
  types.forEach((type) => {
    switch (type) {
      case 'title':
        user['title'] = isDoctor
          ? i18n.t(`medic_title.${user.title}`)
          : getGender(
            user.gender,
            i18n.t('medic_title.mr', 'Monsieur'),
            i18n.t('medic_title.ms', 'Madame'),
            i18n.t('unkown_abbreviation', ''),
          );
        break;
      case 'titledName':
        const title = isDoctor
          ? i18n.t(`medic_title.${user.title}`)
          : getGender(
            user.gender,
            i18n.t('mister_abbreviation', 'Monsieur'),
            i18n.t('miss_abbreviation', 'Madame'),
            i18n.t('unkown_abbreviation', ''),
          );
        user['titledName'] = `${title ? `${title} ` : ''}${
          user.use_name ? `${user.use_name}` : user.lastname || ''
        }`;
        break;
      case 'fullContactName':
        user['fullContactName'] = isDoctor
          ? `${user.firstname ? `${user.firstname} ` : ''}${user.lastname || ''}`
          : `${user.firstname ? `${user.firstname} ` : ''}${
            user.use_name ? `${user.use_name}` : user.lastname || ''
          }`;
        break;
      case 'fullDisplayName':
        user['fullDisplayName'] = isDoctor
          ? `${user.firstname ? `${user.firstname} ` : ''}${user.lastname || ''}`
          : `${user.firstname ? `${user.firstname} ` : ''}${
            user.use_name ? `${user.use_name} ${i18n.t(isWoman ? 'born_she' : 'born_he')} ` : ''
          }${user.lastname || ''}`;
        break;
      case 'birthName':
        user['birthName'] = user.lastname;
        break;
      case 'birthNameLabel':
        user['birthNameLabel']
          = user.use_name && `${i18n.t(isWoman ? 'born_she' : 'born_he')} ${user.lastname}`;
        break;
      default:
    }
  });
  return user;
};

const string_to_slug = (str) => {
  str = str.replace(/(^\s{0,10}|\s{0,10}$)/g, ''); // trim
  str = str.toLowerCase();
  // remove accents, swap ñ for n, etc
  var from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;';
  var to = 'aaaaeeeeiiiioooouuuunc------';
  for (var i=0, l=from.length ; i<l ; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }
  str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes
  return str;
};

export { string_to_slug };
