import React from 'react';

import AuthLayoutHeader from './AuthLayoutHeader';
import AuthLayoutWrapper from './AuthLayoutWrapper';

const AuthLayout = ({ children }) => {
  return (
    <div className={'authlayout-font-container'}>
      <AuthLayoutHeader />
      <AuthLayoutWrapper children={children} />
    </div>
  );
};

export default AuthLayout;
