import './style.css';

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { IconAskForDetails, IconDelete } from '../../../../assets/img/icons';
import { PrimaryButton } from '../../../components/buttons';
import Card from '../../../components/card';

const OnMoreInfo = ({ onDetailClick, onDeleteClick, onClickOutside, disableRewording, hasRewording }) => {
  const { t } = useTranslation();
  const active = '';

  const handleClickOutside = (event) => {
    if (
      !event.target.classList.contains('question-type-on-more-info-container')
      && !event.target.classList.contains('ec-primary-button-text')
      && onClickOutside
    )
      onClickOutside();
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleDetailsClick = () => {
    if (hasRewording && onDetailClick && !disableRewording) {
      onDetailClick();
    }
  };

  const handleDeleteClick = () => {
    if (onDeleteClick) {
      onDeleteClick();
    }
  };

  return (
    <div className="question-type-on-more-info-container">
      <Card>
        <PrimaryButton
          active={active === 'details'}
          image={IconAskForDetails}
          text={t('form_screen_write_details')}
          onClick={handleDetailsClick}
          hidden={disableRewording}
          disabled={!hasRewording}
          type="button"
        />
        <PrimaryButton
          active={active === 'delete'}
          image={IconDelete}
          text={t('form_screen_delete_question')}
          onClick={handleDeleteClick}
          type="button"
        />
      </Card>
    </div>
  );
};

export default OnMoreInfo;
