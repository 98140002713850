
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useFormContext } from 'react-hook-form';


const Input = ({
  name,
  rules,
  inputPreset,
  showPswdHelper,
  hidden,
  isLoading,
  success,
  defaultValue,
  className,
  containerClassName,
  readOnly,
  onBlur,
  control,
  preset,
  ...extraInputProps
}) => {
  const methods = useFormContext();
  const { register, formState: { errors } } = methods;

  return (
    <input
      className={`${className || 'createPatient-Input'}`}
      aria-invalid={_.get(errors, 'name') ? 'true' : 'false'}
      readOnly={readOnly}
      {...preset}
      {...extraInputProps}
      {...register(name, rules)}
      defaultValue={defaultValue}
      onBlur={onBlur}
    />
  );
};

Input.propTypes = {
  register: PropTypes.func,
  name: PropTypes.string,
  control: PropTypes.object,
  defaultValue: PropTypes.string,
  rules: PropTypes.object,
  inputProps: PropTypes.object,
  inputPreset: PropTypes.oneOf(['security_number', 'password', 'email', 'postcode', 'rpps_number']),
  hidden: PropTypes.bool,
  isLoading: PropTypes.bool,
  error: PropTypes.bool,
  success: PropTypes.bool,
  type: PropTypes.oneOf(['button', 'checkbox', 'color', 'date', 'datetime-local', 'email', 'file', 'hidden', 'image', 'month', 'number', 'password', 'radio', 'range', 'reset', 'search', 'submit', 'tel', 'text', 'time', 'url', 'week']),
};

export default Input;
