import { put, takeLatest } from 'redux-saga/effects';

import { CityActions } from '../city';
import { ClinicActions } from '../clinic';
import { DisplayActions } from '../display';
import { MedicActions } from '../medic';
import { MedicTemplateActions } from '../medicTemplates';
import { PatientActions } from '../patient';
import { SurveyActions } from '../survey';
import { TemplateActions } from '../template';
import { types } from './actions';

/**
 * Start Fetching all patient for the current medic
 * @returns {Generator<*, void, ?>}
 */
function* resetReducers() {
  yield put(MedicActions.resetReducer());
  yield put(ClinicActions.resetReducer());
  yield put(CityActions.resetReducer());
  yield put(PatientActions.resetReducer());
  yield put(SurveyActions.resetReducer());
  yield put(TemplateActions.resetReducer());
  yield put(MedicTemplateActions.resetReducer());
  yield put(DisplayActions.resetReducer());
}

// eslint-disable-next-line import/no-anonymous-default-export
export default [takeLatest(types.RESET_REDUCERS, resetReducers)];
