import './style.css';

import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { USER_ROLE } from '../../../utils';
import Modal from '../../components/modal/Modal';
import { ModalButtons } from '../buttons';
import Checkbox from '../form/Checkbox';


const CGU = 1;
const RGPD = 2;
const DONE = 3;

const INITIAL_ERROR_STATE = {
  noError: false,
  show: false,
};

const LegalModal = ({
  user,
  deleteCurrentPatient,
  updateCGU,
  updateRGPD,
  isLoginFromQrCode,
  success,
  token,
  logout,
}) => {
  const { t } = useTranslation();
  const [isDeny, setIsDeny]= useState(false);
  const [isCgu, setIsCgu]= useState(false);
  const [error, setError] = useState(INITIAL_ERROR_STATE);
  const [currentState, setCurrentState] = useState(0);
  const role = user.role;

  useEffect(() => {
    setIsCgu(currentState === CGU);
  }, [currentState]);

  useEffect(() => {
    let newCurrentStep = 0;
    if (user){
      if ((role === USER_ROLE.PATIENT && user.edit_info === false) || (user.renew_pwd === true && isLoginFromQrCode === false)){
        newCurrentStep = 0; //don't show legals
      } else if (user.accepted_cgu === false) {
        newCurrentStep = CGU;
      } else if (user.accepted_rgpd === false) {
        newCurrentStep = RGPD;
      } else {
        newCurrentStep = DONE;
      }
    }
    setCurrentState(newCurrentStep);
  }, [
    user && user.accepted_cgu,
    user && user.accepted_rgpd,
    user && user.edit_info,
    user && user.renew_pwd,
    success,
  ]);

  useEffect(() => {
    let newCurrentStep = 0;
    if (user){
      if ((role === USER_ROLE.PATIENT && user.edit_info === false) || (user.renew_pwd === true && isLoginFromQrCode === false)){
        newCurrentStep = 0; //don't show legals
      } else if (user.accepted_cgu === false) {
        newCurrentStep = CGU;
      } else if (user.accepted_rgpd === false) {
        newCurrentStep = RGPD;
      } else {
        newCurrentStep = DONE;
      }
    }
    setCurrentState(newCurrentStep);
  }, [
    user && user.accepted_cgu,
    user && user.accepted_rgpd,
    user && user.edit_info,
    user && user.renew_pwd,
    success,
  ]);

  const handleDeny = () => {
    setIsDeny(true);
  };
  const handleCancelDeny = () => {
    setIsDeny(false);
  };

  const onConfirmDeny = () => {
    deleteCurrentPatient();
    logout();
  };

  const handleNext = () => {
    switch (currentState) {
      case CGU:
        if (error.noError) {
          updateCGU(user.id);
          setError(INITIAL_ERROR_STATE);
        } else if (!error.show) {
          setError({ ...error, show: true });
        }
        break;
      case RGPD:
        if (error.noError) {
          updateRGPD(user.id);
          setError(INITIAL_ERROR_STATE);
        } else if (!error.show) {
          setError({ ...error, show: true });
        }
        break;
      case DONE:
        break;
      default:
        break;
    }
  };

  const onCheckboxClick = (e) => {
    const value = e.target.value;
    setError({
      noError: value,
      show: !value,
    });
  };

  const urlParams = new URLSearchParams();
  urlParams.set('access_token', token);

  return (
    <div>
      <Modal title={isCgu ? t('accept_cgu') : t('accept_rgpd')} hideCloseBtn scrollable isOpen={[CGU, RGPD].includes(currentState) } >
        <>
          <div className={'w-100 d-flex justify-content-start my-2'}>
            <Checkbox
              label={<Trans
                i18nKey={isCgu ? 'cgu_checkbox_label_withlink' : 'rgpd_checkbox_label_withlink'}
                values={{ href: `${process.env.REACT_APP_SIGN_API_URL}/legals/${isCgu ? 'cgu' : 'rgpd' }?${urlParams.toString()}` }}
                components={{ a: <a /> }} />
              }
              className="ec-checkbox-text-consent-header"
              onChange={onCheckboxClick}
              value={error.noError}
            />
          </div>
          { error.show && (
            <p className={'ec-question-basics-common-red-text'}>{isCgu ? t('cgu_checkbox_error') : t('rgpd_checkbox_error')}</p>
          )}
        </>
        <ModalButtons
          firstText={t('refused')}
          secondText={t('accepted')}
          onFirstClick={handleDeny}
          onSecondClick={handleNext}
        />
      </Modal>
      <Modal hideCloseBtn isOpen={[CGU, RGPD].includes(currentState) && isDeny} >
        <p className={'patient-deny-cgu-text'}> {t('cgu_deny')} </p>
        <ModalButtons
          firstText={t('yes')}
          secondText={t('no')}
          onFirstClick={onConfirmDeny}
          onSecondClick={handleCancelDeny}
        />
      </Modal>
      <Outlet />
    </div>
  );
};

export default LegalModal;
