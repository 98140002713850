import './style.css';

import _ from 'lodash';
import React, { useEffect, useState } from 'react';

import SearchBar from '../../searchBar';
import InfiniteLoadList from './InfiniteLoadList';
import ItemSkeleton from './ItemSkeleton';
import ListHeader from './ListHeader';

const searchBarTags = [
  {
    title: 'surveylist.filters.date.title',
    key: 'date',
    type: 'date',
    enumerations: null,
  },
  {
    title: 'surveylist.filters.type.title',
    enumerations: null,
    type: 'string',
    key: 'type',
  },
  {
    title:  'surveylist.filters.number.title',
    key: 'number',
    type: 'string',
    enumerations: null,
  },
  {
    title:  'surveylist.filters.status.title',
    key: 'status',
    type: 'string',
    enumerations: null,
  },
  {
    title:  'surveylist.filters.title.title',
    key: 'title',
    type: 'string',
    enumerations: null,
  },
];

const configStatus = [
  {
    id: 0,
    type: {
      value: 'En cours',
      db: 'Sent',
    },
  },
  {
    id: 1,
    type: {
      value: 'Refusé',
      db: 'Refused',
    },
  },
  {
    id: 2,
    type: {
      value: 'Signé',
      db: 'Accepted',
    },
  },
];

const List = ({
  logginUserRole,
  bearerToken,
  surveys,
  medic,
  patient,
  onClick,
  onResetIsSigned,
  tutors,
  isPatient,
  isSigned,
  isLoading,
  onSearch,
  types,
  preselectedID,
  resetAutoLoginSurveyID,
  isClinicalMode,
}) => {
  const [surveyFiltred, setSurveyFiltred] = useState([]);
  const [smallScreen, setSmallScreen] = useState(false);
  const [typesFormated, setTypesFormated] = useState([]);
  const [hasCounterSign, setHasCounterSign] = useState(false);
  const [chunk, setChunk] = useState();
  const [isSearch, setIsSearch] = useState(false);

  useEffect(() => {
    onResize();
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  });

  useEffect(() => {
    if (surveys.length) {
      /*
        we need to know if surveys includes counter sign survey type in order to adjust
        display of buttons in item
      */
      let counterSignCounter = 0;
      setSurveyFiltred(
        surveys.map((s, index) => {
          if (s.survey_type && s.survey_type.should_countersign) counterSignCounter += 1;
          return {
            is_signed: s.is_signed,
            status: s.status,
            number: _.get(s, 'intervention.number', index),
            date: _.get(s, 'intervention.date'),
            type: _.get(s, 'survey_type.value'),
            id: _.get(s, 'intervention_id'),
            title: s.title,
          };
        }),
      );
      setHasCounterSign(counterSignCounter > 0);
    }
  }, [surveys, JSON.stringify(surveys)]);

  useEffect(() => {
    if (types.length !== typesFormated.length) {
      setTypesFormated(types.map((t) => ({ id: t.id, type: { value: t.value, db: t.value } })));
    }
  }, [types && types.length]);

  const onResize = () => {
    const width = document.body.clientWidth;
    setSmallScreen(width < 865);
  };

  const handleOnResetIsSigned = () => {
    if (onResetIsSigned) {
      onResetIsSigned();
    }
  };

  const reformatSurveys = (surveys) => {
    let count = _
      .chain(surveys)
      .groupBy('intervention.number')
      .mapValues(o => {
        if (Array.isArray(o) && o.length > 1){
          return o.sort((a, b) => a.tutored_id - b.tutored_id);
        } else {
          return o;
        }
      })
      .value();
    count = Object.values(count);
    return isSearch
      ? count
      : count.sort(function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
        const diff = new Date(_.get(b, '0.intervention.date')) - new Date(_.get(a, '0.intervention.date'));
        return diff !== 0 ? diff : _.get(b, '0.id', 0) - _.get(a, '0.id', 0);
      });
  };

  const handleOnSearch = (searched) => {
    setIsSearch(Array.isArray(searched) && searched.length);
    onSearch(searched);
  };

  useEffect(() => {
    const reformatedSurveys = reformatSurveys(surveys);
    if ( Array.isArray(reformatedSurveys)) {
      setChunk(_.chunk(reformatedSurveys, 10));
    }
    return function cleanUp(){
      setChunk();
    };
  }, [JSON.stringify(surveys)]);

  return (
    <>
      <div className={'infopatient-searchbar-container'}>
        <SearchBar
          list={surveyFiltred}
          onSearch={handleOnSearch}
          tags={searchBarTags}
          searchStyle={{ width: '100%', backgroundColor: '#f4f6f8' }}
          wrapperStyle={{ display: 'flex', backgroundColor: '#f4f6f8' }}
          dropdownStyle={{ marginTop: '40px' }}
          selectTags={{ Status: configStatus, Type: typesFormated }}
        />
      </div>
      <div className={'w-100 d-flex flex-column infopatient-list-intervention'}>
        <ListHeader
          isPatient={isPatient}
          isAutonome={isLoading || (!tutors || (Array.isArray(tutors) && !tutors.length))}
        />
        { isLoading || !chunk ? (
          _.times(3, (i) => (
            <div
              key={`info-patient-survey-number-${i}`}
              className={i++ % 2 === 0 ? 'item-card-patient-grey' : 'item-card-patient'}
            >
              <ItemSkeleton key={`patient-list-item-${i}`} skeleton />
            </div>
          ))
        ) : (
          <InfiniteLoadList
            chunk={chunk}
            logginUserRole={logginUserRole}
            bearerToken={bearerToken}
            smallScreen={smallScreen}
            medic={medic}
            patient={patient}
            onClick={onClick}
            tutors={tutors}
            isPatient={isPatient}
            isSigned={isSigned}
            onResetIsSigned={handleOnResetIsSigned}
            preselectedID={preselectedID}
            hasCounterSign={hasCounterSign}
            resetAutoLoginSurveyID={resetAutoLoginSurveyID}
            isClinicalMode={isClinicalMode}
          />
        )}
      </div>
    </>
  );
};

export default List;
