import '../AuthLayoutData/style.css';

import React from 'react';
import { useNavigate } from 'react-router-dom';

import AuthLayoutData from '../AuthLayoutData';
import AuthLayoutLogo from '../AuthLayoutData/AuthLayoutLogo';

const AuthLayoutHeader = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/');
  };

  return (
    <div className={'authlayout-header'} >
      <div className={'authlayout-header-wrapper'}>
        <AuthLayoutLogo onClick={handleClick}/>
        <AuthLayoutData />
      </div>
    </div>
  );};

export default AuthLayoutHeader;
