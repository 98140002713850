import './style.css';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CONTENT_TYPE_MAPPER } from '../../../../../utils/enum';
import { FileUploader, InputCheckbox } from '../../../../components/formV3/controllers';
import { SurveyFormController } from '../../../../components/formV3/innerWrapper';
import { ACTIONS } from '../../../../components/patientDetails/list/DocumentChoice';
import SurveyDocumentViewer from '../../../../components/patientDetails/list/SurveyDocumentViewer';
import { arrayToFormName } from '..';
import QuestionHeader from '../QuestionHeader';

const PDF = ({ question, onDelete, prefixName, index, nestIndex, disabled, currentSurvey, isConsent, consentPreview }) => {
  const PDF_FORM_NAME = arrayToFormName([prefixName, 'translation', 'data']);
  const IS_READ_FORM_NAME = arrayToFormName([prefixName, 'answer', 'isRead']);
  const { t } = useTranslation();
  const methods = useFormContext();
  const pdfField = useWatch({
    control: methods.control,
    name: PDF_FORM_NAME, // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
  });
  const [file, setFile] = useState(null);
  const [showPDF, setShowPDF] = useState(true);
  const [pdfLoading, setPdfLoading] = useState(false);

  useEffect(() => {
    if (pdfField && pdfField.pdf && pdfField.pdf[0]) {
      methods.setValue(PDF_FORM_NAME, { pdf: pdfField.pdf[0], oid: -1 });
      setFile({ file: pdfField.pdf[0] });
    }
  }, [pdfField && pdfField.pdf]);

  useEffect(() => {
    if (isConsent){
      methods.setValue(IS_READ_FORM_NAME, false);
      methods.setValue(arrayToFormName([PDF_FORM_NAME, 'isRead']), false);
    }
  }, []);

  const onRemoveFile = () => {
    methods.setValue(PDF_FORM_NAME, { pdf: null, oid: -1 });
    setFile(null);
  };

  const onHideFile = () => {
    setShowPDF(!showPDF);
  };

  const handleLastClick = () => {
    if (isConsent){
      methods.setValue(IS_READ_FORM_NAME, true);
      methods.setValue(arrayToFormName([PDF_FORM_NAME, 'isRead']), true);
    }
  };

  return (
    <QuestionHeader
      question={question}
      onDelete={onDelete}
      prefixName={prefixName}
      index={index}
      isConsent={isConsent}
      nestIndex={nestIndex}
      disabled={disabled}
      disableRewording

    >
      <div className='ec-question-pdf-container'>
        {isConsent
          ? (
            <SurveyFormController
              controlClassName={pdfLoading || file ? 'd-none' : null}
              name={IS_READ_FORM_NAME}
            >
              <InputCheckbox disabled className='d-none' />
            </SurveyFormController>
          ) : <>
            { consentPreview
              ? null
              : <>
                <SurveyFormController
                  controlClassName={pdfLoading || file ? 'd-none' : null}
                  name={`${PDF_FORM_NAME}.pdf`}
                >
                  <FileUploader
                    buttonTitle={t('upload_document')}
                    buttonClassName='ec-question-pdf-button'
                    disabled={disabled}
                    accept={`${CONTENT_TYPE_MAPPER.PDF}`}
                  />
                </SurveyFormController>
                <p className={`ec-question-pdf-loading-text ${!pdfLoading ? 'd-none' : ''}`}>
                  {t('document_loading')}
                </p>
                <div className='ec-question-pdf-buttons-container'>
                  <button
                    disabled={disabled}
                    className={`ec-question-pdf-button ${!file ? 'd-none' : ''}`}
                    onClick={onRemoveFile}
                    type='button'
                  >
                    {t('delete_document')}
                  </button>
                  <button
                    disabled={disabled}
                    className={`ec-question-pdf-button ${!file ? 'd-none' : ''}`}
                    onClick={onHideFile}
                    type='button'
                  >
                    {t(showPDF ? 'hide_document' : 'show_document')}
                  </button>
                </div>
              </>
            }
          </>
        }
        <SurveyDocumentViewer
          onLastClick={handleLastClick}
          survey={currentSurvey}
          disabled={disabled}
          customNavigation
          hidden={!showPDF}
          ioid={pdfField && pdfField.oid}
          forceDocument={file || (pdfField?.pdf && { file: pdfField.pdf }) || false}
          showBtn={isConsent}
          isConsent={isConsent}
          action={ACTIONS.QUESTION_PDF}
          onFileLoaded={() => setPdfLoading(false)}
        />
      </div>
    </QuestionHeader>
  );
};

export default PDF;

PDF.propTypes = {
  question: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  prefixName: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  nestIndex: PropTypes.number,
};
