

import _ from 'lodash';

import { regexPostcode } from '../../../../../../utils/regex';
import { isNullOrWhitespace } from '../../../../../../utils/utils';
import yup from '../../../../../../utils/yup';

const yupSchema = (isVisible) => {
  const omitList = Object.keys(_.omitBy(isVisible, el => el === true));
  let obj = {
    phone_number: yup
      .string()
      .validMobile({ message: 'patientForms.phone_number.rules.format', excludeEmptyString: true })
      .required('patientForms.phone_number.rules.required'),
    language_id: yup.string(),
    city_postcode: yup
      .string()
      .matches(regexPostcode, { message: 'patientForms.city_postcode.rules.format', excludeEmptyString: true })
    // .required('patientForms.city_postcode.rules.required')
      .nullable()
      .trim(),
    city_id: yup
      .string()
      .when(['city_postcode', 'city'], (postcode, city, schema) => {
        const formatedPostcode = postcode && postcode.replace(/[_ ]/g, '');
        if (isNullOrWhitespace(city) && formatedPostcode.length) {
          return schema.required('patientForms.birthplace_id.rules.required');
        }else {
          return schema.nullable();
        }
      }),
    city: yup
      .string()
      .nullable()
      .trim(),
    email: yup
      .string()
      .email('patientForms.email.rules.email')
      .required('patientForms.email.rules.required'),
    email_confirm: yup
      .string()
      .email('patientForms.email_confirm.rules.email')
      .required('patientForms.email_confirm.rules.required')
      .when('email', {
        is: email => (email && email.length > 0 ? true : false),
        then: yup.string().oneOf([yup.ref('email')], 'patientForms.email_confirm.rules.must_be_the_same'),
      }),
  };
  return yup.object().shape(_.omit(obj, omitList));
};

export default yupSchema;