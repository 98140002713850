// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.infopatient-details-card-container {
  display: flex;
  position: absolute;
  flex-direction: column;
  border-radius: 8px;
  padding-top: 15px;
  padding-left: 10px;
  padding-bottom: 15px;
  background-color: #ffffff;
  margin-top: 100px;
  width: 265px;
  object-fit: contain;
  box-shadow: 0 2px 40px 0 rgba(115, 115, 139, 0.5);
}

.infopatient-details-card-button-active {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.infopatient-details-card-button-active:hover {
  background-color: #f8f8f8;
  border-radius: 8px;
}

.infopatient-detail-card-button-image {
  width: 20px;
  height: 20px;
}

.infopatient-details-card-button-text {
  margin-left: 9px;
  margin-top: 17px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.33;
  color: #393d52;
}
`, "",{"version":3,"sources":["webpack://./src/ui/screens/infoPatient/cardButton/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,sBAAsB;EACtB,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;EAClB,oBAAoB;EACpB,yBAAyB;EACzB,iBAAiB;EACjB,YAAY;EACZ,mBAAmB;EACnB,iDAAiD;AACnD;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,iBAAiB;EACjB,cAAc;AAChB","sourcesContent":[".infopatient-details-card-container {\n  display: flex;\n  position: absolute;\n  flex-direction: column;\n  border-radius: 8px;\n  padding-top: 15px;\n  padding-left: 10px;\n  padding-bottom: 15px;\n  background-color: #ffffff;\n  margin-top: 100px;\n  width: 265px;\n  object-fit: contain;\n  box-shadow: 0 2px 40px 0 rgba(115, 115, 139, 0.5);\n}\n\n.infopatient-details-card-button-active {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n\n.infopatient-details-card-button-active:hover {\n  background-color: #f8f8f8;\n  border-radius: 8px;\n}\n\n.infopatient-detail-card-button-image {\n  width: 20px;\n  height: 20px;\n}\n\n.infopatient-details-card-button-text {\n  margin-left: 9px;\n  margin-top: 17px;\n  font-size: 18px;\n  font-weight: bold;\n  line-height: 1.33;\n  color: #393d52;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
