import { detectSubjectType } from '@casl/ability';

const subjectTypeFromGraphql = (subject) => {
  if (subject && typeof subject === 'object' && subject.__typename) {
    return subject.__typename;
  }

  return detectSubjectType(subject);
};

export default subjectTypeFromGraphql;