import { addDays, differenceInDays, format } from 'date-fns';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import DateWarning from './DateWarning';
import SupInfoForm from './SupInfoForm';
import SurveyPatientForm from './SurveyPatientForm';
import SurveyTemplateForm from './SurveyTemplateForm';
import TutorForm from './TutorForm';
import UploadForm from './UploadForm';
import { STEPS } from './utils/steps/definition';


function RenderStepContent({ activeStep, urlParams, forms, selectedPatient, selectedMedicalTeamId, completeFormStep, previousFormStep, patient }) {
  const { t } = useTranslation();

  switch (activeStep) {
    case STEPS.PATIENT:
      return (
        <SurveyPatientForm
          id="createSurvey.patient"
          onSubmit={completeFormStep}
          onCancel={previousFormStep}
          urlParams={urlParams}
          patient={patient}
        />
      );
    case STEPS.DATE_WARNING:
      const dateConsult = _.get(forms, 'createSurvey.patient.intervention.date_consult');
      const interventionDate = _.get(forms, 'createSurvey.patient.intervention.date');
      return (
        <DateWarning
          id="createSurvey.dateWarning"
          patient={selectedPatient}
          date_consult={dateConsult ? format(dateConsult, 'dd MMMM') : ''}
          theorical_date_acte={dateConsult ? format(addDays(dateConsult, 16), 'dd MMMM') : ''}
          daysToAct={differenceInDays(interventionDate, new Date())}
          onSubmit={completeFormStep}
          onCancel={previousFormStep}
        />
      );
    case STEPS.TEMPLATE:
      return (
        <SurveyTemplateForm
          id="createSurvey.surveyTemplateForm"
          onSubmit={completeFormStep}
          onCancel={previousFormStep}
          selectedMedicalTeamId={selectedMedicalTeamId}
        />
      );
    case STEPS.SUP_INFO:
      return (
        <SupInfoForm
          id="createSurvey.supInfoForm"
          onSubmit={completeFormStep}
          onCancel={previousFormStep}
          template={_.get(forms, 'createSurvey.surveyTemplateForm.template.0')}
        />
      );
    case STEPS.UPLOAD:
      return (
        <UploadForm
          id="createSurvey.paper"
          onSubmit={completeFormStep}
          onCancel={previousFormStep}
        />
      );
    case STEPS.PATIENT_REPRESENTATIVE:
      return (
        <TutorForm
          formField="createSurvey.tutorsRetained"
          onSubmit={completeFormStep}
          onCancel={previousFormStep}
          urlParams={urlParams}
          forms={forms}
        />
      );
    default:
      return <div>{t('survey_create_success')}</div>;
  }
}

RenderStepContent.propTypes = {
  activeStep: PropTypes.string,
  urlParams: PropTypes.object,
  forms: PropTypes.object,
  selectedPatient: PropTypes.object,
  selectedMedicalTeamId: PropTypes.string,
  completeFormStep: PropTypes.func,
  previousFormStep: PropTypes.func,
  patient: PropTypes.object,
};

export default RenderStepContent;
