// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.form-item-error-text-dropdown {
  border-radius: 2px;
  font-size: 17, 9px;
  margin-left: 10px;
  margin-top: 50px;
  margin-bottom: 5px;
  font-weight: normal;
  letter-spacing: 0.45px;
  text-align: left;
  color: red;
}

.dropdown-list-input-text {
  width: 789px;
  height: 40px;
  margin-right: 5px;
  margin-left: 10px;
  border-radius: 8px;
  background-color: #ffffff;
  outline: none;
  display: flex;
  align-items: center;
  padding-left: 2px;
  font-size: 18px;
  font-weight: normal;
  color: #79828b;
}

.dropdown-list-input-text:hover {
  border: solid 1px #cfcfcf;
}

.dropdown-list-input-text::after {
  content: '';
  position: absolute;
  right: 10px;
  top: 16px;
  border: 7px solid transparent;
  border-color: #fff transparent transparent transparent;
}
`, "",{"version":3,"sources":["webpack://./src/ui/screens/createFormV2/QuestionTypes/DropdownList/style.css"],"names":[],"mappings":";;AAEA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,iBAAiB;EACjB,gBAAgB;EAChB,kBAAkB;EAClB,mBAAmB;EACnB,sBAAsB;EACtB,gBAAgB;EAChB,UAAU;AACZ;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,iBAAiB;EACjB,iBAAiB;EACjB,kBAAkB;EAClB,yBAAyB;EACzB,aAAa;EACb,aAAa;EACb,mBAAmB;EACnB,iBAAiB;EACjB,eAAe;EACf,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,WAAW;EACX,SAAS;EACT,6BAA6B;EAC7B,sDAAsD;AACxD","sourcesContent":["\n\n.form-item-error-text-dropdown {\n  border-radius: 2px;\n  font-size: 17, 9px;\n  margin-left: 10px;\n  margin-top: 50px;\n  margin-bottom: 5px;\n  font-weight: normal;\n  letter-spacing: 0.45px;\n  text-align: left;\n  color: red;\n}\n\n.dropdown-list-input-text {\n  width: 789px;\n  height: 40px;\n  margin-right: 5px;\n  margin-left: 10px;\n  border-radius: 8px;\n  background-color: #ffffff;\n  outline: none;\n  display: flex;\n  align-items: center;\n  padding-left: 2px;\n  font-size: 18px;\n  font-weight: normal;\n  color: #79828b;\n}\n\n.dropdown-list-input-text:hover {\n  border: solid 1px #cfcfcf;\n}\n\n.dropdown-list-input-text::after {\n  content: '';\n  position: absolute;\n  right: 10px;\n  top: 16px;\n  border: 7px solid transparent;\n  border-color: #fff transparent transparent transparent;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
