import '../style.scss';

import PropTypes from 'prop-types';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';

function InputGroupedCheckbox({ name, rules = { required: false }, options, defaultValue, containerClassName, readOnly = false, className, ...rest }) {
  const { control } = useFormContext(); // retrieve all hook methods
  const { field: { onChange, value } } = useController({
    name,
    control,
    rules: { ...rules },
    defaultValue: defaultValue,
  });

  const handleChange = (e) => {
    if (readOnly) return;
    const { target: { value: checkboxValue } } = e;
    if (value && value.includes(checkboxValue)){
      onChange((value || []).filter(el => el !== checkboxValue));
    } else {
      onChange(Array.isArray(value) ? [...value, checkboxValue] : [checkboxValue]) ;
    }
  };

  return (
    options.map(({ value: v, label: l }, index) => {
      return (
        <div key={`multiple_choices_question_type_choice_${v}_${index}`} className={containerClassName} >
          <label htmlFor={`${v}`} className={'checkboxContainer'}>
            <input
              id={`${v}`}
              {...rest}
              type="checkbox"
              onChange={(e) => handleChange(e, v)}
              checked={(value || []).includes(v)}
              value={`${v}`}
              disabled={readOnly}
              className={`${className ||''} ${readOnly ? 'noHover' : ''}`}
            />
            <span className="label" >{l}</span>
          </label>
          <br />
        </div>
      );
    })
  );
}

InputGroupedCheckbox.propTypes = {
  register: PropTypes.func,
  name: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ]),
  ),
};

export default InputGroupedCheckbox;
