import { methods } from '../utils';
import { authenticatedService } from './middleware';

const { GET } = methods;
const PROXY_CONNECTOR_URL = process.env.REACT_APP_PROXY_CONNECTOR || process.env.REACT_APP_CONNECTEUR_API_URL || '/connector';

const routes = {
  axigateFromFront: `${PROXY_CONNECTOR_URL}/axigate/fromFront`,
  connecteurPath: (path) => `${PROXY_CONNECTOR_URL}${path}`,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  axigateFromFront: ({ destinationPath, params }) =>
    authenticatedService(GET, routes.connecteurPath(destinationPath), {}, params),
};
