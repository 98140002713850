import { createReducer } from 'reduxsauce';

import { types } from './actions';

const initialState = {};


const resetReducer = () => {
  return { ...initialState };
};

export default createReducer(initialState, { [types.RESET_REDUCER]: resetReducer });
