import '../../form/style.css';
import './../style.css';

import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { SIDE_CONST } from '../../../../utils/enum';
import { isRequiredField, ModalButtonsGroup } from '../../formV3';
import { InputGroupedCheckbox, InputSelect } from '../../formV3/controllers';
import { CustomFormController } from '../../formV3/innerWrapper';

const SupInfoForm = ({ id = 'supInfoForm', defaultValues, onSubmit, updateData, radiusOptions, template, onCancel }) => {
  const { t } = useTranslation();
  const yupSchema = yup.object().shape({
    side_answer: _.get(template, 'side') === 'on'
      ? yup.array().of(yup.mixed().oneOf([SIDE_CONST.LEFT, SIDE_CONST.RIGHT, false]))
        .min(1, 'surveyPatientForm.supInfoForm.side_answer.rules.min')
        .max(2, 'surveyPatientForm.supInfoForm.side_answer.rules.max')
      : null,
    radius_answer:_.get(template, 'radius') === 'on'
      ? yup.array().of(yup.object().shape({
        value: yup.number(),
        label: yup.mixed().oneOf(['Auriculaire', 'Annulaire', 'Majeur', 'Index', 'Pouce', 'Hallux', 'Secundus', 'Tertius', 'Quartus', 'Quintus']),
        isDisabled: yup.boolean(),
      }))
        .min(1, 'surveyPatientForm.supInfoForm.radius_answer.rules.min')
      : null,
  });
  useEffect(() => {
    formMethods.reset({ ...defaultValues });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  const formMethods = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: useMemo(() => {
      return defaultValues;
    }, [defaultValues]),
    resolver: yupResolver(yupSchema),
    shouldFocusError: true,
  });
  const { handleSubmit, watch, formState: { errors } } = formMethods;

  const onSubmits = (data) => {
    let newData ={ ...data };
    if (_.get(template, 'side') !== 'on'){
      newData = _.omit(newData, 'side_answer');
    } else {
      newData = { ...newData, side_answer: data.side_answer.filter(el => el && el !== false) };
    }
    if (_.get(template, 'radius') !== 'on'){
      newData = _.omit(newData, 'radius_answer');
    }
    if (updateData) updateData(id, newData );
    if (onSubmit) onSubmit(null, data);
  };

  // eslint-disable-next-line no-console
  const onErrors = (errors) => console.error('errors :>> ', errors);

  const handleCancel = (id) => {
    if (onCancel) onCancel(id);
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmits, onErrors)} className={'custom-formV2'} >
        {_.get(template, 'side') === 'on' && (
          <div className={'createPatient-Container-part2'}>
            <CustomFormController
              name={'side_answer'}
              isRequiredField={isRequiredField(yupSchema, 'side_answer')}
              label={t('surveyPatientForm.supInfoForm.side_answer.label')}
            >
              <InputGroupedCheckbox
                options={Object.values(SIDE_CONST).map(s => ({
                  value: s,
                  label: t(s),
                }))}
                containerClassName="ec-multiple-choice-checkbox-container"
                defaultValue={_.get(defaultValues, 'side_answer' )}
              />
            </CustomFormController>
          </div>
        )}
        {_.get(template, 'radius') === 'on' && (
          <div className={'createPatient-Container-part2'}>
            <CustomFormController
              name={'radius_answer'}
              isRequiredField={isRequiredField(yupSchema, 'radius_answer')}
              label={t('surveyPatientForm.supInfoForm.radius_answer.label')}
            >
              <InputSelect
                options={radiusOptions}
                isMulti
                defaultValues={_.get(defaultValues, 'radius_answer' )}
              />
            </CustomFormController>
          </div>
        )}
        <ModalButtonsGroup

          onCancel={handleCancel}
          submitLabel={t('form.button.send.label')}
        />
      </form>
      {!!process.env.REACT_APP_DEBUG_FORM && (
        <>
          <pre>
            { JSON.stringify(watch(), null, 2) }
          </pre>
          <pre>
            { JSON.stringify(errors, null, 2) }
          </pre>
        </>
      )}
    </FormProvider>
  );
};

export default SupInfoForm;
