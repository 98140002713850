/* eslint-disable no-template-curly-in-string */
import axios from 'axios';
import { isValid, parse } from 'date-fns';
import { PhoneNumberType } from 'google-libphonenumber';
import _ from 'lodash';
import * as yup from 'yup';

import { userQueries } from '../services/GraphQLRequest';
import { getValuesDoesEmailExists } from '../services/userService';
import { isValidSecuKey } from '../ui/components/form/rule';
import { loadState } from '../utils';
import { digitRegex } from './regex';

yup.setLocale({
  mixed: { required: 'Le champs est requis' },
  string: { required: 'Le champs est requis' },
  number: {
    required: 'Le champs est requis',
    min: 'Le nombre doit etre au moins ${min}',
  },
  boolean: { required: 'Le champs est requis' },
  date: { required: 'Le champs est requis' },
  array: {
    typeError: 'Merci de selectionner le champs',
    required: 'Le champs est requis',
  },
  object: {
    typeError: 'Merci de selectionner le champs',
    required: 'Le champs est requis',
  },
});

yup.addMethod(yup.string, 'validMobile', function({ message, excludeEmptyString }) {
  return this.test({
    name: 'validMobile',
    message,
    test: function (number) {
      const { path, createError } = this;
      return new Promise((resolve) => {
        if (!number) return resolve(true);
        const storage = loadState();
        axios.post(
          `${process.env.REACT_APP_BASE_API_URL}/V2/phone-number/is-valid-phone-number`,
          { phoneNumber: number, client_id: process.env.REACT_APP_CLIENT_ID, client_secret: process.env.REACT_APP_CLIENT_SECRET },
          { headers: storage?.auth?.token ? { Authorization: `Bearer ${storage.auth.token}` } : { } },
        ).then((result) => {
          const { isValidNumber, numberType } = result.data.data;
          if (isValidNumber && PhoneNumberType.MOBILE === numberType){
            resolve(true);
          } else {
            resolve(createError({ path, message }));
          }
        }).catch(() => {
          resolve(createError({ path, message }));
        });
      });
    },
  });
});

yup.addMethod(yup.array, 'allChecked', function(message) {
  return this.test({
    name: 'allChecked',
    message,
    test: function (val, element) {
      // NOTE: this must not be an arrow function, because yup binds it to it's "this"
      // Note the use of this.options.context to reference the dynamic variable
      if (Array.isArray(val)) {
        const { parent } = element;
        const listValues = parent?.translation?.data?.list.map(el => `${el.value}`);
        return listValues.length === val.length && val.filter((v) => !listValues.includes(v)).length === 0;
      }
      return false;
    },
  });
});

yup.addMethod(yup.string, 'minOneMaj', function (message) {
  return this.test({
    name: 'minOneMaj',
    message,
    test: function (value) {
      // NOTE: this must not be an arrow function, because yup binds it to it's "this"
      // Note the use of this.options.context to reference the dynamic variable
      const { path, createError } = this;
      if (!value || value === '') {
        return true;
      } else {
        return /[A-Z]/g.test(value) ? value : createError({ path, message });
      }
    },
  });
});

yup.addMethod(yup.string, 'minOneMinuscule', function (message) {
  return this.test({
    name: 'minOneMinuscule',
    message,
    test: function (value) {
      // NOTE: this must not be an arrow function, because yup binds it to it's "this"
      // Note the use of this.options.context to reference the dynamic variable
      const { path, createError } = this;
      if (!value || value === '') {
        return true;
      } else {
        return /[a-z]/g.test(value) ? value : createError({ path, message });
      }
    },
  });
});

yup.addMethod(yup.string, 'minOneNumber', function (message) {
  return this.test({
    name: 'minOneNumber',
    message,
    test: function (value) {
      // NOTE: this must not be an arrow function, because yup binds it to it's "this"
      // Note the use of this.options.context to reference the dynamic variable
      const { path, createError } = this;
      if (!value || value === '') {
        return true;
      } else {
        return digitRegex.test(value) ? value : createError({ path, message });
      }
    },
  });
});

yup.addMethod(yup.string, 'minOneSpecialChar', function (message) {
  return this.test({
    name: 'minOneSpecialChar',
    message,
    test: function (value) {
      // NOTE: this must not be an arrow function, because yup binds it to it's "this"
      // Note the use of this.options.context to reference the dynamic variable
      const { path, createError } = this;
      if (!value || value === '') {
        return true;
      } else {
        return /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/g.test(value) ? value : createError({ path, message });
      }
    },
  });
});

yup.addMethod(yup.string, 'isValidSecuKey', function (message) {
  return this.test({
    name: 'isValidSecuKey',
    message,
    test: function (value) {
      // NOTE: this must not be an arrow function, because yup binds it to it's "this"
      // Note the use of this.options.context to reference the dynamic variable
      const { path } = this;
      if (!value || !value.length){
        return true;
      } else {
        return isValidSecuKey({ value }).catch(err => {
          return this.createError({ path, message: err.message || message });
        });
      }
    },
  });
});

yup.addMethod(yup.date, 'format', function (formats, message) {
  return this.test({
    name: 'format',
    message,
    test: function (value) {
      // NOTE: this must not be an arrow function, because yup binds it to it's "this"
      // Note the use of this.options.context to reference the dynamic variable
      const { path, createError } = this;
      if (!value || isValid(value)) {
        return true;
      }
      const d = parse(value, formats, new Date());
      return (
        (d !== null && isValid(d))
        || createError({ path, message })
      );
    },
  });
});

yup.addMethod(yup.string, 'isEmailAvailable', function ({ userId }, message) {
  return this.test({
    name: 'isEmailAvailable',
    message,
    test: function (email) {
      // NOTE: this must not be an arrow function, because yup binds it to it's "this"
      // Note the use of this.options.context to reference the dynamic variable
      const { path, createError } = this;
      return new Promise((resolve) => {
        if (!email) return resolve(true);
        const storage = loadState();
        axios.post(
          process.env.REACT_APP_HASURA_URL,
          {
            query: userQueries.doesEmailExist,
            variables: getValuesDoesEmailExists(email, userId),
          },
          { headers: { Authorization: `Bearer ${storage.auth.token}` } },
        ).then((result) => {
          const countPatients = _.get(result, 'data.data.patients_aggregate.aggregate.count', 0);
          const countMedics = _.get(result, 'data.data.medics_aggregate.aggregate.count', 0);
          if (( countPatients + countMedics) === 0){
            resolve(true);
          } else {
            resolve(createError({ path, message }));
          }
        }).catch(() => {
          resolve(createError({ path, message }));
        });
      });
    },
  });
});

export default yup;