import _ from 'lodash';
import { createReducer } from 'reduxsauce';

import { QUESTION_TYPES } from '../../utils/enum';
import { types } from './actions';

const initialState = {
  list: {},
  isListLoading: false,
  types: {},
  specialities: {},
  questionTypes: {},
  questionTypeIdsCheckboxes: [],
  templates: {},
  medicSurveys: [],
  link: [],
  isSurveySigned: null,
  counterSigned: null,
  survey: {},
  generatedURL: null,
  temporarySurvey: null,
  loading: false,
  loadingType: false,
  loadingQuestionType: false,
  loadingTemplateType: false,
  loadingSurvey: false,
  loadingLinkSurveyPatient: false,
  signUrl: null,
  signUrlError: false,
};

/**
 *
 * @param state
 * @returns {{list: {}, loading: boolean}}
 */
const startLoader = (state = { ...initialState }) => ({
  ...state,
  loading: true,
});

/**
 *
 * @param state
 * @returns {{list: {}, loading: boolean}}
 */
const startSpecialitiesLoader = (state = { ...initialState }) => ({
  ...state,
  loadingSpecialities: true,
});

/**
 *
 * @param state
 * @returns {{list: {}, loading: boolean}}
 */
const stopSpecialitiesLoader = (state = { ...initialState }) => ({
  ...state,
  loadingSpecialities: false,
});

/**
 *
 * @param state
 * @returns {{list: {}, loading: boolean}}
 */
const stopLoader = (state = { ...initialState }) => ({
  ...state,
  loading: false,
});

/**
 *
 * @param state
 * @returns {{list: {}, loading: boolean}}
 */
const startTypeLoader = (state = { ...initialState }) => ({
  ...state,
  loadingType: true,
});

/**
 *
 * @param state
 * @returns {{list: {}, loading: boolean}}
 */
const stopTypeLoader = (state = { ...initialState }) => ({
  ...state,
  loadingType: false,
});

/**
 *
 * @param state
 * @returns {{list: {}, loading: boolean}}
 */
const startQuestionTypeLoader = (state = { ...initialState }) => ({
  ...state,
  loadingQuestionType: true,
});

/**
 *
 * @param state
 * @returns {{list: {}, loading: boolean}}
 */
const stopQuestionTypeLoader = (state = { ...initialState }) => ({
  ...state,
  loadingQuestionType: false,
});

const startSurveyLoader = (state = { ...initialState }) => ({
  ...state,
  loadingSurvey: true,
});

const stopSurveyLoader = (state = { ...initialState }) => ({
  ...state,
  loadingSurvey: false,
});
/**
 *
 * @param state
 * @returns {{list: {}, loading: boolean}}
 */

const startLinkSurveyPatientLoader = (state = { ...initialState }) => ({
  ...state,
  loadingLinkSurveyPatient: true,
});

/**
 *
 * @param state
 * @returns {{list: {}, loading: boolean}}
 */

const stopLinkSurveyPatientLoader = (state = { ...initialState }) => ({
  ...state,
  loadingLinkSurveyPatient: false,
});

/**
 * Save many survey type. It will erase old value
 *
 * @param state
 * @param action
 * @returns {{list: {}, loading: boolean}}
 */
const surveyTypesSuccess = (state = { ...initialState }, action = {}) => {
  const { survey_types } = action;
  const { types } = state;
  const data = _.keyBy(survey_types, 'id');
  return {
    ...state,
    types: { ...types, ...data },
    loadingType: false, /*, hasMore: surveys.length > 0 */
  };
};

/**
 * Save many survey type. It will erase old value
 *
 * @param state
 * @param action
 * @returns {{list: {}, loading: boolean}}
 */
const specialitiesSuccess = (state = { ...initialState }, action = {}) => {
  const { survey_specialities } = action;
  const data = _.keyBy(survey_specialities, 'id');
  return {
    ...state,
    specialities: data,
    loadingType: false, /*, hasMore: surveys.length > 0 */
  };
};

/**
 * Save many question type. It will erase old value
 *
 * @param state
 * @param action
 * @returns {{list: {}, loading: boolean}}
 */
const questionTypesSuccess = (state = { ...initialState }, action = {}) => {
  const { question_types } = action;
  const data = _.keyBy(question_types, 'id');

  const questionTypeIdsCheckboxes = question_types
    .filter(q => [QUESTION_TYPES.multiple_choice, QUESTION_TYPES.checkbox_full, QUESTION_TYPES.radio_multi_questions_to_table].includes(q.value))
    .map(q => q.id);
  return {
    ...state,
    questionTypes: data,
    questionTypeIdsCheckboxes,
    loadingQuestionType: false /*, hasMore: surveys.length > 0 */,
  };
};

const linkSurveyPatientSuccess = (state = { ...initialState }, action = {}) => {
  const ids = action.ids || [];
  return {
    ...state,
    link: ids,
    loadingLinkSurveyPatient: false /*, hasMore: surveys.length > 0 */,
  };
};

/**
 * Retrieve current non template survey
 *
 * @param state
 * @param action
 * @returns {{survey: {}, loading: boolean}}
 */
const surveyCurrentSuccess = (state = { ...initialState }, action = {}) => {
  const { surveys } = action;
  const { list } = state;
  const newSurveys = Array.isArray(surveys) ? _.keyBy(surveys.filter(s => s.__typename === 'surveys'), 'id') : { [surveys.id]: surveys };
  return {
    ...state,
    survey: newSurveys,
    list: { ...list, ...newSurveys },
    currentSurvey: Array.isArray(surveys) ? surveys[0] : surveys,
    loadingSurvey: false,
    isListLoading: false,
  };
};

/**
 * Retrieve current non template survey
 *
 * @param state
 * @param action
 * @returns {{survey: {}, loading: boolean}}
 */
const surveyListSuccess = (state = { ...initialState }, action = {}) => {
  const { surveys } = action;
  const newSurveys = Array.isArray(surveys) ? _.keyBy(surveys.filter(s => s.__typename === 'surveys'), 'id') : { [surveys.id]: surveys };
  return {
    ...state,
    list: { ...newSurveys },
    isListLoading: false,
  };
};

const surveyCurrentUpdateSuccess = (state = { ...initialState }, action = {}) => {
  const { questions } = action;
  const { survey } = state;
  let newSurveys = Object.values(survey || {});
  const stepId = questions[0].step_id;
  newSurveys.steps.forEach((step) => {
    if (step.id === stepId) {
      step.questions = questions;
    }
  });
  return {
    ...state,
    survey: newSurveys,
    loadingSurvey: false,
  };
};

const surveyCurrentDelete = (state = { ...initialState }) => ({
  ...state,
  survey: {},
});

const temporarySurveyCreate = (state = { ...initialState }, action = {}) => {
  const { temporarySurvey } = action;
  return {
    ...state,
    temporarySurvey: { ...temporarySurvey },
    loading: false,
  };
};

const temporarySurveyDelete = (state = { ...initialState }) => ({
  ...state,
  temporarySurvey: null,
});

const surveySignSuccess = (state = { ...initialState }) => {
  return {
    ...state,
    isSurveySigned: true,
  };
};

const surveySignFailure = (state = { ...initialState }) => ({
  ...state,
  isSurveySigned: false,
});

const resetSurveySign = (state = { ...initialState }) => {
  return {
    ...state,
    isSurveySigned: null,
  };
};

const surveyCounterSignSuccess = (state = { ...initialState }) => {
  /*
    using new state counterSigned in order to avoid conflict in patientDetail screen
    which is using signed state to success of medic force signing survey
   */
  return {
    ...state,
    counterSigned: true,
  };
};

const surveyCounterSignFailure = (state = { ...initialState }) => {
  return {
    ...state,
    counterSigned: false,
  };
};

const resetSurveyCounterSign = (state = { ...initialState }) => {
  return {
    ...state,
    counterSigned: null,
  };
};

const uploadSignSuccess = (state = { ...initialState }, action = {}) => {
  const { survey } = action;
  const { list } = state;
  const newSurveys = survey.map((el) => (list[el.id] ? { ...list[el.id], ...el } : el));
  return {
    ...state,
    list: { ...list, ..._.keyBy(newSurveys, 'id') },
    loadingSurvey: false,
    isSurveySigned: true,
  };
};

const uploadSignFailure = (state = { ...initialState }) => ({ ...state });

const resetLink = (state = { ...initialState }) => {
  return {
    ...state,
    link: [],
  };
};

const medicSurveysSuccess = (state = { ...initialState }, action = {}) => {
  const { medic_surveys } = action;
  return {
    ...state,
    medicSurveys: medic_surveys,
  };
};

const surveysDeleteSuccess = (state = { ...initialState }, action = {}) => {
  const { IDs } = action;
  let data = state.list;
  IDs.forEach((id) => {
    delete data[id];
  });

  return {
    ...state,
    list: data,
  };
};

const surveyWithdrawalSuccess = (state = { ...initialState }, action = {}) => {
  const { id: updatedSurveyId, status: updatedSurveyStatus } = action.survey;
  const oldSurvey = state.list[updatedSurveyId];
  const newSurvey = { ...oldSurvey, status: updatedSurveyStatus };

  return {
    ...state,
    currentSurvey: {
      ...state.currentSurvey,
      status: updatedSurveyStatus,
    },
    list: {
      ...state.list,
      [updatedSurveyId]: { ...newSurvey },
    },
  };
};

const generateSuccess = (state = { ...initialState }, action = {}) => {
  const { url } = action;
  return {
    ...state,
    generatedURL: url,
  };
};

const genSurveySignUrlSuccess = (state = { ...initialState }, action = {}) => {
  const { url } = action;
  return {
    ...state,
    signUrl: url,
    signUrlError: false,
  };
};

const genSurveySignUrlError = (state = initialState ) => {
  return {
    ...state,
    signUrl: null,
    signUrlError: true,
  };
};

const genSurveySignUrlReset = (state = initialState ) => {
  return {
    ...state,
    signUrl: null,
    signUrlError: false,
  };
};

const generateReset = (state = { ...initialState }) => {
  return {
    ...state,
    generatedURL: null,
  };
};

const fetchPatientSurveyList = (state = initialState) => {
  return {
    ...state,
    isListLoading: true,
  };
};



const resetReducer = () => {
  return { ...initialState };
};

/**
   * MAPPING
   */
export default createReducer(initialState, {
  [types.RESET_REDUCER]: resetReducer,

  [types.MEDIC_SURVEYS_SUCCESS]: medicSurveysSuccess,

  [types.SURVEY_TYPES_REQUEST]: startTypeLoader,
  [types.SURVEY_TYPES_SUCCESS]: surveyTypesSuccess,
  [types.SURVEY_TYPES_FAILURE]: stopTypeLoader,

  [types.SPECIALITIES_REQUEST]: startSpecialitiesLoader,
  [types.SPECIALITIES_SUCCESS]: specialitiesSuccess,
  [types.SPECIALITIES_FAILURE]: stopSpecialitiesLoader,

  [types.LINK_SURVEY_PATIENT_REQUEST]: startLinkSurveyPatientLoader,
  [types.LINK_SURVEY_PATIENT_SUCCESS]: linkSurveyPatientSuccess,
  [types.LINK_SURVEY_PATIENT_FAILURE]: stopLinkSurveyPatientLoader,

  [types.DELETE_LOCAL_LINK]: resetLink,

  [types.QUESTION_TYPES_REQUEST]: startQuestionTypeLoader,
  [types.QUESTION_TYPES_SUCCESS]: questionTypesSuccess,
  [types.QUESTION_TYPES_FAILURE]: stopQuestionTypeLoader,

  [types.SURVEY_CURRENT_REQUEST]: startSurveyLoader,
  [types.SURVEY_CURRENT_SUCCESS]: surveyCurrentSuccess,
  [types.SURVEY_CURRENT_FAILURE]: stopSurveyLoader,
  [types.SURVEY_LIST_SUCCESS]: surveyListSuccess,


  [types.SURVEY_WITHDRAWAL_REQUEST]: startSurveyLoader,
  [types.SURVEY_WITHDRAWAL_SUCCESS]: surveyWithdrawalSuccess,
  [types.SURVEY_WITHDRAWAL_FAILURE]: stopSurveyLoader,

  [types.SURVEY_CURRENT_UPDATE_REQUEST]: startSurveyLoader,
  [types.SURVEY_CURRENT_UPDATE_SUCCESS]: surveyCurrentUpdateSuccess,
  [types.SURVEY_CURRENT_UPDATE_FAILURE]: stopSurveyLoader,

  [types.SURVEY_CURRENT_DELETE]: surveyCurrentDelete,

  [types.SURVEY_CONSENT_UPDATE_REQUEST]: startSurveyLoader,
  [types.SURVEY_CONSENT_UPDATE_FAILURE]: stopSurveyLoader,

  [types.TEMPORARY_SURVEY_CREATE]: startLoader,
  [types.TEMPORARY_SURVEY_SUCCESS]: temporarySurveyCreate,
  [types.TEMPORARY_SURVEY_DELETE]: temporarySurveyDelete,

  [types.SURVEY_SIGN_SUCCESS]: surveySignSuccess,
  [types.SURVEY_SIGN_FAILURE]: surveySignFailure,
  [types.RESET_SURVEY_SIGNED]: resetSurveySign,

  [types.SURVEY_COUNTERSIGN_SUCCESS]: surveyCounterSignSuccess,
  [types.SURVEY_COUNTERSIGN_FAILURE]: surveyCounterSignFailure,

  [types.RESET_SURVEY_COUNTER_SIGNED]: resetSurveyCounterSign,

  [types.SURVEY_UPLOAD_SIGN]: startLoader,
  [types.SURVEY_UPLOAD_SIGN_SUCCESS]: uploadSignSuccess,
  [types.SURVEY_UPLOAD_SIGN_FAILURE]: uploadSignFailure,

  [types.SURVEY_DELETE_REQUEST]: startLoader,
  [types.SURVEY_DELETE_SUCCESS]: surveysDeleteSuccess,
  [types.SURVEY_DELETE_FAILURE]: stopLoader,

  [types.SURVEY_GENERATE_SUCCESS]: generateSuccess,
  [types.SURVEY_GENERATE_RESET]: generateReset,

  [types.GEN_SURVEY_SIGN_URL_SUCCESS]: genSurveySignUrlSuccess,
  [types.GEN_SURVEY_SIGN_URL_ERROR]: genSurveySignUrlError,
  [types.GEN_SURVEY_SIGN_URL_RESET]: genSurveySignUrlReset,

  [types.FETCH_PATIENT_SURVEY_LIST]: fetchPatientSurveyList,
});
