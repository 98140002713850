import './style.css';

import React from 'react';
import { useTranslation } from 'react-i18next';

const ListHeader = () => {
  const { t } = useTranslation();
  return (
    <div className={'list-patients-header'}>
      <p className={'list-patients-header-text list-patients-header-first-item'}>
        {t('medic.list.headers.patient')}
      </p>
      <p className={'list-patients-header-text list-patients-header-second-item'}>
        {t('medic.list.headers.surgeryDate')}
      </p>
    </div>
  );};

export default ListHeader;