import { resettableReducer } from 'reduxsauce';

import { AuthReducers, AuthSagas } from './auth';
import { CityReducers, CitySagas } from './city';
import { ClinicReducers, ClinicSagas } from './clinic';
import { CommonSagas, CommonTypes } from './common';
import { DisplayReducers, DisplaySagas } from './display';
import { FiltersReducers } from './filter';
import { FormsReducers } from './forms';
import { LanguageReducers, LanguageSagas } from './languages';
import { LoaderReducers } from './loader';
import { MedicReducers, MedicSagas } from './medic';
import { MedicTemplateReducers, MedicTemplateSagas } from './medicTemplates';
import { PatientReducers, PatientSagas } from './patient';
import { QuestionIntroReducers, QuestionIntroSagas } from './questionIntro';
import { RadiusReducers } from './radius';
import { SnackReducers, SnackSagas } from './snackBar';
import { SplashReducers, SplashSaga } from './splash';
import { SurveyReducers, SurveySagas } from './survey';
import { TemplateReducers, TemplateSagas } from './template';

// Specify reset action type rule
const resettable = resettableReducer(CommonTypes.RESET_ALL_REDUCERS);

// Merge our redux together
const reducers = {
  auth: resettable(AuthReducers),
  city: resettable(CityReducers),
  clinic: resettable(ClinicReducers),
  display: resettable(DisplayReducers),
  filters: resettable(FiltersReducers),
  forms: resettable(FormsReducers),
  language: resettable(LanguageReducers),
  loader: resettable(LoaderReducers),
  medic: resettable(MedicReducers),
  medicTemplate: MedicTemplateReducers,
  patient: resettable(PatientReducers),
  questionIntro: resettable(QuestionIntroReducers),
  radius: resettable(RadiusReducers),
  snackBar: resettable(SnackReducers),
  survey: resettable(SurveyReducers),
  template: resettable(TemplateReducers),
  splash: resettable(SplashReducers),
};

export const rootSaga = [
  ...AuthSagas,
  ...SnackSagas,
  ...LanguageSagas,
  ...MedicSagas,
  ...DisplaySagas,
  ...PatientSagas,
  ...SurveySagas,
  ...MedicTemplateSagas,
  ...TemplateSagas,
  ...ClinicSagas,
  ...CitySagas,
  ...CommonSagas,
  ...QuestionIntroSagas,
  ...SplashSaga,
];

export default reducers;
