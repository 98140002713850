

import { connect } from 'react-redux';

import { MedicTemplateActions } from '../../../../redux/medicTemplates';
import { SurveyActions } from '../../../../redux/survey';
import SurveysCreate from './SurveysCreate';

const mapStateToProps = ({ survey, medicTemplate, language }) => {
  return {
    languageList: language.list,
    questionTypeList: survey.questionTypes || {},
    questionTypeIdsCheckboxes: survey.questionTypeIdsCheckboxes,
    medicTemplateList: medicTemplate.list || {},
  };
};

const mapDispatchToProps = (dispatch) => ({
  surveyTypesRequest: () => dispatch(SurveyActions.surveyTypesRequest()),
  specialitiesRequest: () => dispatch(SurveyActions.specialitiesRequest()),
  questionTypesRequest: () => dispatch(SurveyActions.questionTypesRequest()),
  currentFormRequest: (id, lngId) => dispatch(MedicTemplateActions.medicTemplatesGetDetailsRequest(id, lngId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SurveysCreate);
