import _ from 'lodash';
import { connect } from 'react-redux';

import { AuthActions } from '../../../../../../redux/auth';
import { FormsActions } from '../../../../../../redux/forms';
import { SnackActions } from '../../../../../../redux/snackBar';
import { TOKEN_ACTIONS } from '../../../../../../utils/enum';
import CodeStep from './CodeStep';

const mapStateToProps = ({ auth, forms }, props) => {
  const { id } = props;
  return {
    isCodeValid: auth.isCodeValid && auth.isCodeValid === true,
    formsData: _.get(forms.data, id),
    username: _.get(forms.data, 'ModalRenew.UsernameStep.username'),
    defaultValues: {
      code: '',
      ..._.get(forms.data, id),
      // , status: false
    },
    smsSentQty: auth?.sms?.sentQty,
    uuid: auth?.sms?.uuid,
    smsError: auth?.sms?.error,
    dateForNewSms: auth?.sms?.nextCanBeSendAt,
    email: _.get(forms.data, 'ModalRenew.EmailStep.email'),
    phoneNumber: _.get(forms.data, 'ModalRenew.EmailStep.phone_number'),
    birthdate : _.get(forms.data, 'ModalRenew.EmailStep.birthdate'),
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateData: (key, data) => dispatch(FormsActions.updateData(key, data)),
  omitData: (key) => dispatch(FormsActions.omitData(key)),
  checkCode: (code) => dispatch(AuthActions.checkCode(code, TOKEN_ACTIONS.forgotPassword)),
  forgotPassword: (username, isPro, email, phoneNumber, birthdate) => dispatch(AuthActions.forgotPassword(username, isPro, email, phoneNumber, birthdate)),
  clearLastSmsSent: () => dispatch(AuthActions.clearLastSmsSent()),
  resetStatus: () => dispatch(AuthActions.resetStatus()),
  displayInfo: (message) => dispatch(SnackActions.displayInfo(message)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CodeStep);
