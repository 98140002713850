import '../../../components/form/style.css';

import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Form, ModalButtonsGroup } from '../../../components/formV3';
import { InputText, PhoneInput } from '../../../components/formV3/controllers';
import InputSelectV2 from '../../../components/formV3/controllers/InputSelectV2';
import { CustomFormController } from '../../../components/formV3/innerWrapper';
import Modal from '../../../components/modal';
import { formInitialValues } from '../FormModel/formInitialValues';
import yupSchema from '../FormModel/validationSchema';

const ID = 'medicForm';

const MedicForm = ({ medic, onSubmit, isOpen, onClose, languageOptions, languagesRequest, message }) => {
  const { t } = useTranslation();
  const [defaultValues, setDefaultValues] = useState(formInitialValues);

  useEffect(() => {
    if(!languageOptions.length) {
      languagesRequest();
    }
  }, []);

  useEffect(() => {
    const newMedic = _.pick(medic, ['rpps_number', 'phone_number', 'local_phone_number', 'user.language_id']);
    const newDefaultValues = _.merge(formInitialValues, newMedic);
    if (!_.isEqual(defaultValues, newDefaultValues)){
      setDefaultValues(newDefaultValues);
    }
  }, [medic]);

  const handleOnCancel = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleSubmit = (data) => {
    if (onSubmit) {
      onSubmit(data);
    }
  };

  return (
    <Modal onClose={handleOnCancel} title={t('modal_edit_medic_form')} isOpen={isOpen} >
      <Form id={ID} yupSchema={yupSchema} onSubmit={handleSubmit} defaultValues={defaultValues}>
        <div className="row">
          <CustomFormController
            name={'rpps_number'}
            label={t('medicForm.rpps_number.label')}
          >
            <InputText inputPreset="rpps_number" />
          </CustomFormController>
        </div>
        <div className="row">
          <CustomFormController
            name={'phone_number'}
            label={t('medicForm.mobile_phone_number.label')}
          >
            <PhoneInput />
          </CustomFormController>
          <div className="row">

            <CustomFormController
              name={'user.language_id'}
              label={t('medicForm.language.label')}
              isRequiredField={true}
            >
              <InputSelectV2 options={languageOptions} />
            </CustomFormController>
          </div>
          <p className='custom-formV2-control-label'>{message}</p>
        </div>
        <ModalButtonsGroup onCancel={handleOnCancel} submitLabel={t('form.button.send.label')} />
      </Form>
    </Modal>
  );
};

export default MedicForm;
