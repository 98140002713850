import { toast } from 'react-toastify';
import { takeLatest } from 'redux-saga/effects';

import i18n from '../../services/i18n/i18n';
import { levels, types } from './actions';

function* displayMessage({ message, level, timeout = 3500 }) {
  const messageI18n = i18n.t(message);
  yield toast[level || levels.INFO](messageI18n, { toastId: message, autoClose: timeout });
}

function* hideSnackBar() {
  yield toast.dismiss();
}

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  takeLatest(types.DISPLAY_ERROR, displayMessage),
  takeLatest(types.DISPLAY_WARNING, displayMessage),
  takeLatest(types.DISPLAY_INFO, displayMessage),
  takeLatest(types.HIDE_SNACK_BAR, hideSnackBar),
];
