import { call, put, takeLatest } from 'redux-saga/effects';

import { QuestionIntroService } from '../../services';
import { SnackActions } from '../snackBar';
import { default as QuestionIntroActions, types } from './actions';

function* introQuestionRequest({ surveyTypeId, lngId }) {
  yield put( QuestionIntroActions.startLoader());
  const [error, data] = yield call(QuestionIntroService.questionIntro, surveyTypeId, lngId);
  if (error || !data) {
    yield put(SnackActions.displayError('question_basics_list_error'));
    yield put(QuestionIntroActions.questionBasicsFailure());
    return;
  }
  const { introduction_questions } = data;
  if (introduction_questions)
    yield put( QuestionIntroActions.introQuestionSuccess(introduction_questions) );
  else {
    yield put(SnackActions.displayError('question_basics_list_error'));
    yield put(QuestionIntroActions.questionBasicsFailure());
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default [takeLatest(types.INTRO_FETCH, introQuestionRequest)];
