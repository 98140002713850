import React from 'react';
import { useTranslation } from 'react-i18next';

import { IconAdd, IconAddPatientWhite, IconForm } from '../../../../assets/img/icons';

export const SIDEMENU_BUTTONS = {
  ADD_PATIENT: 'add_patient',
  ADD_FORM_TO_PATIENT: 'add_form',
  FORM: 'form',
};


const Button = ({ type, onClick, disabled }) => {
  const { t } = useTranslation();

  const handleOnAddClick = () => {
    if (onClick && !disabled) onClick(type);
  };

  const iconSrc = () => {
    switch (type) {
      case SIDEMENU_BUTTONS.ADD_PATIENT:
        return IconAddPatientWhite;
      case SIDEMENU_BUTTONS.ADD_FORM_TO_PATIENT:
        return IconAdd;
      case SIDEMENU_BUTTONS.FORM:
        return IconForm;
      default:
        return IconAdd;
    }
  };

  const textSrc = () => {
    switch (type) {
      case SIDEMENU_BUTTONS.ADD_PATIENT:
        return t('medic.sidemenu.create_patient_btn');
      case SIDEMENU_BUTTONS.ADD_FORM_TO_PATIENT:
        return t('medic.sidemenu.new_form_btn');
      case SIDEMENU_BUTTONS.FORM:
        return t('medic.sidemenu.store_btn');
      default:
        return t('medic.sidemenu.create_patient_btn');
    }
  };

  return (
    <button className={`sidemenu-button ${disabled ? 'disabled' : ''}`} onClick={handleOnAddClick} disabled={disabled} type="button">
      <p className={'sidemenu-button-text'}> {textSrc()} </p>
      <div className={'sidemenu-button-image-card-container'}>
        <div className={'sidemenu-button-image-background'}>
          <img src={iconSrc()} alt="icon" />
        </div>
      </div>
    </button>
  );
};

export default Button;