import '../../style.css';

import { yupResolver } from '@hookform/resolvers/yup';
import { differenceInSeconds, isPast } from 'date-fns';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { isNullOrWhitespace } from '../../../../../../utils/utils';
import yup from '../../../../../../utils/yup';
import { ModalButtonsGroup } from '../../../../../components/formV3';
import { InputText } from '../../../../../components/formV3/controllers';
import ErrorMessage from '../../../../../components/formV3/errors/ErrorMessage';
import { CustomFormController } from '../../../../../components/formV3/innerWrapper';
import { Button } from '../../../../../components/styledComponent';

const CodeStep = (
  {
    id,
    defaultValues,
    updateData,
    onStepCompletion,
    checkCode,
    smsSentQty,
    onCancel,
    omitData,
    clearLastSmsSent,
    forgotPassword,
    isCodeValid,
    resetStatus,
    username,
    isPro,
    dateForNewSms,
    email,
    phoneNumber,
    birthdate,
    onSubmit:onSubmitProps,
    displayInfo,
    uuid,
    smsError,
    title,
  },
) => {
  const { t } = useTranslation();
  const [secToSend, setSecToSend] = useState();

  const parsedDateForNewSms = dateForNewSms && new Date(dateForNewSms);
  const canSendNewSms = !dateForNewSms || (parsedDateForNewSms && isPast(parsedDateForNewSms));

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onSubmit',
    defaultValues: useMemo(() => {
      return defaultValues;
    }, [defaultValues]),
    resolver: yupResolver(yup.object().shape({ code: yup.string().length(6, 'modalRenewForm.code.rules.required').required() })),
    shouldFocusError: true,
  });

  const { control, handleSubmit, formState: { isSubmitSuccessful, errors, isValid } } = formMethods;
  const codeWatch = useWatch({ control, name: 'code', defaultValue: _.get(defaultValues, 'code') });

  useEffect(() => {
    // every second, decrement counter secToSend
    const interval = setInterval(() => {
      if (canSendNewSms) {
        setSecToSend(0);
      } else {
        setSecToSend(differenceInSeconds(parsedDateForNewSms, new Date()));
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [dateForNewSms]);

  useEffect(() => {
    if (smsError) {
      handleCancel();
    }
  }, [smsError]);


  const sendSmsWithCode = () => {
    if (canSendNewSms) {
      forgotPassword(username, isPro, email, phoneNumber, birthdate);
    } else {
      if (!isNullOrWhitespace(uuid)) {
        displayInfo('too_many_attempt_please_try_again_later');
        handleCancel();
      } else {
        displayInfo('please_check_your_sms');
      }
    }
  };

  useEffect(() => {
    // On componentDiDMount, send a first SMS is counter is 0
    if (!smsSentQty || smsSentQty < 1) {
      sendSmsWithCode();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smsSentQty, secToSend]);

  useEffect(() => {
    if (codeWatch && codeWatch.length === 6 ) {
      handleSubmit(data => onSubmit(data))();
    }
    return () => {
      resetStatus();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codeWatch]);

  useEffect(() => {
    // on form submit complete, got to next step
    if (isSubmitSuccessful === true && isCodeValid){
      // clear sms count
      if (clearLastSmsSent) clearLastSmsSent();
      const step = async () => {
        await onStepCompletion();
      };
      step();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful, isCodeValid]);

  const onSubmit = async (data) => {
    checkCode(data.code);
    updateData(id, data);
    onSubmitProps && onSubmitProps(data, id);
  };

  const handleCancel = () => {
    omitData(id);
    if (onCancel) onCancel();
  };

  return (
    <div className={'authentication-forgotten-password-content'}>
      <p className={'authentication-forgotten-password-text-desc'}>
        {title || ''}
      </p>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)} >
          <div >
            <CustomFormController
              name={'code'}
              rules={{ required: true }}
            >
              <InputText
                placeholder={t('modalRenewForm.code.placeholder')}
                autoComplete="one-time-code"
                type="text"
                hidden
              />
            </CustomFormController>
            {errors.status && <ErrorMessage name={'status'} />}

            <ModalButtonsGroup
              cancelLabel={t('cancel')}
              submitLabel={t('next')}
              onCancel={handleCancel}
              disabledSubmit={!isValid}
            />
          </div>
        </form>


        <Button type='button' variant='link' onClick={sendSmsWithCode} disabled={secToSend > 0} className="btn btn-link">
          { (secToSend > 0) ? t('send_code_back_in_given_time', { time: secToSend }) : t('send_code_back')}
        </Button>
      </FormProvider>
    </div>
  );
};

export default CodeStep;
